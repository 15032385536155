// src/components/admin/admin02_ClientData.tsx

import React, { useEffect } from 'react';
import useDataTable from '../../hooks/AdminDashboard/useDataTable';
import UsersStatsSection from './ClientData/UsersTableStats';
import UserTable from './ClientData/UsersTable';
import UsersPermissionTable from './ClientData/UsersPermissionTable'; 
import { useAuth } from '../../context/AuthContext';

const Admin02ClientData: React.FC = () => {
  const { data, loading, error, fetchData, addUser, updateUser, deleteUsers, handleSendTelegramLink } = useDataTable();
  const { user } = useAuth();

  useEffect(() => {
    fetchData(); 
  }, [fetchData]);

  // Check if user is null and handle it safely
  if (!user || user.email !== 'admin@r1trader.com') {
    return <UsersPermissionTable />;
  }

  return (
    <div className="p-2 space-y-2 w-auto max-w-screen  h-auto max-h-screen">
      <h1 className="text-2xl font-bold mb-4">Client Data</h1>
      
      {/* Upper section: Total stats count */}
      <div className="flex justify-center items-center">
      <UsersStatsSection 
        userData={data} 
      />
      </div>
      
      {/* Below stats count: User data table */}
      <div className="flex justify-center items-center">
        <UserTable 
          userData={data}
          fetchData={fetchData} 
          loading={loading} 
          error={error}
          addUser={addUser}
          updateUser={updateUser}
          deleteUsers={deleteUsers} 
          handleSendTelegramLink={handleSendTelegramLink}
        />
      </div>
    </div>
  );
};

export default Admin02ClientData;

// // udpatae code --> 16 oct 2024
// // src/components/admin/admin02_ClientData.tsx

// import React, { useEffect } from 'react';
// import useDataTable from '../../hooks/AdminDashboard/useDataTable';
// import UsersStatsSection from './ClientData/UsersTableStats';
// import UserTable from './ClientData/UsersTable';

// const Admin02ClientData = () => {
//   const { data, loading, error, fetchData, addUser, updateUser, deleteUsers } = useDataTable();

//   useEffect(() => {
//     fetchData(); 
//   }, [fetchData]);

//   return (
//     <div className="p-2 space-y-2 w-auto max-w-screen h-auto max-h-screen">
//       <h1 className="text-2xl font-bold mb-4">Client Data</h1>
      
//       {/* Upper section: Total stats count */}
//       <div className="flex justify-center items-center">
//       <UsersStatsSection 
//         userData={data} 
//       />
//       </div>
      
//       {/* Below stats count: User data table */}
//       <div className="flex justify-center items-center">
//         <UserTable 
//           userData={data}
//           fetchData={fetchData} 
//           loading={loading} 
//           error={error}
//           addUser={addUser}
//           updateUser={updateUser}
//           deleteUsers={deleteUsers} 
//         />
//       </div>
//     </div>
//   );
// };

// export default Admin02ClientData;
