// src/components/admin/ClientData/UsersTableFilter.tsx

import React, { useState } from 'react';
import { MdSort, MdClose  } from 'react-icons/md';

interface UsersTableFilterProps {
  columns: string[];
  onFilterChange: (column: string, direction: 'asc' | 'desc') => void;
  onClose: () => void;
}

const UsersTableFilter: React.FC<UsersTableFilterProps> = ({ columns, onFilterChange, onClose }) => {
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleFilter = () => {
    if (selectedColumn) {
      onFilterChange(selectedColumn, sortDirection);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 z-50"></div>

      {/* Modal Content */}
      <div className="bg-white border rounded p-6 shadow-lg z-50 max-w-sm w-full relative">
        {/* Close Icon */}
        <button
          className="absolute top-3 right-4 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <MdClose className="w-6 h-6" />
        </button>

        <h3 className="text-cyan-800 mb-4 font-semibold">Sort by Column</h3>
        <select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          className="border p-2 rounded mb-4 w-full"
        >
          <option value="">Select Column</option>
          {columns.map((column) => (
            <option key={column} value={column}>
              {column}
            </option>
          ))}
        </select>

        <div className="flex justify-center mb-6 space-x-4">
          <label className="flex items-center">
            <input
              type="radio"
              name="direction"
              value="asc"
              checked={sortDirection === 'asc'}
              onChange={() => setSortDirection('asc')}
              className="mr-2 accent-cyan-800"
            />
            Ascending
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="direction"
              value="desc"
              checked={sortDirection === 'desc'}
              onChange={() => setSortDirection('desc')}
              className="mr-2 accent-cyan-800"
            />
            Descending
          </label>
        </div>

        <button
          onClick={handleFilter}
          className="bg-cyan-800 text-white py-2 px-6 rounded w-full"
        >
          <MdSort className="inline-block mr-2" />
          Sort
        </button>
      </div>
    </div>
  );
};

export default UsersTableFilter;
