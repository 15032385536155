// src/components/admin/ClientData/WhatsappTemplates.tsx

const whatsappTemplates = [
    { 
      title: "General Assistance", 
      content: "Hello 👋, how can I assist you today?" 
    },
    { 
      title: "Subscription Reminder", 
      content: "🔔 Reminder: Your subscription is expiring soon. Renew now to avoid any interruption!" 
    },
    { 
      title: "Thank You Message", 
      content: "🙏 Thank you for choosing our service! We’re here if you need anything else." 
    },
    {
      title: "R One App Invitation",
      content: `👋🏻 亲爱的 R One 学员，
  
  为了让您更便捷地获取 Casper老师 的最新动态和 R One一系列的活动与项目 ，欢迎大家注册并使用 R One App！只需点击下方链接即可轻松注册：
  
  👉 [R One App注册](https://app.r1trader.com/login)
  
  R One App 的亮点功能：
  - 实时接收 Casper老师 所有线上、线下活动的通知和邀请
  - 获取 Casper老师 的最新视频链接，随时学习
  - 方便进行 Traderhub、R One考试和AlgoMatrix 的申请及付费操作
  - 更多功能正在持续优化中，未来将推出更多有趣且能让您受益的项目和通知
  
  ⚠️ 特别提醒： 
  旧学员在注册时，请务必使用曾经观看视频的邮箱并选择 “Existing Member” 进行注册和登录。 R One 将为您提供更多优质服务。
  
  💌 如果在使用过程中有任何问题或需要帮助，随时可以联系 Sky哥哥 ：[联系Sky](https://t.me/SkyWoon)
  
  快来注册 R One App，开启更高效的学习和交易之旅吧！`
    }
  ];
  
  export default whatsappTemplates;
  