// src/components/admin/adminSetting.tsx

import React, { useState } from 'react';
import AdminTable from '../admin/AdminSetting/AdminUsersTable';

const AdminSetting: React.FC = () => {

  // State to manage the selected setting
  const [selectedSetting, setSelectedSetting] = useState<string>('team-permission');

  // Function to conditionally render content based on the selected setting
  const renderContent = () => {
    switch (selectedSetting) {
      case 'team-permission':
        return ( <AdminTable /> );
      case 'company-details':
        return <h3 className="text-xl font-semibold mb-4">Company Details Section</h3>;
      case 'appearance':
        return <h3 className="text-xl font-semibold mb-4">Appearance Section</h3>;
      case 'group':
        return <h3 className="text-xl font-semibold mb-4">Group Section</h3>;
      case 'admin-console':
        return <h3 className="text-xl font-semibold mb-4">Admin Console Section</h3>;
      case 'configuration':
        return <h3 className="text-xl font-semibold mb-4">Configuration Section</h3>;
      case 'about-us':
        return <h3 className="text-xl font-semibold mb-4">About Us Section</h3>;
      default:
        return <h3 className="text-xl font-semibold mb-4">Select a setting from the menu</h3>;
    }
  };

  return (
    <div className="flex justify-center p-2 w-auto min-w-[1000px] space-x-2">
      {/* Left column: Render the selected setting */}
      <div className="w-full border-2 rounded-lg p-4">
        {renderContent()}
      </div>

      {/* Right column: Menu for selecting different settings */}
      <div className="w-[300px] bg-gray-100 p-4">
        <div className="shadow-lg">
          <div className="bg-white rounded-t-lg">
            <h3 className="flex justify-center text-lg font-semibold py-2 px-6 text-cyan-800">
                Settings Menu
            </h3>
          </div>

          {/* divider */}
          <div className="border-b border-gray-300 bg-white"></div>
          
          <div className="bg-white p-6 rounded-b-lg">
            <ul className="space-y-6">
                <li>
                  <button
                    className={`w-full py-2 text-cyan-800 hover:border hover:border-cyan-800 ${selectedSetting === 'appearance' ? 'font-bold' : ''}`}
                    onClick={() => setSelectedSetting('appearance')}
                  >
                    Appearance
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-cyan-800 hover:border hover:border-cyan-800 ${selectedSetting === 'team-permission' ? 'font-bold' : ''}`}
                    onClick={() => setSelectedSetting('team-permission')}
                  >
                    Team Permission
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-cyan-800 hover:border hover:border-cyan-800 ${selectedSetting === 'company-details' ? 'font-bold' : ''}`}
                    onClick={() => setSelectedSetting('company-details')}
                  >
                    Company Details
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-cyan-800 hover:border hover:border-cyan-800 ${selectedSetting === 'configuration' ? 'font-bold' : ''}`}
                    onClick={() => setSelectedSetting('configuration')}
                  >
                    Configuration
                  </button>
                </li>
                <li>
                  <button
                    className={`w-full text-cyan-800 hover:border hover:border-cyan-800 ${selectedSetting === 'about-us' ? 'font-bold' : ''}`}
                    onClick={() => setSelectedSetting('about-us')}
                  >
                    About Us
                  </button>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSetting;



// // update code --> 2 oct 2024
// // src/components/admin/adminSetting.tsx

// import React from 'react';
// import AdminTable from '../admin/AdminSetting/AdminUsersTable';
// import UserRoleManagement from '../admin/AdminSetting/AdminRoleManager';

// const AdminSetting: React.FC = () => {
//   return (
//     <div className="p-6">
//       <h2 className="text-2xl font-semibold mb-4">Admin Settings</h2>

//       {/* Upper section: Admin User Management */}
//       <section className="mb-8">
//         <AdminTable />
//       </section>

//       {/* Lower section: User Role Management */}
//       <section>
//         <UserRoleManagement />
//       </section>
//     </div>
//   );
// };

// export default AdminSetting;
