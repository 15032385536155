// src/components/admin/VideoSetting/VideoUpload.tsx

import React, { useState, useEffect } from 'react';
import { BsUpload } from 'react-icons/bs';
import { FaMinusSquare } from "react-icons/fa";
import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload';
import VideoGroupModal from './VideoGroupModal';

interface VideoUploadProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (data: FormData) => void;
  initialData?: any;  
}

const VideoUpload: React.FC<VideoUploadProps> = ({ isOpen, onClose, handleSubmit, initialData }) => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [videoCategoryArray, setVideoCategoryArray] = useState<number | ''>('');
  const [permission, setPermission] = useState('');
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [video, setVideo] = useState<File | null>(null);
  const [videoDuration, setVideoDuration] = useState<string>('');
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [videoGroupEmails, setVideoGroupEmails] = useState<{ [key: string]: string[] }>({}); 
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch categories from the useVideoUpload hook
  const { categories, fetchVideos } = useVideoUpload();

  // Populate form fields with existing data if in edit mode
  useEffect(() => {
    if (initialData) {
      setTitle(initialData.videoTitle);
      setCategory(initialData.videoCategory);
      setVideoCategoryArray(initialData.videoCategoryArray || '');
      setPermission(initialData.videoPermission);
      setDescription(initialData.videoDescription);
      setVideoDuration(initialData.videoLength);

      // Prepopulate groups if available
      if (initialData.videoGroup) {
        const existingGroups = JSON.parse(initialData.videoGroup);
        setSelectedGroups(existingGroups);
      }

      if (initialData.videoGroupEmail) {
        const existingEmails = JSON.parse(initialData.videoGroupEmail);
        setVideoGroupEmails(existingEmails);
      }

      // Handle existing video and thumbnail
      setThumbnail(initialData.videoThumbnail); 
      setVideo(initialData.videoPath);  
    }
  }, [initialData]);

  // group selectionm
  const groupOptions = [
    "R One Certified Trader",
    "R One Master Trader",
    "R One Elite Trader",
    "R One Dealer",
    "R One Trader Hub",
    "R One Member",
    "R One Basic",
  ];

  // Handle group selection
  const handleGroupSelection = (group: string) => {
    if (group && !selectedGroups.includes(group)) {
      setSelectedGroups([...selectedGroups, group]); 
    }
  }; 
  
  // Handle adding custom group from modal
  const handleAddCustomGroup = (groupName: string, emailList: string[]) => {
    handleGroupSelection(groupName); // Add the custom group
    setVideoGroupEmails((prevEmails) => ({ ...prevEmails, [groupName]: emailList })); 
  };

  const handleRemoveGroup = (group: string) => {
    setSelectedGroups(selectedGroups.filter(g => g !== group)); 
  };

  // Handle video file change and calculate duration
  const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setVideo(file);

      // Create a video element to calculate the duration
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);
      videoElement.onloadedmetadata = () => {
        const duration = videoElement.duration;
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);
        setVideoDuration(
          `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        );  // Format duration as HH:MM:SS
      };
    }
  };

  // Form submission handler
  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  
    if (video && thumbnail) {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('category', category);
      formData.append('categoryArray', videoCategoryArray.toString()); 
      formData.append('permission', permission);
      formData.append('description', description);
      formData.append('videoLength', videoDuration);
  
      // Combine selected predefined groups and custom groups
      const allSelectedGroups = [...selectedGroups]; 
      const customGroupNames = Object.keys(videoGroupEmails); 
  
      // Save only one instance of custom group names
      if (customGroupNames.length > 0) {
        allSelectedGroups.push(...customGroupNames.filter(group => !selectedGroups.includes(group))); 
      }
  
      formData.append('groups', JSON.stringify(allSelectedGroups)); 

      // Only save the email list in "groupEmails" without custom group names
      const emailsWithoutGroupNames = Object.values(videoGroupEmails).flat();
      formData.append('groupEmails', JSON.stringify(emailsWithoutGroupNames));
  
      formData.append('thumbnail', thumbnail);
      formData.append('video', video);
  
      handleSubmit(formData);
  
      // Clear form state after submission
      setTitle('');
      setCategory('');
      setVideoCategoryArray('');
      setPermission('');
      setSelectedGroups([]);
      setVideoGroupEmails({});
      setDescription('');
      setThumbnail(null);
      setVideo(null);
      setVideoDuration('');
  
      onClose(); 
    }
  };

  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-lg h-auto max-h-screen mx-4 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-cyan-800 mb-2">
          {initialData ? "Edit Your Video" : "Upload Your Video"}
        </h2>
        <form onSubmit={onFormSubmit}> 
         <div className="h-auto max-h-[550px] overflow-y-auto border rounded-xl p-4">
          
          {/* Video upload field */}
          <div className="mb-5">
            <div className="flex flex-row justify-between items-center">
              <label className="block text-cyan-800 font-semibold mb-2 mx-4">
                {initialData && !video ? "Current Video" : "Upload Video"}
              </label>
              {videoDuration && 
                <p className="text-cyan-800 text-xs mb-2">
                    Video Duration: <span className="text-rose-700">{videoDuration}</span>
                </p>
              }
            </div>
            <input
              type="file"
              accept="video/*"
              className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
              onChange={handleVideoChange}
            />
            {initialData && !video && (
              <div>
                <p className="text-sm text-gray-700">Current video: {initialData.videoPath}</p>
              </div>
            )}
          </div>

          {/* Thumbnail Upload Field */}
          <div className="mb-5">
            <label className="block text-cyan-800 font-semibold mb-2 mx-4">
              {initialData && !thumbnail ? "Current Thumbnail" : "Upload Thumbnail"}
            </label>
            <input
              type="file"
              accept="image/*"
              className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
              onChange={(e) => setThumbnail(e.target.files ? e.target.files[0] : null)}
            />
            {initialData && !thumbnail && (
              <div>
                <img src={initialData.videoThumbnail} alt="Thumbnail" className="w-24 h-24" />
              </div>
            )}
          </div>

          {/* Title field */}
          <div className="mb-5">
            <label className="block text-cyan-800 font-semibold mb-2 mx-4">Video Title</label>
            <input
              type="text"
              value={title}
              className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
              onChange={(e) => setTitle(e.target.value)}  // Set title
              placeholder="Enter video title"
              required
            />
          </div>

          {/* Category field */}
          <div className="mb-5">
            <div className="flex justify-between items-center">
              <label className="block text-cyan-800 font-semibold mb-2 mx-4">Video Category</label>
              <select
                value={category}
                className="block max-w-[300px] px-4 py-1 text-sm text-gray-700 mb-2"
                onChange={(e) => setCategory(e.target.value)}
                onClick={fetchVideos}
              >
                <option value="">select a category</option>
                  {categories.map((cat) => (
                    <option key={cat} value={cat}>{cat}</option>
                  ))}
              </select>
            </div>
            <div className="flex justify-normal items-center">
              <input
                type="text"
                value={category}
                className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
                onChange={(e) => setCategory(e.target.value)}  
                placeholder="Enter category"
                required
              />
              <div className="flex justify-normal items-center">
                <span className="mx-4">Array:</span>
                <input
                  type="number"
                  value={videoCategoryArray} 
                  min={0}
                  className="block w-full px-4 py-2 text-center text-sm text-gray-700 border border-gray-300 rounded-lg"
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setVideoCategoryArray(value >= 0 ? value : 0);
                  }}
                  placeholder="Array"
                  required
                />
              </div>
            </div>
          </div>

          {/* Permission field */}
          <div className="mb-5">
            <label className="block text-cyan-800 font-semibold mx-4">Video Permission</label>
            <select
              value={permission}
              className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
              onChange={(e) => setPermission(e.target.value)}
            >
              <option value="">Select a permission</option>
              <option value="Private">Private</option>
              <option value="Public">Public</option>
            </select>
          </div>

          {/* Group field (only visible if permission is Private) */}
          {permission === 'Private' && (
            <div className="mb-5">
                <div className="flex justify-between items-center mb-2">
                    <label className="block text-cyan-800 font-semibold mx-4">Private Group Access</label>
                    {/* Dropdown to select a group */}
                    <select
                        className="block max-w-[160px] text-sm text-gray-600 rounded-lg px-4 py-1"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === 'custom') {
                                setIsModalOpen(true);
                            } else {
                                handleGroupSelection(value);
                            }
                        }}
                        value=""
                    >
                    <option value="" disabled>Select a group</option>
                        {groupOptions.map(group => (
                            <option key={group} value={group}>{group}</option>
                        ))}
                        <option value="custom">Add custom group</option>
                    </select>
                </div>

                {/* Display empty state if no groups are added */}
                {selectedGroups.length === 0 ? (
                    <div className=" text-center border-dashed border-4 border-gray-800 border-opacity-20 rounded-lg p-4">
                        <span className="text-white text-sm font-semibold bg-gray-300 px-4 py-1 rounded-lg">
                            Add a group
                        </span>
                    </div>
                ) : (
                    <div className="ml-4">
                        <ul className="mb-2">
                            {selectedGroups.map(group => (
                                <li key={group} className="text-sm text-cyan-800 flex justify-normal items-center">
                                    <FaMinusSquare 
                                      className="mr-2 cursor-pointer" 
                                      onClick={() => handleRemoveGroup(group)}
                                    /> 
                                    {group}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            )}

          {/* Description field */}
          <div className="mb-5">
            <label className="block text-cyan-800 font-semibold mb-2 mx-4">Video Description</label>
            <textarea
              value={description}
              className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
              onChange={(e) => setDescription(e.target.value)} 
              placeholder="Enter video description"
              rows={3}
              required
            />
          </div>
         </div>

          {/* Button section */}
          <div className="flex justify-between text-sm mt-4">
            <button 
              type="button"
              onClick={onClose} 
              className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800 hover:bg-cyan-800 hover:text-white"
            >
              Cancel
            </button>
            <button 
              type="submit"  // Submit the form here
              className="bg-cyan-800 text-white px-4 py-2 rounded-lg shadow flex justify-between items-center"
            >
              {initialData ? "Save" : "Upload"}
              <BsUpload className="ml-2" />
            </button>
          </div>
        </form>
      </div>

      {/* VideoGroupModal for custom group */}
      <VideoGroupModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        handleAddGroup={handleAddCustomGroup}
      />
    </div>
  );
};

export default VideoUpload;

// // udpate code --> 11 oct 2024
// // src/components/admin/VideoSetting/VideoUpload.tsx

// import React, { useState, useEffect } from 'react';
// import { BsUpload } from 'react-icons/bs';
// import { FaMinusSquare } from "react-icons/fa";
// import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload';
// import VideoGroupModal from './VideoGroupModal';

// interface VideoUploadProps {
//   isOpen: boolean;
//   onClose: () => void;
//   handleSubmit: (data: FormData) => void;
//   initialData?: any;  
// }

// const VideoUpload: React.FC<VideoUploadProps> = ({ isOpen, onClose, handleSubmit, initialData }) => {
//   const [title, setTitle] = useState('');
//   const [category, setCategory] = useState('');
//   const [videoCategoryArray, setVideoCategoryArray] = useState<number | ''>('');
//   const [permission, setPermission] = useState('');
//   const [description, setDescription] = useState('');
//   const [thumbnail, setThumbnail] = useState<File | null>(null);
//   const [video, setVideo] = useState<File | null>(null);
//   const [videoDuration, setVideoDuration] = useState<string>('');
//   const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
//   const [videoGroupEmails, setVideoGroupEmails] = useState<{ [key: string]: string[] }>({}); 
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // Fetch categories from the useVideoUpload hook
//   const { categories } = useVideoUpload();

//   // Populate form fields with existing data if in edit mode
//   useEffect(() => {
//     if (initialData) {
//       setTitle(initialData.videoTitle);
//       setCategory(initialData.videoCategory);
//       setVideoCategoryArray(initialData.videoCategoryArray || '');
//       setPermission(initialData.videoPermission);
//       setDescription(initialData.videoDescription);
//       setVideoDuration(initialData.videoLength);

//       // Prepopulate groups if available
//       if (initialData.videoGroup) {
//         const existingGroups = JSON.parse(initialData.videoGroup);
//         setSelectedGroups(existingGroups);
//       }

//       if (initialData.videoGroupEmail) {
//         const existingEmails = JSON.parse(initialData.videoGroupEmail);
//         setVideoGroupEmails(existingEmails);
//       }

//       // Handle existing video and thumbnail
//       setThumbnail(initialData.videoThumbnail); 
//       setVideo(initialData.videoPath);  
//     }
//   }, [initialData]);

//   // group selectionm
//   const groupOptions = [
//     "R One Certified Trader",
//     "R One Master Trader",
//     "R One Elite Trader",
//     "R One Dealer",
//     "R One Trader Hub",
//     "R One Member",
//     "R One Basic",
//   ];

//   // Handle group selection
//   const handleGroupSelection = (group: string) => {
//     if (group && !selectedGroups.includes(group)) {
//       setSelectedGroups([...selectedGroups, group]); 
//     }
//   }; 
  
//   // Handle adding custom group from modal
//   const handleAddCustomGroup = (groupName: string, emailList: string[]) => {
//     handleGroupSelection(groupName); // Add the custom group
//     setVideoGroupEmails((prevEmails) => ({ ...prevEmails, [groupName]: emailList })); 
//   };

//   const handleRemoveGroup = (group: string) => {
//     setSelectedGroups(selectedGroups.filter(g => g !== group)); 
//   };

//   // Handle video file change and calculate duration
//   const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files ? e.target.files[0] : null;
//     if (file) {
//       setVideo(file);

//       // Create a video element to calculate the duration
//       const videoElement = document.createElement('video');
//       videoElement.src = URL.createObjectURL(file);
//       videoElement.onloadedmetadata = () => {
//         const duration = videoElement.duration;
//         const hours = Math.floor(duration / 3600);
//         const minutes = Math.floor((duration % 3600) / 60);
//         const seconds = Math.floor(duration % 60);
//         setVideoDuration(
//           `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
//         );  // Format duration as HH:MM:SS
//       };
//     }
//   };

//   // Form submission handler
//   const onFormSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
  
//     if (video && thumbnail) {
//       const formData = new FormData();
//       formData.append('title', title);
//       formData.append('category', category);
//       formData.append('categoryArray', videoCategoryArray.toString()); 
//       formData.append('permission', permission);
//       formData.append('description', description);
//       formData.append('videoLength', videoDuration);
  
//       // If there's any custom group, save the custom group name and emails.
//       if (Object.keys(videoGroupEmails).length > 0) {
//         formData.append('groups', JSON.stringify(Object.keys(videoGroupEmails))); 
//         formData.append('groupEmails', JSON.stringify(videoGroupEmails));
//       } else {
//         // If no custom group, save the selected predefined groups.
//         formData.append('groups', JSON.stringify(selectedGroups)); 
//         formData.append('groupEmails', JSON.stringify({})); 
//       }
  
//       formData.append('thumbnail', thumbnail);
//       formData.append('video', video);
  
//       handleSubmit(formData);
  
//       // Clear form state after submission
//       setTitle('');
//       setCategory('');
//       setVideoCategoryArray('');
//       setPermission('Private');
//       setSelectedGroups([]);
//       setVideoGroupEmails({});
//       setDescription('');
//       setThumbnail(null);
//       setVideo(null);
//       setVideoDuration('');
  
//       onClose(); 
//     } else {
//       alert('Please select both a video and a thumbnail.');
//     }
//   };

//   if (!isOpen) return null;
  
//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
//       <div className="bg-white w-full max-w-lg h-auto max-h-screen mx-4 p-6 rounded-lg shadow-lg">
//         <h2 className="text-2xl font-semibold text-cyan-800 mb-2">
//           {initialData ? "Edit Your Video" : "Upload Your Video"}
//         </h2>
//         <form onSubmit={onFormSubmit}> 
//          <div className="h-auto max-h-[550px] overflow-y-auto border rounded-xl p-4">
          
//           {/* Video upload field */}
//           <div className="mb-5">
//             <div className="flex flex-row justify-between items-center">
//               <label className="block text-cyan-800 font-semibold mb-2 mx-4">
//                 {initialData && !video ? "Current Video" : "Upload Video"}
//               </label>
//               {videoDuration && 
//                 <p className="text-cyan-800 text-xs mb-2">
//                     Video Duration: <span className="text-rose-700">{videoDuration}</span>
//                 </p>
//               }
//             </div>
//             <input
//               type="file"
//               accept="video/*"
//               className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//               onChange={handleVideoChange}
//             />
//             {initialData && !video && (
//               <div>
//                 <p className="text-sm text-gray-700">Current video: {initialData.videoPath}</p>
//               </div>
//             )}
//           </div>

//           {/* Thumbnail Upload Field */}
//           <div className="mb-5">
//             <label className="block text-cyan-800 font-semibold mb-2 mx-4">
//               {initialData && !thumbnail ? "Current Thumbnail" : "Upload Thumbnail"}
//             </label>
//             <input
//               type="file"
//               accept="image/*"
//               className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//               onChange={(e) => setThumbnail(e.target.files ? e.target.files[0] : null)}
//             />
//             {initialData && !thumbnail && (
//               <div>
//                 <img src={initialData.videoThumbnail} alt="Thumbnail" className="w-24 h-24" />
//               </div>
//             )}
//           </div>

//           {/* Title field */}
//           <div className="mb-5">
//             <label className="block text-cyan-800 font-semibold mb-2 mx-4">Video Title</label>
//             <input
//               type="text"
//               value={title}
//               className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//               onChange={(e) => setTitle(e.target.value)}  // Set title
//               placeholder="Enter video title"
//               required
//             />
//           </div>

//           {/* Category field */}
//           <div className="mb-5">
//             <div className="flex justify-between items-center">
//               <label className="block text-cyan-800 font-semibold mb-2 mx-4">Video Category</label>
//               <select
//                 value={category}
//                 className="block max-w-[300px] px-4 py-1 text-sm text-gray-700 mb-2"
//                 onChange={(e) => setCategory(e.target.value)}
//               >
//                 <option value="">select a category</option>
//                   {categories.map((cat) => (
//                     <option key={cat} value={cat}>{cat}</option>
//                   ))}
//               </select>
//             </div>
//             <div className="flex justify-normal items-center">
//               <input
//                 type="text"
//                 value={category}
//                 className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                 onChange={(e) => setCategory(e.target.value)}  
//                 placeholder="Enter category"
//                 required
//               />
//               <div className="flex justify-normal items-center">
//                 <span className="mx-4">Array:</span>
//                 <input
//                   type="number"
//                   value={videoCategoryArray} 
//                   min={0}
//                   className="block w-full px-4 py-2 text-center text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   onChange={(e) => {
//                     const value = Number(e.target.value);
//                     setVideoCategoryArray(value >= 0 ? value : 0);
//                   }}
//                   placeholder="Array"
//                   required
//                 />
//               </div>
//             </div>
//           </div>

//           {/* Permission field */}
//           <div className="mb-5">
//             <label className="block text-cyan-800 font-semibold mx-4">Video Permission</label>
//             <select
//               value={permission}
//               className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//               onChange={(e) => setPermission(e.target.value)}
//             >
//               <option value="">Select a permission</option>
//               <option value="Private">Private</option>
//               <option value="Public">Public</option>
//             </select>
//           </div>

//           {/* Group field (only visible if permission is Private) */}
//           {permission === 'Private' && (
//             <div className="mb-5">
//                 <div className="flex justify-between items-center mb-2">
//                     <label className="block text-cyan-800 font-semibold mx-4">Private Group Access</label>
//                     {/* Dropdown to select a group */}
//                     <select
//                         className="block max-w-[160px] text-sm text-gray-600 rounded-lg px-4 py-1"
//                         onChange={(e) => {
//                             const value = e.target.value;
//                             if (value === 'custom') {
//                                 setIsModalOpen(true);
//                             } else {
//                                 handleGroupSelection(value);
//                             }
//                         }}
//                         value=""
//                     >
//                     <option value="" disabled>Select a group</option>
//                         {groupOptions.map(group => (
//                             <option key={group} value={group}>{group}</option>
//                         ))}
//                         <option value="custom">Add custom group</option>
//                     </select>
//                 </div>

//                 {/* Display empty state if no groups are added */}
//                 {selectedGroups.length === 0 ? (
//                     <div className=" text-center border-dashed border-4 border-gray-800 border-opacity-20 rounded-lg p-4">
//                         <span className="text-white text-sm font-semibold bg-gray-300 px-4 py-1 rounded-lg">
//                             Add a group
//                         </span>
//                     </div>
//                 ) : (
//                     <div className="ml-4">
//                         <ul className="mb-2">
//                             {selectedGroups.map(group => (
//                                 <li key={group} className="text-sm text-cyan-800 flex justify-normal items-center">
//                                     <FaMinusSquare 
//                                       className="mr-2 cursor-pointer" 
//                                       onClick={() => handleRemoveGroup(group)}
//                                     /> 
//                                     {group}
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 )}
//             </div>
//             )}

//           {/* Description field */}
//           <div className="mb-5">
//             <label className="block text-cyan-800 font-semibold mb-2 mx-4">Video Description</label>
//             <textarea
//               value={description}
//               className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//               onChange={(e) => setDescription(e.target.value)} 
//               placeholder="Enter video description"
//               rows={3}
//               required
//             />
//           </div>
//          </div>

//           {/* Button section */}
//           <div className="flex justify-between text-sm mt-4">
//             <button 
//               type="button"
//               onClick={onClose} 
//               className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800 hover:bg-cyan-800 hover:text-white"
//             >
//               Cancel
//             </button>
//             <button 
//               type="submit"  // Submit the form here
//               className="bg-cyan-800 text-white px-4 py-2 rounded-lg shadow flex justify-between items-center"
//             >
//               {initialData ? "Save" : "Upload"}
//               <BsUpload className="ml-2" />
//             </button>
//           </div>
//         </form>
//       </div>

//       {/* VideoGroupModal for custom group */}
//       <VideoGroupModal
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         handleAddGroup={handleAddCustomGroup}
//       />
//     </div>
//   );
// };

// export default VideoUpload;