// src/components/PasswordResetUpdate.tsx

import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import useUserDashboard from '../hooks/Dashboard/useUserDashboard';

const PasswordResetModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { resetPassword } = useUserDashboard(); 
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .matches(/[A-Za-z]/, 'Password must contain at least one letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[~!@#$%^&*()\-_={}[\];:'",<.>/?]/, 'Password must contain at least one symbol')
        .min(8, 'Password must be at least 8 characters')
        .required('Please enter a new password'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
        .required('Please confirm your new password'),
    }),
    onSubmit: async (values) => {
      if (values.newPassword === "abcd1234") {
        setError("New password cannot be the default password.");
      } else {
        await resetPassword(values.newPassword);
        onClose();
      }
    },
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <h2 className="text-lg font-semibold mb-4 text-center text-cyan-800">Reset Password</h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1 mx-2">New Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full p-2 border rounded"
              />
              <span
                className="absolute right-3 top-3 cursor-pointer text-cyan-800"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <p className="text-red-500 text-sm mt-1">{formik.errors.newPassword}</p>
            ) : null}
          </div>

          <div className="mb-4">
            <label className="block mb-1 mx-2">Confirm New Password</label>
            <div className="relative">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full p-2 border rounded"
              />
              <span
                className="absolute right-3 top-3 cursor-pointer text-cyan-800"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="text-red-500 text-sm mt-1">{formik.errors.confirmPassword}</p>
            ) : null}
          </div>

          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-cyan-800 text-white rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetModal;

