// // src/components/admin/AdminSetting/AdminUsersTable.tsx

import React, { useState } from 'react';
import { CgSidebarOpen } from "react-icons/cg";
import { useAdminUsers, AdminUser, AssignUser } from '../../../hooks/AdminDashboard/useAdminRoles';
import AdminAddAccountModal from './AdminAddAccountModal';
import AdminAssignMemberModal from './AdminAssignMemberModal';

const AdminRole: React.FC = () => {
  const { 
    adminUsers, assignUsers, loading, error, 
    fetchAdminUsers, deleteAdminUsers, 
    fetchAssignUsers, assignMembers, deleteAssignUsers, updateAssignUser
  } = useAdminUsers(); 
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]); 
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [isAssignMemberModalOpen, setIsAssignMemberModalOpen] = useState(false); 
  const [isToggleChecked, setIsToggleChecked] = useState(false);
  const [editingUser, setEditingUser] = useState<AdminUser | null>(null);
  const [isEmailListModalOpen, setIsEmailListModalOpen] = useState(false);
  const [selectedAssignEmails, setSelectedAssignEmails] = useState<string[]>([]);
  const [isAssignToggleChecked, setIsAssignToggleChecked] = useState(false);
  const [selectedAssignUsers, setSelectedAssignUsers] = useState<string[]>([]);
  const [editingAssignUser, setEditingAssignUser] = useState<AssignUser | null>(null);

  // Toggle selection of a single admin user
  const toggleSelectUser = (roleId: string) => {
    setSelectedUsers(prev =>
      prev.includes(roleId) ? prev.filter(id => id !== roleId) : [...prev, roleId]
    );
  };

  // Toggle selection of all admin users
  const toggleSelectAll = () => {
    if (selectedUsers.length === adminUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(adminUsers.map(user => user.roleId)); 
    }
  };

  // Toggle checkbox visibility
  const handleToggleCheckboxes = () => {
    setIsToggleChecked(prev => !prev); 
  };

  // Open modal to add new account
  const handleAddAccountClick = async () => {
    setEditingUser(null); 
    setIsModalOpen(true);
  };

  // Open modal for editing user on double-click
  const handleRowDoubleClick = (user: AdminUser) => {
    setEditingUser(user); 
    setIsModalOpen(true);
  };

  // Close modal
  const handleCloseModal = async () => {
    setIsModalOpen(false);
    await fetchAdminUsers();
  };

  // Remove selected admin users
  const handleRemoveAccount = async () => {
    if (selectedUsers.length === 0) return;
    await deleteAdminUsers(selectedUsers); 
    setSelectedUsers([]); 
    await fetchAdminUsers();
    setIsToggleChecked(false);
  };

  // ------------------ assign member -------------------- //
  // Open modal for assigning members
  const handleAssignMemberClick = () => {
    setIsAssignMemberModalOpen(true);
  };

  const handleCloseAssignMemberModal = () => {
    setIsAssignMemberModalOpen(false);
  };

  // Toggle selection of a single assign user
  const toggleAssignSelectUser = (assignId: string) => {
    setSelectedAssignUsers(prev =>
      prev.includes(assignId) ? prev.filter(id => id !== assignId) : [...prev, assignId]
    );
  };
  

  // Toggle checkbox visibility for Assign Users Table
  const handleAssignToggleCheckboxes = () => {
    setIsAssignToggleChecked(prev => !prev); 
    setSelectedAssignUsers([]); // Reset selected users when toggling
  };

  const handleAssignMembers = async (formData: any) => {
    try {
      if (editingAssignUser) {
        // If editing, update the existing assign user
        await updateAssignUser(formData); 
      } else {
        // Otherwise, create a new assign user
        await assignMembers(formData);
      }
      fetchAssignUsers();
    } catch (error) {
      console.error('Error assigning members:', error);
    } finally {
      setEditingAssignUser(null);
    }
  };

  // Function to handle removing assign users
  const handleRemoveAssign = async () => {
    if (selectedAssignUsers.length === 0) return; 
    await deleteAssignUsers(selectedAssignUsers); 
    setSelectedAssignUsers([]);
  };

  const handleShowEmailList = (emails: string[]) => {
    setSelectedAssignEmails(emails);
    setIsEmailListModalOpen(true);
  };
  
  const handleCloseEmailListModal = () => {
    setIsEmailListModalOpen(false);
  };

  const handleAssignRowDoubleClick = (assign: AssignUser) => {
    setIsAssignMemberModalOpen(true);
    setEditingAssignUser(assign);
  };

  return (
    <div className="p-2">
      <div className="text-2xl font-bold mb-4">Admin Role Manager</div>

      <div className="flex justify-end items-center mb-4">
        <input
          type="text"
          placeholder="Search by any related keywords"
          className="w-auto min-w-[300px] px-4 py-2 border rounded-md text-sm"
        />
        <div className="flex justify-end space-x-2 text-sm w-full">
          <button 
            className="flex justify-normal items-center text-cyan-800 font-semibold mr-4"
            onClick={handleToggleCheckboxes} 
          >
            <CgSidebarOpen className="mr-2 text-xl"/>
            Toggle
          </button>
          <button
            className="bg-cyan-800 text-white px-4 py-2 rounded-md"
            onClick={handleAddAccountClick}
          >
            Add Account
          </button>
          <button
            className="bg-rose-600 text-white px-4 py-2 rounded-md"
            onClick={handleRemoveAccount}
          >
            Remove Account
          </button>
        </div>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <>
          {/* Admin Users Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white text-sm">
              <thead>
                <tr className="text-center bg-gray-100">
                  {isToggleChecked && (
                    <th className="px-6 py-3 border-b">
                      <input
                        type="checkbox"
                        onChange={toggleSelectAll}
                        checked={selectedUsers.length === adminUsers.length}
                      />
                    </th>
                  )}
                  <th className="px-6 py-3 border-b">id</th>
                  <th className="px-6 py-3 border-b">Account</th>
                  <th className="px-6 py-3 border-b">Email</th>
                  <th className="px-6 py-3 border-b">Role</th>
                  <th className="px-6 py-3 border-b">Role Assign</th>
                  <th className="px-6 py-3 border-b">Status</th>
                </tr>
              </thead>
              <tbody>
                {adminUsers.length > 0 ? (
                  adminUsers.map(user => (
                    <tr
                      key={user.roleId}
                      className={`text-center text-xs hover:bg-gray-50 ${
                        selectedUsers.includes(user.roleId) ? 'bg-gray-200' : ''
                      }`}
                      onDoubleClick={() => handleRowDoubleClick(user)}
                    >
                      {isToggleChecked && (
                        <td className="px-2 py-2 border-b">
                          <input
                            type="checkbox"
                            className="accent-cyan-800 hover:accent-slate-500"
                            checked={selectedUsers.includes(user.roleId)}
                            onChange={() => toggleSelectUser(user.roleId)}
                          />
                        </td>
                      )}
                      <td className="px-2 py-3 border-b">{user.roleId}</td>
                      <td className="px-6 py-3 border-b">{user.name}</td>
                      <td className="px-6 py-3 border-b">{user.email}</td>
                      <td className="px-6 py-3 border-b">{user.role}</td>
                      <td className="px-6 py-3 border-b">
                        {user.roleAssign.split(', ').length > 2 
                          ? `${user.roleAssign.split(', ')[0]} +${user.roleAssign.split(', ').length - 1}`
                          : user.roleAssign
                        }
                      </td>
                      <td className="px-6 py-3 border-b">
                        <span
                          className={`font-medium ${
                            user.status === 'Active' ? 'text-green-600' : 'text-red-600'
                          }`}
                        >
                          {user.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="px-6 py-4 border-4 border-dashed h-[200px] text-center"
                    >
                      <span className="px-8 py-2 text-gray-300 text-sm">
                        No admin account found
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Assign Users Table */}
          <div className="overflow-x-auto mt-8">
            <h3 className="text-lg font-bold mb-4">Member Assign Manager</h3>

            <div className="flex justify-end items-center mb-4">
              <input
                type="text"
                placeholder="Search by any related keywords"
                className="w-auto min-w-[300px] px-4 py-2 border rounded-md text-sm"
              />
              <div className="flex justify-end space-x-2 text-sm w-full">
                <button 
                  className="flex justify-normal items-center text-cyan-800 font-semibold mr-4"
                  onClick={handleAssignToggleCheckboxes} 
                >
                  <CgSidebarOpen className="mr-2 text-xl"/>
                  Toggle
                </button>
                <button
                  className="bg-cyan-800 text-white px-4 py-2 rounded-md"
                  onClick={handleAssignMemberClick}
                >
                  Assign Member
                </button>
                <button
                  className="bg-rose-600 text-white px-4 py-2 rounded-md"
                  onClick={handleRemoveAssign}
                >
                  Remove Assign
                </button>
              </div>
            </div>

            <table className="min-w-full bg-white text-sm">
              <thead>
                <tr className="text-center bg-gray-100">
                  {isAssignToggleChecked && (
                    <th className="px-6 py-3 border-b">
                      <input
                        type="checkbox"
                        onChange={() => setSelectedAssignUsers(assignUsers.length === selectedAssignUsers.length ? [] : assignUsers.map(user => user.assignId))}
                        checked={selectedAssignUsers.length === assignUsers.length && assignUsers.length > 0}
                      />
                    </th>
                  )}
                  <th className="px-6 py-3 border-b">id</th>
                  <th className="px-6 py-3 border-b">Assign Account</th>
                  <th className="px-6 py-3 border-b">Assign Member</th>
                  <th className="px-6 py-3 border-b">Regional Group</th>
                  <th className="px-6 py-3 border-b">Status</th>
                </tr>
              </thead>
              <tbody>
                {assignUsers.length > 0 ? (
                  assignUsers.map(assign => (
                    <tr 
                      key={assign.assignId} 
                      className={`text-center text-xs hover:bg-gray-50 ${selectedAssignUsers.includes(assign.assignId) ? 'bg-gray-200' : ''}`}
                      onDoubleClick={() => handleAssignRowDoubleClick(assign)}
                    >
                      {isAssignToggleChecked && (
                        <td className="px-2 py-2 border-b">
                          <input
                            type="checkbox"
                            className="accent-cyan-800 hover:accent-slate-500"
                            checked={selectedAssignUsers.includes(assign.assignId)}
                            onChange={() => toggleAssignSelectUser(assign.assignId)}
                          />
                        </td>
                      )}
                      <td className="px-6 py-4 border-b">{assign.assignId}</td>
                      <td className="px-6 py-4 border-b">{assign.assignAccount}</td>
                      <td className="px-6 py-3 border-b hover:underline cursor-pointer" 
                        onClick={() => handleShowEmailList(assign.assignMember.split(', '))}
                      >
                        {assign.assignMember.split(', ').length > 1
                          ? `${assign.assignMember.split(', ')[0]} +${assign.assignMember.split(', ').length - 1}`
                          : assign.assignMember
                        }
                      </td>
                      <td className="px-6 py-4 border-b">{assign.assignGroup}</td>
                      <td className="px-6 py-4 border-b">
                        <span
                          className={`font-medium ${
                            assign.assignStatus === 'Active' ? 'text-green-600' : 'text-red-600'
                          }`}
                        >
                          {assign.assignStatus}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="px-6 py-4 border-4 border-dashed h-[200px] text-center"
                    >
                      <span className="px-8 py-2 text-gray-300">No member assignments found</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* Add Account Modal */}
      {isModalOpen && 
        <AdminAddAccountModal 
          onClose={handleCloseModal}
          initialData={editingUser} 
        />
      }

      {/* assigning members modal */}
      {isAssignMemberModalOpen && (
        <AdminAssignMemberModal
          onClose={handleCloseAssignMemberModal}
          handleAssignMembers={handleAssignMembers}
          initialData={editingAssignUser || undefined}
          isEdit={!!editingAssignUser}
        />
      )}

      {/* Assign Member Email list */}
      {isEmailListModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-md">
            <h3 className="font-bold text-cyan-800 text-sm border-2 border-cyan-800 px-6 py-2 rounded-full mb-2">
              Assigned Emails
            </h3>
            <ul>
              {selectedAssignEmails.map((email, index) => (
                <li key={index} className="text-sm">{email}</li>
              ))}
            </ul>
            <button className="bg-cyan-800 rounded-md text-white px-4 py-1 mt-4" onClick={handleCloseEmailListModal}>Close</button>
          </div>
        </div>
      )}

    </div>
  );
};

export default AdminRole;

// // udpate code --> 28 oct 2024
// // // src/components/admin/AdminSetting/AdminUsersTable.tsx

// import React, { useState } from 'react';
// import { CgSidebarOpen } from "react-icons/cg";
// import { useAdminUsers, AdminUser } from '../../../hooks/AdminDashboard/useAdminRoles';
// import AdminAddAccountModal from './AdminAddAccountModal';
// import AdminAssignMemberModal from './AdminAssignMemberModal';

// const AdminRole: React.FC = () => {
//   const { 
//     adminUsers, assignUsers, loading, error, 
//     fetchAdminUsers, deleteAdminUsers, 
//     fetchAssignUsers, assignMembers, deleteAssignUsers 
//   } = useAdminUsers(); 
//   const [selectedUsers, setSelectedUsers] = useState<string[]>([]); 
//   const [isModalOpen, setIsModalOpen] = useState(false); 
//   const [isAssignMemberModalOpen, setIsAssignMemberModalOpen] = useState(false); 
//   const [isToggleChecked, setIsToggleChecked] = useState(false);
//   const [editingUser, setEditingUser] = useState<AdminUser | null>(null);
//   const [isEmailListModalOpen, setIsEmailListModalOpen] = useState(false);
//   const [selectedAssignEmails, setSelectedAssignEmails] = useState<string[]>([]);
//   const [isAssignToggleChecked, setIsAssignToggleChecked] = useState(false);
//   const [selectedAssignUsers, setSelectedAssignUsers] = useState<string[]>([]);

//   // Toggle selection of a single admin user
//   const toggleSelectUser = (roleId: string) => {
//     setSelectedUsers(prev =>
//       prev.includes(roleId) ? prev.filter(id => id !== roleId) : [...prev, roleId]
//     );
//   };

//   // Toggle selection of all admin users
//   const toggleSelectAll = () => {
//     if (selectedUsers.length === adminUsers.length) {
//       setSelectedUsers([]);
//     } else {
//       setSelectedUsers(adminUsers.map(user => user.roleId)); 
//     }
//   };

//   // Toggle checkbox visibility
//   const handleToggleCheckboxes = () => {
//     setIsToggleChecked(prev => !prev); 
//   };

//   // Open modal to add new account
//   const handleAddAccountClick = async () => {
//     setEditingUser(null); 
//     setIsModalOpen(true);
//   };

//   // Open modal for editing user on double-click
//   const handleRowDoubleClick = (user: AdminUser) => {
//     setEditingUser(user); 
//     setIsModalOpen(true);
//   };

//   // Close modal
//   const handleCloseModal = async () => {
//     setIsModalOpen(false);
//     await fetchAdminUsers();
//   };

//   // Remove selected admin users
//   const handleRemoveAccount = async () => {
//     if (selectedUsers.length === 0) return;
//     await deleteAdminUsers(selectedUsers); 
//     setSelectedUsers([]); 
//     await fetchAdminUsers();
//     setIsToggleChecked(false);
//   };

//   // ------------------ assign member -------------------- //
//   // Open modal for assigning members
//   const handleAssignMemberClick = () => {
//     setIsAssignMemberModalOpen(true);
//   };

//   const handleCloseAssignMemberModal = () => {
//     setIsAssignMemberModalOpen(false);
//   };

//   // Toggle selection of a single assign user
//   const toggleAssignSelectUser = (assignId: string) => {
//     setSelectedAssignUsers(prev =>
//       prev.includes(assignId) ? prev.filter(id => id !== assignId) : [...prev, assignId]
//     );
//   };
  

//   // Toggle checkbox visibility for Assign Users Table
//   const handleAssignToggleCheckboxes = () => {
//     setIsAssignToggleChecked(prev => !prev); 
//     setSelectedAssignUsers([]); // Reset selected users when toggling
//   };

//   const handleAssignMembers = async (formData: any) => {
//     try {
//       await assignMembers(formData);
//       fetchAssignUsers(); 
//     } catch (error) {
//       console.error('Error assigning members:', error);
//     }
//   };

//   // Function to handle removing assign users
//   const handleRemoveAssign = async () => {
//     if (selectedAssignUsers.length === 0) return; 
//     await deleteAssignUsers(selectedAssignUsers); 
//     setSelectedAssignUsers([]);
//   };

//   const handleShowEmailList = (emails: string[]) => {
//     setSelectedAssignEmails(emails);
//     setIsEmailListModalOpen(true);
//   };
  
//   const handleCloseEmailListModal = () => {
//     setIsEmailListModalOpen(false);
//   };

//   return (
//     <div className="p-2">
//       <div className="text-2xl font-bold mb-4">Admin Role Manager</div>

//       <div className="flex justify-end items-center mb-4">
//         <input
//           type="text"
//           placeholder="Search by any related keywords"
//           className="w-auto min-w-[300px] px-4 py-2 border rounded-md text-sm"
//         />
//         <div className="flex justify-end space-x-2 text-sm w-full">
//           <button 
//             className="flex justify-normal items-center text-cyan-800 font-semibold mr-4"
//             onClick={handleToggleCheckboxes} 
//           >
//             <CgSidebarOpen className="mr-2 text-xl"/>
//             Toggle
//           </button>
//           <button
//             className="bg-cyan-800 text-white px-4 py-2 rounded-md"
//             onClick={handleAddAccountClick}
//           >
//             Add Account
//           </button>
//           <button
//             className="bg-rose-600 text-white px-4 py-2 rounded-md"
//             onClick={handleRemoveAccount}
//           >
//             Remove Account
//           </button>
//         </div>
//       </div>

//       {loading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <p className="text-red-600">{error}</p>
//       ) : (
//         <>
//           {/* Admin Users Table */}
//           <div className="overflow-x-auto">
//             <table className="min-w-full bg-white text-sm">
//               <thead>
//                 <tr className="text-center bg-gray-100">
//                   {isToggleChecked && (
//                     <th className="px-6 py-3 border-b">
//                       <input
//                         type="checkbox"
//                         onChange={toggleSelectAll}
//                         checked={selectedUsers.length === adminUsers.length}
//                       />
//                     </th>
//                   )}
//                   <th className="px-6 py-3 border-b">id</th>
//                   <th className="px-6 py-3 border-b">Account</th>
//                   <th className="px-6 py-3 border-b">Email</th>
//                   <th className="px-6 py-3 border-b">Role</th>
//                   <th className="px-6 py-3 border-b">Role Assign</th>
//                   <th className="px-6 py-3 border-b">Status</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {adminUsers.length > 0 ? (
//                   adminUsers.map(user => (
//                     <tr
//                       key={user.roleId}
//                       className={`text-center text-xs hover:bg-gray-50 ${
//                         selectedUsers.includes(user.roleId) ? 'bg-gray-200' : ''
//                       }`}
//                       onDoubleClick={() => handleRowDoubleClick(user)}
//                     >
//                       {isToggleChecked && (
//                         <td className="px-2 py-2 border-b">
//                           <input
//                             type="checkbox"
//                             className="accent-cyan-800 hover:accent-slate-500"
//                             checked={selectedUsers.includes(user.roleId)}
//                             onChange={() => toggleSelectUser(user.roleId)}
//                           />
//                         </td>
//                       )}
//                       <td className="px-2 py-3 border-b">{user.roleId}</td>
//                       <td className="px-6 py-3 border-b">{user.name}</td>
//                       <td className="px-6 py-3 border-b">{user.email}</td>
//                       <td className="px-6 py-3 border-b">{user.role}</td>
//                       <td className="px-6 py-3 border-b">
//                         {user.roleAssign.split(', ').length > 2 
//                           ? `${user.roleAssign.split(', ')[0]} +${user.roleAssign.split(', ').length - 1}`
//                           : user.roleAssign
//                         }
//                       </td>
//                       <td className="px-6 py-3 border-b">
//                         <span
//                           className={`font-medium ${
//                             user.status === 'Active' ? 'text-green-600' : 'text-red-600'
//                           }`}
//                         >
//                           {user.status}
//                         </span>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td
//                       colSpan={7}
//                       className="px-6 py-4 border-4 border-dashed h-[200px] text-center"
//                     >
//                       <span className="px-8 py-2 text-gray-300 text-sm">
//                         No admin account found
//                       </span>
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>

//           {/* Assign Users Table */}
//           <div className="overflow-x-auto mt-8">
//             <h3 className="text-lg font-bold mb-4">Member Assign Manager</h3>

//             <div className="flex justify-end items-center mb-4">
//               <input
//                 type="text"
//                 placeholder="Search by any related keywords"
//                 className="w-auto min-w-[300px] px-4 py-2 border rounded-md text-sm"
//               />
//               <div className="flex justify-end space-x-2 text-sm w-full">
//                 <button 
//                   className="flex justify-normal items-center text-cyan-800 font-semibold mr-4"
//                   onClick={handleAssignToggleCheckboxes} 
//                 >
//                   <CgSidebarOpen className="mr-2 text-xl"/>
//                   Toggle
//                 </button>
//                 <button
//                   className="bg-cyan-800 text-white px-4 py-2 rounded-md"
//                   onClick={handleAssignMemberClick}
//                 >
//                   Assign Member
//                 </button>
//                 <button
//                   className="bg-rose-600 text-white px-4 py-2 rounded-md"
//                   onClick={handleRemoveAssign}
//                 >
//                   Remove Assign
//                 </button>
//               </div>
//             </div>

//             <table className="min-w-full bg-white text-sm">
//               <thead>
//                 <tr className="text-center bg-gray-100">
//                   {isAssignToggleChecked && (
//                     <th className="px-6 py-3 border-b">
//                       <input
//                         type="checkbox"
//                         onChange={() => setSelectedAssignUsers(assignUsers.length === selectedAssignUsers.length ? [] : assignUsers.map(user => user.assignId))}
//                         checked={selectedAssignUsers.length === assignUsers.length && assignUsers.length > 0}
//                       />
//                     </th>
//                   )}
//                   <th className="px-6 py-3 border-b">id</th>
//                   <th className="px-6 py-3 border-b">Assign Account</th>
//                   <th className="px-6 py-3 border-b">Assign Member</th>
//                   <th className="px-6 py-3 border-b">Regional Group</th>
//                   <th className="px-6 py-3 border-b">Status</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {assignUsers.length > 0 ? (
//                   assignUsers.map(assign => (
//                     <tr key={assign.assignId} className={`text-center text-xs hover:bg-gray-50 ${selectedAssignUsers.includes(assign.assignId) ? 'bg-gray-200' : ''}`}>
//                       {isAssignToggleChecked && (
//                         <td className="px-2 py-2 border-b">
//                           <input
//                             type="checkbox"
//                             className="accent-cyan-800 hover:accent-slate-500"
//                             checked={selectedAssignUsers.includes(assign.assignId)}
//                             onChange={() => toggleAssignSelectUser(assign.assignId)}
//                           />
//                         </td>
//                       )}
//                       <td className="px-6 py-4 border-b">{assign.assignId}</td>
//                       <td className="px-6 py-4 border-b">{assign.assignAccount}</td>
//                       <td className="px-6 py-3 border-b hover:underline cursor-pointer" 
//                         onClick={() => handleShowEmailList(assign.assignMember.split(', '))}
//                       >
//                         {assign.assignMember.split(', ').length > 1
//                           ? `${assign.assignMember.split(', ')[0]} +${assign.assignMember.split(', ').length - 1}`
//                           : assign.assignMember
//                         }
//                       </td>
//                       <td className="px-6 py-4 border-b">{assign.assignGroup}</td>
//                       <td className="px-6 py-4 border-b">
//                         <span
//                           className={`font-medium ${
//                             assign.assignStatus === 'Active' ? 'text-green-600' : 'text-red-600'
//                           }`}
//                         >
//                           {assign.assignStatus}
//                         </span>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td
//                       colSpan={5}
//                       className="px-6 py-4 border-4 border-dashed h-[200px] text-center"
//                     >
//                       <span className="px-8 py-2 text-gray-300">No member assignments found</span>
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </>
//       )}

//       {/* Add Account Modal */}
//       {isModalOpen && 
//         <AdminAddAccountModal 
//           onClose={handleCloseModal}
//           initialData={editingUser} 
//         />
//       }

//       {/* assigning members modal */}
//       {isAssignMemberModalOpen && (
//         <AdminAssignMemberModal
//           onClose={handleCloseAssignMemberModal}
//           handleAssignMembers={handleAssignMembers}
//         />
//       )}

//       {/* Assign Member Email list */}
//       {isEmailListModalOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//           <div className="bg-white p-8 rounded-md">
//             <h3 className="font-bold text-cyan-800 text-sm border-2 border-cyan-800 px-6 py-2 rounded-full mb-2">
//               Assigned Emails
//             </h3>
//             <ul>
//               {selectedAssignEmails.map((email, index) => (
//                 <li key={index} className="text-sm">{email}</li>
//               ))}
//             </ul>
//             <button className="bg-cyan-800 rounded-md text-white px-4 py-1 mt-4" onClick={handleCloseEmailListModal}>Close</button>
//           </div>
//         </div>
//       )}

//     </div>
//   );
// };

// export default AdminRole;