// src/components/client/MiniApp/App03_MySubscriptionRegistrationHub.tsx

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaArrowCircleLeft, FaInfoCircle, FaClipboard } from 'react-icons/fa';
// import file
import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
import useSubscriptionSubmit, { SubscriptionHubData } from '../../../hooks/Subscription/useSubscriptionSubmit';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';
import useCurrencyConversion from '../../../hooks/currencyAPI';
import { useEventRegTerms } from '../../../context/SubRegTermContext';

import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
import ServerNameImg from '../../../assets/miniApp/broker_server_name.png';

// Add AlertType type definition here
type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';

interface TraderHubRegistrationProps {
  plan: Plan;
  onBack: () => void;
}

const TraderHubRegistration: React.FC<TraderHubRegistrationProps> = ({ plan, onBack }) => {
  const { user } = useUserDashboard();
  const { fetchTrialInfo, fetchTrialPeriod, fetchUserRoleAndEntitlement } = useSubscriptionData();
  const { 
    submitTraderHubSubscription,
    updateVoucherDaysInDatabase,
    fetchVoucherDetails, 
    fetchAndUpdateUserAlgoMarixData,
    checkUserVoucherEntitlement, 
  } = useSubscriptionSubmit();
  const { validate, errors, setErrors } = useFormValidation();
  const termsAndConditions = useEventRegTerms();
  // component 
  const [isModalOpen, setIsModalOpen] = useState(false);
  // form field state
  const defaultMemberType = { type: 'R One Member' };
  const [orderID, setOrderID] = useState('');
  const [planType, setPlanType] = useState('');
  const [planDays, setPlanDays] = useState<number | null>(null);
  const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
  const { myrAmount } = useCurrencyConversion(0, parseFloat(discountedPlanAmount));
  const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
  const isAccountSectionEnabled = selectedAccountType !== null;
  const [accountServer, setAccountServer] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountPassword, setAccountPassword] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [receipts, setReceipts] = useState<File[]>([]);
  const [notes, setNotes] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  // apply trial
  const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
  // apply referral
  const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
  const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
  const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
  const [referralApplied, setReferralApplied] = useState(false);
  // apply voucher code
  const [applyDisabled, setApplyDisabled] = useState(true);
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
  const [voucherDays, setVoucherDays] = useState<number | null>(null);
  const [voucherEnabled, setVoucherEnabled] = useState(false);
  const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
  const [voucherApplied, setVoucherApplied] = useState(false); 
  // submit states
  const [isSubmitting, setIsSubmitting] = useState(false); 
  // renew 
  const [isRenewal, setIsRenewal] = useState(false);
  // set alert
  const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
  const [alert, setAlert] = useState<{ 
    visible: boolean, 
    type: AlertType, 
    title?: string, 
    message: string, 
    isLoading?: boolean 
  }>({ 
    visible: false, 
    type: 'check', 
    message: '' 
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const closeAlert = () => setReferralAlert({ visible: false, type: 'check', message: '' });

  const handleAccountTypeChange = (type: string) => {
    setSelectedAccountType(type === selectedAccountType ? null : type);
  };

  // generate order id for trader hub
  useEffect(() => {
    const generatedOrderID = 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
    setOrderID(generatedOrderID);
  }, []);

  //------------------- trial apply ----------------------//
  // search for trial details
  useEffect(() => {
    const fetchTrialDetails = async () => {
      if (planType === 'trial' && user?.email) {
        const { trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

        if (trialDays) {
          setPlanTrialDays(trialDays);

        } else {
          setPlanTrialDays(null);
        }

      } else {
        setPlanDays(parseInt(plan.planPeriod, 10));
        setPlanTrialDays(null);
      }
    };
    fetchTrialDetails();
  }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);

  //------------------- voucher apply ----------------------//
  // toggle voucher enable or disable
  useEffect(() => {
    setApplyDisabled(!voucherEnabled);
  }, [voucherEnabled]);

  // voucher plan amount effect
  useEffect(() => {
    let calculatedPlanAmount = parseFloat(plan.planAmount);

    if (plan.planPeriod === '365') {
      calculatedPlanAmount = calculatedPlanAmount * 12;
    }

    setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
  }, [plan.planAmount, plan.planPeriod]);

  // disable voucher for trial
  useEffect(() => {
    if (planType === 'trial') {
      setVoucherEnabled(false);
    }
  }, [planType]);  
  
  // Update the voucherEnabled useEffect
  useEffect(() => {
    const updateVoucherDetails = async () => {
      if (voucherEnabled && voucherCode) {
        try {

          // Fetch voucher details
          const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
          if (voucher) {
            const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
            const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
            const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

            // Update state with fetched data
            setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
            setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
            setVoucherRedeemCount(voucher.voucherRedeemCount);

            // Update the voucher days in the database
            await updateVoucherDaysInDatabase(voucher.id, remainingDays);

          } else {
            setVoucherPeriod(null);
            setVoucherDays(null);
          }
        } catch (error) {
          console.error('Error fetching voucher details:', error);
          setVoucherRedeemCount(0);
        }
      } else {
        setVoucherPeriod(null);
        setVoucherDays(null);
      }
    };

    updateVoucherDetails();
  }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

  //------------------- referral apply ----------------------//
  // toggle enabled or disable referral effect
  useEffect(() => {
    if (isReferralEnabled) {
        setApplyReferralDisabled(false);
    } else {
        setApplyReferralDisabled(true);
    }
  }, [isReferralEnabled]);

  //------------------- define function ----------------------//
  // apply referral email function
  const handleApplyReferralEmail = () => {
    setReferralAlert({
      visible: true,
      type: 'confirm',
      message: 'Do you want to apply this referral email?',
    });
    // Disable the referral email field after applying
    setIsReferralEnabled(false);
  };

  // apply referral alert function
  const confirmReferralApply = () => {
    try {
      setReferralApplied(true); // Set success status
      setReferralAlert({
        visible: true,
        type: 'success',
        title: 'Referral Applied',
        message: 'Referral email applied successfully!',
      });
    } catch (error) {
      setReferralApplied(false); // Set failure status
      setReferralAlert({
        visible: true,
        type: 'error',
        title: 'Referral Error',
        message: 'Failed to apply the referral. Please try again.',
      });
    }
  };

  // apply voucher function
  const applyVoucher = async () => {
    if (voucherCode && !voucherApplied) {
      try {
          // Fetch voucher details without applying it yet to get the remaining days
          const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
          if (!voucher) {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'Voucher cannot be applied as it has already been fully redeemed.',
            });
            return;
          }

          // Check if the user is entitled to redeem this voucher
          const userEntitlement = await checkUserVoucherEntitlement(
            voucher.voucherCode, 
            voucher.voucherMemberType, 
            voucher.voucherGroupMember
          );

          if (!userEntitlement) {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'You are not entitled to redeem this voucher code.',
            });
            return;
          }

          // Check if the voucher is expired
          const currentDate = moment().tz("Asia/Kuala_Lumpur");
          const voucherPeriodDate = moment(voucher.voucherPeriod);
          const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

          if (remainingDays < 0) {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'The voucher has expired and cannot be applied.',
            });
            return;
          }

          // Check if the voucher has already been fully redeemed
          if (voucher.voucherRedeemCount === '0') {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'Voucher cannot be applied as it has already been fully redeemed.',
            });
            return; 
          }

          if (voucher && user) {
            // Fetch and update the user's AlgoMarix data in a single API call
            const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
            if (result) {
              const { accountVoucherCheck } = result;
      
              // Apply check only if the user exists and accountVoucherCheck is "0"
              if (accountVoucherCheck === '0' && planType !== 'subscribe') {
                setAlert({
                  visible: true,
                  type: 'error',
                  title: 'Voucher Error',
                  message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
                });
                return;
              }
            
              // Handle voucher redeem check logic
              if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
                if (voucherApplied) {
                  setAlert({
                      visible: true,
                      type: 'error',
                      title: 'Voucher Error',
                      message: 'You have already redeemed this voucher code and cannot redeem it again.',
                  });
                  return;
                }
              }

              // Handle specific redeem count
              if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
                if (voucherApplied) {
                  setAlert({
                    visible: true,
                    type: 'error',
                    title: 'Voucher Error',
                    message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
                  });
                  return;
                }
              }

              let newAmount = parseFloat(plan.planAmount);

              // Adjust for annual plan period
              if (plan.planPeriod === '365') {
                newAmount = newAmount * 12;
              }
    
              if (voucher.voucherType === 'By Amount') {
                newAmount -= parseFloat(voucher.voucherAmount);
              } else if (voucher.voucherType === 'By Percentage') {
                newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
              }
    
              // Update voucherDays in the subscription data
              setVoucherDays(remainingDays);

              // Now that the voucher is successfully applied, decrement the redeem count
              await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
              await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

              setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
              setVoucherRedeemCount(voucher.voucherRedeemCount);
              setVoucherApplied(true); 
              setAlert({
                visible: true,
                type: 'success',
                title: 'Voucher Applied',
                message: 'Voucher applied successfully!',
              });
          } else {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'Failed to retrieve user data. Please try again.',
            });
          }
        }
      } catch (error) {
        setDiscountedPlanAmount(plan.planAmount);
        setAlert({
          visible: true,
          type: 'error',
          title: 'Voucher Error',
          message: 'Failed to apply the voucher. Please check the voucher code and try again.',
        });
      }
    } else if (voucherApplied) {
      setAlert({
        visible: true,
        type: 'error',
        title: 'Voucher Error',
        message: 'This voucher has already been applied to this order.',
      });
    }
  };

  const handlePlanTypeChange = async (type: string) => {
    if (type === 'Renew') {
      setIsRenewal(true);
    } else {
      setIsRenewal(false);
    }
  
    if (type === 'trial' && user?.email) {
      const { userRole, isEntitled } = await fetchUserRoleAndEntitlement(user.email, parseInt(plan.planID, 10));
  
      if (userRole && isEntitled) {
        setPlanType(type);
        
        // Fetch the trial period for TraderHub and set it
      const TrialDaysPeriod = await fetchTrialPeriod(type);
      if (TrialDaysPeriod) {
        setPlanTrialDays(TrialDaysPeriod); 
      } else {
        const { trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));
        setPlanTrialDays(trialDays);
      }
      
        } else {
        setAlert({
          visible: true,
          type: 'error',
          title: 'Trial Selection Error',
          message: 'You are not entitled to select the trial. Switching back to Subscribe.',
        });
        setPlanType('subscribe');
      
      }
    } else {
      setPlanType(type);
      setPlanDays(parseInt(plan.planPeriod, 10));
      setPlanTrialDays(null);
    }
  };

  // function for click subscribe button
  const handleSubscribeClick = async () => {
    if (!user) return;

    // Prevent double-click by checking if the process is already in progress
    if (isSubmitting) {
      setAlert({
        visible: true,
        type: 'warning',
        title: 'Subscription in Progress',
        message: 'Your subscription is already being processed. Please wait...',
      });
      return;
    }

    // Use the validate function instead of validateForm
    if (!validate({
        planType,
        accountType: selectedAccountType || '',
        accountServer,
        accountNumber,
        accountPassword,
        paymentMethod,
        paymentReceipts: receipts,
        termsAccepted
    })) {
        setAlert({
            visible: true,
            type: 'error',
            title: 'Warning',
            message: 'Please scroll up and review to ensure all required fields are filled in.',
        });
        return;
    }

    setIsSubmitting(true); 
    setAlert({
        visible: true,
        type: 'confirm',
        message: 'Make sure you provided correct information before subscribing?'
    });
  };

  // function for confirm subscribe submit
  const confirmSubscribe = async () => {
    try {
      setAlert((prev) => ({ ...prev, isLoading: true }));
  
      // Here, you would gather the form data
      const subscriptionHubData: SubscriptionHubData = {
        planID: plan.planID || '',
        orderID,
        name: user?.username || '',
        phone: user?.phone || '',
        email: user?.email || '',
        referralEmail,
        planNameHub: plan.planName,
        planTypeHub: planType,
        planPeriodHub: planType === 'trial' ? '' : plan.planPeriod,  // Set planPeriodHub to '0' for trial
        planPeriodTrialHub: planType === 'trial' ? planTrialDays?.toString() || '' : '',
        planAmountHub: discountedPlanAmount,
        voucherCodeHub: voucherCode,
        renewalDate: '', 
        expireDays: '',
        accountType: selectedAccountType || '',
        accountServer,
        accountNumber,
        accountPassword,
        PaymentMethodHub: paymentMethod,
        PaymentReceiptsHub: receipts.map(receipt => receipt.name).join(', '),
        notes,
        memberType: defaultMemberType,
        image: '', 
        termAgreed: termsAccepted,
      };
  
      const response = await submitTraderHubSubscription(subscriptionHubData, receipts);
  
      if (response.success) {
        setAlert({
          type: 'success',
          message: 'Subscription successful!',
          visible: true,
          isLoading: false,
        });
      } else {
        setAlert({
          type: 'error',
          title: 'Subscription failed',
          message: 'If you need support, please contact <a href="mailto:general@r1trader.com">general@r1trader.com</a>',
          visible: true,
          isLoading: false
        })
      }
    } catch (error: any) {
      setAlert({
        type: 'error',
        title: 'Subscription failed',
        message: error.message || 'An error occurred during the subscription process.',
        visible: true,
        isLoading: false,
      });
    }
  }; 

  return (
    <div>
      <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
        <form>
          <div className="flex justify-center my-4 sm:my-0 xs:my-0">
            <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
              Basic Information
            </span>
          </div>

          {/* Description Field */}
          <div className="my-4">
            <p className="mb-6 font-semibold">{plan.planDescription}</p>
          </div>

          {/* Order ID Field */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
          </div>

          {/* Username Field */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
          </div>

          {/* Phone Field */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="phone">Contact No.</label>
            <input 
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
              id="phone" 
              type="text" 
              value={user?.phone || ''} disabled />
          </div>

          {/* Email Field */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
          </div>

          {/* Referral Email Field */}
          <div className="mb-4 flex items-center">
            <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
              Referral Email
            </label>
            <div className="flex justify-between items-center">
              <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
              <div className="text-xs font-thin ml-2 text-rose-500">
                ( Enter your referrer's email or leave as default. )
              </div>
              <input
                type="checkbox"
                className="ml-2 accent-cyan-800"
                checked={isReferralEnabled}
                onChange={() => setIsReferralEnabled(!isReferralEnabled)}
              />
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <input
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
              id="referral-email"
              type="email"
              value={referralEmail}
              onChange={(e) => setReferralEmail(e.target.value)}
              disabled={!isReferralEnabled}
              placeholder="Enter referral email"
            />
            <button
              type="button"
              className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
              onClick={handleApplyReferralEmail}
              disabled={applyReferralDisabled || referralApplied}
            >
              Apply
            </button>
          </div>
          
          <div className="py-4 sm:py-2 xs:py-2"></div>

          {/* Plan Name Field */}
          <div className="mb-4">
            <div className="flex justify-center mb-4">
              <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
            </div>
            <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
            <input 
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
              id="plan-name" 
              type="text" 
              value={plan.planName} disabled 
            />
          </div>

          {/* Plan Trial Period Field */}
          {planType === 'trial' ? (
            <>
              <div className="mb-4">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="plan-trial-period"
                >
                  Plan Trial Period (days)
                </label>
                <input 
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
                  id="plan-trial-period" 
                  type="text" 
                  value={planTrialDays !== null && planTrialDays !== undefined ? planTrialDays.toString() : ''} 
                  disabled 
                />
              </div>
            </>
          ) : (
            <div className="mb-4">
              {/* Plan Period (Days) Field */}
              <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
              <input 
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
                id="plan-period" 
                type="text" 
                value={planDays !== null && planDays !== undefined ? planDays.toString() : ''} 
                disabled 
              />
            </div>
          )}

          {/* Plan Type Field */}
          <div className="mb-4">
            <div className="flex justify-normal items-center ">
              <label 
                className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
                htmlFor="plan-type">
                  Plan Type
              </label>
              <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
              <div className="text-xs text-rose-600 mb-2 ml-2">
                ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
              </div>
            </div>
            <select
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
              id="plan-type"
              value={planType}
              onChange={(e) => { 
                handlePlanTypeChange(e.target.value); 
                setPlanType(e.target.value);
                if (errors.planType) {
                  setErrors((prevErrors) => ({ ...prevErrors, planType:false }));
                }
              }}
              disabled={isRenewal}
            >
              <option value="">Select a Plan Type</option> 
              <option value="trial">Trial</option>
              <option value="subscribe">Subscribe</option>
              <option value="Renew">Renew</option>
            </select>
          </div>

          {/* Plan Amount Field */}
          {planType !== 'trial' && (
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
                <FaInfoCircle 
                  className="w-4 h-4 mb-2 ml-2 cursor-pointer"
                />
                {/* Only show conversion rate if the currency is not MYR */}
                {plan.planCurrency !== 'MYR' && (
                  <div className="text-xs text-rose-600 mb-2 ml-2">
                    (Conversion based on today’s rate:  <span className="font-bold text-sm">{`RM ${myrAmount}`}</span>)
                  </div>
                )}
              </div>
              <input 
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
                id="plan-amount" 
                type="text" 
                value={`${discountedPlanAmount} ${plan.planCurrency}`} 
                disabled 
              />
            </div>
          )}


          {/* Plan Amount Field
          {planType !== 'trial' && (
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
                <FaInfoCircle 
                  className="w-4 h-4 mb-2 ml-2 cursor-pointer"
                />
                <div className="text-xs text-rose-600 mb-2 ml-2">
                   (Conversion based on today’s rate:  <span className="font-bold text-sm">{`RM ${myrAmount}`}</span>)
                </div>
              </div>
              <input 
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
                id="plan-amount" 
                type="text" 
                value={`${discountedPlanAmount} ${plan.planCurrency}`} 
                disabled 
              />
            </div>
          )} */}

          {/* Apply Voucher Field */}
          <div className="mb-4 flex items-center">
            <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
              Apply Voucher
            </label>
            <div className="flex justify-between items-center">
              <FaInfoCircle/>
              <div className="text-xs text-rose-600 ml-2">
                {voucherEnabled ? (
                  <span>
                    ( 
                      The voucher is valid until 
                        <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
                      left only 
                        <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
                      Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
                    )
                  </span>
                ) : (
                  <span>
                    ( Apply voucher for subscription benefit.)
                  </span>
                )}
              </div>
              <input
                type="checkbox"
                className="ml-2 accent-cyan-800"
                checked={voucherEnabled}
                onChange={() => setVoucherEnabled(!voucherEnabled)}
                disabled={planType === 'trial'}
              />
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <input
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
              id="voucher-code"
              type="text"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              disabled={!voucherEnabled}
              placeholder="Enter voucher code"
            />
            <button
              type="button"
              className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
              onClick={applyVoucher}
              disabled={applyDisabled}
            >
              Apply
            </button>
          </div>

          <div className="py-4 sm:py-2 xs:py-2"></div>

          {/* trade account section */}
          <div className="p-4 border rounded-xl">
            <div className="text-rose-600 mb-4">
                Only need to fill in this field if there is a requirement to show and verify your trading account; otherwise, you can leave it blank. For questions, contact 
              <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
            </div>
            <div className="mb-4">
              <div className="flex justify-center items-center mb-4">
                <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Trading Account</span>
              </div>
              <div className="flex justify-normal items-center">
                <label className="block mb-2 font-semibold">
                  Account Type
                </label>
                <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  ( choose only if required to fill in trade account detail )
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-normal items-center">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedAccountType === 'LIVE'}
                      onChange={() => handleAccountTypeChange('LIVE')}
                      className="mr-2 accent-cyan-800"
                    />
                  </label>
                  <span>LIVE</span>
                </div>
                <div className="flex justify-normal items-center">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedAccountType === 'DEMO'}
                      onChange={() => handleAccountTypeChange('DEMO')}
                      className="mr-2 accent-cyan-800"
                    />
                  </label>
                  <span>DEMO</span>
                </div>
              </div>
            </div>

            {/* Trading Account Section (Only enabled if account type is selected) */}
            <fieldset 
              disabled={!isAccountSectionEnabled} 
              className={!isAccountSectionEnabled ? 'opacity-50' : ''}
            >
              {/* Account Server field */}
              <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="account-server"
                >
                  Account Server
                </label>
                <div className="relative group mx-2">
                  <div className="flex justify-between items-center">
                    <FaInfoCircle 
                      className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3 text-cyan-800"
                      aria-hidden="true"
                    />
                  </div>
                  {/* Tooltip */}
                  <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs text-white bg-cyan-800">
                    You can find your account server details in your trading platform under the account information section.
                    <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
                  </div>
                </div>
                <div className="text-xs text-rose-600 mb-2">
                  ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
                </div>
              </div>
              <input
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountServer ? 'border-rose-600' : ''}`}
                  id="account-server"
                  type="text"
                  value={accountServer}
                  onChange={(e) => {
                    setAccountServer(e.target.value);
                    if (errors.accountServer) {
                      setErrors((prevErrors) => ({ ...prevErrors, accountServer: false }));
                    }
                  }}
                  disabled={!isAccountSectionEnabled}
                />
              </div>
              
              {/* Account Number field */}  
              <div className="mb-4">
                <div className="flex justify-normal items-center">
                  <label className="block mb-2 font-semibold" htmlFor="account-number">
                    Account Number
                  </label>
                  <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
                  <div className="text-xs text-rose-600 mb-2 ml-2">
                    ( This is your trading account id number )
                  </div>
                </div>
                <input
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountNumber ? 'border-rose-600' : ''}`}
                  id="account-number"
                  type="number"
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                    if (errors.accountNumber) {
                      setErrors((prevErrors) => ({ ...prevErrors, accountNumber: false }));
                    }
                  }}
                  disabled={!isAccountSectionEnabled}
                />
              </div>
              
              {/* Account Password field */} 
              <div className="mb-4">
                <div className="flex justify-normal items-center">
                  <label className="block mb-2 font-semibold" htmlFor="account-password">
                    Account Password
                  </label>
                  <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
                  <div className="text-xs text-rose-600 mb-2 ml-2">
                    ( This is your trading account investor "read-only" password. )
                  </div>
                </div>
                <input
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountPassword ? 'border-rose-600' : ''}`}
                  id="account-password"
                  type="password"
                  value={accountPassword}
                  onChange={(e) => {
                    setAccountPassword(e.target.value);
                    if (errors.accountPassword) {
                      setErrors((prevErrors) => ({ ...prevErrors, accountPassword:false }));
                    }
                  }}
                  disabled={!isAccountSectionEnabled}
                />
              </div>
            </fieldset>
          </div>

          <div className="py-4 sm:py-2 xs:py-2"></div>

          <div className="flex justify-center mb-4">
            <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
          </div>

          {/* Payment Method Field */}
          {planType !== 'trial' && (
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label className="block font-semibold xs:w-[60px]">Payment Method</label>
                <FaInfoCircle className="ml-2"/>
                <div className="text-xs text-rose-600 ml-2">
                  ( choose your payment method )
                </div>
              </div>
              <div className="flex items-center mb-2">
                <input
                  type="radio"
                  id="bank-transfer"
                  name="payment-method"
                  value="Bank Transfer"
                  checked={paymentMethod === 'Bank Transfer'}
                  className="accent-cyan-800 hover:accent-cyan-600"
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
              </div>
              {paymentMethod === 'Bank Transfer' && (
                <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4 ">
                  <span>Bank Account</span><br />
                  <div className="flex justify-between items-center sm:text-xs xs:text-xs">
                    <div>
                      <li>Please proceed with the payment through USDT (cryptocurrency).</li>
                      {/* <li>FINOX TRADE MARKET SDN. BHD.</li>
                      <li>Hong leong bank</li>
                      <li>23600590742</li> */}
                    </div>
                    <FaClipboard 
                      className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
                      onClick={() => navigator.clipboard.writeText("23600590742")} 
                    />
                  </div>
                </div>
              )}
              <div className="flex items-center">
                <input
                  type="radio"
                  id="usdt"
                  name="payment-method"
                  value="USDT"
                  checked={paymentMethod === 'USDT'}
                  className="accent-cyan-800 hover:accent-cyan-600"
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
              </div>
              {paymentMethod === 'USDT' && (
                <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
                  <span>Wallet Address:</span><br />
                  <div className="flex justify-between items-cente sm:text-xs accent-cyan-800">
                    <div>
                      <li>Tron (TRC20) Network</li>
                      <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
                    </div>
                    <FaClipboard 
                      className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
                      onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Plan Trial Requirement Field */}
          {planType === 'trial' && (
            <div className="mb-4">
              <p className="font-semibold">Plan Trial Requirement:</p>
              <ul className="list-disc list-inside">
              <li>Deposit 500 USD on Lirunex Account</li>
                <li>
                  <a 
                    href="https://my.lirunex.online/Home/Signup?Referral=90859&GroupId=8046" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:underline"
                  >
                    Sign up for a Lirunex Account here
                  </a>
                </li>
              </ul>
            </div>
          )}

          {/* Payment Receipts Field */}
          <div className="mb-4">
            <div className="flex justify-normal items-center">
              <label 
                className="block font-semibold sm:w-[60px] xs:w-[60px]" 
                htmlFor="payment-receipts"
              >
                {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
              </label>
              <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
              <div className="text-xs text-rose-600 ml-2">
                {planType === 'trial' 
                  ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
                  : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
              </div>
            </div>
            <input
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
              type="file"
              name="PaymentReceipt"
              onChange={(e) => {
                const filesArray = [...receipts];
                if (e.target.files && e.target.files[0]) {
                  filesArray.push(e.target.files[0]);
                }
                setReceipts(filesArray);
                if (errors.paymentReceipts) {
                  setErrors((prevErrors) => ({ ...prevErrors, paymentReceipts: false }));
                }
              }}
            />
          </div>

          {/* Notes Field */}
          <div className="mb-6">
            <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
            <textarea 
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
              id="notes" 
              placeholder="Leave any notes here (optional)..." 
              value={notes} 
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          {/* Terms and Conditions Field */}
          <div className="flex justify-normal items-center mb-4">
            <label>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
              />
            </label>
            <span 
                className="cursor-pointer text-cyan-800 underline" 
                onClick={openModal}
              >
                I have read and understand the terms and conditions
            </span>
          </div>

          {/* Submit Form Button */}
          <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
            <button 
              onClick={onBack} 
              className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
            >
              <FaArrowCircleLeft />
              <div className="ml-2">
                Back to Details
              </div>
            </button>
            <button 
              type="button"
              onClick={handleSubscribeClick}
              disabled={!termsAccepted || isSubmitting}
              className={`px-4 py-2 rounded-md flex items-center justify-center text-white ${!termsAccepted || isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-cyan-800'}`}
            >
              <BiSolidPurchaseTag />
              <div className="ml-2">
                Subscribe
              </div>
            </button>
          </div>
        </form>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto max-w-[800px] max-h-screen lg:w-[800px] lg:h-[600px] md:w-[700px] md:h-[600px] sm:w-[450px] sm:h-[700px] xs:max-w-screen xs:h-[600px]">
            <span
              className="border-2 p-10 overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              {termsAndConditions}
            </span>
            <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
              close
            </button>
          </div>
        </div>
      )}

      <MyTicketAlert 
        type={alert.type} 
        message={alert.message} 
        visible={alert.visible} 
        onClose={() => setAlert({ ...alert, visible: false })} 
        onConfirm={confirmSubscribe} 
        onCancel={closeAlert}
        isLoading={alert.isLoading} 
      />

      <MyTicketAlert 
        type={referralAlert.type} 
        message={referralAlert.message} 
        visible={referralAlert.visible} 
        onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
        onConfirm={confirmReferralApply}
        onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
      />
    </div>
  );
};

export default TraderHubRegistration;


// // update code --> 12 sept 2024
// // src/components/client/MiniApp/App03_MySubscriptionRegistrationHub.tsx

// import React, { useState, useEffect } from 'react';
// import moment from 'moment-timezone';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaClipboard } from 'react-icons/fa';
// // import file
// import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
// import useSubscriptionSubmit, { SubscriptionHubData } from '../../../hooks/Subscription/useSubscriptionSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';
// import useCurrencyConversion from '../../../hooks/currencyAPI';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';

// import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png';

// // Add AlertType type definition here
// type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';

// interface TraderHubRegistrationProps {
//   plan: Plan;
//   onBack: () => void;
// }

// const TraderHubRegistration: React.FC<TraderHubRegistrationProps> = ({ plan, onBack }) => {
//   const { user } = useUserDashboard();
//   const { fetchTrialInfo, fetchTrialPeriod, fetchUserRoleAndEntitlement } = useSubscriptionData();
//   const { 
//     submitTraderHubSubscription,
//     updateVoucherDaysInDatabase,
//     fetchVoucherDetails, 
//     fetchAndUpdateUserAlgoMarixData,
//     checkUserVoucherEntitlement, 
//   } = useSubscriptionSubmit();
//   const { validate, errors, setErrors } = useFormValidation();
//   const termsAndConditions = useEventRegTerms();
//   // component 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // form field state
//   const defaultMemberType = { type: 'R One Member' };
//   const [orderID, setOrderID] = useState('');
//   const [planType, setPlanType] = useState('');
//   const [planDays, setPlanDays] = useState<number | null>(null);
//   const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
//   const { myrAmount } = useCurrencyConversion(0, parseFloat(discountedPlanAmount));
//   const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
//   const isAccountSectionEnabled = selectedAccountType !== null;
//   const [accountServer, setAccountServer] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   // apply trial
//   const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
//   // apply referral
//   const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
//   const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
//   const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
//   const [referralApplied, setReferralApplied] = useState(false);
//   // apply voucher code
//   const [applyDisabled, setApplyDisabled] = useState(true);
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
//   const [voucherDays, setVoucherDays] = useState<number | null>(null);
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
//   const [voucherApplied, setVoucherApplied] = useState(false); 
//   // submit states
//   const [isSubmitting, setIsSubmitting] = useState(false); 
//   // renew 
//   const [isRenewal, setIsRenewal] = useState(false);
//   // set alert
//   const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
//   const [alert, setAlert] = useState<{ 
//     visible: boolean, 
//     type: AlertType, 
//     title?: string, 
//     message: string, 
//     isLoading?: boolean 
//   }>({ 
//     visible: false, 
//     type: 'check', 
//     message: '' 
//   });

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   const closeAlert = () => setReferralAlert({ visible: false, type: 'check', message: '' });

//   const handleAccountTypeChange = (type: string) => {
//     setSelectedAccountType(type === selectedAccountType ? null : type);
//   };

//   // generate order id for trader hub
//   useEffect(() => {
//     const generatedOrderID = 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//     setOrderID(generatedOrderID);
//   }, []);

//   //------------------- trial apply ----------------------//
//   // search for trial details
//   useEffect(() => {
//     const fetchTrialDetails = async () => {
//       if (planType === 'trial' && user?.email) {
//         const { trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

//         if (trialDays) {
//           setPlanTrialDays(trialDays);
//         } else {
//           setPlanTrialDays(null);
//         }

//       } else {
//         setPlanDays(parseInt(plan.planPeriod, 10));
//         setPlanTrialDays(null);
//       }
//     };
//     fetchTrialDetails();
//   }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);

//   //------------------- voucher apply ----------------------//
//   // toggle voucher enable or disable
//   useEffect(() => {
//     setApplyDisabled(!voucherEnabled);
//   }, [voucherEnabled]);

//   // voucher plan amount effect
//   useEffect(() => {
//     let calculatedPlanAmount = parseFloat(plan.planAmount);

//     if (plan.planPeriod === '365') {
//       calculatedPlanAmount = calculatedPlanAmount * 12;
//     }

//     setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
//   }, [plan.planAmount, plan.planPeriod]);

//   // disable voucher for trial
//   useEffect(() => {
//     if (planType === 'trial') {
//       setVoucherEnabled(false);
//     }
//   }, [planType]);  
  
//   // Update the voucherEnabled useEffect
//   useEffect(() => {
//     const updateVoucherDetails = async () => {
//       if (voucherEnabled && voucherCode) {
//         try {

//           // Fetch voucher details
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
//           if (voucher) {
//             const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
//             const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
//             const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

//             // Update state with fetched data
//             setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
//             setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
//             setVoucherRedeemCount(voucher.voucherRedeemCount);

//             // Update the voucher days in the database
//             await updateVoucherDaysInDatabase(voucher.id, remainingDays);

//           } else {
//             setVoucherPeriod(null);
//             setVoucherDays(null);
//           }
//         } catch (error) {
//           console.error('Error fetching voucher details:', error);
//           setVoucherRedeemCount(0);
//         }
//       } else {
//         setVoucherPeriod(null);
//         setVoucherDays(null);
//       }
//     };

//     updateVoucherDetails();
//   }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

//   //------------------- referral apply ----------------------//
//   // toggle enabled or disable referral effect
//   useEffect(() => {
//     if (isReferralEnabled) {
//         setApplyReferralDisabled(false);
//     } else {
//         setApplyReferralDisabled(true);
//     }
//   }, [isReferralEnabled]);

//   //------------------- define function ----------------------//
//   // apply referral email function
//   const handleApplyReferralEmail = () => {
//     setReferralAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Do you want to apply this referral email?',
//     });
//     // Disable the referral email field after applying
//     setIsReferralEnabled(false);
//   };

//   // apply referral alert function
//   const confirmReferralApply = () => {
//     try {
//       setReferralApplied(true); // Set success status
//       setReferralAlert({
//         visible: true,
//         type: 'success',
//         title: 'Referral Applied',
//         message: 'Referral email applied successfully!',
//       });
//     } catch (error) {
//       setReferralApplied(false); // Set failure status
//       setReferralAlert({
//         visible: true,
//         type: 'error',
//         title: 'Referral Error',
//         message: 'Failed to apply the referral. Please try again.',
//       });
//     }
//   };

//   // apply voucher function
//   const applyVoucher = async () => {
//     if (voucherCode && !voucherApplied) {
//       try {
//           // Fetch voucher details without applying it yet to get the remaining days
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
//           if (!voucher) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return;
//           }

//           // Check if the user is entitled to redeem this voucher
//           const userEntitlement = await checkUserVoucherEntitlement(
//             voucher.voucherCode, 
//             voucher.voucherMemberType, 
//             voucher.voucherGroupMember
//           );

//           if (!userEntitlement) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'You are not entitled to redeem this voucher code.',
//             });
//             return;
//           }

//           // Check if the voucher is expired
//           const currentDate = moment().tz("Asia/Kuala_Lumpur");
//           const voucherPeriodDate = moment(voucher.voucherPeriod);
//           const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

//           if (remainingDays < 0) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'The voucher has expired and cannot be applied.',
//             });
//             return;
//           }

//           // Check if the voucher has already been fully redeemed
//           if (voucher.voucherRedeemCount === '0') {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return; 
//           }

//           if (voucher && user) {
//             // Fetch and update the user's AlgoMarix data in a single API call
//             const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
//             if (result) {
//               const { accountVoucherCheck } = result;
      
//               // Apply check only if the user exists and accountVoucherCheck is "0"
//               if (accountVoucherCheck === '0' && planType !== 'subscribe') {
//                 setAlert({
//                   visible: true,
//                   type: 'error',
//                   title: 'Voucher Error',
//                   message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
//                 });
//                 return;
//               }
            
//               // Handle voucher redeem check logic
//               if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: 'You have already redeemed this voucher code and cannot redeem it again.',
//                 });
//                 return;
//               }

//               // Handle specific redeem count 
//               if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
//                 });
//                 return;
//               }

//               let newAmount = parseFloat(plan.planAmount);

//               // Adjust for annual plan period
//               if (plan.planPeriod === '365') {
//                 newAmount = newAmount * 12;
//               }
    
//               if (voucher.voucherType === 'By Amount') {
//                 newAmount -= parseFloat(voucher.voucherAmount);
//               } else if (voucher.voucherType === 'By Percentage') {
//                 newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
//               }
    
//               // Update voucherDays in the subscription data
//               setVoucherDays(remainingDays);

//               // Now that the voucher is successfully applied, decrement the redeem count
//               await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
//               await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

//               setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
//               setVoucherRedeemCount(voucher.voucherRedeemCount);
//               setVoucherApplied(true); 
//               setAlert({
//                 visible: true,
//                 type: 'success',
//                 title: 'Voucher Applied',
//                 message: 'Voucher applied successfully!',
//               });
//           } else {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Failed to retrieve user data. Please try again.',
//             });
//           }
//         }
//       } catch (error) {
//         setDiscountedPlanAmount(plan.planAmount);
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Voucher Error',
//           message: 'Failed to apply the voucher. Please check the voucher code and try again.',
//         });
//       }
//     } else if (voucherApplied) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Voucher Error',
//         message: 'This voucher has already been applied to this order.',
//       });
//     }
//   };

//   const handlePlanTypeChange = async (type: string) => {
//     if (type === 'Renew') {
//       setIsRenewal(true);
//     } else {
//       setIsRenewal(false);
//     }
  
//     if (type === 'trial' && user?.email) {
//       const { userRole, isEntitled } = await fetchUserRoleAndEntitlement(user.email, parseInt(plan.planID, 10));
  
//       if (userRole && isEntitled) {
//         setPlanType(type);
        
//         // Fetch the trial period for TraderHub and set it
//       const TrialDaysPeriod = await fetchTrialPeriod(type);
//       if (TrialDaysPeriod) {
//         setPlanTrialDays(TrialDaysPeriod); 
//       } else {
//         const { trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));
//         setPlanTrialDays(trialDays);
//       }
      
//         } else {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Trial Selection Error',
//           message: 'You are not entitled to select the trial. Switching back to Subscribe.',
//         });
//         setPlanType('subscribe');
      
//       }
//     } else {
//       setPlanType(type);
//       setPlanDays(parseInt(plan.planPeriod, 10));
//       setPlanTrialDays(null);
//     }
//   };

//   // function for click subscribe button
//   const handleSubscribeClick = async () => {
//     if (!user) return;

//     // Prevent double-click by checking if the process is already in progress
//     if (isSubmitting) {
//       setAlert({
//         visible: true,
//         type: 'warning',
//         title: 'Subscription in Progress',
//         message: 'Your subscription is already being processed. Please wait...',
//       });
//       return;
//     }

//     // Use the validate function instead of validateForm
//     if (!validate({
//         planType,
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         paymentMethod,
//         paymentReceipts: receipts,
//         termsAccepted
//     })) {
//         setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Please scroll up and review to ensure all required fields are filled in.',
//         });
//         return;
//     }

//     setIsSubmitting(true); 
//     setAlert({
//         visible: true,
//         type: 'confirm',
//         message: 'Make sure you provided correct information before subscribing?'
//     });
//   };

//   // function for confirm subscribe submit
//   const confirmSubscribe = async () => {
//     try {
//       setAlert((prev) => ({ ...prev, isLoading: true }));
  
//       // Here, you would gather the form data
//       const subscriptionHubData: SubscriptionHubData = {
//         planID: plan.planID || '',
//         orderID,
//         name: user?.username || '',
//         phone: user?.phone || '',
//         email: user?.email || '',
//         referralEmail,
//         planNameHub: plan.planName,
//         planTypeHub: planType,
//         planPeriodHub: plan.planPeriod || '',
//         planAmountHub: discountedPlanAmount,
//         voucherCodeHub: voucherCode,
//         renewalDate: '', 
//         expireDays: '',
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         PaymentMethodHub: paymentMethod,
//         PaymentReceiptsHub: receipts.map(receipt => receipt.name).join(', '),
//         notes,
//         memberType: defaultMemberType,
//         image: '', 
//         termAgreed: termsAccepted,
//       };
  
//       const response = await submitTraderHubSubscription(subscriptionHubData, receipts);
  
//       if (response.success) {
//         setAlert({
//           type: 'success',
//           message: 'Subscription successful!',
//           visible: true,
//           isLoading: false,
//         });
//       } else {
//         setAlert({
//           type: 'error',
//           title: 'Subscription failed',
//           message: 'If you need support, please contact <a href="mailto:general@r1trader.com">general@r1trader.com</a>',
//           visible: true,
//           isLoading: false
//         })
//       }
//     } catch (error: any) {
//       setAlert({
//         type: 'error',
//         title: 'Subscription failed',
//         message: error.message || 'An error occurred during the subscription process.',
//         visible: true,
//         isLoading: false,
//       });
//     }
//   }; 

//   return (
//     <div>
//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <form>
//           <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//               Basic Information
//             </span>
//           </div>

//           {/* Description Field */}
//           <div className="my-4">
//             <p className="mb-6 font-semibold">{plan.planDescription}</p>
//           </div>

//           {/* Order ID Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
//           </div>

//           {/* Username Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
//           </div>

//           {/* Phone Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="phone">Contact No.</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="phone" 
//               type="text" 
//               value={user?.phone || ''} disabled />
//           </div>

//           {/* Email Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
//           </div>

//           {/* Referral Email Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
//               Referral Email
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//               <div className="text-xs font-thin ml-2 text-rose-500">
//                 ( Enter your referrer's email or leave as default. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={isReferralEnabled}
//                 onChange={() => setIsReferralEnabled(!isReferralEnabled)}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="referral-email"
//               type="email"
//               value={referralEmail}
//               onChange={(e) => setReferralEmail(e.target.value)}
//               disabled={!isReferralEnabled}
//               placeholder="Enter referral email"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={handleApplyReferralEmail}
//               disabled={applyReferralDisabled || referralApplied}
//             >
//               Apply
//             </button>
//           </div>
          
//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Plan Name Field */}
//           <div className="mb-4">
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
//             </div>
//             <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="plan-name" 
//               type="text" 
//               value={plan.planName} disabled 
//             />
//           </div>

//           {/* Plan Trial Period Field */}
//           {planType === 'trial' ? (
//             <>
//               <div className="mb-4">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="plan-trial-period"
//                 >
//                   Plan Trial Period (days)
//                 </label>
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                   id="plan-trial-period" 
//                   type="text" 
//                   value={planTrialDays !== null && planTrialDays !== undefined ? planTrialDays.toString() : ''} 
//                   disabled 
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="mb-4">
//               {/* Plan Period (Days) Field */}
//               <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-period" 
//                 type="text" 
//                 value={planDays !== null && planDays !== undefined ? planDays.toString() : ''} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Plan Type Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center ">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
//                 htmlFor="plan-type">
//                   Plan Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
//               </div>
//             </div>
//             <select
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
//               id="plan-type"
//               value={planType}
//               onChange={(e) => { 
//                 handlePlanTypeChange(e.target.value); 
//                 setPlanType(e.target.value);
//                 if (errors.planType) {
//                   setErrors((prevErrors) => ({ ...prevErrors, planType:false }));
//                 }
//               }}
//               disabled={isRenewal}
//             >
//               <option value="">Select a Plan Type</option> 
//               <option value="trial">Trial</option>
//               <option value="subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           {/* Plan Amount Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
//                 <FaInfoCircle 
//                   className="w-4 h-4 mb-2 ml-2 cursor-pointer"
//                 />
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                    (Conversion based on today’s rate:  <span className="font-bold text-sm">{`RM ${myrAmount}`}</span>)
//                 </div>
//               </div>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-amount" 
//                 type="text" 
//                 value={`${discountedPlanAmount} ${plan.planCurrency}`} 
//                 disabled 
//               />
//           </div>
          
//           )}

//           {/* Apply Voucher Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
//               Apply Voucher
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {voucherEnabled ? (
//                   <span>
//                     ( 
//                       The voucher is valid until 
//                         <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
//                       left only 
//                         <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
//                       Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
//                     )
//                   </span>
//                 ) : (
//                   <span>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                 )}
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={voucherEnabled}
//                 onChange={() => setVoucherEnabled(!voucherEnabled)}
//                 disabled={planType === 'trial'}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="voucher-code"
//               type="text"
//               value={voucherCode}
//               onChange={(e) => setVoucherCode(e.target.value)}
//               disabled={!voucherEnabled}
//               placeholder="Enter voucher code"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={applyVoucher}
//               disabled={applyDisabled}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* trade account section */}
//           <div className="p-4 border rounded-xl">
//             <div className="text-rose-600 mb-4">
//                 Only need to fill in this field if there is a requirement to show and verify your trading account; otherwise, you can leave it blank. For questions, contact 
//               <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//             </div>
//             <div className="mb-4">
//               <div className="flex justify-center items-center mb-4">
//                 <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Trading Account</span>
//               </div>
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold">
//                   Account Type
//                 </label>
//                 <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( choose only if required to fill in trade account detail )
//                 </div>
//               </div>
//               <div className="flex flex-col">
//                 <div className="flex justify-normal items-center">
//                   <label className="flex items-center">
//                     <input
//                       type="checkbox"
//                       checked={selectedAccountType === 'LIVE'}
//                       onChange={() => handleAccountTypeChange('LIVE')}
//                       className="mr-2 accent-cyan-800"
//                     />
//                   </label>
//                   <span>LIVE</span>
//                 </div>
//                 <div className="flex justify-normal items-center">
//                   <label className="flex items-center">
//                     <input
//                       type="checkbox"
//                       checked={selectedAccountType === 'DEMO'}
//                       onChange={() => handleAccountTypeChange('DEMO')}
//                       className="mr-2 accent-cyan-800"
//                     />
//                   </label>
//                   <span>DEMO</span>
//                 </div>
//               </div>
//             </div>

//             {/* Trading Account Section (Only enabled if account type is selected) */}
//             <fieldset 
//               disabled={!isAccountSectionEnabled} 
//               className={!isAccountSectionEnabled ? 'opacity-50' : ''}
//             >
//               {/* Account Server field */}
//               <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="account-server"
//                 >
//                   Account Server
//                 </label>
//                 <div className="relative group mx-2">
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle 
//                       className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3 text-cyan-800"
//                       aria-hidden="true"
//                     />
//                   </div>
//                   {/* Tooltip */}
//                   <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs text-white bg-cyan-800">
//                     You can find your account server details in your trading platform under the account information section.
//                     <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                   </div>
//                 </div>
//                 <div className="text-xs text-rose-600 mb-2">
//                   ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
//                 </div>
//               </div>
//               <input
//                   className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountServer ? 'border-rose-600' : ''}`}
//                   id="account-server"
//                   type="text"
//                   value={accountServer}
//                   onChange={(e) => {
//                     setAccountServer(e.target.value);
//                     if (errors.accountServer) {
//                       setErrors((prevErrors) => ({ ...prevErrors, accountServer: false }));
//                     }
//                   }}
//                   disabled={!isAccountSectionEnabled}
//                 />
//               </div>
              
//               {/* Account Number field */}  
//               <div className="mb-4">
//                 <div className="flex justify-normal items-center">
//                   <label className="block mb-2 font-semibold" htmlFor="account-number">
//                     Account Number
//                   </label>
//                   <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                   <div className="text-xs text-rose-600 mb-2 ml-2">
//                     ( This is your trading account id number )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountNumber ? 'border-rose-600' : ''}`}
//                   id="account-number"
//                   type="number"
//                   value={accountNumber}
//                   onChange={(e) => {
//                     setAccountNumber(e.target.value);
//                     if (errors.accountNumber) {
//                       setErrors((prevErrors) => ({ ...prevErrors, accountNumber: false }));
//                     }
//                   }}
//                   disabled={!isAccountSectionEnabled}
//                 />
//               </div>
              
//               {/* Account Password field */} 
//               <div className="mb-4">
//                 <div className="flex justify-normal items-center">
//                   <label className="block mb-2 font-semibold" htmlFor="account-password">
//                     Account Password
//                   </label>
//                   <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                   <div className="text-xs text-rose-600 mb-2 ml-2">
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountPassword ? 'border-rose-600' : ''}`}
//                   id="account-password"
//                   type="password"
//                   value={accountPassword}
//                   onChange={(e) => {
//                     setAccountPassword(e.target.value);
//                     if (errors.accountPassword) {
//                       setErrors((prevErrors) => ({ ...prevErrors, accountPassword:false }));
//                     }
//                   }}
//                   disabled={!isAccountSectionEnabled}
//                 />
//               </div>
//             </fieldset>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//           </div>

//           {/* Payment Method Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block font-semibold xs:w-[60px]">Payment Method</label>
//                 <FaInfoCircle className="ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   ( choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mb-2">
//                 <input
//                   type="radio"
//                   id="bank-transfer"
//                   name="payment-method"
//                   value="Bank Transfer"
//                   checked={paymentMethod === 'Bank Transfer'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4 ">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center sm:text-xs xs:text-xs">
//                     <div>
//                       <li>FINOX TRADE MARKET SDN. BHD.</li>
//                       <li>Hong leong bank</li>
//                       <li>23600590742</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("23600590742")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="usdt"
//                   name="payment-method"
//                   value="USDT"
//                   checked={paymentMethod === 'USDT'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-cente sm:text-xs accent-cyan-800">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Plan Trial Requirement Field */}
//           {planType === 'trial' && (
//             <div className="mb-4">
//               <p className="font-semibold">Plan Trial Requirement:</p>
//               <ul className="list-disc list-inside">
//               <li>Deposit 500 USD on Lirunex Account</li>
//                 <li>
//                   <a 
//                     href="https://my.lirunex.online/Home/Signup?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className="text-blue-600 hover:underline"
//                   >
//                     Sign up for a Lirunex Account here
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           )}

//           {/* Payment Receipts Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="payment-receipts"
//               >
//                 {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
//               </label>
//               <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {planType === 'trial' 
//                   ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
//                   : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
//               type="file"
//               name="PaymentReceipt"
//               onChange={(e) => {
//                 const filesArray = [...receipts];
//                 if (e.target.files && e.target.files[0]) {
//                   filesArray.push(e.target.files[0]);
//                 }
//                 setReceipts(filesArray);
//                 if (errors.paymentReceipts) {
//                   setErrors((prevErrors) => ({ ...prevErrors, paymentReceipts: false }));
//                 }
//               }}
//             />
//           </div>

//           {/* Notes Field */}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </div>

//           {/* Terms and Conditions Field */}
//           <div className="flex justify-normal items-center mb-4">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//                 className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
//               />
//             </label>
//             <span 
//                 className="cursor-pointer text-cyan-800 underline" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//             </span>
//           </div>

//           {/* Submit Form Button */}
//           <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
//             <button 
//               onClick={onBack} 
//               className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//             >
//               <FaArrowCircleLeft />
//               <div className="ml-2">
//                 Back to Details
//               </div>
//             </button>
//             <button 
//               type="button"
//               onClick={handleSubscribeClick}
//               disabled={!termsAccepted || isSubmitting}
//               className={`px-4 py-2 rounded-md flex items-center justify-center text-white ${!termsAccepted || isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-cyan-800'}`}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">
//                 Subscribe
//               </div>
//             </button>
//           </div>
//         </form>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto max-w-[800px] max-h-screen lg:w-[800px] lg:h-[600px] md:w-[700px] md:h-[600px] sm:w-[450px] sm:h-[700px] xs:max-w-screen xs:h-[600px]">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       <MyTicketAlert 
//         type={alert.type} 
//         message={alert.message} 
//         visible={alert.visible} 
//         onClose={() => setAlert({ ...alert, visible: false })} 
//         onConfirm={confirmSubscribe} 
//         onCancel={closeAlert}
//         isLoading={alert.isLoading} 
//       />

//       <MyTicketAlert 
//         type={referralAlert.type} 
//         message={referralAlert.message} 
//         visible={referralAlert.visible} 
//         onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
//         onConfirm={confirmReferralApply}
//         onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
//       />
//     </div>
//   );
// };

// export default TraderHubRegistration;


// // udpate code --> 11 sept 2024 v2
// // src/components/client/MiniApp/App03_MySubscriptionRegistrationHub.tsx

// import React, { useState, useEffect } from 'react';
// import moment from 'moment-timezone';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaClipboard } from 'react-icons/fa';
// // import file
// import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
// import useSubscriptionSubmit, { SubscriptionHubData } from '../../../hooks/Subscription/useSubscriptionSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';
// import useCurrencyConversion from '../../../hooks/currencyAPI';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';

// import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png';

// // Add AlertType type definition here
// type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';

// interface TraderHubRegistrationProps {
//   plan: Plan;
//   onBack: () => void;
// }

// const TraderHubRegistration: React.FC<TraderHubRegistrationProps> = ({ plan, onBack }) => {
//   const { user } = useUserDashboard();
//   const { fetchTrialInfo, fetchTrialPeriod, fetchUserRoleAndEntitlement } = useSubscriptionData();
//   const { 
//     submitTraderHubSubscription,
//     updateVoucherDaysInDatabase,
//     fetchVoucherDetails, 
//     fetchAndUpdateUserAlgoMarixData,
//     checkUserVoucherEntitlement, 
//   } = useSubscriptionSubmit();
//   const { validate, errors, setErrors } = useFormValidation();
//   const termsAndConditions = useEventRegTerms();
//   // component 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // form field state
//   const defaultMemberType = { type: 'R One Member' };
//   const [orderID, setOrderID] = useState('');
//   const [planType, setPlanType] = useState('');
//   const [planDays, setPlanDays] = useState<number | null>(null);
//   const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
//   const { myrAmount } = useCurrencyConversion(0, parseFloat(discountedPlanAmount));
//   const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
//   const isAccountSectionEnabled = selectedAccountType !== null;
//   const [accountServer, setAccountServer] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   // apply trial
//   const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
//   // apply referral
//   const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
//   const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
//   const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
//   const [referralApplied, setReferralApplied] = useState(false);
//   // apply voucher code
//   const [applyDisabled, setApplyDisabled] = useState(true);
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
//   const [voucherDays, setVoucherDays] = useState<number | null>(null);
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
//   const [voucherApplied, setVoucherApplied] = useState(false); 
//   // submit states
//   const [isSubmitting, setIsSubmitting] = useState(false); 
//   // renew 
//   const [isRenewal, setIsRenewal] = useState(false);
//   // set alert
//   const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
//   const [alert, setAlert] = useState<{ 
//     visible: boolean, 
//     type: AlertType, 
//     title?: string, 
//     message: string, 
//     isLoading?: boolean 
//   }>({ 
//     visible: false, 
//     type: 'check', 
//     message: '' 
//   });

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   const closeAlert = () => setReferralAlert({ visible: false, type: 'check', message: '' });

//   const handleAccountTypeChange = (type: string) => {
//     setSelectedAccountType(type === selectedAccountType ? null : type);
//   };

//   // generate order id for trader hub
//   useEffect(() => {
//     const generatedOrderID = 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//     setOrderID(generatedOrderID);
//   }, []);

//   //------------------- trial apply ----------------------//
//   // search for trial details
//   useEffect(() => {
//     const fetchTrialDetails = async () => {
//       if (planType === 'trial' && user?.email) {
//         const { trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

//         if (trialDays) {
//           setPlanTrialDays(trialDays);
//         } else {
//           setPlanTrialDays(null);
//         }
//       } else {
//         setPlanDays(parseInt(plan.planPeriod, 10));
//         setPlanTrialDays(null);
//       }
//     };
//     fetchTrialDetails();
//   }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);

//   //------------------- voucher apply ----------------------//
//   // toggle voucher enable or disable
//   useEffect(() => {
//     setApplyDisabled(!voucherEnabled);
//   }, [voucherEnabled]);

//   // voucher plan amount effect
//   useEffect(() => {
//     let calculatedPlanAmount = parseFloat(plan.planAmount);

//     if (plan.planPeriod === '365') {
//       calculatedPlanAmount = calculatedPlanAmount * 12;
//     }

//     setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
//   }, [plan.planAmount, plan.planPeriod]);

//   // disable voucher for trial
//   useEffect(() => {
//     if (planType === 'trial') {
//       setVoucherEnabled(false);
//     }
//   }, [planType]);  
  
//   // Update the voucherEnabled useEffect
//   useEffect(() => {
//     const updateVoucherDetails = async () => {
//       if (voucherEnabled && voucherCode) {
//         try {

//           // Fetch voucher details
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
//           if (voucher) {
//             const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
//             const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
//             const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

//             // Update state with fetched data
//             setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
//             setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
//             setVoucherRedeemCount(voucher.voucherRedeemCount);

//             // Update the voucher days in the database
//             await updateVoucherDaysInDatabase(voucher.id, remainingDays);

//           } else {
//             setVoucherPeriod(null);
//             setVoucherDays(null);
//           }
//         } catch (error) {
//           console.error('Error fetching voucher details:', error);
//           setVoucherRedeemCount(0);
//         }
//       } else {
//         setVoucherPeriod(null);
//         setVoucherDays(null);
//       }
//     };

//     updateVoucherDetails();
//   }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

//   //------------------- referral apply ----------------------//
//   // toggle enabled or disable referral effect
//   useEffect(() => {
//     if (isReferralEnabled) {
//         setApplyReferralDisabled(false);
//     } else {
//         setApplyReferralDisabled(true);
//     }
//   }, [isReferralEnabled]);

//   //------------------- define function ----------------------//
//   // apply referral email function
//   const handleApplyReferralEmail = () => {
//     setReferralAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Do you want to apply this referral email?',
//     });
//     // Disable the referral email field after applying
//     setIsReferralEnabled(false);
//   };

//   // apply referral alert function
//   const confirmReferralApply = () => {
//     try {
//       setReferralApplied(true); // Set success status
//       setReferralAlert({
//         visible: true,
//         type: 'success',
//         title: 'Referral Applied',
//         message: 'Referral email applied successfully!',
//       });
//     } catch (error) {
//       setReferralApplied(false); // Set failure status
//       setReferralAlert({
//         visible: true,
//         type: 'error',
//         title: 'Referral Error',
//         message: 'Failed to apply the referral. Please try again.',
//       });
//     }
//   };

//   // apply voucher function
//   const applyVoucher = async () => {
//     if (voucherCode && !voucherApplied) {
//       try {
//           // Fetch voucher details without applying it yet to get the remaining days
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
//           if (!voucher) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return;
//           }

//           // Check if the user is entitled to redeem this voucher
//           const userEntitlement = await checkUserVoucherEntitlement(
//             voucher.voucherCode, 
//             voucher.voucherMemberType, 
//             voucher.voucherGroupMember
//           );

//           if (!userEntitlement) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'You are not entitled to redeem this voucher code.',
//             });
//             return;
//           }

//           // Check if the voucher is expired
//           const currentDate = moment().tz("Asia/Kuala_Lumpur");
//           const voucherPeriodDate = moment(voucher.voucherPeriod);
//           const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

//           if (remainingDays < 0) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'The voucher has expired and cannot be applied.',
//             });
//             return;
//           }

//           // Check if the voucher has already been fully redeemed
//           if (voucher.voucherRedeemCount === '0') {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return; 
//           }

//           if (voucher && user) {
//             // Fetch and update the user's AlgoMarix data in a single API call
//             const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
//             if (result) {
//               const { accountVoucherCheck } = result;
      
//               // Apply check only if the user exists and accountVoucherCheck is "0"
//               if (accountVoucherCheck === '0' && planType !== 'subscribe') {
//                 setAlert({
//                   visible: true,
//                   type: 'error',
//                   title: 'Voucher Error',
//                   message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
//                 });
//                 return;
//               }
            
//               // Handle voucher redeem check logic
//               if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: 'You have already redeemed this voucher code and cannot redeem it again.',
//                 });
//                 return;
//               }

//               // Handle specific redeem count 
//               if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
//                 });
//                 return;
//               }

//               let newAmount = parseFloat(plan.planAmount);

//               // Adjust for annual plan period
//               if (plan.planPeriod === '365') {
//                 newAmount = newAmount * 12;
//               }
    
//               if (voucher.voucherType === 'By Amount') {
//                 newAmount -= parseFloat(voucher.voucherAmount);
//               } else if (voucher.voucherType === 'By Percentage') {
//                 newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
//               }
    
//               // Update voucherDays in the subscription data
//               setVoucherDays(remainingDays);

//               // Now that the voucher is successfully applied, decrement the redeem count
//               await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
//               await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

//               setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
//               setVoucherRedeemCount(voucher.voucherRedeemCount);
//               setVoucherApplied(true); 
//               setAlert({
//                 visible: true,
//                 type: 'success',
//                 title: 'Voucher Applied',
//                 message: 'Voucher applied successfully!',
//               });
//           } else {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Failed to retrieve user data. Please try again.',
//             });
//           }
//         }
//       } catch (error) {
//         setDiscountedPlanAmount(plan.planAmount);
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Voucher Error',
//           message: 'Failed to apply the voucher. Please check the voucher code and try again.',
//         });
//       }
//     } else if (voucherApplied) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Voucher Error',
//         message: 'This voucher has already been applied to this order.',
//       });
//     }
//   };

//   const handlePlanTypeChange = async (type: string) => {
//     if (type === 'Renew') {
//       setIsRenewal(true);
//     } else {
//       setIsRenewal(false);
//     }
  
//     if (type === 'trial' && user?.email && plan.planID) {
//       const { userRole, isEntitled } = await fetchUserRoleAndEntitlement(user.email, parseInt(plan.planID, 10));
  
//       if (userRole && isEntitled) {
//         setPlanType(type);

//       // Fetch trial period from the backend and set it in state
//       const trialDays = await fetchTrialPeriod(parseInt(plan.planID, 10));
//         setPlanTrialDays(trialDays);
//       } else {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Trial Selection Error',
//           message: 'You are not entitled to select the trial. Switching back to Subscribe.',
//         });
//         setPlanType('subscribe');
//       }
//     } else {
//       setPlanType(type);
//       setPlanDays(parseInt(plan.planPeriod, 10));
//       setPlanTrialDays(null);
//     }
//   };

//   // function for click subscribe button
//   const handleSubscribeClick = async () => {
//     if (!user) return;

//     // Prevent double-click by checking if the process is already in progress
//     if (isSubmitting) {
//       setAlert({
//         visible: true,
//         type: 'warning',
//         title: 'Subscription in Progress',
//         message: 'Your subscription is already being processed. Please wait...',
//       });
//       return;
//     }

//     // Use the validate function instead of validateForm
//     if (!validate({
//         planType,
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         paymentMethod,
//         paymentReceipts: receipts,
//         termsAccepted
//     })) {
//         setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Please scroll up and review to ensure all required fields are filled in.',
//         });
//         return;
//     }

//     setIsSubmitting(true); 
//     setAlert({
//         visible: true,
//         type: 'confirm',
//         message: 'Make sure you provided correct information before subscribing?'
//     });
//   };

//   // function for confirm subscribe submit
//   const confirmSubscribe = async () => {
//     try {
//       setAlert((prev) => ({ ...prev, isLoading: true }));
  
//       // Here, you would gather the form data
//       const subscriptionHubData: SubscriptionHubData = {
//         planID: plan.planID || '',
//         orderID,
//         name: user?.username || '',
//         phone: user?.phone || '',
//         email: user?.email || '',
//         referralEmail,
//         planNameHub: plan.planName,
//         planTypeHub: planType,
//         planPeriodHub: plan.planPeriod || '',
//         planAmountHub: discountedPlanAmount,
//         voucherCodeHub: voucherCode,
//         renewalDate: '', 
//         expireDays: '',
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         PaymentMethodHub: paymentMethod,
//         PaymentReceiptsHub: receipts.map(receipt => receipt.name).join(', '),
//         notes,
//         memberType: defaultMemberType,
//         image: '', 
//         termAgreed: termsAccepted,
//       };
  
//       const response = await submitTraderHubSubscription(subscriptionHubData, receipts);
  
//       if (response.success) {
//         setAlert({
//           type: 'success',
//           message: 'Subscription successful!',
//           visible: true,
//           isLoading: false,
//         });
//       } else {
//         setAlert({
//           type: 'error',
//           title: 'Subscription failed',
//           message: 'If you need support, please contact <a href="mailto:general@r1trader.com">general@r1trader.com</a>',
//           visible: true,
//           isLoading: false
//         })
//       }
//     } catch (error: any) {
//       setAlert({
//         type: 'error',
//         title: 'Subscription failed',
//         message: error.message || 'An error occurred during the subscription process.',
//         visible: true,
//         isLoading: false,
//       });
//     }
//   };  

//   // // function for submit reciept upload
//   // const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//   //   const newReceipts = [...receipts];
//   //   if (e.target.files && e.target.files[0]) {
//   //     newReceipts[index] = e.target.files[0];
//   //     setReceipts(newReceipts);
//   //   }
//   // };  

//   return (
//     <div>
//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <form>
//           <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//               Basic Information
//             </span>
//           </div>

//           {/* Description Field */}
//           <div className="my-4">
//             <p className="mb-6 font-semibold">{plan.planDescription}</p>
//           </div>

//           {/* Order ID Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
//           </div>

//           {/* Username Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
//           </div>

//           {/* Phone Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="phone">Contact No.</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="phone" 
//               type="text" 
//               value={user?.phone || ''} disabled />
//           </div>

//           {/* Email Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
//           </div>

//           {/* Referral Email Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
//               Referral Email
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//               <div className="text-xs font-thin ml-2 text-rose-500">
//                 ( Enter your referrer's email or leave as default. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={isReferralEnabled}
//                 onChange={() => setIsReferralEnabled(!isReferralEnabled)}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="referral-email"
//               type="email"
//               value={referralEmail}
//               onChange={(e) => setReferralEmail(e.target.value)}
//               disabled={!isReferralEnabled}
//               placeholder="Enter referral email"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={handleApplyReferralEmail}
//               disabled={applyReferralDisabled || referralApplied}
//             >
//               Apply
//             </button>
//           </div>
          
//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Plan Name Field */}
//           <div className="mb-4">
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
//             </div>
//             <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="plan-name" 
//               type="text" 
//               value={plan.planName} disabled 
//             />
//           </div>

//           {/* Plan Trial Period Field */}
//           {planType === 'trial' ? (
//             <>
//               <div className="mb-4">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="plan-trial-period"
//                 >
//                   Plan Trial Period (days)
//                 </label>
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                   id="plan-trial-period" 
//                   type="text" 
//                   value={planTrialDays !== null && planTrialDays !== undefined ? planTrialDays.toString() : ''} 
//                   disabled 
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="mb-4">
//               {/* Plan Period (Days) Field */}
//               <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-period" 
//                 type="text" 
//                 value={planDays !== null && planDays !== undefined ? planDays.toString() : ''} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Plan Type Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center ">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
//                 htmlFor="plan-type">
//                   Plan Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
//               </div>
//             </div>
//             <select
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
//               id="plan-type"
//               value={planType}
//               onChange={(e) => { 
//                 handlePlanTypeChange(e.target.value); 
//                 setPlanType(e.target.value);
//                 if (errors.planType) {
//                   setErrors((prevErrors) => ({ ...prevErrors, planType:false }));
//                 }
//               }}
//               disabled={isRenewal}
//             >
//               <option value="">Select a Plan Type</option> 
//               <option value="trial">Trial</option>
//               <option value="subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           {/* Plan Amount Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
//                 <FaInfoCircle 
//                   className="w-4 h-4 mb-2 ml-2 cursor-pointer"
//                 />
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   (Conversion based on today’s rate:  <span className="font-bold text-sm">{`RM ${myrAmount}`}</span>)
//                 </div>
//               </div>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-amount" 
//                 type="text" 
//                 value={`${discountedPlanAmount} ${plan.planCurrency}`} 
//                 disabled 
//               />
//           </div>
          
//           )}

//           {/* Apply Voucher Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
//               Apply Voucher
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {voucherEnabled ? (
//                   <span>
//                     ( 
//                       The voucher is valid until 
//                         <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
//                       left only 
//                         <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
//                       Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
//                     )
//                   </span>
//                 ) : (
//                   <span>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                 )}
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={voucherEnabled}
//                 onChange={() => setVoucherEnabled(!voucherEnabled)}
//                 disabled={planType === 'trial'}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="voucher-code"
//               type="text"
//               value={voucherCode}
//               onChange={(e) => setVoucherCode(e.target.value)}
//               disabled={!voucherEnabled}
//               placeholder="Enter voucher code"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={applyVoucher}
//               disabled={applyDisabled}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>
//           {/* Trading Account Section (Only enabled if account type is selected) */}
//           <div className="p-4 border rounded-xl">
//             <div className="text-rose-600 mb-4">
//                 Only need to fill in this field if there is a requirement to show and verify your trading account; otherwise, you can leave it blank. For questions, contact 
//               <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
//             </div>

//             {/* trade account section */}
//             {/* Account Type Field (Updated with checkboxes) */}
//             <div className="mb-4">
//               <div className="flex justify-center items-center mb-4">
//                 <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Trading Account</span>
//               </div>
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold">
//                   Account Type
//                 </label>
//                 <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( choose only if required to fill in trade account detail )
//                 </div>
//               </div>
//               <div className="flex flex-col">
//                 <div className="flex justify-normal items-center">
//                   <label className="flex items-center">
//                     <input
//                       type="checkbox"
//                       checked={selectedAccountType === 'LIVE'}
//                       onChange={() => handleAccountTypeChange('LIVE')}
//                       className="mr-2 accent-cyan-800"
//                     />
//                   </label>
//                   <span>LIVE</span>
//                 </div>
//                 <div className="flex justify-normal items-center">
//                   <label className="flex items-center">
//                     <input
//                       type="checkbox"
//                       checked={selectedAccountType === 'DEMO'}
//                       onChange={() => handleAccountTypeChange('DEMO')}
//                       className="mr-2 accent-cyan-800"
//                     />
//                   </label>
//                   <span>DEMO</span>
//                 </div>
//               </div>
//             </div>

//             <fieldset 
//               disabled={!isAccountSectionEnabled} 
//               className={!isAccountSectionEnabled ? 'opacity-50' : ''}
//             >
//               {/* Account Server field */}
//               <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="account-server"
//                 >
//                   Account Server
//                 </label>
//                 <div className="relative group mx-2">
//                   <div className="flex justify-between items-center">
//                     <FaInfoCircle 
//                       className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3 text-cyan-800"
//                       aria-hidden="true"
//                     />
//                   </div>
//                   {/* Tooltip */}
//                   <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs text-white bg-cyan-800">
//                     You can find your account server details in your trading platform under the account information section.
//                     <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                   </div>
//                 </div>
//                 <div className="text-xs text-rose-600 mb-2">
//                   ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
//                 </div>
//               </div>
//               <input
//                   className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountServer ? 'border-rose-600' : ''}`}
//                   id="account-server"
//                   type="text"
//                   value={accountServer}
//                   onChange={(e) => {
//                     setAccountServer(e.target.value);
//                     if (errors.accountServer) {
//                       setErrors((prevErrors) => ({ ...prevErrors, accountServer: false }));
//                     }
//                   }}
//                   disabled={!isAccountSectionEnabled}
//                 />
//               </div>
              
//               {/* Account Number field */}  
//               <div className="mb-4">
//                 <div className="flex justify-normal items-center">
//                   <label className="block mb-2 font-semibold" htmlFor="account-number">
//                     Account Number
//                   </label>
//                   <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                   <div className="text-xs text-rose-600 mb-2 ml-2">
//                     ( This is your trading account id number )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountNumber ? 'border-rose-600' : ''}`}
//                   id="account-number"
//                   type="number"
//                   value={accountNumber}
//                   onChange={(e) => {
//                     setAccountNumber(e.target.value);
//                     if (errors.accountNumber) {
//                       setErrors((prevErrors) => ({ ...prevErrors, accountNumber: false }));
//                     }
//                   }}
//                   disabled={!isAccountSectionEnabled}
//                 />
//               </div>
              
//               {/* Account Password field */} 
//               <div className="mb-4">
//                 <div className="flex justify-normal items-center">
//                   <label className="block mb-2 font-semibold" htmlFor="account-password">
//                     Account Password
//                   </label>
//                   <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                   <div className="text-xs text-rose-600 mb-2 ml-2">
//                     ( This is your trading account investor "read-only" password. )
//                   </div>
//                 </div>
//                 <input
//                   className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountPassword ? 'border-rose-600' : ''}`}
//                   id="account-password"
//                   type="password"
//                   value={accountPassword}
//                   onChange={(e) => {
//                     setAccountPassword(e.target.value);
//                     if (errors.accountPassword) {
//                       setErrors((prevErrors) => ({ ...prevErrors, accountPassword:false }));
//                     }
//                   }}
//                   disabled={!isAccountSectionEnabled}
//                 />
//               </div>
//             </fieldset>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//           </div>

//           {/* Payment Method Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block font-semibold xs:w-[60px]">Payment Method</label>
//                 <FaInfoCircle className="ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   ( choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mb-2">
//                 <input
//                   type="radio"
//                   id="bank-transfer"
//                   name="payment-method"
//                   value="Bank Transfer"
//                   checked={paymentMethod === 'Bank Transfer'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4 ">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center sm:text-xs xs:text-xs">
//                     <div>
//                       <li>FINOX TRADE MARKET SDN. BHD.</li>
//                       <li>Hong leong bank</li>
//                       <li>23600590742</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("23600590742")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="usdt"
//                   name="payment-method"
//                   value="USDT"
//                   checked={paymentMethod === 'USDT'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-cente sm:text-xs accent-cyan-800">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Plan Trial Requirement Field */}
//           {planType === 'trial' && (
//             <div className="mb-4">
//               <p className="font-semibold">Plan Trial Requirement:</p>
//               <ul className="list-disc list-inside">
//                 <li>Deposit 500 USD on Lirunex Account</li>
//                 <li>
//                   <a 
//                     href="https://my.lirunex.online/Home/Signup?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className="text-blue-600 hover:underline"
//                   >
//                     Sign up for a Lirunex Account here
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           )}

//           {/* Payment Receipts Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="payment-receipts"
//               >
//                 {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
//               </label>
//               <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {planType === 'trial' 
//                   ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
//                   : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
//               type="file"
//               name="PaymentReceipt"
//               onChange={(e) => {
//                 const filesArray = [...receipts];
//                 if (e.target.files && e.target.files[0]) {
//                   filesArray.push(e.target.files[0]);
//                 }
//                 setReceipts(filesArray);
//                 if (errors.paymentReceipts) {
//                   setErrors((prevErrors) => ({ ...prevErrors, paymentReceipts: false }));
//                 }
//               }}
//             />
//           </div>

//           {/* Notes Field */}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </div>

//           {/* Terms and Conditions Field */}
//           <div className="flex justify-normal items-center mb-4">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//                 className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
//               />
//             </label>
//             <span 
//                 className="cursor-pointer text-cyan-800 underline" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//             </span>
//           </div>

//           {/* Submit Form Button */}
//           <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
//             <button 
//               onClick={onBack} 
//               className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//             >
//               <FaArrowCircleLeft />
//               <div className="ml-2">
//                 Back to Details
//               </div>
//             </button>
//             <button 
//               type="button"
//               onClick={handleSubscribeClick}
//               disabled={!termsAccepted || isSubmitting}
//               className={`px-4 py-2 rounded-md flex items-center justify-center text-white ${!termsAccepted || isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-cyan-800'}`}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">
//                 Subscribe
//               </div>
//             </button>
//           </div>
//         </form>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto max-w-[800px] max-h-screen lg:w-[800px] lg:h-[600px] md:w-[700px] md:h-[600px] sm:w-[450px] sm:h-[700px] xs:max-w-screen xs:h-[600px]">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       <MyTicketAlert 
//         type={alert.type} 
//         message={alert.message} 
//         visible={alert.visible} 
//         onClose={() => setAlert({ ...alert, visible: false })} 
//         onConfirm={confirmSubscribe} 
//         onCancel={closeAlert}
//         isLoading={alert.isLoading} 
//       />

//       <MyTicketAlert 
//         type={referralAlert.type} 
//         message={referralAlert.message} 
//         visible={referralAlert.visible} 
//         onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
//         onConfirm={confirmReferralApply}
//         onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
//       />
//     </div>
//   );
// };

// export default TraderHubRegistration;

// // udpate code --> 11 Sept 2024
// // src/components/client/MiniApp/App03_MySubscriptionRegistrationHub.tsx

// import React, { useState, useEffect } from 'react';
// import moment from 'moment-timezone';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaClipboard } from 'react-icons/fa';
// // import file
// import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
// import useSubscriptionSubmit, { SubscriptionHubData } from '../../../hooks/Subscription/useSubscriptionSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';
// import useCurrencyConversion from '../../../hooks/currencyAPI';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';

// import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png';

// // Add AlertType type definition here
// type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';

// interface TraderHubRegistrationProps {
//   plan: Plan;
//   onBack: () => void;
// }

// const TraderHubRegistration: React.FC<TraderHubRegistrationProps> = ({ plan, onBack }) => {
//   const { user } = useUserDashboard();
//   const { fetchTrialInfo, fetchUserRoleAndEntitlement } = useSubscriptionData();
//   const { 
//     submitTraderHubSubscription,
//     updateVoucherDaysInDatabase,
//     fetchVoucherDetails, 
//     fetchAndUpdateUserAlgoMarixData,
//     checkUserVoucherEntitlement, 
//   } = useSubscriptionSubmit();
//   const { validate, errors, setErrors } = useFormValidation();
//   const termsAndConditions = useEventRegTerms();
//   // component 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // form field state
//   const defaultMemberType = { type: 'R One Member' };
//   const [orderID, setOrderID] = useState('');
//   const [planType, setPlanType] = useState('');
//   const [planDays, setPlanDays] = useState<number | null>(null);
//   const [isConvertedToMYR, setIsConvertedToMYR] = useState(false);
//   const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
//   const { myrAmount } = useCurrencyConversion(0, parseFloat(discountedPlanAmount));
//   const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
//   const isAccountSectionEnabled = selectedAccountType !== null;
//   const [accountServer, setAccountServer] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   // apply trial
//   const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
//   const [planRequirement, setPlanRequirement] = useState<string[]>([]);
//   // apply referral
//   const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
//   const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
//   const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
//   const [referralApplied, setReferralApplied] = useState(false);
//   // apply voucher code
//   const [applyDisabled, setApplyDisabled] = useState(true);
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
//   const [voucherDays, setVoucherDays] = useState<number | null>(null);
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
//   const [voucherApplied, setVoucherApplied] = useState(false); 
//   // submit states
//   const [isSubmitting, setIsSubmitting] = useState(false); 
//   // renew 
//   const [isRenewal, setIsRenewal] = useState(false);
//   // set alert
//   const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
//   const [alert, setAlert] = useState<{ 
//     visible: boolean, 
//     type: AlertType, 
//     title?: string, 
//     message: string, 
//     isLoading?: boolean 
//   }>({ 
//     visible: false, 
//     type: 'check', 
//     message: '' 
//   });

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   const closeAlert = () => setReferralAlert({ visible: false, type: 'check', message: '' });

//   const handleAccountTypeChange = (type: string) => {
//     setSelectedAccountType(type === selectedAccountType ? null : type);
//   };

//   // generate order id for trader hub
//   useEffect(() => {
//     const generatedOrderID = 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//     setOrderID(generatedOrderID);
//   }, []);

//   //------------------- trial apply ----------------------//
//   // search for trial details
//   useEffect(() => {
//     const fetchTrialDetails = async () => {
//       if (planType === 'trial' && user?.email) {
//         const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

//         if (trialDays) {
//           setPlanTrialDays(trialDays);
//         } else {
//           setPlanTrialDays(null);
//         }

//         if (trialRequirement) {
//           setPlanRequirement(trialRequirement.split(',').map((req: string) => req.trim()));
//         } else {
//           setPlanRequirement([]);
//         }
//       } else {
//         setPlanDays(parseInt(plan.planPeriod, 10));
//         setPlanTrialDays(null);
//       }
//     };
//     fetchTrialDetails();
//   }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);

//   //------------------- voucher apply ----------------------//
//   // toggle voucher enable or disable
//   useEffect(() => {
//     setApplyDisabled(!voucherEnabled);
//   }, [voucherEnabled]);

//   // voucher plan amount effect
//   useEffect(() => {
//     let calculatedPlanAmount = parseFloat(plan.planAmount);

//     if (plan.planPeriod === '365') {
//       calculatedPlanAmount = calculatedPlanAmount * 12;
//     }

//     setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
//   }, [plan.planAmount, plan.planPeriod]);

//   // disable voucher for trial
//   useEffect(() => {
//     if (planType === 'trial') {
//       setVoucherEnabled(false);
//     }
//   }, [planType]);  
  
//   // Update the voucherEnabled useEffect
//   useEffect(() => {
//     const updateVoucherDetails = async () => {
//       if (voucherEnabled && voucherCode) {
//         try {

//           // Fetch voucher details
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
//           if (voucher) {
//             const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
//             const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
//             const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

//             // Update state with fetched data
//             setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
//             setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
//             setVoucherRedeemCount(voucher.voucherRedeemCount);

//             // Update the voucher days in the database
//             await updateVoucherDaysInDatabase(voucher.id, remainingDays);

//           } else {
//             setVoucherPeriod(null);
//             setVoucherDays(null);
//           }
//         } catch (error) {
//           console.error('Error fetching voucher details:', error);
//           setVoucherRedeemCount(0);
//         }
//       } else {
//         setVoucherPeriod(null);
//         setVoucherDays(null);
//       }
//     };

//     updateVoucherDetails();
//   }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

//   //------------------- referral apply ----------------------//
//   // toggle enabled or disable referral effect
//   useEffect(() => {
//     if (isReferralEnabled) {
//         setApplyReferralDisabled(false);
//     } else {
//         setApplyReferralDisabled(true);
//     }
//   }, [isReferralEnabled]);

//   //------------------- define function ----------------------//
//   // apply referral email function
//   const handleApplyReferralEmail = () => {
//     setReferralAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Do you want to apply this referral email?',
//     });
//     // Disable the referral email field after applying
//     setIsReferralEnabled(false);
//   };

//   // apply referral alert function
//   const confirmReferralApply = () => {
//     try {
//       setReferralApplied(true); // Set success status
//       setReferralAlert({
//         visible: true,
//         type: 'success',
//         title: 'Referral Applied',
//         message: 'Referral email applied successfully!',
//       });
//     } catch (error) {
//       setReferralApplied(false); // Set failure status
//       setReferralAlert({
//         visible: true,
//         type: 'error',
//         title: 'Referral Error',
//         message: 'Failed to apply the referral. Please try again.',
//       });
//     }
//   };

//   // apply voucher function
//   const applyVoucher = async () => {
//     if (voucherCode && !voucherApplied) {
//       try {
//           // Fetch voucher details without applying it yet to get the remaining days
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
//           if (!voucher) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return;
//           }

//           // Check if the user is entitled to redeem this voucher
//           const userEntitlement = await checkUserVoucherEntitlement(
//             voucher.voucherCode, 
//             voucher.voucherMemberType, 
//             voucher.voucherGroupMember
//           );

//           if (!userEntitlement) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'You are not entitled to redeem this voucher code.',
//             });
//             return;
//           }

//           // Check if the voucher is expired
//           const currentDate = moment().tz("Asia/Kuala_Lumpur");
//           const voucherPeriodDate = moment(voucher.voucherPeriod);
//           const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

//           if (remainingDays < 0) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'The voucher has expired and cannot be applied.',
//             });
//             return;
//           }

//           // Check if the voucher has already been fully redeemed
//           if (voucher.voucherRedeemCount === '0') {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return; 
//           }

//           if (voucher && user) {
//             // Fetch and update the user's AlgoMarix data in a single API call
//             const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
//             if (result) {
//               const { accountVoucherCheck } = result;
      
//               // Apply check only if the user exists and accountVoucherCheck is "0"
//               if (accountVoucherCheck === '0' && planType !== 'subscribe') {
//                 setAlert({
//                   visible: true,
//                   type: 'error',
//                   title: 'Voucher Error',
//                   message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
//                 });
//                 return;
//               }
            
//               // Handle voucher redeem check logic
//               if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: 'You have already redeemed this voucher code and cannot redeem it again.',
//                 });
//                 return;
//               }

//               // Handle specific redeem count 
//               if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
//                 });
//                 return;
//               }

//               let newAmount = parseFloat(plan.planAmount);

//               // Adjust for annual plan period
//               if (plan.planPeriod === '365') {
//                 newAmount = newAmount * 12;
//               }
    
//               if (voucher.voucherType === 'By Amount') {
//                 newAmount -= parseFloat(voucher.voucherAmount);
//               } else if (voucher.voucherType === 'By Percentage') {
//                 newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
//               }
    
//               // Update voucherDays in the subscription data
//               setVoucherDays(remainingDays);

//               // Now that the voucher is successfully applied, decrement the redeem count
//               await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
//               await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

//               setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
//               setVoucherRedeemCount(voucher.voucherRedeemCount);
//               setVoucherApplied(true); 
//               setAlert({
//                 visible: true,
//                 type: 'success',
//                 title: 'Voucher Applied',
//                 message: 'Voucher applied successfully!',
//               });
//           } else {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Failed to retrieve user data. Please try again.',
//             });
//           }
//         }
//       } catch (error) {
//         setDiscountedPlanAmount(plan.planAmount);
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Voucher Error',
//           message: 'Failed to apply the voucher. Please check the voucher code and try again.',
//         });
//       }
//     } else if (voucherApplied) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Voucher Error',
//         message: 'This voucher has already been applied to this order.',
//       });
//     }
//   };

//   const handlePlanTypeChange = async (type: string) => {
//     if (type === 'Renew') {
//       setIsRenewal(true);
//     } else {
//       setIsRenewal(false);
//     }
  
//     if (type === 'trial' && user?.email) {
//       const { userRole, isEntitled } = await fetchUserRoleAndEntitlement(user.email, parseInt(plan.planID, 10));
  
//       if (userRole && isEntitled) {
//         setPlanType(type);
//         const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));
//         console.log('Fetched trial info:', { trialRequirement, trialDays }); // Debug log
//         setPlanTrialDays(trialDays);
//         setPlanRequirement(trialRequirement ? trialRequirement.split(',').map((req: string) => req.trim()) : []);
//       } else {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Trial Selection Error',
//           message: 'You are not entitled to select the trial. Switching back to Subscribe.',
//         });
//         setPlanType('subscribe');
//       }
//     } else {
//       setPlanType(type);
//       setPlanDays(parseInt(plan.planPeriod, 10));
//       setPlanTrialDays(null);
//       setPlanRequirement([]);
//     }
//   };

//   // function for click subscribe button
//   const handleSubscribeClick = async () => {
//     if (!user) return;

//     // Prevent double-click by checking if the process is already in progress
//     if (isSubmitting) {
//       setAlert({
//         visible: true,
//         type: 'warning',
//         title: 'Subscription in Progress',
//         message: 'Your subscription is already being processed. Please wait...',
//       });
//       return;
//     }

//     // Use the validate function instead of validateForm
//     if (!validate({
//         planType,
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         paymentMethod,
//         paymentReceipts: receipts,
//         termsAccepted
//     })) {
//         setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Please scroll up and review to ensure all required fields are filled in.',
//         });
//         return;
//     }

//     setIsSubmitting(true); 
//     setAlert({
//         visible: true,
//         type: 'confirm',
//         message: 'Make sure you provided correct information before subscribing?'
//     });
//   };

//   // function for confirm subscribe submit
//   const confirmSubscribe = async () => {
//     try {
//       setAlert((prev) => ({ ...prev, isLoading: true }));
  
//       // Here, you would gather the form data
//       const subscriptionHubData: SubscriptionHubData = {
//         planID: plan.planID || '',
//         orderID,
//         name: user?.username || '',
//         phone: user?.phone || '',
//         email: user?.email || '',
//         referralEmail,
//         planNameHub: plan.planName,
//         planTypeHub: planType,
//         planPeriodHub: plan.planPeriod || '',
//         planAmountHub: discountedPlanAmount,
//         voucherCodeHub: voucherCode,
//         renewalDate: '', 
//         expireDays: '',
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         PaymentMethodHub: paymentMethod,
//         PaymentReceiptsHub: receipts.map(receipt => receipt.name).join(', '),
//         notes,
//         memberType: defaultMemberType,
//         image: '', 
//         termAgreed: termsAccepted,
//       };
  
//       const response = await submitTraderHubSubscription(subscriptionHubData, receipts);
  
//       if (response.success) {
//         setAlert({
//           type: 'success',
//           message: 'Subscription successful!',
//           visible: true,
//           isLoading: false,
//         });
//       } else {
//         setAlert({
//           type: 'error',
//           title: 'Subscription failed',
//           message: 'If you need support, please contact <a href="mailto:general@r1trader.com">general@r1trader.com</a>',
//           visible: true,
//           isLoading: false
//         })
//       }
//     } catch (error: any) {
//       setAlert({
//         type: 'error',
//         title: 'Subscription failed',
//         message: error.message || 'An error occurred during the subscription process.',
//         visible: true,
//         isLoading: false,
//       });
//     }
//   };  

//   // // function for submit reciept upload
//   // const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//   //   const newReceipts = [...receipts];
//   //   if (e.target.files && e.target.files[0]) {
//   //     newReceipts[index] = e.target.files[0];
//   //     setReceipts(newReceipts);
//   //   }
//   // };  

//   return (
//     <div>
//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <form>
//           <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//               Basic Information
//             </span>
//           </div>

//           {/* Description Field */}
//           <div className="my-4">
//             <p className="mb-6 font-semibold">{plan.planDescription}</p>
//           </div>

//           {/* Order ID Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
//           </div>

//           {/* Username Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
//           </div>

//           {/* Phone Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="phone">Contact No.</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="phone" 
//               type="text" 
//               value={user?.phone || ''} disabled />
//           </div>

//           {/* Email Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
//           </div>

//           {/* Referral Email Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
//               Referral Email
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//               <div className="text-xs font-thin ml-2 text-rose-500">
//                 ( Enter your referrer's email or leave as default. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={isReferralEnabled}
//                 onChange={() => setIsReferralEnabled(!isReferralEnabled)}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="referral-email"
//               type="email"
//               value={referralEmail}
//               onChange={(e) => setReferralEmail(e.target.value)}
//               disabled={!isReferralEnabled}
//               placeholder="Enter referral email"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={handleApplyReferralEmail}
//               disabled={applyReferralDisabled || referralApplied}
//             >
//               Apply
//             </button>
//           </div>
          
//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Plan Name Field */}
//           <div className="mb-4">
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
//             </div>
//             <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="plan-name" 
//               type="text" 
//               value={plan.planName} disabled 
//             />
//           </div>

//           {/* Plan Trial Period Field */}
//           {planType === 'trial' ? (
//             <>
//               <div className="mb-4">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="plan-trial-period"
//                 >
//                   Plan Trial Period (days)
//                 </label>
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                   id="plan-trial-period" 
//                   type="text" 
//                   value={planTrialDays !== null && planTrialDays !== undefined ? planTrialDays.toString() : ''} 
//                   disabled 
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="mb-4">
//               {/* Plan Period (Days) Field */}
//               <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-period" 
//                 type="text" 
//                 value={planDays !== null && planDays !== undefined ? planDays.toString() : ''} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Plan Type Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center ">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
//                 htmlFor="plan-type">
//                   Plan Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
//               </div>
//             </div>
//             <select
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
//               id="plan-type"
//               value={planType}
//               onChange={(e) => { 
//                 handlePlanTypeChange(e.target.value); 
//                 setPlanType(e.target.value);
//                 if (errors.planType) {
//                   setErrors((prevErrors) => ({ ...prevErrors, planType:false }));
//                 }
//               }}
//               disabled={isRenewal}
//             >
//               <option value="">Select a Plan Type</option> 
//               <option value="trial">Trial</option>
//               <option value="subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           {/* Plan Amount Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
//                 <FaInfoCircle 
//                   className="w-4 h-4 mb-2 ml-2 cursor-pointer"
//                   onClick={() => setIsConvertedToMYR(!isConvertedToMYR)} // Toggle between USD and MYR
//                 />
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   (Click "!" to convert to {isConvertedToMYR ? "USD" : "MYR"})
//                 </div>
//               </div>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-amount" 
//                 type="text" 
//                 value={isConvertedToMYR ? `${myrAmount} MYR` : `${discountedPlanAmount} ${plan.planCurrency}`} 
//                 disabled 
//               />
//           </div>
          
//           )}

//           {/* Apply Voucher Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
//               Apply Voucher
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {voucherEnabled ? (
//                   <span>
//                     ( 
//                       The voucher is valid until 
//                         <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
//                       left only 
//                         <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
//                       Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
//                     )
//                   </span>
//                 ) : (
//                   <span>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                 )}
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={voucherEnabled}
//                 onChange={() => setVoucherEnabled(!voucherEnabled)}
//                 disabled={planType === 'trial'}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="voucher-code"
//               type="text"
//               value={voucherCode}
//               onChange={(e) => setVoucherCode(e.target.value)}
//               disabled={!voucherEnabled}
//               placeholder="Enter voucher code"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={applyVoucher}
//               disabled={applyDisabled}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* trade account section */}
//           {/* Account Type Field (Updated with checkboxes) */}
//           <div className="mb-4">
//             <div className="flex justify-center items-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Trading Account</span>
//             </div>
//             <div className="flex justify-normal items-center">
//               <label className="block mb-2 font-semibold">
//                 Account Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( choose only if required to fill in trade account detail )
//               </div>
//             </div>
//             <div className="flex flex-col">
//               <div className="flex justify-normal items-center">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={selectedAccountType === 'LIVE'}
//                     onChange={() => handleAccountTypeChange('LIVE')}
//                     className="mr-2 accent-cyan-800"
//                   />
//                 </label>
//                 <span>LIVE</span>
//               </div>
//               <div className="flex justify-normal items-center">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={selectedAccountType === 'DEMO'}
//                     onChange={() => handleAccountTypeChange('DEMO')}
//                     className="mr-2 accent-cyan-800"
//                   />
//                 </label>
//                 <span>DEMO</span>
//               </div>
//             </div>
//           </div>

//           {/* Trading Account Section (Only enabled if account type is selected) */}
//           <fieldset 
//             disabled={!isAccountSectionEnabled} 
//             className={!isAccountSectionEnabled ? 'opacity-50' : ''}
//           >
//             {/* Account Server field */}
//             <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="account-server"
//               >
//                 Account Server
//               </label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3 text-cyan-800"
//                     aria-hidden="true"
//                   />
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs text-white bg-cyan-800">
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                 </div>
//               </div>
//               <div className="text-xs text-rose-600 mb-2">
//                 ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
//               </div>
//             </div>
//             <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountServer ? 'border-rose-600' : ''}`}
//                 id="account-server"
//                 type="text"
//                 value={accountServer}
//                 onChange={(e) => {
//                   setAccountServer(e.target.value);
//                   if (errors.accountServer) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountServer: false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
            
//             {/* Account Number field */}  
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="account-number">
//                   Account Number
//                 </label>
//                 <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( This is your trading account id number )
//                 </div>
//               </div>
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountNumber ? 'border-rose-600' : ''}`}
//                 id="account-number"
//                 type="number"
//                 value={accountNumber}
//                 onChange={(e) => {
//                   setAccountNumber(e.target.value);
//                   if (errors.accountNumber) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountNumber: false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
            
//             {/* Account Password field */} 
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="account-password">
//                   Account Password
//                 </label>
//                 <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( This is your trading account investor "read-only" password. )
//                 </div>
//               </div>
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountPassword ? 'border-rose-600' : ''}`}
//                 id="account-password"
//                 type="password"
//                 value={accountPassword}
//                 onChange={(e) => {
//                   setAccountPassword(e.target.value);
//                   if (errors.accountPassword) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountPassword:false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
//           </fieldset>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//           </div>

//           {/* Payment Method Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block font-semibold xs:w-[60px]">Payment Method</label>
//                 <FaInfoCircle className="ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   ( choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mb-2">
//                 <input
//                   type="radio"
//                   id="bank-transfer"
//                   name="payment-method"
//                   value="Bank Transfer"
//                   checked={paymentMethod === 'Bank Transfer'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4 ">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center sm:text-xs xs:text-xs">
//                     <div>
//                       <li>FINOX TRADE MARKET SDN. BHD.</li>
//                       <li>Hong leong bank</li>
//                       <li>23600590742</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("23600590742")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="usdt"
//                   name="payment-method"
//                   value="USDT"
//                   checked={paymentMethod === 'USDT'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-cente sm:text-xs accent-cyan-800">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}

//           Plan Trial Requirement Field
//           {planType === 'trial' && (
//             <div className="mb-4">
//               <p className="font-semibold">Plan Trial Requirement:</p>
//               <ul className="list-disc list-inside">
//                 {Array.isArray(planRequirement) && planRequirement.map((requirement, index) => (
//                   <li key={index}>{requirement}</li>
//                 ))}
//               </ul>
//             </div>
//           )}

//           {/* Payment Receipts Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="payment-receipts"
//               >
//                 {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
//               </label>
//               <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {planType === 'trial' 
//                   ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
//                   : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
//               type="file"
//               name="PaymentReceipt"
//               onChange={(e) => {
//                 const filesArray = [...receipts];
//                 if (e.target.files && e.target.files[0]) {
//                   filesArray.push(e.target.files[0]);
//                 }
//                 setReceipts(filesArray);
//                 if (errors.paymentReceipts) {
//                   setErrors((prevErrors) => ({ ...prevErrors, paymentReceipts: false }));
//                 }
//               }}
//             />
//           </div>

//           {/* Notes Field */}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </div>

//           {/* Terms and Conditions Field */}
//           <div className="flex justify-normal items-center mb-4">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//                 className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
//               />
//             </label>
//             <span 
//                 className="cursor-pointer text-cyan-800 underline" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//             </span>
//           </div>

//           {/* Submit Form Button */}
//           <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
//             <button 
//               onClick={onBack} 
//               className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//             >
//               <FaArrowCircleLeft />
//               <div className="ml-2">
//                 Back to Details
//               </div>
//             </button>
//             <button 
//               type="button"
//               onClick={handleSubscribeClick}
//               disabled={!termsAccepted || isSubmitting}
//               className={`px-4 py-2 rounded-md flex items-center justify-center text-white ${!termsAccepted || isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-cyan-800'}`}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">
//                 Subscribe
//               </div>
//             </button>
//           </div>
//         </form>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto max-w-[800px] max-h-screen lg:w-[800px] lg:h-[600px] md:w-[700px] md:h-[600px] sm:w-[450px] sm:h-[700px] xs:max-w-screen xs:h-[600px]">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       <MyTicketAlert 
//         type={alert.type} 
//         message={alert.message} 
//         visible={alert.visible} 
//         onClose={() => setAlert({ ...alert, visible: false })} 
//         onConfirm={confirmSubscribe} 
//         onCancel={closeAlert}
//         isLoading={alert.isLoading} 
//       />

//       <MyTicketAlert 
//         type={referralAlert.type} 
//         message={referralAlert.message} 
//         visible={referralAlert.visible} 
//         onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
//         onConfirm={confirmReferralApply}
//         onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
//       />
//     </div>
//   );
// };

// export default TraderHubRegistration;

// // udpate code --> 31 Aug 2024
// // src/components/client/MiniApp/App03_MySubscriptionRegistrationHub.tsx

// import React, { useState, useEffect } from 'react';
// import moment from 'moment-timezone';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaClipboard } from 'react-icons/fa';
// // import file
// import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
// import useSubscriptionSubmit, { SubscriptionHubData } from '../../../hooks/Subscription/useSubscriptionSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';

// import { useEventRegTerms } from '../../../context/SubRegTermContext';

// import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png';

// // Add AlertType type definition here
// type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';

// interface TraderHubRegistrationProps {
//   plan: Plan;
//   onBack: () => void;
// }

// const TraderHubRegistration: React.FC<TraderHubRegistrationProps> = ({ plan, onBack }) => {
//   const { user } = useUserDashboard();
//   const { fetchTrialInfo, fetchUserRoleAndEntitlement } = useSubscriptionData();
//   const { 
//     submitTraderHubSubscription,
//     updateVoucherDaysInDatabase,
//     fetchVoucherDetails, 
//     fetchAndUpdateUserAlgoMarixData,
//     checkUserVoucherEntitlement, 
//   } = useSubscriptionSubmit();
//   const { validate, errors, setErrors } = useFormValidation();
//   const termsAndConditions = useEventRegTerms();
//   // component 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // form field state
//   const defaultMemberType = { type: 'R One Member' };
//   const [orderID, setOrderID] = useState('');
//   const [planType, setPlanType] = useState('');
//   const [planDays, setPlanDays] = useState<number | null>(null);
//   const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
//   const isAccountSectionEnabled = selectedAccountType !== null;
//   const [accountServer, setAccountServer] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   // apply trial
//   const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
//   const [planRequirement, setPlanRequirement] = useState<string[]>([]);
//   // apply referral
//   const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
//   const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
//   const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
//   const [referralApplied, setReferralApplied] = useState(false);
//   // apply voucher code
//   const [applyDisabled, setApplyDisabled] = useState(true);
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
//   const [voucherDays, setVoucherDays] = useState<number | null>(null);
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
//   const [voucherApplied, setVoucherApplied] = useState(false);
//   const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
//   // submit states
//   const [isSubmitting, setIsSubmitting] = useState(false); 
//   // renew 
//   const [isRenewal, setIsRenewal] = useState(false);
//   // set alert
//   const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
//   const [alert, setAlert] = useState<{ 
//     visible: boolean, 
//     type: AlertType, 
//     title?: string, 
//     message: string, 
//     isLoading?: boolean 
//   }>({ 
//     visible: false, 
//     type: 'check', 
//     message: '' 
//   });

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   const closeAlert = () => setReferralAlert({ visible: false, type: 'check', message: '' });

//   const handleAccountTypeChange = (type: string) => {
//     setSelectedAccountType(type === selectedAccountType ? null : type);
//   };

//   // generate order id for trader hub
//   useEffect(() => {
//     const generatedOrderID = 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//     setOrderID(generatedOrderID);
//   }, []);

//   //------------------- trial apply ----------------------//
//   // search for trial details
//   useEffect(() => {
//     const fetchTrialDetails = async () => {
//       if (planType === 'trial' && user?.email) {
//         const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

//         if (trialDays) {
//           setPlanTrialDays(trialDays);
//         } else {
//           setPlanTrialDays(null);
//         }

//         if (trialRequirement) {
//           setPlanRequirement(trialRequirement.split(',').map((req: string) => req.trim()));
//         } else {
//           setPlanRequirement([]);
//         }
//       } else {
//         setPlanDays(parseInt(plan.planPeriod, 10));
//         setPlanTrialDays(null);
//       }
//     };
//     fetchTrialDetails();
//   }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);

//   //------------------- voucher apply ----------------------//
//   // toggle voucher enable or disable
//   useEffect(() => {
//     setApplyDisabled(!voucherEnabled);
//   }, [voucherEnabled]);

//   // voucher plan amount effect
//   useEffect(() => {
//     let calculatedPlanAmount = parseFloat(plan.planAmount);

//     if (plan.planPeriod === '365') {
//       calculatedPlanAmount = calculatedPlanAmount * 12;
//     }

//     setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
//   }, [plan.planAmount, plan.planPeriod]);

//   // disable voucher for trial
//   useEffect(() => {
//     if (planType === 'trial') {
//       setVoucherEnabled(false);
//     }
//   }, [planType]);  
  
//   // Update the voucherEnabled useEffect
//   useEffect(() => {
//     const updateVoucherDetails = async () => {
//       if (voucherEnabled && voucherCode) {
//         try {

//           // Fetch voucher details
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
//           if (voucher) {
//             const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
//             const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
//             const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

//             // Update state with fetched data
//             setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
//             setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
//             setVoucherRedeemCount(voucher.voucherRedeemCount);

//             // Update the voucher days in the database
//             await updateVoucherDaysInDatabase(voucher.id, remainingDays);

//           } else {
//             setVoucherPeriod(null);
//             setVoucherDays(null);
//           }
//         } catch (error) {
//           console.error('Error fetching voucher details:', error);
//           setVoucherRedeemCount(0);
//         }
//       } else {
//         setVoucherPeriod(null);
//         setVoucherDays(null);
//       }
//     };

//     updateVoucherDetails();
//   }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

//   //------------------- referral apply ----------------------//
//   // toggle enabled or disable referral effect
//   useEffect(() => {
//     if (isReferralEnabled) {
//         setApplyReferralDisabled(false);
//     } else {
//         setApplyReferralDisabled(true);
//     }
//   }, [isReferralEnabled]);

//   //------------------- define function ----------------------//
//   // apply referral email function
//   const handleApplyReferralEmail = () => {
//     setReferralAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Do you want to apply this referral email?',
//     });
//     // Disable the referral email field after applying
//     setIsReferralEnabled(false);
//   };

//   // apply referral alert function
//   const confirmReferralApply = () => {
//     try {
//       setReferralApplied(true); // Set success status
//       setReferralAlert({
//         visible: true,
//         type: 'success',
//         title: 'Referral Applied',
//         message: 'Referral email applied successfully!',
//       });
//     } catch (error) {
//       setReferralApplied(false); // Set failure status
//       setReferralAlert({
//         visible: true,
//         type: 'error',
//         title: 'Referral Error',
//         message: 'Failed to apply the referral. Please try again.',
//       });
//     }
//   };

//   // apply voucher function
//   const applyVoucher = async () => {
//     if (voucherCode && !voucherApplied) {
//       try {
//           // Fetch voucher details without applying it yet to get the remaining days
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
//           if (!voucher) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return;
//           }

//           // Check if the user is entitled to redeem this voucher
//           const userEntitlement = await checkUserVoucherEntitlement(
//             voucher.voucherCode, 
//             voucher.voucherMemberType, 
//             voucher.voucherGroupMember
//           );

//           if (!userEntitlement) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'You are not entitled to redeem this voucher code.',
//             });
//             return;
//           }

//           // Check if the voucher is expired
//           const currentDate = moment().tz("Asia/Kuala_Lumpur");
//           const voucherPeriodDate = moment(voucher.voucherPeriod);
//           const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

//           if (remainingDays < 0) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'The voucher has expired and cannot be applied.',
//             });
//             return;
//           }

//           // Check if the voucher has already been fully redeemed
//           if (voucher.voucherRedeemCount === '0') {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return; 
//           }

//           if (voucher && user) {
//             // Fetch and update the user's AlgoMarix data in a single API call
//             const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
//             if (result) {
//               const { accountVoucherCheck } = result;
      
//               // Apply check only if the user exists and accountVoucherCheck is "0"
//               if (accountVoucherCheck === '0' && planType !== 'subscribe') {
//                 setAlert({
//                   visible: true,
//                   type: 'error',
//                   title: 'Voucher Error',
//                   message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
//                 });
//                 return;
//               }
            
//               // Handle voucher redeem check logic
//               if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: 'You have already redeemed this voucher code and cannot redeem it again.',
//                 });
//                 return;
//               }

//               // Handle specific redeem count 
//               if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
//                 });
//                 return;
//               }

//               let newAmount = parseFloat(plan.planAmount);

//               // Adjust for annual plan period
//               if (plan.planPeriod === '365') {
//                 newAmount = newAmount * 12;
//               }
    
//               if (voucher.voucherType === 'By Amount') {
//                 newAmount -= parseFloat(voucher.voucherAmount);
//               } else if (voucher.voucherType === 'By Percentage') {
//                 newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
//               }
    
//               // Update voucherDays in the subscription data
//               setVoucherDays(remainingDays);

//               // Now that the voucher is successfully applied, decrement the redeem count
//               await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
//               await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

//               setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
//               setVoucherRedeemCount(voucher.voucherRedeemCount);
//               setVoucherApplied(true); 
//               setAlert({
//                 visible: true,
//                 type: 'success',
//                 title: 'Voucher Applied',
//                 message: 'Voucher applied successfully!',
//               });
//           } else {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Failed to retrieve user data. Please try again.',
//             });
//           }
//         }
//       } catch (error) {
//         setDiscountedPlanAmount(plan.planAmount);
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Voucher Error',
//           message: 'Failed to apply the voucher. Please check the voucher code and try again.',
//         });
//       }
//     } else if (voucherApplied) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Voucher Error',
//         message: 'This voucher has already been applied to this order.',
//       });
//     }
//   };

//   const handlePlanTypeChange = async (type: string) => {
//     if (type === 'Renew') {
//       setIsRenewal(true);
//     } else {
//       setIsRenewal(false);
//     }
  
//     if (type === 'trial' && user?.email) {
//       const { userRole, isEntitled } = await fetchUserRoleAndEntitlement(user.email, parseInt(plan.planID, 10));
  
//       if (userRole && isEntitled) {
//         setPlanType(type);
//         const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));
//         console.log('Fetched trial info:', { trialRequirement, trialDays }); // Debug log
//         setPlanTrialDays(trialDays);
//         setPlanRequirement(trialRequirement ? trialRequirement.split(',').map((req: string) => req.trim()) : []);
//       } else {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Trial Selection Error',
//           message: 'You are not entitled to select the trial. Switching back to Subscribe.',
//         });
//         setPlanType('subscribe');
//       }
//     } else {
//       setPlanType(type);
//       setPlanDays(parseInt(plan.planPeriod, 10));
//       setPlanTrialDays(null);
//       setPlanRequirement([]);
//     }
//   };

//   // function for click subscribe button
//   const handleSubscribeClick = async () => {
//     if (!user) return;

//     // Prevent double-click by checking if the process is already in progress
//     if (isSubmitting) {
//       setAlert({
//         visible: true,
//         type: 'warning',
//         title: 'Subscription in Progress',
//         message: 'Your subscription is already being processed. Please wait...',
//       });
//       return;
//     }

//     // Use the validate function instead of validateForm
//     if (!validate({
//         planType,
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         paymentMethod,
//         paymentReceipts: receipts,
//         termsAccepted
//     })) {
//         setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Please scroll up and review to ensure all required fields are filled in.',
//         });
//         return;
//     }

//     setIsSubmitting(true); 
//     setAlert({
//         visible: true,
//         type: 'confirm',
//         message: 'Make sure you provided correct information before subscribing?'
//     });
//   };

//   // function for confirm subscribe submit
//   const confirmSubscribe = async () => {
//     try {
//       setAlert((prev) => ({ ...prev, isLoading: true }));
  
//       // Here, you would gather the form data
//       const subscriptionHubData: SubscriptionHubData = {
//         planID: plan.planID || '',
//         orderID,
//         name: user?.username || '',
//         phone: user?.phone || '',
//         email: user?.email || '',
//         referralEmail,
//         planNameHub: plan.planName,
//         planTypeHub: planType,
//         planPeriodHub: plan.planPeriod || '',
//         planAmountHub: discountedPlanAmount,
//         voucherCodeHub: voucherCode,
//         renewalDate: '', 
//         expireDays: '',
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         PaymentMethodHub: paymentMethod,
//         PaymentReceiptsHub: receipts.map(receipt => receipt.name).join(', '),
//         notes,
//         memberType: defaultMemberType,
//         image: '', 
//         termAgreed: termsAccepted,
//       };
  
//       const response = await submitTraderHubSubscription(subscriptionHubData, receipts);
  
//       if (response.success) {
//         setAlert({
//           type: 'success',
//           message: 'Subscription successful!',
//           visible: true,
//           isLoading: false,
//         });
//       } else {
//         setAlert({
//           type: 'error',
//           title: 'Subscription failed',
//           message: 'If you need support, please contact <a href="mailto:general@r1trader.com">general@r1trader.com</a>',
//           visible: true,
//           isLoading: false
//         })
//       }
//     } catch (error: any) {
//       setAlert({
//         type: 'error',
//         title: 'Subscription failed',
//         message: error.message || 'An error occurred during the subscription process.',
//         visible: true,
//         isLoading: false,
//       });
//     }
//   };  

//   // // function for submit reciept upload
//   // const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//   //   const newReceipts = [...receipts];
//   //   if (e.target.files && e.target.files[0]) {
//   //     newReceipts[index] = e.target.files[0];
//   //     setReceipts(newReceipts);
//   //   }
//   // };  

//   return (
//     <div>
//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <form>
//           <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//               Basic Information
//             </span>
//           </div>

//           {/* Description Field */}
//           <div className="my-4">
//             <p className="mb-6 font-semibold">{plan.planDescription}</p>
//           </div>

//           {/* Order ID Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
//           </div>

//           {/* Username Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
//           </div>

//           {/* Phone Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="phone">Contact No.</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="phone" 
//               type="text" 
//               value={user?.phone || ''} disabled />
//           </div>

//           {/* Email Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
//           </div>

//           {/* Referral Email Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
//               Referral Email
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//               <div className="text-xs font-thin ml-2 text-rose-500">
//                 ( Enter your referrer's email or leave as default. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={isReferralEnabled}
//                 onChange={() => setIsReferralEnabled(!isReferralEnabled)}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="referral-email"
//               type="email"
//               value={referralEmail}
//               onChange={(e) => setReferralEmail(e.target.value)}
//               disabled={!isReferralEnabled}
//               placeholder="Enter referral email"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={handleApplyReferralEmail}
//               disabled={applyReferralDisabled || referralApplied}
//             >
//               Apply
//             </button>
//           </div>
          
//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Plan Name Field */}
//           <div className="mb-4">
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
//             </div>
//             <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="plan-name" 
//               type="text" 
//               value={plan.planName} disabled 
//             />
//           </div>

//           {/* Plan Trial Period Field */}
//           {planType === 'trial' ? (
//             <>
//               <div className="mb-4">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="plan-trial-period"
//                 >
//                   Plan Trial Period (days)
//                 </label>
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                   id="plan-trial-period" 
//                   type="text" 
//                   value={planTrialDays !== null && planTrialDays !== undefined ? planTrialDays.toString() : ''} 
//                   disabled 
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="mb-4">
//               {/* Plan Period (Days) Field */}
//               <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-period" 
//                 type="text" 
//                 value={planDays !== null && planDays !== undefined ? planDays.toString() : ''} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Plan Type Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center ">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
//                 htmlFor="plan-type">
//                   Plan Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
//               </div>
//             </div>
//             <select
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
//               id="plan-type"
//               value={planType}
//               onChange={(e) => { 
//                 handlePlanTypeChange(e.target.value); 
//                 setPlanType(e.target.value);
//                 if (errors.planType) {
//                   setErrors((prevErrors) => ({ ...prevErrors, planType:false }));
//                 }
//               }}
//               disabled={isRenewal}
//             >
//               <option value="">Select a Plan Type</option> 
//               <option value="trial">Trial</option>
//               <option value="subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           {/* Plan Amount Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-amount" 
//                 type="text" 
//                 value={`${discountedPlanAmount} ${plan.planCurrency}`} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Apply Voucher Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
//               Apply Voucher
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {voucherEnabled ? (
//                   <span>
//                     ( 
//                       The voucher is valid until 
//                         <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
//                       left only 
//                         <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
//                       Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
//                     )
//                   </span>
//                 ) : (
//                   <span>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                 )}
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={voucherEnabled}
//                 onChange={() => setVoucherEnabled(!voucherEnabled)}
//                 disabled={planType === 'trial'}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="voucher-code"
//               type="text"
//               value={voucherCode}
//               onChange={(e) => setVoucherCode(e.target.value)}
//               disabled={!voucherEnabled}
//               placeholder="Enter voucher code"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={applyVoucher}
//               disabled={applyDisabled}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* trade account section */}
//           {/* Account Type Field (Updated with checkboxes) */}
//           <div className="mb-4">
//             <div className="flex justify-center items-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Trading Account</span>
//             </div>
//             <div className="flex justify-normal items-center">
//               <label className="block mb-2 font-semibold">
//                 Account Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( choose only if required to fill in trade account detail )
//               </div>
//             </div>
//             <div className="flex flex-col">
//               <div className="flex justify-normal items-center">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={selectedAccountType === 'LIVE'}
//                     onChange={() => handleAccountTypeChange('LIVE')}
//                     className="mr-2 accent-cyan-800"
//                   />
//                 </label>
//                 <span>LIVE</span>
//               </div>
//               <div className="flex justify-normal items-center">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={selectedAccountType === 'DEMO'}
//                     onChange={() => handleAccountTypeChange('DEMO')}
//                     className="mr-2 accent-cyan-800"
//                   />
//                 </label>
//                 <span>DEMO</span>
//               </div>
//             </div>
//           </div>

//           {/* Trading Account Section (Only enabled if account type is selected) */}
//           <fieldset 
//             disabled={!isAccountSectionEnabled} 
//             className={!isAccountSectionEnabled ? 'opacity-50' : ''}
//           >
//             {/* Account Server field */}
//             <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="account-server"
//               >
//                 Account Server
//               </label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3 text-cyan-800"
//                     aria-hidden="true"
//                   />
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs text-white bg-cyan-800">
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                 </div>
//               </div>
//               <div className="text-xs text-rose-600 mb-2">
//                 ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
//               </div>
//             </div>
//             <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountServer ? 'border-rose-600' : ''}`}
//                 id="account-server"
//                 type="text"
//                 value={accountServer}
//                 onChange={(e) => {
//                   setAccountServer(e.target.value);
//                   if (errors.accountServer) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountServer: false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
            
//             {/* Account Number field */}  
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="account-number">
//                   Account Number
//                 </label>
//                 <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( This is your trading account id number )
//                 </div>
//               </div>
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountNumber ? 'border-rose-600' : ''}`}
//                 id="account-number"
//                 type="number"
//                 value={accountNumber}
//                 onChange={(e) => {
//                   setAccountNumber(e.target.value);
//                   if (errors.accountNumber) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountNumber: false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
            
//             {/* Account Password field */} 
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="account-password">
//                   Account Password
//                 </label>
//                 <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( This is your trading account investor "read-only" password. )
//                 </div>
//               </div>
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountPassword ? 'border-rose-600' : ''}`}
//                 id="account-password"
//                 type="password"
//                 value={accountPassword}
//                 onChange={(e) => {
//                   setAccountPassword(e.target.value);
//                   if (errors.accountPassword) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountPassword:false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
//           </fieldset>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//           </div>

//           {/* Payment Method Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block font-semibold xs:w-[60px]">Payment Method</label>
//                 <FaInfoCircle className="ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   ( choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mb-2">
//                 <input
//                   type="radio"
//                   id="bank-transfer"
//                   name="payment-method"
//                   value="Bank Transfer"
//                   checked={paymentMethod === 'Bank Transfer'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4 ">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center sm:text-xs xs:text-xs">
//                     <div>
//                       <li>FINOX TRADE MARKET SDN. BHD.</li>
//                       <li>Hong leong bank</li>
//                       <li>23600590742</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("23600590742")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="usdt"
//                   name="payment-method"
//                   value="USDT"
//                   checked={paymentMethod === 'USDT'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-cente sm:text-xs accent-cyan-800">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Plan Trial Requirement Field */}
//           {planType === 'trial' && (
//             <div className="mb-4">
//               <p className="font-semibold">Plan Trial Requirement:</p>
//               <ul className="list-disc list-inside">
//                 {Array.isArray(planRequirement) && planRequirement.map((requirement, index) => (
//                   <li key={index}>{requirement}</li>
//                 ))}
//               </ul>
//             </div>
//           )}

//           {/* Payment Receipts Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="payment-receipts"
//               >
//                 {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
//               </label>
//               <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {planType === 'trial' 
//                   ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
//                   : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
//               type="file"
//               name="PaymentReceipt"
//               onChange={(e) => {
//                 const filesArray = [...receipts];
//                 if (e.target.files && e.target.files[0]) {
//                   filesArray.push(e.target.files[0]);
//                 }
//                 setReceipts(filesArray);
//                 if (errors.paymentReceipts) {
//                   setErrors((prevErrors) => ({ ...prevErrors, paymentReceipts: false }));
//                 }
//               }}
//             />
//           </div>

//           {/* Notes Field */}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </div>

//           {/* Terms and Conditions Field */}
//           <div className="flex justify-normal items-center mb-4">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//                 className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
//               />
//             </label>
//             <span 
//                 className="cursor-pointer text-cyan-800 underline" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//             </span>
//           </div>

//           {/* Submit Form Button */}
//           <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
//             <button 
//               onClick={onBack} 
//               className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//             >
//               <FaArrowCircleLeft />
//               <div className="ml-2">
//                 Back to Details
//               </div>
//             </button>
//             <button 
//               type="button"
//               onClick={handleSubscribeClick}
//               disabled={!termsAccepted || isSubmitting}
//               className={`px-4 py-2 rounded-md flex items-center justify-center text-white ${!termsAccepted || isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-cyan-800'}`}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">
//                 Subscribe
//               </div>
//             </button>
//           </div>
//         </form>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto max-w-[800px] max-h-screen lg:w-[800px] lg:h-[600px] md:w-[700px] md:h-[600px] sm:w-[450px] sm:h-[700px] xs:max-w-screen xs:h-[600px]">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       <MyTicketAlert 
//         type={alert.type} 
//         message={alert.message} 
//         visible={alert.visible} 
//         onClose={() => setAlert({ ...alert, visible: false })} 
//         onConfirm={confirmSubscribe} 
//         onCancel={closeAlert}
//         isLoading={alert.isLoading} 
//       />

//       <MyTicketAlert 
//         type={referralAlert.type} 
//         message={referralAlert.message} 
//         visible={referralAlert.visible} 
//         onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
//         onConfirm={confirmReferralApply}
//         onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
//       />
//     </div>
//   );
// };

// export default TraderHubRegistration;

// // update code --> 30 Aug 0224
// // src/components/client/MiniApp/App03_MySubscriptionRegistrationHub.tsx

// import React, { useState, useEffect } from 'react';
// import moment from 'moment-timezone';
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaInfoCircle, FaClipboard } from 'react-icons/fa';
// // import file
// import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
// import useSubscriptionSubmit, { SubscriptionHubData } from '../../../hooks/Subscription/useSubscriptionSubmit';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';

// import { useEventRegTerms } from '../../../context/SubRegTermContext';

// import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png';

// // Add AlertType type definition here
// type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';

// interface TraderHubRegistrationProps {
//   plan: Plan;
//   onBack: () => void;
// }

// const TraderHubRegistration: React.FC<TraderHubRegistrationProps> = ({ plan, onBack }) => {
//   const { user } = useUserDashboard();
//   const { fetchTrialInfo } = useSubscriptionData();
//   const { 
//     submitTraderHubSubscription,
//     updateVoucherDaysInDatabase,
//     fetchVoucherDetails, 
//     fetchAndUpdateUserAlgoMarixData,
//     checkUserVoucherEntitlement, 
//   } = useSubscriptionSubmit();
//   const { validate, errors, setErrors } = useFormValidation();
//   const termsAndConditions = useEventRegTerms();
//   // component 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // form field state
//   const defaultMemberType = { type: 'R One Member' };
//   const [orderID, setOrderID] = useState('');
//   const [planType, setPlanType] = useState('');
//   const [planDays, setPlanDays] = useState<number | null>(null);
//   const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
//   const isAccountSectionEnabled = selectedAccountType !== null;
//   const [accountServer, setAccountServer] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   const [notes, setNotes] = useState('');
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   // apply trial
//   const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
//   const [planRequirement, setPlanRequirement] = useState<string[]>([]);
//   // apply referral
//   const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
//   const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
//   const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
//   const [referralApplied, setReferralApplied] = useState(false);
//   // apply voucher code
//   const [applyDisabled, setApplyDisabled] = useState(true);
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
//   const [voucherDays, setVoucherDays] = useState<number | null>(null);
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
//   const [voucherApplied, setVoucherApplied] = useState(false);
//   const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
//   // submit states
//   const [isSubmitting, setIsSubmitting] = useState(false); 
//   // renew 
//   const [isRenewal, setIsRenewal] = useState(false);
//   // set alert
//   const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
//   const [alert, setAlert] = useState<{ 
//     visible: boolean, 
//     type: AlertType, 
//     title?: string, 
//     message: string, 
//     isLoading?: boolean 
//   }>({ 
//     visible: false, 
//     type: 'check', 
//     message: '' 
//   });

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   const closeAlert = () => setReferralAlert({ visible: false, type: 'check', message: '' });

//   const handleAccountTypeChange = (type: string) => {
//     setSelectedAccountType(type === selectedAccountType ? null : type);
//   };

//   // generate order id for trader hub
//   useEffect(() => {
//     const generatedOrderID = 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//     setOrderID(generatedOrderID);
//   }, []);

//   //------------------- trial apply ----------------------//
//   // search for trial details
//   useEffect(() => {
//     const fetchTrialDetails = async () => {
//       if (planType === 'trial' && user?.email) {
//         const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

//         if (trialDays) {
//           setPlanTrialDays(trialDays);
//         } else {
//           setPlanTrialDays(null);
//         }

//         if (trialRequirement) {
//           setPlanRequirement(trialRequirement.split(',').map((req: string) => req.trim()));
//         } else {
//           setPlanRequirement([]);
//         }
//       } else {
//         setPlanDays(parseInt(plan.planPeriod, 10));
//         setPlanTrialDays(null);
//       }
//     };
//     fetchTrialDetails();
//   }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);

//   //------------------- voucher apply ----------------------//
//   // toggle voucher enable or disable
//   useEffect(() => {
//     setApplyDisabled(!voucherEnabled);
//   }, [voucherEnabled]);

//   // voucher plan amount effect
//   useEffect(() => {
//     let calculatedPlanAmount = parseFloat(plan.planAmount);

//     if (plan.planPeriod === '365') {
//       calculatedPlanAmount = calculatedPlanAmount * 12;
//     }

//     setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
//   }, [plan.planAmount, plan.planPeriod]);

//   // disable voucher for trial
//   useEffect(() => {
//     if (planType === 'trial') {
//       setVoucherEnabled(false);
//     }
//   }, [planType]);  
  
//   // Update the voucherEnabled useEffect
//   useEffect(() => {
//     const updateVoucherDetails = async () => {
//       if (voucherEnabled && voucherCode) {
//         try {

//           // Fetch voucher details
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
//           if (voucher) {
//             const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
//             const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
//             const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

//             // Update state with fetched data
//             setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
//             setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
//             setVoucherRedeemCount(voucher.voucherRedeemCount);

//             // Update the voucher days in the database
//             await updateVoucherDaysInDatabase(voucher.id, remainingDays);

//           } else {
//             setVoucherPeriod(null);
//             setVoucherDays(null);
//           }
//         } catch (error) {
//           console.error('Error fetching voucher details:', error);
//           setVoucherRedeemCount(0);
//         }
//       } else {
//         setVoucherPeriod(null);
//         setVoucherDays(null);
//       }
//     };

//     updateVoucherDetails();
//   }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

//   //------------------- referral apply ----------------------//
//   // toggle enabled or disable referral effect
//   useEffect(() => {
//     if (isReferralEnabled) {
//         setApplyReferralDisabled(false);
//     } else {
//         setApplyReferralDisabled(true);
//     }
//   }, [isReferralEnabled]);

//   //------------------- define function ----------------------//
//   // apply referral email function
//   const handleApplyReferralEmail = () => {
//     setReferralAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Do you want to apply this referral email?',
//     });
//     // Disable the referral email field after applying
//     setIsReferralEnabled(false);
//   };

//   // apply referral alert function
//   const confirmReferralApply = () => {
//     try {
//       setReferralApplied(true); // Set success status
//       setReferralAlert({
//         visible: true,
//         type: 'success',
//         title: 'Referral Applied',
//         message: 'Referral email applied successfully!',
//       });
//     } catch (error) {
//       setReferralApplied(false); // Set failure status
//       setReferralAlert({
//         visible: true,
//         type: 'error',
//         title: 'Referral Error',
//         message: 'Failed to apply the referral. Please try again.',
//       });
//     }
//   };

//   // apply voucher function
//   const applyVoucher = async () => {
//     if (voucherCode && !voucherApplied) {
//       try {
//           // Fetch voucher details without applying it yet to get the remaining days
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
//           if (!voucher) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return;
//           }

//           // Check if the user is entitled to redeem this voucher
//           const userEntitlement = await checkUserVoucherEntitlement(
//             voucher.voucherCode, 
//             voucher.voucherMemberType, 
//             voucher.voucherGroupMember
//           );

//           if (!userEntitlement) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'You are not entitled to redeem this voucher code.',
//             });
//             return;
//           }

//           // Check if the voucher is expired
//           const currentDate = moment().tz("Asia/Kuala_Lumpur");
//           const voucherPeriodDate = moment(voucher.voucherPeriod);
//           const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

//           if (remainingDays < 0) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'The voucher has expired and cannot be applied.',
//             });
//             return;
//           }

//           // Check if the voucher has already been fully redeemed
//           if (voucher.voucherRedeemCount === '0') {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return; 
//           }

//           if (voucher && user) {
//             // Fetch and update the user's AlgoMarix data in a single API call
//             const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
//             if (result) {
//               const { accountVoucherCheck } = result;
      
//               // Apply check only if the user exists and accountVoucherCheck is "0"
//               if (accountVoucherCheck === '0' && planType !== 'subscribe') {
//                 setAlert({
//                   visible: true,
//                   type: 'error',
//                   title: 'Voucher Error',
//                   message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
//                 });
//                 return;
//               }
            
//               // Handle voucher redeem check logic
//               if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: 'You have already redeemed this voucher code and cannot redeem it again.',
//                 });
//                 return;
//               }

//               // Handle specific redeem count 
//               if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
//                 });
//                 return;
//               }

//               let newAmount = parseFloat(plan.planAmount);

//               // Adjust for annual plan period
//               if (plan.planPeriod === '365') {
//                 newAmount = newAmount * 12;
//               }
    
//               if (voucher.voucherType === 'By Amount') {
//                 newAmount -= parseFloat(voucher.voucherAmount);
//               } else if (voucher.voucherType === 'By Percentage') {
//                 newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
//               }
    
//               // Update voucherDays in the subscription data
//               setVoucherDays(remainingDays);

//               // Now that the voucher is successfully applied, decrement the redeem count
//               await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
//               await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

//               setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
//               setVoucherRedeemCount(voucher.voucherRedeemCount);
//               setVoucherApplied(true); 
//               setAlert({
//                 visible: true,
//                 type: 'success',
//                 title: 'Voucher Applied',
//                 message: 'Voucher applied successfully!',
//               });
//           } else {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Failed to retrieve user data. Please try again.',
//             });
//           }
//         }
//       } catch (error) {
//         setDiscountedPlanAmount(plan.planAmount);
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Voucher Error',
//           message: 'Failed to apply the voucher. Please check the voucher code and try again.',
//         });
//       }
//     } else if (voucherApplied) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Voucher Error',
//         message: 'This voucher has already been applied to this order.',
//       });
//     }
//   };

//   const handlePlanTypeChange = (type: string) => {
//     setPlanType(type);
//     if (type === 'Renew') {
//         setIsRenewal(true);
//     } else {
//         setIsRenewal(false);
//     }
//   };

//   // function for click subscribe button
//   const handleSubscribeClick = async () => {
//     if (!user) return;

//     // Prevent double-click by checking if the process is already in progress
//     if (isSubmitting) {
//       setAlert({
//         visible: true,
//         type: 'warning',
//         title: 'Subscription in Progress',
//         message: 'Your subscription is already being processed. Please wait...',
//       });
//       return;
//     }

//     // Use the validate function instead of validateForm
//     if (!validate({
//         planType,
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         paymentMethod,
//         paymentReceipts: receipts,
//         termsAccepted
//     })) {
//         setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Please scroll up and review to ensure all required fields are filled in.',
//         });
//         return;
//     }

//     setIsSubmitting(true); 
//     setAlert({
//         visible: true,
//         type: 'confirm',
//         message: 'Make sure you provided correct information before subscribing?'
//     });
//   };

//   // function for confirm subscribe submit
//   const confirmSubscribe = async () => {
//     try {
//       setAlert((prev) => ({ ...prev, isLoading: true }));
  
//       // Here, you would gather the form data
//       const subscriptionHubData: SubscriptionHubData = {
//         planID: plan.planID || '',
//         orderID,
//         name: user?.username || '',
//         phone: user?.phone || '',
//         email: user?.email || '',
//         referralEmail,
//         planNameHub: plan.planName,
//         planTypeHub: planType,
//         planPeriodHub: plan.planPeriod || '',
//         planAmountHub: discountedPlanAmount,
//         voucherCodeHub: voucherCode,
//         renewalDate: '', 
//         expireDays: '',
//         accountType: selectedAccountType || '',
//         accountServer,
//         accountNumber,
//         accountPassword,
//         PaymentMethodHub: paymentMethod,
//         PaymentReceiptsHub: receipts.map(receipt => receipt.name).join(', '),
//         notes,
//         memberType: defaultMemberType,
//         image: '', 
//         termAgreed: termsAccepted,
//       };
  
//       const response = await submitTraderHubSubscription(subscriptionHubData, receipts);
  
//       if (response.success) {
//         setAlert({
//           type: 'success',
//           message: 'Subscription successful!',
//           visible: true,
//           isLoading: false,
//         });
//       } else {
//         setAlert({
//           type: 'error',
//           title: 'Subscription failed',
//           message: 'If you need support, please contact <a href="mailto:general@r1trader.com">general@r1trader.com</a>',
//           visible: true,
//           isLoading: false
//         })
//       }
//     } catch (error: any) {
//       setAlert({
//         type: 'error',
//         title: 'Subscription failed',
//         message: error.message || 'An error occurred during the subscription process.',
//         visible: true,
//         isLoading: false,
//       });
//     }
//   };  

//   // // function for submit reciept upload
//   // const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//   //   const newReceipts = [...receipts];
//   //   if (e.target.files && e.target.files[0]) {
//   //     newReceipts[index] = e.target.files[0];
//   //     setReceipts(newReceipts);
//   //   }
//   // };  

//   return (
//     <div>
//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <form>
//           <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//               Basic Information
//             </span>
//           </div>

//           {/* Description Field */}
//           <div className="my-4">
//             <p className="mb-6 font-semibold">{plan.planDescription}</p>
//           </div>

//           {/* Order ID Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
//           </div>

//           {/* Username Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
//           </div>

//           {/* Phone Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Contact No.</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="username" 
//               type="text" 
//               value={user?.phone || ''} disabled />
//           </div>

//           {/* Email Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
//           </div>

//           {/* Referral Email Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
//               Referral Email
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//               <div className="text-xs font-thin ml-2 text-rose-500">
//                 ( Enter your referrer's email or leave as default. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={isReferralEnabled}
//                 onChange={() => setIsReferralEnabled(!isReferralEnabled)}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="referral-email"
//               type="email"
//               value={referralEmail}
//               onChange={(e) => setReferralEmail(e.target.value)}
//               disabled={!isReferralEnabled}
//               placeholder="Enter referral email"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={handleApplyReferralEmail}
//               disabled={applyReferralDisabled || referralApplied}
//             >
//               Apply
//             </button>
//           </div>
          
//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Plan Name Field */}
//           <div className="mb-4">
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
//             </div>
//             <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="plan-name" 
//               type="text" 
//               value={plan.planName} disabled 
//             />
//           </div>

//           {/* Plan Trial Period Field */}
//           {planType === 'trial' ? (
//             <>
//               <div className="mb-4">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="plan-trial-period">
//                     Plan Trial Period (days)
//                 </label>
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                   id="plan-trial-period" 
//                   type="text" 
//                   value={planTrialDays ? planTrialDays.toString() : ''} 
//                   disabled 
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="mb-4">
//               {/* Plan Period (Days) Field */}
//               <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-period" 
//                 type="text" 
//                 value={planDays ? planDays.toString() : ''} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Plan Type Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center ">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
//                 htmlFor="plan-type">
//                   Plan Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
//               </div>
//             </div>
//             <select
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
//               id="plan-type"
//               value={planType}
//               onChange={(e) => { 
//                 handlePlanTypeChange(e.target.value); 
//                 setPlanType(e.target.value);
//                 if (errors.planType) {
//                   setErrors((prevErrors) => ({ ...prevErrors, planType:false }));
//                 }
//               }}
//               disabled={isRenewal}
//             >
//               <option value="">Select a Plan Type</option> 
//               <option value="trial">Trial</option>
//               <option value="subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           {/* Plan Amount Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-amount" 
//                 type="text" 
//                 value={`${discountedPlanAmount} ${plan.planCurrency}`} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Apply Voucher Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
//               Apply Voucher
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {voucherEnabled ? (
//                   <span>
//                     ( 
//                       The voucher is valid until 
//                         <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
//                       left only 
//                         <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
//                       Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
//                     )
//                   </span>
//                 ) : (
//                   <span>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                 )}
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 accent-cyan-800"
//                 checked={voucherEnabled}
//                 onChange={() => setVoucherEnabled(!voucherEnabled)}
//                 disabled={planType === 'trial'}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="voucher-code"
//               type="text"
//               value={voucherCode}
//               onChange={(e) => setVoucherCode(e.target.value)}
//               disabled={!voucherEnabled}
//               placeholder="Enter voucher code"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={applyVoucher}
//               disabled={applyDisabled}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* trade account section */}
//           {/* Account Type Field (Updated with checkboxes) */}
//           <div className="mb-4">
//             <div className="flex justify-center items-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Trading Account</span>
//             </div>
//             <div className="flex justify-normal items-center">
//               <label className="block mb-2 font-semibold">
//                 Account Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( choose only if required to fill in trade account detail )
//               </div>
//             </div>
//             <div className="flex flex-col">
//               <div className="flex justify-normal items-center">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={selectedAccountType === 'LIVE'}
//                     onChange={() => handleAccountTypeChange('LIVE')}
//                     className="mr-2 accent-cyan-800"
//                   />
//                 </label>
//                 <span>LIVE</span>
//               </div>
//               <div className="flex justify-normal items-center">
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={selectedAccountType === 'DEMO'}
//                     onChange={() => handleAccountTypeChange('DEMO')}
//                     className="mr-2 accent-cyan-800"
//                   />
//                 </label>
//                 <span>DEMO</span>
//               </div>
//             </div>
//           </div>

//           {/* Trading Account Section (Only enabled if account type is selected) */}
//           <fieldset 
//             disabled={!isAccountSectionEnabled} 
//             className={!isAccountSectionEnabled ? 'opacity-50' : ''}
//           >
//             {/* Account Server field */}
//             <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="account-server"
//               >
//                 Account Server
//               </label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3 text-cyan-800"
//                     aria-hidden="true"
//                   />
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs text-white bg-cyan-800">
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                 </div>
//               </div>
//               <div className="text-xs text-rose-600 mb-2">
//                 ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
//               </div>
//             </div>
//             <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountServer ? 'border-rose-600' : ''}`}
//                 id="account-server"
//                 type="text"
//                 value={accountServer}
//                 onChange={(e) => {
//                   setAccountServer(e.target.value);
//                   if (errors.accountServer) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountServer: false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
            
//             {/* Account Number field */}  
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="account-number">
//                   Account Number
//                 </label>
//                 <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( This is your trading account id number )
//                 </div>
//               </div>
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountNumber ? 'border-rose-600' : ''}`}
//                 id="account-number"
//                 type="number"
//                 value={accountNumber}
//                 onChange={(e) => {
//                   setAccountNumber(e.target.value);
//                   if (errors.accountNumber) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountNumber: false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
            
//             {/* Account Password field */} 
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block mb-2 font-semibold" htmlFor="account-password">
//                   Account Password
//                 </label>
//                 <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//                 <div className="text-xs text-rose-600 mb-2 ml-2">
//                   ( This is your trading account investor "read-only" password. )
//                 </div>
//               </div>
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.accountPassword ? 'border-rose-600' : ''}`}
//                 id="account-password"
//                 type="password"
//                 value={accountPassword}
//                 onChange={(e) => {
//                   setAccountPassword(e.target.value);
//                   if (errors.accountPassword) {
//                     setErrors((prevErrors) => ({ ...prevErrors, accountPassword:false }));
//                   }
//                 }}
//                 disabled={!isAccountSectionEnabled}
//               />
//             </div>
//           </fieldset>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//           </div>

//           {/* Payment Method Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block font-semibold xs:w-[60px]">Payment Method</label>
//                 <FaInfoCircle className="ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   ( choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mb-2">
//                 <input
//                   type="radio"
//                   id="bank-transfer"
//                   name="payment-method"
//                   value="Bank Transfer"
//                   checked={paymentMethod === 'Bank Transfer'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4 ">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center sm:text-xs xs:text-xs">
//                     <div>
//                       <li>FINOX TRADE MARKET SDN. BHD.</li>
//                       <li>Hong leong bank</li>
//                       <li>23600590742</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("23600590742")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="usdt"
//                   name="payment-method"
//                   value="USDT"
//                   checked={paymentMethod === 'USDT'}
//                   className="accent-cyan-800 hover:accent-cyan-600"
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                 />
//                 <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-cente sm:text-xs accent-cyan-800">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Plan Trial Requirement Field */}
//           {planType === 'trial' && (
//             <div className="mb-4">
//               <p className="font-semibold">Plan Trial Requirement:</p>
//               <ul className="list-disc list-inside">
//                 {Array.isArray(planRequirement) && planRequirement.map((requirement, index) => (
//                   <li key={index}>{requirement}</li>
//                 ))}
//               </ul>
//             </div>
//           )}

//           {/* Payment Receipts Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="payment-receipts"
//               >
//                 {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
//               </label>
//               <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {planType === 'trial' 
//                   ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
//                   : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
//               type="file"
//               name="PaymentReceipt"
//               onChange={(e) => {
//                 const filesArray = [...receipts];
//                 if (e.target.files && e.target.files[0]) {
//                   filesArray.push(e.target.files[0]);
//                 }
//                 setReceipts(filesArray);
//                 if (errors.paymentReceipts) {
//                   setErrors((prevErrors) => ({ ...prevErrors, paymentReceipts: false }));
//                 }
//               }}
//             />
//           </div>

//           {/* Notes Field */}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </div>

//           {/* Terms and Conditions Field */}
//           <div className="flex justify-normal items-center mb-4">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//                 className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
//               />
//             </label>
//             <span 
//                 className="cursor-pointer text-cyan-800 underline" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//             </span>
//           </div>

//           {/* Submit Form Button */}
//           <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
//             <button 
//               onClick={onBack} 
//               className="px-4 py-2 rounded-md flex items-center justify-center bg-cyan-800 text-white"
//             >
//               <FaArrowCircleLeft />
//               <div className="ml-2">
//                 Back to Details
//               </div>
//             </button>
//             <button 
//               type="button"
//               onClick={handleSubscribeClick}
//               disabled={!termsAccepted || isSubmitting}
//               className={`px-4 py-2 rounded-md flex items-center justify-center text-white ${!termsAccepted || isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-cyan-800'}`}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">
//                 Subscribe
//               </div>
//             </button>
//           </div>
//         </form>
//       </div>

//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto max-w-[800px] max-h-screen lg:w-[800px] lg:h-[600px] md:w-[700px] md:h-[600px] sm:w-[450px] sm:h-[700px] xs:max-w-screen xs:h-[600px]">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       <MyTicketAlert 
//         type={alert.type} 
//         message={alert.message} 
//         visible={alert.visible} 
//         onClose={() => setAlert({ ...alert, visible: false })} 
//         onConfirm={confirmSubscribe} 
//         onCancel={closeAlert}
//         isLoading={alert.isLoading} 
//       />

//       <MyTicketAlert 
//         type={referralAlert.type} 
//         message={referralAlert.message} 
//         visible={referralAlert.visible} 
//         onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
//         onConfirm={confirmReferralApply}
//         onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
//       />
//     </div>
//   );
// };

// export default TraderHubRegistration;