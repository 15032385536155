// src/hooks/AdminDashboard/useEmailSetting.tsx

import { useState, useCallback, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext'; 

interface CampaignEmail {
  id: number;
  campTitle: string;
  campType: string;
  campList: string;
  // content setting
  contentTitle: string;
  contentName: string;
  contentBody: string;
  contentButton: string;
  // email setting
  sendFrom: string;
  sendTo: string;
  sendCC: string;
  subjectMail: string;
  logicStatus?: string;
}

interface CampaignWorkflow {
  id: number;
  workflowId: number;
  workflowName: string;
  workflowType: string;
  workflowStatus: string;
  clickedStats: string;
  createdAt: Date;
}


interface Schedule {
  workflowId: number;
  logicId: string;
  scheduleName: string;
  emailContent: string;
  logicType: string;
  scheduleDate: string;
  scheduleFlag: number;
  period: number;
  nextRun: string;
  clickCount: number;
  logicStatus: string;
}

export const useEmail = () => {
  const [email, setEmail] = useState<CampaignEmail[]>([]);
  const [workflows, setWorkflows] = useState<CampaignWorkflow[]>([]); 
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();

  // Campaigns Email section --------------------------------------->>
  // fetching campaigns email setting
  const fetchCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/email-campaign`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
      }

      const data = await response.json();
      setEmail(data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setError('Failed to fetch campaigns.');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]); 

  const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/email-campaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCampaignEmail),
      });

      if (!response.ok) {
        throw new Error(`Failed to create a campaign: ${response.statusText}`);
      }

      const data = await response.json();

      setEmail([...email, { ...newCampaignEmail, id: data.id }]);
      setLoading(false);
    } catch (error) {

      console.error('Error in addCampaign:', error);
      setError('Failed to add campaign. Please try again.');
      setLoading(false);
    }
  };

  const updateCampaign = async (updatedCampaign: CampaignEmail) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedCampaign),
      });

      if (!response.ok) {
        throw new Error(`Failed to update campaign: ${response.statusText}`);
      }

      setEmail((prevEmails) =>
        prevEmails.map((email) =>
          email.id === updatedCampaign.id ? updatedCampaign : email
        )
      );
      setLoading(false);
    } catch (error) {
      console.error('Error in updateCampaign:', error); 
      setError('Failed to update campaign.');
    }
  };

  // Add the deleteCampaign function
  const deleteCampaign = async (campaignId: number) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/email-campaign/${campaignId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete campaign: ${response.statusText}`);
      }

      setEmail((prevEmails) => prevEmails.filter((email) => email.id !== campaignId));
      setLoading(false);
    } catch (error) {
      console.error('Error in deleteCampaign:', error);
      setError('Failed to delete campaign. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns(); 
  }, [fetchCampaigns]);

  // created workflow section --------------------------------------->>
  // Fetch workflows
  const fetchWorkflows = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/workflows`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        console.error(`Failed to fetch workflows. Status: ${response.status}`);
        throw new Error(`Failed to fetch workflows: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      setWorkflows(data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching workflows:', error);
      setError('Failed to fetch workflows.');
      setLoading(false);
    }
  }, [apiUrl]);
  

   // Function to update the workflow status and name
  const updateWorkflowStatus = async (workflowId: number, newStatus: string, workflowName?: string) => {
    try {
      const requestBody: any = {};
      if (newStatus !== 'KeepCurrentStatus') {
        requestBody.workflowStatus = newStatus;
      }
      if (workflowName !== undefined) {
        requestBody.workflowName = workflowName;
      }

      if (Object.keys(requestBody).length === 0) {
        // Nothing to update
        return;
      }

      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to update workflow');
      }

      // Update local workflows state
      setWorkflows((prevWorkflows) =>
        prevWorkflows.map((workflow) =>
          workflow.workflowId === workflowId
            ? { ...workflow, ...requestBody }
            : workflow
        )
      );

      await fetchWorkflows(); // Refresh workflows
    } catch (error) {
      console.error('Error updating workflow:', error);
      throw error;
    }
  };

  // add a workflow
  const addWorkflow = async (newWorkflow: { workflowName: string, workflowStatus: string }) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/workflows`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newWorkflow),
      });

      if (!response.ok) {
        throw new Error(`Failed to create workflow: ${response.statusText}`);
      }

      const data = await response.json();
      // Ensure the list of workflow is up to date
      await fetchWorkflows();

      setLoading(false);
      return data;

    } catch (error) {
      console.error('Error in addWorkflow:', error);
      setError('Failed to add workflow. Please try again.');
      setLoading(false);
    }
  };

  const deleteWorkflow = async (workflowId: number) => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to delete workflow: ${response.statusText}`);
      }
  
      setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.workflowId !== workflowId));
    } catch (error) {
      setError('Failed to delete workflow.');
      console.error('Error deleting workflow:', error);
    } finally {
      setLoading(false);
    }
  };  

  // Fetch logic rows for a specific workflow
  const fetchLogics = useCallback(async (workflowId: number | undefined) => {
    if (!workflowId) {
      console.error('Invalid workflowId provided for fetching logics');
      return []; 
    }

    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        console.log(`Failed to fetch logic rows. Status: ${response.status}`);
        throw new Error(`Failed to fetch logic rows: ${response.statusText}`);
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (error) {
      console.error('Error fetching logic rows:', error);
      setError('Failed to fetch logic rows.');
      setLoading(false);
      throw error;
    }
  }, [apiUrl]);
  
  const addLogic = async (workflowId: number | undefined, newLogic: Partial<Schedule>) => {
    if (!workflowId) {
      console.error('Invalid workflowId provided for adding logic');
      throw new Error('Invalid workflowId');
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newLogic), // Send the new logic data
      });

      if (!response.ok) {
        throw new Error(`Failed to add logic row: ${response.statusText}`);
      }

      const result = await response.json();
      setLoading(false);
      return result;
    } catch (error) {
      console.error('Error in addLogic:', error);
      setError('Failed to add logic.');
      setLoading(false);
      throw error;
    }
  };

   // Function to update logic
   const updateLogic = async (workflowId: number, logicId: string, updatedLogic: Partial<Schedule>) => {
    try {
      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedLogic),
      });

      if (!response.ok) {
        throw new Error('Failed to update logic');
      }

      const updated = await response.json();
      return updated.logic;
    } catch (error) {
      console.error('Error updating logic:', error);
      throw error;
    }
  };

  const deleteLogics = async (workflowId: number, logicIds: string[]) => {
    try {
      // Loop through each selected logic row and send the delete request
      for (const logicId of logicIds) {
        await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
      return true; // Return success
    } catch (error) {
      console.error('Failed to delete logic rows:', error);
      throw new Error('Failed to delete logic rows.');
    }
  };

  // -- send email route ------------------------------------------------------------------------>>
  // Function to send test email
  const sendTestEmail = async (logicId: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/send-test-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ logicId }),
      });

      if (!response.ok) {
        throw new Error(`Failed to send test email: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error sending test email:', error);
      throw error;
    }
  };

  // Function to schedule and send emails based on logic settings
  let executionStopped = false;
  let emailSentFlags: { [scheduleName: string]: { started: boolean; followUp: boolean; ended: boolean } } = {};  // Store flags for each logic type per schedule

  const scheduleLogicEmails = async (logic: Schedule, schedules: Schedule[]) => {
    const currentTime = new Date().toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' });
    const currentDateTime = new Date(currentTime);
  
    let scheduleTime: Date | null = null;
    console.log(`[DEBUG] Logic Details for "${logic.scheduleName}":`, logic);

    // Skip processing if the scheduleFlag is 0
    if (logic.scheduleFlag === 0) {
      console.log(`[DEBUG] Skipping logic "${logic.scheduleName}" as scheduleFlag is 0.`);
      await checkNextRun(logic, schedules); // Proceed to the next logic
      return;
    }

    // Initialize emailSentFlags for the current logic's scheduleName if it doesn't exist
    if (!emailSentFlags[logic.scheduleName]) {
      emailSentFlags[logic.scheduleName] = { started: false, followUp: false, ended: false };
    }
  
    if (executionStopped) {
      console.log("Execution has been stopped. No further emails will be sent.");
      return;
    }
  
    // Check if the logic is "On"
    if (logic.logicStatus === "On") {
      // Get the scheduled time if "scheduleDate" is provided and valid
      if (logic.scheduleDate && logic.scheduleDate !== "1979-01-01 12:00:00") {
        scheduleTime = new Date(new Date(logic.scheduleDate).toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' }));
        console.log(`[DEBUG] Scheduled Time (Malaysia/KL): ${scheduleTime.toISOString()}`);
      }
  
      // Handle future scheduling and prevent duplicate emails
      if (scheduleTime && scheduleTime > currentDateTime && !emailSentFlags[logic.scheduleName].started && !emailSentFlags[logic.scheduleName].followUp && !emailSentFlags[logic.scheduleName].ended) {
        const delay = scheduleTime.getTime() - currentDateTime.getTime();  // Calculate the delay in milliseconds
        console.log(`[DEBUG] Scheduling email for logic "${logic.scheduleName}" after ${delay} milliseconds.`);
  
        setTimeout(async () => {
          const now = new Date().toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' });
          console.log(`[DEBUG] Current Time (Malaysia/KL): ${new Date(now).toISOString()} - Triggering email for logic: ${logic.scheduleName}`);
          
          await sendEmail(logic);  // Send the email after the delay
          console.log(`[DEBUG] Scheduled email sent for logic: ${logic.scheduleName}`);
          
          // Update scheduleFlag to 0 after sending the email
          await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
          
          emailSentFlags[logic.scheduleName].started = true;  // Mark the "Started" flag as sent
          await checkNextRun(logic, schedules);
        }, delay);
  
        return;  // Exit after scheduling the email for a future date
      }
  
      // If no valid scheduleTime or scheduleTime <= current time, send the "Started" email immediately
      if ((!scheduleTime || scheduleTime <= currentDateTime) && !emailSentFlags[logic.scheduleName].started) {
        console.log(`[DEBUG] Current time (${currentDateTime.toISOString()}) has reached or passed the scheduled time (${scheduleTime ? scheduleTime.toISOString() : 'N/A'}). Sending "Started" email immediately.`);
        await sendEmail(logic);  // Send the "Started" email immediately
        console.log(`[DEBUG] Immediate email sent for "Started" logic: ${logic.scheduleName}`);
        
        // Update scheduleFlag to 0 after sending the email
        await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
        
        emailSentFlags[logic.scheduleName].started = true;  // Mark "Started" as sent
        await checkNextRun(logic, schedules);  // Proceed to the next logic
      }
  
      // Handle "Started" logic type
      if (!emailSentFlags[logic.scheduleName].started && logic.logicType === "Started") {
        console.log(`[DEBUG] "Started" logic type detected for: ${logic.scheduleName}`);
  
        if (!scheduleTime || scheduleTime <= currentDateTime) {
          await sendEmail(logic);  // Send "Started" logic email immediately
          console.log(`[DEBUG] Immediate email sent for "Started" logic: ${logic.scheduleName}`);
          
          // Update scheduleFlag to 0 after sending the email
          await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
          
          emailSentFlags[logic.scheduleName].started = true;  // Mark "Started" as sent
          await checkNextRun(logic, schedules);  // Proceed to the next logic
        } else {
          const delay = scheduleTime.getTime() - currentDateTime.getTime();
          console.log(`[DEBUG] Scheduling "Started" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
          setTimeout(async () => {
            if (!emailSentFlags[logic.scheduleName].started) {
              await sendEmail(logic);
              console.log(`[DEBUG] Scheduled "Started" email sent for logic: ${logic.scheduleName}`);
              
              // Update scheduleFlag to 0 after sending the email
              await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
              
              emailSentFlags[logic.scheduleName].started = true;  // Mark as sent
              await checkNextRun(logic, schedules);  // Proceed to the next logic
            }
          }, delay);
        }
        return;
      }
  
      // Handle "Follow-Up" logic type after "Started"
      if (!emailSentFlags[logic.scheduleName].followUp && logic.logicType === "Follow-Up" && emailSentFlags[logic.scheduleName].started) {
        console.log(`[DEBUG] "Follow-Up" logic type detected for: ${logic.scheduleName}`);
  
        if (!scheduleTime || scheduleTime <= currentDateTime) {
          await sendEmail(logic);  // Send "Follow-Up" logic email immediately
          console.log(`[DEBUG] Immediate email sent for "Follow-Up" logic: ${logic.scheduleName}`);
          
          // Update scheduleFlag to 0 after sending the email
          await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
          
          emailSentFlags[logic.scheduleName].followUp = true;  // Mark "Follow-Up" as sent
          await checkNextRun(logic, schedules);  // Proceed to the next logic
        } else {
          const delay = scheduleTime.getTime() - currentDateTime.getTime();
          console.log(`[DEBUG] Scheduling "Follow-Up" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
          setTimeout(async () => {
            if (!emailSentFlags[logic.scheduleName].followUp) {
              await sendEmail(logic);
              console.log(`[DEBUG] Scheduled "Follow-Up" email sent for logic: ${logic.scheduleName}`);
              
              // Update scheduleFlag to 0 after sending the email
              await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
              
              emailSentFlags[logic.scheduleName].followUp = true;  // Mark as sent
              await checkNextRun(logic, schedules);  // Proceed to the next logic
            }
          }, delay);
        }
        return;
      }
  
      // Handle "Ended" logic type after "Follow-Up"
      if (!emailSentFlags[logic.scheduleName].ended && logic.logicType === "Ended" && emailSentFlags[logic.scheduleName].followUp) {
        console.log(`[DEBUG] "Ended" logic type detected for: ${logic.scheduleName}`);
  
        if (!scheduleTime || scheduleTime <= currentDateTime) {
          await sendEmail(logic);  // Send "Ended" logic email immediately
          console.log(`[DEBUG] Immediate email sent for "Ended" logic: ${logic.scheduleName}`);
          
          // Update scheduleFlag to 0 after sending the email
          await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
          
          emailSentFlags[logic.scheduleName].ended = true;  // Mark "Ended" as sent
          executionStopped = true;  // Stop after sending the ended email
        } else {
          const delay = scheduleTime.getTime() - currentDateTime.getTime();
          console.log(`[DEBUG] Scheduling "Ended" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
          setTimeout(async () => {
            if (!emailSentFlags[logic.scheduleName].ended) {
              await sendEmail(logic);
              console.log(`[DEBUG] Scheduled "Ended" email sent for logic: ${logic.scheduleName}`);
              
              // Update scheduleFlag to 0 after sending the email
              await updateLogic(logic.workflowId, logic.logicId, { scheduleFlag: 0 });
              
              emailSentFlags[logic.scheduleName].ended = true;  // Mark as sent
              executionStopped = true;  // Stop after sending the ended email
            }
          }, delay);
        }
        return;
      }
  
      // Handle "Stop Next Run" logic
      if (logic.nextRun === "Stop Next Run" && !emailSentFlags[logic.scheduleName].started) {
        await sendEmail(logic);
        console.log(`Email sent for logic: ${logic.scheduleName}. Stopping further logic execution.`);
        emailSentFlags[logic.scheduleName].started = true;  // Prevent re-sending
        executionStopped = true;
        return;
      }
  
      // Handle "No Apply" period (-1)
      if (logic.period === -1) {
        console.log(`[DEBUG] Period is set to -1, meaning no period applies. Only scheduling based on scheduleDate.`);
        return;  // Exit as period is -1 and scheduling will follow scheduleDate
      }
    } else {
      console.log(`Logic is off: ${logic.scheduleName}`);
    }
  };
  

  // Function to check and schedule the next run logic
  const checkNextRun = async (logic: Schedule, schedules: Schedule[]) => {
    // Stop further execution if the flag is set
    if (executionStopped) {
      console.log("Execution has been stopped. No further logic will be processed.");
      return;  // Stop further logic from being processed
    }
  
    // If the nextRun is "Stop Next Run", stop the execution
    if (logic.nextRun === "Stop Next Run") {
      console.log(`Logic sequence is stopping due to "Stop Next Run" for: ${logic.scheduleName}`);
      return;  // Stop execution here and do not schedule any further logic
    }
  
    // Find the next logic based on nextRun
    const nextLogic = schedules.find(s => s.scheduleName === logic.nextRun);
  
    // Skip the next logic if its scheduleFlag is 0
    if (nextLogic && nextLogic.logicStatus === "On" && nextLogic.scheduleFlag === 1) {
      console.log(`Executing next logic: ${nextLogic.scheduleName}`);
      await scheduleLogicEmails(nextLogic, schedules);  // Continue with the next logic
    } else {
      console.log(`No valid next logic found or it's Off or already processed (scheduleFlag is 0): ${logic.scheduleName}`);
    }
  };
  


  // Fetch email content matching the `campTitle`
  const fetchEmailContent = async (campTitle: string) => {
    console.log(`FEC01: Fetching email content for campaign title: ${campTitle}`);

    try {
        const response = await fetch(`${apiUrl}/api/email-campaign?campTitle=${campTitle}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
            console.error('Failed to fetch email content. Status:', response.status);
            return null;
        }

        const data = await response.json();
        console.log('FEC02: Fetched email content:', data);
        return data; // Ensure this returns an array of campaigns
    } catch (error) {
        console.error('Error fetching email content:', error);
        return null;
    }
  };

  // Function to send email with content matched by `campTitle`
  const sendEmail = async (logic: Schedule) => {
    try {
      console.log(`Fetching email content for logic: ${logic.scheduleName}`);
      console.log(`Fetching email content with campTitle: ${logic.emailContent}`);
  
      // Fetch email content based on the campaign title from the logic
      const emailCampaigns: CampaignEmail[] = await fetchEmailContent(logic.emailContent);
  
      if (!emailCampaigns || emailCampaigns.length === 0) {
        console.error(`No email content found for campTitle: ${logic.emailContent}`);
        return;
      }
  
      // Find the matching campaign where campTitle matches the emailContent from logic
      const matchingCampaign = emailCampaigns.find((campaign: CampaignEmail) => 
        campaign.campTitle.trim().toLowerCase() === logic.emailContent.trim().toLowerCase()
      );
  
      if (!matchingCampaign) {
        console.error(`No matching campaign found for campTitle: ${logic.emailContent}`);
        return;
      }
  
      console.log('Matching campaign data:', matchingCampaign);
  
      // Prepare the email data using the matched campaign details
      const emailData = {
        campaignId: matchingCampaign.id, // Ensure the correct campaignId is used
        sendFrom: matchingCampaign.sendFrom,
        sendTo: matchingCampaign.sendTo,
        sendCC: matchingCampaign.sendCC,
        subjectMail: matchingCampaign.subjectMail,
        contentTitle: matchingCampaign.contentTitle,
        contentBody: matchingCampaign.contentBody,
        contentButton: matchingCampaign.contentButton,
      };
  
      console.log(`Sending email with data:`, emailData);
  
      // Send the email using the prepared emailData
      await sendScheduledEmail(emailData);
    } catch (error) {
      console.error('Error in sending email:', error);
    }
  };   
  
  // Send scheduled email using the email data
  const sendScheduledEmail = async (emailData: any) => {
    try {
        console.log('Preparing to send scheduled email with the following data:', emailData);

        // Ensure campaignId is present before proceeding
        if (!emailData.campaignId) {
            console.error('Error: Missing campaignId in emailData:', emailData);
            throw new Error('Missing campaignId in emailData');
        }

        const response = await fetch(`${apiUrl}/api/send-scheduled-emails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ campaignId: emailData.campaignId }), // Ensure campaignId is passed correctly
        });

        if (!response.ok) {
            throw new Error(`Failed to send scheduled emails: ${response.statusText}`);
        }

        const result = await response.json();
        console.log('SSE01: Scheduled email sent successfully:', result);
    } catch (error) {
        console.error('Error sending scheduled email:', error);
    }
  };


  return {
    // email content
    email,
    fetchCampaigns,
    addCampaign,
    updateCampaign,
    deleteCampaign,
    // workflow 
    workflows,
    fetchWorkflows,
    addWorkflow,
    updateWorkflowStatus,
    deleteWorkflow,
    // workflow logic
    fetchLogics,
    addLogic,
    updateLogic,
    deleteLogics,
    // schedule email
    scheduleLogicEmails,
    sendTestEmail,
    loading,
    error,
  };
};

// // update code --> 20 Sept 2024 v02
// // src/hooks/AdminDashboard/useEmailSetting.tsx

// import { useState, useCallback, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext'; 

// interface CampaignEmail {
//   id: number;
//   campTitle: string;
//   campType: string;
//   campList: string;
//   // content setting
//   contentTitle: string;
//   contentName: string;
//   contentBody: string;
//   contentButton: string;
//   // email setting
//   sendFrom: string;
//   sendTo: string;
//   sendCC: string;
//   subjectMail: string;
//   logicStatus?: string;
// }

// interface CampaignWorkflow {
//   id: number;
//   workflowId: number;
//   workflowName: string;
//   workflowType: string;
//   workflowStatus: string;
//   clickedStats: string;
//   createdAt: Date;
// }


// interface Schedule {
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   scheduleFlag: number;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// export const useEmail = () => {
//   const [email, setEmail] = useState<CampaignEmail[]>([]);
//   const [workflows, setWorkflows] = useState<CampaignWorkflow[]>([]); 
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   // Campaigns Email section --------------------------------------->>
//   // fetching campaigns email setting
//   const fetchCampaigns = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setEmail(data);
//     } catch (error) {
//       console.error('Error fetching campaigns:', error);
//       setError('Failed to fetch campaigns.');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]); 

//   const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newCampaignEmail),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create a campaign: ${response.statusText}`);
//       }

//       const data = await response.json();

//       setEmail([...email, { ...newCampaignEmail, id: data.id }]);
//       setLoading(false);
//     } catch (error) {

//       console.error('Error in addCampaign:', error);
//       setError('Failed to add campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   const updateCampaign = async (updatedCampaign: CampaignEmail) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedCampaign),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to update campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) =>
//         prevEmails.map((email) =>
//           email.id === updatedCampaign.id ? updatedCampaign : email
//         )
//       );
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in updateCampaign:', error); 
//       setError('Failed to update campaign.');
//     }
//   };

//   // Add the deleteCampaign function
//   const deleteCampaign = async (campaignId: number) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${campaignId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to delete campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) => prevEmails.filter((email) => email.id !== campaignId));
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in deleteCampaign:', error);
//       setError('Failed to delete campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCampaigns(); 
//   }, [fetchCampaigns]);

//   // created workflow section --------------------------------------->>
//   // Fetch workflows
//   const fetchWorkflows = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         console.error(`Failed to fetch workflows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch workflows: ${response.statusText}`);
//       }
  
//       const data = await response.json();
  
//       setWorkflows(data);
//       setLoading(false);

//     } catch (error) {
//       console.error('Error fetching workflows:', error);
//       setError('Failed to fetch workflows.');
//       setLoading(false);
//     }
//   }, [apiUrl]);
  

//    // Function to update the workflow status and name
//   const updateWorkflowStatus = async (workflowId: number, newStatus: string, workflowName?: string) => {
//     try {
//       const requestBody: any = {};
//       if (newStatus !== 'KeepCurrentStatus') {
//         requestBody.workflowStatus = newStatus;
//       }
//       if (workflowName !== undefined) {
//         requestBody.workflowName = workflowName;
//       }

//       if (Object.keys(requestBody).length === 0) {
//         // Nothing to update
//         return;
//       }

//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/status`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update workflow');
//       }

//       // Update local workflows state
//       setWorkflows((prevWorkflows) =>
//         prevWorkflows.map((workflow) =>
//           workflow.workflowId === workflowId
//             ? { ...workflow, ...requestBody }
//             : workflow
//         )
//       );

//       await fetchWorkflows(); // Refresh workflows
//     } catch (error) {
//       console.error('Error updating workflow:', error);
//       throw error;
//     }
//   };

//   // add a workflow
//   const addWorkflow = async (newWorkflow: { workflowName: string, workflowStatus: string }) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newWorkflow),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create workflow: ${response.statusText}`);
//       }

//       const data = await response.json();
//       // Ensure the list of workflow is up to date
//       await fetchWorkflows();

//       setLoading(false);
//       return data;

//     } catch (error) {
//       console.error('Error in addWorkflow:', error);
//       setError('Failed to add workflow. Please try again.');
//       setLoading(false);
//     }
//   };

//   const deleteWorkflow = async (workflowId: number) => {
//     setLoading(true);
//     setError(null);
  
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to delete workflow: ${response.statusText}`);
//       }
  
//       setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.workflowId !== workflowId));
//     } catch (error) {
//       setError('Failed to delete workflow.');
//       console.error('Error deleting workflow:', error);
//     } finally {
//       setLoading(false);
//     }
//   };  

//   // Fetch logic rows for a specific workflow
//   const fetchLogics = useCallback(async (workflowId: number | undefined) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for fetching logics');
//       return []; 
//     }

//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         console.log(`Failed to fetch logic rows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch logic rows: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setLoading(false);
//       return data;
//     } catch (error) {
//       console.error('Error fetching logic rows:', error);
//       setError('Failed to fetch logic rows.');
//       setLoading(false);
//       throw error;
//     }
//   }, [apiUrl]);
  
//   const addLogic = async (workflowId: number | undefined, newLogic: Partial<Schedule>) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for adding logic');
//       throw new Error('Invalid workflowId');
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newLogic), // Send the new logic data
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to add logic row: ${response.statusText}`);
//       }

//       const result = await response.json();
//       setLoading(false);
//       return result;
//     } catch (error) {
//       console.error('Error in addLogic:', error);
//       setError('Failed to add logic.');
//       setLoading(false);
//       throw error;
//     }
//   };

//    // Function to update logic
//    const updateLogic = async (workflowId: number, logicId: string, updatedLogic: Partial<Schedule>) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedLogic),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update logic');
//       }

//       const updated = await response.json();
//       return updated.logic;
//     } catch (error) {
//       console.error('Error updating logic:', error);
//       throw error;
//     }
//   };

//   const deleteLogics = async (workflowId: number, logicIds: string[]) => {
//     try {
//       // Loop through each selected logic row and send the delete request
//       for (const logicId of logicIds) {
//         await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//           method: 'DELETE',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//       }
//       return true; // Return success
//     } catch (error) {
//       console.error('Failed to delete logic rows:', error);
//       throw new Error('Failed to delete logic rows.');
//     }
//   };

//   // -- send email route ------------------------------------------------------------------------>>
//   // Function to send test email
//   const sendTestEmail = async (logicId: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/send-test-email`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ logicId }),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to send test email: ${response.statusText}`);
//       }

//       return await response.json();
//     } catch (error) {
//       console.error('Error sending test email:', error);
//       throw error;
//     }
//   };

//   // Function to schedule and send emails based on logic settings
//   let executionStopped = false;
//   let emailSentFlags: { [scheduleName: string]: { started: boolean; followUp: boolean; ended: boolean } } = {};  // Store flags for each logic type per schedule

//   const scheduleLogicEmails = async (logic: Schedule, schedules: Schedule[]) => {
//     const currentTime = new Date().toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' });
//     const currentDateTime = new Date(currentTime); // Convert current time to Date object

//     let scheduleTime: Date | null = null;

//     console.log(`[DEBUG] Current Time (Malaysia/KL): ${currentDateTime.toISOString()}`);
//     console.log(`[DEBUG] Logic Details for "${logic.scheduleName}":`, logic);

//     // Initialize email sent flags for this logic if not already initialized
//     if (!emailSentFlags[logic.scheduleName]) {
//         emailSentFlags[logic.scheduleName] = { started: false, followUp: false, ended: false };
//     }

//     if (executionStopped) {
//         console.log("Execution has been stopped. No further emails will be sent.");
//         return;
//     }

//     // Check if the logic is "On"
//     if (logic.logicStatus === "On") {
//         // Get the scheduled time if "scheduleDate" is provided and valid
//         if (logic.scheduleDate && logic.scheduleDate !== "1979-01-01 12:00:00") {
//             scheduleTime = new Date(new Date(logic.scheduleDate).toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' })); // Convert to local timezone
//             console.log(`[DEBUG] Scheduled Time (Malaysia/KL): ${scheduleTime.toISOString()}`);
//         }

//         // Handle future scheduling and prevent duplicate emails
//         if (scheduleTime && scheduleTime > currentDateTime && !emailSentFlags[logic.scheduleName].started && !emailSentFlags[logic.scheduleName].followUp && !emailSentFlags[logic.scheduleName].ended) {
//             const delay = scheduleTime.getTime() - currentDateTime.getTime();  // Calculate the delay in milliseconds
//             console.log(`[DEBUG] Scheduling email for logic "${logic.scheduleName}" after ${delay} milliseconds.`);

//             setTimeout(async () => {
//                 const now = new Date().toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' });
//                 console.log(`[DEBUG] Current Time (Malaysia/KL): ${new Date(now).toISOString()} - Triggering email for logic: ${logic.scheduleName}`);
//                 await sendEmail(logic);  // Send the email after the delay
//                 console.log(`[DEBUG] Scheduled email sent for logic: ${logic.scheduleName}`);
//                 emailSentFlags[logic.scheduleName].started = true;  // Mark the "Started" flag as sent
//                 await checkNextRun(logic, schedules);
//             }, delay);

//             return;  // Exit after scheduling the email for a future date
//         }

//         // If no valid scheduleTime or scheduleTime <= current time, send the "Started" email immediately
//         if ((!scheduleTime || scheduleTime <= currentDateTime) && !emailSentFlags[logic.scheduleName].started) {
//             console.log(`[DEBUG] Current time (${currentDateTime.toISOString()}) has reached or passed the scheduled time (${scheduleTime ? scheduleTime.toISOString() : 'N/A'}). Sending "Started" email immediately.`);
//             await sendEmail(logic);  // Send the "Started" email immediately
//             console.log(`[DEBUG] Immediate email sent for "Started" logic: ${logic.scheduleName}`);
//             emailSentFlags[logic.scheduleName].started = true;  // Mark "Started" as sent
//             await checkNextRun(logic, schedules);  // Proceed to the next logic
//         }

//         // Handle "Started" logic type
//         if (!emailSentFlags[logic.scheduleName].started && logic.logicType === "Started") {
//           console.log(`[DEBUG] "Started" logic type detected for: ${logic.scheduleName}`);

//           if (!scheduleTime || scheduleTime <= currentDateTime) {
//               await sendEmail(logic);  // Send "Started" logic email immediately
//               console.log(`[DEBUG] Immediate email sent for "Started" logic: ${logic.scheduleName}`);
//               emailSentFlags[logic.scheduleName].started = true;  // Mark "Started" as sent
//               await checkNextRun(logic, schedules);  // Proceed to the next logic
//           } else {
//               const delay = scheduleTime.getTime() - currentDateTime.getTime();
//               console.log(`[DEBUG] Scheduling "Started" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
//               setTimeout(async () => {
//                   if (!emailSentFlags[logic.scheduleName].started) {
//                       await sendEmail(logic);
//                       console.log(`[DEBUG] Scheduled "Started" email sent for logic: ${logic.scheduleName}`);
//                       emailSentFlags[logic.scheduleName].started = true;  // Mark as sent
//                       await checkNextRun(logic, schedules);  // Proceed to the next logic
//                   }
//               }, delay);
//           }
//           return;
//       }

//       // Handle "Follow-Up" logic type after "Started"
//       if (!emailSentFlags[logic.scheduleName].followUp && logic.logicType === "Follow-Up" && emailSentFlags[logic.scheduleName].started) {
//           console.log(`[DEBUG] "Follow-Up" logic type detected for: ${logic.scheduleName}`);

//           if (!scheduleTime || scheduleTime <= currentDateTime) {
//               await sendEmail(logic);  // Send "Follow-Up" logic email immediately
//               console.log(`[DEBUG] Immediate email sent for "Follow-Up" logic: ${logic.scheduleName}`);
//               emailSentFlags[logic.scheduleName].followUp = true;  // Mark "Follow-Up" as sent
//               await checkNextRun(logic, schedules);  // Proceed to the next logic
//           } else {
//               const delay = scheduleTime.getTime() - currentDateTime.getTime();
//               console.log(`[DEBUG] Scheduling "Follow-Up" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
//               setTimeout(async () => {
//                   if (!emailSentFlags[logic.scheduleName].followUp) {
//                       await sendEmail(logic);
//                       console.log(`[DEBUG] Scheduled "Follow-Up" email sent for logic: ${logic.scheduleName}`);
//                       emailSentFlags[logic.scheduleName].followUp = true;  // Mark as sent
//                       await checkNextRun(logic, schedules);  // Proceed to the next logic
//                   }
//               }, delay);
//           }
//           return;
//       }

//       // Handle "Ended" logic type after "Follow-Up"
//       if (!emailSentFlags[logic.scheduleName].ended && logic.logicType === "Ended" && emailSentFlags[logic.scheduleName].followUp) {
//           console.log(`[DEBUG] "Ended" logic type detected for: ${logic.scheduleName}`);

//           if (!scheduleTime || scheduleTime <= currentDateTime) {
//               await sendEmail(logic);  // Send "Ended" logic email immediately
//               console.log(`[DEBUG] Immediate email sent for "Ended" logic: ${logic.scheduleName}`);
//               emailSentFlags[logic.scheduleName].ended = true;  // Mark "Ended" as sent
//               executionStopped = true;  // Stop after sending the ended email
//           } else {
//               const delay = scheduleTime.getTime() - currentDateTime.getTime();
//               console.log(`[DEBUG] Scheduling "Ended" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
//               setTimeout(async () => {
//                   if (!emailSentFlags[logic.scheduleName].ended) {
//                       await sendEmail(logic);
//                       console.log(`[DEBUG] Scheduled "Ended" email sent for logic: ${logic.scheduleName}`);
//                       emailSentFlags[logic.scheduleName].ended = true;  // Mark as sent
//                       executionStopped = true;  // Stop after sending the ended email
//                   }
//               }, delay);
//           }
//           return;
//       }

//       // Handle "Stop Next Run" logic
//       if (logic.nextRun === "Stop Next Run" && !emailSentFlags[logic.scheduleName].started) {
//           await sendEmail(logic);
//           console.log(`Email sent for logic: ${logic.scheduleName}. Stopping further logic execution.`);
//           emailSentFlags[logic.scheduleName].started = true;  // Prevent re-sending
//           executionStopped = true;
//           return;
//       }

//       // Handle "No Apply" period (-1)
//       if (logic.period === -1) {
//           console.log(`[DEBUG] Period is set to -1, meaning no period applies. Only scheduling based on scheduleDate.`);
//           return;  // Exit as period is -1 and scheduling will follow scheduleDate
//       }
//     } else {
//         console.log(`Logic is off: ${logic.scheduleName}`);
//     }
//   };



//   // // Function to schedule and send emails based on logic settings
//   // let executionStopped = false;

//   // const scheduleLogicEmails = async (logic: Schedule, schedules: Schedule[]) => {
//   //   const currentTime = new Date().toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' });
//   //   const currentDateTime = new Date(currentTime); // Convert current time to Date object

//   //   let scheduleTime: Date | null = null;

//   //   console.log(`[DEBUG] Current Time (Malaysia/KL): ${currentDateTime.toISOString()}`);
//   //   console.log(`[DEBUG] Logic Details for "${logic.scheduleName}":`, logic);

//   //   if (executionStopped) {
//   //       console.log("Execution has been stopped. No further emails will be sent.");
//   //       return;
//   //   }

//   //   // Check if the logic is "On"
//   //   if (logic.logicStatus === "On") {
//   //       // Get the scheduled time if "scheduleDate" is provided and valid
//   //       if (logic.scheduleDate && logic.scheduleDate !== "1979-01-01 12:00:00") {
//   //           scheduleTime = new Date(new Date(logic.scheduleDate).toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' })); // Convert to local timezone
//   //           console.log(`[DEBUG] Scheduled Time (Malaysia/KL): ${scheduleTime.toISOString()}`);
//   //       }

//   //       // Handle future scheduling
//   //       if (scheduleTime && scheduleTime > currentDateTime) {
//   //           const delay = scheduleTime.getTime() - currentDateTime.getTime();  // Calculate the delay in milliseconds
//   //           console.log(`[DEBUG] Scheduling email for logic "${logic.scheduleName}" after ${delay} milliseconds.`);

//   //           setTimeout(async () => {
//   //               const now = new Date().toLocaleString("en-US", { timeZone: 'Asia/Kuala_Lumpur' });
//   //               console.log(`[DEBUG] Current Time (Malaysia/KL): ${new Date(now).toISOString()} - Triggering email for logic: ${logic.scheduleName}`);
//   //               await sendEmail(logic);  // Send the email after the delay
//   //               console.log(`[DEBUG] Scheduled email sent for logic: ${logic.scheduleName}`);
//   //               await checkNextRun(logic, schedules);
//   //           }, delay);

//   //           return;  // Exit after scheduling the email for a future date
//   //       }

//   //       // If no valid scheduleTime or scheduleTime <= current time, send the email immediately
//   //       if (!scheduleTime || scheduleTime <= currentDateTime) {
//   //           console.log(`[DEBUG] Current time (${currentDateTime.toISOString()}) has reached or passed the scheduled time (${scheduleTime ? scheduleTime.toISOString() : 'N/A'}). Sending email immediately.`);
//   //           await sendEmail(logic);  // Send the email immediately
//   //           console.log(`[DEBUG] Immediate email sent for logic: ${logic.scheduleName}`);
//   //           await checkNextRun(logic, schedules);  // Proceed to the next logic
//   //       }

//   //       // Handle "Stop Next Run" logic
//   //       if (logic.nextRun === "Stop Next Run") {
//   //           await sendEmail(logic);
//   //           console.log(`Email sent for logic: ${logic.scheduleName}. Stopping further logic execution.`);
//   //           executionStopped = true;
//   //           return;
//   //       }

//   //       // Handle "No Apply" period (-1)
//   //       if (logic.period === -1) {
//   //           console.log(`[DEBUG] Period is set to -1, meaning no period applies. Only scheduling based on scheduleDate.`);
//   //           return;  // Exit as period is -1 and scheduling will follow scheduleDate
//   //       }

//   //       // Handle "Ended" logic type
//   //       if (logic.logicType === "Ended") {
//   //           console.log(`[DEBUG] "Ended" logic type detected for: ${logic.scheduleName}`);

//   //           if (!scheduleTime || scheduleTime <= currentDateTime) {
//   //               await sendEmail(logic);  // Send "Ended" logic email
//   //               console.log(`[DEBUG] Immediate email sent for "Ended" logic: ${logic.scheduleName}`);
//   //               executionStopped = true;  // Stop after sending the ended email
//   //           } else {
//   //               // Schedule for the future if applicable
//   //               const delay = scheduleTime.getTime() - currentDateTime.getTime();
//   //               console.log(`[DEBUG] Scheduling "Ended" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
//   //               setTimeout(async () => {
//   //                   await sendEmail(logic);
//   //                   console.log(`[DEBUG] Scheduled "Ended" email sent for logic: ${logic.scheduleName}`);
//   //                   executionStopped = true;
//   //               }, delay);
//   //           }
//   //           return;
//   //       }

//   //       // Handle "Started" logic type
//   //       if (logic.logicType === "Started") {
//   //           console.log(`[DEBUG] "Started" logic type detected for: ${logic.scheduleName}`);

//   //           if (!scheduleTime || scheduleTime <= currentDateTime) {
//   //               await sendEmail(logic);  // Send "Started" logic email immediately
//   //               console.log(`[DEBUG] Immediate email sent for "Started" logic: ${logic.scheduleName}`);
//   //               await checkNextRun(logic, schedules);  // Proceed to the next logic
//   //           } else {
//   //               // Schedule for the future if applicable
//   //               const delay = scheduleTime.getTime() - currentDateTime.getTime();
//   //               console.log(`[DEBUG] Scheduling "Started" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
//   //               setTimeout(async () => {
//   //                   await sendEmail(logic);
//   //                   console.log(`[DEBUG] Scheduled "Started" email sent for logic: ${logic.scheduleName}`);
//   //                   await checkNextRun(logic, schedules);
//   //               }, delay);
//   //           }
//   //           return;
//   //       }

//   //       // Handle "Follow-Up" logic type
//   //       if (logic.logicType === "Follow-Up") {
//   //           console.log(`[DEBUG] "Follow-Up" logic type detected for: ${logic.scheduleName}`);

//   //           if (!scheduleTime || scheduleTime <= currentDateTime) {
//   //               await sendEmail(logic);  // Send "Follow-Up" logic email immediately
//   //               console.log(`[DEBUG] Immediate email sent for "Follow-Up" logic: ${logic.scheduleName}`);
//   //               await checkNextRun(logic, schedules);  // Proceed to the next logic
//   //           } else {
//   //               // Schedule for the future if applicable
//   //               const delay = scheduleTime.getTime() - currentDateTime.getTime();
//   //               console.log(`[DEBUG] Scheduling "Follow-Up" logic email for "${logic.scheduleName}" after ${delay} milliseconds.`);
//   //               setTimeout(async () => {
//   //                   await sendEmail(logic);
//   //                   console.log(`[DEBUG] Scheduled "Follow-Up" email sent for logic: ${logic.scheduleName}`);
//   //                   await checkNextRun(logic, schedules);
//   //               }, delay);
//   //           }
//   //           return;
//   //       }
//   //   } else {
//   //       console.log(`Logic is off: ${logic.scheduleName}`);
//   //   }
//   // };

//   // Function to check and schedule the next run logic
//   const checkNextRun = async (logic: Schedule, schedules: Schedule[]) => {
//     // Stop further execution if the flag is set
//     if (executionStopped) {
//         console.log("Execution has been stopped. No further logic will be processed.");
//         return;  // Stop further logic from being processed
//     }

//     // If the nextRun is "Stop Next Run", stop the execution
//     if (logic.nextRun === "Stop Next Run") {
//         console.log(`Logic sequence is stopping due to "Stop Next Run" for: ${logic.scheduleName}`);
//         return;  // Stop execution here and do not schedule any further logic
//     }

//     // Find the next logic based on nextRun
//     const nextLogic = schedules.find(s => s.scheduleName === logic.nextRun);

//     if (nextLogic && nextLogic.logicStatus === "On") {
//         console.log(`Executing next logic: ${nextLogic.scheduleName}`);
//         await scheduleLogicEmails(nextLogic, schedules);  // Continue with the next logic
//     } else {
//         console.log(`No valid next logic found or it's Off: ${logic.scheduleName}`);
//     }
//   };


//   // Fetch email content matching the `campTitle`
//   const fetchEmailContent = async (campTitle: string) => {
//     console.log(`FEC01: Fetching email content for campaign title: ${campTitle}`);

//     try {
//         const response = await fetch(`${apiUrl}/api/email-campaign?campTitle=${campTitle}`, {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (!response.ok) {
//             console.error('Failed to fetch email content. Status:', response.status);
//             return null;
//         }

//         const data = await response.json();
//         console.log('FEC02: Fetched email content:', data);
//         return data; // Ensure this returns an array of campaigns
//     } catch (error) {
//         console.error('Error fetching email content:', error);
//         return null;
//     }
//   };

//   // Function to send email with content matched by `campTitle`
//   const sendEmail = async (logic: Schedule) => {
//     try {
//       console.log(`Fetching email content for logic: ${logic.scheduleName}`);
//       console.log(`Fetching email content with campTitle: ${logic.emailContent}`);
  
//       // Fetch email content based on the campaign title from the logic
//       const emailCampaigns: CampaignEmail[] = await fetchEmailContent(logic.emailContent);
  
//       if (!emailCampaigns || emailCampaigns.length === 0) {
//         console.error(`No email content found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       // Find the matching campaign where campTitle matches the emailContent from logic
//       const matchingCampaign = emailCampaigns.find((campaign: CampaignEmail) => 
//         campaign.campTitle.trim().toLowerCase() === logic.emailContent.trim().toLowerCase()
//       );
  
//       if (!matchingCampaign) {
//         console.error(`No matching campaign found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       console.log('Matching campaign data:', matchingCampaign);
  
//       // Prepare the email data using the matched campaign details
//       const emailData = {
//         campaignId: matchingCampaign.id, // Ensure the correct campaignId is used
//         sendFrom: matchingCampaign.sendFrom,
//         sendTo: matchingCampaign.sendTo,
//         sendCC: matchingCampaign.sendCC,
//         subjectMail: matchingCampaign.subjectMail,
//         contentTitle: matchingCampaign.contentTitle,
//         contentBody: matchingCampaign.contentBody,
//         contentButton: matchingCampaign.contentButton,
//       };
  
//       console.log(`Sending email with data:`, emailData);
  
//       // Send the email using the prepared emailData
//       await sendScheduledEmail(emailData);
//     } catch (error) {
//       console.error('Error in sending email:', error);
//     }
//   };   
  
//   // Send scheduled email using the email data
//   const sendScheduledEmail = async (emailData: any) => {
//     try {
//         console.log('Preparing to send scheduled email with the following data:', emailData);

//         // Ensure campaignId is present before proceeding
//         if (!emailData.campaignId) {
//             console.error('Error: Missing campaignId in emailData:', emailData);
//             throw new Error('Missing campaignId in emailData');
//         }

//         const response = await fetch(`${apiUrl}/api/send-scheduled-emails`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ campaignId: emailData.campaignId }), // Ensure campaignId is passed correctly
//         });

//         if (!response.ok) {
//             throw new Error(`Failed to send scheduled emails: ${response.statusText}`);
//         }

//         const result = await response.json();
//         console.log('SSE01: Scheduled email sent successfully:', result);
//     } catch (error) {
//         console.error('Error sending scheduled email:', error);
//     }
//   };


//   return {
//     // email content
//     email,
//     fetchCampaigns,
//     addCampaign,
//     updateCampaign,
//     deleteCampaign,
//     // workflow 
//     workflows,
//     fetchWorkflows,
//     addWorkflow,
//     updateWorkflowStatus,
//     deleteWorkflow,
//     // workflow logic
//     fetchLogics,
//     addLogic,
//     updateLogic,
//     deleteLogics,
//     // schedule email
//     scheduleLogicEmails,
//     sendTestEmail,
//     loading,
//     error,
//   };
// };

// // udpate code --> 20 sept 2024
// // src/hooks/AdminDashboard/useEmailSetting.tsx

// import { useState, useCallback, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext'; 
// import moment from 'moment-timezone';

// interface CampaignEmail {
//   id: number;
//   campTitle: string;
//   campType: string;
//   campList: string;
//   // content setting
//   contentTitle: string;
//   contentName: string;
//   contentBody: string;
//   contentButton: string;
//   // email setting
//   sendFrom: string;
//   sendTo: string;
//   sendCC: string;
//   subjectMail: string;
//   logicStatus?: string;
// }

// interface CampaignWorkflow {
//   id: number;
//   workflowId: number;
//   workflowName: string;
//   workflowType: string;
//   workflowStatus: string;
//   clickedStats: string;
//   createdAt: Date;
// }


// interface Schedule {
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// export const useEmail = () => {
//   const [email, setEmail] = useState<CampaignEmail[]>([]);
//   const [workflows, setWorkflows] = useState<CampaignWorkflow[]>([]); 
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   // Campaigns Email section --------------------------------------->>
//   // fetching campaigns email setting
//   const fetchCampaigns = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setEmail(data);
//     } catch (error) {
//       console.error('Error fetching campaigns:', error);
//       setError('Failed to fetch campaigns.');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]); 

//   const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newCampaignEmail),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create a campaign: ${response.statusText}`);
//       }

//       const data = await response.json();

//       setEmail([...email, { ...newCampaignEmail, id: data.id }]);
//       setLoading(false);
//     } catch (error) {

//       console.error('Error in addCampaign:', error);
//       setError('Failed to add campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   const updateCampaign = async (updatedCampaign: CampaignEmail) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedCampaign),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to update campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) =>
//         prevEmails.map((email) =>
//           email.id === updatedCampaign.id ? updatedCampaign : email
//         )
//       );
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in updateCampaign:', error); 
//       setError('Failed to update campaign.');
//     }
//   };

//   // Add the deleteCampaign function
//   const deleteCampaign = async (campaignId: number) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${campaignId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to delete campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) => prevEmails.filter((email) => email.id !== campaignId));
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in deleteCampaign:', error);
//       setError('Failed to delete campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCampaigns(); 
//   }, [fetchCampaigns]);

//   // created workflow section --------------------------------------->>
//   // Fetch workflows
//   const fetchWorkflows = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         console.error(`Failed to fetch workflows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch workflows: ${response.statusText}`);
//       }
  
//       const data = await response.json();
  
//       setWorkflows(data);
//       setLoading(false);

//     } catch (error) {
//       console.error('Error fetching workflows:', error);
//       setError('Failed to fetch workflows.');
//       setLoading(false);
//     }
//   }, [apiUrl]);
  

//    // Function to update the workflow status and name
//   const updateWorkflowStatus = async (workflowId: number, newStatus: string, workflowName?: string) => {
//     try {
//       const requestBody: any = {};
//       if (newStatus !== 'KeepCurrentStatus') {
//         requestBody.workflowStatus = newStatus;
//       }
//       if (workflowName !== undefined) {
//         requestBody.workflowName = workflowName;
//       }

//       if (Object.keys(requestBody).length === 0) {
//         // Nothing to update
//         return;
//       }

//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/status`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update workflow');
//       }

//       // Update local workflows state
//       setWorkflows((prevWorkflows) =>
//         prevWorkflows.map((workflow) =>
//           workflow.workflowId === workflowId
//             ? { ...workflow, ...requestBody }
//             : workflow
//         )
//       );

//       await fetchWorkflows(); // Refresh workflows
//     } catch (error) {
//       console.error('Error updating workflow:', error);
//       throw error;
//     }
//   };

//   // add a workflow
//   const addWorkflow = async (newWorkflow: { workflowName: string, workflowStatus: string }) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newWorkflow),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create workflow: ${response.statusText}`);
//       }

//       const data = await response.json();
//       // Ensure the list of workflow is up to date
//       await fetchWorkflows();

//       setLoading(false);
//       return data;

//     } catch (error) {
//       console.error('Error in addWorkflow:', error);
//       setError('Failed to add workflow. Please try again.');
//       setLoading(false);
//     }
//   };

//   const deleteWorkflow = async (workflowId: number) => {
//     setLoading(true);
//     setError(null);
  
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to delete workflow: ${response.statusText}`);
//       }
  
//       setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.workflowId !== workflowId));
//     } catch (error) {
//       setError('Failed to delete workflow.');
//       console.error('Error deleting workflow:', error);
//     } finally {
//       setLoading(false);
//     }
//   };  

//   // Fetch logic rows for a specific workflow
//   const fetchLogics = useCallback(async (workflowId: number | undefined) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for fetching logics');
//       return []; 
//     }

//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         console.log(`Failed to fetch logic rows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch logic rows: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setLoading(false);
//       return data;
//     } catch (error) {
//       console.error('Error fetching logic rows:', error);
//       setError('Failed to fetch logic rows.');
//       setLoading(false);
//       throw error;
//     }
//   }, [apiUrl]);
  
//   const addLogic = async (workflowId: number | undefined, newLogic: Partial<Schedule>) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for adding logic');
//       throw new Error('Invalid workflowId');
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newLogic), // Send the new logic data
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to add logic row: ${response.statusText}`);
//       }

//       const result = await response.json();
//       setLoading(false);
//       return result;
//     } catch (error) {
//       console.error('Error in addLogic:', error);
//       setError('Failed to add logic.');
//       setLoading(false);
//       throw error;
//     }
//   };

//    // Function to update logic
//    const updateLogic = async (workflowId: number, logicId: string, updatedLogic: Partial<Schedule>) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedLogic),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update logic');
//       }

//       const updated = await response.json();
//       return updated.logic;
//     } catch (error) {
//       console.error('Error updating logic:', error);
//       throw error;
//     }
//   };

//   const deleteLogics = async (workflowId: number, logicIds: string[]) => {
//     try {
//       // Loop through each selected logic row and send the delete request
//       for (const logicId of logicIds) {
//         await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//           method: 'DELETE',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//       }
//       return true; // Return success
//     } catch (error) {
//       console.error('Failed to delete logic rows:', error);
//       throw new Error('Failed to delete logic rows.');
//     }
//   };

//   // -- send email route ------------------------------------------------------------------------>>
//   // Function to send test email
//   const sendTestEmail = async (logicId: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/send-test-email`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ logicId }),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to send test email: ${response.statusText}`);
//       }

//       return await response.json();
//     } catch (error) {
//       console.error('Error sending test email:', error);
//       throw error;
//     }
//   };

//   // Function to schedule and send emails based on logic settings
//   let executionStopped = false; 

//   const scheduleLogicEmails = async (logic: Schedule, schedules: Schedule[]) => {
//     const currentTime = moment.tz('Asia/Kuala_Lumpur');  // Set current time to Malaysia/Kuala Lumpur time
//     let scheduleTime: moment.Moment | null = null;

//     console.log(`[DEBUG] Current Time (Malaysia/KL): ${currentTime.format('YYYY-MM-DD HH:mm:ss')}`);
//     console.log(`[DEBUG] Logic Details for "${logic.scheduleName}":`, logic);

//     if (executionStopped) {
//         console.log("Execution has been stopped. No further emails will be sent.");
//         return;
//     }

//     // Check if the logic is "On"
//     if (logic.logicStatus === "On") {

//         // Handle the "Ended" logic first
//         if (logic.logicType === "Ended") {

//             await sendEmail(logic); 
//             console.log(`End mail sent for logic: ${logic.scheduleName}`);

//             executionStopped = true;  
//             return;  
//         }

//         // Handle the case for "Stop Next Run"
//         if (logic.nextRun === "Stop Next Run") {

//             await sendEmail(logic); 
//             console.log(`Email sent for logic: ${logic.scheduleName}. Stopping further logic execution.`);

//             executionStopped = true; 
//             return;
//         }

//         // Handle the "No Apply" period (-1)
//         if (logic.period === -1) {
//             return;  
//         }

//         // Handle "Started" and "Follow-Up" logic types
//         if (logic.logicType === "Started") {
//             console.log(`[DEBUG] "Started" logic type detected for: ${logic.scheduleName}`);

//             // Ensure email is scheduled for the correct time
//             if (logic.scheduleDate && logic.scheduleDate !== "1979-01-01 12:00:00") {
//               scheduleTime = moment.tz(logic.scheduleDate, 'Asia/Kuala_Lumpur');  // Schedule at the specific date
//               console.log(`[DEBUG] Scheduled Time (Malaysia/KL): ${scheduleTime.format('YYYY-MM-DD HH:mm:ss')}`);
//             } 

//             // Handle future scheduling
//             if (scheduleTime && scheduleTime.isAfter(currentTime)) {
//               const delay = scheduleTime.diff(currentTime);  // Get the delay in milliseconds

//               setTimeout(async () => {
//                   console.log(`[DEBUG] Triggering email for logic: ${logic.scheduleName}`);
//                   await sendEmail(logic);  // Send the email after the delay
//                   console.log(`[DEBUG] Scheduled email sent for logic: ${logic.scheduleName}`);
//                   await checkNextRun(logic, schedules);
//               }, delay);

//               return;  // Exit after scheduling the email for a future date
//             }

//             // If the schedule date is in the past or immediate
//             if (!scheduleTime || scheduleTime.isSameOrBefore(currentTime)) {
//               console.log("[DEBUG] Sending email immediately since the schedule time is in the past or invalid.");
//               await sendEmail(logic);  // Send the "Started" logic email immediately
//               console.log(`[DEBUG] Started email sent for logic: ${logic.scheduleName}`);
//               await checkNextRun(logic, schedules);  // Proceed to the next logic
//           }
//       } else if (logic.logicType === "Follow-Up") {
//           console.log(`[DEBUG] Follow-up logic executing: ${logic.scheduleName}`);
//           await sendEmail(logic);  // Send the "Follow-Up" logic email
//           await checkNextRun(logic, schedules);  // Proceed to the next logic if valid
//       }

//     } else {
//         console.log(`Logic is off: ${logic.scheduleName}`);
//     }
//   };

//   // Function to check and schedule the next run logic
//   const checkNextRun = async (logic: Schedule, schedules: Schedule[]) => {
//     // Stop further execution if the flag is set
//     if (executionStopped) {
//         console.log("Execution has been stopped. No further logic will be processed.");
//         return;  // Stop further logic from being processed
//     }

//     // If the nextRun is "Stop Next Run", stop the execution
//     if (logic.nextRun === "Stop Next Run") {
//         console.log(`Logic sequence is stopping due to "Stop Next Run" for: ${logic.scheduleName}`);
//         return;  // Stop execution here and do not schedule any further logic
//     }

//     // Find the next logic based on nextRun
//     const nextLogic = schedules.find(s => s.scheduleName === logic.nextRun);

//     if (nextLogic && nextLogic.logicStatus === "On") {
//         console.log(`Executing next logic: ${nextLogic.scheduleName}`);
//         await scheduleLogicEmails(nextLogic, schedules);  // Continue with the next logic
//     } else {
//         console.log(`No valid next logic found or it's Off: ${logic.scheduleName}`);
//     }
//   };


//   // Fetch email content matching the `campTitle`
//   const fetchEmailContent = async (campTitle: string) => {
//     console.log(`FEC01: Fetching email content for campaign title: ${campTitle}`);

//     try {
//         const response = await fetch(`${apiUrl}/api/email-campaign?campTitle=${campTitle}`, {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (!response.ok) {
//             console.error('Failed to fetch email content. Status:', response.status);
//             return null;
//         }

//         const data = await response.json();
//         console.log('FEC02: Fetched email content:', data);
//         return data; // Ensure this returns an array of campaigns
//     } catch (error) {
//         console.error('Error fetching email content:', error);
//         return null;
//     }
//   };

//   // Function to send email with content matched by `campTitle`
//   const sendEmail = async (logic: Schedule) => {
//     try {
//       console.log(`Fetching email content for logic: ${logic.scheduleName}`);
//       console.log(`Fetching email content with campTitle: ${logic.emailContent}`);
  
//       // Fetch email content based on the campaign title from the logic
//       const emailCampaigns: CampaignEmail[] = await fetchEmailContent(logic.emailContent);
  
//       if (!emailCampaigns || emailCampaigns.length === 0) {
//         console.error(`No email content found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       // Find the matching campaign where campTitle matches the emailContent from logic
//       const matchingCampaign = emailCampaigns.find((campaign: CampaignEmail) => 
//         campaign.campTitle.trim().toLowerCase() === logic.emailContent.trim().toLowerCase()
//       );
  
//       if (!matchingCampaign) {
//         console.error(`No matching campaign found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       console.log('Matching campaign data:', matchingCampaign);
  
//       // Prepare the email data using the matched campaign details
//       const emailData = {
//         campaignId: matchingCampaign.id, // Ensure the correct campaignId is used
//         sendFrom: matchingCampaign.sendFrom,
//         sendTo: matchingCampaign.sendTo,
//         sendCC: matchingCampaign.sendCC,
//         subjectMail: matchingCampaign.subjectMail,
//         contentTitle: matchingCampaign.contentTitle,
//         contentBody: matchingCampaign.contentBody,
//         contentButton: matchingCampaign.contentButton,
//       };
  
//       console.log(`Sending email with data:`, emailData);
  
//       // Send the email using the prepared emailData
//       await sendScheduledEmail(emailData);
//     } catch (error) {
//       console.error('Error in sending email:', error);
//     }
//   };   
  
//   // Send scheduled email using the email data
//   const sendScheduledEmail = async (emailData: any) => {
//     try {
//         console.log('Preparing to send scheduled email with the following data:', emailData);

//         // Ensure campaignId is present before proceeding
//         if (!emailData.campaignId) {
//             console.error('Error: Missing campaignId in emailData:', emailData);
//             throw new Error('Missing campaignId in emailData');
//         }

//         const response = await fetch(`${apiUrl}/api/send-scheduled-emails`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ campaignId: emailData.campaignId }), // Ensure campaignId is passed correctly
//         });

//         if (!response.ok) {
//             throw new Error(`Failed to send scheduled emails: ${response.statusText}`);
//         }

//         const result = await response.json();
//         console.log('SSE01: Scheduled email sent successfully:', result);
//     } catch (error) {
//         console.error('Error sending scheduled email:', error);
//     }
//   };


//   return {
//     // email content
//     email,
//     fetchCampaigns,
//     addCampaign,
//     updateCampaign,
//     deleteCampaign,
//     // workflow 
//     workflows,
//     fetchWorkflows,
//     addWorkflow,
//     updateWorkflowStatus,
//     deleteWorkflow,
//     // workflow logic
//     fetchLogics,
//     addLogic,
//     updateLogic,
//     deleteLogics,
//     // schedule email
//     scheduleLogicEmails,
//     sendTestEmail,
//     loading,
//     error,
//   };
// };


// // udpate code --> 19 sept 2024 v03
// // src/hooks/AdminDashboard/useEmailSetting.tsx

// import { useState, useCallback, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext'; 

// interface CampaignEmail {
//   id: number;
//   campTitle: string;
//   campType: string;
//   campList: string;
//   // content setting
//   contentTitle: string;
//   contentName: string;
//   contentBody: string;
//   contentButton: string;
//   // email setting
//   sendFrom: string;
//   sendTo: string;
//   sendCC: string;
//   subjectMail: string;
//   logicStatus?: string;
// }

// interface CampaignWorkflow {
//   id: number;
//   workflowId: number;
//   workflowName: string;
//   workflowType: string;
//   workflowStatus: string;
//   clickedStats: string;
//   createdAt: Date;
// }


// interface Schedule {
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// export const useEmail = () => {
//   const [email, setEmail] = useState<CampaignEmail[]>([]);
//   const [workflows, setWorkflows] = useState<CampaignWorkflow[]>([]); 
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   // Campaigns Email section --------------------------------------->>
//   // fetching campaigns email setting
//   const fetchCampaigns = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setEmail(data);
//     } catch (error) {
//       console.error('Error fetching campaigns:', error);
//       setError('Failed to fetch campaigns.');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]); 

//   const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newCampaignEmail),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create a campaign: ${response.statusText}`);
//       }

//       const data = await response.json();

//       setEmail([...email, { ...newCampaignEmail, id: data.id }]);
//       setLoading(false);
//     } catch (error) {

//       console.error('Error in addCampaign:', error);
//       setError('Failed to add campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   const updateCampaign = async (updatedCampaign: CampaignEmail) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedCampaign),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to update campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) =>
//         prevEmails.map((email) =>
//           email.id === updatedCampaign.id ? updatedCampaign : email
//         )
//       );
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in updateCampaign:', error); 
//       setError('Failed to update campaign.');
//     }
//   };

//   // Add the deleteCampaign function
//   const deleteCampaign = async (campaignId: number) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${campaignId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to delete campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) => prevEmails.filter((email) => email.id !== campaignId));
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in deleteCampaign:', error);
//       setError('Failed to delete campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCampaigns(); 
//   }, [fetchCampaigns]);

//   // created workflow section --------------------------------------->>
//   // Fetch workflows
//   const fetchWorkflows = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         console.error(`Failed to fetch workflows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch workflows: ${response.statusText}`);
//       }
  
//       const data = await response.json();
  
//       setWorkflows(data);
//       setLoading(false);

//     } catch (error) {
//       console.error('Error fetching workflows:', error);
//       setError('Failed to fetch workflows.');
//       setLoading(false);
//     }
//   }, [apiUrl]);
  

//    // Function to update the workflow status and name
//   const updateWorkflowStatus = async (workflowId: number, newStatus: string, workflowName?: string) => {
//     try {
//       const requestBody: any = {};
//       if (newStatus !== 'KeepCurrentStatus') {
//         requestBody.workflowStatus = newStatus;
//       }
//       if (workflowName !== undefined) {
//         requestBody.workflowName = workflowName;
//       }

//       if (Object.keys(requestBody).length === 0) {
//         // Nothing to update
//         return;
//       }

//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/status`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update workflow');
//       }

//       // Update local workflows state
//       setWorkflows((prevWorkflows) =>
//         prevWorkflows.map((workflow) =>
//           workflow.workflowId === workflowId
//             ? { ...workflow, ...requestBody }
//             : workflow
//         )
//       );

//       await fetchWorkflows(); // Refresh workflows
//     } catch (error) {
//       console.error('Error updating workflow:', error);
//       throw error;
//     }
//   };

//   // add a workflow
//   const addWorkflow = async (newWorkflow: { workflowName: string, workflowStatus: string }) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newWorkflow),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create workflow: ${response.statusText}`);
//       }

//       const data = await response.json();
//       // Ensure the list of workflow is up to date
//       await fetchWorkflows();

//       setLoading(false);
//       return data;

//     } catch (error) {
//       console.error('Error in addWorkflow:', error);
//       setError('Failed to add workflow. Please try again.');
//       setLoading(false);
//     }
//   };

//   const deleteWorkflow = async (workflowId: number) => {
//     setLoading(true);
//     setError(null);
  
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to delete workflow: ${response.statusText}`);
//       }
  
//       setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.workflowId !== workflowId));
//     } catch (error) {
//       setError('Failed to delete workflow.');
//       console.error('Error deleting workflow:', error);
//     } finally {
//       setLoading(false);
//     }
//   };  

//   // Fetch logic rows for a specific workflow
//   const fetchLogics = useCallback(async (workflowId: number | undefined) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for fetching logics');
//       return []; 
//     }

//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         console.log(`Failed to fetch logic rows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch logic rows: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setLoading(false);
//       return data;
//     } catch (error) {
//       console.error('Error fetching logic rows:', error);
//       setError('Failed to fetch logic rows.');
//       setLoading(false);
//       throw error;
//     }
//   }, [apiUrl]);
  
//   const addLogic = async (workflowId: number | undefined, newLogic: Partial<Schedule>) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for adding logic');
//       throw new Error('Invalid workflowId');
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newLogic), // Send the new logic data
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to add logic row: ${response.statusText}`);
//       }

//       const result = await response.json();
//       setLoading(false);
//       return result;
//     } catch (error) {
//       console.error('Error in addLogic:', error);
//       setError('Failed to add logic.');
//       setLoading(false);
//       throw error;
//     }
//   };

//    // Function to update logic
//    const updateLogic = async (workflowId: number, logicId: string, updatedLogic: Partial<Schedule>) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedLogic),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update logic');
//       }

//       const updated = await response.json();
//       return updated.logic;
//     } catch (error) {
//       console.error('Error updating logic:', error);
//       throw error;
//     }
//   };

//   const deleteLogics = async (workflowId: number, logicIds: string[]) => {
//     try {
//       // Loop through each selected logic row and send the delete request
//       for (const logicId of logicIds) {
//         await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//           method: 'DELETE',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//       }
//       return true; // Return success
//     } catch (error) {
//       console.error('Failed to delete logic rows:', error);
//       throw new Error('Failed to delete logic rows.');
//     }
//   };

//   // -- send email route ------------------------------------------------------------------------>>
//   // Function to send test email
//   const sendTestEmail = async (logicId: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/send-test-email`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ logicId }),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to send test email: ${response.statusText}`);
//       }

//       return await response.json();
//     } catch (error) {
//       console.error('Error sending test email:', error);
//       throw error;
//     }
//   };

//   // Function to schedule and send emails based on logic settings
//   let executionStopped = false;  // Global flag to stop further execution

//   const scheduleLogicEmails = async (logic: Schedule, schedules: Schedule[]) => {
//       const currentTime = new Date();
//       let scheduleTime: Date | null = null;

//       // Stop further execution if the flag is set
//       if (executionStopped) {
//           console.log("Execution has already been stopped. No further emails will be sent.");
//           return;  // Prevent further processing
//       }

//       // Check if the logic is "On"
//       if (logic.logicStatus === "On") {

//           // Handle the "Ended" logic first
//           if (logic.logicType === "Ended") {
//               console.log(`End logic executed for: ${logic.scheduleName}`);
//               await sendEmail(logic);  // Send the "End Mail"
//               console.log(`End mail sent for logic: ${logic.scheduleName}`);

//               executionStopped = true;  // Set flag to stop further execution
//               return;  // Stop after sending the End Mail - no further execution
//           }

//           // Handle the case for "Stop Next Run"
//           if (logic.nextRun === "Stop Next Run") {
//               console.log(`Logic sequence is stopping because nextRun is "Stop Next Run" for: ${logic.scheduleName}`);
//               await sendEmail(logic);  // Send the email for this logic once
//               console.log(`Email sent for logic: ${logic.scheduleName}. Stopping further logic execution.`);

//               executionStopped = true;  // Set flag to stop further execution
//               return;  // Stop the logic sequence after this email
//           }

//           // Handle "Started" and "Follow-Up" logic types
//           if (logic.logicType === "Started") {
//               console.log(`Started logic found: ${logic.scheduleName}`);

//               if (logic.scheduleDate && logic.scheduleDate !== "1979-01-01 12:00:00") {
//                   scheduleTime = new Date(logic.scheduleDate);  // Schedule at the specific date
//               } else if (logic.period > 0) {
//                   scheduleTime = new Date(currentTime);
//                   scheduleTime.setDate(currentTime.getDate() + logic.period);  // Add the period to the current date
//                   scheduleTime.setHours(8, 0, 0, 0);  // Set time to 8:00 AM (customizable)
//               }

//               if (!scheduleTime || scheduleTime <= currentTime) {
//                   await sendEmail(logic);  // Send the "Started" logic email immediately
//                   console.log(`Started email sent for logic: ${logic.scheduleName}`);
//                   await checkNextRun(logic, schedules);  // Proceed to the next logic
//               }

//           } else if (logic.logicType === "Follow-Up") {
//               console.log(`Follow-up logic executing: ${logic.scheduleName}`);
//               await sendEmail(logic);  // Send the "Follow-Up" logic email
//               await checkNextRun(logic, schedules);  // Proceed to the next logic if valid
//           }

//       } else {
//           console.log(`Logic is off: ${logic.scheduleName}`);
//       }
//   };

//   // Function to check and schedule the next run logic
//   const checkNextRun = async (logic: Schedule, schedules: Schedule[]) => {
//     // Stop further execution if the flag is set
//     if (executionStopped) {
//         console.log("Execution has been stopped. No further logic will be processed.");
//         return;  // Stop further logic from being processed
//     }

//     // If the nextRun is "Stop Next Run", stop the execution
//     if (logic.nextRun === "Stop Next Run") {
//         console.log(`Logic sequence is stopping due to "Stop Next Run" for: ${logic.scheduleName}`);
//         return;  // Stop execution here and do not schedule any further logic
//     }

//     // Find the next logic based on nextRun
//     const nextLogic = schedules.find(s => s.scheduleName === logic.nextRun);

//     if (nextLogic && nextLogic.logicStatus === "On") {
//         console.log(`Executing next logic: ${nextLogic.scheduleName}`);
//         await scheduleLogicEmails(nextLogic, schedules);  // Continue with the next logic
//     } else {
//         console.log(`No valid next logic found or it's Off: ${logic.scheduleName}`);
//     }
//   };


//   // Fetch email content matching the `campTitle`
//   const fetchEmailContent = async (campTitle: string) => {
//     console.log(`FEC01: Fetching email content for campaign title: ${campTitle}`);

//     try {
//         const response = await fetch(`${apiUrl}/api/email-campaign?campTitle=${campTitle}`, {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (!response.ok) {
//             console.error('Failed to fetch email content. Status:', response.status);
//             return null;
//         }

//         const data = await response.json();
//         console.log('FEC02: Fetched email content:', data);
//         return data; // Ensure this returns an array of campaigns
//     } catch (error) {
//         console.error('Error fetching email content:', error);
//         return null;
//     }
//   };

//   // Function to send email with content matched by `campTitle`
//   const sendEmail = async (logic: Schedule) => {
//     try {
//       console.log(`Fetching email content for logic: ${logic.scheduleName}`);
//       console.log(`Fetching email content with campTitle: ${logic.emailContent}`);
  
//       // Fetch email content based on the campaign title from the logic
//       const emailCampaigns: CampaignEmail[] = await fetchEmailContent(logic.emailContent);
  
//       if (!emailCampaigns || emailCampaigns.length === 0) {
//         console.error(`No email content found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       // Find the matching campaign where campTitle matches the emailContent from logic
//       const matchingCampaign = emailCampaigns.find((campaign: CampaignEmail) => 
//         campaign.campTitle.trim().toLowerCase() === logic.emailContent.trim().toLowerCase()
//       );
  
//       if (!matchingCampaign) {
//         console.error(`No matching campaign found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       console.log('Matching campaign data:', matchingCampaign);
  
//       // Prepare the email data using the matched campaign details
//       const emailData = {
//         campaignId: matchingCampaign.id, // Ensure the correct campaignId is used
//         sendFrom: matchingCampaign.sendFrom,
//         sendTo: matchingCampaign.sendTo,
//         sendCC: matchingCampaign.sendCC,
//         subjectMail: matchingCampaign.subjectMail,
//         contentTitle: matchingCampaign.contentTitle,
//         contentBody: matchingCampaign.contentBody,
//         contentButton: matchingCampaign.contentButton,
//       };
  
//       console.log(`Sending email with data:`, emailData);
  
//       // Send the email using the prepared emailData
//       await sendScheduledEmail(emailData);
//     } catch (error) {
//       console.error('Error in sending email:', error);
//     }
//   };   
  
//   // Send scheduled email using the email data
//   const sendScheduledEmail = async (emailData: any) => {
//     try {
//         console.log('Preparing to send scheduled email with the following data:', emailData);

//         // Ensure campaignId is present before proceeding
//         if (!emailData.campaignId) {
//             console.error('Error: Missing campaignId in emailData:', emailData);
//             throw new Error('Missing campaignId in emailData');
//         }

//         const response = await fetch(`${apiUrl}/api/send-scheduled-emails`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ campaignId: emailData.campaignId }), // Ensure campaignId is passed correctly
//         });

//         if (!response.ok) {
//             throw new Error(`Failed to send scheduled emails: ${response.statusText}`);
//         }

//         const result = await response.json();
//         console.log('SSE01: Scheduled email sent successfully:', result);
//     } catch (error) {
//         console.error('Error sending scheduled email:', error);
//     }
//   };


//   return {
//     // email content
//     email,
//     fetchCampaigns,
//     addCampaign,
//     updateCampaign,
//     deleteCampaign,
//     // workflow 
//     workflows,
//     fetchWorkflows,
//     addWorkflow,
//     updateWorkflowStatus,
//     deleteWorkflow,
//     // workflow logic
//     fetchLogics,
//     addLogic,
//     updateLogic,
//     deleteLogics,
//     // schedule email
//     scheduleLogicEmails,
//     sendTestEmail,
//     loading,
//     error,
//   };
// };

// // udpate code --> 19 sept 2024 v02
// // src/hooks/AdminDashboard/useEmailSetting.tsx

// import { useState, useCallback, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext'; 

// interface CampaignEmail {
//   id: number;
//   campTitle: string;
//   campType: string;
//   campList: string;
//   // content setting
//   contentTitle: string;
//   contentName: string;
//   contentBody: string;
//   contentButton: string;
//   // email setting
//   sendFrom: string;
//   sendTo: string;
//   sendCC: string;
//   subjectMail: string;
//   logicStatus?: string;
// }

// interface CampaignWorkflow {
//   id: number;
//   workflowId: number;
//   workflowName: string;
//   workflowType: string;
//   workflowStatus: string;
//   clickedStats: string;
//   createdAt: Date;
// }


// interface Schedule {
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// export const useEmail = () => {
//   const [email, setEmail] = useState<CampaignEmail[]>([]);
//   const [workflows, setWorkflows] = useState<CampaignWorkflow[]>([]); 
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   // Campaigns Email section --------------------------------------->>
//   // fetching campaigns email setting
//   const fetchCampaigns = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setEmail(data);
//     } catch (error) {
//       console.error('Error fetching campaigns:', error);
//       setError('Failed to fetch campaigns.');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]); 

//   const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newCampaignEmail),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create a campaign: ${response.statusText}`);
//       }

//       const data = await response.json();

//       setEmail([...email, { ...newCampaignEmail, id: data.id }]);
//       setLoading(false);
//     } catch (error) {

//       console.error('Error in addCampaign:', error);
//       setError('Failed to add campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   const updateCampaign = async (updatedCampaign: CampaignEmail) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedCampaign),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to update campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) =>
//         prevEmails.map((email) =>
//           email.id === updatedCampaign.id ? updatedCampaign : email
//         )
//       );
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in updateCampaign:', error); 
//       setError('Failed to update campaign.');
//     }
//   };

//   // Add the deleteCampaign function
//   const deleteCampaign = async (campaignId: number) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${campaignId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to delete campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) => prevEmails.filter((email) => email.id !== campaignId));
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in deleteCampaign:', error);
//       setError('Failed to delete campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCampaigns(); 
//   }, [fetchCampaigns]);

//   // created workflow section --------------------------------------->>
//   // Fetch workflows
//   const fetchWorkflows = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         console.error(`Failed to fetch workflows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch workflows: ${response.statusText}`);
//       }
  
//       const data = await response.json();
  
//       setWorkflows(data);
//       setLoading(false);

//     } catch (error) {
//       console.error('Error fetching workflows:', error);
//       setError('Failed to fetch workflows.');
//       setLoading(false);
//     }
//   }, [apiUrl]);
  

//    // Function to update the workflow status and name
//   const updateWorkflowStatus = async (workflowId: number, newStatus: string, workflowName?: string) => {
//     try {
//       const requestBody: any = {};
//       if (newStatus !== 'KeepCurrentStatus') {
//         requestBody.workflowStatus = newStatus;
//       }
//       if (workflowName !== undefined) {
//         requestBody.workflowName = workflowName;
//       }

//       if (Object.keys(requestBody).length === 0) {
//         // Nothing to update
//         return;
//       }

//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/status`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update workflow');
//       }

//       // Update local workflows state
//       setWorkflows((prevWorkflows) =>
//         prevWorkflows.map((workflow) =>
//           workflow.workflowId === workflowId
//             ? { ...workflow, ...requestBody }
//             : workflow
//         )
//       );

//       await fetchWorkflows(); // Refresh workflows
//     } catch (error) {
//       console.error('Error updating workflow:', error);
//       throw error;
//     }
//   };

//   // add a workflow
//   const addWorkflow = async (newWorkflow: { workflowName: string, workflowStatus: string }) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newWorkflow),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create workflow: ${response.statusText}`);
//       }

//       const data = await response.json();
//       // Ensure the list of workflow is up to date
//       await fetchWorkflows();

//       setLoading(false);
//       return data;

//     } catch (error) {
//       console.error('Error in addWorkflow:', error);
//       setError('Failed to add workflow. Please try again.');
//       setLoading(false);
//     }
//   };

//   const deleteWorkflow = async (workflowId: number) => {
//     setLoading(true);
//     setError(null);
  
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to delete workflow: ${response.statusText}`);
//       }
  
//       setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.workflowId !== workflowId));
//     } catch (error) {
//       setError('Failed to delete workflow.');
//       console.error('Error deleting workflow:', error);
//     } finally {
//       setLoading(false);
//     }
//   };  

//   // Fetch logic rows for a specific workflow
//   const fetchLogics = useCallback(async (workflowId: number | undefined) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for fetching logics');
//       return []; 
//     }

//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         console.log(`Failed to fetch logic rows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch logic rows: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setLoading(false);
//       return data;
//     } catch (error) {
//       console.error('Error fetching logic rows:', error);
//       setError('Failed to fetch logic rows.');
//       setLoading(false);
//       throw error;
//     }
//   }, [apiUrl]);
  
//   const addLogic = async (workflowId: number | undefined, newLogic: Partial<Schedule>) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for adding logic');
//       throw new Error('Invalid workflowId');
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newLogic), // Send the new logic data
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to add logic row: ${response.statusText}`);
//       }

//       const result = await response.json();
//       setLoading(false);
//       return result;
//     } catch (error) {
//       console.error('Error in addLogic:', error);
//       setError('Failed to add logic.');
//       setLoading(false);
//       throw error;
//     }
//   };

//    // Function to update logic
//    const updateLogic = async (workflowId: number, logicId: string, updatedLogic: Partial<Schedule>) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedLogic),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update logic');
//       }

//       const updated = await response.json();
//       return updated.logic;
//     } catch (error) {
//       console.error('Error updating logic:', error);
//       throw error;
//     }
//   };

//   const deleteLogics = async (workflowId: number, logicIds: string[]) => {
//     try {
//       // Loop through each selected logic row and send the delete request
//       for (const logicId of logicIds) {
//         await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//           method: 'DELETE',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//       }
//       return true; // Return success
//     } catch (error) {
//       console.error('Failed to delete logic rows:', error);
//       throw new Error('Failed to delete logic rows.');
//     }
//   };

//   // -- send email route ------------------------------------------------------------------------>>
//   // Function to send test email
//   const sendTestEmail = async (logicId: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/send-test-email`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ logicId }),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to send test email: ${response.statusText}`);
//       }

//       return await response.json();
//     } catch (error) {
//       console.error('Error sending test email:', error);
//       throw error;
//     }
//   };

//   // Function to schedule and send emails based on logic settings
//   const scheduleLogicEmails = async (logic: Schedule, schedules: Schedule[]) => {
//     const currentTime = new Date();
//     let scheduleTime: Date | null = null;
  
//     // Initialize logic based on its type and status
//     if (logic.logicStatus === "On") {
//       if (logic.logicType === "Started") {
//         // Handle "Started" logic
//         console.log(`Started logic found: ${logic.scheduleName}`);
        
//         if (logic.scheduleDate && logic.scheduleDate !== "1979-01-01 12:00:00") {
//           scheduleTime = new Date(logic.scheduleDate); // Schedule at the specific date
//         } else if (logic.period > 0) {
//           scheduleTime = new Date(currentTime);
//           scheduleTime.setDate(currentTime.getDate() + logic.period); // Add the period to the current date
//           scheduleTime.setHours(8, 0, 0, 0); // Set time to 8:00 AM (customizable)
//         }
  
//         if (!scheduleTime || scheduleTime <= currentTime) {
//           await sendEmail(logic); // Send the "Start" logic email immediately
          
//           console.log(`Started email sent for logic: ${logic.scheduleName}`);
//           await checkNextRun(logic, schedules); // Proceed to the next logic
//         }
//       } else if (logic.logicType === "Follow-Up") {
//         // Handle "Follow-Up" logic
//         console.log(`Follow-up logic executing: ${logic.scheduleName}`);
//         await sendEmail(logic);
//         await checkNextRun(logic, schedules); // Move to the next logic
//       } else if (logic.logicType === "Ended") {
//         // Handle "Ended" logic
//         console.log(`End logic executed for: ${logic.scheduleName}`);
//         return; // End the logic sequence
//       }
//     } else {
//       console.log(`Logic is off: ${logic.scheduleName}`);
//     }
//   };  

//   // Function to check and schedule the next run logic
//   const checkNextRun = async (logic: Schedule, schedules: Schedule[]) => {
//     if (logic.nextRun === "Stop Next Run") {
      
//       console.log(`Stopping logic sequence at: ${logic.scheduleName}`);
//       return; // Stop execution here
//     }
  
//     const nextLogic = schedules.find(s => s.scheduleName === logic.nextRun);
//     if (nextLogic && nextLogic.logicStatus === "On") {
//       console.log(`Executing next logic: ${nextLogic.scheduleName}`);
//       await scheduleLogicEmails(nextLogic, schedules); // Continue with the next logic
//     } else {
//       console.log(`No next logic found or it's Off: ${logic.scheduleName}`);
//     }
//   };  

//   // Fetch email content matching the `campTitle`
//   const fetchEmailContent = async (campTitle: string) => {
//     console.log(`FEC01: Fetching email content for campaign title: ${campTitle}`);

//     try {
//         const response = await fetch(`${apiUrl}/api/email-campaign?campTitle=${campTitle}`, {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (!response.ok) {
//             console.error('Failed to fetch email content. Status:', response.status);
//             return null;
//         }

//         const data = await response.json();
//         console.log('FEC02: Fetched email content:', data);
//         return data; // Ensure this returns an array of campaigns
//     } catch (error) {
//         console.error('Error fetching email content:', error);
//         return null;
//     }
//   };

//   // Function to send email with content matched by `campTitle`
//   const sendEmail = async (logic: Schedule) => {
//     try {
//       console.log(`Fetching email content for logic: ${logic.scheduleName}`);
//       console.log(`Fetching email content with campTitle: ${logic.emailContent}`);
  
//       // Fetch email content based on the campaign title from the logic
//       const emailCampaigns: CampaignEmail[] = await fetchEmailContent(logic.emailContent);
  
//       if (!emailCampaigns || emailCampaigns.length === 0) {
//         console.error(`No email content found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       // Find the matching campaign where campTitle matches the emailContent from logic
//       const matchingCampaign = emailCampaigns.find((campaign: CampaignEmail) => 
//         campaign.campTitle.trim().toLowerCase() === logic.emailContent.trim().toLowerCase()
//       );
  
//       if (!matchingCampaign) {
//         console.error(`No matching campaign found for campTitle: ${logic.emailContent}`);
//         return;
//       }
  
//       console.log('Matching campaign data:', matchingCampaign);
  
//       // Prepare the email data using the matched campaign details
//       const emailData = {
//         campaignId: matchingCampaign.id, // Ensure the correct campaignId is used
//         sendFrom: matchingCampaign.sendFrom,
//         sendTo: matchingCampaign.sendTo,
//         sendCC: matchingCampaign.sendCC,
//         subjectMail: matchingCampaign.subjectMail,
//         contentTitle: matchingCampaign.contentTitle,
//         contentBody: matchingCampaign.contentBody,
//         contentButton: matchingCampaign.contentButton,
//       };
  
//       console.log(`Sending email with data:`, emailData);
  
//       // Send the email using the prepared emailData
//       await sendScheduledEmail(emailData);
//     } catch (error) {
//       console.error('Error in sending email:', error);
//     }
//   };  
  
//   // Send scheduled email using the email data
//   const sendScheduledEmail = async (emailData: any) => {
//     try {
//         console.log('Preparing to send scheduled email with the following data:', emailData);

//         // Ensure campaignId is present before proceeding
//         if (!emailData.campaignId) {
//             console.error('Error: Missing campaignId in emailData:', emailData);
//             throw new Error('Missing campaignId in emailData');
//         }

//         const response = await fetch(`${apiUrl}/api/send-scheduled-emails`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ campaignId: emailData.campaignId }), // Ensure campaignId is passed correctly
//         });

//         if (!response.ok) {
//             throw new Error(`Failed to send scheduled emails: ${response.statusText}`);
//         }

//         const result = await response.json();
//         console.log('SSE01: Scheduled email sent successfully:', result);
//     } catch (error) {
//         console.error('Error sending scheduled email:', error);
//     }
//   };


//   return {
//     // email content
//     email,
//     fetchCampaigns,
//     addCampaign,
//     updateCampaign,
//     deleteCampaign,
//     // workflow 
//     workflows,
//     fetchWorkflows,
//     addWorkflow,
//     updateWorkflowStatus,
//     deleteWorkflow,
//     // workflow logic
//     fetchLogics,
//     addLogic,
//     updateLogic,
//     deleteLogics,
//     // schedule email
//     scheduleLogicEmails,
//     sendTestEmail,
//     loading,
//     error,
//   };
// };

// // udpatae xcode --> 19 sept 2024
// // src/hooks/AdminDashboard/useEmailSetting.tsx

// import { useState, useCallback, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext'; 

// interface CampaignEmail {
//   id: number;
//   campTitle: string;
//   campType: string;
//   campList: string;
//   // content setting
//   contentTitle: string;
//   contentName: string;
//   contentBody: string;
//   contentButton: string;
//   // email setting
//   sendFrom: string;
//   sendTo: string;
//   sendCC: string;
//   subjectMail: string;
//   logicStatus?: string;
// }

// interface CampaignWorkflow {
//   id: number;
//   workflowId: number;
//   workflowName: string;
//   workflowType: string;
//   workflowStatus: string;
//   clickedStats: string;
//   createdAt: Date;
// }


// interface Schedule {
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// export const useEmail = () => {
//   const [email, setEmail] = useState<CampaignEmail[]>([]);
//   const [workflows, setWorkflows] = useState<CampaignWorkflow[]>([]); 
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   // Campaigns Email section --------------------------------------->>
//   // fetching campaigns email setting
//   const fetchCampaigns = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setEmail(data);
//     } catch (error) {
//       console.error('Error fetching campaigns:', error);
//       setError('Failed to fetch campaigns.');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]); 

//   const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newCampaignEmail),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create a campaign: ${response.statusText}`);
//       }

//       const data = await response.json();

//       setEmail([...email, { ...newCampaignEmail, id: data.id }]);
//       setLoading(false);
//     } catch (error) {

//       console.error('Error in addCampaign:', error);
//       setError('Failed to add campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   const updateCampaign = async (updatedCampaign: CampaignEmail) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedCampaign),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to update campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) =>
//         prevEmails.map((email) =>
//           email.id === updatedCampaign.id ? updatedCampaign : email
//         )
//       );
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in updateCampaign:', error); 
//       setError('Failed to update campaign.');
//     }
//   };

//   useEffect(() => {
//     fetchCampaigns(); 
//   }, [fetchCampaigns]);

//   // created workflow section --------------------------------------->>
//   // Fetch workflows
//   const fetchWorkflows = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         console.error(`Failed to fetch workflows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch workflows: ${response.statusText}`);
//       }
  
//       const data = await response.json();
  
//       setWorkflows(data);
//       setLoading(false);

//     } catch (error) {
//       console.error('Error fetching workflows:', error);
//       setError('Failed to fetch workflows.');
//       setLoading(false);
//     }
//   }, [apiUrl]);
  

//    // Function to update the workflow status and name
//   const updateWorkflowStatus = async (workflowId: number, newStatus: string, workflowName?: string) => {
//     try {
//       const requestBody: any = {};
//       if (newStatus !== 'KeepCurrentStatus') {
//         requestBody.workflowStatus = newStatus;
//       }
//       if (workflowName !== undefined) {
//         requestBody.workflowName = workflowName;
//       }

//       if (Object.keys(requestBody).length === 0) {
//         // Nothing to update
//         return;
//       }

//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/status`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update workflow');
//       }

//       // Update local workflows state
//       setWorkflows((prevWorkflows) =>
//         prevWorkflows.map((workflow) =>
//           workflow.workflowId === workflowId
//             ? { ...workflow, ...requestBody }
//             : workflow
//         )
//       );

//       await fetchWorkflows(); // Refresh workflows
//     } catch (error) {
//       console.error('Error updating workflow:', error);
//       throw error;
//     }
//   };

//   // add a workflow
//   const addWorkflow = async (newWorkflow: { workflowName: string, workflowStatus: string }) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newWorkflow),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create workflow: ${response.statusText}`);
//       }

//       const data = await response.json();
//       // Ensure the list of workflow is up to date
//       await fetchWorkflows();

//       setLoading(false);
//       return data;

//     } catch (error) {
//       console.error('Error in addWorkflow:', error);
//       setError('Failed to add workflow. Please try again.');
//       setLoading(false);
//     }
//   };

//   const deleteWorkflow = async (workflowId: number) => {
//     setLoading(true);
//     setError(null);
  
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to delete workflow: ${response.statusText}`);
//       }
  
//       setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.workflowId !== workflowId));
//     } catch (error) {
//       setError('Failed to delete workflow.');
//       console.error('Error deleting workflow:', error);
//     } finally {
//       setLoading(false);
//     }
//   };  

//   // Fetch logic rows for a specific workflow
//   const fetchLogics = useCallback(async (workflowId: number | undefined) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for fetching logics');
//       return []; 
//     }

//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         console.log(`Failed to fetch logic rows. Status: ${response.status}`);
//         throw new Error(`Failed to fetch logic rows: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setLoading(false);
//       return data;
//     } catch (error) {
//       console.error('Error fetching logic rows:', error);
//       setError('Failed to fetch logic rows.');
//       setLoading(false);
//       throw error;
//     }
//   }, [apiUrl]);
  
//   const addLogic = async (workflowId: number | undefined, newLogic: Partial<Schedule>) => {
//     if (!workflowId) {
//       console.error('Invalid workflowId provided for adding logic');
//       throw new Error('Invalid workflowId');
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newLogic), // Send the new logic data
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to add logic row: ${response.statusText}`);
//       }

//       const result = await response.json();
//       setLoading(false);
//       return result;
//     } catch (error) {
//       console.error('Error in addLogic:', error);
//       setError('Failed to add logic.');
//       setLoading(false);
//       throw error;
//     }
//   };

//    // Function to update logic
//    const updateLogic = async (workflowId: number, logicId: string, updatedLogic: Partial<Schedule>) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedLogic),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update logic');
//       }

//       const updated = await response.json();
//       return updated.logic;
//     } catch (error) {
//       console.error('Error updating logic:', error);
//       throw error;
//     }
//   };

//   const deleteLogics = async (workflowId: number, logicIds: string[]) => {
//     try {
//       // Loop through each selected logic row and send the delete request
//       for (const logicId of logicIds) {
//         await fetch(`${apiUrl}/api/workflows/${workflowId}/logics/${logicId}`, {
//           method: 'DELETE',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//       }
//       return true; // Return success
//     } catch (error) {
//       console.error('Failed to delete logic rows:', error);
//       throw new Error('Failed to delete logic rows.');
//     }
//   };

//   // -- send email route ------------------------------------------------------------------------>>
//   // Function to send test email
//   const sendTestEmail = async (logicId: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/send-test-email`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ logicId }),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to send test email: ${response.statusText}`);
//       }

//       return await response.json();
//     } catch (error) {
//       console.error('Error sending test email:', error);
//       throw error;
//     }
//   };

//   // Function to schedule and send emails based on logic settings
//   const scheduleLogicEmails = async (logic: Schedule, schedules: Schedule[]) => {
//     const currentTime = new Date();
//     let scheduleTime: Date | null = null;
  
//     console.log(`Scheduling email for logic: ${logic.scheduleName}`);
    
//     if (logic.scheduleDate && logic.scheduleDate !== '1979-01-01 12:00:00' && logic.scheduleDate !== 'No Apply') {
//       scheduleTime = new Date(logic.scheduleDate);
//       console.log(`Email scheduled for: ${scheduleTime}`);
//     } else if (logic.period > 0) {
//       scheduleTime = new Date(currentTime);
//       scheduleTime.setDate(currentTime.getDate() + logic.period);
//       scheduleTime.setHours(8, 0, 0, 0); // Set time to 8 AM
//       console.log(`Email scheduled to be sent after ${logic.period} days at 8:00 AM`);
//     }
  
//     if (!scheduleTime || scheduleTime <= currentTime) {
//       console.log('Sending email immediately');
//       await sendEmail(logic);
//     } else {
//       const delay = scheduleTime.getTime() - currentTime.getTime();
//       console.log(`Email will be sent in ${delay}ms`);
//       setTimeout(async () => {
//         console.log('Sending scheduled email');
//         await sendEmail(logic);
//         await checkNextRun(logic, schedules);
//       }, delay);
//     }
//   };   

//   // Function to check and schedule the next run logic
//   const checkNextRun = async (logic: Schedule, schedules: Schedule[]) => {
//     const nextLogic = schedules.find(s => s.scheduleName === logic.nextRun);
//     if (nextLogic && nextLogic.logicStatus === 'On') {
//       console.log(`Scheduling next logic: ${nextLogic.scheduleName}`);
//       await scheduleLogicEmails(nextLogic, schedules);
//     } else {
//       console.log('No next logic to run or it is Off');
//     }
//   };

//   // Fetch email content matching the `campTitle`
//   const fetchEmailContent = async (campTitle: string) => {
//     console.log(`Fetching email content for campaign title: ${campTitle}`);
    
//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign?campTitle=${campTitle}`, {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' },
//       });
  
//       if (!response.ok) {
//         console.error('Failed to fetch email content, status:', response.status);
//         return null;
//       }
  
//       const data = await response.json();
//       console.log('Fetched email content:', data);
//       return data; // Ensure this returns an array of campaigns
//     } catch (error) {
//       console.error('Error fetching email content:', error);
//       return null;
//     }
//   };
   

//   // Function to send email with content matched by `campTitle`
//   const sendEmail = async (logic: Schedule) => {
//     try {
//       console.log(`Fetching email content for: ${logic.emailContent}`);
    
//       // Fetch the email content based on the campaign title
//       const emailCampaigns = await fetchEmailContent(logic.emailContent);
      
//       // Log what is fetched
//       console.log('Fetched email campaigns:', emailCampaigns);
    
//       // If no email campaign is found, log a detailed error message
//       if (!emailCampaigns || emailCampaigns.length === 0) {
//         console.error(`No email campaign found for: ${logic.emailContent}`);
//         return;
//       }
    
//       // Since `fetchEmailContent` returns an array, we need to extract the first campaign
//       const emailCampaign = emailCampaigns[0];
    
//       // Check for all necessary fields before proceeding to send the email
//       const { sendFrom, sendTo, sendCC, subjectMail, contentTitle, contentName, contentBody, contentButton } = emailCampaign;
    
//       console.log(`Preparing to send email with the following details:
//         sendFrom: ${sendFrom},
//         sendTo: ${sendTo},
//         subjectMail: ${subjectMail},
//         contentTitle: ${contentTitle}`);
    
//       // If any of these are missing, log an error
//       if (!sendFrom || !sendTo || !subjectMail) {
//         console.error('Missing essential email fields (sendFrom, sendTo, subjectMail) in email campaign:', emailCampaign);
//         return;
//       }
    
//       // Prepare email data and log it
//       const emailData = {
//         sendFrom,
//         sendTo,
//         sendCC,
//         subjectMail,
//         contentTitle,
//         contentName,
//         contentBody,
//         contentButton,
//         campaignId: emailCampaign.id, // Ensure that the campaignId is passed
//       };
    
//       console.log('Email data to be sent:', emailData);
    
//       // Proceed with sending the scheduled email
//       await sendScheduledEmail(emailData);
    
//     } catch (error) {
//       console.error('Error sending email:', error);
//     }
//   };
 

//   // Send scheduled email using the email data
//   const sendScheduledEmail = async (emailData: any) => {
//     try {
//       // Assuming the emailData contains the campaignId, ensure it's correctly passed
//       const response = await fetch(`${apiUrl}/api/send-scheduled-emails`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ campaignId: emailData.campaignId }), // Ensure that campaignId is sent
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to send scheduled emails: ${response.statusText}`);
//       }
  
//       const result = await response.json();
//       console.log('Scheduled email sent successfully', result);
//     } catch (error) {
//       console.error('Error sending scheduled email:', error);
//     }
//   };

//   return {
//     // email content
//     email,
//     fetchCampaigns,
//     addCampaign,
//     updateCampaign,
//     // workflow 
//     workflows,
//     fetchWorkflows,
//     addWorkflow,
//     updateWorkflowStatus,
//     deleteWorkflow,
//     // workflow logic
//     fetchLogics,
//     addLogic,
//     updateLogic,
//     deleteLogics,
//     // schedule email
//     scheduleLogicEmails,
//     sendTestEmail,
//     loading,
//     error,
//   };
// };


