// src/hooks/useSignUp.tsx

import { useEnv } from '../../context/EnvContext';

export const useSignUp = () => {
  const { apiUrl } = useEnv();

  const signUp = async (data: {
    termsAndConditions: string;
    memberType: string;
    email: string;
    gender: string;
    name: string;
    icNo: string;
    phone: string;
    dob: string;
    country: string;
    state:string;
    address: string;
    password: string;
    role?: string; 
  }) => {
    const response = await fetch(`${apiUrl}/api/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Failed to sign up:', errorData);
      throw new Error(errorData.error);
    }

    return response.json();
  };

  const checkExistingUser = async (email: string) => {
    const response = await fetch(`${apiUrl}/api/check-existing-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  
    if (response.status === 404) {
      return false;
    }
  
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Failed to check existing user:', errorData);
      throw new Error(errorData.error);
    }
  
    const data = await response.json();
    return data.exists;
  };

return { signUp, checkExistingUser };

};


// // udpate code --> 19 july 2024
// // src/hooks/useSignUp.tsx

// import { useEnv } from '../../context/EnvContext';

// export const useSignUp = () => {
//   const { apiUrl } = useEnv();

//   const signUp = async (data: {
//     termsAndConditions: string;
//     memberType: string;
//     email: string;
//     gender: string; // Added gender
//     name: string;
//     phone: string;
//     dob: string;
//     country: string;
//     address: string;
//     password: string;
//   }) => {
//     console.log('Sending data to backend');

//     const response = await fetch(`${apiUrl}/api/register`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     });

//     if (!response.ok) {
//       const errorData = await response.json();
//       console.error('Failed to sign up:', errorData);
//       throw new Error(errorData.error);
//     }

//     return response.json();
//   };

//   const checkExistingUser = async (email: string) => {
//     const response = await fetch(`${apiUrl}/api/check-existing-user`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ email }),
//     });
  
//     if (response.status === 404) {
//       return false;
//     }
  
//     if (!response.ok) {
//       const errorData = await response.json();
//       console.error('Failed to check existing user:', errorData);
//       throw new Error(errorData.error);
//     }
  
//     const data = await response.json();
//     return data.exists;
//   };

// return { signUp, checkExistingUser };

// };

