// src/components/admin/ClientData/UserTableChart.tsx

import React from 'react';
import { FaChartLine, FaDollarSign, FaCoins, FaWallet, FaPiggyBank, FaDotCircle } from 'react-icons/fa';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Radar, RadarChart, PolarGrid, PolarAngleAxis } from 'recharts';

interface UserTableChartProps {
  tradingAccount: any;
  profitHistory: Array<{ time: string; growth: number }>;
  formatNumber: (number: number | undefined | null) => string;
  formatVolumeToLotSize: (volume: number | undefined | null) => string;
}

const UserTableChart: React.FC<UserTableChartProps> = ({ tradingAccount, profitHistory, formatNumber }) => {
  const radarData = tradingAccount?.radarChartData ?? {
    tradeActivity: 0,
    profitTradePercent: 0,
    lossTradePercent: 0,
    drawdown: 0,
    riskPerTrade: 0,
  };

  const formatVolumeToLotSize = (volume: number | undefined | null): string => {
    return volume ? (volume / 100).toFixed(2) : '0.00'; 
  };  

  return (
    <div className="flex flex-row justify-between items-center py-2">
      {/* Initial capital info */}
      <div className="flex flex-col space-y-2 my-2 rounded text-xs bg-white p-4">
        {tradingAccount && (
          <>
            <div className="flex justify-between items-center py-2 border-b">
              <span className="text-cyan-800 font-semibold flex items-center">
                <FaChartLine className="mr-2 text-cyan-800" /> Growth:
              </span>
              <span className="text-gray-800 text-right ml-4">
                {tradingAccount.growth > 0 ? '+' : ''}{formatNumber(tradingAccount.growth)}%
              </span>
            </div>
            <div className="flex justify-between items-center py-2">
              <span className="text-cyan-800 font-semibold flex items-center">
                <FaDotCircle className="mr-2 text-cyan-800" /> Lot Size:
              </span>
              <span className="text-gray-800 text-right ml-4">{formatVolumeToLotSize(tradingAccount.totalLotSize)} lot</span>
            </div>
            <div className="flex justify-between items-center py-2 border-b">
              <span className="text-cyan-800 font-semibold flex items-center">
                <FaDollarSign className="mr-2 text-cyan-800" /> Profit:
              </span>
              <span className="text-gray-800 text-right ml-4">
                {tradingAccount.profit > 0 ? '+' : ''}{formatNumber(tradingAccount.profit)} USD
              </span>
            </div>
            <div className="flex justify-between items-center py-2 border-b">
              <span className="text-cyan-800 font-semibold flex items-center">
                <FaCoins className="mr-2 text-cyan-800" /> Equity:
              </span>
              <span className="text-gray-800 text-right ml-4">{formatNumber(tradingAccount.equity)} USD</span>
            </div>
            <div className="flex justify-between items-center py-2 border-b">
              <span className="text-cyan-800 font-semibold flex items-center">
                <FaWallet className="mr-2 text-cyan-800" /> Balance:
              </span>
              <span className="text-gray-800 text-right ml-4">{formatNumber(tradingAccount.balance)} USD</span>
            </div>
            <div className="flex justify-between items-center py-2">
              <span className="text-cyan-800 font-semibold flex items-center">
                <FaPiggyBank className="mr-2 text-cyan-800" /> Initial Capital:
              </span>
              <span className="text-gray-800 text-right ml-4">{formatNumber(tradingAccount.initial)} USD</span>
            </div>
          </>
        )}
      </div>

      {/* Growth Performance Area Chart */}
      <div className="flex flex-col justify-center space-y-2 my-2 rounded text-xs bg-white p-4 w-auto min-w-[300px]">
        {/* Display Total Growth Percentage Above the Chart */}
        <h3 className="text-center text-cyan-800 font-semibold mb-2">
          Growth Performance: 
          <span className="bg-cyan-800 text-white rounded-full px-2 py-1">
            {tradingAccount ? formatNumber(tradingAccount.growth) : 'N/A'} % 
          </span>
        </h3>

        {profitHistory && profitHistory.length > 0 ? (
          <ResponsiveContainer width="100%" height={180}>
            <AreaChart data={profitHistory}>
              <defs>
                <linearGradient id="growthGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#0E7490" stopOpacity={0.8} /> 
                  <stop offset="100%" stopColor="#0E7490" stopOpacity={0.1} /> 
                </linearGradient> 
              </defs>
              
              <XAxis dataKey="time" hide />
              <YAxis hide />

              {/* Customize the tooltip */}
              <Tooltip 
                formatter={(value) => typeof value === 'number' ? `${value.toFixed(2)}%` : `${parseFloat(value as string).toFixed(2)}%`} 
                labelFormatter={(time) => new Date(time as string).toLocaleDateString()}
                cursor={{ display: 'none' }} 
              />
              
              <Area type="monotone" dataKey="growth" stroke="#00BCD4" fill="#B2EBF2" />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <p className="text-center text-gray-500">No growth data available.</p>
        )}
      </div>

      {/* Radar Chart for Trade Account Rating Info*/}
      <div className="flex flex-col justify-center my-2 rounded text-xs bg-white p-4 w-auto min-w-[400px] ">
        <ResponsiveContainer width="100%" height={220}>
          <RadarChart outerRadius={90} data={[
            { 
              subject: `Trade Activity (${formatNumber(radarData.tradingActivity)}%)`, 
              A: radarData.tradeActivity, fullMark: 10 
            },
            { 
              subject: `Profit Trade (${formatNumber(radarData.profitTradePercent)}%)`, 
              A: radarData.profitTradePercent / 10, fullMark: 10 
            },
            { 
              subject: `Loss Trade (${formatNumber(radarData.lossTradePercent)}%)`, 
              A: radarData.lossTradePercent / 10, fullMark: 10 
            },
            { 
              subject: `MaxDrawdown (${formatNumber(radarData.drawdown)}%)`, 
              A: radarData.drawdown / 10, fullMark: 10 
            },
            { 
              subject: `Risk per Trade (${formatNumber(radarData.riskPerTrade)}%)`, 
              A: radarData.riskPerTrade / 10, fullMark: 10 
            }
          ]}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" tick={{ fontSize: 10, textAnchor: 'middle', fontWeight: 'bold' }} />
            <Radar name="Rating" dataKey="A" stroke="#00BCD4" fill="#00BCD4" fillOpacity={0.2} />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default UserTableChart;
