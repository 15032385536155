// src/hooks/AdminDashboard/useRoleAccess.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext';

interface RolePermissions {
  fullAccess: string,
  overview: string,
  userData: {
    enable: string;
    addData: string;
    editData: string;
    deleteData: string;
  };
  emailSchedule: {
    enable: string;
    addLogic: string;
    editLogic: string;
    deleteLogic: string;
    addEmail: string;
    editEmail: string;
    deleteEmail: string;
    changeStatus: string;
  },
  eventSetting: string,
  eventNotification: string,
  productSubscription: string,
  fundedProgram: string,
  videoManage: string,
  adminSetting: string,
}

// Helper function to set all permissions to "enable"
const enableAllPermissions = (): RolePermissions => ({
  fullAccess: 'enable',
  overview: 'enable',
  userData: { enable: 'enable', addData: 'enable', editData: 'enable', deleteData: 'enable' },
  emailSchedule: {
    enable: 'enable', addLogic: 'enable', editLogic: 'enable', deleteLogic: 'enable',
    addEmail: 'enable', editEmail: 'enable', deleteEmail: 'enable', changeStatus: 'enable',
  },
  eventSetting: 'enable',
  eventNotification: 'enable',
  productSubscription: 'enable',
  fundedProgram: 'enable',
  videoManage: 'enable',
  adminSetting: 'enable',
});

export const useRoleAccess = (email: string) => {
  const { apiUrl } = useEnv();
  const [permissions, setPermissions] = useState<RolePermissions | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!email) {
      setPermissions(null);
      setLoading(false);
      return;
    }

    const fetchPermissions = async () => {
      try {
        const response = await fetch(`${apiUrl}/admin-users/${email}/permissions`);
        if (!response.ok) {
          throw new Error('Failed to fetch permissions');
        }
        const data = await response.json();

        // Check if fullAccess is enabled; if so, enable all permissions
        if (data.rolePermission?.fullAccess === 'enable') {
          setPermissions(enableAllPermissions());
        } else {
          setPermissions(data.rolePermission);
        }
      } catch (err: any) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [apiUrl, email]);

  return { permissions, loading, error };
};

// // udpate code --> 28 oct 2024
// // src/hooks/AdminDashboard/useRoleAccess.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface RolePermissions {
//   fullAccess: string,
//   overview: string,
//   userData: {
//     enable: string;
//     addData: string;
//     editData: string;
//     deleteData: string;
//   };
//   emailSchedule: {
//     enable: string;
//     addLogic: string;
//     editLogic: string;
//     deleteLogic: string;
//     addEmail: string;
//     editEmail: string;
//     deleteEmail: string;
//     changeStatus: string;
//   },
//   eventSetting: string,
//   eventNotification: string,
//   productSubscription: string,
//   fundedProgram: string,
//   videoManage: string,
//   adminSetting: string,
// }

// export const useRoleAccess = (email: string) => {
//   const { apiUrl } = useEnv();
//   const [permissions, setPermissions] = useState<RolePermissions | null>(null);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     if (!email) {
//       setPermissions(null);
//       setLoading(false);
//       return;
//     }

//     const fetchPermissions = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/admin-users/${email}/permissions`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch permissions');
//         }
//         const data = await response.json();
//         setPermissions(data.rolePermission);
//       } catch (err: any) {
//         setError(err.message || 'An error occurred');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPermissions();
//   }, [apiUrl, email]);

//   return { permissions, loading, error };
// };


// // udpate code --> 21 oct 2024
// // src/hooks/AdminDashboard/useRoleAccess.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface RolePermissions {
//   fullAccess: string,
//   overview: string,
//   userData: string,
//   emailSchedule: string,
//   eventSetting: string,
//   eventNotification: string,
//   productSubscription: string,
//   fundedProgram: string,
//   videoManage: string,
//   adminSetting: string,
// }

// export const useRoleAccess = (email: string) => {
//   const { apiUrl } = useEnv();
//   const [permissions, setPermissions] = useState<RolePermissions | null>(null);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     if (!email) {
//       setPermissions(null);
//       setLoading(false);
//       return;
//     }

//     const fetchPermissions = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/admin-users/${email}/permissions`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch permissions');
//         }
//         const data = await response.json();
//         setPermissions(data.rolePermission);
//       } catch (err: any) {
//         setError(err.message || 'An error occurred');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPermissions();
//   }, [apiUrl, email]);

//   return { permissions, loading, error };
// };
