// src/components/admin/VideoSetting/VideoGroupListModal.tsx

import React, { useState, useEffect } from 'react';
import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload'; 

interface GroupListModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoId: string;  
}

const GroupListModal: React.FC<GroupListModalProps> = ({ isOpen, onClose, videoId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [groupNames, setGroupNames] = useState<string[]>([]); // Group names from videoGroup
  const [groupEmails, setGroupEmails] = useState<string[]>([]); // Emails from videoGroupEmail
  const [ShowNoEmailGroup, setShowNoEmailGroup ] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { videos, fetchVideos } = useVideoUpload();  // Fetch video data and make sure to refetch

  useEffect(() => {
    if (isOpen && videoId) {
      // Refetch the video data for live updates
      fetchVideos();
  
      const video = videos.find(v => v.videoId === videoId);
  
      if (video) {
        let parsedGroup: string[] = [];
        let parsedGroupEmails: string[] = [];
  
        // Parse videoGroup (group names)
        try {
          parsedGroup = JSON.parse(video.videoGroup || '[]');
        } catch (error) {
          console.error('Error parsing videoGroup:', error);
        }
  
        // Parse videoGroupEmail (group emails)
        try {
          let emailObject: any = {};
          if (typeof video.videoGroupEmail === 'string') {
            emailObject = JSON.parse(video.videoGroupEmail || '{}');
          } else {
            emailObject = video.videoGroupEmail || {};
          }
  
          // Collect emails from all groups, not just the first one
          parsedGroupEmails = (Object.values(emailObject) as string[][]).flat();
        } catch (error) {
          console.error('Error parsing videoGroupEmail:', error);
        }
  
        // Update state with group names and emails
        setGroupNames(parsedGroup.length > 0 ? parsedGroup : ['Private Group']);
        setGroupEmails(parsedGroupEmails);
  
        // Set ShowNoEmailGroup based on email availability
        if (parsedGroupEmails.length === 0) {
          setShowNoEmailGroup(true); // Show "No emails available"
        } else {
          setShowNoEmailGroup(false); // Hide "No emails available"
        }
      }
  
      setIsLoading(false);  
    }
  }, [isOpen, videoId, videos, fetchVideos]);
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg h-auto max-h-screen w-auto">
        {isLoading ? (
          <p>Loading group data...</p>
        ) : (
          <>
            <h2 className="text-xl text-center font-bold mb-4 text-cyan-800 border-2 border-cyan-800 px-4 py-1 rounded-full">
                Group List
            </h2>
            
            {/* Group Names - Display in bullet point */}
            <ul className="list-disc pl-5 mb-4">
              {groupNames.map((name, index) => (
                <li key={index} className="text-cyan-800 font-semibold">{name}</li>
              ))}
            </ul>

            {/* Show the button to expand/collapse if there are emails */}
            {groupEmails.length > 0 ? (
                <>
                    <button
                        onClick={() => setIsExpanded(!isExpanded)} // Toggle expand/collapse
                        className="bg-cyan-800 text-white px-4 py-2 rounded-lg mb-4"
                    >
                        {isExpanded ? 'Hide Emails' : 'Show Emails'}
                    </button>

                    {isExpanded && (
                        <ul className="list-disc pl-5 border-2 rounded h-auto max-h-[500px] overflow-y-auto">
                            {groupEmails.map((email, index) => (
                            <li key={index} className="text-sm text-gray-700">{email}</li>
                            ))}
                        </ul>
                    )}
                </>
            ) : (
                <div 
                    className={`text-sm text-gray-500 ${ShowNoEmailGroup ? "" : ""}`}
                >
                    {ShowNoEmailGroup && 'No emails available for this group.'}
                </div>
            )}


            <button
              onClick={onClose}
              className="mt-4 bg-cyan-800 text-white px-4 py-2 rounded-lg"
            >
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default GroupListModal;


