// components/admin/alertMessage.tsx

import React from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineExclamationCircle, AiFillWarning } from 'react-icons/ai';

interface ConfirmAlertMessageProps {
  type: 'confirm' | 'success' | 'warning' | 'error';
  message: string;
  title?: string;
  visible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

const ConfirmAlertMessage: React.FC<ConfirmAlertMessageProps> = ({ 
  type, 
  message, 
  title, 
  visible, 
  onClose, 
  onConfirm 
}) => {
  if (!visible) return null;

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <AiOutlineCheckCircle className="text-cyan-800 text-2xl mr-2" />;
      case 'warning':
        return <AiFillWarning className="text-rose-600 text-2xl mr-2" />;
      case 'error':
        return <AiOutlineCloseCircle className="text-yellow-500 text-2xl mr-2" />;
      case 'confirm':
        return <AiOutlineExclamationCircle className="text-rose-600 text-2xl mr-2" />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-70 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="flex items-center mb-4">
          {getIcon()}
          <h3 className="text-lg font-semibold">
            {title || (type === 'confirm' ? 'Confirm Action' : type.charAt(0).toUpperCase() + type.slice(1))}
          </h3>
        </div>
        <p className="mb-6">{message}</p>
        <div className="flex justify-end space-x-2">
          {(type === 'confirm' || type === 'warning') && (
            <>
              <button
                className="bg-gray-400 text-gray-100 px-4 py-2 rounded-md hover:bg-gray-500"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="bg-cyan-800 text-white px-4 py-2 rounded-md hover:bg-cyan-900 "
                onClick={onConfirm}
              >
                Confirm
              </button>
            </>
          )}
          {(type === 'success' || type === 'error') && (
            <button
              className="bg-cyan-800 hover:bg-cyan-700 text-white px-4 py-2 rounded-md"
              onClick={onClose}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmAlertMessage;
