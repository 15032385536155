// src/pages/client/p03_UserDashboard.tsx

// import react components
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// import components file
import TopMenu from '../../components/TopMenu';
import AppFooter from '../../components/Footer';
import UserInfo from '../../components/client/Dashboard/UserInfo';
import UserPointsStats from '../../components/client/Dashboard/UserPointStats';
import UserTask from '../../components/client/Dashboard/UserTask';
import UserCalendar from '../../components/client/Dashboard/UserCalendar';
import UserEvent from '../../components/client/Dashboard/UserEvent';
import NotifyBanner from '../../components/client/Banner';
import UserBoard from '../../components/client/Dashboard/UserBoard';
import UserApp from '../../components/client/Dashboard/UserApp';
import AccountExpireAlert from '../../components/AccountExpireAlert';

// import hooks file
import useUserStage from '../../hooks/Dashboard/useUserStage';
import useUserDashboard from '../../hooks/Dashboard/useUserDashboard';

// import state modal
import StateModal from '../../components/UpdateState';
import PasswordResetModal from '../../components/PasswordResetUpdate';

const OverlayWrapper: React.FC<{ 
  children: React.ReactNode, 
  isGuest: boolean, 
  message: string, 
  subMessage: string 
}> = ({ children, isGuest, message, subMessage }) => (
  <div className="relative w-full h-full">
    {children}
    {isGuest && (
      <div className="absolute inset-0 bg-gray-300 bg-opacity-95 border-2 border-rose-300 flex flex-col items-center justify-center rounded-lg z-10">
        <span className="text-red-500 font-medium text-center px-4 md:text-sm sm:text-sm xs:text-sm">{message}</span>
        <span className="text-red-500 font-bold text-center px-4 mt-4 md:text-sm sm:text-sm xs:text-sm">{subMessage}</span>
      </div>
    )}
  </div>
);

const DashboardView: React.FC<{ email: string }> = ({ email }) => {
  const { isGuest } = useUserStage();
  const { 
    user, 
    showExpireAlert, handleExpireAlertConfirm, 
    updateUserState, showStateModal, setShowStateModal,
    needsPasswordReset, 
    setNeedsPasswordReset 
  } = useUserDashboard();
  const [loading, setLoading] = useState(true);

  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      if (user && (!user.state || !user.icNo) && !modalShown) {
        setShowStateModal(true);
        setModalShown(true);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [user, modalShown, setShowStateModal]);

  return (
    <div className="flex flex-col items-center h-screen w-full bg-gray-100">
      {showExpireAlert && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <AccountExpireAlert visible={showExpireAlert} onClose={handleExpireAlertConfirm} />
        </div>
      )}

      {/* Show Password Reset Modal if needed */}
      {needsPasswordReset && (
        <PasswordResetModal onClose={() => setNeedsPasswordReset(false)} />
      )}

      {/* Display the modal to select the state */}
      {showStateModal && (
        <StateModal 
          onSave={updateUserState} /> 
      )}

      <TopMenu />
      <NotifyBanner />
      <div className="flex flex-col flex-grow w-full max-w-[1100px] h-auto max-h-screen p-4 space-y-4 overflow-y-auto md:space-y-2 sm:space-y-0 xs:space-y-0">
        <div className="flex flex-row w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
          <div className="w-1/2 p-2 rounded-xl md:w-[400px] sm:w-full xs:w-full">
            {loading ? <Skeleton height={200} /> : <UserInfo />}
          </div>
          <div className="flex flex-col w-full sm:w-auto xs:w-auto">
            <div className="w-full p-2 rounded-xl">
              {loading ? <Skeleton height={200} /> : <UserBoard />}
            </div>
            <div className="w-full p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
              <OverlayWrapper
                isGuest={isGuest}
                message="Explore your rewards and program statuses in My Stats"
                subMessage="Enroll classes to access this feature"
              >
                {loading ? <Skeleton height={200} /> : <UserPointsStats />}
              </OverlayWrapper>
            </div>
          </div>
        </div>
        <div className="flex w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
          <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
            {loading ? <Skeleton height={200} /> : <UserCalendar email={email} />}
          </div>
          <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
            {loading ? <Skeleton height={200} /> : <UserEvent email={email} />}
          </div>
        </div>
        <div className="w-full p-2 bg-gray-100 rounded-xl">
          {loading ? <Skeleton height={200} /> : <UserApp />}
        </div>
        <div className="flex space-x-4">
          <div className="p-2 w-full bg-gray-100 rounded-xl">
            <OverlayWrapper
              isGuest={isGuest}
              message="Track your tasks, earn points, and unlock rewards with My Progress Start collecting points now!"
              subMessage="Enroll classes to access this feature"
            >
              {loading ? <Skeleton height={200} /> : <UserTask />}
            </OverlayWrapper>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 w-full">
        <AppFooter />
      </div>
    </div>
  );
};

export default DashboardView;


// // udpatae code --> 18 oct 2024
// // src/pages/client/p03_UserDashboard.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

// // import components file
// import TopMenu from '../../components/TopMenu';
// import AppFooter from '../../components/Footer';
// import UserInfo from '../../components/client/Dashboard/UserInfo';
// import UserPointsStats from '../../components/client/Dashboard/UserPointStats';
// import UserTask from '../../components/client/Dashboard/UserTask';
// import UserCalendar from '../../components/client/Dashboard/UserCalendar';
// import UserEvent from '../../components/client/Dashboard/UserEvent';
// import NotifyBanner from '../../components/client/Banner';
// import UserBoard from '../../components/client/Dashboard/UserBoard';
// import UserApp from '../../components/client/Dashboard/UserApp';
// import AccountExpireAlert from '../../components/AccountExpireAlert';

// // import hooks file
// import useUserStage from '../../hooks/Dashboard/useUserStage';
// import useUserDashboard from '../../hooks/Dashboard/useUserDashboard';

// // import state modal
// import StateModal from '../../components/UpdateState';

// const OverlayWrapper: React.FC<{ 
//   children: React.ReactNode, 
//   isGuest: boolean, 
//   message: string, 
//   subMessage: string 
// }> = ({ children, isGuest, message, subMessage }) => (
//   <div className="relative w-full h-full">
//     {children}
//     {isGuest && (
//       <div className="absolute inset-0 bg-gray-300 bg-opacity-95 border-2 border-rose-300 flex flex-col items-center justify-center rounded-lg z-10">
//         <span className="text-red-500 font-medium text-center px-4 md:text-sm sm:text-sm xs:text-sm">{message}</span>
//         <span className="text-red-500 font-bold text-center px-4 mt-4 md:text-sm sm:text-sm xs:text-sm">{subMessage}</span>
//       </div>
//     )}
//   </div>
// );

// const DashboardView: React.FC<{ email: string }> = ({ email }) => {
//   const { isGuest } = useUserStage();
//   const { showExpireAlert, handleExpireAlertConfirm, updateUserState, showStateModal } = useUserDashboard();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Simulate loading delay
//     const timer = setTimeout(() => setLoading(false), 2000);
//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <div className="flex flex-col items-center h-screen w-full bg-gray-100">
//       {showExpireAlert && (
//         <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//           <AccountExpireAlert visible={showExpireAlert} onClose={handleExpireAlertConfirm} />
//         </div>
//       )}

//       {/* Display the modal to select the state */}
//       {showStateModal && (
//         <StateModal onSave={updateUserState} /> 
//       )}

//       <TopMenu />
//       <NotifyBanner />
//       <div className="flex flex-col flex-grow w-full max-w-[1100px] h-auto max-h-screen p-4 space-y-4 overflow-y-auto md:space-y-2 sm:space-y-0 xs:space-y-0">
//         <div className="flex flex-row w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 rounded-xl md:w-[400px] sm:w-full xs:w-full">
//             {loading ? <Skeleton height={200} /> : <UserInfo />}
//           </div>
//           <div className="flex flex-col w-full sm:w-auto xs:w-auto">
//             <div className="w-full p-2 rounded-xl">
//               {loading ? <Skeleton height={200} /> : <UserBoard />}
//             </div>
//             <div className="w-full p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//               <OverlayWrapper
//                 isGuest={isGuest}
//                 message="Explore your rewards and program statuses in My Stats"
//                 subMessage="Enroll classes to access this feature"
//               >
//                 {loading ? <Skeleton height={200} /> : <UserPointsStats />}
//               </OverlayWrapper>
//             </div>
//           </div>
//         </div>
//         <div className="flex w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Discover and plan your events easily with My Calendar to Stay informed and ahead"
//               subMessage="Enroll classes to access this feature"
//             >
//               {loading ? <Skeleton height={200} /> : <UserCalendar email={email} />}
//             </OverlayWrapper>
//           </div>
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Stay updated on today's events with My Event. See your bookings and briefs on upcoming events effortlessly"
//               subMessage="Enroll classes to access this feature"
//             >
//               {loading ? <Skeleton height={200} /> : <UserEvent email={email} />}
//             </OverlayWrapper>
//           </div>
//         </div>
//         <div className="w-full p-2 bg-gray-100 rounded-xl">
//           {loading ? <Skeleton height={200} /> : <UserApp />}
//         </div>
//         <div className="flex space-x-4">
//           <div className="p-2 w-full bg-gray-100 rounded-xl">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Track your tasks, earn points, and unlock rewards with My Progress Start collecting points now!"
//               subMessage="Enroll classes to access this feature"
//             >
//               {loading ? <Skeleton height={200} /> : <UserTask />}
//             </OverlayWrapper>
//           </div>
//         </div>
//       </div>
//       <div className="flex-shrink-0 w-full">
//         <AppFooter />
//       </div>
//     </div>
//   );
// };

// export default DashboardView;