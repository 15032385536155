// src/hooks/MiniApp/useVideo.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext';
import useUserDashboard from '../Dashboard/useUserDashboard';


interface Video {
  videoId: string;
  videoTitle: string;
  videoLength: string;
  videoDescription: string;
  videoCategory: string;
  videoCategoryArray: number;
  videoPath: string;
  videoThumbnail: string;
  hasPermission?: boolean;
}

interface Playlist {
  videoPlaylistId: string;
  videoPlaylistTitle: string;
  videoPlaylistDescription: string;
  videoPlaylistContent: string;
  videoPlaylistPermission: string;
}

const useVideo = () => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [videoCode, setVideoCode] = useState<string | null>(null); 
    const { apiUrl } = useEnv();
    const { user } = useUserDashboard(); 

  useEffect(() => {
    // Fetch videos
    const fetchVideos = async () => {
      try {
        const res = await fetch(`${apiUrl}/videos`);
        const data = await res.json();
        setVideos(data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    // Fetch playlists
    const fetchPlaylists = async () => {
      try {
        const res = await fetch(`${apiUrl}/playlists`);
        const data = await res.json();
        setPlaylists(data);
      } catch (error) {
        console.error('Error fetching playlists:', error);
      }
    };

    fetchVideos();
    fetchPlaylists();
  }, [apiUrl]);

   // Function to save user video data and fetch the generated code
   const saveUserVideoData = async (videoTitle: string, videoCategory: string) => {
    if (!user || !user.email || !user.username) {
      console.error('User information missing');
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/save-user-video`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: user.email,     
          userName: user.username,  
          videoTitle,
          videoCategory,  
        }),
      });
  
      const data = await response.json();
      if (data.success) {
        setVideoCode(data.code);
        return data.code; 
      }
    } catch (error) {
      console.error('Error saving user video data:', error);
      return null;
    }
  };

  return { videos, playlists, saveUserVideoData, videoCode };
};

export default useVideo;
