// src/components/admin/CampaignSetting.tsx

// import react components
import React, { useState, useEffect } from 'react';
import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import { useAuth } from '../../../context/AuthContext';   
import MailSetting from './MailSetting';
import CampaignLogic from './CampingLogic';
// import react icon
import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
import { IoTimerSharp } from "react-icons/io5";
import { MdPlaylistAddCircle, MdPlayArrow, MdStop, MdEditSquare } from "react-icons/md"; 
import { BsFillInboxesFill } from "react-icons/bs";
import { TbLayoutGridRemove } from "react-icons/tb";
import { FaCheck, FaTimes } from 'react-icons/fa';
// import custom components
import ConfirmAlertMessage  from '../alertMessage';

const CampaignSetting: React.FC = () => {
  const { user } = useAuth();
  const { 
    addCampaign, addWorkflow, workflows,
    fetchLogics, 
    fetchWorkflows, updateWorkflowStatus, deleteWorkflow,
    scheduleLogicEmails
    } = useEmail();
  const { permissions, loading, error } = useRoleAccess(user?.email || '');   
  // table filtering
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  // open modal component
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
  // State for editing workflow name
  const [newWorkflowName, setNewWorkflowName] = useState('');
  const [editingWorkflowId, setEditingWorkflowId] = useState<number | null>(null);
  const [editedWorkflowName, setEditedWorkflowName] = useState<string>('');
  // alert message
  const [showConfirmAlert, setShowConfirmAlert] = useState(false); 
  const [confirmMessage, setConfirmMessage] = useState(''); 
  const [confirmTitle, setConfirmTitle] = useState(''); 
  const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm'); 
  const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); 
  
  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  useEffect(() => {
    const updateClock = () => {
      const now = new Date().toLocaleTimeString('en-MY', {
        timeZone: 'Asia/Kuala_Lumpur',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
      setCurrentTime(now);
    };
  
    const intervalId = setInterval(updateClock, 1000); 
  
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <div>Loading permissions...</div>;
  }

  if (error) {
    return <div>Error loading permissions: {error}</div>;
  }

  if (!permissions || !permissions.emailSchedule) {
    return <div>No permissions data available.</div>;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0]; 
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kuala_Lumpur',
    };
    const formattedTime = date.toLocaleString('en-US', options); 
    
    return `${formattedDate}, ${formattedTime}`;
  };

  const handleCreate = (
    campaign: { 
      campTitle: string; 
      campType: string;
      campList: string
      contentTitle: string;
      contentName: string;
      contentBody: string;
      contentButton: string;
      sendFrom: string;
      sendTo: string;
      sendCC: string;
      subjectMail: string;
    }) => {
    addCampaign({ ...campaign });  
    setIsModalOpen(false); 
  };
  
  const handleOpenWorkflowModal = () => {
    setIsWorkflowModalOpen(true);
  };

  const handleAddWorkflow = async () => {
    try {
      // Add workflow to the backend
      await addWorkflow({ workflowName: newWorkflowName, workflowStatus: 'Stop' });

      // Close the modal
      setIsWorkflowModalOpen(false);

      // Fetch the updated list of workflows
      await fetchWorkflows();

      setNewWorkflowName('');
    } catch (error) {
      console.error('Error adding workflow:', error);
    }
  };  

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle double-click on workflow name
  const handleWorkflowNameDoubleClick = (workflowId: number, currentName: string) => {
    setEditingWorkflowId(workflowId);
    setEditedWorkflowName(currentName);
  };

  // Function to confirm the edit
  const handleConfirmEditWorkflowName = async (workflowId: number) => {
    try {
      if (!editedWorkflowName.trim()) {
        alert('Workflow name cannot be empty.');
        return;
      }

      // Update the workflow name in the backend
      await updateWorkflowStatus(workflowId, 'KeepCurrentStatus', editedWorkflowName.trim());

      setEditingWorkflowId(null);
      setEditedWorkflowName('');
      await fetchWorkflows();
    } catch (error) {
      console.error('Error updating workflow name:', error);
      alert('Failed to update workflow name. Please try again.');
    }
  };

  // Function to cancel the edit
  const handleCancelEditWorkflowName = () => {
    setEditingWorkflowId(null);
    setEditedWorkflowName('');
  };

  // <<----- Filtering workflow function
   const filteredWorkflows = workflows.filter((workflow) =>
    workflow.workflowType === "Flow" && 
    (workflow.workflowName ? workflow.workflowName.toLowerCase().includes(searchTerm.toLowerCase()) : false)
  );
  
  // <<----- Filtering workflow table action stats
  const handleToggleStatus = (workflowId: number, currentStatus: string) => {
    setConfirmMessage(`Do you want to ${currentStatus === 'Stop' ? 'play' : 'stop'} this workflow?`);
    setConfirmType('confirm');
    setActionToConfirm(() => async () => {
      const newStatus = currentStatus === 'Stop' ? 'Play' : 'Stop';
      await updateWorkflowStatus(workflowId, newStatus);
      await fetchWorkflows(); 
  
      // If the workflow status is "Play", start scheduling the logic
      if (newStatus === 'Play') {
        await startWorkflowLogic(workflowId);
      }
    });
    setShowConfirmAlert(true);
    setConfirmTitle(`Confirm to ${currentStatus === 'Stop' ? 'Play' : 'Stop'} Workflow`);
  };
  
  // Function to start scheduling logic when workflow is "Play"
  const startWorkflowLogic = async (workflowId: number) => {
    const logicRows = await fetchLogics(workflowId);
  
    // Loop through all logic rows and only execute the ones that are "On"
    for (const logic of logicRows) {
      if (logic.logicStatus === 'On') {
        await scheduleLogicEmails(logic, logicRows); 
      }
    }
  };
 
  
  const handleRemoveWorkflow = (workflowId: number) => {
    setConfirmMessage('Are you sure you want to remove this workflow? This action will delete the included logic and cannot be undone.');
    setConfirmType('warning');
    setActionToConfirm(() => async () => {
      await deleteWorkflow(workflowId);
      await fetchWorkflows();
    });
    setShowConfirmAlert(true);
    setConfirmTitle('Confirm Workflow Removal');
  };

  const handleConfirm = async () => {
    if (typeof actionToConfirm === 'function') {
      await actionToConfirm(); 
    }
    setShowConfirmAlert(false); 
  };  

  // Function to edit a workflow
  const handleEditWorkflow = (workflowId: number) => {
    setSelectedWorkflowId(workflowId); 
    setIsLogicModalOpen(true);         
  };

  return (
    <div>
      <div className="bg-white shadow-md p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
        <div className="mb-4 flex justify-between items-center space-x-2">
          <div className="flex justify-between items-center relative w-full">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <IoMdSearch className="text-2xl text-cyan-800" />
            </span>
            <input
                type="text"
                placeholder="Search by Title or Content"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border px-2 py-1 rounded pl-10 w-[300px]"
            />
            <div className="flex justify-end items-center space-x-2">
              <div className="flex justify-normal items-center text-cyan-800 border border-cyan-800 px-4 rounded-md font-semibold text-sm">
                <IoTimerSharp className="mr-2"/> {currentTime}
              </div>
              <button 
                onClick={handleOpenModal}
                className={`flex justify-center items-center text-base text-cyan-800 space-x-2 cursor-pointer ${permissions?.emailSchedule.editEmail === 'enable' ? 'text-cyan-800' : 'text-gray-300 cursor-not-allowed'}`}
                disabled={permissions?.emailSchedule?.editEmail !== 'enable'} 
              >
                <BsFillInboxesFill />
              </button>
            </div>
          </div>
        </div>

        {/* Single Campaign Table Section */}
        <div className="mb-6">
          <table className="w-full table-full bg-white text-center rounded text-sm">
            <thead>
              <tr className="bg-gray-200 text-cyan-800">
                <th className="px-4 py-2 w-auto">id</th>
                <th className="px-4 py-2 w-auto">Workflow Name</th>
                <th className="px-4 py-2 w-auto">Workflow Status</th>
                <th className="px-4 py-2 w-auto">Created Date</th>
                <th className="px-4 py-2 w-auto">Click Stats</th>
                <th className="px-4 py-2 w-auto">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredWorkflows.length > 0 ? (
                filteredWorkflows.map((workflow) => (
                <tr key={workflow.id} className="border-b border-x hover:bg-gray-100">

                  {/* workflow id section */}
                  <td className="px-4 py-2">{workflow.workflowId}</td>

                  {/* workflow name section */}
                  <td className="px-4 py-2">
                    {editingWorkflowId === workflow.workflowId ? (
                      <div className="flex text-center items-center">
                        <input
                          type="text"
                          value={editedWorkflowName}
                          onChange={(e) => setEditedWorkflowName(e.target.value)}
                          className="border p-1 rounded text-center"
                        />
                        <button onClick={() => handleConfirmEditWorkflowName(workflow.workflowId)}>
                          <FaCheck className="text-cyan-800 ml-2 cursor-pointer" />
                        </button>
                        <button onClick={handleCancelEditWorkflowName}>
                          <FaTimes className="text-rose-500 ml-2 cursor-pointer" />
                        </button>
                      </div>
                    ) : (
                      <span
                        onDoubleClick={() => handleWorkflowNameDoubleClick(workflow.workflowId, workflow.workflowName)}
                        className="cursor-text"
                      >
                        {workflow.workflowName}
                      </span>
                    )}
                  </td>

                  {/* workflow status section */}
                  <td className="px-4 py-2 flex justify-center items-center">
                    <button
                      onClick={() => handleToggleStatus(workflow.workflowId, workflow.workflowStatus)}
                      disabled={permissions?.emailSchedule.changeStatus !== 'enable'} 
                      className={`flex justify-normal items-center text-sm ${
                        workflow.workflowStatus === 'Stop' ? 'text-rose-600' : 'text-cyan-800'
                      } ${
                        permissions?.emailSchedule.changeStatus !== 'enable' 
                          ? ' text-gray-300 cursor-not-allowed' 
                          : ''
                      }`}
                    >
                      {workflow.workflowStatus === 'Stop' ? (
                        <>
                          <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
                          <span>Stop</span>
                        </>
                      ) : (
                        <>
                          <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
                          <span>Play</span>
                        </>
                      )}
                    </button>
                  </td>
                  <td className="px-4 py-2">{formatDate(workflow.createdAt.toString())}</td>
                  <td className="px-4 py-2">{workflow.clickedStats}</td>
                  <td>

                    <div className="flex justify-center items-center space-x-2">
                      {/* Edit icon */}
                      <button                           
                        className={`cursor-pointer text-lg text-cyan-800 ${permissions?.emailSchedule.editLogic === 'enable' ? 'text-cyan-800' : 'text-gray-200 cursor-not-allowed'}`} 
                        onClick={() => handleEditWorkflow(workflow.workflowId)}
                        disabled={permissions?.emailSchedule.editLogic !== 'enable'} 
                      >
                        <MdEditSquare />
                      </button>

                      {/* Remove icon */}
                      <button                           
                        className={`cursor-pointer text-lg text-cyan-800 ${permissions?.emailSchedule.deleteLogic === 'enable' ? 'text-cyan-800' : 'text-gray-300 cursor-not-allowed'}`} 
                        onClick={() => handleRemoveWorkflow(workflow.workflowId)}
                        disabled={permissions?.emailSchedule.deleteLogic !== 'enable'} 
                      >
                        <TbLayoutGridRemove />
                      </button>
                    </div>

                  </td>
                </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-4 py-4 border-dashed border-2">
                    No campaigns found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="flex justify-end w-full space-x-2 my-2">
            <button
              onClick={handleOpenWorkflowModal}
              className={`py-2 px-4 text-center rounded ${permissions?.emailSchedule.addLogic === 'enable' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}
              disabled={permissions?.emailSchedule.addLogic !== 'enable'}
            >
              <div className="flex justify-center items-center">
                <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
              </div>
            </button>
            <div className="flex flex-normal items-center">
              <button
                onClick={handleOpenModal}
                className={`py-2 px-4 text-center rounded ${permissions?.emailSchedule.addEmail === 'enable' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}
                disabled={permissions?.emailSchedule.addEmail !== 'enable'}
              >
                <div className="flex justify-center items-center">
                  <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* show alert message */}
      {showConfirmAlert && (
        <ConfirmAlertMessage
          type={confirmType}
          title={confirmTitle}  // Ensure title is passed here
          message={confirmMessage}
          visible={showConfirmAlert}
          onClose={() => setShowConfirmAlert(false)}
          onConfirm={handleConfirm} 
        />
      )}

      {/* Workflow Modal */}
      {isWorkflowModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
            <div className="flex justify-end">
              <button
                onClick={() => setIsWorkflowModalOpen(false)}
                className="text-rose-500 text-2xl"
              >
                <IoMdCloseCircle />
              </button>
            </div>
            <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
            <input
              type="text"
              value={newWorkflowName}
              onChange={(e) => setNewWorkflowName(e.target.value)}
              className="border p-2 rounded w-full mb-4"
              placeholder="Workflow Name"
            />
            <div className="flex justify-end space-x-2">
              <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
                Cancel
              </button>
              <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Logic Modal */}
      {isLogicModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
            {selectedWorkflowId !== null && (
              <CampaignLogic
                workflowId={selectedWorkflowId} 
                workflowName={
                  workflows.find((wf) => wf.workflowId === selectedWorkflowId)?.workflowName || ''
                }
                closeWorkflow={() => setIsLogicModalOpen(false)}
              />
            )}
          </div>
        </div>
      )}

      {/* add Mail Content Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
            <div className="flex justify-end">
              <button
                onClick={handleCloseModal}
                className=" text-rose-500 text-2xl"
              >
                <IoMdCloseCircle />
              </button>
            </div>
            <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignSetting;

// // update code --> 23 oct 2024
// // src/components/admin/CampaignSetting.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
// import { useAuth } from '../../../context/AuthContext';   
// import MailSetting from './MailSetting';
// import CampaignLogic from './CampingLogic';
// // import react icon
// import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
// import { IoTimerSharp } from "react-icons/io5";
// import { MdPlaylistAddCircle, MdPlayArrow, MdStop, MdEditSquare } from "react-icons/md"; 
// import { BsFillInboxesFill } from "react-icons/bs";
// import { TbLayoutGridRemove } from "react-icons/tb";
// import { FaCheck, FaTimes } from 'react-icons/fa';
// // import custom components
// import ConfirmAlertMessage  from '../alertMessage';

// const CampaignSetting: React.FC = () => {
//   const { user } = useAuth();
//   const { 
//     addCampaign, addWorkflow, workflows,
//     fetchLogics, 
//     fetchWorkflows, updateWorkflowStatus, deleteWorkflow,
//     scheduleLogicEmails
//     } = useEmail();
//   const { permissions } = useRoleAccess(user?.email || '');   
//   // table filtering
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentTime, setCurrentTime] = useState('');
//   // open modal component
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
//   const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
//   const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
//   // State for editing workflow name
//   const [newWorkflowName, setNewWorkflowName] = useState('');
//   const [editingWorkflowId, setEditingWorkflowId] = useState<number | null>(null);
//   const [editedWorkflowName, setEditedWorkflowName] = useState<string>('');
//   // alert message
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);  // State for confirmation alert visibility
//   const [confirmMessage, setConfirmMessage] = useState(''); // State for confirmation message
//   const [confirmTitle, setConfirmTitle] = useState(''); // Add this state to hold the confirmation title
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm'); // State for alert type
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); // State for the action to confirm
  
//   useEffect(() => {
//     fetchWorkflows();
//   }, [fetchWorkflows]);

//   useEffect(() => {
//     const updateClock = () => {
//       const now = new Date().toLocaleTimeString('en-MY', {
//         timeZone: 'Asia/Kuala_Lumpur',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//         hour12: true,
//       });
//       setCurrentTime(now);
//     };
  
//     const intervalId = setInterval(updateClock, 1000); // Update every second
  
//     return () => clearInterval(intervalId); // Cleanup on unmount
//   }, []);

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const formattedDate = date.toISOString().split('T')[0]; 
//     const options: Intl.DateTimeFormatOptions = {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'Asia/Kuala_Lumpur',
//     };
//     const formattedTime = date.toLocaleString('en-US', options); 
    
//     return `${formattedDate}, ${formattedTime}`;
//   };

//   const handleCreate = (
//     campaign: { 
//       campTitle: string; 
//       campType: string;
//       campList: string
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentButton: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => {
//     addCampaign({ ...campaign });  
//     setIsModalOpen(false); 
//   };
  
//   const handleOpenWorkflowModal = () => {
//     setIsWorkflowModalOpen(true);
//   };

//   const handleAddWorkflow = async () => {
//     try {
//       // Add workflow to the backend
//       await addWorkflow({ workflowName: newWorkflowName, workflowStatus: 'Stop' });

//       // Close the modal
//       setIsWorkflowModalOpen(false);

//       // Fetch the updated list of workflows
//       await fetchWorkflows();

//       setNewWorkflowName(''); // Clear the input
//     } catch (error) {
//       console.error('Error adding workflow:', error);
//     }
//   };  

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   // Function to handle double-click on workflow name
//   const handleWorkflowNameDoubleClick = (workflowId: number, currentName: string) => {
//     setEditingWorkflowId(workflowId);
//     setEditedWorkflowName(currentName);
//   };

//   // Function to confirm the edit
//   const handleConfirmEditWorkflowName = async (workflowId: number) => {
//     try {
//       if (!editedWorkflowName.trim()) {
//         alert('Workflow name cannot be empty.');
//         return;
//       }

//       // Update the workflow name in the backend
//       await updateWorkflowStatus(workflowId, 'KeepCurrentStatus', editedWorkflowName.trim());

//       setEditingWorkflowId(null);
//       setEditedWorkflowName('');
//       await fetchWorkflows(); // Refresh the workflows to update the UI
//     } catch (error) {
//       console.error('Error updating workflow name:', error);
//       alert('Failed to update workflow name. Please try again.');
//     }
//   };

//   // Function to cancel the edit
//   const handleCancelEditWorkflowName = () => {
//     setEditingWorkflowId(null);
//     setEditedWorkflowName('');
//   };

//   // <<----- Filtering workflow function
//    const filteredWorkflows = workflows.filter((workflow) =>
//     workflow.workflowType === "Flow" &&  // Only include workflows with type "Flow"
//     (workflow.workflowName ? workflow.workflowName.toLowerCase().includes(searchTerm.toLowerCase()) : false)
//   );
  
//   // <<----- Filtering workflow table action stats
//   const handleToggleStatus = (workflowId: number, currentStatus: string) => {
//     setConfirmMessage(`Do you want to ${currentStatus === 'Stop' ? 'play' : 'stop'} this workflow?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const newStatus = currentStatus === 'Stop' ? 'Play' : 'Stop';
//       await updateWorkflowStatus(workflowId, newStatus);
//       await fetchWorkflows(); // Refresh the workflows to update the UI
  
//       // If the workflow status is "Play", start scheduling the logic
//       if (newStatus === 'Play') {
//         await startWorkflowLogic(workflowId);
//       }
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle(`Confirm to ${currentStatus === 'Stop' ? 'Play' : 'Stop'} Workflow`);
//   };
  
//   // Function to start scheduling logic when workflow is "Play"
//   const startWorkflowLogic = async (workflowId: number) => {
//     const logicRows = await fetchLogics(workflowId);
  
//     // Loop through all logic rows and only execute the ones that are "On"
//     for (const logic of logicRows) {
//       if (logic.logicStatus === 'On') {
//         await scheduleLogicEmails(logic, logicRows); // Pass both logic and logicRows (schedules)
//       }
//     }
//   };
 
  
//   const handleRemoveWorkflow = (workflowId: number) => {
//     setConfirmMessage('Are you sure you want to remove this workflow? This action will delete the included logic and cannot be undone.');
//     setConfirmType('warning');
//     setActionToConfirm(() => async () => {
//       await deleteWorkflow(workflowId);
//       await fetchWorkflows();
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle('Confirm Workflow Removal');
//   };

//   const handleConfirm = async () => {
//     if (typeof actionToConfirm === 'function') {
//       await actionToConfirm(); // Executes the stored action
//     }
//     setShowConfirmAlert(false); // Close the confirmation alert after the action
//   };  

//   // Function to edit a workflow
//   const handleEditWorkflow = (workflowId: number) => {
//     setSelectedWorkflowId(workflowId);  // Store the selected workflowId
//     setIsLogicModalOpen(true);          // Open the modal for editing the workflow
//   };

//   return (
//     <div>
//       <div className="bg-white shadow-md p-6 rounded-lg">
//         <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
//         <div className="mb-4 flex justify-between items-center space-x-2">
//           <div className="flex justify-between items-center relative w-full">
//             <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
//               <IoMdSearch className="text-2xl text-cyan-800" />
//             </span>
//             <input
//                 type="text"
//                 placeholder="Search by Title or Content"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="border px-2 py-1 rounded pl-10 w-[300px]"
//             />
//             <div className="flex justify-end items-center space-x-2">
//               <div className="flex justify-normal items-center text-cyan-800 border border-cyan-800 px-4 rounded-md font-semibold text-sm">
//                 <IoTimerSharp className="mr-2"/> {currentTime}
//               </div>
//               <div 
//                 onClick={handleOpenModal}
//                 className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//               >
//                 <BsFillInboxesFill />
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Single Campaign Table Section */}
//         <div className="mb-6">
//           <table className="w-full table-full bg-white text-center rounded text-sm">
//             <thead>
//               <tr className="bg-gray-200 text-cyan-800">
//                 <th className="px-4 py-2 w-auto">id</th>
//                 <th className="px-4 py-2 w-auto">Workflow Name</th>
//                 <th className="px-4 py-2 w-auto">Workflow Status</th>
//                 <th className="px-4 py-2 w-auto">Created Date</th>
//                 <th className="px-4 py-2 w-auto">Click Stats</th>
//                 <th className="px-4 py-2 w-auto">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredWorkflows.length > 0 ? (
//                 filteredWorkflows.map((workflow) => (
//                 <tr key={workflow.id} className="border-b border-x hover:bg-gray-100">

//                   {/* workflow id section */}
//                   <td className="px-4 py-2">{workflow.workflowId}</td>

//                   {/* workflow name section */}
//                   <td className="px-4 py-2">
//                     {editingWorkflowId === workflow.workflowId ? (
//                       <div className="flex text-center items-center">
//                         <input
//                           type="text"
//                           value={editedWorkflowName}
//                           onChange={(e) => setEditedWorkflowName(e.target.value)}
//                           className="border p-1 rounded text-center"
//                         />
//                         <button onClick={() => handleConfirmEditWorkflowName(workflow.workflowId)}>
//                           <FaCheck className="text-cyan-800 ml-2 cursor-pointer" />
//                         </button>
//                         <button onClick={handleCancelEditWorkflowName}>
//                           <FaTimes className="text-rose-500 ml-2 cursor-pointer" />
//                         </button>
//                       </div>
//                     ) : (
//                       <span
//                         onDoubleClick={() => handleWorkflowNameDoubleClick(workflow.workflowId, workflow.workflowName)}
//                         className="cursor-text"
//                       >
//                         {workflow.workflowName}
//                       </span>
//                     )}
//                   </td>

//                   {/* workflow status section */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <button
//                       onClick={() => handleToggleStatus(workflow.workflowId, workflow.workflowStatus)}
//                       className={`flex justify-normal items-center text-sm ${
//                         workflow.workflowStatus === 'Stop' ? 'text-rose-600' : 'text-cyan-800'
//                       }`}
//                     >
//                       {workflow.workflowStatus === 'Stop' ? (
//                         <>
//                           <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
//                           <span>Stop</span>
//                         </>
//                       ) : (
//                         <>
//                           <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
//                           <span>Play</span>
//                         </>
//                       )}
//                     </button>
//                   </td>
//                   <td className="px-4 py-2">{formatDate(workflow.createdAt.toString())}</td>
//                   <td className="px-4 py-2">{workflow.clickedStats}</td>
//                   <td>
//                     <div 
//                       className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//                     >
//                       {/* Edit icon */}
//                       <MdEditSquare 
//                         className="cursor-pointer" 
//                         onClick={() => handleEditWorkflow(workflow.workflowId)} 
//                       />
//                       {/* Remove icon */}
//                       <TbLayoutGridRemove 
//                         className="cursor-pointer" 
//                         onClick={() => handleRemoveWorkflow(workflow.workflowId)} 
//                       />
//                     </div>
//                   </td>
//                 </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-4 py-4 border-dashed border-2">
//                     No campaigns found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//           <div className="flex justify-end w-full space-x-2 my-2">
//             <button
//               onClick={handleOpenWorkflowModal}
//               className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//             >
//               <div className="flex justify-center items-center">
//                 <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
//               </div>
//             </button>
//             <div className="flex flex-normal items-center">
//               <button
//                 onClick={handleOpenModal}
//                 className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//               >
//                 <div className="flex justify-center items-center">
//                   <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
//                 </div>
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
      
//       {/* show alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title={confirmTitle}  // Ensure title is passed here
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={handleConfirm} 
//         />
//       )}

//       {/* Workflow Modal */}
//       {isWorkflowModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={() => setIsWorkflowModalOpen(false)}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
//             <input
//               type="text"
//               value={newWorkflowName}
//               onChange={(e) => setNewWorkflowName(e.target.value)}
//               className="border p-2 rounded w-full mb-4"
//               placeholder="Workflow Name"
//             />
//             <div className="flex justify-end space-x-2">
//               <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
//                 Cancel
//               </button>
//               <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Logic Modal */}
//       {isLogicModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
//             {selectedWorkflowId !== null && (
//               <CampaignLogic
//                 workflowId={selectedWorkflowId} 
//                 workflowName={
//                   workflows.find((wf) => wf.workflowId === selectedWorkflowId)?.workflowName || ''
//                 }
//                 closeWorkflow={() => setIsLogicModalOpen(false)}
//               />
//             )}
//           </div>
//         </div>
//       )}

//       {/* add Mail Content Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseModal}
//                 className=" text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignSetting;

// // udpate code --> 19 sept 2024
// // src/components/admin/CampaignSetting.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  
// import MailSetting from './MailSetting';
// import CampaignLogic from './CampingLogic';
// // import react icon
// import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
// import { MdPlaylistAddCircle, MdPlayArrow, MdStop, MdEditSquare } from "react-icons/md"; 
// import { BsFillInboxesFill } from "react-icons/bs";
// import { TbLayoutGridRemove } from "react-icons/tb";
// import { FaCheck, FaTimes } from 'react-icons/fa';
// // import custom components
// import ConfirmAlertMessage  from '../alertMessage';

// const CampaignSetting: React.FC = () => {
//   const { 
//     addCampaign, addWorkflow, workflows,
//     fetchLogics, 
//     fetchWorkflows, updateWorkflowStatus, deleteWorkflow,
//     scheduleLogicEmails
//     } = useEmail();   
//   // table filtering
//   const [searchTerm, setSearchTerm] = useState(''); 
//   // open modal component
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
//   const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
//   const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
//   // State for editing workflow name
//   const [newWorkflowName, setNewWorkflowName] = useState('');
//   const [editingWorkflowId, setEditingWorkflowId] = useState<number | null>(null);
//   const [editedWorkflowName, setEditedWorkflowName] = useState<string>('');
//   // alert message
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);  // State for confirmation alert visibility
//   const [confirmMessage, setConfirmMessage] = useState(''); // State for confirmation message
//   const [confirmTitle, setConfirmTitle] = useState(''); // Add this state to hold the confirmation title
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm'); // State for alert type
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); // State for the action to confirm
  
//   useEffect(() => {
//     fetchWorkflows();
//   }, [fetchWorkflows]);

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const formattedDate = date.toISOString().split('T')[0]; 
//     const options: Intl.DateTimeFormatOptions = {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'Asia/Kuala_Lumpur',
//     };
//     const formattedTime = date.toLocaleString('en-US', options); 
    
//     return `${formattedDate}, ${formattedTime}`;
//   };

//   const handleCreate = (
//     campaign: { 
//       campTitle: string; 
//       campType: string;
//       campList: string
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentButton: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => {
//     addCampaign({ ...campaign });  
//     setIsModalOpen(false); 
//   };
  
//   const handleOpenWorkflowModal = () => {
//     setIsWorkflowModalOpen(true);
//   };

//   const handleAddWorkflow = async () => {
//     try {
//       // Add workflow to the backend
//       const newWorkflow = await addWorkflow({ workflowName: newWorkflowName, workflowStatus: 'Stop' });

//       // Close the modal
//       setIsWorkflowModalOpen(false);

//       // Fetch the updated list of workflows
//       await fetchWorkflows();

//       // Open the logic modal for the newly created workflow
//       setSelectedWorkflowId(newWorkflow.workflowId);
//       setIsLogicModalOpen(true);
//       setNewWorkflowName(''); // Clear the input
//     } catch (error) {
//       console.error('Error adding workflow:', error);
//     }
//   };  

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   // Function to handle double-click on workflow name
//   const handleWorkflowNameDoubleClick = (workflowId: number, currentName: string) => {
//     setEditingWorkflowId(workflowId);
//     setEditedWorkflowName(currentName);
//   };

//   // Function to confirm the edit
//   const handleConfirmEditWorkflowName = async (workflowId: number) => {
//     try {
//       if (!editedWorkflowName.trim()) {
//         alert('Workflow name cannot be empty.');
//         return;
//       }

//       // Update the workflow name in the backend
//       await updateWorkflowStatus(workflowId, 'KeepCurrentStatus', editedWorkflowName.trim());

//       setEditingWorkflowId(null);
//       setEditedWorkflowName('');
//       await fetchWorkflows(); // Refresh the workflows to update the UI
//     } catch (error) {
//       console.error('Error updating workflow name:', error);
//       alert('Failed to update workflow name. Please try again.');
//     }
//   };

//   // Function to cancel the edit
//   const handleCancelEditWorkflowName = () => {
//     setEditingWorkflowId(null);
//     setEditedWorkflowName('');
//   };

//   // <<----- Filtering workflow function
//    const filteredWorkflows = workflows.filter((workflow) =>
//     workflow.workflowType === "Flow" &&  // Only include workflows with type "Flow"
//     (workflow.workflowName ? workflow.workflowName.toLowerCase().includes(searchTerm.toLowerCase()) : false)
//   );
  
//   // <<----- Filtering workflow table action stats
//   const handleToggleStatus = (workflowId: number, currentStatus: string) => {
//     setConfirmMessage(`Do you want to ${currentStatus === 'Stop' ? 'play' : 'stop'} this workflow?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const newStatus = currentStatus === 'Stop' ? 'Play' : 'Stop';
//       await updateWorkflowStatus(workflowId, newStatus);
//       await fetchWorkflows(); // Refresh the workflows to update the UI

//       // If the workflow status is "Play", start scheduling the logic
//       if (newStatus === 'Play') {
//         console.log(`Workflow ${workflowId} is now playing. Starting logic scheduling.`);
//         await startWorkflowLogic(workflowId);
//       }
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle(`Confirm to ${currentStatus === 'Stop' ? 'Play' : 'Stop'} Workflow`);
//   };

//   // Start scheduling logic when workflow is "Play"
//   const startWorkflowLogic = async (workflowId: number) => {
//     console.log(`Fetching logics for workflow ${workflowId}`);
//     const logicRows = await fetchLogics(workflowId);

//     for (const logic of logicRows) {
//       if (logic.logicStatus === 'On') {
//         console.log(`Scheduling logic ${logic.scheduleName} for workflow ${workflowId}`);
//         await scheduleLogicEmails(logic, logicRows); // Pass both logic and logicRows (schedules)
//       }
//     }
//   };
  
//   const handleRemoveWorkflow = (workflowId: number) => {
//     setConfirmMessage('Are you sure you want to remove this workflow? This action will delete the included logic and cannot be undone.');
//     setConfirmType('warning');
//     setActionToConfirm(() => async () => {
//       await deleteWorkflow(workflowId);
//       await fetchWorkflows();
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle('Confirm Workflow Removal');
//   };

//   const handleConfirm = async () => {
//     if (typeof actionToConfirm === 'function') {
//       await actionToConfirm(); // Executes the stored action
//     }
//     setShowConfirmAlert(false); // Close the confirmation alert after the action
//   };  

//   // Function to edit a workflow
//   const handleEditWorkflow = (workflowId: number) => {
//     setSelectedWorkflowId(workflowId);  // Store the selected workflowId
//     setIsLogicModalOpen(true);          // Open the modal for editing the workflow
//   };

//   return (
//     <div>
//       <div className="bg-white shadow-md p-6 rounded-lg">
//         <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
//         <div className="mb-4 flex justify-between items-center space-x-2">
//           <div className="flex justify-normal items-center relative w-full">
//             <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
//               <IoMdSearch className="text-2xl text-cyan-800" />
//             </span>
//             <input
//                 type="text"
//                 placeholder="Search by Title or Content"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="border p-2 rounded pl-10 w-full"
//             />
//           </div>
//           <div className="flex justify-end w-full space-x-2">
//             <button
//                 onClick={handleOpenWorkflowModal}
//                 className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//             >
//                 <div className="flex justify-center items-center">
//                     <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
//                 </div>
//             </button>
//             <div className="flex flex-normal items-center">
//               <button
//                   onClick={handleOpenModal}
//                   className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//               >
//                   <div className="flex justify-center items-center">
//                       <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
//                   </div>
//               </button>
//             </div>
//             <div 
//               onClick={handleOpenModal}
//               className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//             >
//               <BsFillInboxesFill />
//             </div>
//           </div>
//         </div>

//         {/* Single Campaign Table Section */}
//         <div className="mb-6">
//           <table className="w-full table-full bg-white text-center rounded text-sm">
//             <thead>
//               <tr className="bg-gray-200 text-cyan-800">
//                 <th className="px-4 py-2 w-auto">id</th>
//                 <th className="px-4 py-2 w-auto">Workflow Name</th>
//                 <th className="px-4 py-2 w-auto">Workflow Status</th>
//                 <th className="px-4 py-2 w-auto">Created Date</th>
//                 <th className="px-4 py-2 w-auto">Click Stats</th>
//                 <th className="px-4 py-2 w-auto">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredWorkflows.length > 0 ? (
//                 filteredWorkflows.map((workflow) => (
//                 <tr key={workflow.id} className="border-b border-x hover:bg-gray-100">

//                   {/* workflow id section */}
//                   <td className="px-4 py-2">{workflow.workflowId}</td>

//                   {/* workflow name section */}
//                   <td className="px-4 py-2">
//                     {editingWorkflowId === workflow.workflowId ? (
//                       <div className="flex text-center items-center">
//                         <input
//                           type="text"
//                           value={editedWorkflowName}
//                           onChange={(e) => setEditedWorkflowName(e.target.value)}
//                           className="border p-1 rounded text-center"
//                         />
//                         <button onClick={() => handleConfirmEditWorkflowName(workflow.workflowId)}>
//                           <FaCheck className="text-cyan-800 ml-2 cursor-pointer" />
//                         </button>
//                         <button onClick={handleCancelEditWorkflowName}>
//                           <FaTimes className="text-rose-500 ml-2 cursor-pointer" />
//                         </button>
//                       </div>
//                     ) : (
//                       <span
//                         onDoubleClick={() => handleWorkflowNameDoubleClick(workflow.workflowId, workflow.workflowName)}
//                         className="cursor-text"
//                       >
//                         {workflow.workflowName}
//                       </span>
//                     )}
//                   </td>

//                   {/* workflow status section */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <button
//                       onClick={() => handleToggleStatus(workflow.workflowId, workflow.workflowStatus)}
//                       className={`flex justify-normal items-center text-sm ${
//                         workflow.workflowStatus === 'Stop' ? 'text-rose-600' : 'text-cyan-800'
//                       }`}
//                     >
//                       {workflow.workflowStatus === 'Stop' ? (
//                         <>
//                           <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
//                           <span>Stop</span>
//                         </>
//                       ) : (
//                         <>
//                           <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
//                           <span>Play</span>
//                         </>
//                       )}
//                     </button>
//                   </td>
//                   <td className="px-4 py-2">{formatDate(workflow.createdAt.toString())}</td>
//                   <td className="px-4 py-2">{workflow.clickedStats}</td>
//                   <td>
//                     <div 
//                       className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//                     >
//                       {/* Edit icon */}
//                       <MdEditSquare 
//                         className="cursor-pointer" 
//                         onClick={() => handleEditWorkflow(workflow.workflowId)} 
//                       />
//                       {/* Remove icon */}
//                       <TbLayoutGridRemove 
//                         className="cursor-pointer" 
//                         onClick={() => handleRemoveWorkflow(workflow.workflowId)} 
//                       />
//                     </div>
//                   </td>
//                 </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-4 py-4 border-dashed border-2">
//                     No campaigns found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
      
//       {/* show alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title={confirmTitle}  // Ensure title is passed here
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={handleConfirm} 
//         />
//       )}

//       {/* Workflow Modal */}
//       {isWorkflowModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={() => setIsWorkflowModalOpen(false)}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
//             <input
//               type="text"
//               value={newWorkflowName}
//               onChange={(e) => setNewWorkflowName(e.target.value)}
//               className="border p-2 rounded w-full mb-4"
//               placeholder="Workflow Name"
//             />
//             <div className="flex justify-end space-x-2">
//               <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
//                 Cancel
//               </button>
//               <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Logic Modal */}
//       {isLogicModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
//             {selectedWorkflowId !== null && (
//               <CampaignLogic
//                 workflowId={selectedWorkflowId} 
//                 workflowName={
//                   workflows.find((wf) => wf.workflowId === selectedWorkflowId)?.workflowName || ''
//                 }
//                 closeWorkflow={() => setIsLogicModalOpen(false)}
//               />
//             )}
//           </div>
//         </div>
//       )}

//       {/* add Mail Content Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseModal}
//                 className=" text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignSetting;

// // update code --> 18 Sept 2024 v03
// // src/components/admin/CampaignSetting.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  
// import MailSetting from './MailSetting';
// import CampaignLogic from './CampingLogic';
// // import react icon
// import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
// import { MdPlaylistAddCircle, MdPlayArrow, MdStop, MdEditSquare } from "react-icons/md"; 
// import { BsFillInboxesFill } from "react-icons/bs";
// import { TbLayoutGridRemove } from "react-icons/tb";
// import { FaCheck, FaTimes } from 'react-icons/fa';
// // import custom components
// import ConfirmAlertMessage  from '../alertMessage';

// const CampaignSetting: React.FC = () => {
//   const { 
//     addCampaign, addWorkflow, workflows, 
//     fetchWorkflows, updateWorkflowStatus, deleteWorkflow,
//     } = useEmail();   
//   // table filtering
//   const [searchTerm, setSearchTerm] = useState(''); 
//   // open modal component
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
//   const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
//   const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
//   // State for editing workflow name
//   const [newWorkflowName, setNewWorkflowName] = useState('');
//   const [editingWorkflowId, setEditingWorkflowId] = useState<number | null>(null);
//   const [editedWorkflowName, setEditedWorkflowName] = useState<string>('');
//   // alert message
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);  // State for confirmation alert visibility
//   const [confirmMessage, setConfirmMessage] = useState(''); // State for confirmation message
//   const [confirmTitle, setConfirmTitle] = useState(''); // Add this state to hold the confirmation title
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm'); // State for alert type
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); // State for the action to confirm
  
//   useEffect(() => {
//     fetchWorkflows();
//   }, [fetchWorkflows]);

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const formattedDate = date.toISOString().split('T')[0]; 
//     const options: Intl.DateTimeFormatOptions = {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'Asia/Kuala_Lumpur',
//     };
//     const formattedTime = date.toLocaleString('en-US', options); 
    
//     return `${formattedDate}, ${formattedTime}`;
//   };

//   const handleCreate = (
//     campaign: { 
//       campTitle: string; 
//       campType: string;
//       campList: string
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentButton: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => {
//     addCampaign({ ...campaign });  
//     setIsModalOpen(false); 
//   };
  
//   const handleOpenWorkflowModal = () => {
//     setIsWorkflowModalOpen(true);
//   };

//   const handleAddWorkflow = async () => {
//     try {
//       // Add workflow to the backend
//       const newWorkflow = await addWorkflow({ workflowName: newWorkflowName, workflowStatus: 'Stop' });

//       // Close the modal
//       setIsWorkflowModalOpen(false);

//       // Fetch the updated list of workflows
//       await fetchWorkflows();

//       // Open the logic modal for the newly created workflow
//       setSelectedWorkflowId(newWorkflow.workflowId);
//       setIsLogicModalOpen(true);
//       setNewWorkflowName(''); // Clear the input
//     } catch (error) {
//       console.error('Error adding workflow:', error);
//     }
//   };  

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   // Function to handle double-click on workflow name
//   const handleWorkflowNameDoubleClick = (workflowId: number, currentName: string) => {
//     setEditingWorkflowId(workflowId);
//     setEditedWorkflowName(currentName);
//   };

//   // Function to confirm the edit
//   const handleConfirmEditWorkflowName = async (workflowId: number) => {
//     try {
//       if (!editedWorkflowName.trim()) {
//         alert('Workflow name cannot be empty.');
//         return;
//       }

//       // Update the workflow name in the backend
//       await updateWorkflowStatus(workflowId, 'KeepCurrentStatus', editedWorkflowName.trim());

//       setEditingWorkflowId(null);
//       setEditedWorkflowName('');
//       await fetchWorkflows(); // Refresh the workflows to update the UI
//     } catch (error) {
//       console.error('Error updating workflow name:', error);
//       alert('Failed to update workflow name. Please try again.');
//     }
//   };

//   // Function to cancel the edit
//   const handleCancelEditWorkflowName = () => {
//     setEditingWorkflowId(null);
//     setEditedWorkflowName('');
//   };

//   // <<----- Filtering workflow function
//    const filteredWorkflows = workflows.filter((workflow) =>
//     workflow.workflowType === "Flow" &&  // Only include workflows with type "Flow"
//     (workflow.workflowName ? workflow.workflowName.toLowerCase().includes(searchTerm.toLowerCase()) : false)
//   );
  
//   // <<----- Filtering workflow table action stats
//   const handleToggleStatus = (workflowId: number, currentStatus: string) => {
//     setConfirmMessage(`Do you want to ${currentStatus === 'Stop' ? 'play' : 'stop'} this workflow?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const newStatus = currentStatus === 'Stop' ? 'Play' : 'Stop';
//       await updateWorkflowStatus(workflowId, newStatus);
//       await fetchWorkflows(); // Refresh the workflows to update the UI
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle(`Confirm to ${currentStatus === 'Stop' ? 'Play' : 'Stop'} Workflow`);
//   };  

//   const handleRemoveWorkflow = (workflowId: number) => {
//     setConfirmMessage('Are you sure you want to remove this workflow? This action will delete the included logic and cannot be undone.');
//     setConfirmType('warning');
//     setActionToConfirm(() => async () => {
//       await deleteWorkflow(workflowId);
//       await fetchWorkflows();
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle('Confirm Workflow Removal');
//   };

//   const handleConfirm = async () => {
//     if (typeof actionToConfirm === 'function') {
//       await actionToConfirm(); // Executes the stored action
//     }
//     setShowConfirmAlert(false); // Close the confirmation alert after the action
//   };  

//   // Function to edit a workflow
//   const handleEditWorkflow = (workflowId: number) => {
//     setSelectedWorkflowId(workflowId);  // Store the selected workflowId
//     setIsLogicModalOpen(true);          // Open the modal for editing the workflow
//   };

//   return (
//     <div>
//       <div className="bg-white shadow-md p-6 rounded-lg">
//         <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
//         <div className="mb-4 flex justify-between items-center space-x-2">
//           <div className="flex justify-normal items-center relative w-full">
//             <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
//               <IoMdSearch className="text-2xl text-cyan-800" />
//             </span>
//             <input
//                 type="text"
//                 placeholder="Search by Title or Content"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="border p-2 rounded pl-10 w-full"
//             />
//           </div>
//           <div className="flex justify-end w-full space-x-2">
//             <button
//                 onClick={handleOpenWorkflowModal}
//                 className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//             >
//                 <div className="flex justify-center items-center">
//                     <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
//                 </div>
//             </button>
//             <div className="flex flex-normal items-center">
//               <button
//                   onClick={handleOpenModal}
//                   className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//               >
//                   <div className="flex justify-center items-center">
//                       <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
//                   </div>
//               </button>
//             </div>
//             <div 
//               onClick={handleOpenModal}
//               className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//             >
//               <BsFillInboxesFill />
//             </div>
//           </div>
//         </div>

//         {/* Single Campaign Table Section */}
//         <div className="mb-6">
//           <table className="w-full table-full bg-white text-center rounded text-sm">
//             <thead>
//               <tr className="bg-gray-200 text-cyan-800">
//                 <th className="px-4 py-2 w-auto">id</th>
//                 <th className="px-4 py-2 w-auto">Workflow Name</th>
//                 <th className="px-4 py-2 w-auto">Workflow Status</th>
//                 <th className="px-4 py-2 w-auto">Created Date</th>
//                 <th className="px-4 py-2 w-auto">Click Stats</th>
//                 <th className="px-4 py-2 w-auto">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredWorkflows.length > 0 ? (
//                 filteredWorkflows.map((workflow) => (
//                 <tr key={workflow.id} className="border-b border-x hover:bg-gray-100">

//                   {/* workflow id section */}
//                   <td className="px-4 py-2">{workflow.workflowId}</td>

//                   {/* workflow name section */}
//                   <td className="px-4 py-2">
//                     {editingWorkflowId === workflow.workflowId ? (
//                       <div className="flex text-center items-center">
//                         <input
//                           type="text"
//                           value={editedWorkflowName}
//                           onChange={(e) => setEditedWorkflowName(e.target.value)}
//                           className="border p-1 rounded text-center"
//                         />
//                         <button onClick={() => handleConfirmEditWorkflowName(workflow.workflowId)}>
//                           <FaCheck className="text-cyan-800 ml-2 cursor-pointer" />
//                         </button>
//                         <button onClick={handleCancelEditWorkflowName}>
//                           <FaTimes className="text-rose-500 ml-2 cursor-pointer" />
//                         </button>
//                       </div>
//                     ) : (
//                       <span
//                         onDoubleClick={() => handleWorkflowNameDoubleClick(workflow.workflowId, workflow.workflowName)}
//                         className="cursor-text"
//                       >
//                         {workflow.workflowName}
//                       </span>
//                     )}
//                   </td>

//                   {/* workflow status section */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <button
//                       onClick={() => handleToggleStatus(workflow.workflowId, workflow.workflowStatus)}
//                       className={`flex justify-normal items-center text-sm ${
//                         workflow.workflowStatus === 'Stop' ? 'text-rose-600' : 'text-cyan-800'
//                       }`}
//                     >
//                       {workflow.workflowStatus === 'Stop' ? (
//                         <>
//                           <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
//                           <span>Stop</span>
//                         </>
//                       ) : (
//                         <>
//                           <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
//                           <span>Play</span>
//                         </>
//                       )}
//                     </button>
//                   </td>
//                   <td className="px-4 py-2">{formatDate(workflow.createdAt.toString())}</td>
//                   <td className="px-4 py-2">{workflow.clickedStats}</td>
//                   <td>
//                     <div 
//                       className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//                     >
//                       {/* Edit icon */}
//                       <MdEditSquare 
//                         className="cursor-pointer" 
//                         onClick={() => handleEditWorkflow(workflow.workflowId)} 
//                       />
//                       {/* Remove icon */}
//                       <TbLayoutGridRemove 
//                         className="cursor-pointer" 
//                         onClick={() => handleRemoveWorkflow(workflow.workflowId)} 
//                       />
//                     </div>
//                   </td>
//                 </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-4 py-4 border-dashed border-2">
//                     No campaigns found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
      
//       {/* show alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title={confirmTitle}  // Ensure title is passed here
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={handleConfirm} 
//         />
//       )}

//       {/* Workflow Modal */}
//       {isWorkflowModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={() => setIsWorkflowModalOpen(false)}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
//             <input
//               type="text"
//               value={newWorkflowName}
//               onChange={(e) => setNewWorkflowName(e.target.value)}
//               className="border p-2 rounded w-full mb-4"
//               placeholder="Workflow Name"
//             />
//             <div className="flex justify-end space-x-2">
//               <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
//                 Cancel
//               </button>
//               <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Logic Modal */}
//       {isLogicModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
//             {selectedWorkflowId !== null && (
//               <CampaignLogic
//                 workflowId={selectedWorkflowId} 
//                 workflowName={
//                   workflows.find((wf) => wf.workflowId === selectedWorkflowId)?.workflowName || ''
//                 }
//                 closeWorkflow={() => setIsLogicModalOpen(false)}
//               />
//             )}
//           </div>
//         </div>
//       )}

//       {/* add Mail Content Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseModal}
//                 className=" text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignSetting;

// // update code --> 18 sept 2024 v02
// // src/components/admin/CampaignSetting.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  
// import MailSetting from './MailSetting';
// import CampaignLogic from './CampingLogic';
// // import react icon
// import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
// import { MdPlaylistAddCircle, MdPlayArrow, MdStop, MdEditSquare } from "react-icons/md"; 
// import { BsFillInboxesFill } from "react-icons/bs";
// import { TbLayoutGridRemove } from "react-icons/tb";
// import { FaCheck, FaTimes } from 'react-icons/fa';
// // import custom components
// import ConfirmAlertMessage  from '../alertMessage';

// const CampaignSetting: React.FC = () => {
//   const { 
//     addCampaign, addWorkflow, workflows, 
//     fetchWorkflows, updateWorkflowStatus, deleteWorkflow,
//     } = useEmail();   
//   // table filtering
//   const [searchTerm, setSearchTerm] = useState(''); 
//   // open modal component
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
//   const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
//   const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
//   // State for editing workflow name
//   const [newWorkflowName, setNewWorkflowName] = useState('');
//   const [editingWorkflowId, setEditingWorkflowId] = useState<number | null>(null);
//   const [editedWorkflowName, setEditedWorkflowName] = useState<string>('');
//   // alert message
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);  // State for confirmation alert visibility
//   const [confirmMessage, setConfirmMessage] = useState(''); // State for confirmation message
//   const [confirmTitle, setConfirmTitle] = useState(''); // Add this state to hold the confirmation title
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm'); // State for alert type
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); // State for the action to confirm
  
//   useEffect(() => {
//     fetchWorkflows();
//   }, [fetchWorkflows]);

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const formattedDate = date.toISOString().split('T')[0]; 
//     const options: Intl.DateTimeFormatOptions = {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'Asia/Kuala_Lumpur',
//     };
//     const formattedTime = date.toLocaleString('en-US', options); 
    
//     return `${formattedDate}, ${formattedTime}`;
//   };

//   const handleCreate = (
//     campaign: { 
//       campTitle: string; 
//       campType: string;
//       campList: string
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentButton: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => {
//     addCampaign({ ...campaign });  
//     setIsModalOpen(false); 
//   };
  
//   const handleOpenWorkflowModal = () => {
//     setIsWorkflowModalOpen(true);
//   };

//   const handleAddWorkflow = async () => {
//     try {
//       // Add workflow to the backend
//       const newWorkflow = await addWorkflow({ workflowName: newWorkflowName, workflowStatus: 'Stop' });

//       // Close the modal
//       setIsWorkflowModalOpen(false);

//       // Fetch the updated list of workflows
//       await fetchWorkflows();

//       // Open the logic modal for the newly created workflow
//       setSelectedWorkflowId(newWorkflow.workflowId);
//       setIsLogicModalOpen(true);
//       setNewWorkflowName(''); // Clear the input
//     } catch (error) {
//       console.error('Error adding workflow:', error);
//     }
//   };  

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   // Function to handle double-click on workflow name
//   const handleWorkflowNameDoubleClick = (workflowId: number, currentName: string) => {
//     setEditingWorkflowId(workflowId);
//     setEditedWorkflowName(currentName);
//   };

//   // Function to confirm the edit
//   const handleConfirmEditWorkflowName = async (workflowId: number) => {
//     try {
//       if (!editedWorkflowName.trim()) {
//         alert('Workflow name cannot be empty.');
//         return;
//       }

//       // Update the workflow name in the backend
//       await updateWorkflowStatus(workflowId, 'KeepCurrentStatus', editedWorkflowName.trim());

//       setEditingWorkflowId(null);
//       setEditedWorkflowName('');
//       await fetchWorkflows(); // Refresh the workflows to update the UI
//     } catch (error) {
//       console.error('Error updating workflow name:', error);
//       alert('Failed to update workflow name. Please try again.');
//     }
//   };

//   // Function to cancel the edit
//   const handleCancelEditWorkflowName = () => {
//     setEditingWorkflowId(null);
//     setEditedWorkflowName('');
//   };

//   // <<----- Filtering workflow function
//    const filteredWorkflows = workflows.filter((workflow) =>
//     workflow.workflowType === "Flow" &&  // Only include workflows with type "Flow"
//     (workflow.workflowName ? workflow.workflowName.toLowerCase().includes(searchTerm.toLowerCase()) : false)
//   );
  
//   // <<----- Filtering workflow table action stats
//   const handleToggleStatus = (workflowId: number, currentStatus: string) => {
//     setConfirmMessage(`Do you want to ${currentStatus === 'Stop' ? 'play' : 'stop'} this workflow?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const newStatus = currentStatus === 'Stop' ? 'Play' : 'Stop';
//       await updateWorkflowStatus(workflowId, newStatus);
//       await fetchWorkflows(); // Refresh the workflows to update the UI
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle(`Confirm to ${currentStatus === 'Stop' ? 'Play' : 'Stop'} Workflow`);
//   };  

//   const handleRemoveWorkflow = (workflowId: number) => {
//     setConfirmMessage('Are you sure you want to remove this workflow? This action will delete the included logic and cannot be undone.');
//     setConfirmType('warning');
//     setActionToConfirm(() => async () => {
//       await deleteWorkflow(workflowId);
//       await fetchWorkflows();
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle('Confirm Workflow Removal');
//   };

//   const handleConfirm = async () => {
//     if (typeof actionToConfirm === 'function') {
//       await actionToConfirm(); // Executes the stored action
//     }
//     setShowConfirmAlert(false); // Close the confirmation alert after the action
//   };  

//   // Function to edit a workflow
//   const handleEditWorkflow = (workflowId: number) => {
//     setSelectedWorkflowId(workflowId);  // Store the selected workflowId
//     setIsLogicModalOpen(true);          // Open the modal for editing the workflow
//   };

//   return (
//     <div>
//       <div className="bg-white shadow-md p-6 rounded-lg">
//         <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
//         <div className="mb-4 flex justify-between items-center space-x-2">
//           <div className="flex justify-normal items-center relative w-full">
//             <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
//               <IoMdSearch className="text-2xl text-cyan-800" />
//             </span>
//             <input
//                 type="text"
//                 placeholder="Search by Title or Content"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="border p-2 rounded pl-10 w-full"
//             />
//           </div>
//           <div className="flex justify-end w-full space-x-2">
//             <button
//                 onClick={handleOpenWorkflowModal}
//                 className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//             >
//                 <div className="flex justify-center items-center">
//                     <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
//                 </div>
//             </button>
//             <div className="flex flex-normal items-center">
//               <button
//                   onClick={handleOpenModal}
//                   className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//               >
//                   <div className="flex justify-center items-center">
//                       <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
//                   </div>
//               </button>
//             </div>
//             <div 
//               onClick={handleOpenModal}
//               className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//             >
//               <BsFillInboxesFill />
//             </div>
//           </div>
//         </div>

//         {/* Single Campaign Table Section */}
//         <div className="mb-6">
//           <table className="w-full table-full bg-white text-center rounded text-sm">
//             <thead>
//               <tr className="bg-gray-200 text-cyan-800">
//                 <th className="px-4 py-2 w-auto">id</th>
//                 <th className="px-4 py-2 w-auto">Workflow Name</th>
//                 <th className="px-4 py-2 w-auto">Workflow Status</th>
//                 <th className="px-4 py-2 w-auto">Created Date</th>
//                 <th className="px-4 py-2 w-auto">Click Stats</th>
//                 <th className="px-4 py-2 w-auto">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredWorkflows.length > 0 ? (
//                 filteredWorkflows.map((workflow) => (
//                 <tr key={workflow.id} className="border-b border-x hover:bg-gray-100">

//                   {/* workflow id section */}
//                   <td className="px-4 py-2">{workflow.workflowId}</td>

//                   {/* workflow name section */}
//                   <td className="px-4 py-2">
//                     {editingWorkflowId === workflow.workflowId ? (
//                       <div className="flex text-center items-center">
//                         <input
//                           type="text"
//                           value={editedWorkflowName}
//                           onChange={(e) => setEditedWorkflowName(e.target.value)}
//                           className="border p-1 rounded text-center"
//                         />
//                         <button onClick={() => handleConfirmEditWorkflowName(workflow.workflowId)}>
//                           <FaCheck className="text-cyan-800 ml-2 cursor-pointer" />
//                         </button>
//                         <button onClick={handleCancelEditWorkflowName}>
//                           <FaTimes className="text-rose-500 ml-2 cursor-pointer" />
//                         </button>
//                       </div>
//                     ) : (
//                       <span
//                         onDoubleClick={() => handleWorkflowNameDoubleClick(workflow.workflowId, workflow.workflowName)}
//                         className="cursor-text"
//                       >
//                         {workflow.workflowName}
//                       </span>
//                     )}
//                   </td>

//                   {/* workflow status section */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <button
//                       onClick={() => handleToggleStatus(workflow.workflowId, workflow.workflowStatus)}
//                       className={`flex justify-normal items-center text-sm ${
//                         workflow.workflowStatus === 'Stop' ? 'text-rose-600' : 'text-cyan-800'
//                       }`}
//                     >
//                       {workflow.workflowStatus === 'Stop' ? (
//                         <>
//                           <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
//                           <span>Stop</span>
//                         </>
//                       ) : (
//                         <>
//                           <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
//                           <span>Play</span>
//                         </>
//                       )}
//                     </button>
//                   </td>
//                   <td className="px-4 py-2">{formatDate(workflow.createdAt.toString())}</td>
//                   <td className="px-4 py-2">{workflow.clickedStats}</td>
//                   <td>
//                     <div 
//                       className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//                     >
//                       {/* Edit icon */}
//                       <MdEditSquare 
//                         className="cursor-pointer" 
//                         onClick={() => handleEditWorkflow(workflow.workflowId)} 
//                       />
//                       {/* Remove icon */}
//                       <TbLayoutGridRemove 
//                         className="cursor-pointer" 
//                         onClick={() => handleRemoveWorkflow(workflow.workflowId)} 
//                       />
//                     </div>
//                   </td>
//                 </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-4 py-4 border-dashed border-2">
//                     No campaigns found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
      
//       {/* show alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title={confirmTitle}  // Ensure title is passed here
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={handleConfirm} 
//         />
//       )}

//       {/* Workflow Modal */}
//       {isWorkflowModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={() => setIsWorkflowModalOpen(false)}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
//             <input
//               type="text"
//               value={newWorkflowName}
//               onChange={(e) => setNewWorkflowName(e.target.value)}
//               className="border p-2 rounded w-full mb-4"
//               placeholder="Workflow Name"
//             />
//             <div className="flex justify-end space-x-2">
//               <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
//                 Cancel
//               </button>
//               <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Logic Modal */}
//       {isLogicModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
//             {selectedWorkflowId !== null && (
//               <CampaignLogic
//                 workflowId={selectedWorkflowId} 
//                 workflowName={
//                   workflows.find((wf) => wf.workflowId === selectedWorkflowId)?.workflowName || ''
//                 }
//                 closeWorkflow={() => setIsLogicModalOpen(false)}
//               />
//             )}
//           </div>
//         </div>
//       )}

//       {/* add Mail Content Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseModal}
//                 className=" text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignSetting;

// // update code --> 18 Sept 2024
// // src/components/admin/CampaignSetting.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  
// import MailSetting from './MailSetting';
// import CampaignLogic from './CampingLogic';
// // import react icon
// import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
// import { MdPlaylistAddCircle, MdPlayArrow, MdStop, MdEditSquare } from "react-icons/md"; 
// import { BsFillInboxesFill } from "react-icons/bs";
// import { TbLayoutGridRemove } from "react-icons/tb";
// // import custom components
// import ConfirmAlertMessage  from '../alertMessage';

// const CampaignSetting: React.FC = () => {
//   const { 
//     addCampaign, addWorkflow, workflows, 
//     fetchWorkflows, updateWorkflowStatus, deleteWorkflow,
//     } = useEmail();   
//   // table filtering
//   const [searchTerm, setSearchTerm] = useState(''); 
//   // open modal component
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
//   const [workflowName, setWorkflowName] = useState(''); 
//   const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
//   const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(null);
//   // alert message
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);  // State for confirmation alert visibility
//   const [confirmMessage, setConfirmMessage] = useState(''); // State for confirmation message
//   const [confirmTitle, setConfirmTitle] = useState(''); // Add this state to hold the confirmation title
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm'); // State for alert type
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); // State for the action to confirm
  
//   useEffect(() => {
//     fetchWorkflows();
//   }, [fetchWorkflows]);

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const formattedDate = date.toISOString().split('T')[0]; 
//     const options: Intl.DateTimeFormatOptions = {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'Asia/Kuala_Lumpur',
//     };
//     const formattedTime = date.toLocaleString('en-US', options); 
    
//     return `${formattedDate}, ${formattedTime}`;
//   };

//   const handleCreate = (
//     campaign: { 
//       campTitle: string; 
//       campType: string;
//       campList: string
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentButton: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => {
//     addCampaign({ ...campaign });  
//     setIsModalOpen(false); 
//   };
  
//   const handleOpenWorkflowModal = () => {
//     setIsWorkflowModalOpen(true);
//   };

//   const handleAddWorkflow = async () => {
//     try {
//       // Add workflow to the backend
//       const newWorkflow = await addWorkflow({ workflowName, workflowStatus: 'Stop' });
      
//       // Close the modal
//       setIsWorkflowModalOpen(false);
  
//       // Fetch the updated list of workflows
//       await fetchWorkflows();
      
//       // open the create name for workflow table modal for the newly created workflow
//       setSelectedWorkflowId(newWorkflow.workflowId);
//       setIsLogicModalOpen(false);
//     } catch (error) {
//       console.error('Error adding workflow:', error);
//     }
//   };  

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   // <<----- Filtering workflow function
//    const filteredWorkflows = workflows.filter((workflow) =>
//     workflow.workflowType === "Flow" &&  // Only include workflows with type "Flow"
//     (workflow.workflowName ? workflow.workflowName.toLowerCase().includes(searchTerm.toLowerCase()) : false)
//   );
  
//   // <<----- Filtering workflow table action stats
//   const handleToggleStatus = (workflowId: number, currentStatus: string) => {
//     setConfirmMessage(`Do you want to ${currentStatus === 'Stop' ? 'play' : 'stop'} this workflow?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const newStatus = currentStatus === 'Stop' ? 'Play' : 'Stop';
//       await updateWorkflowStatus(workflowId, newStatus);
//       await fetchWorkflows(); // Refresh the workflows to update the UI
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle(`Confirm to ${currentStatus === 'Stop' ? 'Play' : 'Stop'} Workflow`);
//   };  

//   const handleRemoveWorkflow = (workflowId: number) => {
//     setConfirmMessage('Are you sure you want to remove this workflow? This action will delete the included logic and cannot be undone.');
//     setConfirmType('warning');
//     setActionToConfirm(() => async () => {
//       await deleteWorkflow(workflowId);
//       await fetchWorkflows();
//     });
//     setShowConfirmAlert(true);
//     setConfirmTitle('Confirm Workflow Removal');
//   };

//   const handleConfirm = async () => {
//     if (typeof actionToConfirm === 'function') {
//       await actionToConfirm(); // Executes the stored action
//     }
//     setShowConfirmAlert(false); // Close the confirmation alert after the action
//   };  

//   // Function to edit a workflow
//   const handleEditWorkflow = (workflowId: number, workflowName: string) => {
//     setSelectedWorkflowId(workflowId);  // Store the selected workflowId
//     setWorkflowName(workflowName);      // Store the workflowName
//     setIsLogicModalOpen(true);          // Open the modal for editing the workflow
//   };

//   return (
//     <div>
//       <div className="bg-white shadow-md p-6 rounded-lg">
//         <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
//         <div className="mb-4 flex justify-between items-center space-x-2">
//           <div className="flex justify-normal items-center relative w-full">
//             <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
//               <IoMdSearch className="text-2xl text-cyan-800" />
//             </span>
//             <input
//                 type="text"
//                 placeholder="Search by Title or Content"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="border p-2 rounded pl-10 w-full"
//             />
//           </div>
//           <div className="flex justify-end w-full space-x-2">
//             <button
//                 onClick={handleOpenWorkflowModal}
//                 className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//             >
//                 <div className="flex justify-center items-center">
//                     <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
//                 </div>
//             </button>
//             <div className="flex flex-normal items-center">
//               <button
//                   onClick={handleOpenModal}
//                   className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//               >
//                   <div className="flex justify-center items-center">
//                       <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
//                   </div>
//               </button>
//             </div>
//             <div 
//               onClick={handleOpenModal}
//               className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//             >
//               <BsFillInboxesFill />
//             </div>
//           </div>
//         </div>

//         {/* Single Campaign Table Section */}
//         <div className="mb-6">
//           <table className="w-full table-full bg-white text-center rounded text-sm">
//             <thead>
//               <tr className="bg-gray-200 text-cyan-800">
//                 <th className="px-4 py-2 w-auto">id</th>
//                 <th className="px-4 py-2 w-auto">Workflow Name</th>
//                 <th className="px-4 py-2 w-auto">Workflow Status</th>
//                 <th className="px-4 py-2 w-auto">Created Date</th>
//                 <th className="px-4 py-2 w-auto">Click Stats</th>
//                 <th className="px-4 py-2 w-auto">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredWorkflows.length > 0 ? (
//                 filteredWorkflows.map((workflow) => (
//                 <tr key={workflow.id} className="border-b border-x hover:bg-gray-100">

//                   {/* workflow id section */}
//                   <td className="px-4 py-2">{workflow.workflowId}</td>

//                   {/* workflow name section */}
//                   <td className="px-4 py-2">{workflow.workflowName}</td>

//                   {/* workflow status section */}
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <button
//                       onClick={() => handleToggleStatus(workflow.workflowId, workflow.workflowStatus)}
//                       className={`flex justify-normal items-center text-sm ${
//                         workflow.workflowStatus === 'Stop' ? 'text-rose-600' : 'text-cyan-800'
//                       }`}
//                     >
//                       {workflow.workflowStatus === 'Stop' ? (
//                         <>
//                           <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
//                           <span>Stop</span>
//                         </>
//                       ) : (
//                         <>
//                           <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
//                           <span>Play</span>
//                         </>
//                       )}
//                     </button>
//                   </td>
//                   <td className="px-4 py-2">{formatDate(workflow.createdAt.toString())}</td>
//                   <td className="px-4 py-2">{workflow.clickedStats}</td>
//                   <td>
//                     <div 
//                       className="flex justify-center items-center text-base text-cyan-800 space-x-2"
//                     >
//                       {/* Edit icon */}
//                       <MdEditSquare 
//                         className="cursor-pointer" 
//                         onClick={() => handleEditWorkflow(workflow.workflowId, workflow.workflowName)} 
//                       />
//                       {/* Remove icon */}
//                       <TbLayoutGridRemove 
//                         className="cursor-pointer" 
//                         onClick={() => handleRemoveWorkflow(workflow.workflowId)} 
//                       />
//                     </div>
//                   </td>
//                 </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-4 py-4 border-dashed border-2">
//                     No campaigns found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
      
//       {/* show alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title={confirmTitle}  // Ensure title is passed here
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={handleConfirm} 
//         />
//       )}

//       {/* Workflow Modal */}
//       {isWorkflowModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={() => setIsWorkflowModalOpen(false)}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
//             <input
//               type="text"
//               value={workflowName}
//               onChange={(e) => setWorkflowName(e.target.value)}
//               className="border p-2 rounded w-full mb-4"
//               placeholder="Workflow Name"
//             />
//             <div className="flex justify-end space-x-2">
//               <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
//                 Cancel
//               </button>
//               <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Logic Modal */}
//       {isLogicModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
//             {selectedWorkflowId !== null && (
//               <CampaignLogic
//                 workflowId={selectedWorkflowId} 
//                 workflowName={workflowName}
//                 closeWorkflow={() => setIsLogicModalOpen(false)}
//               />
//             )}
//           </div>
//         </div>
//       )}

//       {/* add Mail Content Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseModal}
//                 className=" text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignSetting;