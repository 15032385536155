// src/hooks/useTradingStats.tsx

import { useState, useEffect } from 'react';
import { useEnv } from '../context/EnvContext';

export interface TradingStats {
  symbol: string;
  ticket: string;
  open_time: string;
  close_time: string;
  login: number;
  swap: number;
  volume: number;
  totalLotSize: number;
  sl: number;
  commission: number;
  comment: string;
  open_price: number;
  tp: number;
  profit: number;
  close_price: number;
  cmd: string;
}

export interface ProfitHistory {
  time: string;
  profit: number;
  growth: number;
}

export interface TradingAccount {
  email: string;
  leverage: string;
  balance: number;
  name: string;
  login: number;
  equity: number;
  currency: string;
  credit: number;
  profit?: number;   
  growth?: number;      
  initial?: number;
  overallRating?: string; 
}

const useTradingStats = (email: string) => {
  const { apiUrl } = useEnv();
  const [tradingStats, setTradingStats] = useState<TradingStats[] | null>(null);
  const [tradingAccount, setTradingAccount] = useState<TradingAccount | null>(null);
  const [profitHistory, setProfitHistory] = useState<ProfitHistory[] | null>(null);
  const [totalLotSize, setTotalLotSize] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!email) return;

    const fetchTradingData = async () => {
      setLoading(true);
      setTradingStats(null);   
      setTradingAccount(null);
      setProfitHistory(null);   
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/trading-stats?email=${email}`);
        if (!response.ok) {
          throw new Error('Failed to fetch trading data');
        }
        const data = await response.json();
        setTradingAccount(data.tradingAccount);
        setTradingStats(data.tradingStats); 
        setProfitHistory(data.profitHistory);     
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchTotalLotSize = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/user-lotsize?email=${email}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user lot size');
        }
        const data = await response.json();
        setTotalLotSize(data.totalLotSize); // Set the lot size to state
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      }
    };

    fetchTradingData();
    fetchTotalLotSize();
  }, [email, apiUrl]);

  return { tradingStats, tradingAccount, totalLotSize, profitHistory, loading, error };
};

export default useTradingStats;


// // udpate code --> 25 oct 2024 v02
// // src/hooks/useTradingStats.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../context/EnvContext';

// export interface TradingStats {
//   symbol: string;
//   ticket: string;
//   open_time: string;
//   close_time: string;
//   login: number;
//   swap: number;
//   volume: number;
//   sl: number;
//   commission: number;
//   comment: string;
//   open_price: number;
//   tp: number;
//   profit: number;
//   close_price: number;
//   cmd: string;
// }

// export interface TradingAccount {
//   email: string;
//   leverage: string;
//   balance: number;
//   name: string;
//   login: number;
//   equity: number;
//   currency: string;
//   credit: number;
// }

// const useTradingStats = (email: string) => {
//   const { apiUrl } = useEnv();
//   const [tradingStats, setTradingStats] = useState<TradingStats[] | null>(null);
//   const [tradingAccount, setTradingAccount] = useState<TradingAccount | null>(null);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchTradingData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(`${apiUrl}/api/trading-stats?email=${email}`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch trading data');
//         }
//         const data = await response.json();
//         setTradingAccount(data.tradingAccount);
//         setTradingStats(data.tradingStats);     
//       } catch (err) {
//         if (err instanceof Error) {
//           setError(err.message);
//         } else {
//           setError('An unknown error occurred');
//         }
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (email) {
//       fetchTradingData();
//     }
//   }, [email, apiUrl]);

//   return { tradingStats, tradingAccount, loading, error };
// };

// export default useTradingStats;


// // udpate code --> 25 oct 2024
// // hooks/AdminDashboard/useTradingStats.ts

// import { useState, useEffect } from 'react';
// import { useEnv } from '../context/EnvContext';

// export interface TradingStats {
//   fullName: string;
//   email: string;
//   symbol: string;
//   ticket: string;
//   open_time: string;
//   close_time: string;
//   login: number;
//   swap: number;
//   volume: number;
//   sl: number;
//   commission: number;
//   comment: string;
//   open_price: number;
//   tp: number;
//   profit: number;
//   close_price: number;
//   cmd: string;
// }

// const useTradingStats = (email: string) => {
//   const { apiUrl } = useEnv();
//   const [tradingStats, setTradingStats] = useState<TradingStats[] | null>(null);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchTradingStats = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(`${apiUrl}/api/trading-stats?email=${email}`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch trading stats');
//         }
//         const data = await response.json();
//         setTradingStats(data);
//       } catch (err) {
//         // Use type assertion for proper error handling
//         if (err instanceof Error) {
//           setError(err.message);
//         } else {
//           setError('An unknown error occurred');
//         }
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (email) {
//       fetchTradingStats();
//     }
//   }, [email, apiUrl]);

//   return { tradingStats, loading, error };
// };

// export default useTradingStats;