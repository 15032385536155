// src/components/admin/MarketSetting/CampaignLogic.tsx

// import react components
import React, { useState, useEffect } from 'react';
// import react icon
import { LuWorkflow, LuListRestart } from "react-icons/lu";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { AiFillDelete, AiFillCloseSquare } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { FaSquareCheck } from "react-icons/fa6";
import { BiSolidAlarmAdd } from "react-icons/bi";
import { MdLibraryAddCheck, MdUpdateDisabled, MdRestartAlt } from "react-icons/md";
// import custom hook file
import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// import custom components file
import ConfirmAlertMessage from '../alertMessage';

// Interface definitions
interface Schedule {
  workflowType: string;
  logicId: string;
  scheduleName: string;
  emailContent: string;
  logicType: string;
  scheduleDate: string;
  scheduleFlag: number;
  period: number;
  nextRun: string;
  clickCount: number;
  logicStatus: string;
}

const formatDate = (date: string) => {
  // Check directly if date string is exactly the "1979-01-01 12:00:00"
  if (date === '1979-01-01 12:00:00' || date.startsWith('1979-01-01')) {
    return 'No Apply';
  }

  const d = new Date(date);
  
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  const hours = d.getHours() % 12 || 12;
  const minutes = String(d.getMinutes()).padStart(2, '0');
  const seconds = String(d.getSeconds()).padStart(2, '0');
  const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
};


const CampaignLogic: React.FC<{ workflowId: number; workflowName: string; closeWorkflow: () => void }> = ({ workflowId, workflowName, closeWorkflow }) => {
  const { 
    email: campaigns, fetchCampaigns, 
    fetchWorkflows, 
    fetchLogics, addLogic, updateLogic, deleteLogics,
    sendTestEmail, scheduleLogicEmails  
  } = useEmail();
  // State for schedules
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  // State for search
  const [searchTerm, setSearchTerm] = useState('');
  // State for Edit Mode
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedSchedule, setEditedSchedule] = useState<Partial<Schedule>>({});
  // State for Multi-Select Mode
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  // state for alert message 
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm');
  const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); 

  useEffect(() => {
    const fetchInitialLogics = async () => {
      if (!workflowId) return;
      try {
        await fetchCampaigns();
        await fetchWorkflows();
        // Fetch logic data for the specific workflowId
        const logicData = await fetchLogics(workflowId);
  
        // Filter to include only logic with flowType "Logic"
        const filteredData = logicData.filter((logic: Schedule) => logic.workflowType === "Logic");
  
        // Update state with filtered logic rows
        setSchedules(filteredData);
      } catch (error) {
        console.error('Failed to fetch logic data');
      }
    };
    fetchInitialLogics();
  }, [fetchCampaigns, fetchWorkflows, workflowId, fetchLogics]);   

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(schedules.map(schedule => schedule.logicId)); // Select all rows
    }
    setSelectAll(!selectAll); // Toggle select all state
  };  

  // Add appropriate error handling for add logic
  const handleAddScheduleDirectly = async () => {
    if (!workflowId) return;
  
    const newLogic = {
      scheduleName: 'New ScheduleName',
      emailContent: 'Select an Email Content',
      logicType: 'Choose a Type',
      scheduleDate: '1979-01-01 12:00:00',
      scheduleFlag: 1,
      period: 0,
      nextRun: 'Select a Next Logic',
      clickCount: 0,
      logicStatus: 'Off',
    };
  
    try {
      const addedLogic = await addLogic(workflowId, newLogic);
  
      // Immediately update schedules with the new logic
      setSchedules(prevSchedules => [...prevSchedules, addedLogic]);
  
      // Fetch updated logic rows and filter again
      const updatedLogics = await fetchLogics(workflowId);
      const filteredLogics = updatedLogics.filter((logic: Schedule) => logic.workflowType === "Logic");
  
      setSchedules(filteredLogics);
    } catch (error) {
      console.error('Failed to add logic');
    }
  };  

  // Handler to toggle active status
  const handleToggleActive = async (id: string, currentStatus: string) => {
    try {
      // Toggle logicStatus between 'On' and 'Off'
      const newStatus = currentStatus === 'On' ? 'Off' : 'On';

      // Update the status in the database
      await updateLogic(workflowId, id, { logicStatus: newStatus });

      // Find the full schedule object by logicId
      const logicToRun = schedules.find((schedule) => schedule.logicId === id);

      // If the new status is 'On' and the logic is found, schedule the emails
      if (newStatus === 'On' && logicToRun) {
        console.log(`Scheduling emails for logic: ${logicToRun.scheduleName}`);
        
        // Ensure logicToRun has the workflowId before passing it to the function
        await scheduleLogicEmails(
          { ...logicToRun, workflowId }, 
          schedules.map(schedule => ({ ...schedule, workflowId })) // Ensure each schedule has workflowId
        );
      }
      // Update the local state to reflect the change
      setSchedules(schedules.map(schedule =>
        schedule.logicId === id ? { ...schedule, logicStatus: newStatus } : schedule
      ));
    } catch (error) {
      console.error('Failed to update logic status:', error);
    }
  };

  // Handler for double-click to enter or exit edit mode
  const handleRowDoubleClick = (schedule: Schedule) => {
    // Only enter edit mode if not already editing this row
    if (editingId !== schedule.logicId) {
      // Set the editing ID to the current logic row
      setEditingId(schedule.logicId);
  
      // Copy the current schedule data into the editedSchedule state
      setEditedSchedule({
        scheduleName: schedule.scheduleName,
        emailContent: schedule.emailContent,
        logicType: schedule.logicType,
        scheduleDate: schedule.scheduleDate === 'No Apply' ? 'No Apply' : schedule.scheduleDate,
        scheduleFlag: schedule.scheduleFlag,
        period: schedule.period,
        nextRun: schedule.nextRun,
      });
    }
  };  

  // Handler to confirm edit
  const handleConfirmEdit = async (logicId: string) => {
    const finalScheduleDate = editedSchedule.scheduleDate === "No Apply"
      ? "1979-01-01 12:00:00"
      : editedSchedule.scheduleDate;
  
    // Validate the input fields before updating
    if (
      editedSchedule.scheduleName &&
      editedSchedule.emailContent &&
      editedSchedule.logicType &&
      finalScheduleDate &&
      editedSchedule.scheduleFlag &&
      editedSchedule.period !== undefined &&
      editedSchedule.nextRun
    ) {
      try {
        // Update the logic in the backend
        const updatedLogic = await updateLogic(workflowId, logicId, {
          ...editedSchedule,
          scheduleDate: finalScheduleDate,
        });
  
        // Update the state with the saved logic
        setSchedules((prevSchedules) =>
          prevSchedules.map((schedule) =>
            schedule.logicId === logicId ? { ...schedule, ...updatedLogic } : schedule
          )
        );
  
        // Exit edit mode
        setEditingId(null);
        setEditedSchedule({});
      } catch (error) {
        console.error('Failed to update logic:', error);
      }
    } else {
      alert('Please fill in all fields.');
    }
  };  

  // Handler to cancel edit
  const handleCancelEdit = () => {
    setEditingId(null);
    setEditedSchedule({});
  };

  // Handler to delete selected rows
  const handleDeleteSelectedRows = async () => {
    if (selectedRows.length > 0) {
      setConfirmMessage('Are you sure you want to delete the selected logic rows? This action cannot be undone.');
      setConfirmType('warning');
      setActionToConfirm(() => async () => {
        try {
          await deleteLogics(workflowId, selectedRows);
          setSchedules(schedules.filter(schedule => !selectedRows.includes(schedule.logicId)));
          setSelectedRows([]);
        } catch (error) {
          alert('Failed to delete logic rows');
        }
      });
      setShowConfirmAlert(true); // Show the confirmation dialog
    } else {
      alert('No schedules selected for deletion.');
    }
  };

  // Filtered schedules based on search term
  const filteredSchedules = schedules.filter((schedule) =>
    schedule.scheduleName ? schedule.scheduleName.toLowerCase().includes(searchTerm.toLowerCase()) : false
  );  

   // Function to send test email for the selected logic row
   const handleSendTestEmail = (logicId: string) => {
    // Find the schedule corresponding to the logicId
    const selectedSchedule = schedules.find(schedule => schedule.logicId === logicId);
  
    if (!selectedSchedule) {
      setConfirmMessage('No email content found for this logic.');
      setConfirmType('error');
      setShowConfirmAlert(true);
      return;
    }
  
    // Show confirmation alert before sending the test email
    setConfirmMessage(`You are testing to send a ${selectedSchedule.emailContent} content. Do you want to proceed?`);
    setConfirmType('confirm');
    setActionToConfirm(() => async () => {
      try {
        // Attempt to send the test email
        await sendTestEmail(logicId);
        // If successful, show success message
        setConfirmMessage(`${selectedSchedule.emailContent} content sent successfully`);
        setConfirmType('success');
      } catch (error) {
        // If failed, show error message
        setConfirmMessage(`Failed to send ${selectedSchedule.emailContent} content`);
        setConfirmType('error');
      }
      setShowConfirmAlert(true); // Display the result in an alert
    });
    setShowConfirmAlert(true); // Show the confirmation alert
  };   

  const handleResetAllFlags = async () => {
    try {
      // Update the scheduleFlag for all logic rows to 1 in the backend
      const updatedLogics = await Promise.all(
        schedules.map(async (schedule) => {
          await updateLogic(workflowId, schedule.logicId, { scheduleFlag: 1 });
          return { ...schedule, scheduleFlag: 1 }; // Set local state as well
        })
      );
      
      setSchedules(updatedLogics); // Update the state with reset flags
    } catch (error) {
      console.error('Failed to reset all schedule flags:', error);
    }
  };  

  return (
    <div className="mb-4 p-4">
      {/* Workflow Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <LuWorkflow className="text-cyan-800 text-xl mr-2 cursor-pointer"/>
          <h3 className="text-xl font-semibold text-cyan-800">Workflow Logic: {workflowName}</h3>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="text-cyan-800 text-xl p-2"
            onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
          >
            <MdLibraryAddCheck />
          </button>
          <button
            className="text-cyan-800 text-xl p-2"
            onClick={() => {
              if (selectedRows.length === 1) {
                handleSendTestEmail(selectedRows[0]); // Send email for the selected row
              } else {
                alert('Please select one logic row to send the test email');
              }
            }}
          >
            <FaTelegramPlane />
          </button>
          <button
            className="text-cyan-800 text-xl p-2"
            onClick={handleResetAllFlags}
          >
            <LuListRestart />
          </button>
          <input
            type="text"
            placeholder="Search schedules..."
            className="px-4 py-2 border rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="text-cyan-800 text-xl p-2"
            onClick={handleAddScheduleDirectly}
          >
            <BiSolidAlarmAdd />
          </button>
          <button
            className="text-cyan-800 text-xl p-2"
            onClick={handleDeleteSelectedRows}
          >
            <AiFillDelete />
          </button>
        </div>
      </div>

      {/* Schedules Table */}
      <div className="overflow-x-auto">
        <table className="w-[1200px] bg-white border text-sm">
          <thead>
            <tr className="bg-gray-200 text-cyan-800 font-semibold">
              {/* Select All Checkbox */}
              {isMultiSelectEnabled && (
                <th className="py-2 px-4 border-b accent-cyan-800">
                  <input 
                    type="checkbox" 
                    checked={selectAll} 
                    onChange={handleSelectAll} 
                  />
                </th>
              )}
              <th className="py-2 px-4 border-b">Schedules Name</th>
              <th className="py-2 px-4 border-b">Email Content</th>
              <th className="py-2 px-4 border-b">Type</th>
              <th className="py-2 px-4 border-b">Schedule Date</th>
              <th className="py-2 px-4 border-b">Schedule Flag</th>
              <th className="py-2 px-4 border-b">Period (Days)</th>
              <th className="py-2 px-4 border-b">Next Run</th>
              <th className="py-2 px-4 border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredSchedules.length > 0 ? (
              filteredSchedules.map((schedule) => (
                <tr 
                  key={schedule.logicId} 
                  className="text-center text-xs accent-cyan-800 hover:bg-gray-50 cursor-pointer border-b"
                  onClick={(e) => {
                    if (isMultiSelectEnabled) {
                      if (selectedRows.includes(schedule.logicId)) {
                        setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
                      } else {
                        setSelectedRows([...selectedRows, schedule.logicId]);
                      }
                    }
                  }}
                  onDoubleClick={() => handleRowDoubleClick(schedule)}
                >
                  {isMultiSelectEnabled && (
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(schedule.logicId)}
                        onChange={() => {
                          if (selectedRows.includes(schedule.logicId)) {
                            setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
                          } else {
                            setSelectedRows([...selectedRows, schedule.logicId]);
                          }
                        }}
                      />
                    </td>
                  )}

                  {/* Workflow Logic Name */}
                  <td className="py-2 px-4">
                    {editingId === schedule.logicId ? (
                      <input
                        type="text"
                        value={editedSchedule.scheduleName}
                        onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleName: e.target.value })}
                        className="border rounded px-2 py-1 w-full text-center"
                      />
                    ) : (
                      schedule.scheduleName
                    )}
                  </td>

                  {/* Workflow Logic Email Content */}
                  <td className="py-2 px-4">
                    {editingId === schedule.logicId ? (
                      <select
                        value={editedSchedule.emailContent}
                        onChange={(e) => setEditedSchedule({ ...editedSchedule, emailContent: e.target.value })}
                        className="border rounded px-2 py-1 w-full text-center"
                      >
                        <option value="">Select an Email Content</option>
                        {campaigns && campaigns.map((campaign) => (
                          <option key={campaign.id} value={campaign.campTitle}>
                            {campaign.campTitle}
                          </option>
                        ))}
                      </select>
                    ) : (
                      schedule.emailContent
                    )}
                  </td>

                  {/* Workflow Logic Type */}
                  <td className="py-2 px-4">
                    {editingId === schedule.logicId ? (
                      <select
                        value={editedSchedule.logicType}
                        onChange={(e) => setEditedSchedule({ ...editedSchedule, logicType: e.target.value })}
                        className="border rounded px-2 py-1 w-full text-center"
                      >
                        <option value="">Choose a Type</option>
                        <option value="Started">Started</option>
                        <option value="Follow-Up">Follow-Up</option>
                        <option value="Ended">Ended</option>
                      </select>
                    ) : (
                      schedule.logicType
                    )}
                  </td>

                  {/* Workflow Logic Schedule Date */}
                  <td className="py-2 px-4">
                    {editingId === schedule.logicId ? (
                      <>
                        {editedSchedule.scheduleDate === 'No Apply' ? (
                          <select
                            value={editedSchedule.scheduleDate}
                            onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
                            className="border rounded px-2 py-1 w-full"
                          >
                            <option value="1979-01-01 12:00:00">No Apply</option>
                            <option value="">Select Date</option>
                          </select>
                        ) : (
                          <div className="flex justify-normal items-center">
                            <input
                              type="datetime-local"
                              value={editedSchedule.scheduleDate}
                              onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
                              className="border rounded px-2 py-1 w-[160px] text-center"
                            />
                            <div className="relative cursor-help bg-white border-black">
                              <button
                                className="flex justify-normal items-center text-gray-600 text-sm ml-2"
                                onClick={() => setEditedSchedule({ ...editedSchedule, scheduleDate: 'No Apply' })}
                              >
                                <MdUpdateDisabled />
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <span>{schedule.scheduleDate === 'No Apply' ? 'No Apply' : formatDate(schedule.scheduleDate)}</span>
                    )}
                  </td>

                  {/* Workflow Logic Schedule Flag */}
                  <td className="py-2 px-4">
                    {editingId === schedule.logicId ? (
                      <div className="flex items-center justify-center">
                        <input
                          type="number"
                          value={editedSchedule.scheduleFlag} 
                          disabled // Disable manual editing
                          className="border rounded px-2 py-1 w-[100px] text-center"
                        />
                        <button
                          className="ml-2 text-gray-600"
                          onClick={() => setEditedSchedule({ ...editedSchedule, scheduleFlag: 1 })} 
                          title="Reset the schedule flag"
                        >
                          <MdRestartAlt />
                        </button>
                      </div>
                    ) : (
                      schedule.scheduleFlag
                    )}
                  </td>

                  {/* Workflow Logic Period */}
                  <td className="py-2 px-4">
                    {editingId === schedule.logicId ? (
                      <div className="flex items-center justify-center">
                        <input
                          type="number"
                          value={editedSchedule.period}
                          onChange={(e) => setEditedSchedule({ ...editedSchedule, period: Number(e.target.value) })}
                          className="border rounded px-2 py-1 w-[100px] text-center"
                        />
                        <button
                          className="ml-2 text-gray-600"
                          onClick={() => setEditedSchedule({ ...editedSchedule, period: -1 })} // Autofill -1 for No Apply
                          title="Set Period to No Apply (-1)"
                        >
                          <MdUpdateDisabled />
                        </button>
                      </div>
                    ) : (
                      <span>{schedule.period === -1 ? 'No Apply' : schedule.period}</span>
                    )}
                  </td>

                  {/* Workflow Logic Next Run */}
                  <td className="py-2 px-4">
                    {editingId === schedule.logicId ? (
                      <select
                        value={editedSchedule.nextRun || ''}  // Ensure the value is properly selected
                        onChange={(e) => setEditedSchedule({ ...editedSchedule, nextRun: e.target.value })}
                        className="border rounded px-2 py-1 w-full text-center"
                      >
                        <option value="">Select Next Schedule Name</option>
                        <option value="Stop Next Run">Stop Next Run</option>
                        {schedules.length > 0 ? (
                          schedules
                            .filter((s) => s.logicId !== schedule.logicId) // Exclude the current schedule from being its own nextRun
                            .map((s) => (
                              <option key={s.logicId} value={s.scheduleName}>
                                {s.scheduleName}
                              </option>
                            ))
                        ) : (
                          <option disabled>No schedules available</option> 
                        )}
                      </select>
                    ) : (
                      schedule.nextRun
                    )}
                  </td>

                  {/* Workflow Logic Action */}
                  <td className="py-4 px-4 flex justify-center items-center space-x-1">
                    {editingId === schedule.logicId ? (
                      <>
                        <button
                          className="text-cyan-800 text-lg p-1"
                          onClick={() => handleConfirmEdit(schedule.logicId)}
                        >
                          <FaSquareCheck />
                        </button>
                        <button
                          className="text-gray-400 text-lg p-1"
                          onClick={handleCancelEdit}
                        >
                          <AiFillCloseSquare />
                        </button>
                      </>
                    ) : (
                      <>
                        <span 
                          className="flex items-center space-x-1 cursor-pointer" 
                          onClick={() => handleToggleActive(schedule.logicId, schedule.logicStatus)}
                        >
                          {schedule.logicStatus === 'On' ? (
                            <BsToggleOn className="text-cyan-800 text-2xl" />
                          ) : (
                            <BsToggleOff className="text-gray-400 text-2xl" />
                          )}
                          <span className="text-sm text-gray-700">{schedule.logicStatus === 'On' ? 'On' : 'Off'}</span>
                        </span>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="py-8 text-center bg-white">
                  <span className="text-cyan-800 border-cyan-800 border-opacity-60 border font-semibold text-sm px-4 py-1 rounded-full">
                    No schedules found
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {/* show confirm alert message */}
      {showConfirmAlert && (
        <ConfirmAlertMessage
          type={confirmType}
          title="Confirm Action"
          message={confirmMessage}
          visible={showConfirmAlert}
          onClose={() => setShowConfirmAlert(false)}
          onConfirm={async () => {
            await actionToConfirm();  
            setShowConfirmAlert(false);
          }}
        />
      )}

      {/* Workflow Controls */}
      <div className="flex justify-end items-center space-x-4 mt-4">
        <button
          className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full"
          onClick={closeWorkflow}
        >
          Close Workflow
        </button>
      </div>
    </div>
  );
};

export default CampaignLogic;

// // update code --> 19 sept 2024 v02
// // src/components/admin/MarketSetting/CampaignLogic.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// // import react icon
// import { LuWorkflow } from "react-icons/lu";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { AiFillDelete, AiFillCloseSquare } from "react-icons/ai";
// import { FaTelegramPlane } from "react-icons/fa";
// import { FaSquareCheck } from "react-icons/fa6";
// import { BiSolidAlarmAdd } from "react-icons/bi";
// import { MdLibraryAddCheck, MdUpdateDisabled } from "react-icons/md";
// // import custom hook file
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// // import custom components file
// import ConfirmAlertMessage from '../alertMessage';

// // Interface definitions
// interface Schedule {
//   workflowType: string;
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// const formatDate = (date: string) => {
//   // Check directly if date string is exactly the "1979-01-01 12:00:00"
//   if (date === '1979-01-01 12:00:00' || date.startsWith('1979-01-01')) {
//     return 'No Apply';
//   }

//   const d = new Date(date);
  
//   const year = d.getFullYear();
//   const month = String(d.getMonth() + 1).padStart(2, '0');
//   const day = String(d.getDate()).padStart(2, '0');
//   const hours = d.getHours() % 12 || 12;
//   const minutes = String(d.getMinutes()).padStart(2, '0');
//   const seconds = String(d.getSeconds()).padStart(2, '0');
//   const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
// };


// const CampaignLogic: React.FC<{ workflowId: number; workflowName: string; closeWorkflow: () => void }> = ({ workflowId, workflowName, closeWorkflow }) => {
//   const { 
//     email: campaigns, fetchCampaigns, 
//     fetchWorkflows, 
//     fetchLogics, addLogic, updateLogic, deleteLogics,
//     sendTestEmail, scheduleLogicEmails  
//   } = useEmail();
//   // State for schedules
//   const [schedules, setSchedules] = useState<Schedule[]>([]);
//   // State for search
//   const [searchTerm, setSearchTerm] = useState('');
//   // State for Edit Mode
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [editedSchedule, setEditedSchedule] = useState<Partial<Schedule>>({});
//   // State for Multi-Select Mode
//   const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // state for alert message 
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);
//   const [confirmMessage, setConfirmMessage] = useState('');
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm');
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); 

//   useEffect(() => {
//     const fetchInitialLogics = async () => {
//       if (!workflowId) return;
//       try {
//         await fetchCampaigns();
//         await fetchWorkflows();
//         // Fetch logic data for the specific workflowId
//         const logicData = await fetchLogics(workflowId);
  
//         // Filter to include only logic with flowType "Logic"
//         const filteredData = logicData.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//         // Update state with filtered logic rows
//         setSchedules(filteredData);
//       } catch (error) {
//         console.error('Failed to fetch logic data');
//       }
//     };
//     fetchInitialLogics();
//   }, [fetchCampaigns, fetchWorkflows, workflowId, fetchLogics]);   

//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedRows([]); // Deselect all
//     } else {
//       setSelectedRows(schedules.map(schedule => schedule.logicId)); // Select all rows
//     }
//     setSelectAll(!selectAll); // Toggle select all state
//   };  

//   // Add appropriate error handling for add logic
//   const handleAddScheduleDirectly = async () => {
//     if (!workflowId) return;
  
//     const newLogic = {
//       scheduleName: 'New ScheduleName',
//       emailContent: 'Select an Email Content',
//       logicType: 'Choose a Type',
//       scheduleDate: '1979-01-01 12:00:00',
//       period: 0,
//       nextRun: 'Select a Next Logic',
//       clickCount: 0,
//       logicStatus: 'Off',
//     };
  
//     try {
//       const addedLogic = await addLogic(workflowId, newLogic);
  
//       // Immediately update schedules with the new logic
//       setSchedules(prevSchedules => [...prevSchedules, addedLogic]);
  
//       // Fetch updated logic rows and filter again
//       const updatedLogics = await fetchLogics(workflowId);
//       const filteredLogics = updatedLogics.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//       setSchedules(filteredLogics);
//     } catch (error) {
//       console.error('Failed to add logic');
//     }
//   };  

//   // Handler to toggle active status
//   const handleToggleActive = async (id: string, currentStatus: string) => {
//     try {
//       // Toggle logicStatus between 'On' and 'Off'
//       const newStatus = currentStatus === 'On' ? 'Off' : 'On';

//       // Update the status in the database
//       await updateLogic(workflowId, id, { logicStatus: newStatus });

//       // Find the full schedule object by logicId
//       const logicToRun = schedules.find((schedule) => schedule.logicId === id);

//       // If the new status is 'On' and the logic is found, schedule the emails
//       if (newStatus === 'On' && logicToRun) {
//         console.log(`Scheduling emails for logic: ${logicToRun.scheduleName}`);
//         await scheduleLogicEmails(logicToRun, schedules); // Pass the full Schedule object
//       }

//       // Update the local state to reflect the change
//       setSchedules(schedules.map(schedule =>
//         schedule.logicId === id ? { ...schedule, logicStatus: newStatus } : schedule
//       ));
//     } catch (error) {
//       console.error('Failed to update logic status:', error);
//     }
//   };

//   // Handler for double-click to enter or exit edit mode
//   const handleRowDoubleClick = (schedule: Schedule) => {
//     // Only enter edit mode if not already editing this row
//     if (editingId !== schedule.logicId) {
//       // Set the editing ID to the current logic row
//       setEditingId(schedule.logicId);
  
//       // Copy the current schedule data into the editedSchedule state
//       setEditedSchedule({
//         scheduleName: schedule.scheduleName,
//         emailContent: schedule.emailContent,
//         logicType: schedule.logicType,
//         scheduleDate: schedule.scheduleDate === 'No Apply' ? 'No Apply' : schedule.scheduleDate,
//         period: schedule.period,
//         nextRun: schedule.nextRun,
//       });
//     }
//   };  

//   // Handler to confirm edit
//   const handleConfirmEdit = async (logicId: string) => {
//     const finalScheduleDate = editedSchedule.scheduleDate === "No Apply"
//       ? "1979-01-01 12:00:00"
//       : editedSchedule.scheduleDate;
  
//     // Validate the input fields before updating
//     if (
//       editedSchedule.scheduleName &&
//       editedSchedule.emailContent &&
//       editedSchedule.logicType &&
//       finalScheduleDate &&
//       editedSchedule.period !== undefined &&
//       editedSchedule.nextRun
//     ) {
//       try {
//         // Update the logic in the backend
//         const updatedLogic = await updateLogic(workflowId, logicId, {
//           ...editedSchedule,
//           scheduleDate: finalScheduleDate,
//         });
  
//         // Update the state with the saved logic
//         setSchedules((prevSchedules) =>
//           prevSchedules.map((schedule) =>
//             schedule.logicId === logicId ? { ...schedule, ...updatedLogic } : schedule
//           )
//         );
  
//         // Exit edit mode
//         setEditingId(null);
//         setEditedSchedule({});
//       } catch (error) {
//         console.error('Failed to update logic:', error);
//       }
//     } else {
//       alert('Please fill in all fields.');
//     }
//   };  

//   // Handler to cancel edit
//   const handleCancelEdit = () => {
//     setEditingId(null);
//     setEditedSchedule({});
//   };

//   // Handler to delete selected rows
//   const handleDeleteSelectedRows = async () => {
//     if (selectedRows.length > 0) {
//       setConfirmMessage('Are you sure you want to delete the selected logic rows? This action cannot be undone.');
//       setConfirmType('warning');
//       setActionToConfirm(() => async () => {
//         try {
//           await deleteLogics(workflowId, selectedRows);
//           setSchedules(schedules.filter(schedule => !selectedRows.includes(schedule.logicId)));
//           setSelectedRows([]);
//         } catch (error) {
//           alert('Failed to delete logic rows');
//         }
//       });
//       setShowConfirmAlert(true); // Show the confirmation dialog
//     } else {
//       alert('No schedules selected for deletion.');
//     }
//   };

//   // Filtered schedules based on search term
//   const filteredSchedules = schedules.filter((schedule) =>
//     schedule.scheduleName ? schedule.scheduleName.toLowerCase().includes(searchTerm.toLowerCase()) : false
//   );  

//    // Function to send test email for the selected logic row
//    const handleSendTestEmail = (logicId: string) => {
//     // Find the schedule corresponding to the logicId
//     const selectedSchedule = schedules.find(schedule => schedule.logicId === logicId);
  
//     if (!selectedSchedule) {
//       setConfirmMessage('No email content found for this logic.');
//       setConfirmType('error');
//       setShowConfirmAlert(true);
//       return;
//     }
  
//     // Show confirmation alert before sending the test email
//     setConfirmMessage(`You are testing to send a ${selectedSchedule.emailContent} content. Do you want to proceed?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       try {
//         // Attempt to send the test email
//         await sendTestEmail(logicId);
//         // If successful, show success message
//         setConfirmMessage(`${selectedSchedule.emailContent} content sent successfully`);
//         setConfirmType('success');
//       } catch (error) {
//         // If failed, show error message
//         setConfirmMessage(`Failed to send ${selectedSchedule.emailContent} content`);
//         setConfirmType('error');
//       }
//       setShowConfirmAlert(true); // Display the result in an alert
//     });
//     setShowConfirmAlert(true); // Show the confirmation alert
//   };   

//   return (
//     <div className="mb-4 p-4">
//       {/* Workflow Header */}
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex items-center">
//           <LuWorkflow className="text-cyan-800 text-xl mr-2 cursor-pointer"/>
//           <h3 className="text-xl font-semibold text-cyan-800">Workflow Logic: {workflowName}</h3>
//         </div>
//         <div className="flex items-center space-x-2">
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
//           >
//             <MdLibraryAddCheck />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => {
//               if (selectedRows.length === 1) {
//                 handleSendTestEmail(selectedRows[0]); // Send email for the selected row
//               } else {
//                 alert('Please select one logic row to send the test email');
//               }
//             }}
//           >
//             <FaTelegramPlane />
//           </button>
//           <input
//             type="text"
//             placeholder="Search schedules..."
//             className="px-4 py-2 border rounded"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleAddScheduleDirectly}
//           >
//             <BiSolidAlarmAdd />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleDeleteSelectedRows}
//           >
//             <AiFillDelete />
//           </button>
//         </div>
//       </div>

//       {/* Schedules Table */}
//       <div className="overflow-x-auto">
//         <table className="w-[1200px] bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               {/* Select All Checkbox */}
//               {isMultiSelectEnabled && (
//                 <th className="py-2 px-4 border-b accent-cyan-800">
//                   <input 
//                     type="checkbox" 
//                     checked={selectAll} 
//                     onChange={handleSelectAll} 
//                   />
//                 </th>
//               )}
//               <th className="py-2 px-4 border-b">Schedules Name</th>
//               <th className="py-2 px-4 border-b">Email Content</th>
//               <th className="py-2 px-4 border-b">Type</th>
//               <th className="py-2 px-4 border-b">Schedule Date</th>
//               <th className="py-2 px-4 border-b">Period (Days)</th>
//               <th className="py-2 px-4 border-b">Next Run</th>
//               <th className="py-2 px-4 border-b">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredSchedules.length > 0 ? (
//               filteredSchedules.map((schedule) => (
//                 <tr 
//                   key={schedule.logicId} 
//                   className="text-center text-xs accent-cyan-800 hover:bg-gray-50 cursor-pointer border-b"
//                   onClick={(e) => {
//                     if (isMultiSelectEnabled) {
//                       if (selectedRows.includes(schedule.logicId)) {
//                         setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                       } else {
//                         setSelectedRows([...selectedRows, schedule.logicId]);
//                       }
//                     }
//                   }}
//                   onDoubleClick={() => handleRowDoubleClick(schedule)}
//                 >
//                   {isMultiSelectEnabled && (
//                     <td className="py-2 px-4">
//                       <input
//                         type="checkbox"
//                         checked={selectedRows.includes(schedule.logicId)}
//                         onChange={() => {
//                           if (selectedRows.includes(schedule.logicId)) {
//                             setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                           } else {
//                             setSelectedRows([...selectedRows, schedule.logicId]);
//                           }
//                         }}
//                       />
//                     </td>
//                   )}

//                   {/* Workflow Logic Name */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="text"
//                         value={editedSchedule.scheduleName}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleName: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       schedule.scheduleName
//                     )}
//                   </td>

//                   {/* Workflow Logic Email Content */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.emailContent}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, emailContent: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select an Email Content</option>
//                         {campaigns && campaigns.map((campaign) => (
//                           <option key={campaign.id} value={campaign.campTitle}>
//                             {campaign.campTitle}
//                           </option>
//                         ))}
//                       </select>
//                     ) : (
//                       schedule.emailContent
//                     )}
//                   </td>

//                   {/* Workflow Logic Type */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.logicType}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, logicType: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Choose a Type</option>
//                         <option value="Started">Started</option>
//                         <option value="Follow-Up">Follow-Up</option>
//                         <option value="Ended">Ended</option>
//                       </select>
//                     ) : (
//                       schedule.logicType
//                     )}
//                   </td>

//                   {/* Workflow Logic Schedule Date */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         {editedSchedule.scheduleDate === 'No Apply' ? (
//                           <select
//                             value={editedSchedule.scheduleDate}
//                             onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                             className="border rounded px-2 py-1 w-full"
//                           >
//                             <option value="1979-01-01 12:00:00">No Apply</option>
//                             <option value="">Select Date</option>
//                           </select>
//                         ) : (
//                           <div className="flex justify-normal items-center">
//                             <input
//                               type="datetime-local"
//                               value={editedSchedule.scheduleDate}
//                               onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                               className="border rounded px-2 py-1 w-[160px] text-center"
//                             />
//                             <div className="relative cursor-help bg-white border-black">
//                               <button
//                                 className="flex justify-normal items-center text-gray-600 text-sm ml-2"
//                                 onClick={() => setEditedSchedule({ ...editedSchedule, scheduleDate: 'No Apply' })}
//                               >
//                                 <MdUpdateDisabled />
//                               </button>
//                             </div>
//                           </div>
//                         )}
//                       </>
//                     ) : (
//                       <span>{schedule.scheduleDate === 'No Apply' ? 'No Apply' : formatDate(schedule.scheduleDate)}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Period */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <div className="flex items-center justify-center">
//                         <input
//                           type="number"
//                           value={editedSchedule.period}
//                           onChange={(e) => setEditedSchedule({ ...editedSchedule, period: Number(e.target.value) })}
//                           className="border rounded px-2 py-1 w-[100px] text-center"
//                         />
//                         <button
//                           className="ml-2 text-gray-600"
//                           onClick={() => setEditedSchedule({ ...editedSchedule, period: -1 })} // Autofill -1 for No Apply
//                           title="Set Period to No Apply (-1)"
//                         >
//                           <MdUpdateDisabled />
//                         </button>
//                       </div>
//                     ) : (
//                       <span>{schedule.period === -1 ? 'No Apply' : schedule.period}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Next Run */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.nextRun || ''}  // Ensure the value is properly selected
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, nextRun: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select Next Schedule Name</option>
//                         <option value="Stop Next Run">Stop Next Run</option>
//                         {schedules.length > 0 ? (
//                           schedules
//                             .filter((s) => s.logicId !== schedule.logicId) // Exclude the current schedule from being its own nextRun
//                             .map((s) => (
//                               <option key={s.logicId} value={s.scheduleName}>
//                                 {s.scheduleName}
//                               </option>
//                             ))
//                         ) : (
//                           <option disabled>No schedules available</option> 
//                         )}
//                       </select>
//                     ) : (
//                       schedule.nextRun
//                     )}
//                   </td>

//                   {/* Workflow Logic Action */}
//                   <td className="py-4 px-4 flex justify-center items-center space-x-1">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         <button
//                           className="text-cyan-800 text-lg p-1"
//                           onClick={() => handleConfirmEdit(schedule.logicId)}
//                         >
//                           <FaSquareCheck />
//                         </button>
//                         <button
//                           className="text-gray-400 text-lg p-1"
//                           onClick={handleCancelEdit}
//                         >
//                           <AiFillCloseSquare />
//                         </button>
//                       </>
//                     ) : (
//                       <>
//                         <span 
//                           className="flex items-center space-x-1 cursor-pointer" 
//                           onClick={() => handleToggleActive(schedule.logicId, schedule.logicStatus)}
//                         >
//                           {schedule.logicStatus === 'On' ? (
//                             <BsToggleOn className="text-cyan-800 text-2xl" />
//                           ) : (
//                             <BsToggleOff className="text-gray-400 text-2xl" />
//                           )}
//                           <span className="text-sm text-gray-700">{schedule.logicStatus === 'On' ? 'On' : 'Off'}</span>
//                         </span>
//                       </>
//                     )}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="py-8 text-center bg-white">
//                   <span className="text-cyan-800 border-cyan-800 border-opacity-60 border font-semibold text-sm px-4 py-1 rounded-full">
//                     No schedules found
//                   </span>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
      
//       {/* show confirm alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title="Confirm Action"
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={async () => {
//             await actionToConfirm();  
//             setShowConfirmAlert(false);
//           }}
//         />
//       )}

//       {/* Workflow Controls */}
//       <div className="flex justify-end items-center space-x-4 mt-4">
//         <button
//           className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full"
//           onClick={closeWorkflow}
//         >
//           Close Workflow
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;


// // udpate code --> 19 sept 2024
// // src/components/admin/MarketSetting/CampaignLogic.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// // import react icon
// import { LuWorkflow } from "react-icons/lu";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { AiFillDelete, AiFillCloseSquare } from "react-icons/ai";
// import { FaTelegramPlane } from "react-icons/fa";
// import { FaSquareCheck } from "react-icons/fa6";
// import { BiSolidAlarmAdd } from "react-icons/bi";
// import { MdLibraryAddCheck, MdUpdateDisabled } from "react-icons/md";
// // import custom hook file
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// // import custom components file
// import ConfirmAlertMessage from '../alertMessage';

// // Interface definitions
// interface Schedule {
//   workflowType: string;
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// const formatDate = (date: string) => {
//   // Check directly if date string is exactly the "1979-01-01 12:00:00"
//   if (date === '1979-01-01 12:00:00' || date.startsWith('1979-01-01')) {
//     return 'No Apply';
//   }

//   const d = new Date(date);
  
//   const year = d.getFullYear();
//   const month = String(d.getMonth() + 1).padStart(2, '0');
//   const day = String(d.getDate()).padStart(2, '0');
//   const hours = d.getHours() % 12 || 12;
//   const minutes = String(d.getMinutes()).padStart(2, '0');
//   const seconds = String(d.getSeconds()).padStart(2, '0');
//   const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
// };


// const CampaignLogic: React.FC<{ workflowId: number; workflowName: string; closeWorkflow: () => void }> = ({ workflowId, workflowName, closeWorkflow }) => {
//   const { 
//     email: campaigns, fetchCampaigns, 
//     fetchWorkflows, 
//     fetchLogics, addLogic, updateLogic, deleteLogics,
//     sendTestEmail, scheduleLogicEmails  
//   } = useEmail();
//   // State for schedules
//   const [schedules, setSchedules] = useState<Schedule[]>([]);
//   // State for search
//   const [searchTerm, setSearchTerm] = useState('');
//   // State for Edit Mode
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [editedSchedule, setEditedSchedule] = useState<Partial<Schedule>>({});
//   // State for Multi-Select Mode
//   const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // state for alert message 
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);
//   const [confirmMessage, setConfirmMessage] = useState('');
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm');
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); 

//   useEffect(() => {
//     const fetchInitialLogics = async () => {
//       if (!workflowId) return;
//       try {
//         await fetchCampaigns();
//         await fetchWorkflows();
//         // Fetch logic data for the specific workflowId
//         const logicData = await fetchLogics(workflowId);
  
//         // Filter to include only logic with flowType "Logic"
//         const filteredData = logicData.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//         // Update state with filtered logic rows
//         setSchedules(filteredData);
//       } catch (error) {
//         console.error('Failed to fetch logic data');
//       }
//     };
//     fetchInitialLogics();
//   }, [fetchCampaigns, fetchWorkflows, workflowId, fetchLogics]);   

//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedRows([]); // Deselect all
//     } else {
//       setSelectedRows(schedules.map(schedule => schedule.logicId)); // Select all rows
//     }
//     setSelectAll(!selectAll); // Toggle select all state
//   };  

//   // Add appropriate error handling for add logic
//   const handleAddScheduleDirectly = async () => {
//     if (!workflowId) return;
  
//     const newLogic = {
//       scheduleName: 'New ScheduleName',
//       emailContent: 'Select an Email Content',
//       logicType: 'Choose a Type',
//       scheduleDate: '1979-01-01 12:00:00',
//       period: 0,
//       nextRun: 'Select a Next Logic',
//       clickCount: 0,
//       logicStatus: 'Off',
//     };
  
//     try {
//       const addedLogic = await addLogic(workflowId, newLogic);
  
//       // Immediately update schedules with the new logic
//       setSchedules(prevSchedules => [...prevSchedules, addedLogic]);
  
//       // Fetch updated logic rows and filter again
//       const updatedLogics = await fetchLogics(workflowId);
//       const filteredLogics = updatedLogics.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//       setSchedules(filteredLogics);
//     } catch (error) {
//       console.error('Failed to add logic');
//     }
//   };  

//   // Handler to toggle active status
//   const handleToggleActive = async (id: string, currentStatus: string) => {
//     try {
//       // Toggle logicStatus between 'On' and 'Off'
//       const newStatus = currentStatus === 'On' ? 'Off' : 'On';

//       // Update the status in the database
//       await updateLogic(workflowId, id, { logicStatus: newStatus });

//       // Find the full schedule object by logicId
//       const logicToRun = schedules.find((schedule) => schedule.logicId === id);

//       // If the new status is 'On' and the logic is found, schedule the emails
//       if (newStatus === 'On' && logicToRun) {
//         console.log(`Scheduling emails for logic: ${logicToRun.scheduleName}`);
//         await scheduleLogicEmails(logicToRun, schedules); // Pass the full Schedule object
//       }

//       // Update the local state to reflect the change
//       setSchedules(schedules.map(schedule =>
//         schedule.logicId === id ? { ...schedule, logicStatus: newStatus } : schedule
//       ));
//     } catch (error) {
//       console.error('Failed to update logic status:', error);
//     }
//   };

//   // Handler for double-click to enter or exit edit mode
//   const handleRowDoubleClick = (schedule: Schedule) => {
//     if (editingId === schedule.logicId) {
//       return;
//     } else {
//       setEditingId(schedule.logicId);
//       setEditedSchedule({ ...schedule });
//     }
//   };

//   // Handler to confirm edit
//   const handleConfirmEdit = (logicId: string) => {
//     setConfirmMessage('Are you sure you want to save the changes to this logic row?');
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const finalScheduleDate = editedSchedule.scheduleDate === "No Apply"
//         ? "1979-01-01 12:00:00"
//         : editedSchedule.scheduleDate;
  
//       if (
//         editedSchedule.scheduleName &&
//         editedSchedule.emailContent &&
//         editedSchedule.logicType &&
//         finalScheduleDate &&
//         editedSchedule.period !== undefined &&
//         editedSchedule.nextRun
//       ) {
//         try {
//           const updatedLogic = await updateLogic(workflowId, logicId, {
//             ...editedSchedule,
//             scheduleDate: finalScheduleDate,
//           });
//           setSchedules(schedules.map(s => (s.logicId === logicId ? { ...s, ...updatedLogic } : s)));
//           setEditingId(null);
//           setEditedSchedule({});
//         } catch (error) {
//           console.error('Failed to update logic:', error);
//         }
//       } else {
//         alert('Please fill in all fields.');
//       }
//     });
//     setShowConfirmAlert(true); // Show the confirmation dialog
//   };

//   // Handler to cancel edit
//   const handleCancelEdit = () => {
//     setEditingId(null);
//     setEditedSchedule({});
//   };

//   // Handler to delete selected rows
//   const handleDeleteSelectedRows = async () => {
//     if (selectedRows.length > 0) {
//       setConfirmMessage('Are you sure you want to delete the selected logic rows? This action cannot be undone.');
//       setConfirmType('warning');
//       setActionToConfirm(() => async () => {
//         try {
//           await deleteLogics(workflowId, selectedRows);
//           setSchedules(schedules.filter(schedule => !selectedRows.includes(schedule.logicId)));
//           setSelectedRows([]);
//         } catch (error) {
//           alert('Failed to delete logic rows');
//         }
//       });
//       setShowConfirmAlert(true); // Show the confirmation dialog
//     } else {
//       alert('No schedules selected for deletion.');
//     }
//   };

//   // Filtered schedules based on search term
//   const filteredSchedules = schedules.filter((schedule) =>
//     schedule.scheduleName ? schedule.scheduleName.toLowerCase().includes(searchTerm.toLowerCase()) : false
//   );  

//    // Function to send test email for the selected logic row
//    const handleSendTestEmail = (logicId: string) => {
//     // Find the schedule corresponding to the logicId
//     const selectedSchedule = schedules.find(schedule => schedule.logicId === logicId);
  
//     if (!selectedSchedule) {
//       setConfirmMessage('No email content found for this logic.');
//       setConfirmType('error');
//       setShowConfirmAlert(true);
//       return;
//     }
  
//     // Show confirmation alert before sending the test email
//     setConfirmMessage(`You are testing to send a ${selectedSchedule.emailContent} content. Do you want to proceed?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       try {
//         // Attempt to send the test email
//         await sendTestEmail(logicId);
//         // If successful, show success message
//         setConfirmMessage(`${selectedSchedule.emailContent} content sent successfully`);
//         setConfirmType('success');
//       } catch (error) {
//         // If failed, show error message
//         setConfirmMessage(`Failed to send ${selectedSchedule.emailContent} content`);
//         setConfirmType('error');
//       }
//       setShowConfirmAlert(true); // Display the result in an alert
//     });
//     setShowConfirmAlert(true); // Show the confirmation alert
//   };   

//   return (
//     <div className="mb-4 p-4">
//       {/* Workflow Header */}
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex items-center">
//           <LuWorkflow className="text-cyan-800 text-xl mr-2 cursor-pointer"/>
//           <h3 className="text-xl font-semibold text-cyan-800">Workflow Logic: {workflowName}</h3>
//         </div>
//         <div className="flex items-center space-x-2">
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
//           >
//             <MdLibraryAddCheck />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => {
//               if (selectedRows.length === 1) {
//                 handleSendTestEmail(selectedRows[0]); // Send email for the selected row
//               } else {
//                 alert('Please select one logic row to send the test email');
//               }
//             }}
//           >
//             <FaTelegramPlane />
//           </button>
//           <input
//             type="text"
//             placeholder="Search schedules..."
//             className="px-4 py-2 border rounded"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleAddScheduleDirectly}
//           >
//             <BiSolidAlarmAdd />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleDeleteSelectedRows}
//           >
//             <AiFillDelete />
//           </button>
//         </div>
//       </div>

//       {/* Schedules Table */}
//       <div className="overflow-x-auto">
//         <table className="w-[1200px] bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               {/* Select All Checkbox */}
//               {isMultiSelectEnabled && (
//                 <th className="py-2 px-4 border-b accent-cyan-800">
//                   <input 
//                     type="checkbox" 
//                     checked={selectAll} 
//                     onChange={handleSelectAll} 
//                   />
//                 </th>
//               )}
//               <th className="py-2 px-4 border-b">Schedules Name</th>
//               <th className="py-2 px-4 border-b">Email Content</th>
//               <th className="py-2 px-4 border-b">Type</th>
//               <th className="py-2 px-4 border-b">Schedule Date</th>
//               <th className="py-2 px-4 border-b">Period (Days)</th>
//               <th className="py-2 px-4 border-b">Next Run</th>
//               <th className="py-2 px-4 border-b">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredSchedules.length > 0 ? (
//               filteredSchedules.map((schedule) => (
//                 <tr 
//                   key={schedule.logicId} 
//                   className="text-center text-xs accent-cyan-800 hover:bg-gray-50 cursor-pointer border-b"
//                   onClick={(e) => {
//                     if (isMultiSelectEnabled) {
//                       if (selectedRows.includes(schedule.logicId)) {
//                         setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                       } else {
//                         setSelectedRows([...selectedRows, schedule.logicId]);
//                       }
//                     }
//                   }}
//                   onDoubleClick={() => handleRowDoubleClick(schedule)}
//                 >
//                   {isMultiSelectEnabled && (
//                     <td className="py-2 px-4">
//                       <input
//                         type="checkbox"
//                         checked={selectedRows.includes(schedule.logicId)}
//                         onChange={() => {
//                           if (selectedRows.includes(schedule.logicId)) {
//                             setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                           } else {
//                             setSelectedRows([...selectedRows, schedule.logicId]);
//                           }
//                         }}
//                       />
//                     </td>
//                   )}

//                   {/* Workflow Logic Name */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="text"
//                         value={editedSchedule.scheduleName}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleName: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       schedule.scheduleName
//                     )}
//                   </td>

//                   {/* Workflow Logic Email Content */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.emailContent}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, emailContent: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select an Email Content</option>
//                         {campaigns && campaigns.map((campaign) => (
//                           <option key={campaign.id} value={campaign.campTitle}>
//                             {campaign.campTitle}
//                           </option>
//                         ))}
//                       </select>
//                     ) : (
//                       schedule.emailContent
//                     )}
//                   </td>

//                   {/* Workflow Logic Type */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.logicType}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, logicType: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Choose a Type</option>
//                         <option value="Started">Started</option>
//                         <option value="Follow-Up">Follow-Up</option>
//                         <option value="Ended">Ended</option>
//                       </select>
//                     ) : (
//                       schedule.logicType
//                     )}
//                   </td>

//                   {/* Workflow Logic Schedule Date */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         {editedSchedule.scheduleDate === 'No Apply' ? (
//                           <select
//                             value={editedSchedule.scheduleDate}
//                             onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                             className="border rounded px-2 py-1 w-full"
//                           >
//                             <option value="1979-01-01 12:00:00">No Apply</option>
//                             <option value="">Select Date</option>
//                           </select>
//                         ) : (
//                           <div className="flex justify-normal items-center">
//                             <input
//                               type="datetime-local"
//                               value={editedSchedule.scheduleDate}
//                               onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                               className="border rounded px-2 py-1 w-[160px] text-center"
//                             />
//                             <div className="relative cursor-help bg-white border-black">
//                               <button
//                                 className="flex justify-normal items-center text-gray-600 text-sm ml-2"
//                                 onClick={() => setEditedSchedule({ ...editedSchedule, scheduleDate: 'No Apply' })}
//                               >
//                                 <MdUpdateDisabled />
//                               </button>
//                             </div>
//                           </div>
//                         )}
//                       </>
//                     ) : (
//                       <span>{schedule.scheduleDate === 'No Apply' ? 'No Apply' : formatDate(schedule.scheduleDate)}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Period */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="number"
//                         value={editedSchedule.period}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, period: Number(e.target.value) })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       <span>{schedule.period === 0 ? 'No Apply' : schedule.period}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Next Run */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.nextRun || ''}  // Ensure the value is properly selected
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, nextRun: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select Next Schedule Name</option>
//                         {schedules.length > 0 ? (
//                           schedules
//                             .filter((s) => s.logicId !== schedule.logicId) // Exclude the current schedule from being its own nextRun
//                             .map((s) => (
//                               <option key={s.logicId} value={s.scheduleName}>
//                                 {s.scheduleName}
//                               </option>
//                             ))
//                         ) : (
//                           <option disabled>No schedules available</option>  // Handle empty schedules case
//                         )}
//                       </select>
//                     ) : (
//                       schedule.nextRun || 'No Next Run Defined'  // Display message if nextRun is not set
//                     )}
//                   </td>

//                   {/* Workflow Logic Action */}
//                   <td className="py-4 px-4 flex justify-center items-center space-x-1">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         <button
//                           className="text-cyan-800 text-lg p-1"
//                           onClick={() => handleConfirmEdit(schedule.logicId)}
//                         >
//                           <FaSquareCheck />
//                         </button>
//                         <button
//                           className="text-gray-400 text-lg p-1"
//                           onClick={handleCancelEdit}
//                         >
//                           <AiFillCloseSquare />
//                         </button>
//                       </>
//                     ) : (
//                       <>
//                         <span 
//                           className="flex items-center space-x-1 cursor-pointer" 
//                           onClick={() => handleToggleActive(schedule.logicId, schedule.logicStatus)}
//                         >
//                           {schedule.logicStatus === 'On' ? (
//                             <BsToggleOn className="text-cyan-800 text-2xl" />
//                           ) : (
//                             <BsToggleOff className="text-gray-400 text-2xl" />
//                           )}
//                           <span className="text-sm text-gray-700">{schedule.logicStatus === 'On' ? 'On' : 'Off'}</span>
//                         </span>
//                       </>
//                     )}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="py-8 text-center bg-white">
//                   <span className="text-cyan-800 border-cyan-800 border-opacity-60 border font-semibold text-sm px-4 py-1 rounded-full">
//                     No schedules found
//                   </span>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
      
//       {/* show confirm alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title="Confirm Action"
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={async () => {
//             await actionToConfirm();  
//             setShowConfirmAlert(false);
//           }}
//         />
//       )}

//       {/* Workflow Controls */}
//       <div className="flex justify-end items-center space-x-4 mt-4">
//         <button
//           className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full"
//           onClick={closeWorkflow}
//         >
//           Close Workflow
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;

// // udpate code --> 18 sept 2024 v03
// // src/components/admin/MarketSetting/CampaignLogic.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// // import react icon
// import { LuWorkflow } from "react-icons/lu";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { AiFillDelete, AiFillCloseSquare } from "react-icons/ai";
// import { FaTelegramPlane } from "react-icons/fa";
// import { FaSquareCheck } from "react-icons/fa6";
// import { BiSolidAlarmAdd } from "react-icons/bi";
// import { MdLibraryAddCheck, MdUpdateDisabled } from "react-icons/md";
// // import custom hook file
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// // import custom components file
// import ConfirmAlertMessage from '../alertMessage';

// // Interface definitions
// interface Schedule {
//   workflowType: string;
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// const formatDate = (date: string) => {
//   // Check directly if date string is exactly the "1979-01-01 12:00:00"
//   if (date === '1979-01-01 12:00:00' || date.startsWith('1979-01-01')) {
//     return 'No Apply';
//   }

//   const d = new Date(date);
  
//   const year = d.getFullYear();
//   const month = String(d.getMonth() + 1).padStart(2, '0');
//   const day = String(d.getDate()).padStart(2, '0');
//   const hours = d.getHours() % 12 || 12;
//   const minutes = String(d.getMinutes()).padStart(2, '0');
//   const seconds = String(d.getSeconds()).padStart(2, '0');
//   const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
// };


// const CampaignLogic: React.FC<{ workflowId: number; workflowName: string; closeWorkflow: () => void }> = ({ workflowId, workflowName, closeWorkflow }) => {
//   const { 
//     email: campaigns, fetchCampaigns, 
//     workflows, fetchWorkflows, 
//     fetchLogics, addLogic, updateLogic, deleteLogics,
//     sendTestEmail 
//   } = useEmail();
//   // State for schedules
//   const [schedules, setSchedules] = useState<Schedule[]>([]);
//   // State for search
//   const [searchTerm, setSearchTerm] = useState('');
//   // State for Edit Mode
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [editedSchedule, setEditedSchedule] = useState<Partial<Schedule>>({});
//   // State for Multi-Select Mode
//   const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // state for alert message 
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);
//   const [confirmMessage, setConfirmMessage] = useState('');
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm');
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); 

//   useEffect(() => {
//     const fetchInitialLogics = async () => {
//       if (!workflowId) return;
//       try {
//         await fetchCampaigns();
//         await fetchWorkflows();
//         // Fetch logic data for the specific workflowId
//         const logicData = await fetchLogics(workflowId);
  
//         // Filter to include only logic with flowType "Logic"
//         const filteredData = logicData.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//         // Update state with filtered logic rows
//         setSchedules(filteredData);
//       } catch (error) {
//         console.error('Failed to fetch logic data');
//       }
//     };
//     fetchInitialLogics();
//   }, [fetchCampaigns, fetchWorkflows, workflowId, fetchLogics]);   

//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedRows([]); // Deselect all
//     } else {
//       setSelectedRows(schedules.map(schedule => schedule.logicId)); // Select all rows
//     }
//     setSelectAll(!selectAll); // Toggle select all state
//   };  

//   // Add appropriate error handling for add logic
//   const handleAddScheduleDirectly = async () => {
//     if (!workflowId) return;
  
//     const newLogic = {
//       scheduleName: 'New ScheduleName',
//       emailContent: 'Select an Email Content',
//       logicType: 'Choose a Type',
//       scheduleDate: '1979-01-01 12:00:00',
//       period: 0,
//       nextRun: 'Select a Next Logic',
//       clickCount: 0,
//       logicStatus: 'Off',
//     };
  
//     try {
//       const addedLogic = await addLogic(workflowId, newLogic);
  
//       // Immediately update schedules with the new logic
//       setSchedules(prevSchedules => [...prevSchedules, addedLogic]);
  
//       // Fetch updated logic rows and filter again
//       const updatedLogics = await fetchLogics(workflowId);
//       const filteredLogics = updatedLogics.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//       setSchedules(filteredLogics);
//     } catch (error) {
//       console.error('Failed to add logic');
//     }
//   };  

//   // Handler to toggle active status
//   const handleToggleActive = async (id: string, currentStatus: string) => {
//     try {
//       // Toggle logicStatus between 'On' and 'Off'
//       const newStatus = currentStatus === 'On' ? 'Off' : 'On';

//       // Update the status in the database
//       await updateLogic(workflowId, id, { logicStatus: newStatus });

//       // Update the local state to reflect the change
//       setSchedules(schedules.map(schedule =>
//         schedule.logicId === id 
//           ? { ...schedule, logicStatus: newStatus } 
//           : schedule
//       ));
//     } catch (error) {
//       console.error('Failed to update logic status:', error);
//     }
//   };
  
//   // Handler for double-click to enter or exit edit mode
//   const handleRowDoubleClick = (schedule: Schedule) => {
//     if (editingId === schedule.logicId) {
//       return;
//     } else {
//       setEditingId(schedule.logicId);
//       setEditedSchedule({ ...schedule });
//     }
//   };

//   // Handler to confirm edit
//   const handleConfirmEdit = (logicId: string) => {
//     setConfirmMessage('Are you sure you want to save the changes to this logic row?');
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const finalScheduleDate = editedSchedule.scheduleDate === "No Apply"
//         ? "1979-01-01 12:00:00"
//         : editedSchedule.scheduleDate;
  
//       if (
//         editedSchedule.scheduleName &&
//         editedSchedule.emailContent &&
//         editedSchedule.logicType &&
//         finalScheduleDate &&
//         editedSchedule.period !== undefined &&
//         editedSchedule.nextRun
//       ) {
//         try {
//           const updatedLogic = await updateLogic(workflowId, logicId, {
//             ...editedSchedule,
//             scheduleDate: finalScheduleDate,
//           });
//           setSchedules(schedules.map(s => (s.logicId === logicId ? { ...s, ...updatedLogic } : s)));
//           setEditingId(null);
//           setEditedSchedule({});
//         } catch (error) {
//           console.error('Failed to update logic:', error);
//         }
//       } else {
//         alert('Please fill in all fields.');
//       }
//     });
//     setShowConfirmAlert(true); // Show the confirmation dialog
//   };

//   // Handler to cancel edit
//   const handleCancelEdit = () => {
//     setEditingId(null);
//     setEditedSchedule({});
//   };

//   // Handler to delete selected rows
//   const handleDeleteSelectedRows = async () => {
//     if (selectedRows.length > 0) {
//       setConfirmMessage('Are you sure you want to delete the selected logic rows? This action cannot be undone.');
//       setConfirmType('warning');
//       setActionToConfirm(() => async () => {
//         try {
//           await deleteLogics(workflowId, selectedRows);
//           setSchedules(schedules.filter(schedule => !selectedRows.includes(schedule.logicId)));
//           setSelectedRows([]);
//         } catch (error) {
//           alert('Failed to delete logic rows');
//         }
//       });
//       setShowConfirmAlert(true); // Show the confirmation dialog
//     } else {
//       alert('No schedules selected for deletion.');
//     }
//   };

//   // Filtered schedules based on search term
//   const filteredSchedules = schedules.filter((schedule) =>
//     schedule.scheduleName ? schedule.scheduleName.toLowerCase().includes(searchTerm.toLowerCase()) : false
//   );  

//    // Function to send test email for the selected logic row
//    const handleSendTestEmail = (logicId: string) => {
//     // Find the schedule corresponding to the logicId
//     const selectedSchedule = schedules.find(schedule => schedule.logicId === logicId);
  
//     if (!selectedSchedule) {
//       setConfirmMessage('No email content found for this logic.');
//       setConfirmType('error');
//       setShowConfirmAlert(true);
//       return;
//     }
  
//     // Show confirmation alert before sending the test email
//     setConfirmMessage(`You are testing to send a ${selectedSchedule.emailContent} content. Do you want to proceed?`);
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       try {
//         // Attempt to send the test email
//         await sendTestEmail(logicId);
//         // If successful, show success message
//         setConfirmMessage(`${selectedSchedule.emailContent} content sent successfully`);
//         setConfirmType('success');
//       } catch (error) {
//         // If failed, show error message
//         setConfirmMessage(`Failed to send ${selectedSchedule.emailContent} content`);
//         setConfirmType('error');
//       }
//       setShowConfirmAlert(true); // Display the result in an alert
//     });
//     setShowConfirmAlert(true); // Show the confirmation alert
//   };   

//   return (
//     <div className="mb-4 p-4">
//       {/* Workflow Header */}
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex items-center">
//           <LuWorkflow className="text-cyan-800 text-xl mr-2 cursor-pointer"/>
//           <h3 className="text-xl font-semibold text-cyan-800">Workflow Logic: {workflowName}</h3>
//         </div>
//         <div className="flex items-center space-x-2">
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
//           >
//             <MdLibraryAddCheck />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => {
//               if (selectedRows.length === 1) {
//                 handleSendTestEmail(selectedRows[0]); // Send email for the selected row
//               } else {
//                 alert('Please select one logic row to send the test email');
//               }
//             }}
//           >
//             <FaTelegramPlane />
//           </button>
//           <input
//             type="text"
//             placeholder="Search schedules..."
//             className="px-4 py-2 border rounded"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleAddScheduleDirectly}
//           >
//             <BiSolidAlarmAdd />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleDeleteSelectedRows}
//           >
//             <AiFillDelete />
//           </button>
//         </div>
//       </div>

//       {/* Schedules Table */}
//       <div className="overflow-x-auto">
//         <table className="w-[1200px] bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               {/* Select All Checkbox */}
//               {isMultiSelectEnabled && (
//                 <th className="py-2 px-4 border-b accent-cyan-800">
//                   <input 
//                     type="checkbox" 
//                     checked={selectAll} 
//                     onChange={handleSelectAll} 
//                   />
//                 </th>
//               )}
//               <th className="py-2 px-4 border-b">Schedules Name</th>
//               <th className="py-2 px-4 border-b">Email Content</th>
//               <th className="py-2 px-4 border-b">Type</th>
//               <th className="py-2 px-4 border-b">Schedule Date</th>
//               <th className="py-2 px-4 border-b">Period (Days)</th>
//               <th className="py-2 px-4 border-b">Next Run</th>
//               <th className="py-2 px-4 border-b">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredSchedules.length > 0 ? (
//               filteredSchedules.map((schedule) => (
//                 <tr 
//                   key={schedule.logicId} 
//                   className="text-center text-xs accent-cyan-800 hover:bg-gray-50 cursor-pointer border-b"
//                   onClick={(e) => {
//                     if (isMultiSelectEnabled) {
//                       if (selectedRows.includes(schedule.logicId)) {
//                         setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                       } else {
//                         setSelectedRows([...selectedRows, schedule.logicId]);
//                       }
//                     }
//                   }}
//                   onDoubleClick={() => handleRowDoubleClick(schedule)}
//                 >
//                   {isMultiSelectEnabled && (
//                     <td className="py-2 px-4">
//                       <input
//                         type="checkbox"
//                         checked={selectedRows.includes(schedule.logicId)}
//                         onChange={() => {
//                           if (selectedRows.includes(schedule.logicId)) {
//                             setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                           } else {
//                             setSelectedRows([...selectedRows, schedule.logicId]);
//                           }
//                         }}
//                       />
//                     </td>
//                   )}

//                   {/* Workflow Logic Name */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="text"
//                         value={editedSchedule.scheduleName}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleName: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       schedule.scheduleName
//                     )}
//                   </td>

//                   {/* Workflow Logic Email Content */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.emailContent}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, emailContent: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select an Email Content</option>
//                         {campaigns && campaigns.map((campaign) => (
//                           <option key={campaign.id} value={campaign.campTitle}>
//                             {campaign.campTitle}
//                           </option>
//                         ))}
//                       </select>
//                     ) : (
//                       schedule.emailContent
//                     )}
//                   </td>

//                   {/* Workflow Logic Type */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.logicType}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, logicType: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Choose a Type</option>
//                         <option value="Started">Started</option>
//                         <option value="Follow-Up">Follow-Up</option>
//                         <option value="Ended">Ended</option>
//                       </select>
//                     ) : (
//                       schedule.logicType
//                     )}
//                   </td>

//                   {/* Workflow Logic Schedule Date */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         {editedSchedule.scheduleDate === 'No Apply' ? (
//                           <select
//                             value={editedSchedule.scheduleDate}
//                             onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                             className="border rounded px-2 py-1 w-full"
//                           >
//                             <option value="1979-01-01 12:00:00">No Apply</option>
//                             <option value="">Select Date</option>
//                           </select>
//                         ) : (
//                           <div className="flex justify-normal items-center">
//                             <input
//                               type="datetime-local"
//                               value={editedSchedule.scheduleDate}
//                               onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                               className="border rounded px-2 py-1 w-[160px] text-center"
//                             />
//                             <div className="relative cursor-help bg-white border-black">
//                               <button
//                                 className="flex justify-normal items-center text-gray-600 text-sm ml-2"
//                                 onClick={() => setEditedSchedule({ ...editedSchedule, scheduleDate: 'No Apply' })}
//                               >
//                                 <MdUpdateDisabled />
//                               </button>
//                             </div>
//                           </div>
//                         )}
//                       </>
//                     ) : (
//                       <span>{schedule.scheduleDate === 'No Apply' ? 'No Apply' : formatDate(schedule.scheduleDate)}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Period */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="number"
//                         value={editedSchedule.period}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, period: Number(e.target.value) })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       <span>{schedule.period === 0 ? 'No Apply' : schedule.period}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Next Run */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.nextRun}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, nextRun: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select a Next Logic</option>
//                         {workflows && workflows
//                           .filter((workflow) => workflow.workflowType === 'Flow')
//                           .map((workflow) => (
//                             <option key={workflow.workflowId} value={workflow.workflowName}>
//                               {workflow.workflowName}
//                             </option>
//                           ))}
//                       </select>
//                     ) : (
//                       schedule.nextRun
//                     )}
//                   </td>

//                   {/* Workflow Logic Action */}
//                   <td className="py-4 px-4 flex justify-center items-center space-x-1">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         <button
//                           className="text-cyan-800 text-lg p-1"
//                           onClick={() => handleConfirmEdit(schedule.logicId)}
//                         >
//                           <FaSquareCheck />
//                         </button>
//                         <button
//                           className="text-gray-400 text-lg p-1"
//                           onClick={handleCancelEdit}
//                         >
//                           <AiFillCloseSquare />
//                         </button>
//                       </>
//                     ) : (
//                       <>
//                         <span 
//                           className="flex items-center space-x-1 cursor-pointer" 
//                           onClick={() => handleToggleActive(schedule.logicId, schedule.logicStatus)}
//                         >
//                           {schedule.logicStatus === 'On' ? (
//                             <BsToggleOn className="text-cyan-800 text-2xl" />
//                           ) : (
//                             <BsToggleOff className="text-gray-400 text-2xl" />
//                           )}
//                           <span className="text-sm text-gray-700">{schedule.logicStatus === 'On' ? 'On' : 'Off'}</span>
//                         </span>
//                       </>
//                     )}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="py-8 text-center bg-white">
//                   <span className="text-cyan-800 border-cyan-800 border-opacity-60 border font-semibold text-sm px-4 py-1 rounded-full">
//                     No schedules found
//                   </span>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
      
//       {/* show confirm alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title="Confirm Action"
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={async () => {
//             await actionToConfirm();  
//             setShowConfirmAlert(false);
//           }}
//         />
//       )}

//       {/* Workflow Controls */}
//       <div className="flex justify-end items-center space-x-4 mt-4">
//         <button
//           className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full"
//           onClick={closeWorkflow}
//         >
//           Close Workflow
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;

// // update code --> 18 Sept 2024 v02
// // src/components/admin/MarketSetting/CampaignLogic.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// // import react icon
// import { LuWorkflow } from "react-icons/lu";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { AiFillDelete, AiFillCloseSquare } from "react-icons/ai";
// import { FaTelegramPlane } from "react-icons/fa";
// import { FaSquareCheck } from "react-icons/fa6";
// import { BiSolidAlarmAdd } from "react-icons/bi";
// import { MdLibraryAddCheck, MdUpdateDisabled } from "react-icons/md";
// // import custom hook file
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// // import custom components file
// import ConfirmAlertMessage from '../alertMessage';

// // Interface definitions
// interface Schedule {
//   workflowType: string;
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// const formatDate = (date: string) => {
//   // Check directly if date string is exactly the "1979-01-01 12:00:00"
//   if (date === '1979-01-01 12:00:00' || date.startsWith('1979-01-01')) {
//     return 'No Apply';
//   }

//   const d = new Date(date);
  
//   const year = d.getFullYear();
//   const month = String(d.getMonth() + 1).padStart(2, '0');
//   const day = String(d.getDate()).padStart(2, '0');
//   const hours = d.getHours() % 12 || 12;
//   const minutes = String(d.getMinutes()).padStart(2, '0');
//   const seconds = String(d.getSeconds()).padStart(2, '0');
//   const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
// };


// const CampaignLogic: React.FC<{ workflowId: number; workflowName: string; closeWorkflow: () => void }> = ({ workflowId, workflowName, closeWorkflow }) => {
//   const { email: campaigns, fetchCampaigns, workflows, fetchWorkflows, fetchLogics, addLogic, updateLogic, deleteLogics } = useEmail();
//   // State for schedules
//   const [schedules, setSchedules] = useState<Schedule[]>([]);
//   // State for search
//   const [searchTerm, setSearchTerm] = useState('');
//   // State for Edit Mode
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [editedSchedule, setEditedSchedule] = useState<Partial<Schedule>>({});
//   // State for Multi-Select Mode
//   const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // state for alert message 
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);
//   const [confirmMessage, setConfirmMessage] = useState('');
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm');
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); 

//   useEffect(() => {
//     const fetchInitialLogics = async () => {
//       if (!workflowId) return;
//       try {
//         await fetchCampaigns();
//         await fetchWorkflows();
//         // Fetch logic data for the specific workflowId
//         const logicData = await fetchLogics(workflowId);
  
//         // Filter to include only logic with flowType "Logic"
//         const filteredData = logicData.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//         // Update state with filtered logic rows
//         setSchedules(filteredData);
//       } catch (error) {
//         console.error('Failed to fetch logic data');
//       }
//     };
//     fetchInitialLogics();
//   }, [fetchCampaigns, fetchWorkflows, workflowId, fetchLogics]);   

//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedRows([]); // Deselect all
//     } else {
//       setSelectedRows(schedules.map(schedule => schedule.logicId)); // Select all rows
//     }
//     setSelectAll(!selectAll); // Toggle select all state
//   };  

//   // Add appropriate error handling for add logic
//   const handleAddScheduleDirectly = async () => {
//     if (!workflowId) return;
  
//     const newLogic = {
//       scheduleName: 'New ScheduleName',
//       emailContent: 'Select an Email Content',
//       logicType: 'Choose a Type',
//       scheduleDate: '1979-01-01 12:00:00',
//       period: 0,
//       nextRun: 'Select a Next Logic',
//       clickCount: 0,
//       logicStatus: 'Off',
//     };
  
//     try {
//       const addedLogic = await addLogic(workflowId, newLogic);
  
//       // Immediately update schedules with the new logic
//       setSchedules(prevSchedules => [...prevSchedules, addedLogic]);
  
//       // Fetch updated logic rows and filter again
//       const updatedLogics = await fetchLogics(workflowId);
//       const filteredLogics = updatedLogics.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//       setSchedules(filteredLogics);
//     } catch (error) {
//       console.error('Failed to add logic');
//     }
//   };  

//   // Handler to toggle active status
//   const handleToggleActive = async (id: string, currentStatus: string) => {
//     try {
//       // Toggle logicStatus between 'On' and 'Off'
//       const newStatus = currentStatus === 'On' ? 'Off' : 'On';

//       // Update the status in the database
//       await updateLogic(workflowId, id, { logicStatus: newStatus });

//       // Update the local state to reflect the change
//       setSchedules(schedules.map(schedule =>
//         schedule.logicId === id 
//           ? { ...schedule, logicStatus: newStatus } 
//           : schedule
//       ));
//     } catch (error) {
//       console.error('Failed to update logic status:', error);
//     }
//   };
  
//   // Handler for double-click to enter or exit edit mode
//   const handleRowDoubleClick = (schedule: Schedule) => {
//     if (editingId === schedule.logicId) {
//       return;
//     } else {
//       setEditingId(schedule.logicId);
//       setEditedSchedule({ ...schedule });
//     }
//   };

//   // Handler to confirm edit
//   const handleConfirmEdit = (logicId: string) => {
//     setConfirmMessage('Are you sure you want to save the changes to this logic row?');
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const finalScheduleDate = editedSchedule.scheduleDate === "No Apply"
//         ? "1979-01-01 12:00:00"
//         : editedSchedule.scheduleDate;
  
//       if (
//         editedSchedule.scheduleName &&
//         editedSchedule.emailContent &&
//         editedSchedule.logicType &&
//         finalScheduleDate &&
//         editedSchedule.period !== undefined &&
//         editedSchedule.nextRun
//       ) {
//         try {
//           const updatedLogic = await updateLogic(workflowId, logicId, {
//             ...editedSchedule,
//             scheduleDate: finalScheduleDate,
//           });
//           setSchedules(schedules.map(s => (s.logicId === logicId ? { ...s, ...updatedLogic } : s)));
//           setEditingId(null);
//           setEditedSchedule({});
//         } catch (error) {
//           console.error('Failed to update logic:', error);
//         }
//       } else {
//         alert('Please fill in all fields.');
//       }
//     });
//     setShowConfirmAlert(true); // Show the confirmation dialog
//   };

//   // Handler to cancel edit
//   const handleCancelEdit = () => {
//     setEditingId(null);
//     setEditedSchedule({});
//   };

//   // Handler to delete selected rows
//   const handleDeleteSelectedRows = async () => {
//     if (selectedRows.length > 0) {
//       setConfirmMessage('Are you sure you want to delete the selected logic rows? This action cannot be undone.');
//       setConfirmType('warning');
//       setActionToConfirm(() => async () => {
//         try {
//           await deleteLogics(workflowId, selectedRows);
//           setSchedules(schedules.filter(schedule => !selectedRows.includes(schedule.logicId)));
//           setSelectedRows([]);
//         } catch (error) {
//           alert('Failed to delete logic rows');
//         }
//       });
//       setShowConfirmAlert(true); // Show the confirmation dialog
//     } else {
//       alert('No schedules selected for deletion.');
//     }
//   };

//   // Filtered schedules based on search term
//   const filteredSchedules = schedules.filter((schedule) =>
//     schedule.scheduleName ? schedule.scheduleName.toLowerCase().includes(searchTerm.toLowerCase()) : false
//   );  

//   return (
//     <div className="mb-4 p-4">
//       {/* Workflow Header */}
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex items-center">
//           <LuWorkflow className="text-cyan-800 text-xl mr-2 cursor-pointer"/>
//           <h3 className="text-xl font-semibold text-cyan-800">Workflow Logic: {workflowName}</h3>
//         </div>
//         <div className="flex items-center space-x-2">
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
//           >
//             <MdLibraryAddCheck />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//           >
//             <FaTelegramPlane />
//           </button>
//           <input
//             type="text"
//             placeholder="Search schedules..."
//             className="px-4 py-2 border rounded"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleAddScheduleDirectly}
//           >
//             <BiSolidAlarmAdd />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleDeleteSelectedRows}
//           >
//             <AiFillDelete />
//           </button>
//         </div>
//       </div>

//       {/* Schedules Table */}
//       <div className="overflow-x-auto">
//         <table className="w-[1200px] bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               {/* Select All Checkbox */}
//               {isMultiSelectEnabled && (
//                 <th className="py-2 px-4 border-b accent-cyan-800">
//                   <input 
//                     type="checkbox" 
//                     checked={selectAll} 
//                     onChange={handleSelectAll} 
//                   />
//                 </th>
//               )}
//               <th className="py-2 px-4 border-b">Schedules Name</th>
//               <th className="py-2 px-4 border-b">Email Content</th>
//               <th className="py-2 px-4 border-b">Type</th>
//               <th className="py-2 px-4 border-b">Schedule Date</th>
//               <th className="py-2 px-4 border-b">Period (Days)</th>
//               <th className="py-2 px-4 border-b">Next Run</th>
//               <th className="py-2 px-4 border-b">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredSchedules.length > 0 ? (
//               filteredSchedules.map((schedule) => (
//                 <tr 
//                   key={schedule.logicId} 
//                   className="text-center text-xs accent-cyan-800 hover:bg-gray-50 cursor-pointer border-b"
//                   onClick={(e) => {
//                     if (isMultiSelectEnabled) {
//                       if (selectedRows.includes(schedule.logicId)) {
//                         setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                       } else {
//                         setSelectedRows([...selectedRows, schedule.logicId]);
//                       }
//                     }
//                   }}
//                   onDoubleClick={() => handleRowDoubleClick(schedule)}
//                 >
//                   {isMultiSelectEnabled && (
//                     <td className="py-2 px-4">
//                       <input
//                         type="checkbox"
//                         checked={selectedRows.includes(schedule.logicId)}
//                         onChange={() => {
//                           if (selectedRows.includes(schedule.logicId)) {
//                             setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                           } else {
//                             setSelectedRows([...selectedRows, schedule.logicId]);
//                           }
//                         }}
//                       />
//                     </td>
//                   )}

//                   {/* Workflow Logic Name */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="text"
//                         value={editedSchedule.scheduleName}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleName: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       schedule.scheduleName
//                     )}
//                   </td>

//                   {/* Workflow Logic Email Content */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.emailContent}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, emailContent: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select an Email Content</option>
//                         {campaigns && campaigns.map((campaign) => (
//                           <option key={campaign.id} value={campaign.campTitle}>
//                             {campaign.campTitle}
//                           </option>
//                         ))}
//                       </select>
//                     ) : (
//                       schedule.emailContent
//                     )}
//                   </td>

//                   {/* Workflow Logic Type */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.logicType}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, logicType: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Choose a Type</option>
//                         <option value="Started">Started</option>
//                         <option value="Follow-Up">Follow-Up</option>
//                         <option value="Ended">Ended</option>
//                       </select>
//                     ) : (
//                       schedule.logicType
//                     )}
//                   </td>

//                   {/* Workflow Logic Schedule Date */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         {editedSchedule.scheduleDate === 'No Apply' ? (
//                           <select
//                             value={editedSchedule.scheduleDate}
//                             onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                             className="border rounded px-2 py-1 w-full"
//                           >
//                             <option value="1979-01-01 12:00:00">No Apply</option>
//                             <option value="">Select Date</option>
//                           </select>
//                         ) : (
//                           <div className="flex justify-normal items-center">
//                             <input
//                               type="datetime-local"
//                               value={editedSchedule.scheduleDate}
//                               onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                               className="border rounded px-2 py-1 w-[160px] text-center"
//                             />
//                             <div className="relative cursor-help bg-white border-black">
//                               <button
//                                 className="flex justify-normal items-center text-gray-600 text-sm ml-2"
//                                 onClick={() => setEditedSchedule({ ...editedSchedule, scheduleDate: 'No Apply' })}
//                               >
//                                 <MdUpdateDisabled />
//                               </button>
//                             </div>
//                           </div>
//                         )}
//                       </>
//                     ) : (
//                       <span>{schedule.scheduleDate === 'No Apply' ? 'No Apply' : formatDate(schedule.scheduleDate)}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Period */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="number"
//                         value={editedSchedule.period}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, period: Number(e.target.value) })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       <span>{schedule.period === 0 ? 'No Apply' : schedule.period}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Next Run */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.nextRun}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, nextRun: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select a Next Logic</option>
//                         {workflows && workflows
//                           .filter((workflow) => workflow.workflowType === 'Flow')
//                           .map((workflow) => (
//                             <option key={workflow.workflowId} value={workflow.workflowName}>
//                               {workflow.workflowName}
//                             </option>
//                           ))}
//                       </select>
//                     ) : (
//                       schedule.nextRun
//                     )}
//                   </td>

//                   {/* Workflow Logic Action */}
//                   <td className="py-4 px-4 flex justify-center items-center space-x-1">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         <button
//                           className="text-cyan-800 text-lg p-1"
//                           onClick={() => handleConfirmEdit(schedule.logicId)}
//                         >
//                           <FaSquareCheck />
//                         </button>
//                         <button
//                           className="text-gray-400 text-lg p-1"
//                           onClick={handleCancelEdit}
//                         >
//                           <AiFillCloseSquare />
//                         </button>
//                       </>
//                     ) : (
//                       <>
//                         <span 
//                           className="flex items-center space-x-1 cursor-pointer" 
//                           onClick={() => handleToggleActive(schedule.logicId, schedule.logicStatus)}
//                         >
//                           {schedule.logicStatus === 'On' ? (
//                             <BsToggleOn className="text-cyan-800 text-2xl" />
//                           ) : (
//                             <BsToggleOff className="text-gray-400 text-2xl" />
//                           )}
//                           <span className="text-sm text-gray-700">{schedule.logicStatus === 'On' ? 'On' : 'Off'}</span>
//                         </span>
//                       </>
//                     )}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="py-8 text-center bg-white">
//                   <span className="text-cyan-800 border-cyan-800 border-opacity-60 border font-semibold text-sm px-4 py-1 rounded-full">
//                     No schedules found
//                   </span>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
      
//       {/* show confirm alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title="Confirm Action"
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={async () => {
//             await actionToConfirm();  
//             setShowConfirmAlert(false);
//           }}
//         />
//       )}

//       {/* Workflow Controls */}
//       <div className="flex justify-end items-center space-x-4 mt-4">
//         <button
//           className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full"
//           onClick={closeWorkflow}
//         >
//           Close Workflow
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;

// // udpate code --> 18 Sept 2024
// // src/components/admin/MarketSetting/CampaignLogic.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// // import react icon
// import { LuWorkflow } from "react-icons/lu";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { AiFillDelete, AiFillCloseSquare } from "react-icons/ai";
// import { FaSquareCheck } from "react-icons/fa6";
// import { BiSolidAlarmAdd } from "react-icons/bi";
// import { MdLibraryAddCheck, MdUpdateDisabled } from "react-icons/md";
// // import custom hook file
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// // import custom components file
// import ConfirmAlertMessage from '../alertMessage';

// // Interface definitions
// interface Schedule {
//   workflowType: string;
//   logicId: string;
//   scheduleName: string;
//   emailContent: string;
//   logicType: string;
//   scheduleDate: string;
//   period: number;
//   nextRun: string;
//   clickCount: number;
//   logicStatus: string;
// }

// const formatDate = (date: string) => {
//   // Check directly if date string is exactly the "1979-01-01 12:00:00"
//   if (date === '1979-01-01 12:00:00' || date.startsWith('1979-01-01')) {
//     return 'No Apply';
//   }

//   const d = new Date(date);
  
//   const year = d.getFullYear();
//   const month = String(d.getMonth() + 1).padStart(2, '0');
//   const day = String(d.getDate()).padStart(2, '0');
//   const hours = d.getHours() % 12 || 12;
//   const minutes = String(d.getMinutes()).padStart(2, '0');
//   const seconds = String(d.getSeconds()).padStart(2, '0');
//   const ampm = d.getHours() >= 12 ? 'PM' : 'AM';

//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
// };


// const CampaignLogic: React.FC<{ workflowId: number; workflowName: string; closeWorkflow: () => void }> = ({ workflowId, workflowName, closeWorkflow }) => {
//   const { email: campaigns, fetchCampaigns, workflows, fetchWorkflows, fetchLogics, addLogic, updateLogic, deleteLogics } = useEmail();
//   // State for schedules
//   const [schedules, setSchedules] = useState<Schedule[]>([]);
//   // State for search
//   const [searchTerm, setSearchTerm] = useState('');
//   // State for Edit Mode
//   const [editingId, setEditingId] = useState<string | null>(null);
//   const [editedSchedule, setEditedSchedule] = useState<Partial<Schedule>>({});
//   // State for Multi-Select Mode
//   const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // state for alert message 
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);
//   const [confirmMessage, setConfirmMessage] = useState('');
//   const [confirmType, setConfirmType] = useState<'confirm' | 'success' | 'warning' | 'error'>('confirm');
//   const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {}); 

//   useEffect(() => {
//     const fetchInitialLogics = async () => {
//       if (!workflowId) return;
//       try {
//         await fetchCampaigns();
//         await fetchWorkflows();
//         // Fetch logic data for the specific workflowId
//         const logicData = await fetchLogics(workflowId);
  
//         // Filter to include only logic with flowType "Logic"
//         const filteredData = logicData.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//         // Update state with filtered logic rows
//         setSchedules(filteredData);
//       } catch (error) {
//         console.error('Failed to fetch logic data');
//       }
//     };
//     fetchInitialLogics();
//   }, [fetchCampaigns, fetchWorkflows, workflowId, fetchLogics]);   

//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedRows([]); // Deselect all
//     } else {
//       setSelectedRows(schedules.map(schedule => schedule.logicId)); // Select all rows
//     }
//     setSelectAll(!selectAll); // Toggle select all state
//   };  

//   // Add appropriate error handling for add logic
//   const handleAddScheduleDirectly = async () => {
//     if (!workflowId) return;
  
//     const newLogic = {
//       scheduleName: 'New ScheduleName',
//       emailContent: 'Select an Email Content',
//       logicType: 'Choose a Type',
//       scheduleDate: '1979-01-01 12:00:00',
//       period: 0,
//       nextRun: 'Select a Next Logic',
//       clickCount: 0,
//       logicStatus: 'Off',
//     };
  
//     try {
//       const addedLogic = await addLogic(workflowId, newLogic);
  
//       // Immediately update schedules with the new logic
//       setSchedules(prevSchedules => [...prevSchedules, addedLogic]);
  
//       // Fetch updated logic rows and filter again
//       const updatedLogics = await fetchLogics(workflowId);
//       const filteredLogics = updatedLogics.filter((logic: Schedule) => logic.workflowType === "Logic");
  
//       setSchedules(filteredLogics);
//     } catch (error) {
//       console.error('Failed to add logic');
//     }
//   };  

//   // Handler to toggle active status
//   const handleToggleActive = async (id: string, currentStatus: string) => {
//     try {
//       // Toggle logicStatus between 'On' and 'Off'
//       const newStatus = currentStatus === 'On' ? 'Off' : 'On';

//       // Update the status in the database
//       await updateLogic(workflowId, id, { logicStatus: newStatus });

//       // Update the local state to reflect the change
//       setSchedules(schedules.map(schedule =>
//         schedule.logicId === id 
//           ? { ...schedule, logicStatus: newStatus } 
//           : schedule
//       ));
//     } catch (error) {
//       console.error('Failed to update logic status:', error);
//     }
//   };
  
//   // Handler for double-click to enter or exit edit mode
//   const handleRowDoubleClick = (schedule: Schedule) => {
//     if (editingId === schedule.logicId) {
//       return;
//     } else {
//       setEditingId(schedule.logicId);
//       setEditedSchedule({ ...schedule });
//     }
//   };

//   // Handler to confirm edit
//   const handleConfirmEdit = (logicId: string) => {
//     setConfirmMessage('Are you sure you want to save the changes to this logic row?');
//     setConfirmType('confirm');
//     setActionToConfirm(() => async () => {
//       const finalScheduleDate = editedSchedule.scheduleDate === "No Apply"
//         ? "1979-01-01 12:00:00"
//         : editedSchedule.scheduleDate;
  
//       if (
//         editedSchedule.scheduleName &&
//         editedSchedule.emailContent &&
//         editedSchedule.logicType &&
//         finalScheduleDate &&
//         editedSchedule.period !== undefined &&
//         editedSchedule.nextRun
//       ) {
//         try {
//           const updatedLogic = await updateLogic(workflowId, logicId, {
//             ...editedSchedule,
//             scheduleDate: finalScheduleDate,
//           });
//           setSchedules(schedules.map(s => (s.logicId === logicId ? { ...s, ...updatedLogic } : s)));
//           setEditingId(null);
//           setEditedSchedule({});
//         } catch (error) {
//           console.error('Failed to update logic:', error);
//         }
//       } else {
//         alert('Please fill in all fields.');
//       }
//     });
//     setShowConfirmAlert(true); // Show the confirmation dialog
//   };

//   // Handler to cancel edit
//   const handleCancelEdit = () => {
//     setEditingId(null);
//     setEditedSchedule({});
//   };

//   // Handler to delete selected rows
//   const handleDeleteSelectedRows = async () => {
//     if (selectedRows.length > 0) {
//       setConfirmMessage('Are you sure you want to delete the selected logic rows? This action cannot be undone.');
//       setConfirmType('warning');
//       setActionToConfirm(() => async () => {
//         try {
//           await deleteLogics(workflowId, selectedRows);
//           setSchedules(schedules.filter(schedule => !selectedRows.includes(schedule.logicId)));
//           setSelectedRows([]);
//         } catch (error) {
//           alert('Failed to delete logic rows');
//         }
//       });
//       setShowConfirmAlert(true); // Show the confirmation dialog
//     } else {
//       alert('No schedules selected for deletion.');
//     }
//   };

//   // Filtered schedules based on search term
//   const filteredSchedules = schedules.filter((schedule) =>
//     schedule.scheduleName ? schedule.scheduleName.toLowerCase().includes(searchTerm.toLowerCase()) : false
//   );  

//   return (
//     <div className="mb-4 p-4">
//       {/* Workflow Header */}
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex items-center">
//           <LuWorkflow className="text-cyan-800 text-xl mr-2 cursor-pointer"/>
//           <h3 className="text-xl font-semibold text-cyan-800">Workflow Logic: {workflowName}</h3>
//         </div>
//         <div className="flex items-center space-x-2">
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={() => setIsMultiSelectEnabled(!isMultiSelectEnabled)}
//           >
//             <MdLibraryAddCheck />
//           </button>
//           <input
//             type="text"
//             placeholder="Search schedules..."
//             className="px-4 py-2 border rounded"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleAddScheduleDirectly}
//           >
//             <BiSolidAlarmAdd />
//           </button>
//           <button
//             className="text-cyan-800 text-xl p-2"
//             onClick={handleDeleteSelectedRows}
//           >
//             <AiFillDelete />
//           </button>
//         </div>
//       </div>

//       {/* Schedules Table */}
//       <div className="overflow-x-auto">
//         <table className="w-[1200px] bg-white border text-sm">
//           <thead>
//             <tr className="bg-gray-200 text-cyan-800 font-semibold">
//               {/* Select All Checkbox */}
//               {isMultiSelectEnabled && (
//                 <th className="py-2 px-4 border-b accent-cyan-800">
//                   <input 
//                     type="checkbox" 
//                     checked={selectAll} 
//                     onChange={handleSelectAll} 
//                   />
//                 </th>
//               )}
//               <th className="py-2 px-4 border-b">Schedules Name</th>
//               <th className="py-2 px-4 border-b">Email Content</th>
//               <th className="py-2 px-4 border-b">Type</th>
//               <th className="py-2 px-4 border-b">Schedule Date</th>
//               <th className="py-2 px-4 border-b">Period (Days)</th>
//               <th className="py-2 px-4 border-b">Next Run</th>
//               <th className="py-2 px-4 border-b">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredSchedules.length > 0 ? (
//               filteredSchedules.map((schedule) => (
//                 <tr 
//                   key={schedule.logicId} 
//                   className="text-center text-xs accent-cyan-800 hover:bg-gray-50 cursor-pointer border-b"
//                   onClick={(e) => {
//                     if (isMultiSelectEnabled) {
//                       if (selectedRows.includes(schedule.logicId)) {
//                         setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                       } else {
//                         setSelectedRows([...selectedRows, schedule.logicId]);
//                       }
//                     }
//                   }}
//                   onDoubleClick={() => handleRowDoubleClick(schedule)}
//                 >
//                   {isMultiSelectEnabled && (
//                     <td className="py-2 px-4">
//                       <input
//                         type="checkbox"
//                         checked={selectedRows.includes(schedule.logicId)}
//                         onChange={() => {
//                           if (selectedRows.includes(schedule.logicId)) {
//                             setSelectedRows(selectedRows.filter((id) => id !== schedule.logicId));
//                           } else {
//                             setSelectedRows([...selectedRows, schedule.logicId]);
//                           }
//                         }}
//                       />
//                     </td>
//                   )}

//                   {/* Workflow Logic Name */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="text"
//                         value={editedSchedule.scheduleName}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleName: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       schedule.scheduleName
//                     )}
//                   </td>

//                   {/* Workflow Logic Email Content */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.emailContent}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, emailContent: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select an Email Content</option>
//                         {campaigns && campaigns.map((campaign) => (
//                           <option key={campaign.id} value={campaign.contentTitle}>
//                             {campaign.contentTitle}
//                           </option>
//                         ))}
//                       </select>
//                     ) : (
//                       schedule.emailContent
//                     )}
//                   </td>

//                   {/* Workflow Logic Type */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.logicType}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, logicType: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Choose a Type</option>
//                         <option value="Started">Started</option>
//                         <option value="Follow-Up">Follow-Up</option>
//                         <option value="Ended">Ended</option>
//                       </select>
//                     ) : (
//                       schedule.logicType
//                     )}
//                   </td>

//                   {/* Workflow Logic Schedule Date */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         {editedSchedule.scheduleDate === 'No Apply' ? (
//                           <select
//                             value={editedSchedule.scheduleDate}
//                             onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                             className="border rounded px-2 py-1 w-full"
//                           >
//                             <option value="1979-01-01 12:00:00">No Apply</option>
//                             <option value="">Select Date</option>
//                           </select>
//                         ) : (
//                           <div className="flex justify-normal items-center">
//                             <input
//                               type="datetime-local"
//                               value={editedSchedule.scheduleDate}
//                               onChange={(e) => setEditedSchedule({ ...editedSchedule, scheduleDate: e.target.value })}
//                               className="border rounded px-2 py-1 w-[160px] text-center"
//                             />
//                             <div className="relative cursor-help bg-white border-black">
//                               <button
//                                 className="flex justify-normal items-center text-gray-600 text-sm ml-2"
//                                 onClick={() => setEditedSchedule({ ...editedSchedule, scheduleDate: 'No Apply' })}
//                               >
//                                 <MdUpdateDisabled />
//                               </button>
//                             </div>
//                           </div>
//                         )}
//                       </>
//                     ) : (
//                       <span>{schedule.scheduleDate === 'No Apply' ? 'No Apply' : formatDate(schedule.scheduleDate)}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Period */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <input
//                         type="number"
//                         value={editedSchedule.period}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, period: Number(e.target.value) })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       />
//                     ) : (
//                       <span>{schedule.period === 0 ? 'No Apply' : schedule.period}</span>
//                     )}
//                   </td>

//                   {/* Workflow Logic Next Run */}
//                   <td className="py-2 px-4">
//                     {editingId === schedule.logicId ? (
//                       <select
//                         value={editedSchedule.nextRun}
//                         onChange={(e) => setEditedSchedule({ ...editedSchedule, nextRun: e.target.value })}
//                         className="border rounded px-2 py-1 w-full text-center"
//                       >
//                         <option value="">Select a Next Logic</option>
//                         {workflows && workflows
//                           .filter((workflow) => workflow.workflowType === 'Flow')
//                           .map((workflow) => (
//                             <option key={workflow.workflowId} value={workflow.workflowName}>
//                               {workflow.workflowName}
//                             </option>
//                           ))}
//                       </select>
//                     ) : (
//                       schedule.nextRun
//                     )}
//                   </td>

//                   {/* Workflow Logic Action */}
//                   <td className="py-4 px-4 flex justify-center items-center space-x-1">
//                     {editingId === schedule.logicId ? (
//                       <>
//                         <button
//                           className="text-cyan-800 text-lg p-1"
//                           onClick={() => handleConfirmEdit(schedule.logicId)}
//                         >
//                           <FaSquareCheck />
//                         </button>
//                         <button
//                           className="text-gray-400 text-lg p-1"
//                           onClick={handleCancelEdit}
//                         >
//                           <AiFillCloseSquare />
//                         </button>
//                       </>
//                     ) : (
//                       <>
//                         <span 
//                           className="flex items-center space-x-1 cursor-pointer" 
//                           onClick={() => handleToggleActive(schedule.logicId, schedule.logicStatus)}
//                         >
//                           {schedule.logicStatus === 'On' ? (
//                             <BsToggleOn className="text-cyan-800 text-2xl" />
//                           ) : (
//                             <BsToggleOff className="text-gray-400 text-2xl" />
//                           )}
//                           <span className="text-sm text-gray-700">{schedule.logicStatus === 'On' ? 'On' : 'Off'}</span>
//                         </span>
//                       </>
//                     )}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={9} className="py-8 text-center bg-white">
//                   <span className="text-cyan-800 border-cyan-800 border-opacity-60 border font-semibold text-sm px-4 py-1 rounded-full">
//                     No schedules found
//                   </span>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
      
//       {/* show confirm alert message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type={confirmType}
//           title="Confirm Action"
//           message={confirmMessage}
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={async () => {
//             await actionToConfirm();  
//             setShowConfirmAlert(false);
//           }}
//         />
//       )}

//       {/* Workflow Controls */}
//       <div className="flex justify-end items-center space-x-4 mt-4">
//         <button
//           className="bg-gray-200 text-gray-500 px-4 py-2 rounded-full"
//           onClick={closeWorkflow}
//         >
//           Close Workflow
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;