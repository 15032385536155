// src/components/client/MiniApp/App03_MySubscription.tsx

import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import icon
import { MdOutlineSubscriptions } from 'react-icons/md';
// import context
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { useEnv } from '../../../context/EnvContext';
// import custom hook 
import useSubscriptionData, { Product, Subscription, Plan } from '../../../hooks/Subscription/useSubscriptionData';
import SubscriptionPlan from './App03_MySubscriptionPlan';
import SubscriptionItem from './App03_MySubscriptionItem';
import MySubscriptionRegistration from './App03_MySubscriptionRegistration';
import MySubscriptionRegistrationHub from './App03_MySubscriptionRegistrationHub'

const MySubscription: React.FC = () => {
  // useFunction
  const { theme } = useTheme();
  const { apiUrl } = useEnv();
  const { user } = useAuth();
  const { data, loading, error, formatDate, convertPlanPeriod, getStatusStyle, getHubStatusStyle, fetchData, fetchTrialInfo, calculateRemainingDays } = useSubscriptionData();
  const { products, algoSubscriptions, traderHubSubscriptions } = data;
  // useState
  const [activeTab, setActiveTab] = useState('products');
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<Subscription[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [accountDetails, setAccountDetails] = useState<{
    accountID: string;
    accountType: string;
    accountNumber: string;
    accountPassword: string;
    accountServer: string;
  } | null>(null);

  // State to control whether to show registration form
  const [showRegistration, setShowRegistration] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  
  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  // useEffect
  useEffect(() => {
    if (user && user.email) {
      const userSubscriptions = traderHubSubscriptions.concat(algoSubscriptions).filter((subscription: Subscription) => subscription.email === user.email);
      setFilteredSubscriptions(userSubscriptions);
  
      fetchTrialInfo(user.email, 0)
        .catch(error => {
          console.error('Error fetching trial info:', error);
        });
      }
    }, [user, algoSubscriptions, traderHubSubscriptions, fetchTrialInfo]);

  useEffect(() => {
    if (activeTab === 'subscriptions') {
      // Refetch the subscriptions to get the latest planDays
      fetchData();
    }
  }, [activeTab, fetchData]);

  const getProductImageUrl = (image: string | undefined) => {
    return image ? `${apiUrl}/uploads/forSub/${image}` : "https://via.placeholder.com/150";
  };

  const togglePasswordVisibility = (subscriptionId: number) => {
    setFilteredSubscriptions(prevSubscriptions =>
      prevSubscriptions.map(sub =>
        sub.id === subscriptionId ? { ...sub, showPassword: !sub.showPassword } : sub
      )
    );
  };

  const handleRenewClick = (subscription: Subscription) => {
    setAccountDetails({
      accountID: subscription.accountID,
      accountType: subscription.accountType,
      accountNumber: subscription.accountNumber,
      accountPassword: subscription.accountPassword,
      accountServer: subscription.accountServer,
    });
  
    // Determine the product name to search for
    const productNameToFind = subscription.productName === 'TraderHub' ? 'Trader Hub' : subscription.productName;
  
    // Automatically select the product based on the adjusted product name
    const selectedProduct = products.find(product => 
      product.productName === productNameToFind
    );
  
    if (selectedProduct) {
      setSelectedProduct(selectedProduct);
      setActiveTab('products');
      setShowRegistration(true);
    }
  };  

  // const handleRenewClick = (subscription: Subscription) => {
  //   setAccountDetails({
  //     accountID: subscription.accountID,
  //     accountType: subscription.accountType,
  //     accountNumber: subscription.accountNumber,
  //     accountPassword: subscription.accountPassword,
  //     accountServer: subscription.accountServer,
  //   });
  
  //   // Determine the product name to search for
  //   const productNameToFind = subscription.productName === 'TraderHub' ? 'Trader Hub' : subscription.productName;
  
  //   // Automatically select the product based on the adjusted product name
  //   const selectedProduct = products.find(product => 
  //     product.productName === productNameToFind
  //   );
  
  //   if (selectedProduct) {
  //     setSelectedProduct(selectedProduct);
  //     setActiveTab('products');
  //   }
  // };  

  const renderProductList = () => (
    Array.isArray(products) ? products.map((product: Product) => (
      <div key={product.id} className="flex flex-row items-center rounded-lg p-2 bg-gray-100 sm:flex-col xs:flex-col xs:pb-4">
        <img
          src={getProductImageUrl(product.image)}
          alt={product.productName}
          className="flex w-[150px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
        />
        <div className="mt-4 flex flex-row sm:mt-0 xs:mt-0">
          <div>
            <div 
              className="text-xl font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-base xs:text-center sm:my-0 xs:my-0" 
              style={styles.title}
            >
              {product.productName}
            </div>
            <p 
              className="my-2 text-sm pr-[50px] text-zinc-600 md:text-xs sm:text-xs xs:text-xs sm:text-center xs:text-center sm:px-4 xs:px-4" 
              style={styles.text}
            >
              {product.productDescription}
            </p>
          </div>
        </div>
        <button
          className="px-4 py-2 mr-4 text-sm bg-cyan-800 text-white rounded sm:mr-0 xs:mr-0 xs:text-xs"
          onClick={() => setSelectedProduct(product)}
        >
          More Details
        </button>
      </div>
    )) : null
  );

  const renderSubscriptionList = () => {
    if (filteredSubscriptions.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center p-4 border-dashed border-2 border-gray-300 rounded-lg">
          <p className="text-gray-500 mb-4">You have no subscription products.</p>
          <button
            className="px-4 py-2 bg-cyan-800 text-white rounded"
            onClick={() => setActiveTab('products')}
          >
            Go to Product List
          </button>
        </div>
      );
    }

    return filteredSubscriptions.map((subscription: Subscription) => (
      <SubscriptionItem
        key={subscription.id}
        subscription={subscription}
        getProductImageUrl={getProductImageUrl}
        formatDate={formatDate}
        convertPlanPeriod={convertPlanPeriod}
        getStatusStyle={getStatusStyle}
        getHubStatusStyle={getHubStatusStyle}
        calculateRemainingDays={calculateRemainingDays}
        versionNumber={data.versionNumber}
        togglePasswordVisibility={togglePasswordVisibility}
        setParentActiveTab={setActiveTab}
        setSelectedProduct={setSelectedProduct}
        setAccountDetails={setAccountDetails}
        handleRenewClick={handleRenewClick}
      />
    ));
  };

  const handlePlanSelection = (plan: Plan) => {
    if (selectedProduct && selectedProduct.productName === 'Trader Hub' && selectedProduct.id === 2) {
      // Go for Trader Hub registration form
      setSelectedPlan(plan);
      setShowRegistration(true);
    } else if (selectedProduct && selectedProduct.productName === 'AlgoMatrix' && selectedProduct.id === 1) {
      // Go for AlgoMatrix registration form
      setSelectedPlan(plan);
      setShowRegistration(true);
    } else {
      // Handle other products' registration forms
      setSelectedPlan(plan);
      setShowRegistration(true);
    }
  };  

  // const handlePlanSelection = (plan: Plan) => {
  //   if (selectedProduct && selectedProduct.id === 4) {
  //     // If the selected product is TraderHub, show the TraderHub registration form
  //     setSelectedPlan(plan);
  //     setShowRegistration(true);
  //   } else {
  //     // Show the standard registration form for other products
  //     setSelectedPlan(plan);
  //     setShowRegistration(true);
  //   }
  // };  

  if (loading) {
    return (
      <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
        <div className="flex flex-col space-y-4">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="flex flex-row items-center rounded-lg p-2 bg-gray-100">
              <Skeleton width={150} height={150} className="flex w-[150px] h-[150px] rounded-lg" />
              <div className="flex-1 ml-4">
                <Skeleton height={24} width={`80%`} className="mb-2" />
                <Skeleton height={20} width={`90%`} className="mb-2" />
                <Skeleton height={20} width={`70%`} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2 flex flex-col items-center justify-center">
        <p className="text-red-500 mb-4">Error: {error}</p>
        <button
          className="px-4 py-2 bg-cyan-800 text-white rounded"
          onClick={() => fetchData()} 
        >
          Retry
        </button>
      </div>
    );
  }
  

  return (
    <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
      {showRegistration && selectedPlan ? (
        selectedProduct?.id === 2 ? (
          <MySubscriptionRegistrationHub 
            plan={selectedPlan} 
            onBack={() => setShowRegistration(false)}
          />
        ) : (
          <MySubscriptionRegistration 
            plan={selectedPlan} 
            onBack={() => setShowRegistration(false)}
            accountDetails={accountDetails}
          />
        )
      ) : (
        <>
      <h2
        className="flex justify-center items-center text-2xl font-bold mb-4 text-center sm:text-xl xs:text-base"
        style={styles.title}
      >
        <MdOutlineSubscriptions className="mr-2 sm:mr-0 xs:mr-2" />
          R One Subscription Products
      </h2>
      <p className="text-center mb-4 sm:mb-2 xs:mb-2 sm:text-sm xs:text-xs" style={styles.text}>
        Master R One skills and strategies effortlessly with R One Product. Our subscription platform offers user-friendly tutorials and expert guidance for fast, effective learning. Achieve quick mastery and excel with ease.
      </p>
      <div className="flex justify-center mb-2 sm:text-sm sm:mb-4 xs:text-xs">
        <button
          className={`px-9 py-2 ${activeTab === 'products' ? 'bg-cyan-800 text-white rounded-l-xl px-9' : 'bg-gray-200 text-black rounded-l-xl'}`}
          onClick={() => { setActiveTab('products'); setSelectedProduct(null); }}
        >
          Products List
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'subscriptions' ? 'bg-cyan-800 text-white rounded-r-xl' : 'bg-gray-200 text-black rounded-r-xl'}`}
          onClick={() => { setActiveTab('subscriptions'); setSelectedProduct(null); }}
        >
          My Subscriptions
        </button>
      </div>
      <div className="overflow-y-auto h-auto max-h-screen space-y-4 xl:h-[380px] lg:h-[380px] md:h-[370px] sm:h-[325px] xs:h-[315px]">
        {selectedProduct ? (
          <SubscriptionPlan
            plans={selectedProduct.plans}
            onBackToProducts={() => setSelectedProduct(null)}
            accountDetails={accountDetails}
            onPlanSelect={handlePlanSelection}
          />
        ) : (
          activeTab === 'products' ? renderProductList() : renderSubscriptionList()
        )}
      </div>
     </>
    )}
   </div>
  );
};

export default MySubscription;


// // udpate code --> 02 Sept 2024
// // src/components/client/MiniApp/App03_MySubscription.tsx

// import React, { useState, useEffect } from 'react';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
// // import icon
// import { MdOutlineSubscriptions } from 'react-icons/md';
// // import context
// import { useTheme } from '../../../context/ThemeContext';
// import { useAuth } from '../../../context/AuthContext';
// import { useEnv } from '../../../context/EnvContext';
// // import custom hook 
// import useSubscriptionData, { Product, Subscription, Plan } from '../../../hooks/Subscription/useSubscriptionData';
// import SubscriptionPlan from './App03_MySubscriptionPlan';
// import SubscriptionItem from './App03_MySubscriptionItem';
// import MySubscriptionRegistration from './App03_MySubscriptionRegistration';
// import MySubscriptionRegistrationHub from './App03_MySubscriptionRegistrationHub'

// const MySubscription: React.FC = () => {
//   // useFunction
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();
//   const { data, loading, error, formatDate, convertPlanPeriod, getStatusStyle, getHubStatusStyle, fetchData, fetchTrialInfo, calculateRemainingDays } = useSubscriptionData();
//   const { products, algoSubscriptions, traderHubSubscriptions } = data;
//   // useState
//   const [activeTab, setActiveTab] = useState('products');
//   const [filteredSubscriptions, setFilteredSubscriptions] = useState<Subscription[]>([]);
//   const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
//   const [accountDetails, setAccountDetails] = useState<{
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null>(null);

//   // State to control whether to show registration form
//   const [showRegistration, setShowRegistration] = useState(false);
//   const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  
//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // useEffect
//   useEffect(() => {
//     if (user && user.email) {
//       const userSubscriptions = traderHubSubscriptions.concat(algoSubscriptions).filter((subscription: Subscription) => subscription.email === user.email);
//       setFilteredSubscriptions(userSubscriptions);
  
//       fetchTrialInfo(user.email, 0)
//         .catch(error => {
//           console.error('Error fetching trial info:', error);
//         });
//       }
//     }, [user, algoSubscriptions, traderHubSubscriptions, fetchTrialInfo]);

//   useEffect(() => {
//     if (activeTab === 'subscriptions') {
//       // Refetch the subscriptions to get the latest planDays
//       fetchData();
//     }
//   }, [activeTab, fetchData]);

//   const getProductImageUrl = (image: string | undefined) => {
//     return image ? `${apiUrl}/uploads/forSub/${image}` : "https://via.placeholder.com/150";
//   };

//   const togglePasswordVisibility = (subscriptionId: number) => {
//     setFilteredSubscriptions(prevSubscriptions =>
//       prevSubscriptions.map(sub =>
//         sub.id === subscriptionId ? { ...sub, showPassword: !sub.showPassword } : sub
//       )
//     );
//   };

//   const handleRenewClick = (subscription: Subscription) => {
//     setAccountDetails({
//       accountID: subscription.accountID,
//       accountType: subscription.accountType,
//       accountNumber: subscription.accountNumber,
//       accountPassword: subscription.accountPassword,
//       accountServer: subscription.accountServer,
//     });
//     setSelectedProduct(subscription.product || null);
//     setActiveTab('products');
//   };

//   const renderProductList = () => (
//     Array.isArray(products) ? products.map((product: Product) => (
//       <div key={product.id} className="flex flex-row items-center rounded-lg p-2 bg-gray-100 sm:flex-col xs:flex-col xs:pb-4">
//         <img
//           src={getProductImageUrl(product.image)}
//           alt={product.productName}
//           className="flex w-[150px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//         />
//         <div className="mt-4 flex flex-row sm:mt-0 xs:mt-0">
//           <div>
//             <div 
//               className="text-xl font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-base xs:text-center sm:my-0 xs:my-0" 
//               style={styles.title}
//             >
//               {product.productName}
//             </div>
//             <p 
//               className="my-2 text-sm pr-[50px] text-zinc-600 md:text-xs sm:text-xs xs:text-xs sm:text-center xs:text-center sm:px-4 xs:px-4" 
//               style={styles.text}
//             >
//               {product.productDescription}
//             </p>
//           </div>
//         </div>
//         <button
//           className="px-4 py-2 mr-4 text-sm bg-cyan-800 text-white rounded sm:mr-0 xs:mr-0 xs:text-xs"
//           onClick={() => setSelectedProduct(product)}
//         >
//           More Details
//         </button>
//       </div>
//     )) : null
//   );

//   const renderSubscriptionList = () => {
//     if (filteredSubscriptions.length === 0) {
//       return (
//         <div className="flex flex-col items-center justify-center p-4 border-dashed border-2 border-gray-300 rounded-lg">
//           <p className="text-gray-500 mb-4">You have no subscription products.</p>
//           <button
//             className="px-4 py-2 bg-cyan-800 text-white rounded"
//             onClick={() => setActiveTab('products')}
//           >
//             Go to Product List
//           </button>
//         </div>
//       );
//     }

//     return filteredSubscriptions.map((subscription: Subscription) => (
//       <SubscriptionItem
//         key={subscription.id}
//         subscription={subscription}
//         getProductImageUrl={getProductImageUrl}
//         formatDate={formatDate}
//         convertPlanPeriod={convertPlanPeriod}
//         getStatusStyle={getStatusStyle}
//         getHubStatusStyle={getHubStatusStyle}
//         calculateRemainingDays={calculateRemainingDays}
//         versionNumber={data.versionNumber}
//         togglePasswordVisibility={togglePasswordVisibility}
//         setParentActiveTab={setActiveTab}
//         setSelectedProduct={setSelectedProduct}
//         setAccountDetails={setAccountDetails}
//         handleRenewClick={handleRenewClick}
//       />
//     ));
//   };

//   const handlePlanSelection = (plan: Plan) => {
//     if (selectedProduct && selectedProduct.id === 4) {
//       // If the selected product is TraderHub, show the TraderHub registration form
//       setSelectedPlan(plan);
//       setShowRegistration(true);
//     } else {
//       // Show the standard registration form for other products
//       setSelectedPlan(plan);
//       setShowRegistration(true);
//     }
//   };  

//   if (loading) {
//     return (
//       <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
//         <div className="flex flex-col space-y-4">
//           {[...Array(3)].map((_, index) => (
//             <div key={index} className="flex flex-row items-center rounded-lg p-2 bg-gray-100">
//               <Skeleton width={150} height={150} className="flex w-[150px] h-[150px] rounded-lg" />
//               <div className="flex-1 ml-4">
//                 <Skeleton height={24} width={`80%`} className="mb-2" />
//                 <Skeleton height={20} width={`90%`} className="mb-2" />
//                 <Skeleton height={20} width={`70%`} />
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2 flex flex-col items-center justify-center">
//         <p className="text-red-500 mb-4">Error: {error}</p>
//         <button
//           className="px-4 py-2 bg-cyan-800 text-white rounded"
//           onClick={() => fetchData()} 
//         >
//           Retry
//         </button>
//       </div>
//     );
//   }
  

//   return (
//     <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
//       {showRegistration && selectedPlan ? (
//         selectedProduct?.id === 4 ? (
//           <MySubscriptionRegistrationHub 
//             plan={selectedPlan} 
//             onBack={() => setShowRegistration(false)}
//           />
//         ) : (
//           <MySubscriptionRegistration 
//             plan={selectedPlan} 
//             onBack={() => setShowRegistration(false)}
//             accountDetails={accountDetails}
//           />
//         )
//       ) : (
//         <>
//       <h2
//         className="flex justify-center items-center text-2xl font-bold mb-4 text-center sm:text-xl xs:text-base"
//         style={styles.title}
//       >
//         <MdOutlineSubscriptions className="mr-2 sm:mr-0 xs:mr-2" />
//           R One Subscription Products
//       </h2>
//       <p className="text-center mb-4 sm:mb-2 xs:mb-2 sm:text-sm xs:text-xs" style={styles.text}>
//         Master R One skills and strategies effortlessly with R One Product. Our subscription platform offers user-friendly tutorials and expert guidance for fast, effective learning. Achieve quick mastery and excel with ease.
//       </p>
//       <div className="flex justify-center mb-2 sm:text-sm sm:mb-4 xs:text-xs">
//         <button
//           className={`px-9 py-2 ${activeTab === 'products' ? 'bg-cyan-800 text-white rounded-l-xl px-9' : 'bg-gray-200 text-black rounded-l-xl'}`}
//           onClick={() => { setActiveTab('products'); setSelectedProduct(null); }}
//         >
//           Products List
//         </button>
//         <button
//           className={`px-4 py-2 ${activeTab === 'subscriptions' ? 'bg-cyan-800 text-white rounded-r-xl' : 'bg-gray-200 text-black rounded-r-xl'}`}
//           onClick={() => { setActiveTab('subscriptions'); setSelectedProduct(null); }}
//         >
//           My Subscriptions
//         </button>
//       </div>
//       <div className="overflow-y-auto h-auto max-h-screen space-y-4 xl:h-[380px] lg:h-[380px] md:h-[370px] sm:h-[325px] xs:h-[315px]">
//         {selectedProduct ? (
//           <SubscriptionPlan
//             plans={selectedProduct.plans}
//             onBackToProducts={() => setSelectedProduct(null)}
//             accountDetails={accountDetails}
//             onPlanSelect={handlePlanSelection}
//           />
//         ) : (
//           activeTab === 'products' ? renderProductList() : renderSubscriptionList()
//         )}
//       </div>
//      </>
//     )}
//    </div>
//   );
// };

// export default MySubscription;


// // update code --> 27 Aug 2024
// // src/components/client/MiniApp/App03_MySubscription.tsx

// import React, { useState, useEffect } from 'react';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
// // import icon
// import { MdOutlineSubscriptions } from 'react-icons/md';
// // import context
// import { useTheme } from '../../../context/ThemeContext';
// import { useAuth } from '../../../context/AuthContext';
// import { useEnv } from '../../../context/EnvContext';
// // import custom hook 
// import useSubscriptionData, { Product, Subscription, Plan } from '../../../hooks/Subscription/useSubscriptionData';
// import SubscriptionPlan from './App03_MySubscriptionPlan';
// import SubscriptionItem from './App03_MySubscriptionItem';
// import MySubscriptionRegistration from './App03_MySubscriptionRegistration';

// const MySubscription: React.FC = () => {
//   // useFunction
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();
//   const { data, loading, error, formatDate, convertPlanPeriod, getStatusStyle, getHubStatusStyle, fetchData, fetchTrialInfo, calculateRemainingDays } = useSubscriptionData();
//   const { products, algoSubscriptions, traderHubSubscriptions } = data;
//   // useState
//   const [activeTab, setActiveTab] = useState('products');
//   const [filteredSubscriptions, setFilteredSubscriptions] = useState<Subscription[]>([]);
//   const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
//   const [accountDetails, setAccountDetails] = useState<{
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null>(null);

//   // State to control whether to show registration form
//   const [showRegistration, setShowRegistration] = useState(false);
//   const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  
//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // useEffect
//   useEffect(() => {
//     if (user && user.email) {
//       const userSubscriptions = traderHubSubscriptions.concat(algoSubscriptions).filter((subscription: Subscription) => subscription.email === user.email);
//       setFilteredSubscriptions(userSubscriptions);
  
//       fetchTrialInfo(user.email, 0)
//         .catch(error => {
//           console.error('Error fetching trial info:', error);
//         });
//       }
//     }, [user, algoSubscriptions, traderHubSubscriptions, fetchTrialInfo]);

//   useEffect(() => {
//     if (activeTab === 'subscriptions') {
//       // Refetch the subscriptions to get the latest planDays
//       fetchData();
//     }
//   }, [activeTab, fetchData]);

//   const getProductImageUrl = (image: string | undefined) => {
//     return image ? `${apiUrl}/uploads/forSub/${image}` : "https://via.placeholder.com/150";
//   };

//   const togglePasswordVisibility = (subscriptionId: number) => {
//     setFilteredSubscriptions(prevSubscriptions =>
//       prevSubscriptions.map(sub =>
//         sub.id === subscriptionId ? { ...sub, showPassword: !sub.showPassword } : sub
//       )
//     );
//   };

//   const handleRenewClick = (subscription: Subscription) => {
//     setAccountDetails({
//       accountID: subscription.accountID,
//       accountType: subscription.accountType,
//       accountNumber: subscription.accountNumber,
//       accountPassword: subscription.accountPassword,
//       accountServer: subscription.accountServer,
//     });
//     setSelectedProduct(subscription.product || null);
//     setActiveTab('products');
//   };

//   const renderProductList = () => (
//     Array.isArray(products) ? products.map((product: Product) => (
//       <div key={product.id} className="flex flex-row items-center rounded-lg p-2 bg-gray-100 sm:flex-col xs:flex-col xs:pb-4">
//         <img
//           src={getProductImageUrl(product.image)}
//           alt={product.productName}
//           className="flex w-[150px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//         />
//         <div className="mt-4 flex flex-row sm:mt-0 xs:mt-0">
//           <div>
//             <div 
//               className="text-xl font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-base xs:text-center sm:my-0 xs:my-0" 
//               style={styles.title}
//             >
//               {product.productName}
//             </div>
//             <p 
//               className="my-2 text-sm pr-[50px] text-zinc-600 md:text-xs sm:text-xs xs:text-xs sm:text-center xs:text-center sm:px-4 xs:px-4" 
//               style={styles.text}
//             >
//               {product.productDescription}
//             </p>
//           </div>
//         </div>
//         <button
//           className="px-4 py-2 mr-4 text-sm bg-cyan-800 text-white rounded sm:mr-0 xs:mr-0 xs:text-xs"
//           onClick={() => setSelectedProduct(product)}
//         >
//           More Details
//         </button>
//       </div>
//     )) : null
//   );

//   const renderSubscriptionList = () => {
//     if (filteredSubscriptions.length === 0) {
//       return (
//         <div className="flex flex-col items-center justify-center p-4 border-dashed border-2 border-gray-300 rounded-lg">
//           <p className="text-gray-500 mb-4">You have no subscription products.</p>
//           <button
//             className="px-4 py-2 bg-cyan-800 text-white rounded"
//             onClick={() => setActiveTab('products')}
//           >
//             Go to Product List
//           </button>
//         </div>
//       );
//     }

//     return filteredSubscriptions.map((subscription: Subscription) => (
//       <SubscriptionItem
//         key={subscription.id}
//         subscription={subscription}
//         getProductImageUrl={getProductImageUrl}
//         formatDate={formatDate}
//         convertPlanPeriod={convertPlanPeriod}
//         getStatusStyle={getStatusStyle}
//         getHubStatusStyle={getHubStatusStyle}
//         calculateRemainingDays={calculateRemainingDays}
//         versionNumber={data.versionNumber}
//         togglePasswordVisibility={togglePasswordVisibility}
//         setParentActiveTab={setActiveTab}
//         setSelectedProduct={setSelectedProduct}
//         setAccountDetails={setAccountDetails}
//         handleRenewClick={handleRenewClick}
//       />
//     ));
//   };

//   const handlePlanSelection = (plan: Plan) => {
//     setSelectedPlan(plan);
//     setShowRegistration(true);
//   };

//   if (loading) {
//     return (
//       <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
//         <div className="flex flex-col space-y-4">
//           {[...Array(3)].map((_, index) => (
//             <div key={index} className="flex flex-row items-center rounded-lg p-2 bg-gray-100">
//               <Skeleton width={150} height={150} className="flex w-[150px] h-[150px] rounded-lg" />
//               <div className="flex-1 ml-4">
//                 <Skeleton height={24} width={`80%`} className="mb-2" />
//                 <Skeleton height={20} width={`90%`} className="mb-2" />
//                 <Skeleton height={20} width={`70%`} />
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2 flex flex-col items-center justify-center">
//         <p className="text-red-500 mb-4">Error: {error}</p>
//         <button
//           className="px-4 py-2 bg-cyan-800 text-white rounded"
//           onClick={() => fetchData()} 
//         >
//           Retry
//         </button>
//       </div>
//     );
//   }
  

//   return (
//     <div className="w-auto h-auto p-8 rounded-lg sm:p-4 xs:p-2">
//       {showRegistration && selectedPlan ? (
//         <MySubscriptionRegistration 
//           plan={selectedPlan} 
//           onBack={() => setShowRegistration(false)}
//           accountDetails={accountDetails}
//         />
//       ) : (
//         <>
//       <h2
//         className="flex justify-center items-center text-2xl font-bold mb-4 text-center sm:text-xl xs:text-base"
//         style={styles.title}
//       >
//         <MdOutlineSubscriptions className="mr-2 sm:mr-0 xs:mr-2" />
//           R One Subscription Products
//       </h2>
//       <p className="text-center mb-4 sm:mb-2 xs:mb-2 sm:text-sm xs:text-xs" style={styles.text}>
//         Master R One skills and strategies effortlessly with R One Product. Our subscription platform offers user-friendly tutorials and expert guidance for fast, effective learning. Achieve quick mastery and excel with ease.
//       </p>
//       <div className="flex justify-center mb-2 sm:text-sm sm:mb-4 xs:text-xs">
//         <button
//           className={`px-9 py-2 ${activeTab === 'products' ? 'bg-cyan-800 text-white rounded-l-xl px-9' : 'bg-gray-200 text-black rounded-l-xl'}`}
//           onClick={() => { setActiveTab('products'); setSelectedProduct(null); }}
//         >
//           Products List
//         </button>
//         <button
//           className={`px-4 py-2 ${activeTab === 'subscriptions' ? 'bg-cyan-800 text-white rounded-r-xl' : 'bg-gray-200 text-black rounded-r-xl'}`}
//           onClick={() => { setActiveTab('subscriptions'); setSelectedProduct(null); }}
//         >
//           My Subscriptions
//         </button>
//       </div>
//       <div className="overflow-y-auto h-auto max-h-screen space-y-4 xl:h-[380px] lg:h-[380px] md:h-[370px] sm:h-[325px] xs:h-[315px]">
//         {selectedProduct ? (
//           <SubscriptionPlan
//             plans={selectedProduct.plans}
//             onBackToProducts={() => setSelectedProduct(null)}
//             accountDetails={accountDetails}
//             onPlanSelect={handlePlanSelection}
//           />
//         ) : (
//           activeTab === 'products' ? renderProductList() : renderSubscriptionList()
//         )}
//       </div>
//      </>
//     )}
//    </div>
//   );
// };

// export default MySubscription;