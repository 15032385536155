// src/components/admin/VideoPlayerModal.tsx

import React, { useState, useEffect } from 'react';
// import icon
import { IoMdCloseCircle } from "react-icons/io";
import { FaToggleOff, FaToggleOn } from "react-icons/fa6";
// import plyr components
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

interface VideoPlayerModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoSrc: string;
  videoDuration: string;
  videoTitle: string;
}

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({ isOpen, onClose, videoSrc, videoDuration, videoTitle }) => {
  const [isDarkMode, setIsDarkMode] = useState(true); 
  const [playerMounted, setPlayerMounted] = useState(false); 

  // Effect to handle when the modal opens and closes
  useEffect(() => {
    if (isOpen) {
      setPlayerMounted(true);
    } else {
      setPlayerMounted(false);
    }

    return () => {
      setPlayerMounted(false); 
    };
  }, [isOpen]);

  // Video options for Plyr player
  const videoOptions = {
    type: 'video',
    sources: [
      {
        src: videoSrc,
        type: 'video/mp4',
      },
    ],
  } as Plyr.SourceInfo;

  // Toggle between dark and light mode
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  // If the modal is not open, do not render anything
  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 flex justify-center items-center z-50 ${
        isDarkMode ? 'bg-black bg-opacity-75' : 'bg-white bg-opacity-90'
      }`}
    >
      <div className={`w-full max-w-4xl p-6 rounded-lg shadow-2xl relative ${isDarkMode ? 'bg-gray-800 border border-gray-800': 'bg-white border-gray-100'}`}>
        <div className="flex justify-end items-center">
          <button 
            onClick={onClose} 
            className="text-red-500 font-bold text-lg mb-4"
          >
            <IoMdCloseCircle />
          </button>
        </div>
        <div className="p-4 rounded-lg">
            {/* Render Plyr only when the playerMounted state is true */}
            {playerMounted && <Plyr source={videoOptions} />}
            
            <div className="flex justify-between items-center mt-4">
                {/* Display video title and duration */}
                <div className="flex flex-col">
                  <div className="flex justify-normal items-center">
                    <span className={`font-semibold text-xl ${isDarkMode ? 'text-white' : 'text-cyan-800'}`}>Video Title:</span>
                    <span className={`ml-2 text-xl ${isDarkMode ? 'text-white' : 'text-cyan-800'}`}>{videoTitle}</span>
                  </div>
                  <div className="flex justify-normal items-center mt-2">
                    <span className={`text-sm ${isDarkMode ? 'text-gray-50': 'text-gray-800'}`}>Duration:</span>
                    <span className={`ml-2 text-sm ${isDarkMode ? 'text-yellow-500' : 'text-rose-600'}`}>{videoDuration}</span>
                  </div>
                </div>
                
                {/* Theme toggle button */}
                <button
                  onClick={toggleTheme}
                  className="text-2xl rounded-lg"
                >
                  {isDarkMode ? <FaToggleOn className="text-white" /> : <FaToggleOff className="text-cyan-800" />}
                </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerModal;




// // udpate code --> 04 Oct 2024
// // src/components/admin/VideoPlayerModal.tsx

// import React, { useState } from 'react';
// // import icon
// import { IoMdCloseCircle } from "react-icons/io";
// // import plyr components
// import Plyr from 'plyr-react';
// import 'plyr-react/plyr.css';

// interface VideoPlayerModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   videoSrc: string;
//   videoDuration: string; 
// }

// const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({ isOpen, onClose, videoSrc, videoDuration }) => {
//   const [isDarkMode, setIsDarkMode] = useState(true); // State for dark/light mode

//   if (!isOpen) return null;

//   // Video options for Plyr player
//   const videoOptions = {
//     type: 'video',
//     sources: [
//       {
//         src: videoSrc,
//         type: 'video/mp4',
//       },
//     ],
//   } as Plyr.SourceInfo;

//   // Toggle between dark and light mode
//   const toggleTheme = () => {
//     setIsDarkMode(!isDarkMode);
//   };

//   return (
//     <div
//       className={`fixed inset-0 flex justify-center items-center z-50 ${
//         isDarkMode ? 'bg-black bg-opacity-90' : 'bg-white bg-opacity-90'
//       }`}
//     >
//       <div className="bg-white w-full max-w-4xl p-6 rounded-lg relative">
//         <div className="flex justify-between items-center">
//           <button 
//             onClick={onClose} 
//             className="text-red-500 font-bold text-lg"
//           >
//             <IoMdCloseCircle />
//           </button>
//           {/* Theme toggle button */}
//           <button
//             onClick={toggleTheme}
//             className="bg-gray-200 text-black px-3 py-1 rounded-lg hover:bg-gray-300"
//           >
//             {isDarkMode ? 'Light Mode' : 'Dark Mode'}
//           </button>
//         </div>
//         {/* Display video duration */}
//         <p className="text-sm text-gray-600 mb-2">Duration: {videoDuration}</p>
//         <Plyr source={videoOptions} />
//       </div>
//     </div>
//   );
// };

// export default VideoPlayerModal;





