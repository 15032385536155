// src/hooks/Subscription/useSubscriptionSubmit.tsx

import { useState } from 'react';
import { useEnv } from '../../context/EnvContext';
import useUserDashboard from '../Dashboard/useUserDashboard'; 

export interface SubscriptionData {
  orderID: string;
  productID: string;
  planID: string;
  trialID?: string;
  voucherID?: string;
  planName: string;
  userName: string;
  email: string;
  referralEmail: string;
  planType: string;
  planAmount: string;
  planSubDate: string;
  planExpiredDate: string;
  planPeriod?: string | null;
  planDays?: number | null;
  planTrialPeriod?: string | null;
  planTrialDays?: number | null;
  planExpiredTrialDate: string;
  accountID?: string;
  accountStatus?: string;
  accountType?: string;
  accountServer?: string;
  accountNumber?: string;
  accountPassword?: string;
  accountVoucherCode?: string;
  demoAccountType?: string;
  demoAccountServer?: string;
  demoAccountNumber?: string;
  demoAccountPassword?: string;
  PaymentMethod?: string;
  PaymentReceipts?: string;
  notes?: string;
  termAndCondition?: string;
  voucherCode?: string;
  voucherDays?: string;
  voucherRedeemCount?: string;
}
export interface SubscriptionHubData {
  planID?: string;
  orderID: string;
  name: string;
  phone: string;
  email: string;
  referralEmail: string;
  planNameHub: string;
  planTypeHub: string;
  planPeriodHub: string;
  planPeriodTrialHub: string;
  planTrialDays?: number; 
  planAmountHub: string;
  voucherCodeHub: string;
  renewalDate: string;
  expireDays: string;
  accountType: string;
  accountServer: string;
  accountNumber: string;
  accountPassword: string;
  PaymentMethodHub: string;
  PaymentReceiptsHub: string;
  notes: string;
  memberType: { type: string };
  image: string;
  termAgreed: boolean;
}

const useSubscriptionSubmit = () => {
  // use context and other hook
  const { apiUrl } = useEnv();
  const { user } = useUserDashboard(); 
  // use state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const generateOrderID = () => {
    return 'sub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
  };

const fetchVoucherDetails = async (voucherCode: string, planID: number, applyVoucher = false) => {
  // Force the planID to be either 2, or 4
  const allowedPlanIDs = [2, 4];
  const forcedPlanID = allowedPlanIDs.includes(planID) ? planID : 2;
  // 
  try {
    const response = await fetch(`${apiUrl}/api/vouchers?voucherCode=${voucherCode}&planID=${forcedPlanID}&applyVoucher=${applyVoucher}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === 'Voucher is expired') {
        throw new Error('The voucher is expired and cannot be used.');
      }
      if (errorData.error === 'Voucher has no remaining redeems') {
        throw new Error('The voucher has no remaining redeems.');
      }
      throw new Error('Failed to fetch voucher details');
    }

    const vouchers = await response.json();
    return vouchers[0];
  } catch (error) {
    return null;
  }
};


  // const fetchVoucherDetails = async (voucherCode: string, planID: number, applyVoucher = false) => {
  //   try {
  //     const response = await fetch(`${apiUrl}/api/vouchers?voucherCode=${voucherCode}&planID=${4}&applyVoucher=${applyVoucher}`, {
  //       method: 'GET',
  //       headers: { 'Content-Type': 'application/json' }
  //     });
  
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       if (errorData.error === 'Voucher is expired') {
  //         throw new Error('The voucher is expired and cannot be used.');
  //       }
  //       if (errorData.error === 'Voucher has no remaining redeems') {
  //         throw new Error('The voucher has no remaining redeems.');
  //       }
  //       throw new Error('Failed to fetch voucher details');
  //     }
  
  //     const vouchers = await response.json();
  //     return vouchers[0];
  //   } catch (error) {
  //     return null;
  //   }
  // };  

  // Check if the user is entitled to redeem the voucher
  const checkUserVoucherEntitlement = async (
    voucherCode: string, 
    voucherMemberTypes: string[], 
    voucherGroupMember: string
  ) => {
    try {
        if (!user?.email) {
            throw new Error('User email is required.');
        }

        const response = await fetch(
          `${apiUrl}/api/check-voucher-entitlement?email=${encodeURIComponent(user.email)}&voucherCode=${encodeURIComponent(voucherCode)}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          }
        );

        if (!response.ok) {
            throw new Error('Failed to check voucher entitlement.');
        }

        const data = await response.json();
        // console.log('Entitlement check response:', data); // Log the response for debugging

        return data.isEntitled;
    } catch (error) {
        console.error('Error checking voucher entitlement:', error);
        return false;
    }
  };

  const updateVoucherDaysInDatabase = async (voucherID: string, voucherDays: number) => {
    try {
      const response = await fetch(`${apiUrl}/api/vouchers/update-days`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ voucherID, voucherDays }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update voucher days');
      }
    } catch (error) {
      console.error('Error updating voucher days:', error);
    }
  };  

  const fetchAndUpdateUserAlgoMarixData = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/user-voucher-check`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch or update user AlgoMarix data');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching or updating user AlgoMarix data:', error);
      return null;
    }
  };

  const checkUserTrialSubscription = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/user-trial-subscription?email=${email}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user trial subscription');
      }
      const data = await response.json();
      return data.hasTrial;
    } catch (error) {
      console.error('Error fetching user trial subscription:', error);
      return false;
    }
  };

  const checkDemoSubscription = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/check-demo-subscription?email=${email}`);
      if (!response.ok) {
        throw new Error('Failed to check demo subscription');
      }
      const data = await response.json();
      return data.hasDemoSubscription;
    } catch (error) {
      console.error('Error checking demo subscription:', error);
      return false;
    }
  };

  const uploadPaymentReceipts = async (orderID: string, receipts: File[]) => {
    const formData = new FormData();
    formData.append('orderID', orderID);
    if (receipts.length > 0) {
        formData.append('PaymentReceipt', receipts[0]);
    }

    try {
        const response = await fetch(`${apiUrl}/api/upload-receipt`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to upload payment receipt');
        }

        const data = await response.json();
        const filePath = data.filePath;
        const filename = filePath.split('\\').pop(); 
        return filename;
    } catch (error) {
        console.error('Error uploading payment receipt:', error);
        throw error;
    }
  }; 

  const uploadHubPaymentReceipts = async (orderID: string, receipts: File[]) => {
    const formData = new FormData();
    formData.append('orderID', orderID);
    if (receipts.length > 0) {
        formData.append('PaymentReceiptsHub', receipts[0]);
    }

    try {
        const response = await fetch(`${apiUrl}/api/upload-hub-receipt`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to upload payment receipt');
        }

        const data = await response.json();
        const filePath = data.filePath;
        const filename = filePath.split('\\').pop(); 
        return filename;
    } catch (error) {
        console.error('Error uploading payment receipt:', error);
        throw error;
    }
  }; 

  const submitSubscription = async (
    data: Omit<SubscriptionData, 'orderID'> & { isLiveEnabled: boolean, isDemoEnabled: boolean }, 
    receipts: File[]
  ) => {
    setLoading(true);
    setError(null);
    setSuccess(false);
  
    const orderID = generateOrderID();
    let planAmount = parseFloat(data.planAmount);

    try {
      // Fetch existing subscription if planType is "Subscribe" or "Renew"
      let existingSubscription = null;
      if (data.planType === 'Subscribe' || data.planType === 'Renew') {
        const response = await fetch(
          `${apiUrl}/api/subscription?email=${data.email}&accountNumber=${data.accountNumber}`
        );
        if (response.ok) {
          existingSubscription = await response.json();
        }
      }
  
      // If existing subscription found and planDays is not "0", keep original values
      if (existingSubscription && existingSubscription.planDays !== '0') {
        data.planExpiredDate = existingSubscription.planExpiredDate;
        data.planDays = parseInt(existingSubscription.planDays);
        data.accountStatus = existingSubscription.accountStatus;
      }

      // Set accountType based on the user's selection
      if (data.isLiveEnabled) {
        data.accountType = 'LIVE';
      } else if (data.isDemoEnabled) {
        data.accountType = 'DEMO';
      }

      // If a voucher code is applied and the plan type is "Subscribe", save it to accountVoucherCode
      if (data.planType === 'Subscribe' && data.voucherCode) {
        data.accountVoucherCode = data.voucherCode;
      }

      // Handle file upload and extract the file name
      let uploadedFileName = null;
      if (receipts.length > 0) {
        const uploadResponse = await uploadPaymentReceipts(orderID, receipts);
        uploadedFileName = uploadResponse.split('/').pop(); 
      }

      const response = await fetch(`${apiUrl}/api/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          ...data, 
          orderID, 
          planAmount: planAmount, 
          PaymentReceipts: uploadedFileName || data.PaymentReceipts,
        }), 
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit subscription');
      }
  
      setSuccess(true);
    } catch (error) {
      setError((error as Error).message);
      setSuccess(false);
      throw error; 
    } finally {
      setLoading(false);
    }
  
    return orderID;
  }; 

  const submitTraderHubSubscription = async (data: SubscriptionHubData, receipts: File[]) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    // Generate orderID if not provided
    const orderID = data.orderID || 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();

    try {
        // Handle file upload and extract the file name
        let uploadedFileName = null;
        if (receipts.length > 0) {
            const uploadResponse = await uploadHubPaymentReceipts(orderID, receipts);
            uploadedFileName = uploadResponse.split('/').pop(); 
            // Use the uploaded file name in the data object if needed
            data.PaymentReceiptsHub = uploadedFileName;
        }

        const savedPlanPeriodHub = data.planTypeHub === 'trial' ? '' : data.planPeriodHub;
        const savedPlanPeriodTrialHub = data.planTypeHub === 'trial' ? (data.planTrialDays?.toString() || '') : '';

        const response = await fetch(`${apiUrl}/api/subscribe-traderhub`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              ...data, 
              orderID,
              planPeriodHub: savedPlanPeriodHub,       
              planPeriodTrialHub: savedPlanPeriodTrialHub,
            }),
        });

        // Log the response status and body if not successful
        if (!response.ok) {
            const errorBody = await response.json();
            console.error('Failed to submit Trader Hub subscription:', response.status, errorBody);
            throw new Error('Failed to submit Trader Hub subscription');
        }

        setSuccess(true);
        return { success: true };
    } catch (error) {
        setError((error as Error).message);
        setSuccess(false);
        return { success: false, message: (error as Error).message };
    } finally {
        setLoading(false);
    }
};
 

  return { 
    submitSubscription,
    submitTraderHubSubscription, 
    loading, error, success, 
    fetchVoucherDetails, 
    checkUserTrialSubscription, 
    checkDemoSubscription,
    updateVoucherDaysInDatabase,
    fetchAndUpdateUserAlgoMarixData,
    uploadPaymentReceipts,
    checkUserVoucherEntitlement,
  };
};

export default useSubscriptionSubmit;


// // update code -- 12 sept 2024
// // src/hooks/Subscription/useSubscriptionSubmit.tsx

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import useUserDashboard from '../Dashboard/useUserDashboard'; 

// export interface SubscriptionData {
//   orderID: string;
//   productID: string;
//   planID: string;
//   trialID?: string;
//   voucherID?: string;
//   planName: string;
//   userName: string;
//   email: string;
//   referralEmail: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planExpiredDate: string;
//   planPeriod?: string | null;
//   planDays?: number | null;
//   planTrialPeriod?: string | null;
//   planTrialDays?: number | null;
//   planExpiredTrialDate: string;
//   accountID?: string;
//   accountStatus?: string;
//   accountType?: string;
//   accountServer?: string;
//   accountNumber?: string;
//   accountPassword?: string;
//   accountVoucherCode?: string;
//   demoAccountType?: string;
//   demoAccountServer?: string;
//   demoAccountNumber?: string;
//   demoAccountPassword?: string;
//   PaymentMethod?: string;
//   PaymentReceipts?: string;
//   notes?: string;
//   termAndCondition?: string;
//   voucherCode?: string;
//   voucherDays?: string;
//   voucherRedeemCount?: string;
// }
// export interface SubscriptionHubData {
//   planID?: string;
//   orderID: string;
//   name: string;
//   phone: string;
//   email: string;
//   referralEmail: string;
//   planNameHub: string;
//   planTypeHub: string;
//   planPeriodHub: string;
//   planPeriodTrialHub: string;
//   planTrialDays?: number; 
//   planAmountHub: string;
//   voucherCodeHub: string;
//   renewalDate: string;
//   expireDays: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   PaymentMethodHub: string;
//   PaymentReceiptsHub: string;
//   notes: string;
//   memberType: { type: string };
//   image: string;
//   termAgreed: boolean;
// }

// const useSubscriptionSubmit = () => {
//   // use context and other hook
//   const { apiUrl } = useEnv();
//   const { user } = useUserDashboard(); 
//   // use state
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const [success, setSuccess] = useState(false);

//   const generateOrderID = () => {
//     return 'sub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//   };

//   const fetchVoucherDetails = async (voucherCode: string, planID: number, applyVoucher = false) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/vouchers?voucherCode=${voucherCode}&planID=${8}&applyVoucher=${applyVoucher}`, {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' }
//       });
  
//       if (!response.ok) {
//         const errorData = await response.json();
//         if (errorData.error === 'Voucher is expired') {
//           throw new Error('The voucher is expired and cannot be used.');
//         }
//         if (errorData.error === 'Voucher has no remaining redeems') {
//           throw new Error('The voucher has no remaining redeems.');
//         }
//         throw new Error('Failed to fetch voucher details');
//       }
  
//       const vouchers = await response.json();
//       return vouchers[0];
//     } catch (error) {
//       return null;
//     }
//   };  

//   // Check if the user is entitled to redeem the voucher
//   const checkUserVoucherEntitlement = async (
//     voucherCode: string, 
//     voucherMemberTypes: string[], 
//     voucherGroupMember: string
//   ) => {
//     try {
//         if (!user?.email) {
//             throw new Error('User email is required.');
//         }

//         const response = await fetch(
//           `${apiUrl}/api/check-voucher-entitlement?email=${encodeURIComponent(user.email)}&voucherCode=${encodeURIComponent(voucherCode)}`,
//           {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' }
//           }
//         );

//         if (!response.ok) {
//             throw new Error('Failed to check voucher entitlement.');
//         }

//         const data = await response.json();
//         // console.log('Entitlement check response:', data); // Log the response for debugging

//         return data.isEntitled;
//     } catch (error) {
//         console.error('Error checking voucher entitlement:', error);
//         return false;
//     }
//   };

//   const updateVoucherDaysInDatabase = async (voucherID: string, voucherDays: number) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/vouchers/update-days`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ voucherID, voucherDays }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to update voucher days');
//       }
//     } catch (error) {
//       console.error('Error updating voucher days:', error);
//     }
//   };  

//   const fetchAndUpdateUserAlgoMarixData = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/user-voucher-check`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to fetch or update user AlgoMarix data');
//       }
  
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error('Error fetching or updating user AlgoMarix data:', error);
//       return null;
//     }
//   };

//   const checkUserTrialSubscription = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/user-trial-subscription?email=${email}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch user trial subscription');
//       }
//       const data = await response.json();
//       return data.hasTrial;
//     } catch (error) {
//       console.error('Error fetching user trial subscription:', error);
//       return false;
//     }
//   };

//   const checkDemoSubscription = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/check-demo-subscription?email=${email}`);
//       if (!response.ok) {
//         throw new Error('Failed to check demo subscription');
//       }
//       const data = await response.json();
//       return data.hasDemoSubscription;
//     } catch (error) {
//       console.error('Error checking demo subscription:', error);
//       return false;
//     }
//   };

//   const uploadPaymentReceipts = async (orderID: string, receipts: File[]) => {
//     const formData = new FormData();
//     formData.append('orderID', orderID);
//     if (receipts.length > 0) {
//         formData.append('PaymentReceipt', receipts[0]);
//     }

//     try {
//         const response = await fetch(`${apiUrl}/api/upload-receipt`, {
//             method: 'POST',
//             body: formData,
//         });

//         if (!response.ok) {
//             throw new Error('Failed to upload payment receipt');
//         }

//         const data = await response.json();
//         const filePath = data.filePath;
//         const filename = filePath.split('\\').pop(); 
//         return filename;
//     } catch (error) {
//         console.error('Error uploading payment receipt:', error);
//         throw error;
//     }
//   }; 

//   const uploadHubPaymentReceipts = async (orderID: string, receipts: File[]) => {
//     const formData = new FormData();
//     formData.append('orderID', orderID);
//     if (receipts.length > 0) {
//         formData.append('PaymentReceiptsHub', receipts[0]);
//     }

//     try {
//         const response = await fetch(`${apiUrl}/api/upload-hub-receipt`, {
//             method: 'POST',
//             body: formData,
//         });

//         if (!response.ok) {
//             throw new Error('Failed to upload payment receipt');
//         }

//         const data = await response.json();
//         const filePath = data.filePath;
//         const filename = filePath.split('\\').pop(); 
//         return filename;
//     } catch (error) {
//         console.error('Error uploading payment receipt:', error);
//         throw error;
//     }
//   }; 

//   const submitSubscription = async (
//     data: Omit<SubscriptionData, 'orderID'> & { isLiveEnabled: boolean, isDemoEnabled: boolean }, 
//     receipts: File[]
//   ) => {
//     setLoading(true);
//     setError(null);
//     setSuccess(false);
  
//     const orderID = generateOrderID();
//     let planAmount = parseFloat(data.planAmount);

//     try {
//       // Fetch existing subscription if planType is "Subscribe" or "Renew"
//       let existingSubscription = null;
//       if (data.planType === 'Subscribe' || data.planType === 'Renew') {
//         const response = await fetch(
//           `${apiUrl}/api/subscription?email=${data.email}&accountNumber=${data.accountNumber}`
//         );
//         if (response.ok) {
//           existingSubscription = await response.json();
//         }
//       }
  
//       // If existing subscription found and planDays is not "0", keep original values
//       if (existingSubscription && existingSubscription.planDays !== '0') {
//         data.planExpiredDate = existingSubscription.planExpiredDate;
//         data.planDays = parseInt(existingSubscription.planDays);
//         data.accountStatus = existingSubscription.accountStatus;
//       }

//       // Set accountType based on the user's selection
//       if (data.isLiveEnabled) {
//         data.accountType = 'LIVE';
//       } else if (data.isDemoEnabled) {
//         data.accountType = 'DEMO';
//       }

//       // If a voucher code is applied and the plan type is "Subscribe", save it to accountVoucherCode
//       if (data.planType === 'Subscribe' && data.voucherCode) {
//         data.accountVoucherCode = data.voucherCode;
//       }

//       // Handle file upload and extract the file name
//       let uploadedFileName = null;
//       if (receipts.length > 0) {
//         const uploadResponse = await uploadPaymentReceipts(orderID, receipts);
//         uploadedFileName = uploadResponse.split('/').pop(); 
//       }

//       const response = await fetch(`${apiUrl}/api/subscribe`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ 
//           ...data, 
//           orderID, 
//           planAmount: planAmount, 
//           PaymentReceipts: uploadedFileName || data.PaymentReceipts,
//         }), 
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to submit subscription');
//       }
  
//       setSuccess(true);
//     } catch (error) {
//       setError((error as Error).message);
//       setSuccess(false);
//       throw error; 
//     } finally {
//       setLoading(false);
//     }
  
//     return orderID;
//   }; 

//   const submitTraderHubSubscription = async (data: SubscriptionHubData, receipts: File[]) => {
//     setLoading(true);
//     setError(null);
//     setSuccess(false);

//     // Generate orderID if not provided
//     const orderID = data.orderID || 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();

//     try {
//         // Handle file upload and extract the file name
//         let uploadedFileName = null;
//         if (receipts.length > 0) {
//             const uploadResponse = await uploadHubPaymentReceipts(orderID, receipts);
//             uploadedFileName = uploadResponse.split('/').pop(); 
//             // Use the uploaded file name in the data object if needed
//             data.PaymentReceiptsHub = uploadedFileName;
//         }

//         const response = await fetch(`${apiUrl}/api/subscribe-traderhub`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ 
//               ...data, 
//               orderID 
//             }), // Ensure orderID is included
//         });

//         // Log the response status and body if not successful
//         if (!response.ok) {
//             const errorBody = await response.json();
//             console.error('Failed to submit Trader Hub subscription:', response.status, errorBody);
//             throw new Error('Failed to submit Trader Hub subscription');
//         }

//         setSuccess(true);
//         return { success: true };
//     } catch (error) {
//         setError((error as Error).message);
//         setSuccess(false);
//         return { success: false, message: (error as Error).message };
//     } finally {
//         setLoading(false);
//     }
// };
 

//   return { 
//     submitSubscription,
//     submitTraderHubSubscription, 
//     loading, error, success, 
//     fetchVoucherDetails, 
//     checkUserTrialSubscription, 
//     checkDemoSubscription,
//     updateVoucherDaysInDatabase,
//     fetchAndUpdateUserAlgoMarixData,
//     uploadPaymentReceipts,
//     checkUserVoucherEntitlement,
//   };
// };

// export default useSubscriptionSubmit;

// // update code --> 29 Aug 2024
// // src/hooks/Subscription/useSubscriptionSubmit.tsx

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import useUserDashboard from '../Dashboard/useUserDashboard'; 

// export interface SubscriptionData {
//   orderID: string;
//   productID: string;
//   planID: string;
//   trialID?: string;
//   voucherID?: string;
//   planName: string;
//   userName: string;
//   email: string;
//   referralEmail: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planExpiredDate: string;
//   planPeriod?: string | null;
//   planDays?: number | null;
//   planTrialPeriod?: string | null;
//   planTrialDays?: number | null;
//   planExpiredTrialDate: string;
//   accountID?: string;
//   accountStatus?: string;
//   accountType?: string;
//   accountServer?: string;
//   accountNumber?: string;
//   accountPassword?: string;
//   accountVoucherCode?: string;
//   demoAccountType?: string;
//   demoAccountServer?: string;
//   demoAccountNumber?: string;
//   demoAccountPassword?: string;
//   PaymentMethod?: string;
//   PaymentReceipts?: string;
//   notes?: string;
//   termAndCondition?: string;
//   voucherCode?: string;
//   voucherDays?: string;
//   voucherRedeemCount?: string;
// }
// export interface SubscriptionHubData {
//   planID?: string;
//   orderID: string;
//   name: string;
//   phone: string;
//   email: string;
//   referralEmail: string;
//   planNameHub: string;
//   planTypeHub: string;
//   planPeriodHub: string;
//   planAmountHub: string;
//   voucherCodeHub: string;
//   renewalDate: string;
//   expireDays: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   PaymentMethodHub: string;
//   PaymentReceiptsHub: string;
//   notes: string;
//   memberType: { type: string };
//   image: string;
//   termAgreed: boolean;
// }

// const useSubscriptionSubmit = () => {
//   // use context and other hook
//   const { apiUrl } = useEnv();
//   const { user } = useUserDashboard(); 
//   // use state
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const [success, setSuccess] = useState(false);

//   const generateOrderID = () => {
//     return 'sub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//   };

//   const fetchVoucherDetails = async (voucherCode: string, planID: number, applyVoucher = false) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/vouchers?voucherCode=${voucherCode}&planID=${planID}&applyVoucher=${applyVoucher}`, {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' }
//       });
  
//       if (!response.ok) {
//         const errorData = await response.json();
//         if (errorData.error === 'Voucher is expired') {
//           throw new Error('The voucher is expired and cannot be used.');
//         }
//         if (errorData.error === 'Voucher has no remaining redeems') {
//           throw new Error('The voucher has no remaining redeems.');
//         }
//         throw new Error('Failed to fetch voucher details');
//       }
  
//       const vouchers = await response.json();
//       return vouchers[0];
//     } catch (error) {
//       return null;
//     }
//   };  

//   // Check if the user is entitled to redeem the voucher
//   const checkUserVoucherEntitlement = async (
//     voucherCode: string, 
//     voucherMemberTypes: string[], 
//     voucherGroupMember: string
//   ) => {
//     try {
//         if (!user?.email) {
//             throw new Error('User email is required.');
//         }

//         // // Log parameters to check if they are correct
//         // console.log('Checking voucher entitlement with:', {
//         //     email: user.email,
//         //     voucherCode,
//         //     voucherMemberTypes,
//         //     voucherGroupMember
//         // });

//         const response = await fetch(
//           `${apiUrl}/api/check-voucher-entitlement?email=${encodeURIComponent(user.email)}&voucherCode=${encodeURIComponent(voucherCode)}`,
//           {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' }
//           }
//         );

//         if (!response.ok) {
//             throw new Error('Failed to check voucher entitlement.');
//         }

//         const data = await response.json();
//         // console.log('Entitlement check response:', data); // Log the response for debugging

//         return data.isEntitled;
//     } catch (error) {
//         console.error('Error checking voucher entitlement:', error);
//         return false;
//     }
//   };
  
  
//   const updateVoucherDaysInDatabase = async (voucherID: string, voucherDays: number) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/vouchers/update-days`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ voucherID, voucherDays }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to update voucher days');
//       }
//     } catch (error) {
//       console.error('Error updating voucher days:', error);
//     }
//   };  

//   const fetchAndUpdateUserAlgoMarixData = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/user-voucher-check`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to fetch or update user AlgoMarix data');
//       }
  
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error('Error fetching or updating user AlgoMarix data:', error);
//       return null;
//     }
//   };

//   const checkUserTrialSubscription = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/user-trial-subscription?email=${email}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch user trial subscription');
//       }
//       const data = await response.json();
//       return data.hasTrial;
//     } catch (error) {
//       console.error('Error fetching user trial subscription:', error);
//       return false;
//     }
//   };

//   const checkDemoSubscription = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/check-demo-subscription?email=${email}`);
//       if (!response.ok) {
//         throw new Error('Failed to check demo subscription');
//       }
//       const data = await response.json();
//       return data.hasDemoSubscription;
//     } catch (error) {
//       console.error('Error checking demo subscription:', error);
//       return false;
//     }
//   };

//   const uploadPaymentReceipts = async (orderID: string, receipts: File[]) => {
//     const formData = new FormData();
//     formData.append('orderID', orderID);
//     if (receipts.length > 0) {
//         formData.append('PaymentReceipt', receipts[0]);
//     }

//     try {
//         const response = await fetch(`${apiUrl}/api/upload-receipt`, {
//             method: 'POST',
//             body: formData,
//         });

//         if (!response.ok) {
//             throw new Error('Failed to upload payment receipt');
//         }

//         const data = await response.json();
//         const filePath = data.filePath;
//         const filename = filePath.split('\\').pop(); 
//         return filename;
//     } catch (error) {
//         console.error('Error uploading payment receipt:', error);
//         throw error;
//     }
//   }; 

//   const uploadHubPaymentReceipts = async (orderID: string, receipts: File[]) => {
//     const formData = new FormData();
//     formData.append('orderID', orderID);
//     if (receipts.length > 0) {
//         formData.append('PaymentReceiptsHub', receipts[0]);
//     }

//     try {
//         const response = await fetch(`${apiUrl}/api/upload-hub-receipt`, {
//             method: 'POST',
//             body: formData,
//         });

//         if (!response.ok) {
//             throw new Error('Failed to upload payment receipt');
//         }

//         const data = await response.json();
//         const filePath = data.filePath;
//         const filename = filePath.split('\\').pop(); 
//         return filename;
//     } catch (error) {
//         console.error('Error uploading payment receipt:', error);
//         throw error;
//     }
//   }; 

//   const submitSubscription = async (
//     data: Omit<SubscriptionData, 'orderID'> & { isLiveEnabled: boolean, isDemoEnabled: boolean }, 
//     receipts: File[]
//   ) => {
//     setLoading(true);
//     setError(null);
//     setSuccess(false);
  
//     const orderID = generateOrderID();
//     let planAmount = parseFloat(data.planAmount);

//     try {
//       // Fetch existing subscription if planType is "Subscribe" or "Renew"
//       let existingSubscription = null;
//       if (data.planType === 'Subscribe' || data.planType === 'Renew') {
//         const response = await fetch(
//           `${apiUrl}/api/subscription?email=${data.email}&accountNumber=${data.accountNumber}`
//         );
//         if (response.ok) {
//           existingSubscription = await response.json();
//         }
//       }
  
//       // If existing subscription found and planDays is not "0", keep original values
//       if (existingSubscription && existingSubscription.planDays !== '0') {
//         data.planExpiredDate = existingSubscription.planExpiredDate;
//         data.planDays = parseInt(existingSubscription.planDays);
//         data.accountStatus = existingSubscription.accountStatus;
//       }

//       // Set accountType based on the user's selection
//       if (data.isLiveEnabled) {
//         data.accountType = 'LIVE';
//       } else if (data.isDemoEnabled) {
//         data.accountType = 'DEMO';
//       }

//       // If a voucher code is applied and the plan type is "Subscribe", save it to accountVoucherCode
//       if (data.planType === 'Subscribe' && data.voucherCode) {
//         data.accountVoucherCode = data.voucherCode;
//       }

//       // Handle file upload and extract the file name
//       let uploadedFileName = null;
//       if (receipts.length > 0) {
//         const uploadResponse = await uploadPaymentReceipts(orderID, receipts);
//         uploadedFileName = uploadResponse.split('/').pop(); 
//       }

//       const response = await fetch(`${apiUrl}/api/subscribe`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ 
//           ...data, 
//           orderID, 
//           planAmount: planAmount, 
//           PaymentReceipts: uploadedFileName || data.PaymentReceipts,
//         }), 
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to submit subscription');
//       }
  
//       setSuccess(true);
//     } catch (error) {
//       setError((error as Error).message);
//       setSuccess(false);
//       throw error; 
//     } finally {
//       setLoading(false);
//     }
  
//     return orderID;
//   }; 

//   const submitTraderHubSubscription = async (data: SubscriptionHubData, receipts: File[]) => {
//     setLoading(true);
//     setError(null);
//     setSuccess(false);

//     // Generate orderID if not provided
//     const orderID = data.orderID || 'hub-' + Math.random().toString(36).substr(2, 9).toUpperCase();

//     try {
//         // Log the data being sent to the server
//         console.log('Submitting Trader Hub subscription with data:', data);

//         // Handle file upload and extract the file name
//         let uploadedFileName = null;
//         if (receipts.length > 0) {
//             const uploadResponse = await uploadHubPaymentReceipts(orderID, receipts);
//             uploadedFileName = uploadResponse.split('/').pop(); 
//             // Use the uploaded file name in the data object if needed
//             data.PaymentReceiptsHub = uploadedFileName;
//         }

//         const response = await fetch(`${apiUrl}/api/subscribe-traderhub`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ ...data, orderID }), // Ensure orderID is included
//         });

//         // Log the response status and body if not successful
//         if (!response.ok) {
//             const errorBody = await response.json();
//             console.error('Failed to submit Trader Hub subscription:', response.status, errorBody);
//             throw new Error('Failed to submit Trader Hub subscription');
//         }

//         setSuccess(true);
//         return { success: true };
//     } catch (error) {
//         setError((error as Error).message);
//         setSuccess(false);
//         return { success: false, message: (error as Error).message };
//     } finally {
//         setLoading(false);
//     }
// };
 

//   return { 
//     submitSubscription,
//     submitTraderHubSubscription, 
//     loading, error, success, 
//     fetchVoucherDetails, 
//     checkUserTrialSubscription, 
//     checkDemoSubscription,
//     updateVoucherDaysInDatabase,
//     fetchAndUpdateUserAlgoMarixData,
//     uploadPaymentReceipts,
//     checkUserVoucherEntitlement
//   };
// };

// export default useSubscriptionSubmit;
