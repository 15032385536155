// src/hooks/AdminDashboard/useProgram.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface Program {
  id: number;
  name: string;
  timePeriod: string;
  amount: number;
  lotSize: number;
  deposit: number;
  broker: string;
  currency?: 'USD' | 'MYR' | 'FREE';
  startDate: string | null;
  endDate: string | null;
  time: string | null; 
  status: 'running' | 'stopped' | 'expired';
  points?: number;
  description?: string;
  redeemNumber?: number;
  location?: string;
  image?: string | null;
  createdAt?: string;
  slot?: number;
  initialSlot?: number;
  memberType?: string[];
}

export const convertToShortForm = (memberType: string): string => {
  switch (memberType) {
    case "R One Elite Trader":
      return "Elite";
    case "R One Master Trader":
      return "Master";
    case "R One Certified Trader":
      return "Certified";
    case "Trader Hub Member":
      return "TraderHub";
    case "R One Dealer":
      return "Dealer";
    case "R One Member":
      return "Member";
    case "R One Basic":
      return "Basic";
    case "R One Guest":
      return "Guest";
    default:
      return memberType;
  }
};

export const getTagColor = (shortForm: string): string => {
  switch (shortForm) {
    case "Certified":
      return "text-yellow-800";
    case "Dealer":
      return "text-red-800";
    case "Elite":
      return "text-blue-800";
    case "Master":
      return "text-purple-800";
    case "TraderHub":
      return "text-green-800";
    case "Member":
      return "text-sky-800";
    case "Basic":
      return "text-Amber-700";
    case "Guest":
      return "text-gray-500";
    default:
      return "text-yellow-800";
  }
};

export const convertDescription = (text: string): string => {
  if (!text) return '';
  const lines = text.split('\n');
  return lines.map(line => {
    if (line.trim().startsWith('[ ]') && line.trim().endsWith('[ ]')) {
      return `• ${line.replace('[ ]', '').trim()}`;
    }
    return line;
  }).join('\n');
};

export const formatTimeTo12Hour = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  const suffix = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12;
  return `${adjustedHours}:${minutes < 10 ? '0' : ''}${minutes} ${suffix}`;
};

export const formatAmount = (amount: string | number) => {
  const number = typeof amount === 'string' ? parseFloat(amount) : amount;
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};


export const formatDate = (date: string) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const useProgramAPI = () => {
  const { apiUrl } = useEnv();
  const [programs, setPrograms] = useState<Program[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);  

  const fetchPrograms = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/programs`);
      const data = await response.json();
      const parsedData = data.map((event: any) => ({
        ...event,
        memberType: JSON.parse(event.memberType.replace(/\\/g, ''))
      }));
      setPrograms(parsedData);
    } catch (err) {
      setError('Error fetching programs');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);  

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const createProgram = async (formData: FormData) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/programs`, {
        method: 'POST',
        body: formData,
      });
      const createdProgram = await response.json();
      setPrograms((prevPrograms) => [createdProgram, ...prevPrograms]);
      await fetchPrograms();
      return createdProgram;
    } catch (err) {
      setError('Error creating program');
      throw err;
    } finally {
      setLoading(false);
    }
  };  

  const updateProgramStatus = async (programId: number, status: 'running' | 'stopped' | 'expired') => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/programs/${programId}/status`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status }),
      });
      const updatedProgram = await response.json();
      setPrograms((prevPrograms) =>
        prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
      );
      return updatedProgram; 
    } catch (err) {
      setError('Error updating program status');
    } finally {
      setLoading(false);
    }
  };

  const deleteProgram = async (programId: number) => {
    setLoading(true);
    try {
      await fetch(`${apiUrl}/api/programs/${programId}`, {
        method: 'DELETE',
      });
      setPrograms((prevPrograms) => prevPrograms.filter((program) => program.id !== programId));
    } catch (err) {
      setError('Error deleting program');
      throw err;
    } finally {
      setLoading(false);
    }
  };
  
  const updateProgramDetails = async (programId: number, updatedData: Partial<Program>) => {
    setLoading(true);
    try {
      // Ensure memberType is an array before stringifying
      const memberType = Array.isArray(updatedData.memberType) 
        ? updatedData.memberType 
        : (typeof updatedData.memberType === 'string' ? JSON.parse(updatedData.memberType) : []);

      const response = await fetch(`${apiUrl}/api/programs/${programId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...updatedData,
          memberType: JSON.stringify(memberType),
        }),
      });
      const updatedProgram = await response.json();
      setPrograms((prevPrograms) =>
        prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
      );
    } catch (err) {
      setError('Error updating program');
    } finally {
      setLoading(false);
    }
  };     
  
  return { 
    programs, 
    setPrograms, 
    loading, 
    error, 
    fetchPrograms, 
    createProgram, 
    updateProgramStatus, 
    deleteProgram, 
    updateProgramDetails 
  };
};

export default useProgramAPI;

// // udpate code --> 18 Aug 2024
// // src/hooks/AdminDashboard/useProgram.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Program {
//   id: number;
//   name: string;
//   timePeriod: string;
//   amount: number;
//   lotSize: number;
//   deposit: number;
//   broker: string;
//   currency?: 'USD' | 'MYR' | 'FREE';
//   startDate: string | null;
//   endDate: string | null;
//   time: string | null; 
//   status: 'running' | 'stopped' | 'expired';
//   points?: number;
//   description?: string;
//   redeemNumber?: number;
//   location?: string;
//   image?: string | null;
//   createdAt?: string;
//   slot?: number;
//   memberType?: string[];
// }

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     case "R One Guest":
//       return "Guest";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-yellow-800";
//     case "Dealer":
//       return "text-red-800";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-green-800";
//     case "Member":
//       return "text-sky-800";
//     case "Guest":
//       return "text-gray-500";
//     default:
//       return "text-yellow-800";
//   }
// };

// export const convertDescription = (text: string): string => {
//   if (!text) return '';
//   const lines = text.split('\n');
//   return lines.map(line => {
//     if (line.trim().startsWith('[ ]') && line.trim().endsWith('[ ]')) {
//       return `• ${line.replace('[ ]', '').trim()}`;
//     }
//     return line;
//   }).join('\n');
// };

// export const formatTimeTo12Hour = (time: string) => {
//   const [hours, minutes] = time.split(':').map(Number);
//   const suffix = hours >= 12 ? 'PM' : 'AM';
//   const adjustedHours = hours % 12 || 12;
//   return `${adjustedHours}:${minutes < 10 ? '0' : ''}${minutes} ${suffix}`;
// };

// export const formatAmount = (amount: string | number) => {
//   const number = typeof amount === 'string' ? parseFloat(amount) : amount;
//   return new Intl.NumberFormat('en-US', {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2
//   }).format(number);
// };


// export const formatDate = (date: string) => {
//   const d = new Date(date);
//   const month = `${d.getMonth() + 1}`.padStart(2, '0');
//   const day = `${d.getDate()}`.padStart(2, '0');
//   const year = d.getFullYear();
//   return `${year}-${month}-${day}`;
// };

// const useProgramAPI = () => {
//   const { apiUrl } = useEnv();
//   const [programs, setPrograms] = useState<Program[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);  

//   const fetchPrograms = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data = await response.json();
//       const parsedData = data.map((event: any) => ({
//         ...event,
//         memberType: JSON.parse(event.memberType.replace(/\\/g, ''))
//       }));
//       setPrograms(parsedData);
//     } catch (err) {
//       setError('Error fetching programs');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   useEffect(() => {
//     fetchPrograms();
//   }, [fetchPrograms]);

//   const createProgram = async (formData: FormData) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`, {
//         method: 'POST',
//         body: formData,
//       });
//       const createdProgram = await response.json();
//       setPrograms((prevPrograms) => [createdProgram, ...prevPrograms]);
//       await fetchPrograms(); // Fetch the latest programs list
//       return createdProgram;
//     } catch (err) {
//       setError('Error creating program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };  

//   const updateProgramStatus = async (programId: number, status: 'running' | 'stopped' | 'expired') => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs/${programId}/status`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ status }),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//       return updatedProgram; // Return the updated program object
//     } catch (err) {
//       setError('Error updating program status');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const deleteProgram = async (programId: number) => {
//     setLoading(true);
//     try {
//       await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'DELETE',
//       });
//       setPrograms((prevPrograms) => prevPrograms.filter((program) => program.id !== programId));
//     } catch (err) {
//       setError('Error deleting program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };
  
//   const updateProgramDetails = async (programId: number, updatedData: Partial<Program>) => {
//     setLoading(true);
//     try {
//       // Ensure memberType is an array before stringifying
//       const memberType = Array.isArray(updatedData.memberType) 
//         ? updatedData.memberType 
//         : (typeof updatedData.memberType === 'string' ? JSON.parse(updatedData.memberType) : []);

//       const response = await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           ...updatedData,
//           memberType: JSON.stringify(memberType),
//         }),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//     } catch (err) {
//       setError('Error updating program');
//     } finally {
//       setLoading(false);
//     }
//   };     
  
//   return { 
//     programs, 
//     setPrograms, 
//     loading, 
//     error, 
//     fetchPrograms, 
//     createProgram, 
//     updateProgramStatus, 
//     deleteProgram, 
//     updateProgramDetails 
//   };
// };

// export default useProgramAPI;

// // update code --> 03 july 2024
// // src/hooks/AdminDashboard/useProgram.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Program {
//   id: number;
//   name: string;
//   timePeriod: string;
//   amount: number;
//   lotSize: number;
//   deposit: number;
//   currency?: 'USD' | 'MYR' | 'FREE';
//   startDate: string | null;
//   endDate: string | null;
//   time: string | null; 
//   status: 'running' | 'stopped' | 'expired';
//   points?: number;
//   description?: string;
//   redeemNumber?: number;
//   location?: string;
//   image?: string | null;
//   createdAt?: string;
//   slot?: number;
//   memberType?: string[];
// }

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     case "R One Guest":
//       return "Guest";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-yellow-800";
//     case "Dealer":
//       return "text-red-800";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-green-800";
//     case "Member":
//       return "text-sky-800";
//     case "Guest":
//       return "text-gray-500";
//     default:
//       return "text-yellow-800";
//   }
// };

// export const convertToBulletPoints = (text: string): string => {
//   if (!text) return '';
//   const lines = text.split('\n');
//   return lines.map(line => {
//     if (line.includes('[]')) {
//       return `• ${line.replace('[]', '').trim()}`;
//     }
//     return line;
//   }).join('\n');
// };

// const useProgramAPI = () => {
//   const { apiUrl } = useEnv();
//   const [programs, setPrograms] = useState<Program[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchPrograms = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data = await response.json();
//       const parsedData = data.map((event: any) => ({
//         ...event,
//         memberType: JSON.parse(event.memberType.replace(/\\/g, ''))
//       }));
//       setPrograms(parsedData);
//     } catch (err) {
//       setError('Error fetching programs');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   useEffect(() => {
//     fetchPrograms();
//   }, [fetchPrograms]);

//   const createProgram = async (formData: FormData) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`, {
//         method: 'POST',
//         body: formData,
//       });
//       const createdProgram = await response.json();
//       setPrograms((prevPrograms) => [createdProgram, ...prevPrograms]);
//       await fetchPrograms(); // Fetch the latest programs list
//       return createdProgram;
//     } catch (err) {
//       setError('Error creating program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updateProgramStatus = async (programId: number, status: 'running' | 'stopped' | 'expired') => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs/${programId}/status`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ status }),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//       return updatedProgram; // Return the updated program object
//     } catch (err) {
//       setError('Error updating program status');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const deleteProgram = async (programId: number) => {
//     setLoading(true);
//     try {
//       await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'DELETE',
//       });
//       setPrograms((prevPrograms) => prevPrograms.filter((program) => program.id !== programId));
//     } catch (err) {
//       setError('Error deleting program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };
  
//   const updateProgramDetails = async (programId: number, updatedData: Partial<Program>) => {
//     setLoading(true);
//     try {
//       // Ensure memberType is an array before stringifying
//       const memberType = Array.isArray(updatedData.memberType) 
//         ? updatedData.memberType 
//         : (typeof updatedData.memberType === 'string' ? JSON.parse(updatedData.memberType) : []);

//       const response = await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           ...updatedData,
//           memberType: JSON.stringify(memberType),
//         }),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//     } catch (err) {
//       setError('Error updating program');
//     } finally {
//       setLoading(false);
//     }
//   };     
  
//   return { 
//     programs, 
//     setPrograms, 
//     loading, 
//     error, 
//     fetchPrograms, 
//     createProgram, 
//     updateProgramStatus, 
//     deleteProgram, 
//     updateProgramDetails 
//   };
// };

// export default useProgramAPI;

// // udpate code --> 01 july 2024
// // src/hooks/AdminDashboard/useProgram.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Program {
//   id: number;
//   name: string;
//   timePeriod: string;
//   amount: number;
//   currency?: 'USD' | 'MYR' | 'FREE';
//   startDate: string | null;
//   endDate: string | null;
//   time: string | null; 
//   status: 'running' | 'stopped' | 'expired';
//   points?: number;
//   description?: string;
//   redeemNumber?: number;
//   location?: string;
//   image?: string | null;
//   createdAt?: string;
//   slot?: number;
//   memberType?: string[];
// }

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-green-800";
//     case "Dealer":
//       return "text-red-800";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-orange-800";
//     case "Member":
//       return "text-gray-800";
//     default:
//       return "text-yellow-800";
//   }
// };


// const useProgramAPI = () => {
//   const { apiUrl } = useEnv();
//   const [programs, setPrograms] = useState<Program[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchPrograms = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data = await response.json();
//       const parsedData = data.map((event: any) => ({
//         ...event,
//         memberType: JSON.parse(event.memberType.replace(/\\/g, ''))
//       }));
//       setPrograms(parsedData);
//     } catch (err) {
//       setError('Error fetching programs');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   useEffect(() => {
//     fetchPrograms();
//   }, [fetchPrograms]);

//   const createProgram = async (formData: FormData) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`, {
//         method: 'POST',
//         body: formData,
//       });
//       const createdProgram = await response.json();
//       setPrograms((prevPrograms) => [createdProgram, ...prevPrograms]);
//       await fetchPrograms(); // Fetch the latest programs list
//       return createdProgram;
//     } catch (err) {
//       setError('Error creating program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updateProgramStatus = async (programId: number, status: 'running' | 'stopped' | 'expired') => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs/${programId}/status`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ status }),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//       return updatedProgram; // Return the updated program object
//     } catch (err) {
//       setError('Error updating program status');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const deleteProgram = async (programId: number) => {
//     setLoading(true);
//     try {
//       await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'DELETE',
//       });
//       setPrograms((prevPrograms) => prevPrograms.filter((program) => program.id !== programId));
//     } catch (err) {
//       setError('Error deleting program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };
  
//   const updateProgramDetails = async (programId: number, updatedData: Partial<Program>) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(updatedData),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//     } catch (err) {
//       setError('Error updating program');
//     } finally {
//       setLoading(false);
//     }
//   };  
  
//   return { 
//     programs, 
//     setPrograms, 
//     loading, 
//     error, 
//     fetchPrograms, 
//     createProgram, 
//     updateProgramStatus, 
//     deleteProgram, 
//     updateProgramDetails 
//   };
// };

// export default useProgramAPI;


// // src/hooks/AdminDashboard/useProgram.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Program {
//   id: number;
//   name: string;
//   timePeriod: string;
//   amount: number;
//   currency?: 'USD' | 'MYR' | 'FREE';
//   startDate: string | null;
//   endDate: string | null;
//   time: string | null; 
//   status: 'running' | 'stopped' | 'expired';
//   points?: number;
//   description?: string;
//   redeemNumber?: number;
//   location?: string;
//   image?: string | null;
//   createdAt?: string;
//   slot?: number;
//   memberType?: string[];
// }

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-green-800";
//     case "Dealer":
//       return "text-red-800";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-orange-800";
//     case "Member":
//       return "text-gray-800";
//     default:
//       return "text-yellow-800";
//   }
// };


// const useProgramAPI = () => {
//   const { apiUrl } = useEnv();
//   const [programs, setPrograms] = useState<Program[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchPrograms = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data = await response.json();
//       setPrograms(data);
//     } catch (err) {
//       setError('Error fetching programs');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPrograms();
//   }, [fetchPrograms]);

//   const createProgram = async (formData: FormData) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`, {
//         method: 'POST',
//         body: formData,
//       });
//       const createdProgram = await response.json();
//       setPrograms((prevPrograms) => [createdProgram, ...prevPrograms]);
//       await fetchPrograms(); // Fetch the latest programs list
//       return createdProgram;
//     } catch (err) {
//       setError('Error creating program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updateProgramStatus = async (programId: number, status: 'running' | 'stopped' | 'expired') => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs/${programId}/status`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ status }),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//       return updatedProgram; // Return the updated program object
//     } catch (err) {
//       setError('Error updating program status');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const deleteProgram = async (programId: number) => {
//     setLoading(true);
//     try {
//       await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'DELETE',
//       });
//       setPrograms((prevPrograms) => prevPrograms.filter((program) => program.id !== programId));
//     } catch (err) {
//       setError('Error deleting program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };
  
//   const updateProgramDetails = async (programId: number, updatedData: Partial<Program>) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(updatedData),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//     } catch (err) {
//       setError('Error updating program');
//     } finally {
//       setLoading(false);
//     }
//   };  
  
//   return { 
//     programs, 
//     setPrograms, 
//     loading, 
//     error, 
//     fetchPrograms, 
//     createProgram, 
//     updateProgramStatus, 
//     deleteProgram, 
//     updateProgramDetails 
//   };
// };

// export default useProgramAPI;






// // update code --> 20 june 2024 
// // src/hooks/AdminDashboard/useProgram.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext'; // Adjust the path as needed

// export interface Program {
//   id: number;
//   name: string;
//   timePeriod: string;
//   amount: number;
//   startDate: string | null;
//   endDate: string | null; // Added endDate
//   status: 'running' | 'stopped';
//   points?: number; // Added points
//   description?: string; // Added description
//   redeemNumber?: number; // Added redeemNumber
//   currency?: 'USD' | 'MYR' | 'FREE'; // Added currency
//   location?: string; // Add the location field
//   image?: string | null; // Added image
// }

// const useProgramAPI = () => {
//   const { apiUrl } = useEnv(); // Use useEnv to get apiUrl
//   const [programs, setPrograms] = useState<Program[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchPrograms = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data = await response.json();
//       setPrograms(data);
//     } catch (err) {
//       setError('Error fetching programs');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchPrograms();
//   }, [fetchPrograms]);

//   const createProgram = async (formData: FormData) => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs`, {
//         method: 'POST',
//         body: formData,
//       });
//       const createdProgram = await response.json();
//       setPrograms((prevPrograms) => [...prevPrograms, createdProgram]);
//       return createdProgram;
//     } catch (err) {
//       setError('Error creating program');
//       throw err;
//     } finally {
//       setLoading(false);
//     }
//   };  

//   const updateProgramStatus = async (programId: number, status: 'running' | 'stopped') => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/programs/${programId}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ status }),
//       });
//       const updatedProgram = await response.json();
//       setPrograms((prevPrograms) =>
//         prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
//       );
//     } catch (err) {
//       setError('Error updating program status');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { programs, loading, error, fetchPrograms, createProgram, updateProgramStatus };
// };

// export default useProgramAPI;
