// src/components/admin/ClientData/UsersTableTradeStats.tsx

import React from 'react';
import useTradingStats from '../../../hooks/useTradingStats';
import UserTableChart from './UserTableChart';

interface UserTableTradeStatsProps {
  email: string;
  isOpen: boolean;
  onClose: () => void;
}

const UserTableTradeStats: React.FC<UserTableTradeStatsProps> = ({ email, isOpen, onClose }) => {
  const { tradingStats, tradingAccount, profitHistory, loading, error } = useTradingStats(email);

  const formatNumber = (number: number | undefined | null) => {
    return typeof number === 'number' ? number.toFixed(2) : '0.00';
  };
  
  const formatVolumeToLotSize = (volume: number | undefined | null) => {
    return volume ? (volume * 0.01).toFixed(2) : '0.00';
  };  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center">
      <div
        className="modal-overlay absolute inset-0 bg-black opacity-70"
        onClick={onClose}
      ></div>

      <div className="modal relative bg-white rounded-md p-4 w-auto max-w-[1000px] h-auto max-h-screen">
        <h2 className="text-2xl font-bold text-cyan-800 my-6 text-center">Trading History</h2>

        {error ? (
          <div className="flex flex-col items-center text-gray-700 text-lg font-semibold my-6 p-4">
            <p>No user trading data available.</p>
            <a
              href="https://portal.fortuneprime.com/getview?view=register&token=8Krvowwwcowwwwww"
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600 underline hover:text-cyan-800 mt-2"
            >
              Register with Broker
            </a>
          </div>
        ) : (
          <>
            {tradingAccount && (
              <div className="flex flex-row justify-between items-center bg-gray-100 rounded-md">
                <div className="flex justify-start items-center">
                  <div className="mx-4 my-2 p-2 text-base font-semibold text-gray-700">
                    <span className="text-cyan-800">Name:</span> {tradingAccount.name}
                  </div>
                  <div className="mx-4 my-2 p-2 text-base font-semibold text-gray-700">
                    <span className="text-cyan-800">Trade Account:</span> {tradingAccount.login}
                  </div>
                </div>
                <div className="flex justify-end items-center mx-4 my-2 p-2 text-sm font-semibold">
                  <span className="text-cyan-800 mr-2">Overall Rating:</span> 
                  <span className="bg-cyan-800 text-white px-4 py-1 rounded-full">
                    {tradingAccount.overallRating} out of 10
                  </span>
                </div>
              </div>
            )}
            
            {/* User Trading Account info */}
            <UserTableChart 
              tradingAccount={tradingAccount} 
              profitHistory={profitHistory ?? []}
              formatNumber={formatNumber} 
              formatVolumeToLotSize={formatVolumeToLotSize}
            />

            {loading ? (
              <p className="text-center text-gray-500">Loading trading stats...</p>
            ) : tradingStats && tradingStats.length > 0 ? (
              <div className="rounded-md overflow-hidden">
                <div className="overflow-y-auto max-h-[200px] border custom-scrollbar">
                  <table className="w-full table-auto bg-white border-collapse">
                    <thead className="sticky top-0 bg-cyan-800 text-white text-xs uppercase font-bold">
                      <tr>
                        <th className="py-3 px-6 text-center text-nowrap">Ticket</th>
                        <th className="py-3 px-6 text-center text-nowrap">Symbol</th>
                        <th className="py-3 px-6 text-center text-nowrap">Open Time</th>
                        <th className="py-3 px-6 text-center text-nowrap">Open Price</th>
                        <th className="py-3 px-6 text-center text-nowrap">Type</th>
                        <th className="py-3 px-6 text-center text-nowrap">Lot Size</th>
                        <th className="py-3 px-6 text-center text-nowrap">TP</th>
                        <th className="py-3 px-6 text-center text-nowrap">Close Time</th>
                        <th className="py-3 px-6 text-center text-nowrap">Close Price</th>
                        <th className="py-3 px-6 text-center text-nowrap">SL</th>
                        <th className="py-3 px-6 text-center text-nowrap">Profit</th>
                        <th className="py-3 px-6 text-center text-nowrap">Commission</th>
                        <th className="py-3 px-6 text-center text-nowrap">Swap</th>
                        <th className="py-3 px-6 text-center text-nowrap">Comment</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-700 text-xs font-medium">
                      {tradingStats.map((stat, index) => (
                        <tr
                          key={index}
                          className="border-b border-gray-200 hover:bg-gray-100"
                        >
                          <td className="py-4 px-6 text-center text-nowrap">{stat.ticket}</td>
                          <td className="py-4 px-6 text-center text-nowrap">{stat.symbol}</td>
                          <td className="py-4 px-6 text-center text-nowrap">
                            {new Date(stat.open_time).toLocaleString()}
                          </td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatNumber(stat.open_price)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">
                            {stat.cmd === '0' ? 'Sell' : stat.cmd === '1' ? 'Buy' : stat.cmd}
                          </td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatVolumeToLotSize(stat.volume)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatNumber(stat.tp)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">
                            {new Date(stat.close_time).toLocaleString()}
                          </td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatNumber(stat.close_price)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatNumber(stat.sl)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatNumber(stat.profit)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatNumber(stat.commission)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">{formatNumber(stat.swap)}</td>
                          <td className="py-4 px-6 text-center text-nowrap">{stat.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <p className="text-center text-gray-500">No trading stats available for this user.</p>
            )}
          </>
        )}

        <div className="flex justify-end mt-2">
          <button
            onClick={onClose}
            className="text-white bg-cyan-800 text-sm rounded-full px-6 py-1 font-semibold"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTableTradeStats;


// // udpate code --> 25 oct 2024
// // src/components/admin/ClientData/UserTableTradeStats.tsx

// import React from 'react';
// import useTradingStats from '../../../hooks/useTradingStats';

// interface UserTableTradeStatsProps {
//   email: string;
//   isOpen: boolean;
//   onClose: () => void;
// }

// const UserTableTradeStats: React.FC<UserTableTradeStatsProps> = ({ email, isOpen, onClose }) => {
//   const { tradingStats, tradingAccount, loading, error } = useTradingStats(email);

//   if (!isOpen) return null;

//   // Safely format numbers, return fallback if undefined or null
//   const formatNumber = (number: number | undefined | null) => {
//     return number !== undefined && number !== null ? number.toFixed(2) : '0.00';
//   };

//   return (
//     <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//       <div
//         className="modal-overlay absolute inset-0 bg-black opacity-70"
//         onClick={onClose}
//       ></div>

//       <div className="modal relative bg-white rounded-md p-4 w-auto max-w-[1000px] h-auto max-h-screen">
//         <h2 className="text-2xl font-bold text-cyan-800 my-6 text-center">Trading History</h2>

//         {tradingAccount && (
//           <div className="flex flex-row justify-between items-center bg-gray-100 rounded-md">
//             <div className="flex justify-start items-center">
//               <div className="mx-4 my-2 p-2 text-base font-semibold text-gray-700">
//                 <span className="text-cyan-800">Name:</span> {tradingAccount.name}
//               </div>
//               <div className="mx-4 my-2 p-2 text-base font-semibold text-gray-700">
//                 <span className="text-cyan-800">Trade Account:</span> {tradingAccount.login}
//               </div>
//             </div>
//             <div className="flex justify-end items-center mx-4 my-2 p-2 text-base font-semibold">
//               <span className="text-cyan-800">Overall Rating:</span> 6.0  
//             </div>
//           </div>
//         )}

//         <div className="flex flex-row">
//           {/* initial capital info */}
//           <div className="flex flex-col space-y-2 my-6 mx-4 text-xs">
//             {tradingAccount && (
//               <>
//                 <div className="flex justify-between space-x-4">
//                   <span className="text-gray-700 font-semibold">Growth:</span>
//                   <span className="text-gray-900">100%</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span className="text-gray-700 font-semibold">Profit:</span>
//                   <span className="text-gray-900">$12,345.67</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span className="text-gray-700 font-semibold">Equity:</span>
//                   <span className="text-gray-900">${formatNumber(tradingAccount.equity)}</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span className="text-gray-700 font-semibold">Balance:</span>
//                   <span className="text-gray-900">${formatNumber(tradingAccount.balance)}</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span className="text-gray-700 font-semibold">Initial Capital:</span>
//                   <span className="text-gray-900">$10,000.00</span>
//                 </div>
//               </>
//             )}
//           </div>
//         </div>

//         {loading ? (
//           <p className="text-center text-gray-500">Loading trading stats...</p>
//         ) : error ? (
//           <p className="text-center text-red-500">Error: {error}</p>
//         ) : tradingStats && tradingStats.length > 0 ? (
//           <div className="rounded-md overflow-hidden">
//             <div className="overflow-y-auto max-h-[300px] custom-scrollbar">
//               <table className="w-full table-auto bg-white border-collapse">
//                 <thead className="sticky top-0 bg-cyan-800 text-white text-xs uppercase font-bold">
//                   <tr>
//                     <th className="py-3 px-6 text-center text-nowrap">Ticket</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Symbol</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Open Time</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Open Price</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Type</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Lot Size</th>
//                     <th className="py-3 px-6 text-center text-nowrap">TP</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Close Time</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Close Price</th>
//                     <th className="py-3 px-6 text-center text-nowrap">SL</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Profit</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Commission</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Swap</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Comment</th>
//                   </tr>
//                 </thead>
//                 <tbody className="text-gray-700 text-sm font-medium">
//                   {tradingStats.map((stat, index) => (
//                     <tr
//                       key={index}
//                       className="border-b border-gray-200 hover:bg-gray-100"
//                     >
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.ticket}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.symbol}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">
//                         {new Date(stat.open_time).toLocaleString()}
//                       </td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.open_price)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">
//                         {stat.cmd === '0' ? 'Sell' : stat.cmd === '1' ? 'Buy' : stat.cmd}
//                       </td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.volume)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.tp)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">
//                         {new Date(stat.close_time).toLocaleString()}
//                       </td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.close_price)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.sl)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.profit)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.commission)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{formatNumber(stat.swap)}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.comment}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         ) : (
//           <p className="text-center text-gray-500">No trading stats available for this user.</p>
//         )}

//         <div className="flex justify-end mt-6">
//           <button
//             onClick={onClose}
//             className="text-white bg-cyan-600 hover:bg-cyan-700 rounded-full px-8 py-3 font-semibold shadow-lg transition-all duration-300 ease-in-out hover:scale-105"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserTableTradeStats;


// udpate code --> 24 oct 2024
// // src/components/admin/ClientData/UserTableTradeStats.tsx

// import React from 'react';
// import useTradingStats from '../../../hooks/useTradingStats';

// interface UserTableTradeStatsProps {
//   email: string;
//   isOpen: boolean;
//   onClose: () => void;
// }

// const UserTableTradeStats: React.FC<UserTableTradeStatsProps> = ({ email, isOpen, onClose }) => {
//   const { tradingStats, loading, error } = useTradingStats(email);

//   if (!isOpen) return null;

//   // Get the user information from the first item in the tradingStats array
//   const user = tradingStats && tradingStats.length > 0 ? tradingStats[0] : null;

//   return (
//     <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//       <div
//         className="modal-overlay absolute inset-0 bg-black opacity-70"
//         onClick={onClose}
//       ></div>

//       <div className="modal relative bg-white rounded-md p-4 w-auto max-w-[1000px] h-auto max-h-screen">
//         <h2 className="text-2xl font-bold text-cyan-800 my-6 text-center">Trading History</h2>

//         {user && (
//           <div className="flex justify-start items-center bg-gray-100 rounded-md">
//             <div className="mx-4 my-2 p-2 text-base font-semibold text-gray-700">
//               <span className="text-cyan-800">Name:</span> {user.fullName}
//             </div>
//             <div className="mx-4 my-2 p-2 text-base font-semibold text-gray-700">
//               <span className="text-cyan-800">Trade Account:</span> {user.login}
//             </div>
//           </div>
//         )}

//         {loading ? (
//           <p className="text-center text-gray-500">Loading trading stats...</p>
//         ) : error ? (
//           <p className="text-center text-red-500">Error: {error}</p>
//         ) : tradingStats && tradingStats.length > 0 ? (
//           <div className="rounded-md overflow-hidden">
//             <div className="overflow-y-auto max-h-[300px] custom-scrollbar">
//               <table className="w-full table-auto bg-white border-collapse">
//                 <thead className="sticky top-0 bg-cyan-800 text-white text-xs uppercase font-bold">
//                   <tr>
//                     <th className="py-3 px-6 text-center text-nowrap">Ticket</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Symbol</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Open Time</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Open Price</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Type</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Lot Size</th>
//                     <th className="py-3 px-6 text-center text-nowrap">TP</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Close Time</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Close Price</th>
//                     <th className="py-3 px-6 text-center text-nowrap">SL</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Profit</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Commission</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Swap</th>
//                     <th className="py-3 px-6 text-center text-nowrap">Comment</th>
//                   </tr>
//                 </thead>
//                 <tbody className="text-gray-700 text-sm font-medium">
//                   {tradingStats.map((stat, index) => (
//                     <tr
//                       key={index}
//                       className="border-b border-gray-200 hover:bg-gray-100"
//                     >
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.ticket}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.symbol}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">
//                         {new Date(stat.open_time).toLocaleString()}
//                       </td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.open_price}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.cmd}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.volume}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.tp}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">
//                         {new Date(stat.close_time).toLocaleString()}
//                       </td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.close_price}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.sl}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.profit}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.commission}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.swap}</td>
//                       <td className="py-3 px-6 text-center text-nowrap">{stat.comment}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         ) : (
//           <p className="text-center text-gray-500">No trading stats available for this user.</p>
//         )}

//         <div className="flex justify-end mt-6">
//           <button
//             onClick={onClose}
//             className="text-white bg-cyan-600 hover:bg-cyan-700 rounded-full px-8 py-3 font-semibold shadow-lg transition-all duration-300 ease-in-out hover:scale-105"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserTableTradeStats;

