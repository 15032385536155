// src/hooks/AdminDashboard/useVideoUpload.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

interface VideoData {
  videoId: string;
  videoPlaylistId?: string;
  videoTitle: string;
  videoCategory: string;
  videoCategoryArray: number;
  videoPermission: string;
  videoDescription: string;
  videoLength: string;
  videoThumbnail: string;
  videoPath: string;
  videoViews: number;
  videoUploadDate: Date;
  videoGroup?: string; 
  videoGroupEmail?: string[];
}

interface PlaylistData {
  videoPlaylistId: string;
  videoPlaylistTitle: string;
  videoPlaylistDescription: string;
  videoPlaylistArray: number;
  videoPlaylistContent: string[];
  videoPlaylistPermission: string;
  videoPlaylistGroup: string;
  videoPlaylistGroupCustom:string;
  videoPlaylistDate: string;
}

interface UploadResponse {
  message: string;
  videoData: VideoData;
}

const useVideoUpload = () => {
  const { apiUrl } = useEnv();
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [playlists, setPlaylists] = useState<PlaylistData[]>([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [userEmails, setUserEmails] = useState<string[]>([]);


  // Fetch user emails for the custom group modal
  const fetchUserEmails = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/users`);
      if (!response.ok) {
        throw new Error('Failed to fetch user emails');
      }
      const data = await response.json();
      setUserEmails(data.emails || []); 

    } catch (error) {
      console.error('Error fetching user emails:', error);  
    }
  }, [apiUrl]);

  // Function to fetch video list
  const fetchVideos = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/video-data`);
      if (!response.ok) {
        throw new Error('Failed to fetch videos');
      }
      const data: VideoData[] = await response.json();

      setVideos(data);
      setLoading(false);

      // Extract unique categories from videos
      const uniqueCategories = Array.from(new Map(data.map((video) => [video.videoCategory, video.videoCategory])).values());
      setCategories(uniqueCategories);

    } catch (error) {
      console.error('Error fetching video data:', error);
      setLoading(false);
    }
  }, [apiUrl]); 
  

  // Function to upload video
  const uploadVideo = async (formData: FormData): Promise<UploadResponse | null> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${apiUrl}/upload-video`);

      // Update progress state
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setUploadProgress(Math.round(percentComplete));
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response: UploadResponse = JSON.parse(xhr.responseText);
          setVideos((prevVideos) => [...prevVideos, response.videoData]); 
          resolve(response);
        } else {
          reject(new Error("Failed to upload video"));
        }
      };

      xhr.onerror = () => reject(new Error("Error during video upload"));

      xhr.send(formData);
    });
  };

   // Function to update video
   const updateVideo = async (videoId: string, formData: FormData): Promise<UploadResponse | null> => {
    try {
      const response = await fetch(`${apiUrl}/update-video/${videoId}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update video');
      }

      const data: UploadResponse = await response.json();
      
      // Update the videos list with the updated video
      setVideos((prevVideos) =>
        prevVideos.map((video) =>
          video.videoId === videoId ? data.videoData : video
        )
      );

      return data;
    } catch (error) {
      console.error('Error updating video:', error);
      return null;
    }
  };

  // Function to remove videos
  const removeVideos = async (videoIds: string[]) => {
    try {
      const response = await fetch(`${apiUrl}/remove-videos`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoIds }),
      });

      if (!response.ok) {
        throw new Error('Failed to remove videos');
      }

      // Remove the videos from the list
      setVideos((prevVideos) => prevVideos.filter((video) => !videoIds.includes(video.videoId)));
    } catch (error) {
      console.error('Error removing videos:', error);
    }
  };

  // Function to fetch Playlists
  const fetchPlaylists = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/playlists`);
      if (!response.ok) {
        throw new Error('Failed to fetch playlists');
      }
      const data = await response.json();
      setPlaylists(data);  
    } catch (error) {
      console.error('Error fetching playlist data:', error);
    }
  }, [apiUrl]);  

  const createPlaylist = async (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
    try {
      const response = await fetch(`${apiUrl}/create-playlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(playlistData), 
      });
  
      if (!response.ok) {
        throw new Error('Failed to create playlist');
      }
  
      const newPlaylist = await response.json();
      setPlaylists((prevPlaylists) => [...prevPlaylists, newPlaylist]);
    } catch (error) {
      console.error('Error creating playlist:', error);
    }
  };  

  const removePlaylists = async (playlistIds: string[]) => {
    try {
      const response = await fetch(`${apiUrl}/remove-playlists`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ playlistIds }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to remove playlists');
      }
  
      // Remove the playlists from the list
      setPlaylists((prevPlaylists) => prevPlaylists.filter((playlist) => !playlistIds.includes(playlist.videoPlaylistId)));
    } catch (error) {
      console.error('Error removing playlists:', error);
    }
  };
  

  // Fetch video list when the hook is used (component mounts)
  useEffect(() => {
    fetchVideos();
    fetchUserEmails(); 
    fetchPlaylists();
  }, [fetchVideos, fetchUserEmails, fetchPlaylists]);

  return { 
    videos, setVideos, categories, fetchVideos,
    uploadVideo, uploadProgress, updateVideo, removeVideos,
    createPlaylist, fetchPlaylists, removePlaylists,
    playlists, userEmails, loading 
  };
};

export default useVideoUpload;

// // uipdae cdeo --> 10 oct 2024 
// // src/hooks/AdminDashboard/useVideoUpload.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoPlaylistId?: string;
//   videoTitle: string;
//   videoCategory: string;
//   videoCategoryArray: string;
//   videoPermission: string;
//   videoDescription: string;
//   videoLength: string;
//   videoThumbnail: string;
//   videoPath: string;
//   videoViews: number;
//   videoUploadDate: string;
// }

// interface PlaylistData {
//   videoPlaylistId: string;
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: string[];
//   videoPlaylistPermission: string;
//   videoPlaylistGroup: string;
//   videoPlaylistGroupCustom:string;
//   videoPlaylistDate: string;
// }

// interface UploadResponse {
//   message: string;
//   videoData: VideoData;
// }

// const useVideoUpload = () => {
//   const { apiUrl } = useEnv();
//   const [videos, setVideos] = useState<VideoData[]>([]);
//   const [playlists, setPlaylists] = useState<PlaylistData[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [categories, setCategories] = useState<string[]>([]);
//   const [uploadProgress, setUploadProgress] = useState<number>(0);
//   const [userEmails, setUserEmails] = useState<string[]>([]);


//   // Fetch user emails for the custom group modal
//   const fetchUserEmails = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/users`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch user emails');
//       }
//       const data = await response.json();
//       setUserEmails(data.emails || []); 

//     } catch (error) {
//       console.error('Error fetching user emails:', error);  
//     }
//   }, [apiUrl]);

//   // Function to fetch video list
//   const fetchVideos = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/videos`);
//       const data: VideoData[] = await response.json();
//       setVideos(data);
//       setLoading(false);

//       // Extract unique categories from videos
//       const uniqueCategories = Array.from(new Map(data.map((video) => [video.videoCategory, video.videoCategory])).values());
//       setCategories(uniqueCategories);

//     } catch (error) {
//       console.error('Error fetching video data:', error);
//       setLoading(false);
//     }
//   }, [apiUrl]);
  

//   // Function to upload video
//   const uploadVideo = async (formData: FormData): Promise<UploadResponse | null> => {
//     return new Promise((resolve, reject) => {
//       const xhr = new XMLHttpRequest();
//       xhr.open("POST", `${apiUrl}/upload-video`);

//       // Update progress state
//       xhr.upload.onprogress = (event) => {
//         if (event.lengthComputable) {
//           const percentComplete = (event.loaded / event.total) * 100;
//           setUploadProgress(Math.round(percentComplete));
//         }
//       };

//       xhr.onload = () => {
//         if (xhr.status === 200) {
//           const response: UploadResponse = JSON.parse(xhr.responseText);
//           setVideos((prevVideos) => [...prevVideos, response.videoData]); 
//           resolve(response);
//         } else {
//           reject(new Error("Failed to upload video"));
//         }
//       };

//       xhr.onerror = () => reject(new Error("Error during video upload"));

//       xhr.send(formData);
//     });
//   };

//    // Function to update video
//    const updateVideo = async (videoId: string, formData: FormData): Promise<UploadResponse | null> => {
//     try {
//       const response = await fetch(`${apiUrl}/update-video/${videoId}`, {
//         method: 'PUT',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update video');
//       }

//       const data: UploadResponse = await response.json();
      
//       // Update the videos list with the updated video
//       setVideos((prevVideos) =>
//         prevVideos.map((video) =>
//           video.videoId === videoId ? data.videoData : video
//         )
//       );

//       return data;
//     } catch (error) {
//       console.error('Error updating video:', error);
//       return null;
//     }
//   };

//   // Function to remove videos
//   const removeVideos = async (videoIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-videos`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ videoIds }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to remove videos');
//       }

//       // Remove the videos from the list
//       setVideos((prevVideos) => prevVideos.filter((video) => !videoIds.includes(video.videoId)));
//     } catch (error) {
//       console.error('Error removing videos:', error);
//     }
//   };

//   // Function to fetch Playlists
//   const fetchPlaylists = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/playlists`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch playlists');
//       }
//       const data = await response.json();
//       setPlaylists(data);  
//     } catch (error) {
//       console.error('Error fetching playlist data:', error);
//     }
//   }, [apiUrl]);  

//   const createPlaylist = async (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
//     try {
//       const response = await fetch(`${apiUrl}/create-playlist`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(playlistData), 
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to create playlist');
//       }
  
//       const newPlaylist = await response.json();
//       setPlaylists((prevPlaylists) => [...prevPlaylists, newPlaylist]);
//     } catch (error) {
//       console.error('Error creating playlist:', error);
//     }
//   };  

//   const removePlaylists = async (playlistIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-playlists`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ playlistIds }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove playlists');
//       }
  
//       // Remove the playlists from the list
//       setPlaylists((prevPlaylists) => prevPlaylists.filter((playlist) => !playlistIds.includes(playlist.videoPlaylistId)));
//     } catch (error) {
//       console.error('Error removing playlists:', error);
//     }
//   };
  

//   // Fetch video list when the hook is used (component mounts)
//   useEffect(() => {
//     fetchVideos();
//     fetchUserEmails(); 
//     fetchPlaylists();
//   }, [fetchVideos, fetchUserEmails, fetchPlaylists]);

//   return { 
//     videos, setVideos, categories, fetchVideos,
//     uploadVideo, uploadProgress, updateVideo, removeVideos,
//     createPlaylist, fetchPlaylists, removePlaylists,
//     playlists, userEmails, loading 
//   };
// };

// export default useVideoUpload;

// // update code --> 08 oct 2024
// // src/hooks/AdminDashboard/useVideoUpload.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoPlaylistId?: string;
//   videoTitle: string;
//   videoCategory: string;
//   videoCategoryArray: string;
//   videoPermission: string;
//   videoDescription: string;
//   videoLength: string;
//   videoThumbnail: string;
//   videoPath: string;
//   videoViews: number;
//   videoUploadDate: string;
// }

// interface PlaylistData {
//   videoPlaylistId: string;
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: Record<string, { videoId: string; videoTitle: string; videoCategory: string }>;
//   videoPlaylistDate: string;
// }

// interface UploadResponse {
//   message: string;
//   videoData: VideoData;
// }

// const useVideoUpload = () => {
//   const { apiUrl } = useEnv();
//   const [videos, setVideos] = useState<VideoData[]>([]);
//   const [playlists, setPlaylists] = useState<PlaylistData[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [categories, setCategories] = useState<string[]>([]);
//   const [uploadProgress, setUploadProgress] = useState<number>(0);
//   const [userEmails, setUserEmails] = useState<string[]>([]);


//   // Fetch user emails for the custom group modal
//   const fetchUserEmails = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/users`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch user emails');
//       }
//       const data = await response.json();
//       setUserEmails(data.emails || []); 

//     } catch (error) {
//       console.error('Error fetching user emails:', error);  
//     }
//   }, [apiUrl]);

//   // Function to fetch video list
//   const fetchVideos = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/videos`);
//       const data: VideoData[] = await response.json();
//       setVideos(data);
//       setLoading(false);

//       // Extract unique categories from videos
//       const uniqueCategories = Array.from(new Map(data.map((video) => [video.videoCategory, video.videoCategory])).values());
//       setCategories(uniqueCategories);

//     } catch (error) {
//       console.error('Error fetching video data:', error);
//       setLoading(false);
//     }
//   }, [apiUrl]);
  

//   // Function to upload video
//   const uploadVideo = async (formData: FormData): Promise<UploadResponse | null> => {
//     return new Promise((resolve, reject) => {
//       const xhr = new XMLHttpRequest();
//       xhr.open("POST", `${apiUrl}/upload-video`);

//       // Update progress state
//       xhr.upload.onprogress = (event) => {
//         if (event.lengthComputable) {
//           const percentComplete = (event.loaded / event.total) * 100;
//           setUploadProgress(Math.round(percentComplete));
//         }
//       };

//       xhr.onload = () => {
//         if (xhr.status === 200) {
//           const response: UploadResponse = JSON.parse(xhr.responseText);
//           setVideos((prevVideos) => [...prevVideos, response.videoData]); 
//           resolve(response);
//         } else {
//           reject(new Error("Failed to upload video"));
//         }
//       };

//       xhr.onerror = () => reject(new Error("Error during video upload"));

//       xhr.send(formData);
//     });
//   };

//    // Function to update video
//    const updateVideo = async (videoId: string, formData: FormData): Promise<UploadResponse | null> => {
//     try {
//       const response = await fetch(`${apiUrl}/update-video/${videoId}`, {
//         method: 'PUT',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update video');
//       }

//       const data: UploadResponse = await response.json();
      
//       // Update the videos list with the updated video
//       setVideos((prevVideos) =>
//         prevVideos.map((video) =>
//           video.videoId === videoId ? data.videoData : video
//         )
//       );

//       return data;
//     } catch (error) {
//       console.error('Error updating video:', error);
//       return null;
//     }
//   };

//   // Function to remove videos
//   const removeVideos = async (videoIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-videos`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ videoIds }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to remove videos');
//       }

//       // Remove the videos from the list
//       setVideos((prevVideos) => prevVideos.filter((video) => !videoIds.includes(video.videoId)));
//     } catch (error) {
//       console.error('Error removing videos:', error);
//     }
//   };

//   // Function to fetch Playlists
//   const fetchPlaylists = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/playlists`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch playlists');
//       }
//       const data = await response.json();
//       setPlaylists(data);  
//     } catch (error) {
//       console.error('Error fetching playlist data:', error);
//     }
//   }, [apiUrl]);  

//   const createPlaylist = async (playlistData: Omit<PlaylistData, 'videoPlaylistId'>) => {
//     try {
//       const response = await fetch(`${apiUrl}/create-playlist`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(playlistData), 
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to create playlist');
//       }
  
//       const newPlaylist = await response.json();
//       setPlaylists((prevPlaylists) => [...prevPlaylists, newPlaylist]);
//     } catch (error) {
//       console.error('Error creating playlist:', error);
//     }
//   };  

//   const removePlaylists = async (playlistIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-playlists`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ playlistIds }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove playlists');
//       }
  
//       // Remove the playlists from the list
//       setPlaylists((prevPlaylists) => prevPlaylists.filter((playlist) => !playlistIds.includes(playlist.videoPlaylistId)));
//     } catch (error) {
//       console.error('Error removing playlists:', error);
//     }
//   };
  

//   // Fetch video list when the hook is used (component mounts)
//   useEffect(() => {
//     fetchVideos();
//     fetchUserEmails(); 
//     fetchPlaylists();
//   }, [fetchVideos, fetchUserEmails, fetchPlaylists]);

//   return { 
//     videos, setVideos, categories, fetchVideos,
//     uploadVideo, uploadProgress, updateVideo, removeVideos,
//     createPlaylist, fetchPlaylists, removePlaylists,
//     playlists, userEmails, loading 
//   };
// };

// export default useVideoUpload;

// // update code --> 06 oct 2042
// // src/hooks/AdminDashboard/useVideoUpload.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoTitle: string;
//   videoCategory: string;
//   videoCategoryArray: string;
//   videoPermission: string;
//   videoDescription: string;
//   videoLength: string;
//   videoThumbnail: string;
//   videoPath: string;
//   videoViews: number;
//   createdAt: string;
// }

// interface UploadResponse {
//   message: string;
//   videoData: VideoData;
// }

// const useVideoUpload = () => {
//   const { apiUrl } = useEnv();
//   const [videos, setVideos] = useState<VideoData[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [categories, setCategories] = useState<string[]>([]);
//   const [uploadProgress, setUploadProgress] = useState<number>(0);
//   const [userEmails, setUserEmails] = useState<string[]>([]);
//   const [playlists, setPlaylists] = useState<any[]>([]);

//   // Fetch user emails for the custom group modal
//   const fetchUserEmails = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/users`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch user emails');
//       }
//       const data = await response.json();
//       setUserEmails(data.emails || []); 

//     } catch (error) {
//       console.error('Error fetching user emails:', error);  
//     }
//   }, [apiUrl]);

//   // Function to fetch video list
//   const fetchVideos = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/videos`);
//       const data: VideoData[] = await response.json();
//       setVideos(data);
//       setLoading(false);

//       // Extract unique categories from videos
//       const uniqueCategories = Array.from(new Map(data.map((video) => [video.videoCategory, video.videoCategory])).values());
//       setCategories(uniqueCategories);

//     } catch (error) {
//       console.error('Error fetching video data:', error);
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   // Function to fetch Playlists
//   const fetchPlaylists = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/playlists`);
//       const data = await response.json();
//       setPlaylists(data);
//     } catch (error) {
//       console.error('Error fetching playlist data:', error);
//     }
//   }, [apiUrl]);

//   // Function to upload video
//   const uploadVideo = async (formData: FormData): Promise<UploadResponse | null> => {
//     return new Promise((resolve, reject) => {
//       const xhr = new XMLHttpRequest();
//       xhr.open("POST", `${apiUrl}/upload-video`);

//       // Update progress state
//       xhr.upload.onprogress = (event) => {
//         if (event.lengthComputable) {
//           const percentComplete = (event.loaded / event.total) * 100;
//           setUploadProgress(Math.round(percentComplete));
//         }
//       };

//       xhr.onload = () => {
//         if (xhr.status === 200) {
//           const response: UploadResponse = JSON.parse(xhr.responseText);
//           setVideos((prevVideos) => [...prevVideos, response.videoData]); 
//           resolve(response);
//         } else {
//           reject(new Error("Failed to upload video"));
//         }
//       };

//       xhr.onerror = () => reject(new Error("Error during video upload"));

//       xhr.send(formData);
//     });
//   };

//    // Function to update video
//    const updateVideo = async (videoId: string, formData: FormData): Promise<UploadResponse | null> => {
//     try {
//       const response = await fetch(`${apiUrl}/update-video/${videoId}`, {
//         method: 'PUT',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update video');
//       }

//       const data: UploadResponse = await response.json();
      
//       // Update the videos list with the updated video
//       setVideos((prevVideos) =>
//         prevVideos.map((video) =>
//           video.videoId === videoId ? data.videoData : video
//         )
//       );

//       return data;
//     } catch (error) {
//       console.error('Error updating video:', error);
//       return null;
//     }
//   };

//   // Function to remove videos
//   const removeVideos = async (videoIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-videos`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ videoIds }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to remove videos');
//       }

//       // Remove the videos from the list
//       setVideos((prevVideos) => prevVideos.filter((video) => !videoIds.includes(video.videoId)));
//     } catch (error) {
//       console.error('Error removing videos:', error);
//     }
//   };

//   // Fetch video list when the hook is used (component mounts)
//   useEffect(() => {
//     fetchVideos();
//     fetchUserEmails(); 
//     fetchPlaylists();
//   }, [fetchVideos, fetchUserEmails, fetchPlaylists]);

//   return { 
//     videos, setVideos, categories, 
//     uploadVideo, uploadProgress, updateVideo, removeVideos, 
//     playlists, userEmails, loading 
//   };
// };

// export default useVideoUpload;

// // update code --> 05 oct 2024
// // src/hooks/AdminDashboard/useVideoUpload.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoTitle: string;
//   videoCategory: string;
//   videoCategoryArray: string;
//   videoPermission: string;
//   videoDescription: string;
//   videoLength: string;
//   videoThumbnail: string;
//   videoPath: string;
//   videoViews: number;
//   createdAt: string;
// }

// interface UploadResponse {
//   message: string;
//   videoData: VideoData;
// }

// const useVideoUpload = () => {
//   const { apiUrl } = useEnv();
//   const [videos, setVideos] = useState<VideoData[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [categories, setCategories] = useState<string[]>([]);
//   const [uploadProgress, setUploadProgress] = useState<number>(0);
//   const [userEmails, setUserEmails] = useState<string[]>([]);

//   // Fetch user emails for the custom group modal
//   const fetchUserEmails = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/users`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch user emails');
//       }
//       const data = await response.json();
//       setUserEmails(data.emails || []); 

//     } catch (error) {
//       console.error('Error fetching user emails:', error);  
//     }
//   }, [apiUrl]);

//   // Function to fetch video list
//   const fetchVideos = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/videos`);
//       const data: VideoData[] = await response.json();
//       setVideos(data);
//       setLoading(false);

//       // Extract unique categories from videos
//       const uniqueCategories = Array.from(new Map(data.map((video) => [video.videoCategory, video.videoCategory])).values());
//       setCategories(uniqueCategories);

//     } catch (error) {
//       console.error('Error fetching video data:', error);
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   // Function to upload video
//   const uploadVideo = async (formData: FormData): Promise<UploadResponse | null> => {
//     return new Promise((resolve, reject) => {
//       const xhr = new XMLHttpRequest();
//       xhr.open("POST", `${apiUrl}/upload-video`);

//       // Update progress state
//       xhr.upload.onprogress = (event) => {
//         if (event.lengthComputable) {
//           const percentComplete = (event.loaded / event.total) * 100;
//           setUploadProgress(Math.round(percentComplete));
//         }
//       };

//       xhr.onload = () => {
//         if (xhr.status === 200) {
//           const response: UploadResponse = JSON.parse(xhr.responseText);
//           setVideos((prevVideos) => [...prevVideos, response.videoData]); 
//           resolve(response);
//         } else {
//           reject(new Error("Failed to upload video"));
//         }
//       };

//       xhr.onerror = () => reject(new Error("Error during video upload"));

//       xhr.send(formData);
//     });
//   };

//    // Function to update video
//    const updateVideo = async (videoId: string, formData: FormData): Promise<UploadResponse | null> => {
//     try {
//       const response = await fetch(`${apiUrl}/update-video/${videoId}`, {
//         method: 'PUT',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update video');
//       }

//       const data: UploadResponse = await response.json();
      
//       // Update the videos list with the updated video
//       setVideos((prevVideos) =>
//         prevVideos.map((video) =>
//           video.videoId === videoId ? data.videoData : video
//         )
//       );

//       return data;
//     } catch (error) {
//       console.error('Error updating video:', error);
//       return null;
//     }
//   };

//   // Function to remove videos
//   const removeVideos = async (videoIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-videos`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ videoIds }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to remove videos');
//       }

//       // Remove the videos from the list
//       setVideos((prevVideos) => prevVideos.filter((video) => !videoIds.includes(video.videoId)));
//     } catch (error) {
//       console.error('Error removing videos:', error);
//     }
//   };

//   // Fetch video list when the hook is used (component mounts)
//   useEffect(() => {
//     fetchVideos();
//     fetchUserEmails(); 
//   }, [fetchVideos, fetchUserEmails]);

//   return { videos, setVideos, categories, uploadVideo, uploadProgress,updateVideo, removeVideos, userEmails, loading };
// };

// export default useVideoUpload;

// // udpate code --> 04 Oct 2024
// // src/hooks/AdminDashboard/useVideoUpload.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoTitle: string;
//   videoCategory: string;
//   videoPermission: string;
//   videoDescription: string;
//   videoLength: string;
//   videoThumbnail: string;
//   videoPath: string;
//   videoViews: number;
//   createdAt: string;
// }

// interface UploadResponse {
//   message: string;
//   videoData: VideoData;
// }

// const useVideoUpload = () => {
//   const { apiUrl } = useEnv();
//   const [videos, setVideos] = useState<VideoData[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [categories, setCategories] = useState<string[]>([]);
//   const [uploadProgress, setUploadProgress] = useState<number>(0);

//   // Function to fetch video list
//   const fetchVideos = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/videos`);
//       const data: VideoData[] = await response.json();
//       setVideos(data);
//       setLoading(false);

//       // Extract unique categories from videos
//       const uniqueCategories = Array.from(new Map(data.map((video) => [video.videoCategory, video.videoCategory])).values());
//       setCategories(uniqueCategories);

//     } catch (error) {
//       console.error('Error fetching video data:', error);
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   // Function to upload video
//   const uploadVideo = async (formData: FormData): Promise<UploadResponse | null> => {
//     return new Promise((resolve, reject) => {
//       const xhr = new XMLHttpRequest();
//       xhr.open("POST", `${apiUrl}/upload-video`);

//       // Update progress state
//       xhr.upload.onprogress = (event) => {
//         if (event.lengthComputable) {
//           const percentComplete = (event.loaded / event.total) * 100;
//           setUploadProgress(Math.round(percentComplete));
//         }
//       };

//       xhr.onload = () => {
//         if (xhr.status === 200) {
//           const response: UploadResponse = JSON.parse(xhr.responseText);
//           setVideos((prevVideos) => [...prevVideos, response.videoData]); // Add the new video to the list
//           resolve(response);
//         } else {
//           reject(new Error("Failed to upload video"));
//         }
//       };

//       xhr.onerror = () => reject(new Error("Error during video upload"));

//       xhr.send(formData);
//     });
//   };

//   // Function to remove videos
//   const removeVideos = async (videoIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-videos`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ videoIds }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to remove videos');
//       }

//       // Remove the videos from the list
//       setVideos((prevVideos) => prevVideos.filter((video) => !videoIds.includes(video.videoId)));
//     } catch (error) {
//       console.error('Error removing videos:', error);
//     }
//   };

//   // Fetch video list when the hook is used (component mounts)
//   useEffect(() => {
//     fetchVideos();
//   }, [fetchVideos]);

//   return { videos, categories, uploadVideo, uploadProgress, removeVideos, loading };
// };

// export default useVideoUpload;


// // updatae code 03 oct 2024
// // src/hooks/AdminDashboard/useVideoUpload.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoTitle: string;
//   videoCategory: string;
//   videoPermission: string;
//   videoDescription: string;
//   videoLength: string;
//   videoThumbnail: string;
//   videoPath: string;
//   videoViews: number;
//   createdAt: string;
// }

// interface UploadResponse {
//   message: string;
//   videoData: VideoData;
// }

// const useVideoUpload = () => {
//   const { apiUrl } = useEnv();
//   const [videos, setVideos] = useState<VideoData[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [categories, setCategories] = useState<string[]>([]);

//   // Function to fetch video list
//   const fetchVideos = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/videos`);
//       const data: VideoData[] = await response.json();
//       setVideos(data);
//       setLoading(false);

//       // Extract unique categories from videos
//       const uniqueCategories = Array.from(new Map(data.map((video) => [video.videoCategory, video.videoCategory])).values());
//       setCategories(uniqueCategories);

//     } catch (error) {
//       console.error('Error fetching video data:', error);
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   // Function to upload video
//   const uploadVideo = async (formData: FormData): Promise<UploadResponse | null> => {
//     try {
//       const response = await fetch(`${apiUrl}/upload-video`, {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Failed to upload video');
//       }

//       const data: UploadResponse = await response.json();
//       setVideos((prevVideos) => [...prevVideos, data.videoData]); 
//       return data;
//     } catch (error) {
//       console.error('Error during video upload:', error);
//       return null;
//     }
//   };

//   // Function to remove videos
//   const removeVideos = async (videoIds: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/remove-videos`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ videoIds }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to remove videos');
//       }

//       // Remove the videos from the list
//       setVideos((prevVideos) => prevVideos.filter((video) => !videoIds.includes(video.videoId)));
//     } catch (error) {
//       console.error('Error removing videos:', error);
//     }
//   };

//   // Fetch video list when the hook is used (component mounts)
//   useEffect(() => {
//     fetchVideos();
//   }, [fetchVideos]);

//   return { videos, categories, uploadVideo, removeVideos, loading };
// };

// export default useVideoUpload;
