// src/components/login/SignUp.tsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { 
  FaUser, FaEnvelope, FaPhone, FaLock, FaCalendarAlt, FaMapMarkerAlt, 
  FaChevronRight, FaEye, FaEyeSlash, FaClipboardList, 
  FaUserAlt, FaCogs, FaTimes, FaVenusMars, FaRegAddressCard
} from 'react-icons/fa';
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

import { useTheme } from '../../context/ThemeContext';
import { useFormValidation, countryOptions, phoneOptions, stateOptions } from '../../hooks/Login/useFormValidation';
import { useSignUp } from '../../hooks/Login/useSignUp';
import SignUpAlert from '../login/SignUpAlert';

interface SetupPasswordProps {
  visible: boolean;
  onClose: () => void;
}

const SignUp: React.FC<SetupPasswordProps> = ({ visible, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [isExistingEmailChecked, setIsExistingEmailChecked] = useState(false); // Added state
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
  const { theme } = useTheme();
  const formik = useFormValidation(onClose);
  const { signUp, checkExistingUser  } = useSignUp();

  const next = () => setCurrentStep(currentStep + 1);
  const prev = () => setCurrentStep(currentStep - 1);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(e.target.checked);
  };

  const handleEmailBlur = async () => {
    if (formik.values.memberType === 'existing' && formik.values.email && !isExistingEmailChecked) {
      try {
        const exists = await checkExistingUser(formik.values.email);
        if (!exists) {
          setEmailErrorMessage('The email is not associated with an existing member. Please choose "New Member".');
          formik.setFieldValue('memberType', 'new');
        } else {
          setEmailErrorMessage(null);
        }
        setIsExistingEmailChecked(true);
      } catch (error) {
        console.error('Error checking existing user:', error);
        setEmailErrorMessage('Error checking existing user. Please try again later.');
      }
    }
  };  

  const handleMemberTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(e);
    if (e.target.value === 'new') {
      setIsExistingEmailChecked(false); 
      setEmailErrorMessage(null); 
    }
  };  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formik.isValid) {
      formik.validateForm();
      setAlertSuccess(false);
      setShowAlert(true);
      return;
    }
    const { 
      name, 
      icNo,
      countryCode, 
      contactNumber, 
      address, 
      email, 
      password, 
      dob, 
      country,
      state, 
      memberType, 
      gender  
    } = formik.values;
    const phone = `${countryCode}${contactNumber}`;
  
    try {
      await signUp({
        termsAndConditions: 'agree',
        memberType,
        email,
        gender,
        name,
        icNo,
        phone,
        dob,
        country,
        state,
        address,
        password,
        role: 'user',
      });
  
      setAlertSuccess(true);
    } catch (err) {
      console.error('Sign-up error:', err);
      setAlertSuccess(false);
    } finally {
      setShowAlert(true);
    }
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  const steps = [
    {
      title: 'Acknowledgment',
      icon: <FaClipboardList />,
      content: (
        <div className="space-y-4">
          {/* Term and Condition */}
          <div className="max-h-[400px] overflow-y-scroll border-2 border-cyan-800 border-opacity-20 p-4 text-base md:h-[600px] md:text-sm sm:text-xs xs:text-xs " 
            style={styles.text}>
            {/* Detailed Terms and Conditions content */}
            <p><strong>1. Introduction</strong></p><br />
            <p>Welcome to R One Century 创市纪. By registering for an account, you agree to abide by these Terms and Conditions. Please read them carefully.</p><br />
      
            <p><strong>2. Account Registration</strong></p><br />
            <p>2.1. You must provide accurate, current, and complete information during the registration process.</p><br />
            <p>2.2. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p><br />
            <p>2.3. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p><br />
      
            <p><strong>3. User Data Protection</strong></p><br />
            <p>3.1. We are committed to protecting your personal data in compliance with applicable data protection laws, including the General Data Protection Regulation (GDPR).</p><br />
            <p>3.2. By registering for an account, you consent to the collection, use, and processing of your personal data as described in our Privacy Policy.</p><br />
            <p>3.3. Your data will only be used for the purposes of providing and improving our services, and we will not share your personal information with third parties 
               without your explicit consent, except as required by law.</p><br />
      
            <p><strong>4. User Responsibilities</strong></p><br />
            <p>4.1. You agree not to use your account for any illegal or unauthorized purpose.</p><br />
            <p>4.2. You must not, in the use of the service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p><br />
            <p>4.3. You agree to provide accurate and truthful information and to update your account information to keep it accurate and current.</p><br />
      
            <p><strong>5. Security</strong></p><br />
            <p>5.1. We employ appropriate technical and organizational measures to protect your personal data from unauthorized access, use, alteration, and disclosure.</p><br />
            <p>5.2. However, we cannot guarantee absolute security and are not responsible for any unauthorized access or use of your personal data that is beyond our control.</p><br />
      
            <p><strong>6. Termination</strong></p><br />
            <p>6.1. We reserve the right to suspend or terminate your account at our discretion if you violate these Terms and Conditions.</p><br />
            <p>6.2. You may terminate your account at any time by contacting our support team.</p><br />
      
            <p><strong>7. Changes to Terms and Conditions</strong></p><br />
            <p>7.1. We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms and Conditions on our website.</p><br />
            <p>7.2. Your continued use of our services after any changes to these Terms and Conditions constitutes your acceptance of the new terms.</p><br />
      
            <p><strong>8. Contact Information</strong></p><br />
            <p>8.1. If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:general@r1trader.com" style={styles.title}>general@r1trader.com</a>.</p><br />
      
            <p>By registering for an account, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="terms" checked={termsAgreed} onChange={handleTermsChange} 
              className="form-checkbox h-4 w-4" 
              style={{ 
                accentColor:theme.primaryColor 
              }} 
            />
            <label htmlFor="terms" className="ml-4 text-base sm:text-sm xs:text-sm" style={styles.text}>
                I acknowledge the Personal Data Usage and Protection
            </label>
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={next} className="flex items-center px-4 py-2 rounded-lg" 
              disabled={!termsAgreed}
              style={styles.button}>
              <span className="mr-2 text-lg md:text-base sm:text-sm xs:text-xs">Next</span> <IoMdArrowDropright />
            </button>
          </div>
        </div>
      ),
    },    
    {
      title: 'Member Type',
      icon: <FaUserAlt />,
      content: (
        <div className="space-y-4">
          {/* Member Type Section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaUser className="mr-2" />
              <label>Member Type</label>
            </div>
            <select
              name="memberType"
              value={formik.values.memberType}
              onChange={handleMemberTypeChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-zinc-500 focus:border-gray-200 text-base sm:text-sm xs:text-sm"
              style={{ fontFamily: theme.fontFamily }}
            >
              <option value="" disabled>Select member type</option>
              <option value="new">New Member</option>
              <option value="existing">Existing Member</option>
            </select>
            {formik.touched.memberType && formik.errors.memberType ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.memberType}</div>
            ) : null}
            {emailErrorMessage && (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{emailErrorMessage}</div>
            )}
          </div>
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaEnvelope className="mr-2" />
              <label>Email</label>
            </div>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={handleEmailBlur}
              className="block w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              placeholder="Enter a email..."
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.email}</div>
            ) : null}
          </div>

          {/* Gender Section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaVenusMars className="mr-2" />
              <label>Gender</label>
            </div>
            <select
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-zinc-500 focus:border-gray-200 text-base sm:text-sm xs:text-sm"
              style={styles.text}
            >
              <option value="" disabled>Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            {formik.touched.gender && formik.errors.gender ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.gender}</div>
            ) : null}
          </div>

          {/* continus button */}
          <div className="flex justify-between">
            <button type="button" onClick={prev} className="flex items-center px-4 py-2 bg-gray-200 rounded-lg sm:text-sm xs:text-xs"
              style={styles.text}>
              <IoMdArrowDropleft /> <span className="ml-2">Previous</span>
            </button>
            <button type="button" onClick={next} className="flex items-center px-4 py-2 rounded-lg sm:text-sm xs:text-xs"
              style={styles.button}>
              <span className="mr-2">Next</span> <IoMdArrowDropright />
            </button>
          </div>
        </div>
      ),
    },
    {
      title: 'Personal Info',
      icon: <FaUserAlt />,
      content: (
        <div className="space-y-4 overflow-y-auto h-auto max-h-[500px]">

          {/* User name section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaUser className="mr-2" />
              <label>Full Name</label>
            </div>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              style={styles.text}
              placeholder="Enter your full name..."
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.name}</div>
            ) : null}
          </div>

          {/* User id section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaRegAddressCard className="mr-2" />
              <label>Identity Card No</label>
            </div>
            <input
              type="text"
              name="icNo"
              value={formik.values.icNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              style={styles.text}
              placeholder="Identity Card No. xxxxxx-xx-xxxx"
            />
            {formik.touched.icNo && formik.errors.icNo ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.icNo}</div>
            ) : null}
          </div>

          {/* country code and contact number section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaPhone className="mr-2" />
              <label>Contact No</label>
            </div>
            <div className="flex">
              <select
                name="countryCode"
                value={formik.values.countryCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="block w-[140px] pl-4 pr-3 py-2 border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
                style={styles.text}
              >
                <option value="">Select a code</option>
                {phoneOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="block w-3/4 pl-3 pr-3 py-2 border border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
                placeholder="Contact Number"
                style={styles.text}
              />
            </div>
            {formik.touched.countryCode && formik.errors.countryCode ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.countryCode}</div>
            ) : null}
            {formik.touched.contactNumber && formik.errors.contactNumber ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.contactNumber}</div>
            ) : null}
          </div>

          {/* date of birth section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaCalendarAlt className="mr-2" />
              <label>Date of Birth</label>
            </div>
            <input
              type="date"
              name="dob"
              value={formik.values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
            />
            {formik.touched.dob && formik.errors.dob ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.dob}</div>
            ) : null}
          </div>

          {/* country section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaMapMarkerAlt className="mr-2" />
              <label>Country</label>
            </div>
            <select
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              style={styles.text}
            >
              <option value="">Select a country</option>
              {countryOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
            {formik.touched.country && formik.errors.country ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.country}</div>
            ) : null}
          </div>

          {/* state section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaMapMarkerAlt className="mr-2" />
              <label>State</label>
            </div>
            <select
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              style={styles.text}
            >
              <option value="">Select a state</option>
              {stateOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
            {formik.touched.state && formik.errors.state ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.state}</div>
            ) : null}
          </div>

          {/* address section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaMapMarkerAlt className="mr-2" />
              <label>Address</label>
            </div>
            <textarea
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              style={{ fontFamily: theme.fontFamily }}
              placeholder="Enter your full address..."
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.address}</div>
            ) : null}
          </div>

          {/* continus button section */}
          <div className="flex justify-between">
            <button type="button" onClick={prev} className="flex items-center px-4 py-2 bg-gray-300 rounded-lg sm:text-sm xs:text-xs" 
              style={styles.text}>
              <IoMdArrowDropleft /> <span className="ml-2">Previous</span>
            </button>
            <button type="button" onClick={next} className="flex items-center px-4 py-2 rounded-lg sm:text-sm xs:text-xs"
              style={styles.button}>
              <span className="mr-2">Next</span> <IoMdArrowDropright />
            </button>
          </div>
        </div>
      ),
    },
    {
      title: 'Setup Password',
      icon: <FaCogs />,
      content: (
        <div className="space-y-4">
          {/* password section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaLock className="mr-2" />
              <label>Password</label>
            </div>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              style={styles.text}
              placeholder="Password"
            />
            <button type="button" onClick={togglePasswordVisibility} className="absolute right-3 bottom-3 text-gray-400 focus:outline-none">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-600 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">
                {formik.errors.password}
                <ul className="text-red-500 text-xs mt-1 list-disc pl-5">
                  <li>Must contain at least one letter</li>
                  <li>Must contain at least one number</li>
                  <li>Must contain at least one symbol</li>
                  <li>Must be at least 8 characters</li>
                </ul>
              </div>
            ) : null}
          </div>

          {/* confirm password section */}
          <div className="relative">
            <div className="flex justify-normal items-center text-cyan-800 font-semibold mx-2 xs:text-sm">
              <FaLock className="mr-2" />
              <label>Confirm Password</label>
            </div>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="block w-full pl-4 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
              style={styles.text}
              placeholder="Confirm Password"
            />
            <button type="button" onClick={toggleConfirmPasswordVisibility} className="absolute right-3 bottom-3 text-gray-400 focus:outline-none">
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2" style={styles.text}>{formik.errors.confirmPassword}</div>
            ) : null}
          </div>

          {/* submit button section */}
          <div className="flex justify-between">
            <button type="button" onClick={prev} className="flex items-center px-4 py-2 bg-gray-300 rounded-lg sm:text-sm xs:text-xs" 
              style={styles.text}>
              <IoMdArrowDropleft />
              <span className="ml-2">
                Previous
              </span>
            </button>
            <button type="submit" className="flex items-center px-4 py-2 rounded-lg sm:text-sm xs:text-xs" 
              style={styles.button}>
              <span className="mr-2">
                Submit
              </span>
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={`fixed inset-0 flex items-center justify-center ${visible ? 'bg-gray-900 bg-opacity-50 z-50' : 'hidden'}`}>
      <motion.div
        className="bg-white rounded-lg shadow-lg p-6 w-auto max-w-lg h-auto max-h-lg relative sm:w-[400px] xs:w-[365px]"
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: visible ? 1 : 0, scale: visible ? 1 : 0.8 }}
        transition={{ duration: 0.3 }}
      >
        <button onClick={onClose} className="absolute border-2 border-rose-200 rounded-full top-7 right-6 text-rose-300 hover:text-gray-600 focus:outline-none sm:text-base xs:text-sm">
          <FaTimes />
        </button>
        <div className="text-center mb-2">
          <h4 className="text-xl font-semibold md:text-lg sm:text-base xs:text-base"  
            style={styles.title}>
              Register Here
          </h4>
        </div>
        <div>
          <div className="flex items-center py-4 overflow-x-auto whitespace-nowrap text-lg md:text-base sm:text-sm xs:text-sm">
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <button className={`text-gray-600 ${currentStep === index ? 'text-blue-600' : ''}`} onClick={() => setCurrentStep(index)} disabled={currentStep !== index}>
                  <span className="flex items-center" style={styles.title}>
                    {step.icon}
                    <span className="ml-2 font-semibold">
                      {step.title}
                    </span>
                  </span>
                </button>
                {index !== steps.length - 1 && (
                  <span className="mx-5 text-gray-500">
                    <FaChevronRight />
                  </span>
                )}
              </React.Fragment>
            ))}
          </div>
          <form onSubmit={handleSubmit}>
            {steps[currentStep].content}
          </form>
        </div>
      </motion.div>
      <SignUpAlert visible={showAlert} onClose={() => setShowAlert(false)} success={alertSuccess} />
    </div>
  );
};

export default SignUp;


// // udpate code --> 19 july 2024
// // src/components/login/SignUp.tsx

// import React, { useState } from 'react';
// import { motion } from 'framer-motion';

// import { 
//   FaUser, FaEnvelope, FaPhone, FaLock, FaCalendarAlt, FaMapMarkerAlt, 
//   FaChevronRight, FaEye, FaEyeSlash, FaClipboardList, 
//   FaUserAlt, FaCogs, FaTimes, FaVenusMars
// } from 'react-icons/fa';
// import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

// import { useTheme } from '../../context/ThemeContext';
// import { useFormValidation, countryOptions } from '../../hooks/Login/useFormValidation';
// import { useSignUp } from '../../hooks/Login/useSignUp';
// import SignUpAlert from '../login/SignUpAlert';

// interface SetupPasswordProps {
//   visible: boolean;
//   onClose: () => void;
// }

// const SignUp: React.FC<SetupPasswordProps> = ({ visible, onClose }) => {
//   const [currentStep, setCurrentStep] = useState(0);
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [termsAgreed, setTermsAgreed] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const [alertSuccess, setAlertSuccess] = useState(false);
//   const [isExistingEmailChecked, setIsExistingEmailChecked] = useState(false); // Added state
//   const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
//   const { theme } = useTheme();
//   const formik = useFormValidation(onClose);
//   const { signUp, checkExistingUser  } = useSignUp(); // Added checkExistingUser

//   const next = () => setCurrentStep(currentStep + 1);
//   const prev = () => setCurrentStep(currentStep - 1);

//   const togglePasswordVisibility = () => setShowPassword(!showPassword);
//   const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

//   const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setTermsAgreed(e.target.checked);
//   };

//   const handleEmailBlur = async () => {
//     if (formik.values.memberType === 'existing' && formik.values.email && !isExistingEmailChecked) {
//       try {
//         const exists = await checkExistingUser(formik.values.email);
//         if (!exists) {
//           setEmailErrorMessage('The email is not associated with an existing member. Please choose "New Member".');
//           formik.setFieldValue('memberType', 'new');
//         } else {
//           setEmailErrorMessage(null);
//         }
//         setIsExistingEmailChecked(true);
//       } catch (error) {
//         console.error('Error checking existing user:', error);
//         setEmailErrorMessage('Error checking existing user. Please try again later.');
//       }
//     }
//   };  

//   const handleMemberTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     formik.handleChange(e);
//     if (e.target.value === 'new') {
//       setIsExistingEmailChecked(false); // Reset check if user switches to new member
//       setEmailErrorMessage(null); // Clear the error message
//     }
//   };  

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     if (!formik.isValid) {
//       formik.validateForm();
//       setAlertSuccess(false);
//       setShowAlert(true);
//       return;
//     }
//     const { name, countryCode, contactNumber, address, email, password, dob, country, memberType, gender  } = formik.values;
//     const phone = `${countryCode}${contactNumber}`;
  
//     try {
//       console.log('Submitting form data');
//       await signUp({
//         termsAndConditions: 'agree',
//         memberType,
//         email,
//         gender,
//         name,
//         phone,
//         dob,
//         country,
//         address,
//         password,
//       });
  
//       setAlertSuccess(true);
//     } catch (err) {
//       console.error('Sign-up error:', err);
//       setAlertSuccess(false);
//     } finally {
//       setShowAlert(true);
//     }
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   const steps = [
//     {
//       title: 'Acknowledgment',
//       icon: <FaClipboardList />,
//       content: (
//         <div className="space-y-4">
//           <div className="max-h-[400px] overflow-y-scroll border-2 border-cyan-800 border-opacity-20 p-4 text-base md:h-[600px] md:text-sm sm:text-xs xs:text-xs " 
//             style={styles.text}>
//             {/* Detailed Terms and Conditions content */}
//             <p><strong>1. Introduction</strong></p><br />
//             <p>Welcome to R One Century 创市纪. By registering for an account, you agree to abide by these Terms and Conditions. Please read them carefully.</p><br />
      
//             <p><strong>2. Account Registration</strong></p><br />
//             <p>2.1. You must provide accurate, current, and complete information during the registration process.</p><br />
//             <p>2.2. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p><br />
//             <p>2.3. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p><br />
      
//             <p><strong>3. User Data Protection</strong></p><br />
//             <p>3.1. We are committed to protecting your personal data in compliance with applicable data protection laws, including the General Data Protection Regulation (GDPR).</p><br />
//             <p>3.2. By registering for an account, you consent to the collection, use, and processing of your personal data as described in our Privacy Policy.</p><br />
//             <p>3.3. Your data will only be used for the purposes of providing and improving our services, and we will not share your personal information with third parties 
//                without your explicit consent, except as required by law.</p><br />
      
//             <p><strong>4. User Responsibilities</strong></p><br />
//             <p>4.1. You agree not to use your account for any illegal or unauthorized purpose.</p><br />
//             <p>4.2. You must not, in the use of the service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p><br />
//             <p>4.3. You agree to provide accurate and truthful information and to update your account information to keep it accurate and current.</p><br />
      
//             <p><strong>5. Security</strong></p><br />
//             <p>5.1. We employ appropriate technical and organizational measures to protect your personal data from unauthorized access, use, alteration, and disclosure.</p><br />
//             <p>5.2. However, we cannot guarantee absolute security and are not responsible for any unauthorized access or use of your personal data that is beyond our control.</p><br />
      
//             <p><strong>6. Termination</strong></p><br />
//             <p>6.1. We reserve the right to suspend or terminate your account at our discretion if you violate these Terms and Conditions.</p><br />
//             <p>6.2. You may terminate your account at any time by contacting our support team.</p><br />
      
//             <p><strong>7. Changes to Terms and Conditions</strong></p><br />
//             <p>7.1. We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms and Conditions on our website.</p><br />
//             <p>7.2. Your continued use of our services after any changes to these Terms and Conditions constitutes your acceptance of the new terms.</p><br />
      
//             <p><strong>8. Contact Information</strong></p><br />
//             <p>8.1. If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:general@r1trader.com" style={styles.title}>general@r1trader.com</a>.</p><br />
      
//             <p>By registering for an account, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
//           </div>
//           <div className="flex items-center">
//             <input type="checkbox" id="terms" checked={termsAgreed} onChange={handleTermsChange} 
//               className="form-checkbox h-4 w-4" 
//               style={{ 
//                 accentColor:theme.primaryColor 
//               }} 
//             />
//             <label htmlFor="terms" className="ml-4 text-base sm:text-sm xs:text-sm" style={styles.text}>
//                 I acknowledge the Personal Data Usage and Protection
//             </label>
//           </div>
//           <div className="flex justify-end">
//             <button type="button" onClick={next} className="flex items-center px-4 py-2 rounded-lg" 
//               disabled={!termsAgreed}
//               style={styles.button}>
//               <span className="mr-2 text-lg md:text-base sm:text-sm xs:text-xs">Next</span> <IoMdArrowDropright />
//             </button>
//           </div>
//         </div>
//       ),
//     },    
//     {
//       title: 'Member Type',
//       icon: <FaUserAlt />,
//       content: (
//         <div className="space-y-4">
//           <div className="relative">
//             <FaUser className="absolute top-3 left-3 text-gray-400" />
//             <select
//               name="memberType"
//               value={formik.values.memberType}
//               onChange={handleMemberTypeChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-zinc-500 focus:border-gray-200 text-base sm:text-sm xs:text-sm"
//               style={{ fontFamily: theme.fontFamily }}
//             >
//               <option value="" disabled>Select member type</option>
//               <option value="new">New Member</option>
//               <option value="existing">Existing Member</option>
//             </select>
//             {formik.touched.memberType && formik.errors.memberType ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.memberType}</div>
//             ) : null}
//             {emailErrorMessage && (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{emailErrorMessage}</div>
//             )}
//           </div>
//           <div className="relative">
//             <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
//             <input
//               type="email"
//               name="email"
//               value={formik.values.email}
//               onChange={formik.handleChange}
//               onBlur={handleEmailBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//               placeholder="Email"
//             />
//             {formik.touched.email && formik.errors.email ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.email}</div>
//             ) : null}
//           </div>
//           <div className="relative">
//             <FaVenusMars className="absolute top-3 left-3 text-gray-400" />
//             <select
//               name="gender"
//               value={formik.values.gender}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-zinc-500 focus:border-gray-200 text-base sm:text-sm xs:text-sm"
//               style={styles.text}
//             >
//               <option value="" disabled>Select gender</option>
//               <option value="male">Male</option>
//               <option value="female">Female</option>
//             </select>
//             {formik.touched.gender && formik.errors.gender ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.gender}</div>
//             ) : null}
//           </div>
//           <div className="flex justify-between">
//             <button type="button" onClick={prev} className="flex items-center px-4 py-2 bg-gray-200 rounded-lg sm:text-sm xs:text-xs"
//               style={styles.text}>
//               <IoMdArrowDropleft /> <span className="ml-2">Previous</span>
//             </button>
//             <button type="button" onClick={next} className="flex items-center px-4 py-2 rounded-lg sm:text-sm xs:text-xs"
//               style={styles.button}>
//               <span className="mr-2">Next</span> <IoMdArrowDropright />
//             </button>
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: 'Personal Info',
//       icon: <FaUserAlt />,
//       content: (
//         <div className="space-y-4">
//           <div className="relative">
//             <FaUser className="absolute top-3 left-3 text-gray-400" />
//             <input
//               type="text"
//               name="name"
//               value={formik.values.name}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//               style={styles.text}
//               placeholder="Name"
//             />
//             {formik.touched.name && formik.errors.name ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.name}</div>
//             ) : null}
//           </div>
//           <div className="relative">
//             <FaPhone className="absolute top-3 left-3 text-gray-400" />
//             <div className="flex">
//               <input
//                 type="text"
//                 name="countryCode"
//                 value={formik.values.countryCode}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="block w-1/4 pl-10 pr-3 py-2 border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//                 placeholder="+Country Code"
//                 style={styles.text}
//               />
//               <input
//                 type="text"
//                 name="contactNumber"
//                 value={formik.values.contactNumber}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="block w-3/4 pl-3 pr-3 py-2 border border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//                 placeholder="Contact Number"
//                 style={styles.text}
//               />
//             </div>
//             {formik.touched.countryCode && formik.errors.countryCode ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.countryCode}</div>
//             ) : null}
//             {formik.touched.contactNumber && formik.errors.contactNumber ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.contactNumber}</div>
//             ) : null}
//           </div>
//           <div className="relative">
//             <FaCalendarAlt className="absolute top-3 left-3 text-gray-400" />
//             <input
//               type="date"
//               name="dob"
//               value={formik.values.dob}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//             />
//             {formik.touched.dob && formik.errors.dob ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.dob}</div>
//             ) : null}
//           </div>
//           <div className="relative">
//             <FaMapMarkerAlt className="absolute top-3 left-3 text-gray-400" />
//             <select
//               name="country"
//               value={formik.values.country}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//               style={styles.text}
//             >
//               {countryOptions.map(option => (
//                 <option key={option.value} value={option.value}>{option.label}</option>
//               ))}
//             </select>
//             {formik.touched.country && formik.errors.country ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.country}</div>
//             ) : null}
//           </div>
//           <div className="relative">
//             <FaMapMarkerAlt className="absolute top-3 left-3 text-gray-400" />
//             <textarea
//               name="address"
//               value={formik.values.address}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//               style={{ fontFamily: theme.fontFamily }}
//               placeholder="Address"
//             />
//             {formik.touched.address && formik.errors.address ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">{formik.errors.address}</div>
//             ) : null}
//           </div>
//           <div className="flex justify-between">
//             <button type="button" onClick={prev} className="flex items-center px-4 py-2 bg-gray-300 rounded-lg sm:text-sm xs:text-xs" 
//               style={styles.text}>
//               <IoMdArrowDropleft /> <span className="ml-2">Previous</span>
//             </button>
//             <button type="button" onClick={next} className="flex items-center px-4 py-2 rounded-lg sm:text-sm xs:text-xs"
//               style={styles.button}>
//               <span className="mr-2">Next</span> <IoMdArrowDropright />
//             </button>
//           </div>
//         </div>
//       ),
//     },
//     {
//       title: 'Setup Password',
//       icon: <FaCogs />,
//       content: (
//         <div className="space-y-4">
//           <div className="relative">
//             <FaLock className="absolute top-3 left-3 text-gray-400" />
//             <input
//               type={showPassword ? "text" : "password"}
//               name="password"
//               value={formik.values.password}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base sm:text-sm xs:text-sm"
//               style={styles.text}
//               placeholder="Password"
//             />
//             <button type="button" onClick={togglePasswordVisibility} className="absolute right-3 top-3 text-gray-400 focus:outline-none">
//               {showPassword ? <FaEyeSlash /> : <FaEye />}
//             </button>
//             {formik.touched.password && formik.errors.password ? (
//               <div className="text-red-600 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2">
//                 {formik.errors.password}
//                 <ul className="text-red-500 text-xs mt-1 list-disc pl-5">
//                   <li>Must contain at least one letter</li>
//                   <li>Must contain at least one number</li>
//                   <li>Must contain at least one symbol</li>
//                   <li>Must be at least 8 characters</li>
//                 </ul>
//               </div>
//             ) : null}
//           </div>
//           <div className="relative">
//             <FaLock className="absolute top-3 left-3 text-gray-400"/>
//             <input
//               type={showConfirmPassword ? "text" : "password"}
//               name="confirmPassword"
//               value={formik.values.confirmPassword}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base"
//               style={styles.text}
//               placeholder="Confirm Password"
//             />
//             <button type="button" onClick={toggleConfirmPasswordVisibility} className="absolute right-3 top-3 text-gray-400 focus:outline-none">
//               {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//             </button>
//             {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
//               <div className="text-red-500 text-xs mt-1 rounded border border-red-500 bg-red-100 p-2" style={styles.text}>{formik.errors.confirmPassword}</div>
//             ) : null}
//           </div>
//           <div className="flex justify-between">
//             <button type="button" onClick={prev} className="flex items-center px-4 py-2 bg-gray-300 rounded-lg sm:text-sm xs:text-xs" 
//               style={styles.text}>
//               <IoMdArrowDropleft />
//               <span className="ml-2">
//                 Previous
//               </span>
//             </button>
//             <button type="submit" className="flex items-center px-4 py-2 rounded-lg sm:text-sm xs:text-xs" 
//               style={styles.button}>
//               <span className="mr-2">
//                 Submit
//               </span>
//             </button>
//           </div>
//         </div>
//       ),
//     },
//   ];

//   return (
//     <div className={`fixed inset-0 flex items-center justify-center ${visible ? 'bg-gray-900 bg-opacity-50 z-50' : 'hidden'}`}>
//       <motion.div
//         className="bg-white rounded-lg shadow-lg p-6 w-auto max-w-lg h-auto max-h-lg relative sm:w-[400px] xs:w-[365px]"
//         onClick={(e) => e.stopPropagation()}
//         initial={{ opacity: 0, scale: 0.8 }}
//         animate={{ opacity: visible ? 1 : 0, scale: visible ? 1 : 0.8 }}
//         transition={{ duration: 0.3 }}
//       >
//         <button onClick={onClose} className="absolute border-2 border-rose-200 rounded-full top-7 right-6 text-rose-300 hover:text-gray-600 focus:outline-none sm:text-base xs:text-sm">
//           <FaTimes />
//         </button>
//         <div className="text-center mb-2">
//           <h4 className="text-xl font-semibold md:text-lg sm:text-base xs:text-base"  
//             style={styles.title}>
//               Register Here
//           </h4>
//         </div>
//         <div>
//           <div className="flex items-center py-4 overflow-x-auto whitespace-nowrap text-lg md:text-base sm:text-sm xs:text-sm">
//             {steps.map((step, index) => (
//               <React.Fragment key={index}>
//                 <button className={`text-gray-600 ${currentStep === index ? 'text-blue-600' : ''}`} onClick={() => setCurrentStep(index)} disabled={currentStep !== index}>
//                   <span className="flex items-center" style={styles.title}>
//                     {step.icon}
//                     <span className="ml-2 font-semibold">
//                       {step.title}
//                     </span>
//                   </span>
//                 </button>
//                 {index !== steps.length - 1 && (
//                   <span className="mx-5 text-gray-500">
//                     <FaChevronRight />
//                   </span>
//                 )}
//               </React.Fragment>
//             ))}
//           </div>
//           <form onSubmit={handleSubmit}>
//             {steps[currentStep].content}
//           </form>
//         </div>
//       </motion.div>
//       <SignUpAlert visible={showAlert} onClose={() => setShowAlert(false)} success={alertSuccess} />
//     </div>
//   );
// };

// export default SignUp;

