// src/components/admin/ClientData/UsersTableStats.tsx

import React from 'react';

interface StatsSectionProps {
  userData: any[];
}

const UsersStatsSection: React.FC<StatsSectionProps> = ({ userData }) => {
  const totalUsers = userData.length;
  const algoMatrixUsers = userData.filter(user => user.stages === 'algomatrix').length;
  const traderHubUsers = userData.filter(user => user.stages === 'traderhub').length;
  const fundedPrograms = userData.filter(user => user.stages === 'funded program').length;

  return (
    <div className="flex flex-row justify-center items-center space-x-2 bg-gray-100 rounded-md p-6 w-full max-w-screen">
      <div className="font-bold text-xl text-cyan-800 w-[100px] mx-4">
        Overview Stats
      </div>
      <div className="flex flex-row justify-end space-x-4 text-right">
        <div className="bg-gray-100 text-cyan-800 p-4 rounded-lg shadow-lg border">
            <h2 className="text-lg font-semibold mx-6">Total Users</h2>
            <p className="text-2xl font-bold">{totalUsers}</p>
        </div>
        <div className="bg-gray-100 text-cyan-800 p-4 rounded-lg shadow-lg border">
            <h2 className="text-lg font-semibold">Algomatrix Users</h2>
            <p className="text-2xl font-bold">{algoMatrixUsers}</p>
        </div>
        <div className="bg-gray-100 text-cyan-800 p-4 rounded-lg shadow-lg border">
            <h2 className="text-lg font-semibold">TraderHub Users</h2>
            <p className="text-2xl font-bold">{traderHubUsers}</p>
        </div>
        <div className="bg-gray-100 text-cyan-800 p-4 rounded-lg shadow-lg border">
            <h2 className="text-lg font-semibold">Funded Programs</h2>
            <p className="text-2xl font-bold">{fundedPrograms}</p>
        </div>
      </div>
    </div>
  );
};

export default UsersStatsSection;
