// src/hooks/MiniApp/userVideoValidation.tsx

import useUserDashboard from '../hooks/Dashboard/useUserDashboard';
import { useEnv } from '../context/EnvContext'; 

const useVideoValidation = () => {
  const { user } = useUserDashboard();
  const { apiUrl } = useEnv();  // Use the API URL from the context

  const validateVideoPermission = async (videoId: string): Promise<boolean> => {
    // Check if user information is available
    if (!user || !user.email) {
      console.error('User information is missing. Cannot validate permission.');
      return false;
    }

    try {
      // Make the fetch call to validate video permission based on the video ID and user email
      const response = await fetch(`${apiUrl}/validate-video-permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: user.email,  
          videoId,                
        }),
      });

      const data = await response.json();

      // Check the response and return the permission status
      if (data.hasPermission) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error validating video permission:', error);
      return false;
    }
  };

  // Validate playlist permission based on playlistId and user email
  const validatePlaylistPermission = async (playlistId: string): Promise<boolean> => {
    if (!user || !user.email) {
      console.error('User information is missing. Cannot validate playlist permission.');
      return false;
    }

    try {
      const response = await fetch(`${apiUrl}/validate-playlist-permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: user.email,
          playlistId,
        }),
      });

      const data = await response.json();
      return data.hasPermission;
    } catch (error) {
      console.error('Error validating playlist permission:', error);
      return false;
    }
  };

  return { validateVideoPermission, validatePlaylistPermission };
};

export default useVideoValidation;

