// src/components/client/MiniApp/App03_MySubscriptionRegistration.tsx

// react component
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
// react icon
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaArrowCircleLeft, FaClipboard, FaInfoCircle, FaEyeSlash, FaEye } from 'react-icons/fa';
// context file
import { useTheme } from '../../../context/ThemeContext';
import { useEventRegTerms } from '../../../context/SubRegTermContext';
// custom hook file
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import useSubscriptionSubmit, { SubscriptionData } from '../../../hooks/Subscription/useSubscriptionSubmit';
import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';
import useCurrencyConversion from '../../../hooks/currencyAPI';
// custom components
import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
import ServerNameImg from '../../../assets/miniApp/broker_server_name.png'

// Add AlertType type definition here
type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';
interface RegistrationProps {
  plan: Plan;
  onBack: () => void;
  accountDetails: {
    accountID: string;
    accountType: string;
    accountNumber: string;
    accountPassword: string;
    accountServer: string;
  } | null;
}
const MySubscriptionRegistration: React.FC<RegistrationProps> = ({ plan, onBack, accountDetails }) => {
  // fetch data
  const { theme } = useTheme();
  const { user } = useUserDashboard();
  const { fetchTrialInfo } = useSubscriptionData();
  const { 
    submitSubscription, 
    fetchVoucherDetails, 
    checkUserTrialSubscription, 
    checkDemoSubscription, 
    updateVoucherDaysInDatabase,
    fetchAndUpdateUserAlgoMarixData,
    checkUserVoucherEntitlement, 
    loading
  } = useSubscriptionSubmit();
  const { validate, errors } = useFormValidation();
  const termsAndConditions = useEventRegTerms();
  // form field
  const [orderID, setOrderID] = useState('');
  const [planType, setPlanType] = useState('');
  const [planDays, setPlanDays] = useState<number | null>(null);
  const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
  const [planRequirement, setPlanRequirement] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  // apply referral 
  const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
  const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
  const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
  const [referralApplied, setReferralApplied] = useState(false);
  // fill in trading account detail(live)
  const [isLiveEnabled, setIsLiveEnabled] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [accountServer, setAccountServer] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountPassword, setAccountPassword] = useState('');
  // fill in trading account detail(demo)
  const [isDemoEnabled, setIsDemoEnabled] = useState(false);
  const [demoAccountType, setDemoAccountType] = useState('');
  const [demoAccountServer, setDemoAccountServer] = useState('');
  const [demoAccountNumber, setDemoAccountNumber] = useState('');
  const [demoAccountPassword, setDemoAccountPassword] = useState('');
  // other payment field
  const [notes, setNotes] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [receipts, setReceipts] = useState<File[]>([]);
  // renew 
  const [isRenewal, setIsRenewal] = useState(false);
  // apply voucher code
  const [applyDisabled, setApplyDisabled] = useState(true);
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
  const [voucherDays, setVoucherDays] = useState<number | null>(null);
  const [voucherEnabled, setVoucherEnabled] = useState(false);
  const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
  const [voucherApplied, setVoucherApplied] = useState(false);
  const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
  const {myrAmount} = useCurrencyConversion(0, parseFloat(discountedPlanAmount));
  // terms and conditions
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // set for alert message
  const [userHasDemo, setUserHasDemo] = useState(false);
  const [alert, setAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string, isLoading?: boolean }>({ visible: false, type: 'check', message: '' });
  const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  // search for trial details
  useEffect(() => {
    const fetchTrialDetails = async () => {
      if (planType === 'trial' && user?.email) {
        const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

        if (trialDays) {
          setPlanTrialDays(trialDays);
        } else {
          setPlanTrialDays(null);
        }
  
        if (trialRequirement) {
          setPlanRequirement(trialRequirement.split(',').map((req: string) => req.trim()));
        } else {
          setPlanRequirement([]);
        }
      } else {
        setPlanDays(parseInt(plan.planPeriod, 10));
        setPlanTrialDays(null);
      }
    };
    fetchTrialDetails();
  }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);   
  
  // Plan Amount Calculation
  useEffect(() => {
    let calculatedPlanAmount = parseFloat(plan.planAmount);

    if (plan.planPeriod === '365') {
      calculatedPlanAmount = calculatedPlanAmount * 12;
    }

    setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
  }, [plan.planAmount, plan.planPeriod]);

  // check for availble demo subscription
  useEffect(() => {
    const checkDemoSubscriptionStatus = async () => {
      if (user?.email) {
        const hasDemoSubscription = await checkDemoSubscription(user.email);
        setUserHasDemo(hasDemoSubscription);
      }
    };
    checkDemoSubscriptionStatus();
  }, [user?.email, checkDemoSubscription]); 
  
  // generated OrderID
  useEffect(() => {
    const generatedOrderID = 'sub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
    setOrderID(generatedOrderID);
  }, []);
  
  // toggle voucher enable or disable
  useEffect(() => {
    setApplyDisabled(!voucherEnabled);
  }, [voucherEnabled]);
  
  // disable voucher for trial
  useEffect(() => {
    if (planType === 'trial') {
      setVoucherEnabled(false);
    }
  }, [planType]);  
  
  // pass account data from renewal user
  useEffect(() => {
    if (accountDetails) {
      if (accountDetails.accountType === 'LIVE') {
        setAccountType(accountDetails.accountType);
        setAccountServer(accountDetails.accountServer);
        setAccountNumber(accountDetails.accountNumber);
        setAccountPassword(accountDetails.accountPassword);
        setIsLiveEnabled(true);
      } else if (accountDetails.accountType === 'DEMO') {
        setDemoAccountType(accountDetails.accountType);
        setDemoAccountServer(accountDetails.accountServer);
        setDemoAccountNumber(accountDetails.accountNumber);
        setDemoAccountPassword(accountDetails.accountPassword);
        setIsDemoEnabled(true);
      }
      setPlanType('Renew');
      setIsRenewal(true); 
    }
  }, [accountDetails]); 
  
  // toggle enabled or disable referral 
  useEffect(() => {
    if (isReferralEnabled) {
        setApplyReferralDisabled(false);
    } else {
        setApplyReferralDisabled(true);
    }
  }, [isReferralEnabled]);

  // Update the voucherEnabled useEffect
  useEffect(() => {
    const updateVoucherDetails = async () => {
      if (voucherEnabled && voucherCode) {
        try {

          // Fetch voucher details
          const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
          if (voucher) {
            const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
            const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
            const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

            // Update state with fetched data
            setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
            setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
            setVoucherRedeemCount(voucher.voucherRedeemCount);

            // Update the voucher days in the database
            await updateVoucherDaysInDatabase(voucher.id, remainingDays);

          } else {
            setVoucherPeriod(null);
            setVoucherDays(null);
          }
        } catch (error) {
          console.error('Error fetching voucher details:', error);
          setVoucherRedeemCount(0);
        }
      } else {
        setVoucherPeriod(null);
        setVoucherDays(null);
      }
    };

    updateVoucherDetails();
  }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

  const handleApplyReferralEmail = () => {
    setReferralAlert({
      visible: true,
      type: 'confirm',
      message: 'Do you want to apply this referral email?',
    });
    // Disable the referral email field after applying
    setIsReferralEnabled(false);
  };
  
  const confirmReferralApply = () => {
    try {
      setReferralApplied(true); // Set success status
      setReferralAlert({
        visible: true,
        type: 'success',
        title: 'Referral Applied',
        message: 'Referral email applied successfully!',
      });
    } catch (error) {
      setReferralApplied(false); // Set failure status
      setReferralAlert({
        visible: true,
        type: 'error',
        title: 'Referral Error',
        message: 'Failed to apply the referral. Please try again.',
      });
    }
  };

  const applyVoucher = async () => {
    if (voucherCode && !voucherApplied) {
      try {
          // Fetch voucher details without applying it yet to get the remaining days
          const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
          if (!voucher) {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'Voucher cannot be applied as it has already been fully redeemed.',
            });
            return;
          }

          // Check if the user is entitled to redeem this voucher
          const userEntitlement = await checkUserVoucherEntitlement(
            voucher.voucherCode, 
            voucher.voucherMemberType, 
            voucher.voucherGroupMember
          );

          if (!userEntitlement) {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'You are not entitled to redeem this voucher code.',
            });
            return;
          }

          // Check if the voucher is expired
          const currentDate = moment().tz("Asia/Kuala_Lumpur");
          const voucherPeriodDate = moment(voucher.voucherPeriod);
          const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

          if (remainingDays < 0) {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'The voucher has expired and cannot be applied.',
            });
            return;
          }

          // Check if the voucher has already been fully redeemed
          if (voucher.voucherRedeemCount === '0') {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'Voucher cannot be applied as it has already been fully redeemed.',
            });
            return; 
          }

          if (voucher && user) {
            // Fetch and update the user's AlgoMarix data in a single API call
            const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
            if (result) {
              const { accountVoucherCheck } = result;
      
              // Apply check only if the user exists and accountVoucherCheck is "0"
              if (accountVoucherCheck === '0' && planType !== 'subscribe') {
                setAlert({
                  visible: true,
                  type: 'error',
                  title: 'Voucher Error',
                  message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
                });
                return;
              }
            
              // Handle voucher redeem check logic
              if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
                setAlert({
                    visible: true,
                    type: 'error',
                    title: 'Voucher Error',
                    message: 'You have already redeemed this voucher code and cannot redeem it again.',
                });
                return;
              }

              // Handle specific redeem count 
              if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
                setAlert({
                    visible: true,
                    type: 'error',
                    title: 'Voucher Error',
                    message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
                });
                return;
              }

              let newAmount = parseFloat(plan.planAmount);

              // Adjust for annual plan period
              if (plan.planPeriod === '365') {
                newAmount = newAmount * 12;
              }
    
              if (voucher.voucherType === 'By Amount') {
                newAmount -= parseFloat(voucher.voucherAmount);
              } else if (voucher.voucherType === 'By Percentage') {
                newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
              }
    
              // Update voucherDays in the subscription data
              setVoucherDays(remainingDays);

              // Now that the voucher is successfully applied, decrement the redeem count
              await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
              await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

              setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
              setVoucherRedeemCount(voucher.voucherRedeemCount);
              setVoucherApplied(true); 
              setAlert({
                visible: true,
                type: 'success',
                title: 'Voucher Applied',
                message: 'Voucher applied successfully!',
              });
          } else {
            setAlert({
              visible: true,
              type: 'error',
              title: 'Voucher Error',
              message: 'Failed to retrieve user data. Please try again.',
            });
          }
        }
      } catch (error) {
        setDiscountedPlanAmount(plan.planAmount);
        setAlert({
          visible: true,
          type: 'error',
          title: 'Voucher Error',
          message: 'Failed to apply the voucher. Please check the voucher code and try again.',
        });
      }
    } else if (voucherApplied) {
      setAlert({
        visible: true,
        type: 'error',
        title: 'Voucher Error',
        message: 'This voucher has already been applied to this order.',
      });
    }
  };
  
  const handleLiveToggle = () => {
    const newIsLiveEnabled = !isLiveEnabled;
    setIsLiveEnabled(newIsLiveEnabled);

    if (newIsLiveEnabled) {
      setAccountType('LIVE');
      setAccountServer('');
      setAccountNumber('');
      setAccountPassword('');
    } else {
      setAccountType('');
    }
  };

  const handleDemoToggle = () => {
    const newIsDemoEnabled = !isDemoEnabled;
    setIsDemoEnabled(newIsDemoEnabled);

    if (newIsDemoEnabled) {
      setDemoAccountType('DEMO');
      setDemoAccountServer('');
      setDemoAccountNumber('');
      setDemoAccountPassword('');
    } else {
      setDemoAccountType('');
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (planType === 'Renew') {
      // For Renew plan type, only validate payment receipt and terms
      isValid = validate({
        planType,
        accountType: '', 
        accountServer: '',
        accountNumber: '',
        accountPassword: '',
        demoAccountType: '', 
        demoAccountServer: '',
        demoAccountNumber: '',
        demoAccountPassword: '',
        paymentMethod,
        paymentReceipts: receipts,
        termsAccepted,
      });
    } else {
      if (isLiveEnabled && isDemoEnabled) {
        // If both Live and Demo are enabled, require all fields for both accounts
        isValid = validate({
          planType,
          accountType,
          accountServer,
          accountNumber,
          accountPassword,
          demoAccountType,
          demoAccountServer,
          demoAccountNumber,
          demoAccountPassword,
          paymentMethod,
          paymentReceipts: receipts,
          termsAccepted,
        });
      } else if (isLiveEnabled && !isDemoEnabled) {
        // If only Live is enabled, only validate Live account fields
        isValid = validate({
          planType,
          accountType,
          accountServer,
          accountNumber,
          accountPassword,
          demoAccountType: '',
          demoAccountServer: '',
          demoAccountNumber: '',
          demoAccountPassword: '',
          paymentMethod,
          paymentReceipts: receipts,
          termsAccepted,
        });
      } else if (!isLiveEnabled && isDemoEnabled) {
        // If only Demo is enabled, only validate Demo account fields
        isValid = validate({
          planType,
          accountType: '', 
          accountServer: '',
          accountNumber: '',
          accountPassword: '',
          demoAccountType,
          demoAccountServer,
          demoAccountNumber,
          demoAccountPassword,
          paymentMethod,
          paymentReceipts: receipts,
          termsAccepted,
        });
      } else {
        // If both are disabled, at least one account type must be enabled
        setAlert({
          visible: true,
          type: 'error',
          title: 'Warning',
          message: 'Please enable and fill in at least one account type.',
        });
        isValid = false;
      }
    }

    return isValid;
  };  

  const handleSubscribeClick = async () => {
    if (!user) return;
  
    if (!validateForm()) {
      setAlert({
        visible: true,
        type: 'error',
        title: 'Warning',
        message: 'Please scroll up and review to ensure all required fields are filled in.',
      });
      return;
    }

    if (planType === 'trial') {
      const hasTrial = await checkUserTrialSubscription(user.email);
      if (hasTrial) {
        setAlert({
          visible: true,
          type: 'error',
          title: 'Warning',
          message: 'You have already subscribed to a trial plan. Please upgrade your trial to a subscribe account.'
        });
        return;
      }
    }
  
    if (planType === 'subscribe' && accountType === 'DEMO' && userHasDemo) {
      if (userHasDemo) {
        setAlert({
          visible: true,
          type: 'error',
          title: 'Warning',
          message: 'You have already subscribed to a demo account. Only 1 demo subscription account is allowed per user.'
        });
        return;
      }
    }
  
    if (planType === 'subscribe') {
      if (isLiveEnabled && isDemoEnabled) {
        if (!accountNumber || !accountPassword || !accountServer || !demoAccountNumber || !demoAccountPassword || !demoAccountServer) {
          setAlert({
            visible: true,
            type: 'error',
            title: 'Warning',
            message: 'Both Live and Demo account details are required.',
          });
          return;
        }
      } else if (isLiveEnabled && !isDemoEnabled) {
        if (!accountNumber || !accountPassword || !accountServer) {
          setAlert({
            visible: true,
            type: 'error',
            title: 'Warning',
            message: 'Live account details are required.',
          });
          return;
        }
      } else if (!isLiveEnabled && isDemoEnabled) {
        if (!demoAccountNumber || !demoAccountPassword || !demoAccountServer) {
          setAlert({
            visible: true,
            type: 'error',
            title: 'Warning',
            message: 'Demo account details are required.',
          });
          return;
        }
      }
    }

    setAlert({
      visible: true,
      type: 'confirm',
      message: 'Make sure you provided correct information before subscribing?'
    });
  };        
  
  const confirmSubscribe = async () => {
    if (!user) return;
  
    setIsSubmitting(true);
    setAlert((prev) => ({ ...prev, isLoading: true })); 

    const currentDate = moment().tz("Asia/Kuala_Lumpur").toDate();
    const formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
  
    let subscriptionData: SubscriptionData & { isLiveEnabled: boolean, isDemoEnabled: boolean } = {
      orderID,
      productID: plan.planID,
      planID: plan.id ? plan.id.toString() : '',
      planName: plan.planName,
      userName: user.username,
      email: user.email,
      referralEmail,
      planType,
      planAmount: discountedPlanAmount,
      planSubDate: formattedCurrentDate,
      planPeriod: planType === 'trial' ? null : plan.planPeriod,
      planDays: planType === 'trial' ? null : 0,
      planExpiredDate: planType === 'trial' ? "" : formattedCurrentDate,
      planTrialPeriod: planType === 'trial' ? planTrialDays?.toString() || "" : null,
      planTrialDays: planType === 'trial' ? planTrialDays : null,
      planExpiredTrialDate: planType === 'trial' ? formattedCurrentDate : "", 
      PaymentMethod: paymentMethod,
      PaymentReceipts: receipts.map(receipt => receipt.name).join(', '),
      notes,
      termAndCondition: termsAccepted ? 'Accepted' : 'Not Accepted',
      accountType, 
      accountServer,  
      accountNumber,  
      accountPassword,
      demoAccountType, 
      demoAccountServer,  
      demoAccountNumber,  
      demoAccountPassword, 
      voucherCode: voucherEnabled ? voucherCode : undefined,
      accountVoucherCode: voucherCode,
      isLiveEnabled,
      isDemoEnabled,
    };

    
    if (voucherEnabled && voucherCode) {
      // Fetch voucher details again to get the voucherPeriod
      const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10));
      if (voucher && voucher.voucherPeriod) {
        // Calculate remaining voucher days
        const voucherPeriodDate = moment(voucher.voucherPeriod);
        const remainingVoucherDays = voucherPeriodDate.diff(currentDate, 'days');
  
        // Update voucherDays in the subscription data
        subscriptionData = {
          ...subscriptionData,
          voucherDays: remainingVoucherDays.toString(),
        };
  
        // Call the API to update the voucherDays in the database
        await updateVoucherDaysInDatabase(voucherCode, remainingVoucherDays);
      }
    }
  
    if (isRenewal) {
      subscriptionData = {
        ...subscriptionData,
        planType: 'Renew',
        accountStatus: 'Pending',
        accountID: accountDetails ? accountDetails.accountID : '',
      };
    }
  
    try {
      if (isLiveEnabled && isDemoEnabled) {
        const liveSubscriptionData = {
          ...subscriptionData,
          accountType: 'LIVE',
          accountServer,
          accountNumber,
          accountPassword,
        };

        const demoSubscriptionData = {
          ...subscriptionData,
          accountType: 'DEMO',
          accountServer: demoAccountServer,
          accountNumber: demoAccountNumber,
          accountPassword: demoAccountPassword,
        };

        const liveOrderID = await submitSubscription(liveSubscriptionData, receipts);
        const demoOrderID = await submitSubscription(demoSubscriptionData, receipts);

        setOrderID(`${liveOrderID}, ${demoOrderID}`);
      } else if (isLiveEnabled && !isDemoEnabled) {
        const liveSubscriptionData = {
          ...subscriptionData,
          accountType: 'LIVE',
          accountServer,
          accountNumber,
          accountPassword,
        };

        const liveOrderID = await submitSubscription(liveSubscriptionData, receipts);
        setOrderID(liveOrderID);
        
      } else if (!isLiveEnabled && isDemoEnabled) {
        const demoSubscriptionData = {
          ...subscriptionData,
          accountType: 'DEMO',
          accountServer: demoAccountServer,
          accountNumber: demoAccountNumber,
          accountPassword: demoAccountPassword,
        };

        const demoOrderID = await submitSubscription(demoSubscriptionData, receipts);
        setOrderID(demoOrderID);
      }

      setAlert({
        visible: true,
        type: 'success',
        message: 'Subscription accepted. Await admin review and please wait for email confirmation.'
      });
    } catch (error) {
      setAlert({
        visible: true,
        type: 'error',
        message: 'Subscription failed. Please check your details or contact general@r1trader.com for technical support'
      });
    } finally {
      setIsSubmitting(false); 
    }
  };

  const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newReceipts = [...receipts];
    if (e.target.files && e.target.files[0]) {
      newReceipts[index] = e.target.files[0];
      setReceipts(newReceipts);
    }
  };  

  const formState = {
    planType,
    accountType,
    accountServer,
    accountNumber,
    accountPassword,
    demoAccountType,
    demoAccountServer,
    demoAccountNumber,
    demoAccountPassword,
    paymentMethod,
    paymentReceipts: receipts,
    termsAccepted,
  };
  
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const closeAlert = () => setAlert({ visible: false, type: 'check', message: '' });

  return (
    <div>
      <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
        <form>

          <div className="flex justify-center my-4 sm:my-0 xs:my-0">
            <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Basic Information</span>
          </div>

          {/* Description Field */}
          <div className="my-4">
            <p className="mb-6 font-semibold">{plan.planDescription}</p>
          </div>

          {/* Order ID Field */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
          </div>

          {/* Username Field */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
          </div>

          {/* Email Field */}
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
            <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
          </div>

          {/* Referral Email Field */}
          <div className="mb-4 flex items-center">
            <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
              Referral Email
            </label>
            <div className="flex justify-between items-center">
              <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
              <div className="text-xs font-thin ml-2 text-rose-500">
                ( Enter your referrer's email or leave as default. )
              </div>
              <input
                type="checkbox"
                className="ml-2"
                checked={isReferralEnabled}
                onChange={() => setIsReferralEnabled(!isReferralEnabled)}
                style={{ accentColor: theme.primaryColor }}
              />
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <input
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
              id="referral-email"
              type="email"
              value={referralEmail}
              onChange={(e) => setReferralEmail(e.target.value)}
              disabled={!isReferralEnabled}
              placeholder="Enter referral email"
            />
            <button
              type="button"
              className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
              onClick={handleApplyReferralEmail}
              disabled={applyReferralDisabled || referralApplied}
            >
              Apply
            </button>
          </div>

          <div className="py-4 sm:py-2 xs:py-2"></div>

          {/* Plan Name Field */}
          <div className="mb-4">
            <div className="flex justify-center mb-4">
              <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
            </div>
            <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
            <input 
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
              id="plan-name" 
              type="text" 
              value={plan.planName} disabled 
            />
          </div>

          {/* Plan Trial Period Field */}
          {planType === 'trial' ? (
            <>
              <div className="mb-4">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="plan-trial-period">
                    Plan Trial Period (days)
                </label>
                <input 
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
                  id="plan-trial-period" 
                  type="text" 
                  value={planTrialDays ? planTrialDays.toString() : ''} 
                  disabled 
                />
              </div>
            </>
          ) : (
            <div className="mb-4">
              {/* Plan Period (Days) Field */}
              <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
              <input 
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
                id="plan-period" 
                type="text" 
                value={planDays ? planDays.toString() : ''} 
                disabled 
              />
            </div>
          )}

          {/* Plan Type Field */}
          <div className="mb-4">
            <div className="flex justify-normal items-center">
              <label 
                className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
                htmlFor="plan-type">
                  Plan Type
              </label>
              <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
              <div className="text-xs text-rose-600 mb-2 ml-2">
                ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
              </div>
            </div>
            <select
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
              id="plan-type"
              value={planType}
              onChange={(e) => {
                setPlanType(e.target.value);
                const updatedFormState = {
                  ...formState,
                  planType: e.target.value,
                };
                if (errors.planType) validate(updatedFormState);
              }}
              disabled={isRenewal}
            >
              <option value="">Select a Plan Type</option>
              <option value="trial">Trial</option>
              <option value="subscribe">Subscribe</option>
              <option value="Renew">Renew</option>
            </select>
          </div>

          {/* Plan Amount Field */}
          {planType !== 'trial' && (
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
                <FaInfoCircle 
                  className="w-4 h-4 mb-2 ml-2"
                />
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  (Conversion based on today’s rate:<span className="font-bold text-sm">{`RM ${myrAmount}`}</span>)
                </div>
              </div>
              <input 
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
                id="plan-amount" 
                type="text" 
                value={`${discountedPlanAmount} ${plan.planCurrency}`}
                disabled 
              />
            </div>
          )}

          {/* Apply Voucher Field */}
          <div className="mb-4 flex items-center">
            <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
              Apply Voucher
            </label>
            <div className="flex justify-between items-center">
              <FaInfoCircle/>
              <div className="text-xs text-rose-600 ml-2">
                {voucherEnabled ? (
                  <span>
                    ( 
                      The voucher is valid until 
                        <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
                      left only 
                        <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
                      Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
                    )
                  </span>
                ) : (
                  <span>
                    ( Apply voucher for subscription benefit.)
                  </span>
                )}
              </div>
              <input
                type="checkbox"
                className="ml-2"
                checked={voucherEnabled}
                onChange={() => setVoucherEnabled(!voucherEnabled)}
                style={{ accentColor: theme.primaryColor }}
                disabled={planType === 'trial'}
              />
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <input
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
              id="voucher-code"
              type="text"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              disabled={!voucherEnabled}
              placeholder="Enter voucher code"
            />
            <button
              type="button"
              className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
              onClick={applyVoucher}
              disabled={applyDisabled}
            >
              Apply
            </button>
          </div>

          <div className="py-4 sm:py-2 xs:py-2"></div>
          
          {/* fill in Account Section */}
          {/* Account Type Field (for Live) */}
          <div className="p-4 border rounded-xl">
            <div className="text-rose-600 mb-4">
              Must Complete this section with your live and demo account information. Register details for both if applicable. Incomplete information may prevent AlgoMatrix EA from recognizing your accounts, affecting system usage. For questions, contact 
              <a href="https://t.me/SkyWoon" className="text-cyan-800 underline mx-2">Skywoon via Telegram</a> or email us at <a href="mailto:general@r1trader.com" className="text-cyan-800 underline">general@r1trader.com</a>.
            </div>
            <div className="flex items-center justify-center mb-4">
              <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Live Trading Account</span>
            </div>
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="account-type"
                >
                  Account Type
                </label>
                <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  ( Enable the checkbox to fill in the live trading account details. )
                </div>
                <input
                  type="checkbox"
                  className="ml-2 mb-2 accent-cyan-800"
                  checked={isLiveEnabled}
                  onChange={handleLiveToggle}
                />
              </div>
              <input
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountType ? 'border-rose-600' : ''}`}
                id="account-type"
                value="LIVE"
                readOnly
                onChange={(e) => {
                  setAccountType(e.target.value);
                  setAccountServer('');
                  setAccountNumber('');
                  setAccountPassword('');
                  const updatedFormState = {
                    ...formState,
                    accountType: e.target.value,
                  };
                  if (errors.accountType) validate(updatedFormState);
                }}
                disabled={!isLiveEnabled || isRenewal}
              >
              </input>
            </div>

            {/* Account Number Field */}
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold sm:w-[60px] xs:w-[60px]" 
                  htmlFor="account-number"
                >
                  Account Number
                </label>
                <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  ( This is your trading account number )
                </div>
              </div>
              <input
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountNumber ? 'border-rose-600' : ''}`}
                id="account-number"
                type="number"
                value={accountNumber}
                placeholder="Enter trading account number..."
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                  if (errors.accountNumber) validate({ ...formState, accountNumber: e.target.value });
                }}
                disabled={!isLiveEnabled || isRenewal}
              />
            </div>

            {/* Account Password Field */}
            <div className="mb-4 relative">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold xs:w-[120px]" 
                  htmlFor="account-password"
                >
                  Account Password (Read-Only)
                </label>
                <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  ( This is your trading account investor "read-only" password. )
                </div>
              </div>
              <div className="relative">
                <input
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 pr-10 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountPassword ? 'border-rose-600' : ''}`}
                  id="account-password"
                  type={showPassword ? 'text' : 'password'}
                  value={accountPassword}
                  placeholder="Enter trading account investor (read-only) password..."
                  onChange={(e) => {
                    setAccountPassword(e.target.value);
                    if (errors.accountPassword) validate({ ...formState, accountPassword: e.target.value });
                  }}
                  disabled={!isLiveEnabled || isRenewal}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            {/* Account Server Field */}
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="account-server"
                >
                  Account Server
                </label>
                <div className="relative group mx-2">
                  <div className="flex justify-between items-center">
                    <FaInfoCircle 
                      className="text-xl cursor-pointer w-4 h-4 mb-2 sm:w-3 xs:w-3"
                      style={styles.title}
                      aria-hidden="true"
                    />
                  </div>
                  {/* Tooltip */}
                  <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
                    style={styles.button}>
                    You can find your account server details in your trading platform under the account information section.
                    <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
                  </div>
                </div>
                <div className="text-xs text-rose-600 mb-2">
                  ( This is your trading account login server. Point over "<span className="font-bold">!</span>" for more instructions. )
                </div>
              </div>
              <input
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-800 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountServer ? 'border-rose-600' : ''}`}
                id="account-server"
                type="text"
                value={accountServer}
                placeholder="Enter trading account server..."
                onChange={(e) => {
                  setAccountServer(e.target.value);
                  if (errors.accountServer) validate({ ...formState, accountServer: e.target.value });
                }}
                disabled={!isLiveEnabled || isRenewal}
              />
            </div>

            <div className="py-4 sm:py-2 xs:py-2"></div>

            {/* Demo Account Type Field (for Demo) */}
            <div className="flex justify-center mb-4">
              <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
                Demo Trading Account
              </span>
            </div>
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="demo-account-type"
                >
                  Account Type
                </label>
                <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  ( Enable the checkbox to fill in the demo trading account details. )
                </div>
                <input
                  type="checkbox"
                  className="ml-2 mb-2 accent-cyan-800"
                  checked={isDemoEnabled}
                  onChange={handleDemoToggle}
                />
              </div>
              <input
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountType ? 'border-rose-600' : ''}`}
                id="demo-account-type"
                value="DEMO"
                onChange={(e) => {
                  setDemoAccountType(e.target.value);
                  setDemoAccountServer('');
                  setDemoAccountNumber('');
                  setDemoAccountPassword('');
                  const updatedFormState = {
                    ...formState,
                    demoAccountType: e.target.value,
                  };
                  if (errors.demoAccountType) validate(updatedFormState);
                }}
                disabled={!isDemoEnabled || isRenewal}
                readOnly
              >
              </input>
            </div>

            {/* Demo Account Number Field */}
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold sm:w-[60px] xs:w-[60px]" 
                  htmlFor="demo-account-number"
                >
                  Account Number
                </label>
                <FaInfoCircle className="mb-2 ml-2"/>
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  ( This is your trading account number )
                </div>
              </div>
              <input
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountNumber ? 'border-rose-600' : ''}`}
                id="demo-account-number"
                type="number"
                value={demoAccountNumber}
                placeholder="Enter trading account number..."
                onChange={(e) => {
                  setDemoAccountNumber(e.target.value);
                  if (errors.demoAccountNumber) validate({ ...formState, demoAccountNumber: e.target.value });
                }}
                disabled={!isDemoEnabled || isRenewal}
              />
            </div>

            {/* Demo Account Password Field */}
            <div className="mb-4 relative">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold xs:w-[120px]" 
                  htmlFor="demo-account-password"
                >
                  Account Password (Read-Only)
                </label>
                <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
                <div className="text-xs text-rose-600 mb-2 ml-2">
                  ( This is your trading account investor "read-only" password. )
                </div>
              </div>
              <div className="relative">
                <input
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 pr-10 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountPassword ? 'border-rose-600' : ''}`}
                  id="demo-account-password"
                  type={showPassword ? 'text' : 'password'}
                  value={demoAccountPassword}
                  placeholder="Enter trading account investor (read-only) password..."
                  onChange={(e) => {
                    setDemoAccountPassword(e.target.value);
                    if (errors.demoAccountPassword) validate({ ...formState, demoAccountPassword: e.target.value });
                  }}
                  disabled={!isDemoEnabled || isRenewal}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            {/* Demo Account Server Field */}
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label 
                  className="block mb-2 font-semibold" 
                  htmlFor="demo-account-server"
                >
                  Account Server
                </label>
                <div className="relative group mx-2">
                  <div className="flex justify-between items-center">
                    <FaInfoCircle 
                      className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3"
                      style={styles.title}
                      aria-hidden="true"
                    />
                  </div>
                  {/* Tooltip */}
                  <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
                    style={styles.button}>
                    You can find your account server details in your trading platform under the account information section.
                    <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
                  </div>
                </div>
                <div className="text-xs text-rose-600 mb-2">
                  ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
                </div>
              </div>
              <input
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountServer ? 'border-rose-600' : ''}`}
                id="demo-account-server"
                type="text"
                value={demoAccountServer}
                placeholder="Enter trading account server..."
                onChange={(e) => {
                  setDemoAccountServer(e.target.value);
                  if (errors.demoAccountServer) validate({ ...formState, demoAccountServer: e.target.value });
                }}
                disabled={!isDemoEnabled || isRenewal}
              />
            </div>
          </div>

          <div className="py-4 sm:py-2 xs:py-2"></div>

          <div className="flex justify-center mb-4">
            <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
          </div>
          {/* Payment Method Field */}
          {planType !== 'trial' && (
            <div className="mb-4">
              <div className="flex justify-normal items-center">
                <label className="block font-semibold xs:w-[60px]">Payment Method</label>
                <FaInfoCircle className="ml-2"/>
                <div className="text-xs text-rose-600 ml-2">
                  ( choose your payment method )
                </div>
              </div>
              <div className="flex items-center mb-2">
                <input
                  type="radio"
                  id="bank-transfer"
                  name="payment-method"
                  value="Bank Transfer"
                  checked={paymentMethod === 'Bank Transfer'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  style={{ accentColor: theme.primaryColor }}
                />
                <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
              </div>
              {paymentMethod === 'Bank Transfer' && (
                <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
                  <span>Bank Account</span><br />
                  <div className="flex justify-between items-center sm:text-xs xs:text-xs">
                    <div>
                      <li>Please proceed with the payment through USDT (cryptocurrency).</li>
                      {/* <li>EURANUX GROUP PLT</li>
                      <li>CIMB Bank</li>
                      <li>8604820432</li> */}
                    </div>
                    <FaClipboard 
                      className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
                      style={{ color: theme.primaryColor }} 
                      // onClick={() => navigator.clipboard.writeText("8604820432")} 
                    />
                  </div>
                </div>
              )}
              <div className="flex items-center">
                <input
                  type="radio"
                  id="usdt"
                  name="payment-method"
                  value="USDT"
                  checked={paymentMethod === 'USDT'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  style={{ accentColor: theme.primaryColor }}
                />
                <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
              </div>
              {paymentMethod === 'USDT' && (
                <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
                  <span>Wallet Address:</span><br />
                  <div className="flex justify-between items-cente sm:text-xs">
                    <div>
                      <li>Tron (TRC20) Network</li>
                      <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
                    </div>
                    <FaClipboard 
                      className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
                      style={{ color: theme.primaryColor }} 
                      onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Plan Trial Requirement Field */}
          {planType === 'trial' && (
            <div className="mb-4">
              <p className="font-semibold">Plan Trial Requirement:</p>
              <ul className="list-disc list-inside">
                {Array.isArray(planRequirement) && planRequirement.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Payment Receipts Field */}
          <div className="mb-4">
            <div className="flex justify-normal items-center">
              <label 
                className="block font-semibold sm:w-[60px] xs:w-[60px]" 
                htmlFor="payment-receipts"
              >
                {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
              </label>
              <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
              <div className="text-xs text-rose-600 ml-2">
                {planType === 'trial' 
                  ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
                  : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
              </div>
            </div>
            <input
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
              type="file"
              name="PaymentReceipt"
              onChange={(e) => {
                handleReceiptUpload(e, 0);
                if (errors.paymentReceipts) {
                  const filesArray = [...receipts];
                  if (e.target.files && e.target.files[0]) {
                    filesArray.push(e.target.files[0]);
                  }
                  validate({ ...formState, paymentReceipts: filesArray });
                }
              }}
            />
          </div>

          {/* Notes Field */}
          <div className="mb-6">
            <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
            <textarea 
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
              id="notes" 
              placeholder="Leave any notes here (optional)..." 
              value={notes} 
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          {/* Terms and Conditions Field */}
          <div className="flex justify-normal items-center mb-4">
            <label>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
              />
            </label>
            <span 
                className="cursor-pointer text-cyan-800 underline" 
                onClick={openModal}
              >
                I have read and understand the terms and conditions
            </span>
          </div>

          {/* Submit Form Button */}
          <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
            <button 
              onClick={onBack} 
              className="px-4 py-2 rounded-md flex items-center justify-center"
              style={styles.button}>
              <FaArrowCircleLeft />
              <div className="ml-2">Back to Details</div>
            </button>
            <button 
              type="button" 
              className="px-4 py-2 rounded-md flex items-center justify-center"
              onClick={handleSubscribeClick}
              style={{ 
                backgroundColor: termsAccepted ? theme.primaryColor : 'gray', 
                color: theme.text2Color, 
                fontFamily: theme.fontFamily 
              }}
              disabled={!termsAccepted || loading || isSubmitting}
            >
              <BiSolidPurchaseTag />
              <div className="ml-2">Subscribe</div>
            </button>
          </div>
        </form>
      </div>
      
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto lg:w-[800px] lg:h-[800px] sm:max-w-screen sm:max-h-screen sm:h-[800px] xs:max-w-screen xs:max-h-screen xs:h-[600px]">
            <span
              className="border-2 p-10 overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              {termsAndConditions}
            </span>
            <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
              close
            </button>
          </div>
        </div>
      )}

      <MyTicketAlert 
        type={alert.type} 
        message={alert.message} 
        visible={alert.visible} 
        onClose={closeAlert} 
        onConfirm={confirmSubscribe} 
        onCancel={closeAlert}
        isLoading={alert.isLoading} 
      />

      <MyTicketAlert 
        type={referralAlert.type} 
        message={referralAlert.message} 
        visible={referralAlert.visible} 
        onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
        onConfirm={confirmReferralApply}
        onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
      />
    </div>
  );
};

export default MySubscriptionRegistration;

// // udpate code --> 27 Aug 2024
// // src/components/client/MiniApp/App03_MySubscriptionRegistration.tsx

// // react component
// import React, { useState, useEffect } from 'react';
// import moment from 'moment-timezone';
// // react icon
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaClipboard, FaInfoCircle, FaEyeSlash, FaEye } from 'react-icons/fa';
// // context file
// import { useTheme } from '../../../context/ThemeContext';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// // custom hook file
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useSubscriptionSubmit, { SubscriptionData } from '../../../hooks/Subscription/useSubscriptionSubmit';
// import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
// import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';
// // custom components
// import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png'

// // Add AlertType type definition here
// type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';
// interface RegistrationProps {
//   plan: Plan;
//   onBack: () => void;
//   accountDetails: {
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null;
// }
// const MySubscriptionRegistration: React.FC<RegistrationProps> = ({ plan, onBack, accountDetails }) => {
//   // fetch data
//   const { theme } = useTheme();
//   const { user } = useUserDashboard();
//   const { fetchTrialInfo } = useSubscriptionData();
//   const { 
//     submitSubscription, 
//     fetchVoucherDetails, 
//     checkUserTrialSubscription, 
//     checkDemoSubscription, 
//     updateVoucherDaysInDatabase,
//     fetchAndUpdateUserAlgoMarixData,
//     checkUserVoucherEntitlement, 
//     loading
//   } = useSubscriptionSubmit();
//   const { validate, errors } = useFormValidation();
//   const termsAndConditions = useEventRegTerms();
//   // form field
//   const [orderID, setOrderID] = useState('');
//   const [planType, setPlanType] = useState('');
//   const [planDays, setPlanDays] = useState<number | null>(null);
//   const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
//   const [planRequirement, setPlanRequirement] = useState<string[]>([]);
//   const [showPassword, setShowPassword] = useState(false);
//   // apply referral 
//   const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
//   const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
//   const [referralEmail, setReferralEmail] = useState('caspertan90@gmail.com');
//   const [referralApplied, setReferralApplied] = useState(false);
//   // fill in trading account detail(live)
//   const [isLiveEnabled, setIsLiveEnabled] = useState(false);
//   const [accountType, setAccountType] = useState('');
//   const [accountServer, setAccountServer] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   // fill in trading account detail(demo)
//   const [isDemoEnabled, setIsDemoEnabled] = useState(false);
//   const [demoAccountType, setDemoAccountType] = useState('');
//   const [demoAccountServer, setDemoAccountServer] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   // other payment field
//   const [notes, setNotes] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   // renew 
//   const [isRenewal, setIsRenewal] = useState(false);
//   // apply voucher code
//   const [applyDisabled, setApplyDisabled] = useState(true);
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
//   const [voucherDays, setVoucherDays] = useState<number | null>(null);
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
//   const [voucherApplied, setVoucherApplied] = useState(false);
//   const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // set for alert message
//   const [userHasDemo, setUserHasDemo] = useState(false);
//   const [alert, setAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string, isLoading?: boolean }>({ visible: false, type: 'check', message: '' });
//   const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // search for trial details
//   useEffect(() => {
//     const fetchTrialDetails = async () => {
//       if (planType === 'trial' && user?.email) {
//         const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

//         if (trialDays) {
//           setPlanTrialDays(trialDays);
//         } else {
//           setPlanTrialDays(null);
//         }
  
//         if (trialRequirement) {
//           setPlanRequirement(trialRequirement.split(',').map((req: string) => req.trim()));
//         } else {
//           setPlanRequirement([]);
//         }
//       } else {
//         setPlanDays(parseInt(plan.planPeriod, 10));
//         setPlanTrialDays(null);
//       }
//     };
//     fetchTrialDetails();
//   }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);   
  
//   // Plan Amount Calculation
//   useEffect(() => {
//     let calculatedPlanAmount = parseFloat(plan.planAmount);

//     if (plan.planPeriod === '365') {
//       calculatedPlanAmount = calculatedPlanAmount * 12;
//     }

//     setDiscountedPlanAmount(calculatedPlanAmount.toFixed(2));
//   }, [plan.planAmount, plan.planPeriod]);

//   // check for availble demo subscription
//   useEffect(() => {
//     const checkDemoSubscriptionStatus = async () => {
//       if (user?.email) {
//         const hasDemoSubscription = await checkDemoSubscription(user.email);
//         setUserHasDemo(hasDemoSubscription);
//       }
//     };
//     checkDemoSubscriptionStatus();
//   }, [user?.email, checkDemoSubscription]); 
  
//   // generated OrderID
//   useEffect(() => {
//     const generatedOrderID = 'sub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//     setOrderID(generatedOrderID);
//   }, []);
  
//   // toggle voucher enable or disable
//   useEffect(() => {
//     setApplyDisabled(!voucherEnabled);
//   }, [voucherEnabled]);
  
//   // disable voucher for trial
//   useEffect(() => {
//     if (planType === 'trial') {
//       setVoucherEnabled(false);
//     }
//   }, [planType]);  
  
//   // pass account data from renewal user
//   useEffect(() => {
//     if (accountDetails) {
//       if (accountDetails.accountType === 'LIVE') {
//         setAccountType(accountDetails.accountType);
//         setAccountServer(accountDetails.accountServer);
//         setAccountNumber(accountDetails.accountNumber);
//         setAccountPassword(accountDetails.accountPassword);
//         setIsLiveEnabled(true);
//       } else if (accountDetails.accountType === 'DEMO') {
//         setDemoAccountType(accountDetails.accountType);
//         setDemoAccountServer(accountDetails.accountServer);
//         setDemoAccountNumber(accountDetails.accountNumber);
//         setDemoAccountPassword(accountDetails.accountPassword);
//         setIsDemoEnabled(true);
//       }
//       setPlanType('Renew');
//       setIsRenewal(true); 
//     }
//   }, [accountDetails]); 
  
//   // toggle enabled or disable referral 
//   useEffect(() => {
//     if (isReferralEnabled) {
//         setApplyReferralDisabled(false);
//     } else {
//         setApplyReferralDisabled(true);
//     }
//   }, [isReferralEnabled]);

//   // Update the voucherEnabled useEffect
//   useEffect(() => {
//     const updateVoucherDetails = async () => {
//       if (voucherEnabled && voucherCode) {
//         try {

//           // Fetch voucher details
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
//           if (voucher) {
//             const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
//             const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
//             const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

//             // Update state with fetched data
//             setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
//             setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
//             setVoucherRedeemCount(voucher.voucherRedeemCount);

//             // Update the voucher days in the database
//             await updateVoucherDaysInDatabase(voucher.id, remainingDays);

//           } else {
//             setVoucherPeriod(null);
//             setVoucherDays(null);
//           }
//         } catch (error) {
//           console.error('Error fetching voucher details:', error);
//           setVoucherRedeemCount(0);
//         }
//       } else {
//         setVoucherPeriod(null);
//         setVoucherDays(null);
//       }
//     };

//     updateVoucherDetails();
//   }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

//   const handleApplyReferralEmail = () => {
//     setReferralAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Do you want to apply this referral email?',
//     });
//     // Disable the referral email field after applying
//     setIsReferralEnabled(false);
//   };
  
//   const confirmReferralApply = () => {
//     try {
//       setReferralApplied(true); // Set success status
//       setReferralAlert({
//         visible: true,
//         type: 'success',
//         title: 'Referral Applied',
//         message: 'Referral email applied successfully!',
//       });
//     } catch (error) {
//       setReferralApplied(false); // Set failure status
//       setReferralAlert({
//         visible: true,
//         type: 'error',
//         title: 'Referral Error',
//         message: 'Failed to apply the referral. Please try again.',
//       });
//     }
//   };

//   const applyVoucher = async () => {
//     if (voucherCode && !voucherApplied) {
//       try {
//           // Fetch voucher details without applying it yet to get the remaining days
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
//           if (!voucher) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return;
//           }

//           // Check if the user is entitled to redeem this voucher
//           const userEntitlement = await checkUserVoucherEntitlement(
//             voucher.voucherCode, 
//             voucher.voucherMemberType, 
//             voucher.voucherGroupMember
//           );

//           if (!userEntitlement) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'You are not entitled to redeem this voucher code.',
//             });
//             return;
//           }

//           // Check if the voucher is expired
//           const currentDate = moment().tz("Asia/Kuala_Lumpur");
//           const voucherPeriodDate = moment(voucher.voucherPeriod);
//           const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

//           if (remainingDays < 0) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'The voucher has expired and cannot be applied.',
//             });
//             return;
//           }

//           // Check if the voucher has already been fully redeemed
//           if (voucher.voucherRedeemCount === '0') {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return; 
//           }

//           if (voucher && user) {
//             // Fetch and update the user's AlgoMarix data in a single API call
//             const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
//             if (result) {
//               const { accountVoucherCheck } = result;
      
//               // Apply check only if the user exists and accountVoucherCheck is "0"
//               if (accountVoucherCheck === '0' && planType !== 'subscribe') {
//                 setAlert({
//                   visible: true,
//                   type: 'error',
//                   title: 'Voucher Error',
//                   message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
//                 });
//                 return;
//               }
            
//               // Handle voucher redeem check logic
//               if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: 'You have already redeemed this voucher code and cannot redeem it again.',
//                 });
//                 return;
//               }

//               // Handle specific redeem count 
//               if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
//                 });
//                 return;
//               }

//               let newAmount = parseFloat(plan.planAmount);

//               // Adjust for annual plan period
//               if (plan.planPeriod === '365') {
//                 newAmount = newAmount * 12;
//               }
    
//               if (voucher.voucherType === 'By Amount') {
//                 newAmount -= parseFloat(voucher.voucherAmount);
//               } else if (voucher.voucherType === 'By Percentage') {
//                 newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
//               }
    
//               // Update voucherDays in the subscription data
//               setVoucherDays(remainingDays);

//               // Now that the voucher is successfully applied, decrement the redeem count
//               await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
//               await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

//               setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
//               setVoucherRedeemCount(voucher.voucherRedeemCount);
//               setVoucherApplied(true); 
//               setAlert({
//                 visible: true,
//                 type: 'success',
//                 title: 'Voucher Applied',
//                 message: 'Voucher applied successfully!',
//               });
//           } else {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Failed to retrieve user data. Please try again.',
//             });
//           }
//         }
//       } catch (error) {
//         setDiscountedPlanAmount(plan.planAmount);
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Voucher Error',
//           message: 'Failed to apply the voucher. Please check the voucher code and try again.',
//         });
//       }
//     } else if (voucherApplied) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Voucher Error',
//         message: 'This voucher has already been applied to this order.',
//       });
//     }
//   };
  
//   const handleLiveToggle = () => {
//     const newIsLiveEnabled = !isLiveEnabled;
//     setIsLiveEnabled(newIsLiveEnabled);

//     if (newIsLiveEnabled) {
//       setAccountType('LIVE');
//       setAccountServer('');
//       setAccountNumber('');
//       setAccountPassword('');
//     } else {
//       setAccountType('');
//     }
//   };

//   const handleDemoToggle = () => {
//     const newIsDemoEnabled = !isDemoEnabled;
//     setIsDemoEnabled(newIsDemoEnabled);

//     if (newIsDemoEnabled) {
//       setDemoAccountType('DEMO');
//       setDemoAccountServer('');
//       setDemoAccountNumber('');
//       setDemoAccountPassword('');
//     } else {
//       setDemoAccountType('');
//     }
//   };

//   const validateForm = () => {
//     let isValid = true;

//     if (planType === 'Renew') {
//       // For Renew plan type, only validate payment receipt and terms
//       isValid = validate({
//         planType,
//         accountType: '', 
//         accountServer: '',
//         accountNumber: '',
//         accountPassword: '',
//         demoAccountType: '', 
//         demoAccountServer: '',
//         demoAccountNumber: '',
//         demoAccountPassword: '',
//         paymentMethod,
//         paymentReceipts: receipts,
//         termsAccepted,
//       });
//     } else {
//       if (isLiveEnabled && isDemoEnabled) {
//         // If both Live and Demo are enabled, require all fields for both accounts
//         isValid = validate({
//           planType,
//           accountType,
//           accountServer,
//           accountNumber,
//           accountPassword,
//           demoAccountType,
//           demoAccountServer,
//           demoAccountNumber,
//           demoAccountPassword,
//           paymentMethod,
//           paymentReceipts: receipts,
//           termsAccepted,
//         });
//       } else if (isLiveEnabled && !isDemoEnabled) {
//         // If only Live is enabled, only validate Live account fields
//         isValid = validate({
//           planType,
//           accountType,
//           accountServer,
//           accountNumber,
//           accountPassword,
//           demoAccountType: '',
//           demoAccountServer: '',
//           demoAccountNumber: '',
//           demoAccountPassword: '',
//           paymentMethod,
//           paymentReceipts: receipts,
//           termsAccepted,
//         });
//       } else if (!isLiveEnabled && isDemoEnabled) {
//         // If only Demo is enabled, only validate Demo account fields
//         isValid = validate({
//           planType,
//           accountType: '', 
//           accountServer: '',
//           accountNumber: '',
//           accountPassword: '',
//           demoAccountType,
//           demoAccountServer,
//           demoAccountNumber,
//           demoAccountPassword,
//           paymentMethod,
//           paymentReceipts: receipts,
//           termsAccepted,
//         });
//       } else {
//         // If both are disabled, at least one account type must be enabled
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Warning',
//           message: 'Please enable and fill in at least one account type.',
//         });
//         isValid = false;
//       }
//     }

//     return isValid;
//   };  

//   const handleSubscribeClick = async () => {
//     if (!user) return;
  
//     if (!validateForm()) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Warning',
//         message: 'Please scroll up and review to ensure all required fields are filled in.',
//       });
//       return;
//     }

//     if (planType === 'trial') {
//       const hasTrial = await checkUserTrialSubscription(user.email);
//       if (hasTrial) {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Warning',
//           message: 'You have already subscribed to a trial plan. Please upgrade your trial to a subscribe account.'
//         });
//         return;
//       }
//     }
  
//     if (planType === 'subscribe' && accountType === 'DEMO' && userHasDemo) {
//       if (userHasDemo) {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Warning',
//           message: 'You have already subscribed to a demo account. Only 1 demo subscription account is allowed per user.'
//         });
//         return;
//       }
//     }
  
//     if (planType === 'subscribe') {
//       if (isLiveEnabled && isDemoEnabled) {
//         if (!accountNumber || !accountPassword || !accountServer || !demoAccountNumber || !demoAccountPassword || !demoAccountServer) {
//           setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Both Live and Demo account details are required.',
//           });
//           return;
//         }
//       } else if (isLiveEnabled && !isDemoEnabled) {
//         if (!accountNumber || !accountPassword || !accountServer) {
//           setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Live account details are required.',
//           });
//           return;
//         }
//       } else if (!isLiveEnabled && isDemoEnabled) {
//         if (!demoAccountNumber || !demoAccountPassword || !demoAccountServer) {
//           setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Demo account details are required.',
//           });
//           return;
//         }
//       }
//     }

//     setAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Make sure you provided correct information before subscribing?'
//     });
//   };        
  
//   const confirmSubscribe = async () => {
//     if (!user) return;
  
//     setIsSubmitting(true);
//     setAlert((prev) => ({ ...prev, isLoading: true })); 

//     const currentDate = moment().tz("Asia/Kuala_Lumpur").toDate();
//     const formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
  
//     let subscriptionData: SubscriptionData & { isLiveEnabled: boolean, isDemoEnabled: boolean } = {
//       orderID,
//       productID: plan.planID,
//       planID: plan.id ? plan.id.toString() : '',
//       planName: plan.planName,
//       userName: user.username,
//       email: user.email,
//       referralEmail,
//       planType,
//       planAmount: discountedPlanAmount,
//       planSubDate: formattedCurrentDate,
//       planPeriod: planType === 'trial' ? null : plan.planPeriod,
//       planDays: planType === 'trial' ? null : 0,
//       planExpiredDate: planType === 'trial' ? "" : formattedCurrentDate,
//       planTrialPeriod: planType === 'trial' ? planTrialDays?.toString() || "" : null,
//       planTrialDays: planType === 'trial' ? planTrialDays : null,
//       planExpiredTrialDate: planType === 'trial' ? formattedCurrentDate : "", 
//       PaymentMethod: paymentMethod,
//       PaymentReceipts: receipts.map(receipt => receipt.name).join(', '),
//       notes,
//       termAndCondition: termsAccepted ? 'Accepted' : 'Not Accepted',
//       accountType, 
//       accountServer,  
//       accountNumber,  
//       accountPassword,
//       demoAccountType, 
//       demoAccountServer,  
//       demoAccountNumber,  
//       demoAccountPassword, 
//       voucherCode: voucherEnabled ? voucherCode : undefined,
//       accountVoucherCode: voucherCode,
//       isLiveEnabled,
//       isDemoEnabled,
//     };

    
//     if (voucherEnabled && voucherCode) {
//       // Fetch voucher details again to get the voucherPeriod
//       const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10));
//       if (voucher && voucher.voucherPeriod) {
//         // Calculate remaining voucher days
//         const voucherPeriodDate = moment(voucher.voucherPeriod);
//         const remainingVoucherDays = voucherPeriodDate.diff(currentDate, 'days');
  
//         // Update voucherDays in the subscription data
//         subscriptionData = {
//           ...subscriptionData,
//           voucherDays: remainingVoucherDays.toString(),
//         };
  
//         // Call the API to update the voucherDays in the database
//         await updateVoucherDaysInDatabase(voucherCode, remainingVoucherDays);
//       }
//     }
  
//     if (isRenewal) {
//       subscriptionData = {
//         ...subscriptionData,
//         planType: 'Renew',
//         accountStatus: 'Pending',
//         accountID: accountDetails ? accountDetails.accountID : '',
//       };
//     }
  
//     try {
//       if (isLiveEnabled && isDemoEnabled) {
//         const liveSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'LIVE',
//           accountServer,
//           accountNumber,
//           accountPassword,
//         };

//         const demoSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'DEMO',
//           accountServer: demoAccountServer,
//           accountNumber: demoAccountNumber,
//           accountPassword: demoAccountPassword,
//         };

//         const liveOrderID = await submitSubscription(liveSubscriptionData, receipts);
//         const demoOrderID = await submitSubscription(demoSubscriptionData, receipts);

//         setOrderID(`${liveOrderID}, ${demoOrderID}`);
//       } else if (isLiveEnabled && !isDemoEnabled) {
//         const liveSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'LIVE',
//           accountServer,
//           accountNumber,
//           accountPassword,
//         };

//         const liveOrderID = await submitSubscription(liveSubscriptionData, receipts);
//         setOrderID(liveOrderID);
        
//       } else if (!isLiveEnabled && isDemoEnabled) {
//         const demoSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'DEMO',
//           accountServer: demoAccountServer,
//           accountNumber: demoAccountNumber,
//           accountPassword: demoAccountPassword,
//         };

//         const demoOrderID = await submitSubscription(demoSubscriptionData, receipts);
//         setOrderID(demoOrderID);
//       }

//       setAlert({
//         visible: true,
//         type: 'success',
//         message: 'Subscription accepted. Await admin review and please wait for email confirmation.'
//       });
//     } catch (error) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         message: 'Subscription failed. Please check your details or contact general@r1trader.com for technical support'
//       });
//     } finally {
//       setIsSubmitting(false); 
//     }
//   };

//   const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//     const newReceipts = [...receipts];
//     if (e.target.files && e.target.files[0]) {
//       newReceipts[index] = e.target.files[0];
//       setReceipts(newReceipts);
//     }
//   };  

//   const formState = {
//     planType,
//     accountType,
//     accountServer,
//     accountNumber,
//     accountPassword,
//     demoAccountType,
//     demoAccountServer,
//     demoAccountNumber,
//     demoAccountPassword,
//     paymentMethod,
//     paymentReceipts: receipts,
//     termsAccepted,
//   };
  
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   const closeAlert = () => setAlert({ visible: false, type: 'check', message: '' });

//   return (
//     <div>
//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <form>

//           <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Basic Information</span>
//           </div>

//           {/* Description Field */}
//           <div className="my-4">
//             <p className="mb-6 font-semibold">{plan.planDescription}</p>
//           </div>

//           {/* Order ID Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
//           </div>

//           {/* Username Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
//           </div>

//           {/* Email Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
//           </div>

//           {/* Referral Email Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
//               Referral Email
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//               <div className="text-xs font-thin ml-2 text-rose-500">
//                 ( Enter your referrer's email or leave as default. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2"
//                 checked={isReferralEnabled}
//                 onChange={() => setIsReferralEnabled(!isReferralEnabled)}
//                 style={{ accentColor: theme.primaryColor }}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="referral-email"
//               type="email"
//               value={referralEmail}
//               onChange={(e) => setReferralEmail(e.target.value)}
//               disabled={!isReferralEnabled}
//               placeholder="Enter referral email"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={handleApplyReferralEmail}
//               disabled={applyReferralDisabled || referralApplied}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Plan Name Field */}
//           <div className="mb-4">
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
//             </div>
//             <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="plan-name" 
//               type="text" 
//               value={plan.planName} disabled 
//             />
//           </div>

//           {/* Plan Trial Period Field */}
//           {planType === 'trial' ? (
//             <>
//               <div className="mb-4">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="plan-trial-period">
//                     Plan Trial Period (days)
//                 </label>
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                   id="plan-trial-period" 
//                   type="text" 
//                   value={planTrialDays ? planTrialDays.toString() : ''} 
//                   disabled 
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="mb-4">
//               {/* Plan Period (Days) Field */}
//               <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-period" 
//                 type="text" 
//                 value={planDays ? planDays.toString() : ''} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Plan Type Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center ">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
//                 htmlFor="plan-type">
//                   Plan Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
//               </div>
//             </div>
//             <select
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
//               id="plan-type"
//               value={planType}
//               onChange={(e) => {
//                 setPlanType(e.target.value);
//                 const updatedFormState = {
//                   ...formState,
//                   planType: e.target.value,
//                 };
//                 if (errors.planType) validate(updatedFormState);
//               }}
//               disabled={isRenewal}
//             >
//               <option value="">Select a Plan Type</option>
//               <option value="trial">Trial</option>
//               <option value="subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           {/* Plan Amount Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-amount" 
//                 type="text" 
//                 value={`${discountedPlanAmount} ${plan.planCurrency}`} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Apply Voucher Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
//               Apply Voucher
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {voucherEnabled ? (
//                   <span>
//                     ( 
//                       The voucher is valid until 
//                         <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
//                       left only 
//                         <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
//                       Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
//                     )
//                   </span>
//                 ) : (
//                   <span>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                 )}
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2"
//                 checked={voucherEnabled}
//                 onChange={() => setVoucherEnabled(!voucherEnabled)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={planType === 'trial'}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="voucher-code"
//               type="text"
//               value={voucherCode}
//               onChange={(e) => setVoucherCode(e.target.value)}
//               disabled={!voucherEnabled}
//               placeholder="Enter voucher code"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={applyVoucher}
//               disabled={applyDisabled}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>
          
//           {/* fill in Account Section */}
//           {/* Account Type Field (for Live) */}
//           <div className="flex items-center justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Live Trading Account</span>
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="account-type"
//               >
//                 Account Type
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( Enable the checkbox to fill in the live trading account details. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 mb-2 accent-cyan-800"
//                 checked={isLiveEnabled}
//                 onChange={handleLiveToggle}
//               />
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountType ? 'border-rose-600' : ''}`}
//               id="account-type"
//               value="LIVE"
//               readOnly
//               onChange={(e) => {
//                 setAccountType(e.target.value);
//                 setAccountServer('');
//                 setAccountNumber('');
//                 setAccountPassword('');
//                 const updatedFormState = {
//                   ...formState,
//                   accountType: e.target.value,
//                 };
//                 if (errors.accountType) validate(updatedFormState);
//               }}
//               disabled={!isLiveEnabled || isRenewal}
//             >
//             </input>
//           </div>

//           {/* Account Number Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="account-number"
//               >
//                 Account Number
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account number )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountNumber ? 'border-rose-600' : ''}`}
//               id="account-number"
//               type="number"
//               value={accountNumber}
//               placeholder="Enter trading account number..."
//               onChange={(e) => {
//                 setAccountNumber(e.target.value);
//                 if (errors.accountNumber) validate({ ...formState, accountNumber: e.target.value });
//               }}
//               disabled={!isLiveEnabled || isRenewal}
//             />
//           </div>

//           {/* Account Password Field */}
//           <div className="mb-4 relative">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold xs:w-[120px]" 
//                 htmlFor="account-password"
//               >
//                 Account Password (Read-Only)
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account investor "read-only" password. )
//               </div>
//             </div>
//             <div className="relative">
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 pr-10 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountPassword ? 'border-rose-600' : ''}`}
//                 id="account-password"
//                 type={showPassword ? 'text' : 'password'}
//                 value={accountPassword}
//                 placeholder="Enter trading account investor (read-only) password..."
//                 onChange={(e) => {
//                   setAccountPassword(e.target.value);
//                   if (errors.accountPassword) validate({ ...formState, accountPassword: e.target.value });
//                 }}
//                 disabled={!isLiveEnabled || isRenewal}
//               />
//               <button
//                 type="button"
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
//                 onClick={() => setShowPassword(!showPassword)}
//               >
//                 {showPassword ? <FaEyeSlash /> : <FaEye />}
//               </button>
//             </div>
//           </div>

//           {/* Account Server Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="account-server"
//               >
//                 Account Server
//               </label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mb-2 sm:w-3 xs:w-3"
//                     style={styles.title}
//                     aria-hidden="true"
//                   />
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                 </div>
//               </div>
//               <div className="text-xs text-rose-600 mb-2">
//                 ( This is your trading account login server. Point over "<span className="font-bold">!</span>" for more instructions. )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-800 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountServer ? 'border-rose-600' : ''}`}
//               id="account-server"
//               type="text"
//               value={accountServer}
//               placeholder="Enter trading account server..."
//               onChange={(e) => {
//                 setAccountServer(e.target.value);
//                 if (errors.accountServer) validate({ ...formState, accountServer: e.target.value });
//               }}
//               disabled={!isLiveEnabled || isRenewal}
//             />
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Demo Account Type Field (for Demo) */}
//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//               Demo Trading Account
//             </span>
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="demo-account-type"
//               >
//                 Account Type
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( Enable the checkbox to fill in the demo trading account details. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 mb-2 accent-cyan-800"
//                 checked={isDemoEnabled}
//                 onChange={handleDemoToggle}
//               />
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountType ? 'border-rose-600' : ''}`}
//               id="demo-account-type"
//               value="DEMO"
//               onChange={(e) => {
//                 setDemoAccountType(e.target.value);
//                 setDemoAccountServer('');
//                 setDemoAccountNumber('');
//                 setDemoAccountPassword('');
//                 const updatedFormState = {
//                   ...formState,
//                   demoAccountType: e.target.value,
//                 };
//                 if (errors.demoAccountType) validate(updatedFormState);
//               }}
//               disabled={!isDemoEnabled || isRenewal}
//               readOnly
//             >
//             </input>
//           </div>

//           {/* Demo Account Number Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="demo-account-number"
//               >
//                 Account Number
//               </label>
//               <FaInfoCircle className="mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account number )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountNumber ? 'border-rose-600' : ''}`}
//               id="demo-account-number"
//               type="number"
//               value={demoAccountNumber}
//               placeholder="Enter trading account number..."
//               onChange={(e) => {
//                 setDemoAccountNumber(e.target.value);
//                 if (errors.demoAccountNumber) validate({ ...formState, demoAccountNumber: e.target.value });
//               }}
//               disabled={!isDemoEnabled || isRenewal}
//             />
//           </div>

//           {/* Demo Account Password Field */}
//           <div className="mb-4 relative">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold xs:w-[120px]" 
//                 htmlFor="demo-account-password"
//               >
//                 Account Password (Read-Only)
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account investor "read-only" password. )
//               </div>
//             </div>
//             <div className="relative">
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 pr-10 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountPassword ? 'border-rose-600' : ''}`}
//                 id="demo-account-password"
//                 type={showPassword ? 'text' : 'password'}
//                 value={demoAccountPassword}
//                 placeholder="Enter trading account investor (read-only) password..."
//                 onChange={(e) => {
//                   setDemoAccountPassword(e.target.value);
//                   if (errors.demoAccountPassword) validate({ ...formState, demoAccountPassword: e.target.value });
//                 }}
//                 disabled={!isDemoEnabled || isRenewal}
//               />
//               <button
//                 type="button"
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
//                 onClick={() => setShowPassword(!showPassword)}
//               >
//                 {showPassword ? <FaEyeSlash /> : <FaEye />}
//               </button>
//             </div>
//           </div>

//           {/* Demo Account Server Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="demo-account-server"
//               >
//                 Account Server
//               </label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3"
//                     style={styles.title}
//                     aria-hidden="true"
//                   />
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                 </div>
//               </div>
//               <div className="text-xs text-rose-600 mb-2">
//                 ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountServer ? 'border-rose-600' : ''}`}
//               id="demo-account-server"
//               type="text"
//               value={demoAccountServer}
//               placeholder="Enter trading account server..."
//               onChange={(e) => {
//                 setDemoAccountServer(e.target.value);
//                 if (errors.demoAccountServer) validate({ ...formState, demoAccountServer: e.target.value });
//               }}
//               disabled={!isDemoEnabled || isRenewal}
//             />
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//           </div>
//           {/* Payment Method Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block font-semibold xs:w-[60px]">Payment Method</label>
//                 <FaInfoCircle className="ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   ( choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mb-2">
//                 <input
//                   type="radio"
//                   id="bank-transfer"
//                   name="payment-method"
//                   value="Bank Transfer"
//                   checked={paymentMethod === 'Bank Transfer'}
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center sm:text-xs xs:text-xs">
//                     <div>
//                       <li>FINOX TRADE MARKET SDN. BHD.</li>
//                       <li>Hong leong bank</li>
//                       <li>23600590742</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       style={{ color: theme.primaryColor }} 
//                       onClick={() => navigator.clipboard.writeText("23600590742")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="usdt"
//                   name="payment-method"
//                   value="USDT"
//                   checked={paymentMethod === 'USDT'}
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-cente sm:text-xs">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       style={{ color: theme.primaryColor }} 
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Plan Trial Requirement Field */}
//           {planType === 'trial' && (
//             <div className="mb-4">
//               <p className="font-semibold">Plan Trial Requirement:</p>
//               <ul className="list-disc list-inside">
//                 {Array.isArray(planRequirement) && planRequirement.map((requirement, index) => (
//                   <li key={index}>{requirement}</li>
//                 ))}
//               </ul>
//             </div>
//           )}

//           {/* Payment Receipts Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="payment-receipts"
//               >
//                 {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
//               </label>
//               <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {planType === 'trial' 
//                   ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
//                   : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
//               type="file"
//               name="PaymentReceipt"
//               onChange={(e) => {
//                 handleReceiptUpload(e, 0);
//                 if (errors.paymentReceipts) {
//                   const filesArray = [...receipts];
//                   if (e.target.files && e.target.files[0]) {
//                     filesArray.push(e.target.files[0]);
//                   }
//                   validate({ ...formState, paymentReceipts: filesArray });
//                 }
//               }}
//             />
//           </div>

//           {/* Notes Field */}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </div>

//           {/* Terms and Conditions Field */}
//           <div className="flex justify-normal items-center mb-4">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//                 className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
//               />
//             </label>
//             <span 
//                 className="cursor-pointer text-cyan-800 underline" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//             </span>
//           </div>

//           {/* Submit Form Button */}
//           <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
//             <button 
//               onClick={onBack} 
//               className="px-4 py-2 rounded-md flex items-center justify-center"
//               style={styles.button}>
//               <FaArrowCircleLeft />
//               <div className="ml-2">Back to Details</div>
//             </button>
//             <button 
//               type="button" 
//               className="px-4 py-2 rounded-md flex items-center justify-center"
//               onClick={handleSubscribeClick}
//               style={{ 
//                 backgroundColor: termsAccepted ? theme.primaryColor : 'gray', 
//                 color: theme.text2Color, 
//                 fontFamily: theme.fontFamily 
//               }}
//               disabled={!termsAccepted || loading || isSubmitting}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">Subscribe</div>
//             </button>
//           </div>
//         </form>
//       </div>
      
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto lg:w-[800px] lg:h-[800px] sm:max-w-screen sm:max-h-screen sm:h-[800px] xs:max-w-screen xs:max-h-screen xs:h-[600px]">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       <MyTicketAlert 
//         type={alert.type} 
//         message={alert.message} 
//         visible={alert.visible} 
//         onClose={closeAlert} 
//         onConfirm={confirmSubscribe} 
//         onCancel={closeAlert}
//         isLoading={alert.isLoading} 
//       />

//       <MyTicketAlert 
//         type={referralAlert.type} 
//         message={referralAlert.message} 
//         visible={referralAlert.visible} 
//         onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
//         onConfirm={confirmReferralApply}
//         onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
//       />
//     </div>
//   );
// };

// export default MySubscriptionRegistration;

// // update code --> 23 Aug 2024
// // src/components/client/MiniApp/App03_MySubscriptionRegistration.tsx

// // react component
// import React, { useState, useEffect } from 'react';
// import moment from 'moment-timezone';
// // react icon
// import { BiSolidPurchaseTag } from "react-icons/bi";
// import { FaArrowCircleLeft, FaClipboard, FaInfoCircle, FaEyeSlash, FaEye } from 'react-icons/fa';
// // context file
// import { useTheme } from '../../../context/ThemeContext';
// import { useEventRegTerms } from '../../../context/SubRegTermContext';
// // custom hook file
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useSubscriptionSubmit, { SubscriptionData } from '../../../hooks/Subscription/useSubscriptionSubmit';
// import useSubscriptionData, { Plan }  from '../../../hooks/Subscription/useSubscriptionData';
// import useFormValidation from '../../../hooks/Subscription/useSubscriptionValidation';
// // custom components
// import MyTicketAlert from '../MiniApp/App_AlertMessage'; 
// import ServerNameImg from '../../../assets/miniApp/broker_server_name.png'

// // Add AlertType type definition here
// type AlertType = 'error' | 'success' | 'check' | 'confirm' | 'warning';
// interface RegistrationProps {
//   plan: Plan;
//   onBack: () => void;
//   accountDetails: {
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null;
// }
// const MySubscriptionRegistration: React.FC<RegistrationProps> = ({ plan, onBack, accountDetails }) => {
//   // fetch data
//   const { theme } = useTheme();
//   const { user } = useUserDashboard();
//   const { fetchTrialInfo } = useSubscriptionData();
//   const { 
//     submitSubscription, 
//     fetchVoucherDetails, 
//     checkUserTrialSubscription, 
//     checkDemoSubscription, 
//     updateVoucherDaysInDatabase,
//     fetchAndUpdateUserAlgoMarixData,
//     checkUserVoucherEntitlement, 
//     loading
//   } = useSubscriptionSubmit();
//   const { validate, errors } = useFormValidation();
//   const termsAndConditions = useEventRegTerms();
//   // form field
//   const [orderID, setOrderID] = useState('');
//   const [planType, setPlanType] = useState('');
//   const [planDays, setPlanDays] = useState<number | null>(null);
//   const [planTrialDays, setPlanTrialDays] = useState<number | null>(null);
//   const [planRequirement, setPlanRequirement] = useState<string[]>([]);
//   const [showPassword, setShowPassword] = useState(false);
//   // apply referral 
//   const [isReferralEnabled, setIsReferralEnabled] = useState(false); 
//   const [applyReferralDisabled, setApplyReferralDisabled] = useState(true);
//   const [referralEmail, setReferralEmail] = useState('casper90@gmail.com');
//   const [referralApplied, setReferralApplied] = useState(false);
//   // fill in trading account detail(live)
//   const [isLiveEnabled, setIsLiveEnabled] = useState(false);
//   const [accountType, setAccountType] = useState('');
//   const [accountServer, setAccountServer] = useState('');
//   const [accountNumber, setAccountNumber] = useState('');
//   const [accountPassword, setAccountPassword] = useState('');
//   // fill in trading account detail(demo)
//   const [isDemoEnabled, setIsDemoEnabled] = useState(false);
//   const [demoAccountType, setDemoAccountType] = useState('');
//   const [demoAccountServer, setDemoAccountServer] = useState('');
//   const [demoAccountNumber, setDemoAccountNumber] = useState('');
//   const [demoAccountPassword, setDemoAccountPassword] = useState('');
//   // other payment field
//   const [notes, setNotes] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [receipts, setReceipts] = useState<File[]>([]);
//   // renew 
//   const [isRenewal, setIsRenewal] = useState(false);
//   // apply voucher code
//   const [applyDisabled, setApplyDisabled] = useState(true);
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState<string | null>(null);
//   const [voucherDays, setVoucherDays] = useState<number | null>(null);
//   const [voucherEnabled, setVoucherEnabled] = useState(false);
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState<number | null>(null);
//   const [voucherApplied, setVoucherApplied] = useState(false);
//   const [discountedPlanAmount, setDiscountedPlanAmount] = useState(plan.planAmount);
//   // terms and conditions
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // set for alert message
//   const [userHasDemo, setUserHasDemo] = useState(false);
//   const [alert, setAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
//   const [referralAlert, setReferralAlert] = useState<{ visible: boolean, type: AlertType, title?: string, message: string }>({ visible: false, type: 'check', message: '' });
  
//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // search for trial details
//   useEffect(() => {
//     const fetchTrialDetails = async () => {
//       if (planType === 'trial' && user?.email) {
//         const { trialRequirement, trialDays } = await fetchTrialInfo(user.email, parseInt(plan.planID, 10));

//         if (trialDays) {
//           setPlanTrialDays(trialDays);
//         } else {
//           setPlanTrialDays(null);
//         }
  
//         if (trialRequirement) {
//           setPlanRequirement(trialRequirement.split(',').map((req: string) => req.trim()));
//         } else {
//           setPlanRequirement([]);
//         }
//       } else {
//         setPlanDays(parseInt(plan.planPeriod, 10));
//         setPlanTrialDays(null);
//       }
//     };
//     fetchTrialDetails();
//   }, [planType, user?.email, fetchTrialInfo, plan.planID, plan.planPeriod, plan.planName]);   
  
//   // check for availble demo subscription
//   useEffect(() => {
//     const checkDemoSubscriptionStatus = async () => {
//       if (user?.email) {
//         const hasDemoSubscription = await checkDemoSubscription(user.email);
//         setUserHasDemo(hasDemoSubscription);
//       }
//     };
//     checkDemoSubscriptionStatus();
//   }, [user?.email, checkDemoSubscription]); 
  
//   // generated OrderID
//   useEffect(() => {
//     const generatedOrderID = 'sub-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//     setOrderID(generatedOrderID);
//   }, []);
  
//   // toggle voucher enable or disable
//   useEffect(() => {
//     setApplyDisabled(!voucherEnabled);
//   }, [voucherEnabled]);
  
//   // disable voucher for trial
//   useEffect(() => {
//     if (planType === 'trial') {
//       setVoucherEnabled(false);
//     }
//   }, [planType]);  
  
//   // pass account data from renewal user
//   useEffect(() => {
//     if (accountDetails) {
//       if (accountDetails.accountType === 'LIVE') {
//         setAccountType(accountDetails.accountType);
//         setAccountServer(accountDetails.accountServer);
//         setAccountNumber(accountDetails.accountNumber);
//         setAccountPassword(accountDetails.accountPassword);
//         setIsLiveEnabled(true);
//       } else if (accountDetails.accountType === 'DEMO') {
//         setDemoAccountType(accountDetails.accountType);
//         setDemoAccountServer(accountDetails.accountServer);
//         setDemoAccountNumber(accountDetails.accountNumber);
//         setDemoAccountPassword(accountDetails.accountPassword);
//         setIsDemoEnabled(true);
//       }
//       setPlanType('Renew');
//       setIsRenewal(true); 
//     }
//   }, [accountDetails]); 
  
//   // toggle enabled or disable referral 
//   useEffect(() => {
//     if (isReferralEnabled) {
//         setApplyReferralDisabled(false);
//     } else {
//         setApplyReferralDisabled(true);
//     }
//   }, [isReferralEnabled]);

//   // Update the voucherEnabled useEffect
//   useEffect(() => {
//     const updateVoucherDetails = async () => {
//       if (voucherEnabled && voucherCode) {
//         try {

//           // Fetch voucher details
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false);
          
//           if (voucher) {
//             const currentDate = moment().tz("Asia/Kuala_Lumpur").startOf('day');
//             const voucherPeriodDate = moment(voucher.voucherPeriod).startOf('day');
//             const remainingDays = Math.ceil(voucherPeriodDate.diff(currentDate, 'days', true)); 

//             // Update state with fetched data
//             setVoucherPeriod(voucherPeriodDate.format('YYYY-MM-DD'));
//             setVoucherDays(remainingDays >= 0 ? remainingDays : 0);
//             setVoucherRedeemCount(voucher.voucherRedeemCount);

//             // Update the voucher days in the database
//             await updateVoucherDaysInDatabase(voucher.id, remainingDays);

//           } else {
//             setVoucherPeriod(null);
//             setVoucherDays(null);
//           }
//         } catch (error) {
//           console.error('Error fetching voucher details:', error);
//           setVoucherRedeemCount(0);
//         }
//       } else {
//         setVoucherPeriod(null);
//         setVoucherDays(null);
//       }
//     };

//     updateVoucherDetails();
//   }, [voucherEnabled, voucherCode, fetchVoucherDetails, plan.planID, updateVoucherDaysInDatabase]);

//   const handleApplyReferralEmail = () => {
//     setReferralAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Do you want to apply this referral email?',
//     });
//     // Disable the referral email field after applying
//     setIsReferralEnabled(false);
//   };
  
//   const confirmReferralApply = () => {
//     try {
//       setReferralApplied(true); // Set success status
//       setReferralAlert({
//         visible: true,
//         type: 'success',
//         title: 'Referral Applied',
//         message: 'Referral email applied successfully!',
//       });
//     } catch (error) {
//       setReferralApplied(false); // Set failure status
//       setReferralAlert({
//         visible: true,
//         type: 'error',
//         title: 'Referral Error',
//         message: 'Failed to apply the referral. Please try again.',
//       });
//     }
//   };

//   const applyVoucher = async () => {
//     if (voucherCode && !voucherApplied) {
//       try {
//           // Fetch voucher details without applying it yet to get the remaining days
//           const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), false); 
    
//           if (!voucher) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return;
//           }

//           // Check if the user is entitled to redeem this voucher
//           const userEntitlement = await checkUserVoucherEntitlement(
//             voucher.voucherCode, 
//             voucher.voucherMemberType, 
//             voucher.voucherGroupMember
//           );

//           if (!userEntitlement) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'You are not entitled to redeem this voucher code.',
//             });
//             return;
//           }

//           // Check if the voucher is expired
//           const currentDate = moment().tz("Asia/Kuala_Lumpur");
//           const voucherPeriodDate = moment(voucher.voucherPeriod);
//           const remainingDays = voucherPeriodDate.diff(currentDate, 'days');

//           if (remainingDays < 0) {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'The voucher has expired and cannot be applied.',
//             });
//             return;
//           }

//           // Check if the voucher has already been fully redeemed
//           if (voucher.voucherRedeemCount === '0') {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Voucher cannot be applied as it has already been fully redeemed.',
//             });
//             return; 
//           }

//           if (voucher && user) {
//             // Fetch and update the user's AlgoMarix data in a single API call
//             const result = await fetchAndUpdateUserAlgoMarixData(user.email);
    
//             if (result) {
//               const { accountVoucherCheck } = result;
      
//               // Apply check only if the user exists and accountVoucherCheck is "0"
//               if (accountVoucherCheck === '0' && planType !== 'subscribe') {
//                 setAlert({
//                   visible: true,
//                   type: 'error',
//                   title: 'Voucher Error',
//                   message: 'Voucher cannot be applied, Please check you have selected Plan Type, else you has already been used.',
//                 });
//                 return;
//               }
            
//               // Handle voucher redeem check logic
//               if (voucher.voucherRedeemCheck === '1' && accountVoucherCheck === '0') {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: 'You have already redeemed this voucher code and cannot redeem it again.',
//                 });
//                 return;
//               }

//               // Handle specific redeem count 
//               if (voucher.voucherRedeemCheck !== '0' && parseInt(voucher.voucherRedeemCheck, 10) <= parseInt(result.redeemCount, 10)) {
//                 setAlert({
//                     visible: true,
//                     type: 'error',
//                     title: 'Voucher Error',
//                     message: `You have already redeemed this voucher code ${result.redeemCount} times and cannot redeem it more than ${voucher.voucherRedeemCheck} times.`,
//                 });
//                 return;
//               }

//               let newAmount = parseFloat(plan.planAmount);
    
//               if (voucher.voucherType === 'By Amount') {
//                 newAmount -= parseFloat(voucher.voucherAmount);
//               } else if (voucher.voucherType === 'By Percentage') {
//                 newAmount -= newAmount * (parseFloat(voucher.voucherPercentage) / 100);
//               }
    
//               // Update voucherDays in the subscription data
//               setVoucherDays(remainingDays);

//               // Now that the voucher is successfully applied, decrement the redeem count
//               await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10), true);
//               await updateVoucherDaysInDatabase(voucher.id, remainingDays); 

//               setDiscountedPlanAmount(Math.max(newAmount, 0).toFixed(2));
//               setVoucherRedeemCount(voucher.voucherRedeemCount);
//               setVoucherApplied(true); 
//               setAlert({
//                 visible: true,
//                 type: 'success',
//                 title: 'Voucher Applied',
//                 message: 'Voucher applied successfully!',
//               });
//           } else {
//             setAlert({
//               visible: true,
//               type: 'error',
//               title: 'Voucher Error',
//               message: 'Failed to retrieve user data. Please try again.',
//             });
//           }
//         }
//       } catch (error) {
//         setDiscountedPlanAmount(plan.planAmount);
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Voucher Error',
//           message: 'Failed to apply the voucher. Please check the voucher code and try again.',
//         });
//       }
//     } else if (voucherApplied) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Voucher Error',
//         message: 'This voucher has already been applied to this order.',
//       });
//     }
//   };
  
//   const handleLiveToggle = () => {
//     const newIsLiveEnabled = !isLiveEnabled;
//     setIsLiveEnabled(newIsLiveEnabled);

//     if (newIsLiveEnabled) {
//       setAccountType('LIVE');
//       setAccountServer('');
//       setAccountNumber('');
//       setAccountPassword('');
//     } else {
//       setAccountType('');
//     }
//   };

//   const handleDemoToggle = () => {
//     const newIsDemoEnabled = !isDemoEnabled;
//     setIsDemoEnabled(newIsDemoEnabled);

//     if (newIsDemoEnabled) {
//       setDemoAccountType('DEMO');
//       setDemoAccountServer('');
//       setDemoAccountNumber('');
//       setDemoAccountPassword('');
//     } else {
//       setDemoAccountType('');
//     }
//   };

//   const validateForm = () => {
//     let isValid = true;

//     if (planType === 'Renew') {
//       // For Renew plan type, only validate payment receipt and terms
//       isValid = validate({
//         planType,
//         accountType: '', 
//         accountServer: '',
//         accountNumber: '',
//         accountPassword: '',
//         demoAccountType: '', 
//         demoAccountServer: '',
//         demoAccountNumber: '',
//         demoAccountPassword: '',
//         paymentMethod,
//         paymentReceipts: receipts,
//         termsAccepted,
//       });
//     } else {
//       if (isLiveEnabled && isDemoEnabled) {
//         // If both Live and Demo are enabled, require all fields for both accounts
//         isValid = validate({
//           planType,
//           accountType,
//           accountServer,
//           accountNumber,
//           accountPassword,
//           demoAccountType,
//           demoAccountServer,
//           demoAccountNumber,
//           demoAccountPassword,
//           paymentMethod,
//           paymentReceipts: receipts,
//           termsAccepted,
//         });
//       } else if (isLiveEnabled && !isDemoEnabled) {
//         // If only Live is enabled, only validate Live account fields
//         isValid = validate({
//           planType,
//           accountType,
//           accountServer,
//           accountNumber,
//           accountPassword,
//           demoAccountType: '',
//           demoAccountServer: '',
//           demoAccountNumber: '',
//           demoAccountPassword: '',
//           paymentMethod,
//           paymentReceipts: receipts,
//           termsAccepted,
//         });
//       } else if (!isLiveEnabled && isDemoEnabled) {
//         // If only Demo is enabled, only validate Demo account fields
//         isValid = validate({
//           planType,
//           accountType: '', 
//           accountServer: '',
//           accountNumber: '',
//           accountPassword: '',
//           demoAccountType,
//           demoAccountServer,
//           demoAccountNumber,
//           demoAccountPassword,
//           paymentMethod,
//           paymentReceipts: receipts,
//           termsAccepted,
//         });
//       } else {
//         // If both are disabled, at least one account type must be enabled
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Warning',
//           message: 'Please enable and fill in at least one account type.',
//         });
//         isValid = false;
//       }
//     }

//     return isValid;
//   };  

//   const handleSubscribeClick = async () => {
//     if (!user) return;
  
//     if (!validateForm()) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         title: 'Warning',
//         message: 'Please scroll up and review to ensure all required fields are filled in.',
//       });
//       return;
//     }

//     if (planType === 'trial') {
//       const hasTrial = await checkUserTrialSubscription(user.email);
//       if (hasTrial) {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Warning',
//           message: 'You have already subscribed to a trial plan. Please upgrade your trial to a subscribe account.'
//         });
//         return;
//       }
//     }
  
//     if (planType === 'subscribe' && accountType === 'DEMO' && userHasDemo) {
//       if (userHasDemo) {
//         setAlert({
//           visible: true,
//           type: 'error',
//           title: 'Warning',
//           message: 'You have already subscribed to a demo account. Only 1 demo subscription account is allowed per user.'
//         });
//         return;
//       }
//     }
  
//     if (planType === 'subscribe') {
//       if (isLiveEnabled && isDemoEnabled) {
//         if (!accountNumber || !accountPassword || !accountServer || !demoAccountNumber || !demoAccountPassword || !demoAccountServer) {
//           setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Both Live and Demo account details are required.',
//           });
//           return;
//         }
//       } else if (isLiveEnabled && !isDemoEnabled) {
//         if (!accountNumber || !accountPassword || !accountServer) {
//           setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Live account details are required.',
//           });
//           return;
//         }
//       } else if (!isLiveEnabled && isDemoEnabled) {
//         if (!demoAccountNumber || !demoAccountPassword || !demoAccountServer) {
//           setAlert({
//             visible: true,
//             type: 'error',
//             title: 'Warning',
//             message: 'Demo account details are required.',
//           });
//           return;
//         }
//       }
//     }

//     setAlert({
//       visible: true,
//       type: 'confirm',
//       message: 'Make sure you provided correct information before subscribing?'
//     });
//   };        
  
//   const confirmSubscribe = async () => {
//     if (!user) return;
  
//     const currentDate = moment().tz("Asia/Kuala_Lumpur").toDate();
//     const formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
  
//     let subscriptionData: SubscriptionData & { isLiveEnabled: boolean, isDemoEnabled: boolean } = {
//       orderID,
//       productID: plan.planID,
//       planID: plan.id ? plan.id.toString() : '',
//       planName: plan.planName,
//       userName: user.username,
//       email: user.email,
//       referralEmail,
//       planType,
//       planAmount: discountedPlanAmount,
//       planSubDate: formattedCurrentDate,
//       planPeriod: planType === 'trial' ? null : plan.planPeriod,
//       planDays: planType === 'trial' ? null : 0,
//       planExpiredDate: planType === 'trial' ? "" : formattedCurrentDate,
//       planTrialPeriod: planType === 'trial' ? planTrialDays?.toString() || "" : null,
//       planTrialDays: planType === 'trial' ? planTrialDays : null,
//       planExpiredTrialDate: planType === 'trial' ? formattedCurrentDate : "", 
//       PaymentMethod: paymentMethod,
//       PaymentReceipts: receipts.map(receipt => receipt.name).join(', '),
//       notes,
//       termAndCondition: termsAccepted ? 'Accepted' : 'Not Accepted',
//       accountType, 
//       accountServer,  
//       accountNumber,  
//       accountPassword,
//       demoAccountType, 
//       demoAccountServer,  
//       demoAccountNumber,  
//       demoAccountPassword, 
//       voucherCode: voucherEnabled ? voucherCode : undefined,
//       accountVoucherCode: voucherCode,
//       isLiveEnabled,
//       isDemoEnabled,
//     };

    
//     if (voucherEnabled && voucherCode) {
//       // Fetch voucher details again to get the voucherPeriod
//       const voucher = await fetchVoucherDetails(voucherCode, parseInt(plan.planID, 10));
//       if (voucher && voucher.voucherPeriod) {
//         // Calculate remaining voucher days
//         const voucherPeriodDate = moment(voucher.voucherPeriod);
//         const remainingVoucherDays = voucherPeriodDate.diff(currentDate, 'days');
  
//         // Update voucherDays in the subscription data
//         subscriptionData = {
//           ...subscriptionData,
//           voucherDays: remainingVoucherDays.toString(),
//         };
  
//         // Call the API to update the voucherDays in the database
//         await updateVoucherDaysInDatabase(voucherCode, remainingVoucherDays);
//       }
//     }
  
//     if (isRenewal) {
//       subscriptionData = {
//         ...subscriptionData,
//         planType: 'Renew',
//         accountStatus: 'Pending',
//         accountID: accountDetails ? accountDetails.accountID : '',
//       };
//     }
  
//     try {
//       if (isLiveEnabled && isDemoEnabled) {
//         const liveSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'LIVE',
//           accountServer,
//           accountNumber,
//           accountPassword,
//         };

//         const demoSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'DEMO',
//           accountServer: demoAccountServer,
//           accountNumber: demoAccountNumber,
//           accountPassword: demoAccountPassword,
//         };

//         const liveOrderID = await submitSubscription(liveSubscriptionData, receipts);
//         const demoOrderID = await submitSubscription(demoSubscriptionData, receipts);

//         setOrderID(`${liveOrderID}, ${demoOrderID}`);
//       } else if (isLiveEnabled && !isDemoEnabled) {
//         const liveSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'LIVE',
//           accountServer,
//           accountNumber,
//           accountPassword,
//         };

//         const liveOrderID = await submitSubscription(liveSubscriptionData, receipts);
//         setOrderID(liveOrderID);
        
//       } else if (!isLiveEnabled && isDemoEnabled) {
//         const demoSubscriptionData = {
//           ...subscriptionData,
//           accountType: 'DEMO',
//           accountServer: demoAccountServer,
//           accountNumber: demoAccountNumber,
//           accountPassword: demoAccountPassword,
//         };

//         const demoOrderID = await submitSubscription(demoSubscriptionData, receipts);
//         setOrderID(demoOrderID);
//       }

//       setAlert({
//         visible: true,
//         type: 'success',
//         message: 'Subscription accepted. Await admin review and please wait for email confirmation.'
//       });
//     } catch (error) {
//       setAlert({
//         visible: true,
//         type: 'error',
//         message: 'Subscription failed. Please check your details or contact general@r1trader.com for technical support'
//       });
//     }
//   };

//   const handleReceiptUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//     const newReceipts = [...receipts];
//     if (e.target.files && e.target.files[0]) {
//       newReceipts[index] = e.target.files[0];
//       setReceipts(newReceipts);
//     }
//   };  

//   const formState = {
//     planType,
//     accountType,
//     accountServer,
//     accountNumber,
//     accountPassword,
//     demoAccountType,
//     demoAccountServer,
//     demoAccountNumber,
//     demoAccountPassword,
//     paymentMethod,
//     paymentReceipts: receipts,
//     termsAccepted,
//   };
  
//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//   const closeAlert = () => setAlert({ visible: false, type: 'check', message: '' });

//   return (
//     <div>
//       <div className="w-auto h-auto max-h-[550px] overflow-y-auto px-4 md:text-md1 md:h-[650px] sm:h-[550px] sm:p-1 sm:text-sm xs:h-[500px] xs:px-1 xs:text-xs">
//         <form>

//           <div className="flex justify-center my-4 sm:my-0 xs:my-0">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Basic Information</span>
//           </div>

//           {/* Description Field */}
//           <div className="my-4">
//             <p className="mb-6 font-semibold">{plan.planDescription}</p>
//           </div>

//           {/* Order ID Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="order-id">Order ID</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="order-id" type="text" value={orderID} disabled />
//           </div>

//           {/* Username Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="username">Username</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="username" type="text" value={user?.username || ''} disabled />
//           </div>

//           {/* Email Field */}
//           <div className="mb-4">
//             <label className="block mb-2 font-semibold" htmlFor="email">Email</label>
//             <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" id="email" type="email" value={user?.email || ''} disabled />
//           </div>

//           {/* Referral Email Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:mr-0 xs:mr-0 sm:w-[70px] xs:w-[50px]" htmlFor="referral-email">
//               Referral Email
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle className="ml-0 sm:mr-1 xs:mr-1"/>
//               <div className="text-xs font-thin ml-2 text-rose-500">
//                 ( Enter your referrer's email or leave as default. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2"
//                 checked={isReferralEnabled}
//                 onChange={() => setIsReferralEnabled(!isReferralEnabled)}
//                 style={{ accentColor: theme.primaryColor }}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="referral-email"
//               type="email"
//               value={referralEmail}
//               onChange={(e) => setReferralEmail(e.target.value)}
//               disabled={!isReferralEnabled}
//               placeholder="Enter referral email"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyReferralDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={handleApplyReferralEmail}
//               disabled={applyReferralDisabled || referralApplied}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Plan Name Field */}
//           <div className="mb-4">
//             <div className="flex justify-center mb-4">
//               <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Plan Selection</span>
//             </div>
//             <label className="block mb-2 font-semibold" htmlFor="plan-name">Plan Name</label>
//             <input 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="plan-name" 
//               type="text" 
//               value={plan.planName} disabled 
//             />
//           </div>

//           {/* Plan Trial Period Field */}
//           {planType === 'trial' ? (
//             <>
//               <div className="mb-4">
//                 <label 
//                   className="block mb-2 font-semibold" 
//                   htmlFor="plan-trial-period">
//                     Plan Trial Period (days)
//                 </label>
//                 <input 
//                   className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                   id="plan-trial-period" 
//                   type="text" 
//                   value={planTrialDays ? planTrialDays.toString() : ''} 
//                   disabled 
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="mb-4">
//               {/* Plan Period (Days) Field */}
//               <label className="block mb-2 font-semibold" htmlFor="plan-period">Plan Period (days)</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-period" 
//                 type="text" 
//                 value={planDays ? planDays.toString() : ''} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Plan Type Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center ">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[50px] xs:w-[50px]" 
//                 htmlFor="plan-type">
//                   Plan Type
//               </label>
//               <FaInfoCircle className="w-4 h-4 mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( If you're new, select "Subscribe." To apply for a trial, choose "Trial." )
//               </div>
//             </div>
//             <select
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50 text-black' : ''} ${errors.planType ? 'border-rose-600' : ''}`}
//               id="plan-type"
//               value={planType}
//               onChange={(e) => {
//                 setPlanType(e.target.value);
//                 const updatedFormState = {
//                   ...formState,
//                   planType: e.target.value,
//                 };
//                 if (errors.planType) validate(updatedFormState);
//               }}
//               disabled={isRenewal}
//             >
//               <option value="">Select a Plan Type</option>
//               <option value="trial">Trial</option>
//               <option value="subscribe">Subscribe</option>
//               <option value="Renew">Renew</option>
//             </select>
//           </div>

//           {/* Plan Amount Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <label className="block mb-2 font-semibold" htmlFor="plan-amount">Plan Amount</label>
//               <input 
//                 className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//                 id="plan-amount" 
//                 type="text" 
//                 value={`${discountedPlanAmount} ${plan.planCurrency}`} 
//                 disabled 
//               />
//             </div>
//           )}

//           {/* Apply Voucher Field */}
//           <div className="mb-4 flex items-center">
//             <label className="block font-semibold mr-2 sm:w-[60px] xs:w-[50px]" htmlFor="voucher-code">
//               Apply Voucher
//             </label>
//             <div className="flex justify-between items-center">
//               <FaInfoCircle/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {voucherEnabled ? (
//                   <span>
//                     ( 
//                       The voucher is valid until 
//                         <span className="font-bold text-cyan-800"> {voucherPeriod || "Date"} </span> 
//                       left only 
//                         <span className="font-bold text-cyan-800"> {voucherDays !== null ? `${voucherDays} Days` : "Days"}</span>,
//                       Redeem Count: <span className="font-bold text-cyan-800">{voucherRedeemCount || "Counts"}</span> 
//                     )
//                   </span>
//                 ) : (
//                   <span>
//                     ( Apply voucher for subscription benefit.)
//                   </span>
//                 )}
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2"
//                 checked={voucherEnabled}
//                 onChange={() => setVoucherEnabled(!voucherEnabled)}
//                 style={{ accentColor: theme.primaryColor }}
//                 disabled={planType === 'trial'}
//               />
//             </div>
//           </div>
//           <div className="mb-4 flex items-center">
//             <input
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950"
//               id="voucher-code"
//               type="text"
//               value={voucherCode}
//               onChange={(e) => setVoucherCode(e.target.value)}
//               disabled={!voucherEnabled}
//               placeholder="Enter voucher code"
//             />
//             <button
//               type="button"
//               className={`ml-2 px-4 py-2 sm:text-xs xs:text-xs rounded-md ${applyDisabled ? 'bg-gray-400' : 'bg-cyan-800'} text-white`}
//               onClick={applyVoucher}
//               disabled={applyDisabled}
//             >
//               Apply
//             </button>
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>
          
//           {/* fill in Account Section */}
//           {/* Account Type Field (for Live) */}
//           <div className="flex items-center justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Live Trading Account</span>
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="account-type"
//               >
//                 Account Type
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( Enable the checkbox to fill in the live trading account details. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 mb-2 accent-cyan-800"
//                 checked={isLiveEnabled}
//                 onChange={handleLiveToggle}
//               />
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountType ? 'border-rose-600' : ''}`}
//               id="account-type"
//               value="LIVE"
//               readOnly
//               onChange={(e) => {
//                 setAccountType(e.target.value);
//                 setAccountServer('');
//                 setAccountNumber('');
//                 setAccountPassword('');
//                 const updatedFormState = {
//                   ...formState,
//                   accountType: e.target.value,
//                 };
//                 if (errors.accountType) validate(updatedFormState);
//               }}
//               disabled={!isLiveEnabled || isRenewal}
//             >
//             </input>
//           </div>

//           {/* Account Number Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="account-number"
//               >
//                 Account Number
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account number )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountNumber ? 'border-rose-600' : ''}`}
//               id="account-number"
//               type="number"
//               value={accountNumber}
//               placeholder="Enter trading account number..."
//               onChange={(e) => {
//                 setAccountNumber(e.target.value);
//                 if (errors.accountNumber) validate({ ...formState, accountNumber: e.target.value });
//               }}
//               disabled={!isLiveEnabled || isRenewal}
//             />
//           </div>

//           {/* Account Password Field */}
//           <div className="mb-4 relative">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold xs:w-[120px]" 
//                 htmlFor="account-password"
//               >
//                 Account Password (Read-Only)
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account investor "read-only" password. )
//               </div>
//             </div>
//             <div className="relative">
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 pr-10 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountPassword ? 'border-rose-600' : ''}`}
//                 id="account-password"
//                 type={showPassword ? 'text' : 'password'}
//                 value={accountPassword}
//                 placeholder="Enter trading account investor (read-only) password..."
//                 onChange={(e) => {
//                   setAccountPassword(e.target.value);
//                   if (errors.accountPassword) validate({ ...formState, accountPassword: e.target.value });
//                 }}
//                 disabled={!isLiveEnabled || isRenewal}
//               />
//               <button
//                 type="button"
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
//                 onClick={() => setShowPassword(!showPassword)}
//               >
//                 {showPassword ? <FaEyeSlash /> : <FaEye />}
//               </button>
//             </div>
//           </div>

//           {/* Account Server Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="account-server"
//               >
//                 Account Server
//               </label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mb-2 sm:w-3 xs:w-3"
//                     style={styles.title}
//                     aria-hidden="true"
//                   />
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                 </div>
//               </div>
//               <div className="text-xs text-rose-600 mb-2">
//                 ( This is your trading account login server. Point over "<span className="font-bold">!</span>" for more instructions. )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-800 ${isRenewal ? 'bg-gray-50' : ''} ${errors.accountServer ? 'border-rose-600' : ''}`}
//               id="account-server"
//               type="text"
//               value={accountServer}
//               placeholder="Enter trading account server..."
//               onChange={(e) => {
//                 setAccountServer(e.target.value);
//                 if (errors.accountServer) validate({ ...formState, accountServer: e.target.value });
//               }}
//               disabled={!isLiveEnabled || isRenewal}
//             />
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           {/* Demo Account Type Field (for Demo) */}
//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">
//               Demo Trading Account
//             </span>
//           </div>
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="demo-account-type"
//               >
//                 Account Type
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( Enable the checkbox to fill in the demo trading account details. )
//               </div>
//               <input
//                 type="checkbox"
//                 className="ml-2 mb-2 accent-cyan-800"
//                 checked={isDemoEnabled}
//                 onChange={handleDemoToggle}
//               />
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountType ? 'border-rose-600' : ''}`}
//               id="demo-account-type"
//               value="DEMO"
//               onChange={(e) => {
//                 setDemoAccountType(e.target.value);
//                 setDemoAccountServer('');
//                 setDemoAccountNumber('');
//                 setDemoAccountPassword('');
//                 const updatedFormState = {
//                   ...formState,
//                   demoAccountType: e.target.value,
//                 };
//                 if (errors.demoAccountType) validate(updatedFormState);
//               }}
//               disabled={!isDemoEnabled || isRenewal}
//               readOnly
//             >
//             </input>
//           </div>

//           {/* Demo Account Number Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="demo-account-number"
//               >
//                 Account Number
//               </label>
//               <FaInfoCircle className="mb-2 ml-2"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account number )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountNumber ? 'border-rose-600' : ''}`}
//               id="demo-account-number"
//               type="number"
//               value={demoAccountNumber}
//               placeholder="Enter trading account number..."
//               onChange={(e) => {
//                 setDemoAccountNumber(e.target.value);
//                 if (errors.demoAccountNumber) validate({ ...formState, demoAccountNumber: e.target.value });
//               }}
//               disabled={!isDemoEnabled || isRenewal}
//             />
//           </div>

//           {/* Demo Account Password Field */}
//           <div className="mb-4 relative">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold xs:w-[120px]" 
//                 htmlFor="demo-account-password"
//               >
//                 Account Password (Read-Only)
//               </label>
//               <FaInfoCircle className="mb-2 ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 mb-2 ml-2">
//                 ( This is your trading account investor "read-only" password. )
//               </div>
//             </div>
//             <div className="relative">
//               <input
//                 className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 pr-10 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountPassword ? 'border-rose-600' : ''}`}
//                 id="demo-account-password"
//                 type={showPassword ? 'text' : 'password'}
//                 value={demoAccountPassword}
//                 placeholder="Enter trading account investor (read-only) password..."
//                 onChange={(e) => {
//                   setDemoAccountPassword(e.target.value);
//                   if (errors.demoAccountPassword) validate({ ...formState, demoAccountPassword: e.target.value });
//                 }}
//                 disabled={!isDemoEnabled || isRenewal}
//               />
//               <button
//                 type="button"
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
//                 onClick={() => setShowPassword(!showPassword)}
//               >
//                 {showPassword ? <FaEyeSlash /> : <FaEye />}
//               </button>
//             </div>
//           </div>

//           {/* Demo Account Server Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block mb-2 font-semibold" 
//                 htmlFor="demo-account-server"
//               >
//                 Account Server
//               </label>
//               <div className="relative group mx-2">
//                 <div className="flex justify-between items-center">
//                   <FaInfoCircle 
//                     className="text-xl cursor-pointer w-4 h-4 mb-2 xs:w-3"
//                     style={styles.title}
//                     aria-hidden="true"
//                   />
//                 </div>
//                 {/* Tooltip */}
//                 <div className="absolute top-full left-0 z-50 mt-2 hidden w-[500px] px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block lg:w-[400px] md:w-[400px] sm:w-[300px] sm:text-xs"
//                   style={styles.button}>
//                   You can find your account server details in your trading platform under the account information section.
//                   <img src={ServerNameImg} alt="Account Server Example" className="mt-4 cursor-pointer" />
//                 </div>
//               </div>
//               <div className="text-xs text-rose-600 mb-2">
//                 ( This is registered trading account server. Point over "<span className="font-bold">!</span>" for instructions. )
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${isRenewal ? 'bg-gray-50' : ''} ${errors.demoAccountServer ? 'border-rose-600' : ''}`}
//               id="demo-account-server"
//               type="text"
//               value={demoAccountServer}
//               placeholder="Enter trading account server..."
//               onChange={(e) => {
//                 setDemoAccountServer(e.target.value);
//                 if (errors.demoAccountServer) validate({ ...formState, demoAccountServer: e.target.value });
//               }}
//               disabled={!isDemoEnabled || isRenewal}
//             />
//           </div>

//           <div className="py-4 sm:py-2 xs:py-2"></div>

//           <div className="flex justify-center mb-4">
//             <span className="text-md bg-cyan-800 text-white px-4 py-1 rounded-full">Biling Section</span>
//           </div>
//           {/* Payment Method Field */}
//           {planType !== 'trial' && (
//             <div className="mb-4">
//               <div className="flex justify-normal items-center">
//                 <label className="block font-semibold xs:w-[60px]">Payment Method</label>
//                 <FaInfoCircle className="ml-2"/>
//                 <div className="text-xs text-rose-600 ml-2">
//                   ( choose your payment method )
//                 </div>
//               </div>
//               <div className="flex items-center mb-2">
//                 <input
//                   type="radio"
//                   id="bank-transfer"
//                   name="payment-method"
//                   value="Bank Transfer"
//                   checked={paymentMethod === 'Bank Transfer'}
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="bank-transfer" className="ml-2">Bank Transfer</label>
//               </div>
//               {paymentMethod === 'Bank Transfer' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Bank Account</span><br />
//                   <div className="flex justify-between items-center sm:text-xs xs:text-xs">
//                     <div>
//                       <li>FINOX TRADE MARKET SDN. BHD.</li>
//                       <li>Hong leong bank</li>
//                       <li>23600590742</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       style={{ color: theme.primaryColor }} 
//                       onClick={() => navigator.clipboard.writeText("23600590742")} 
//                     />
//                   </div>
//                 </div>
//               )}
//               <div className="flex items-center">
//                 <input
//                   type="radio"
//                   id="usdt"
//                   name="payment-method"
//                   value="USDT"
//                   checked={paymentMethod === 'USDT'}
//                   onChange={(e) => setPaymentMethod(e.target.value)}
//                   style={{ accentColor: theme.primaryColor }}
//                 />
//                 <label htmlFor="usdt" className="ml-2">USDT (Cryptocurrency)</label>
//               </div>
//               {paymentMethod === 'USDT' && (
//                 <div className="ml-6 sm:ml-0 xs:ml-0 my-4 border border-slate-400 rounded-2xl p-4">
//                   <span>Wallet Address:</span><br />
//                   <div className="flex justify-between items-cente sm:text-xs">
//                     <div>
//                       <li>Tron (TRC20) Network</li>
//                       <li>TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t</li>
//                     </div>
//                     <FaClipboard 
//                       className="ml-2 cursor-pointer w-6 h-6 sm:w-4 xs:w-4"
//                       style={{ color: theme.primaryColor }} 
//                       onClick={() => navigator.clipboard.writeText("TNFZCiBQRGfCgWvK2jggxipgm2NFbxH31t")} 
//                     />
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}

//           {/* Plan Trial Requirement Field */}
//           {planType === 'trial' && (
//             <div className="mb-4">
//               <p className="font-semibold">Plan Trial Requirement:</p>
//               <ul className="list-disc list-inside">
//                 {Array.isArray(planRequirement) && planRequirement.map((requirement, index) => (
//                   <li key={index}>{requirement}</li>
//                 ))}
//               </ul>
//             </div>
//           )}

//           {/* Payment Receipts Field */}
//           <div className="mb-4">
//             <div className="flex justify-normal items-center">
//               <label 
//                 className="block font-semibold sm:w-[60px] xs:w-[60px]" 
//                 htmlFor="payment-receipts"
//               >
//                 {planType === 'trial' ? 'Requirement Screenshot' : 'Payment Receipts'}
//               </label>
//               <FaInfoCircle className="ml-2 sm:w-6 xs:w-6"/>
//               <div className="text-xs text-rose-600 ml-2">
//                 {planType === 'trial' 
//                   ? '( Please upload your requirement screenshot in "png" or "jpg" format only. )' 
//                   : '( Please upload your payment receipt in "png" or "jpg" format only. )'}
//               </div>
//             </div>
//             <input
//               className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950 ${errors.paymentReceipts ? 'border-rose-600' : ''}`}
//               type="file"
//               name="PaymentReceipt"
//               onChange={(e) => {
//                 handleReceiptUpload(e, 0);
//                 if (errors.paymentReceipts) {
//                   const filesArray = [...receipts];
//                   if (e.target.files && e.target.files[0]) {
//                     filesArray.push(e.target.files[0]);
//                   }
//                   validate({ ...formState, paymentReceipts: filesArray });
//                 }
//               }}
//             />
//           </div>

//           {/* Notes Field */}
//           <div className="mb-6">
//             <label className="block mb-2 font-semibold" htmlFor="notes">Notes</label>
//             <textarea 
//               className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-950" 
//               id="notes" 
//               placeholder="Leave any notes here (optional)..." 
//               value={notes} 
//               onChange={(e) => setNotes(e.target.value)}
//             />
//           </div>

//           {/* Terms and Conditions Field */}
//           <div className="flex justify-normal items-center mb-4">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={() => setTermsAccepted(!termsAccepted)}
//                 className="mr-2 accent-cyan-800 md:w-4 sm:w-4 xs:w-4"
//               />
//             </label>
//             <span 
//                 className="cursor-pointer text-cyan-800 underline" 
//                 onClick={openModal}
//               >
//                 I have read and understand the terms and conditions
//             </span>
//           </div>

//           {/* Submit Form Button */}
//           <div className="flex justify-between items-center mt-4 space-x-4 sm:text-sm xs:text-xs">
//             <button 
//               onClick={onBack} 
//               className="px-4 py-2 rounded-md flex items-center justify-center"
//               style={styles.button}>
//               <FaArrowCircleLeft />
//               <div className="ml-2">Back to Details</div>
//             </button>
//             <button 
//               type="button" 
//               className="px-4 py-2 rounded-md flex items-center justify-center"
//               onClick={handleSubscribeClick}
//               style={{ 
//                 backgroundColor: termsAccepted ? theme.primaryColor : 'gray', 
//                 color: theme.text2Color, 
//                 fontFamily: theme.fontFamily 
//               }}
//               disabled={!termsAccepted || loading}
//             >
//               <BiSolidPurchaseTag />
//               <div className="ml-2">Subscribe</div>
//             </button>
//           </div>
//         </form>
//       </div>
      
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white border-2 p-8 flex flex-col justify-center rounded w-auto h-auto lg:w-[800px] lg:h-[800px] sm:max-w-screen sm:max-h-screen sm:h-[800px] xs:max-w-screen xs:max-h-screen xs:h-[600px]">
//             <span
//               className="border-2 p-10 overflow-y-auto"
//               onClick={(e) => e.stopPropagation()}
//             >
//               {termsAndConditions}
//             </span>
//             <button className="flex justify-center px-4 py-1 my-2 bg-cyan-800 text-white " onClick={closeModal}>
//               close
//             </button>
//           </div>
//         </div>
//       )}

//       <MyTicketAlert 
//         type={alert.type} 
//         message={alert.message} 
//         visible={alert.visible} 
//         onClose={closeAlert} 
//         onConfirm={confirmSubscribe} 
//         onCancel={closeAlert} 
//       />

//       <MyTicketAlert 
//         type={referralAlert.type} 
//         message={referralAlert.message} 
//         visible={referralAlert.visible} 
//         onClose={() => setReferralAlert({ ...referralAlert, visible: false })} 
//         onConfirm={confirmReferralApply}
//         onCancel={() => setReferralAlert({ ...referralAlert, visible: false })}
//       />
//     </div>
//   );
// };

// export default MySubscriptionRegistration;