// src/components/admin/ClientData/UsersPermissionTable.tsx

import React, {useState} from 'react';
// import icon
import { AiOutlineSearch } from 'react-icons/ai'; 
import { BsPersonFillAdd } from "react-icons/bs";
import { FaRedoAlt, FaWhatsapp } from "react-icons/fa";
import { MdPersonRemoveAlt1, MdFilterListAlt, MdPlaylistAddCheckCircle, MdInsertChart } from "react-icons/md";
// import component file
import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';
import { useAuth } from '../../../context/AuthContext'; 
import useDataTable from '../../../hooks/AdminDashboard/useDataTable';
import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
import UsersTableEditModal from '../ClientData/UsersTableEdit'; 
import UserTableTradeStats from '../ClientData/UsersTableTradeStats';
import whatsappTemplates from '../ClientData/WhatsappTemplates';

const FilterUserTable: React.FC = () => {
  const { user } = useAuth();
  const { 
    filterData, setFilterData, 
    fetchData,  fetchUserVideoStats, fetchAlgoMatrixData, fetchTraderHubData,
    addUser, updateUser, deleteUsers, loading, error 
  } = useDataTable();
  const { permissions } = useRoleAccess(user?.email || '');
  const [searchTerm, setSearchTerm] = useState(''); 
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  // selected mode
  const [selectionMode, setSelectionMode] = useState(false); 
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  // open edit/add data modal
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedUser, setSelectedUser] = useState(null);
  // show user video stats and trading stats
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [userVideoData, setUserVideoData] = useState<any[]>([]);
  // show algomatrix stats and traderhub stats
  const [isAlgoMatrixModalOpen, setIsAlgoMatrixModalOpen] = useState(false);
  const [isTraderHubModalOpen, setIsTraderHubModalOpen] = useState(false);
  const [algoMatrixData, setAlgoMatrixData] = useState<any>(null);
  const [traderHubData, setTraderHubData] = useState<any>(null);
  // open trading stat modal
  const [isTradingStatsModalOpen, setIsTradingStatsModalOpen] = useState(false);
  const [selectedEmailForTradingStats, setSelectedEmailForTradingStats] = useState<string | null>(null);
  // whatapp function
  const [isWhatsAppModalOpen, setIsWhatsAppModalOpen] = useState(false);
  const [whatsAppMessage, setWhatsAppMessage] = useState('');
  const [selectedUserForWhatsApp, setSelectedUserForWhatsApp] = useState<any>(null);

   // Handle Reload
   const handleReload = () => {
    fetchData(); 
  };

  // Handle Search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filtered data based on search term
  const filteredUsers = filterData.filter(user => {
    const idString = String(user.id); 
    return idString.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.country.toLowerCase().includes(searchTerm.toLowerCase()); 
    });

  // Handle sorting
  const handleSort = (column: string) => {
    const direction = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(direction);
  
    // Copy the filteredUsers array before sorting
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      let aValue = a[column as keyof typeof a];
      let bValue = b[column as keyof typeof b];
  
      // Handle undefined or null cases
      if (aValue === null || aValue === undefined) return direction === 'asc' ? 1 : -1;
      if (bValue === null || bValue === undefined) return direction === 'asc' ? -1 : 1;
  
      // Convert to lowercase for string comparison if both values are strings
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }
  
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  
    // Update the filterData state with the sorted data
    setFilterData(sortedUsers);
  };  

  // Handle row selection
  const handleSelectRow = (id: string) => {
    setSelectedRows(prevSelectedRows => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter(rowId => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  // Open Modal for Editing
  const handleEdit = (user: any) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  // Open Modal for Adding
  const handleAdd = () => {
    setSelectedUser(null); 
    setIsModalOpen(true);
  };

  // Handle Delete Selected Rows
  const handleDeleteSelectedRows = async () => {
    if (selectedRows.length === 0) return;

    await deleteUsers(selectedRows);
    fetchData();
    setSelectedRows([]); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveUser = async (updatedUser: any) => {
    if (selectedUser) {
      await updateUser(updatedUser); 
    } else {
      await addUser(updatedUser); 
    }
    fetchData(); 
    handleCloseModal(); 
  };

  // Inside FilterUserTable component
  const handleVideoStats = async (email: string) => {
    try {
      const videoData = await fetchUserVideoStats(email);
      setUserVideoData(videoData);
      setIsVideoModalOpen(true);
    } catch (error) {
      console.error('Error loading video stats:', error);
    }
  };  
  
  const handleAlgoMatrixStats = async (email: string) => {
    try {
      const data = await fetchAlgoMatrixData(email);
      setAlgoMatrixData(data || null); 
    } catch (error) {
      console.error('Error fetching AlgoMatrix stats:', error);
      setAlgoMatrixData(null); 
    } finally {
      setIsAlgoMatrixModalOpen(true);
    }
  };  
  
  const handleTraderHubStats = async (email: string) => {
    try {
      const data = await fetchTraderHubData(email);
      setTraderHubData(data || null); 
    } catch (error) {
      console.error('Error fetching TraderHub stats:', error);
      setTraderHubData(null); 
    } finally {
      setIsTraderHubModalOpen(true); 
    }
  };
  
  // Handle showing the modal
  const handleTradingStats = (email: string) => {
    setSelectedEmailForTradingStats(email);
    setIsTradingStatsModalOpen(true);
  };

  const handleWhatsApp = (user: any) => {
    setSelectedUserForWhatsApp(user); 
    setIsWhatsAppModalOpen(true); 
  };

  const sendWhatsAppMessage = (phone: string, message: string) => {
    const formattedPhone = phone.replace(/\D/g, '');
    const encodedMessage = encodeURIComponent(message); 
    const whatsappUrl = `https://wa.me/${formattedPhone}?text=${encodedMessage}`;
  
    window.open(whatsappUrl, '_blank'); 
  };  

  // Calculate total users, males, and females
  const totalMembers = filterData.length;
  const totalMales = filterData.filter(user => user.gender === 'male').length;
  const totalFemales = filterData.filter(user => user.gender === 'female').length;

  return (
    <div className="bg-white rounded-lg p-2 w-[1070px]">
      <div className="my-4 mx-4 space-x-2 text-cyan-800">
        <span className="text-xl font-semibold text-white bg-cyan-800 rounded-full px-4 py-2">
          Welcome, {user?.email} 
        </span>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg mb-4">
        <div className="flex justify-normal text-2xl text-cyan-800 font-bold ml-4 mt-4">
          <MdInsertChart className="mr-2 text-3xl"/> 
          <span className="mb-2">User Statistics</span>
        </div>
        <div className="flex flex-row justify-end">
          <div className="flex space-x-4">
            <div className="flex flex-col space-x-2 bg-white rounded-lg p-10 text-cyan-800">
              <span className="font-semibold">Total Users:</span>
              <span className="flex justify-end font-semibold text-2xl">{totalMembers}</span>
            </div>
            <div className="flex flex-col space-x-2 bg-white rounded-lg p-10 text-cyan-800">
              <span className="font-semibold">Total Males:</span>
              <span className="flex justify-end font-semibold text-2xl">{totalMales}</span>
            </div>
            <div className="flex flex-col space-x-2 bg-white rounded-lg p-10 text-cyan-800">
              <span className="font-semibold">Total Females:</span>
              <span className="flex justify-end font-semibold text-2xl">{totalFemales}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-1">
        <div className="flex space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search users..."
              className="border px-4 py-1 rounded w-auto"
              value={searchTerm} 
              onChange={handleSearch}
            />
            <AiOutlineSearch 
              className="absolute left-[200px] top-1/2 transform -translate-y-1/2 text-cyan-800" 
            />
          </div>
        </div>

        <div className="flex space-x-2">
          <button 
            className="flex justify-normal items-center text-cyan-800 p-2 rounded"
            onClick={handleReload}  
          >
            <FaRedoAlt className="text-lg cursor-pointer" />
          </button>
          <button 
            className="flex justify-normal items-center text-cyan-800 p-2 rounded"
            onClick={() => handleSort('id')}
          >
            <MdFilterListAlt className="text-xl cursor-pointer"/>
          </button>
          <button 
            className={`flex justify-normal items-center p-2 rounded ${permissions?.userData.deleteData === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
            onClick={() => setSelectionMode(!selectionMode)}
            disabled={permissions?.userData.deleteData !== 'enable'}
          >
            <MdPlaylistAddCheckCircle className="text-xl cursor-pointer"/>
          </button>

          {/* Add User Button: only show if addData is enabled */}
          <button 
            className={`flex justify-normal items-center p-2 rounded ${permissions?.userData.addData === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
            onClick={handleAdd}
            disabled={permissions?.userData.addData !== 'enable'}
          >
            <BsPersonFillAdd className="text-xl cursor-pointer" />
          </button>

          {/* Delete User Button: only show if deleteData is enabled */}
          <button 
            className={`flex justify-normal items-center p-2 rounded ${permissions?.userData.deleteData === 'enable' && selectedRows.length > 0 ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
            onClick={handleDeleteSelectedRows}
            disabled={permissions?.userData.deleteData !== 'enable' || selectedRows.length === 0}
          >
            <MdPersonRemoveAlt1 className="text-xl cursor-pointer"/>
          </button>
        </div>
      </div>

      {/* Show Loading Spinner */}
      {loading ? (
        <div className="flex justify-center items-center py-6">
          <div className="loader"></div>
          <p className="ml-4">Loading assigned users...</p>
        </div>
      ) : error ? (
        <p>Error loading data: {error}</p>
      ) : (
        filteredUsers.length > 0 ? (
        <div className="overflow-auto w-auto max-w-[1150px] max-h-screen h-[340px] p-2">
          <table className="w-auto table-auto border-collapse text-base">
            <thead>
              <tr className="text-center text-sm bg-gray-200">
                {selectionMode && (
                  <th className="p-2 text-nowrap">
                    <input
                      type="checkbox"
                      className="accent-cyan-800 hover:accent-cyan-700"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRows(filteredUsers.map((user) => user.id));
                        } else {
                          setSelectedRows([]);
                        }
                      }}
                      checked={selectedRows.length === filteredUsers.length && filteredUsers.length > 0}
                    />
                  </th>
                )}
                <th className="p-2 text-nowrap">id</th>
                <th className="p-2 text-nowrap">Member</th>
                <th className="p-2 text-nowrap">Name</th>
                <th className="p-2 text-nowrap">Email</th>
                <th className="p-2 text-nowrap">Gender</th>
                <th className="p-2 text-nowrap">Phone</th>
                {/* <th className="p-2 text-nowrap">Date of Birth</th> */}
                <th className="p-2 text-nowrap">Country</th>
                <th className="p-2 text-nowrap">State</th>
                <th className="p-2 text-nowrap">Telegram id</th>
                <th className="p-2 text-nowrap">Discord id</th>
                <th className="p-2 text-nowrap">AlgoMatrix Stats</th>
                <th className="p-2 text-nowrap">Trader Hub Stats</th>
                <th className="p-2 text-nowrap">Ranking</th>
                <th className="p-2 text-nowrap">Program Stats</th>
                <th className="p-2 text-nowrap">Video Stats</th>
                <th className="p-2 text-nowrap">Trading Stats</th>
                <th className="p-2 text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr 
                  key={user.id} 
                  className="text-center text-sm hover:bg-gray-50"
                  onDoubleClick={permissions?.userData.editData === 'enable' ? () => handleEdit(user) : undefined}
                >
                  {selectionMode && (
                    <td className="p-2 border-b text-nowrap">
                      <input
                        type="checkbox"
                        className="accent-cyan-800 hover:accent-cyan-700"
                        checked={selectedRows.includes(user.id)}
                        onChange={() => handleSelectRow(user.id)}
                      />
                    </td>
                  )}
                  <td className="p-2 border-b text-nowrap">{user.id}</td>
                  <td className="p-2 border-b text-nowrap">{user.stages}</td>
                  <td className="p-2 border-b text-nowrap">{user.name}</td>
                  <td className="p-2 border-b text-nowrap">{user.email}</td>
                  <td className="p-2 border-b text-nowrap">
                    <img
                      src={user.gender === 'male' ? maleAvatar : femaleAvatar}
                      alt={user.name}
                      className={`w-8 h-8 rounded-full mx-auto border-2 ${user.gender === 'male' ? "bg-cyan-800 border-cyan-800" : "bg-pink-700 border-pink-700"}`}
                    />
                  </td>
                  <td className="p-2 border-b text-nowrap">{user.phone}</td>
                  {/* <td className="p-2 border-b text-nowrap">{new Date(user.dob).toISOString().split('T')[0]}</td> */}
                  <td className="p-2 border-b text-nowrap">{user.country}</td>
                  <td className="p-2 border-b text-nowrap">{user.state}</td>
                  <td className="p-2 border-b text-nowrap">{user.telegram_id}</td>
                  <td className="p-2 border-b text-nowrap">{user.discord_id}</td>
                  
                  {/* AlgoMatrix Stats (clickable Yes/No) */}
                  <td className="p-2 border-b text-nowrap">
                    <button 
                      onClick={() => handleAlgoMatrixStats(user.email)}
                      className="text-cyan-800 hover:underline"
                    >
                      stats
                    </button>
                  </td>
                  
                  {/* TraderHub Stats (clickable Yes/No) */}
                  <td className="p-2 border-b text-nowrap">
                    <button 
                      onClick={() => handleTraderHubStats(user.email)}
                      className="text-cyan-800 hover:underline"
                    >
                      stats
                    </button>
                  </td>

                  <td className="p-2 border-b text-nowrap">{user.badges}</td>
                  <td className="p-2 border-b text-nowrap">{user.programStats}</td>
                  
                  {/* Video Stats */}
                  <td className="p-2 border-b text-nowrap">
                    <button onClick={() => handleVideoStats(user.email)} className="text-cyan-800 hover:underline">
                      Stats
                    </button>
                  </td>

                  {/* Tradomg Stats */}
                  <td className="p-2 border-b text-nowrap">
                    <button onClick={() => handleTradingStats(user.email)} className="text-cyan-800 hover:underline">
                      Stats
                    </button>
                  </td>

                  {/* Action Button */}
                  <td className="p-2 border-b text-nowrap">

                    {/* WhatsApp Icon */}
                    <button onClick={() => handleWhatsApp(user)} className="text-cyan-800">
                      <FaWhatsapp className="text-lg cursor-pointer" />
                    </button>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        ) : (
          <div>No assigned users found.</div>
        )
      )}

      {/* User Edit Modal */}
      {isModalOpen && (
        <UsersTableEditModal
          user={selectedUser}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveUser}
        />
      )}

      {/* User video stats Modal */}
      {isVideoModalOpen && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center">
          {/* Darker background with animation */}
          <div 
            className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out" 
            onClick={() => setIsVideoModalOpen(false)}
          ></div>
          
          {/* Modal Container */}
          <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
            
            {/* Modal Title */}
            <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">User Video Stats</h2>
            
            {/* Content */}
            {userVideoData.length > 0 ? (
              <div className="overflow-auto">
                <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
                  <thead className="bg-gray-300 text-black text-sm">
                    <tr>
                      <th className="py-3 px-4 text-center">Email</th>
                      <th className="py-3 px-4 text-center">Video Title</th>
                      <th className="py-3 px-4 text-center">Category</th>
                      <th className="py-3 px-4 text-center">Views</th>
                      <th className="py-3 px-4 text-center">Last View</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700 text-sm border-b">
                    {userVideoData.map((videoStat, index) => (
                      <tr 
                        key={index} 
                        className="hover:bg-gray-100"
                      >
                        <td className="py-3 px-4 text-center">{videoStat.userVideoEmail}</td>
                        <td className="py-3 px-4 text-center">{videoStat.userVideoTitle}</td>
                        <td className="py-3 px-4 text-center">{videoStat.userVideoCategory}</td>
                        <td className="py-3 px-4 text-center">{videoStat.userVideoViews}</td>
                        <td className="py-3 px-4 text-center">{new Date(videoStat.userVideoLastViews).toISOString().split('T')[0]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center text-gray-500 my-6">No video stats available for this user.</p>
            )}
            
            {/* Footer with Close Button */}
            <div className="flex justify-end mt-6">
              <button 
                onClick={() => setIsVideoModalOpen(false)} 
                className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* algomatrix stats modal */}
      {isAlgoMatrixModalOpen && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center">
          <div
            className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
            onClick={() => setIsAlgoMatrixModalOpen(false)}
          ></div>

          <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
            <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">AlgoMatrix Stats</h2>

            {/* Check if algoMatrixData exists */}
            {algoMatrixData ? (
              <div className="overflow-auto">
                <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
                  <thead className="bg-gray-300 text-black text-sm">
                    <tr>
                      <th className="py-3 px-4 text-center">Title</th>
                      <th className="py-3 px-4 text-center">Data</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700 text-sm border-b">
                    <tr>
                      <td className="py-3 px-4 text-center">User Name</td>
                      <td className="py-3 px-4 text-center">{algoMatrixData.userName}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Email</td>
                      <td className="py-3 px-4 text-center">{algoMatrixData.email}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Plan Type</td>
                      <td className="py-3 px-4 text-center">{algoMatrixData.plantype}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Plan Expiry Date</td>
                      <td className="py-3 px-4 text-center">{new Date(algoMatrixData.planExpiredDate).toISOString().split('T')[0]}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Account Status</td>
                      <td className="py-3 px-4 text-center">{algoMatrixData.accountStatus}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center text-gray-500 my-6">No AlgoMatrix stats available for this user.</p>
            )}

            <div className="flex justify-end mt-6">
              <button
                onClick={() => setIsAlgoMatrixModalOpen(false)}
                className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* trader hub stats modal */}
      {isTraderHubModalOpen && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center">
          <div
            className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
            onClick={() => setIsTraderHubModalOpen(false)}
          ></div>

          <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
            <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">TraderHub Stats</h2>

            {/* Check if traderHubData exists */}
            {traderHubData ? (
              <div className="overflow-auto">
                <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
                  <thead className="bg-gray-300 text-black text-sm">
                    <tr>
                      <th className="py-3 px-4 text-center">Column</th>
                      <th className="py-3 px-4 text-center">Data</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700 text-sm border-b">
                    <tr>
                      <td className="py-3 px-4 text-center">Name</td>
                      <td className="py-3 px-4 text-center">{traderHubData.name}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Email</td>
                      <td className="py-3 px-4 text-center">{traderHubData.email}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Plan Type</td>
                      <td className="py-3 px-4 text-center">{traderHubData.planTypehub}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Expiry Date</td>
                      <td className="py-3 px-4 text-center">{new Date(traderHubData.expiryDate).toISOString().split('T')[0]}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 text-center">Account Status</td>
                      <td className="py-3 px-4 text-center">{traderHubData.status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center text-gray-500 my-6">No TraderHub stats available for this user.</p>
            )}

            <div className="flex justify-end mt-6">
              <button
                onClick={() => setIsTraderHubModalOpen(false)}
                className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* WhatsApp Modal */}
      {isWhatsAppModalOpen && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center">
          <div
            className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
            onClick={() => setIsWhatsAppModalOpen(false)}
          ></div>

          <div className="modal relative bg-white rounded-2xl shadow-2xl p-6 max-w-lg">
            <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">Send WhatsApp Message</h2>

            <p className="text-center mb-4">Sending message to: <strong>{selectedUserForWhatsApp?.name}</strong></p>
            <p className="text-center mb-4">Contact: <strong>{selectedUserForWhatsApp?.phone}</strong></p>

            {/* Message Input */}
            <textarea
              value={whatsAppMessage}
              onChange={(e) => setWhatsAppMessage(e.target.value)}
              placeholder="Type your message here..."
              className="w-full h-24 border p-2 rounded-md focus:outline-none"
            />

            {/* Template Dropdown */}
            <div className="mt-4">
              <label className="text-sm text-gray-600">Or select a template:</label>
              <select
                onChange={(e) => {
                  const selectedTemplate = whatsappTemplates.find(template => template.title === e.target.value);
                  if (selectedTemplate) {
                    setWhatsAppMessage(selectedTemplate.content); // Auto-fill content
                  }
                }}
                className="w-full border p-2 rounded-md mt-1"
              >
                <option value="">-- Select Template --</option>
                {whatsappTemplates.map((template, idx) => (
                  <option key={idx} value={template.title}>{template.title}</option>
                ))}
              </select>
            </div>

            <div className="flex justify-end mt-6 space-x-4">
              <button
                onClick={() => setIsWhatsAppModalOpen(false)}
                className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
              >
                Cancel
              </button>

              <button
                onClick={() => sendWhatsAppMessage(selectedUserForWhatsApp?.phone, whatsAppMessage)}
                className="text-white bg-green-500 hover:bg-green-600 rounded-full px-6 py-1 font-semibold"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}

      <UserTableTradeStats
        email={selectedEmailForTradingStats || ''}
        isOpen={isTradingStatsModalOpen}
        onClose={() => setIsTradingStatsModalOpen(false)}
      />
      
    </div>
  );
};

export default FilterUserTable;

// // udpate code --> 25 oct 2042
// // src/components/admin/ClientData/UsersPermissionTable.tsx

// import React, {useState} from 'react';
// // import icon
// import { AiOutlineSearch } from 'react-icons/ai'; 
// import { BsPersonFillAdd } from "react-icons/bs";
// import { FaRedoAlt } from "react-icons/fa";
// import { MdPersonRemoveAlt1, MdFilterListAlt, MdPlaylistAddCheckCircle, MdInsertChart } from "react-icons/md";
// // import component file
// import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
// import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';
// import { useAuth } from '../../../context/AuthContext'; 
// import useDataTable from '../../../hooks/AdminDashboard/useDataTable';
// import UsersTableEditModal from './UsersTableEdit'; 
// import { useRoleAccess } from '../../../hooks/AdminDashboard/useRoleAccess';
// import UserTableTradeStats from '../ClientData/UsersTableTradeStats';

// const FilterUserTable: React.FC = () => {
//   const { user } = useAuth();
//   const { 
//     filterData, setFilterData, 
//     fetchData,  fetchUserVideoStats, fetchAlgoMatrixData, fetchTraderHubData,
//     addUser, updateUser, deleteUsers, loading, error 
//   } = useDataTable();
//   const { permissions } = useRoleAccess(user?.email || '');
//   const [searchTerm, setSearchTerm] = useState(''); 
//   const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
//   // selected mode
//   const [selectionMode, setSelectionMode] = useState(false); 
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   // open edit/add data modal
//   const [isModalOpen, setIsModalOpen] = useState(false); 
//   const [selectedUser, setSelectedUser] = useState(null);
//   // show user video stats and trading stats
//   const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
//   const [userVideoData, setUserVideoData] = useState<any[]>([]);
//   // show algomatrix stats and traderhub stats
//   const [isAlgoMatrixModalOpen, setIsAlgoMatrixModalOpen] = useState(false);
//   const [isTraderHubModalOpen, setIsTraderHubModalOpen] = useState(false);
//   const [algoMatrixData, setAlgoMatrixData] = useState<any>(null);
//   const [traderHubData, setTraderHubData] = useState<any>(null);
//   // open trading stat modal
//   const [isTradingStatsModalOpen, setIsTradingStatsModalOpen] = useState(false);
//   const [selectedEmailForTradingStats, setSelectedEmailForTradingStats] = useState<string | null>(null);

//    // Handle Reload
//    const handleReload = () => {
//     fetchData(); 
//   };

//   // Handle Search
//   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filtered data based on search term
//   const filteredUsers = filterData.filter(user => {
//     const idString = String(user.id); 
//     return idString.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       user.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       user.country.toLowerCase().includes(searchTerm.toLowerCase()); 
//     });

//   // Handle sorting
//   const handleSort = (column: string) => {
//     const direction = sortDirection === 'asc' ? 'desc' : 'asc';
//     setSortDirection(direction);
  
//     // Copy the filteredUsers array before sorting
//     const sortedUsers = [...filteredUsers].sort((a, b) => {
//       let aValue = a[column as keyof typeof a];
//       let bValue = b[column as keyof typeof b];
  
//       // Handle undefined or null cases
//       if (aValue === null || aValue === undefined) return direction === 'asc' ? 1 : -1;
//       if (bValue === null || bValue === undefined) return direction === 'asc' ? -1 : 1;
  
//       // Convert to lowercase for string comparison if both values are strings
//       if (typeof aValue === 'string' && typeof bValue === 'string') {
//         aValue = aValue.toLowerCase();
//         bValue = bValue.toLowerCase();
//       }
  
//       if (aValue < bValue) return direction === 'asc' ? -1 : 1;
//       if (aValue > bValue) return direction === 'asc' ? 1 : -1;
//       return 0;
//     });
  
//     // Update the filterData state with the sorted data
//     setFilterData(sortedUsers);
//   };  

//   // Handle row selection
//   const handleSelectRow = (id: string) => {
//     setSelectedRows(prevSelectedRows => {
//       if (prevSelectedRows.includes(id)) {
//         return prevSelectedRows.filter(rowId => rowId !== id);
//       } else {
//         return [...prevSelectedRows, id];
//       }
//     });
//   };

//   // Open Modal for Editing
//   const handleEdit = (user: any) => {
//     setSelectedUser(user);
//     setIsModalOpen(true);
//   };

//   // Open Modal for Adding
//   const handleAdd = () => {
//     setSelectedUser(null); 
//     setIsModalOpen(true);
//   };

//   // Handle Delete Selected Rows
//   const handleDeleteSelectedRows = async () => {
//     if (selectedRows.length === 0) return;

//     await deleteUsers(selectedRows);
//     fetchData();
//     setSelectedRows([]); 
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleSaveUser = async (updatedUser: any) => {
//     if (selectedUser) {
//       await updateUser(updatedUser); 
//     } else {
//       await addUser(updatedUser); 
//     }
//     fetchData(); 
//     handleCloseModal(); 
//   };

//   // Inside FilterUserTable component
//   const handleVideoStats = async (email: string) => {
//     try {
//       console.log(`Loading video stats for ${email}`);
//       const videoData = await fetchUserVideoStats(email);
//       console.log('Video data fetched:', videoData);  
//       setUserVideoData(videoData);
//       setIsVideoModalOpen(true);
//     } catch (error) {
//       console.error('Error loading video stats:', error);
//     }
//   };  
  
//   const handleAlgoMatrixStats = async (email: string) => {
//     try {
//       const data = await fetchAlgoMatrixData(email);
//       setAlgoMatrixData(data || null); 
//     } catch (error) {
//       console.error('Error fetching AlgoMatrix stats:', error);
//       setAlgoMatrixData(null); 
//     } finally {
//       setIsAlgoMatrixModalOpen(true);
//     }
//   };  
  
//   const handleTraderHubStats = async (email: string) => {
//     try {
//       const data = await fetchTraderHubData(email);
//       setTraderHubData(data || null); 
//     } catch (error) {
//       console.error('Error fetching TraderHub stats:', error);
//       setTraderHubData(null); 
//     } finally {
//       setIsTraderHubModalOpen(true); 
//     }
//   };
  
//   // Handle showing the modal
//   const handleTradingStats = (email: string) => {
//     setSelectedEmailForTradingStats(email);
//     setIsTradingStatsModalOpen(true);
//   };
    

//   // Calculate total users, males, and females
//   const totalMembers = filterData.length;
//   const totalMales = filterData.filter(user => user.gender === 'male').length;
//   const totalFemales = filterData.filter(user => user.gender === 'female').length;

//   return (
//     <div className="bg-white rounded-lg p-2">
//       <div className="my-4 mx-4 space-x-2 text-cyan-800">
//         <span className="text-xl font-semibold text-white bg-cyan-800 rounded-full px-4 py-2">
//           Welcome, {user?.email} 
//         </span>
//       </div>

//       <div className="bg-gray-100 p-4 rounded-lg mb-4">
//         <div className="flex justify-normal text-2xl text-cyan-800 font-bold ml-4 mt-4">
//           <MdInsertChart className="mr-2 text-3xl"/> 
//           <span className="mb-2">User Statistics</span>
//         </div>
//         <div className="flex flex-row justify-end">
//           <div className="flex space-x-4">
//             <div className="flex flex-col space-x-2 bg-white rounded-lg p-10 text-cyan-800">
//               <span className="font-semibold">Total Users:</span>
//               <span className="flex justify-end font-semibold text-2xl">{totalMembers}</span>
//             </div>
//             <div className="flex flex-col space-x-2 bg-white rounded-lg p-10 text-cyan-800">
//               <span className="font-semibold">Total Males:</span>
//               <span className="flex justify-end font-semibold text-2xl">{totalMales}</span>
//             </div>
//             <div className="flex flex-col space-x-2 bg-white rounded-lg p-10 text-cyan-800">
//               <span className="font-semibold">Total Females:</span>
//               <span className="flex justify-end font-semibold text-2xl">{totalFemales}</span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="flex items-center justify-between mb-1">
//         <div className="flex space-x-4">
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search users..."
//               className="border px-4 py-1 rounded w-auto"
//               value={searchTerm} 
//               onChange={handleSearch}
//             />
//             <AiOutlineSearch 
//               className="absolute left-[200px] top-1/2 transform -translate-y-1/2 text-cyan-800" 
//             />
//           </div>
//         </div>

//         <div className="flex space-x-2">
//           <button 
//             className="flex justify-normal items-center text-cyan-800 p-2 rounded"
//             onClick={handleReload}  
//           >
//             <FaRedoAlt className="text-lg cursor-pointer" />
//           </button>
//           <button 
//             className="flex justify-normal items-center text-cyan-800 p-2 rounded"
//             onClick={() => handleSort('id')}
//           >
//             <MdFilterListAlt className="text-xl cursor-pointer"/>
//           </button>
//           <button 
//             className={`flex justify-normal items-center p-2 rounded ${permissions?.userData.deleteData === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
//             onClick={() => setSelectionMode(!selectionMode)}
//             disabled={permissions?.userData.deleteData !== 'enable'}
//           >
//             <MdPlaylistAddCheckCircle className="text-xl cursor-pointer"/>
//           </button>

//           {/* Add User Button: only show if addData is enabled */}
//           <button 
//             className={`flex justify-normal items-center p-2 rounded ${permissions?.userData.addData === 'enable' ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
//             onClick={handleAdd}
//             disabled={permissions?.userData.addData !== 'enable'}
//           >
//             <BsPersonFillAdd className="text-xl cursor-pointer" />
//           </button>

//           {/* Delete User Button: only show if deleteData is enabled */}
//           <button 
//             className={`flex justify-normal items-center p-2 rounded ${permissions?.userData.deleteData === 'enable' && selectedRows.length > 0 ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
//             onClick={handleDeleteSelectedRows}
//             disabled={permissions?.userData.deleteData !== 'enable' || selectedRows.length === 0}
//           >
//             <MdPersonRemoveAlt1 className="text-xl cursor-pointer"/>
//           </button>
//         </div>
//       </div>

//       {/* Show Loading Spinner */}
//       {loading ? (
//         <div className="flex justify-center items-center py-6">
//           <div className="loader"></div>
//           <p className="ml-4">Loading assigned users...</p>
//         </div>
//       ) : error ? (
//         <p>Error loading data: {error}</p>
//       ) : (
//         filteredUsers.length > 0 ? (
//         <div className="overflow-auto w-auto max-w-[1150px] max-h-screen h-[340px] p-2">
//           <table className="w-auto table-auto border-collapse text-base">
//             <thead>
//               <tr className="text-center text-sm bg-gray-200">
//                 {selectionMode && (
//                   <th className="p-2 text-nowrap">
//                     <input
//                       type="checkbox"
//                       className="accent-cyan-800 hover:accent-cyan-700"
//                       onChange={(e) => {
//                         if (e.target.checked) {
//                           setSelectedRows(filteredUsers.map((user) => user.id));
//                         } else {
//                           setSelectedRows([]);
//                         }
//                       }}
//                       checked={selectedRows.length === filteredUsers.length && filteredUsers.length > 0}
//                     />
//                   </th>
//                 )}
//                 <th className="p-2 text-nowrap">id</th>
//                 <th className="p-2 text-nowrap">Member</th>
//                 <th className="p-2 text-nowrap">Name</th>
//                 <th className="p-2 text-nowrap">Email</th>
//                 <th className="p-2 text-nowrap">Gender</th>
//                 <th className="p-2 text-nowrap">Phone</th>
//                 <th className="p-2 text-nowrap">Date of Birth</th>
//                 <th className="p-2 text-nowrap">Country</th>
//                 <th className="p-2 text-nowrap">State</th>
//                 <th className="p-2 text-nowrap">Telegram id</th>
//                 <th className="p-2 text-nowrap">Discord id</th>
//                 <th className="p-2 text-nowrap">AlgoMatrix Stats</th>
//                 <th className="p-2 text-nowrap">Trader Hub Stats</th>
//                 <th className="p-2 text-nowrap">Ranking</th>
//                 <th className="p-2 text-nowrap">Program Stats</th>
//                 <th className="p-2 text-nowrap">Video Stats</th>
//                 <th className="p-2 text-nowrap">Trading Stats</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredUsers.map(user => (
//                 <tr 
//                   key={user.id} 
//                   className="text-center text-sm hover:bg-gray-50"
//                   onDoubleClick={permissions?.userData.editData === 'enable' ? () => handleEdit(user) : undefined}
//                 >
//                   {selectionMode && (
//                     <td className="p-2 border-b text-nowrap">
//                       <input
//                         type="checkbox"
//                         className="accent-cyan-800 hover:accent-cyan-700"
//                         checked={selectedRows.includes(user.id)}
//                         onChange={() => handleSelectRow(user.id)}
//                       />
//                     </td>
//                   )}
//                   <td className="p-2 border-b text-nowrap">{user.id}</td>
//                   <td className="p-2 border-b text-nowrap">{user.stages}</td>
//                   <td className="p-2 border-b text-nowrap">{user.name}</td>
//                   <td className="p-2 border-b text-nowrap">{user.email}</td>
//                   <td className="p-2 border-b text-nowrap">
//                     <img
//                       src={user.gender === 'male' ? maleAvatar : femaleAvatar}
//                       alt={user.name}
//                       className={`w-8 h-8 rounded-full mx-auto border-2 ${user.gender === 'male' ? "bg-cyan-800 border-cyan-800" : "bg-pink-700 border-pink-700"}`}
//                     />
//                   </td>
//                   <td className="p-2 border-b text-nowrap">{user.phone}</td>
//                   <td className="p-2 border-b text-nowrap">{new Date(user.dob).toISOString().split('T')[0]}</td>
//                   <td className="p-2 border-b text-nowrap">{user.country}</td>
//                   <td className="p-2 border-b text-nowrap">{user.state}</td>
//                   <td className="p-2 border-b text-nowrap">{user.telegram_id}</td>
//                   <td className="p-2 border-b text-nowrap">{user.discord_id}</td>
                  
//                   {/* AlgoMatrix Stats (clickable Yes/No) */}
//                   <td className="p-2 border-b text-nowrap">
//                     <button 
//                       onClick={() => handleAlgoMatrixStats(user.email)}
//                       className="text-cyan-800 hover:underline"
//                     >
//                       stats
//                     </button>
//                   </td>
                  
//                   {/* TraderHub Stats (clickable Yes/No) */}
//                   <td className="p-2 border-b text-nowrap">
//                     <button 
//                       onClick={() => handleTraderHubStats(user.email)}
//                       className="text-cyan-800 hover:underline"
//                     >
//                       stats
//                     </button>
//                   </td>

//                   <td className="p-2 border-b text-nowrap">{user.badges}</td>
//                   <td className="p-2 border-b text-nowrap">{user.programStats}</td>
                  
//                   {/* Video Stats */}
//                   <td className="p-2 border-b text-nowrap">
//                     <button onClick={() => handleVideoStats(user.email)} className="text-cyan-800 hover:underline">
//                       Stats
//                     </button>
//                   </td>

//                   {/* Tradomg Stats */}
//                   <td className="p-2 border-b text-nowrap">
//                     <button onClick={() => handleTradingStats(user.email)} className="text-cyan-800 hover:underline">
//                       Stats
//                     </button>
//                   </td>

//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//         ) : (
//           <div>No assigned users found.</div>
//         )
//       )}

//       {/* User Edit Modal */}
//       {isModalOpen && (
//         <UsersTableEditModal
//           user={selectedUser}
//           isOpen={isModalOpen}
//           onClose={handleCloseModal}
//           onSave={handleSaveUser}
//         />
//       )}

//       {/* User video stats Modal */}
//       {isVideoModalOpen && (
//         <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//           {/* Darker background with animation */}
//           <div 
//             className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out" 
//             onClick={() => setIsVideoModalOpen(false)}
//           ></div>
          
//           {/* Modal Container */}
//           <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
            
//             {/* Modal Title */}
//             <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">User Video Stats</h2>
            
//             {/* Content */}
//             {userVideoData.length > 0 ? (
//               <div className="overflow-auto">
//                 <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
//                   <thead className="bg-gray-300 text-black text-sm">
//                     <tr>
//                       <th className="py-3 px-4 text-center">Email</th>
//                       <th className="py-3 px-4 text-center">Video Title</th>
//                       <th className="py-3 px-4 text-center">Category</th>
//                       <th className="py-3 px-4 text-center">Views</th>
//                       <th className="py-3 px-4 text-center">Last View</th>
//                     </tr>
//                   </thead>
//                   <tbody className="text-gray-700 text-sm border-b">
//                     {userVideoData.map((videoStat, index) => (
//                       <tr 
//                         key={index} 
//                         className="hover:bg-gray-100"
//                       >
//                         <td className="py-3 px-4 text-center">{videoStat.userVideoEmail}</td>
//                         <td className="py-3 px-4 text-center">{videoStat.userVideoTitle}</td>
//                         <td className="py-3 px-4 text-center">{videoStat.userVideoCategory}</td>
//                         <td className="py-3 px-4 text-center">{videoStat.userVideoViews}</td>
//                         <td className="py-3 px-4 text-center">{new Date(videoStat.userVideoLastViews).toISOString().split('T')[0]}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             ) : (
//               <p className="text-center text-gray-500 my-6">No video stats available for this user.</p>
//             )}
            
//             {/* Footer with Close Button */}
//             <div className="flex justify-end mt-6">
//               <button 
//                 onClick={() => setIsVideoModalOpen(false)} 
//                 className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* algomatrix stats modal */}
//       {isAlgoMatrixModalOpen && (
//         <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//           <div
//             className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
//             onClick={() => setIsAlgoMatrixModalOpen(false)}
//           ></div>

//           <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
//             <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">AlgoMatrix Stats</h2>

//             {/* Check if algoMatrixData exists */}
//             {algoMatrixData ? (
//               <div className="overflow-auto">
//                 <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
//                   <thead className="bg-gray-300 text-black text-sm">
//                     <tr>
//                       <th className="py-3 px-4 text-center">Title</th>
//                       <th className="py-3 px-4 text-center">Data</th>
//                     </tr>
//                   </thead>
//                   <tbody className="text-gray-700 text-sm border-b">
//                     <tr>
//                       <td className="py-3 px-4 text-center">User Name</td>
//                       <td className="py-3 px-4 text-center">{algoMatrixData.userName}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Email</td>
//                       <td className="py-3 px-4 text-center">{algoMatrixData.email}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Plan Type</td>
//                       <td className="py-3 px-4 text-center">{algoMatrixData.plantype}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Plan Expiry Date</td>
//                       <td className="py-3 px-4 text-center">{new Date(algoMatrixData.planExpiredDate).toISOString().split('T')[0]}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Account Status</td>
//                       <td className="py-3 px-4 text-center">{algoMatrixData.accountStatus}</td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             ) : (
//               <p className="text-center text-gray-500 my-6">No AlgoMatrix stats available for this user.</p>
//             )}

//             <div className="flex justify-end mt-6">
//               <button
//                 onClick={() => setIsAlgoMatrixModalOpen(false)}
//                 className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* trader hub stats modal */}
//       {isTraderHubModalOpen && (
//         <div className="fixed inset-0 z-[9999] flex items-center justify-center">
//           <div
//             className="modal-overlay absolute inset-0 bg-black opacity-70 transition-opacity duration-300 ease-in-out"
//             onClick={() => setIsTraderHubModalOpen(false)}
//           ></div>

//           <div className="modal relative bg-white rounded-2xl shadow-2xl p-6">
//             <h2 className="text-2xl font-bold text-cyan-800 mb-4 text-center">TraderHub Stats</h2>

//             {/* Check if traderHubData exists */}
//             {traderHubData ? (
//               <div className="overflow-auto">
//                 <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
//                   <thead className="bg-gray-300 text-black text-sm">
//                     <tr>
//                       <th className="py-3 px-4 text-center">Column</th>
//                       <th className="py-3 px-4 text-center">Data</th>
//                     </tr>
//                   </thead>
//                   <tbody className="text-gray-700 text-sm border-b">
//                     <tr>
//                       <td className="py-3 px-4 text-center">Name</td>
//                       <td className="py-3 px-4 text-center">{traderHubData.name}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Email</td>
//                       <td className="py-3 px-4 text-center">{traderHubData.email}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Plan Type</td>
//                       <td className="py-3 px-4 text-center">{traderHubData.planTypehub}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Expiry Date</td>
//                       <td className="py-3 px-4 text-center">{new Date(traderHubData.expiryDate).toISOString().split('T')[0]}</td>
//                     </tr>
//                     <tr>
//                       <td className="py-3 px-4 text-center">Account Status</td>
//                       <td className="py-3 px-4 text-center">{traderHubData.status}</td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             ) : (
//               <p className="text-center text-gray-500 my-6">No TraderHub stats available for this user.</p>
//             )}

//             <div className="flex justify-end mt-6">
//               <button
//                 onClick={() => setIsTraderHubModalOpen(false)}
//                 className="text-cyan-800 border border-cyan-800 rounded-full px-6 py-1 font-semibold"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       <UserTableTradeStats
//         email={selectedEmailForTradingStats || ''}
//         isOpen={isTradingStatsModalOpen}
//         onClose={() => setIsTradingStatsModalOpen(false)}
//       />
      
//     </div>
//   );
// };

// export default FilterUserTable;