// src/components/admin/VideoSetting/VideoUploadProgress.tsx

import React from 'react';

interface ProgressModalProps {
  isOpen: boolean;
  progress: number;
  onClose: () => void;
}

const ProgressModal: React.FC<ProgressModalProps> = ({ isOpen, progress, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-sm h-auto p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-cyan-800 mb-4">Uploading Video</h2>
        <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
          <div
            className="bg-cyan-800 h-4 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="text-center text-cyan-800">{progress}%</p>
        {progress === 100 && (
          <button
            onClick={onClose}
            className="mt-4 bg-cyan-800 text-white px-4 py-2 rounded-lg"
          >
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default ProgressModal;
