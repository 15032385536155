// src/components/UpdateState.tsx

import React, { useState } from 'react';
import { stateOptions } from '../hooks/Login/useFormValidation';

const StateModal: React.FC<{ onSave: (state: string, idCard: string) => void }> = ({ onSave }) => {
  const [selectedState, setSelectedState] = useState('');
  const [country, setCountry] = useState(''); 
  const [idCard, setIdCard] = useState('');

  const handleSave = () => {
    if (selectedState && idCard) {
      onSave(selectedState, idCard);
    } else {
      alert('Please select a state and enter your Identify card no.');
    }
  };

  const handleIdCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    
    // If "Malaysia" is selected, apply formatting for 6-2-4 digits
    if (country === 'Malaysia') {
      // Remove non-digit characters and format as "XXXXXX-XX-XXXX"
      value = value.replace(/\D/g, '');  // Remove non-numeric characters
      if (value.length > 6) value = value.slice(0, 6) + '-' + value.slice(6);
      if (value.length > 9) value = value.slice(0, 9) + '-' + value.slice(9);
      if (value.length > 14) value = value.slice(0, 14); // Limit length
    }
    
    setIdCard(value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full h-auto max-h-screen ">
        <div className="p-1 overflow-y-auto h-auto max-h-[500px]">
          {/* description */}
          <div className="border text-rose-600 p-4 rounded-md my-4 text-sm">
            Thank you for being a valued member of our R One community. To ensure your profile is complete and to provide 
            you with the best updated application experience, please select your state from the options below. Updating your latest 
            information allows us to tailor our services and communications to better meet your needs.
          </div>

          {/* State Dropdown */}      
          <select
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <option value="">Select a state</option>
            {stateOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {/* description */}
          <div className="border text-rose-600 p-4 rounded-md my-4 text-sm">
            In addition, we require your Identity Card Number to verify your account and ensure the security of your profile. 
            If you are from another country, please provide your full Identity Card Number in a free-text format. This information 
            helps us to serve you better by ensuring that your details are accurate and up-to-date.
          </div>

          {/* Country Dropdown */}
          <div>
            <label className="block mx-2">Identity Id:</label>
            <select
              className="w-full p-2 border border-gray-300 rounded-lg"
              value={country}
              onChange={(e) => { setCountry(e.target.value); setIdCard(''); }}  
            >
              <option value="">Select a country</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Identify card input */}
          {country && (
            <div className="mt-4">
              <label className="block mx-2">
                {country === 'Malaysia' ? 'Identity Card No.' : 'Full Identity Card No.'}
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder={country === 'Malaysia' ? 'XXXXXX-XX-XXXX' : 'Enter Identity Card No.'}
                value={idCard}
                onChange={handleIdCardChange}
                maxLength={country === 'Malaysia' ? 14 : undefined} 
              />
            </div>
          )}
        </div>

        <div className="mt-4 flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-cyan-800 text-white rounded"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default StateModal;

// // udpate code --> 07 nov 2024
// // src/components/UpdateState.tsx

// import React, { useState } from 'react';
// import { stateOptions } from '../hooks/Login/useFormValidation';

// const StateModal: React.FC<{ onSave: (state: string, idCard: string) => void }> = ({ onSave }) => {
//   const [selectedState, setSelectedState] = useState('');
//   const [country, setCountry] = useState(''); 
//   const [idCard, setIdCard] = useState('');

//   const handleSave = () => {
//     if (selectedState && idCard) {
//       onSave(selectedState, idCard);
//     } else {
//       alert('Please select a state and enter your Identify card no.');
//     }
//   };

//   const handleIdCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     let value = e.target.value;
    
//     // If "Malaysia" is selected, apply formatting for 6-2-4 digits
//     if (country === 'Malaysia') {
//       // Remove non-digit characters and format as "XXXXXX-XX-XXXX"
//       value = value.replace(/\D/g, '');  // Remove non-numeric characters
//       if (value.length > 6) value = value.slice(0, 6) + '-' + value.slice(6);
//       if (value.length > 9) value = value.slice(0, 9) + '-' + value.slice(9);
//       if (value.length > 14) value = value.slice(0, 14); // Limit length
//     }
    
//     setIdCard(value);
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full h-auto max-h-screen ">
//         <div className="p-1 overflow-y-auto h-auto max-h-[500px]">
//           {/* description */}
//           <div className="border text-rose-600 p-4 rounded-md my-4 text-sm">
//             Thank you for being a valued member of our R One community. To ensure your profile is complete and to provide 
//             you with the best updated application experience, please select your state from the options below. Updating your latest 
//             information allows us to tailor our services and communications to better meet your needs.
//           </div>

//           {/* State Dropdown */}      
//           <select
//             className="w-full p-2 border border-gray-300 rounded-lg"
//             value={selectedState}
//             onChange={(e) => setSelectedState(e.target.value)}
//           >
//             <option value="">Select a state</option>
//             {stateOptions.map((option) => (
//               <option key={option.value} value={option.value}>
//                 {option.label}
//               </option>
//             ))}
//           </select>

//           {/* description */}
//           <div className="border text-rose-600 p-4 rounded-md my-4 text-sm">
//             In addition, we require your Identity Card Number to verify your account and ensure the security of your profile. 
//             If you are from another country, please provide your full Identity Card Number in a free-text format. This information 
//             helps us to serve you better by ensuring that your details are accurate and up-to-date.
//           </div>

//           {/* Country Dropdown */}
//           <div>
//             <label className="block mx-2">Identity Id:</label>
//             <select
//               className="w-full p-2 border border-gray-300 rounded-lg"
//               value={country}
//               onChange={(e) => { setCountry(e.target.value); setIdCard(''); }}  
//             >
//               <option value="">Select a country</option>
//               <option value="Malaysia">Malaysia</option>
//               <option value="Other">Other</option>
//             </select>
//           </div>

//           {/* Identify card input */}
//           {country && (
//             <div className="mt-4">
//               <label className="block mx-2">
//                 {country === 'Malaysia' ? 'Identity Card No.' : 'Full Identity Card No.'}
//               </label>
//               <input
//                 className="w-full p-2 border border-gray-300 rounded-lg"
//                 placeholder={country === 'Malaysia' ? 'XXXXXX-XX-XXXX' : 'Enter Identity Card No.'}
//                 value={idCard}
//                 onChange={handleIdCardChange}
//                 maxLength={country === 'Malaysia' ? 14 : undefined} 
//               />
//             </div>
//           )}
//         </div>

//         <div className="mt-4 flex justify-end space-x-2">
//           <button
//             className="px-4 py-2 bg-cyan-800 text-white rounded"
//             onClick={handleSave}
//           >
//             Save
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StateModal;