// src/hooks/AdminDashboard/useDataTable.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';
import { useAuth } from '../../context/AuthContext'; 
export interface ClientDataProps {
  id: string;
  name: string;
  icNo: string;
  email: string;
  gender: string;
  countryCode?: string;
  phone: string;
  dob: string;
  country: string;
  state: string;
  address: string;
  telegram_id?: string; 
  discord_id?: string;
  profileImage?: string;
  stages?: string;
  badges?: string;
  programStats?: string;
  password?: string;
  memberType: string;
  dateJoined: Date | null; 
  assignMember?: string;
  assignGroup?: string;
  algoMatrixData?: boolean;
  traderHubData?: boolean;
}
export interface UserVideoDataProps {
  userVideoEmail: string;
  userVideoName: string;
  userVideoTitle: string;
  userVideoCategory: string;
  userVideoViews: string;
  userVideoLastViews: string;
}

export interface AlgoMatrixDataProps {
  accountStatus: string;
  userName: string;
  email: string;
  planType: string;
  planExpiredDate: string;
}

export interface TraderHubDataProps {
  status: string;
  name: string;
  email: string;
  planTypehub: string;
  expiryDate: string;
}

const useDataTable = () => {
  const [data, setData] = useState<ClientDataProps[]>([]);
  const [filterData, setFilterData] = useState<ClientDataProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();
  const { user } = useAuth();

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/data/User`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();

      setData(result);
    } catch (err) {
      setError('Error fetching data');
      console.error('Error details:', err);
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  const handleSendTelegramLink = async (user: ClientDataProps) => {

    try {
      const response = await fetch(`${apiUrl}/api/sendTelegramLink`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email }),  
      });
  
      if (response.ok) {
        await fetchData();
        alert('Telegram link sent via email.');
      } else {
        const errorDetails = await response.json();
        console.error("Failed to send Telegram link, response details:", errorDetails);
        alert('Failed to send Telegram link');
      }
    } catch (error) {
      console.error('Error sending Telegram link via email:', error);
    }
  };
    
  const deleteUsers = async (ids: string[]) => {
    try {
      const response = await fetch(`${apiUrl}/api/data/User`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await fetchData();
    } catch (err) {
      setError('Error deleting users');
      console.error('Error details:', err);
    }
  };

  const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
    try {
      const response = await fetch(`${apiUrl}/api/data/User`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser),
      });
  
      if (response.status === 409) {
        const errorResponse = await response.json();
        console.error("Duplicate user error from server:", errorResponse); 
        throw new Error('Duplicate entry: Email already exists'); 
      }

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Failed to add user - Error from server:", errorResponse); 
        throw new Error('Failed to add new user');
      }
  
      const addedUser = await response.json();

      setData(prevData => [...prevData, addedUser]);
      return addedUser;
    } catch (err) {
      console.error("Error occurred during addUser:", err); 
      setError('Error adding new user');
      console.error('Error details:', err);
      throw err;
    }
  };

  const updateUser = async (updatedUser: ClientDataProps) => {
    try {

      const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser),
      });

      // Enhanced Error Handling: Check if response is not OK
      if (!response.ok) {
        const errorDetails = await response.json();
        console.error("Failed to update user - Error from server:", errorDetails);
        throw new Error(errorDetails.error || 'Failed to update user');
      }

      const result = await response.json();
      setData(prevData => prevData.map(user => user.id === updatedUser.id ? result : user));
      return result;
    } catch (err) {
      setError('Error updating user');
      console.error('Error details:', err);
      throw err;
    }
  };

  // filtering data by assignmember and assign group
  const fetchAssignedUsers = useCallback(async () => {
    setLoading(true);
    try {
      if (!user?.email) {
        throw new Error("User email not found");
      }

      const response = await fetch(`${apiUrl}/api/filterData?email=${user.email}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setFilterData(result);
    } catch (err) {
      setError('Error fetching assigned users');
      console.error('Error details:', err);
    } finally {
      setLoading(false);
    }
  }, [apiUrl, user?.email]);

  const fetchUserVideoStats = async (email: string): Promise<UserVideoDataProps[]> => {
    try {
      const response = await fetch(`${apiUrl}/api/userVideo/${email}`);
      const data = await response.json();
  
      // Check if data is already an array or needs to be wrapped in an array
      if (Array.isArray(data)) {
        return data.map((video: any) => ({
          userVideoEmail: video.userVideoEmail,
          userVideoName: video.userVideoName,
          userVideoTitle: video.userVideoTitle,
          userVideoCategory: video.userVideoCategory,
          userVideoViews: video.userVideoViews,
          userVideoLastViews: video.userVideoLastViews,
        }));
      } else {
        // If the response is not an array, we wrap it in an array
        return [{
          userVideoEmail: data.userVideoEmail,
          userVideoName: data.userVideoName,
          userVideoTitle: data.userVideoTitle,
          userVideoCategory: data.userVideoCategory,
          userVideoViews: data.userVideoViews,
          userVideoLastViews: data.userVideoLastViews,
        }];
      }
    } catch (error) {
      console.error('Error fetching video stats:', error);
      throw error;
    }
  };      

  const fetchTradingStatus = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/algoMatrix/${email}/tradingStatus`);
      if (!response.ok) {
        console.warn(`No trading status found for email: ${email}`);
        return '';
      }

      const data = await response.json();
      return data.tradingStatus;
    } catch (error) {
      console.error('Error fetching trading status:', error);
      return '';
    }
  };

  const fetchAlgoMatrixData = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/algoMatrix/${email}`);

      if (!response.ok) {
        console.warn(`No AlgoMatrix data found for email: ${email}`);
        return null; // Return null if no data
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching AlgoMatrix data:', error);
      return null;
    }
  };
  
  const fetchTraderHubData = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/traderHub/${email}`);

      if (!response.ok) {
        console.warn(`No TraderHub data found for email: ${email}`);
        return null; // Return null if no data
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching TraderHub data:', error);
      return null;
    }
  };  
  
  useEffect(() => {
    if (user) {
      fetchAssignedUsers();
    }
  }, [fetchAssignedUsers, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

 return { 
    loading, error, 
    data, setData, filterData, setFilterData,
    fetchData, fetchAssignedUsers, fetchUserVideoStats, fetchTradingStatus, fetchAlgoMatrixData, fetchTraderHubData,
    addUser, updateUser, deleteUsers, 
    handleSendTelegramLink,
  };
};

export default useDataTable;


// // udpate code --> 22 oct 2024 v02
// // src/hooks/AdminDashboard/useDataTable.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import { useAuth } from '../../context/AuthContext'; 
// export interface ClientDataProps {
//   id: string;
//   name: string;
//   icNo: string;
//   email: string;
//   gender: string;
//   countryCode?: string;
//   phone: string;
//   dob: string;
//   country: string;
//   state: string;
//   address: string;
//   telegram_id?: string; 
//   discord_id?: string;
//   profileImage?: string;
//   stages?: string;
//   badges?: string;
//   programStats?: string;
//   password?: string;
//   memberType: string;
//   assignMember?: string;
//   assignGroup?: string;
// }
// export interface UserVideoDataProps {
//   userVideoEmail: string;
//   userVideoName: string;
//   userVideoTitle: string;
//   userVideoCategory: string;
//   userVideoViews: string;
//   userVideoLastViews: string;
// }

// const useDataTable = () => {
//   const [data, setData] = useState<ClientDataProps[]>([]);
//   const [filterData, setFilterData] = useState<ClientDataProps[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const result = await response.json();

//       setData(result);
//     } catch (err) {
//       setError('Error fetching data');
//       console.error('Error details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const handleSendTelegramLink = async (user: ClientDataProps) => {

//     try {
//       const response = await fetch(`${apiUrl}/api/sendTelegramLink`, { 
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email: user.email }),  
//       });
  
//       if (response.ok) {
//         await fetchData();
//         alert('Telegram link sent via email.');
//       } else {
//         const errorDetails = await response.json();
//         console.error("Failed to send Telegram link, response details:", errorDetails);
//         alert('Failed to send Telegram link');
//       }
//     } catch (error) {
//       console.error('Error sending Telegram link via email:', error);
//     }
//   };
    
//   const deleteUsers = async (ids: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ ids }),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       await fetchData();
//     } catch (err) {
//       setError('Error deleting users');
//       console.error('Error details:', err);
//     }
//   };

//   const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newUser),
//       });
  
//       if (response.status === 409) {
//         const errorResponse = await response.json();
//         console.error("Duplicate user error from server:", errorResponse); 
//         throw new Error('Duplicate entry: Email already exists'); 
//       }

//       if (!response.ok) {
//         const errorResponse = await response.json();
//         console.error("Failed to add user - Error from server:", errorResponse); 
//         throw new Error('Failed to add new user');
//       }
  
//       const addedUser = await response.json();

//       setData(prevData => [...prevData, addedUser]);
//       return addedUser;
//     } catch (err) {
//       console.error("Error occurred during addUser:", err); 
//       setError('Error adding new user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

//   const updateUser = async (updatedUser: ClientDataProps) => {
//     try {

//       const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedUser),
//       });

//       if (!response.ok) {
//       const errorResponse = await response.json(); 
//       console.error('Failed to update user:', errorResponse);
//       throw new Error('Failed to update user');
//     }

//       const result = await response.json();
//       setData(prevData => prevData.map(user => user.id === updatedUser.id ? result : user));
//       return result;
//     } catch (err) {
//       setError('Error updating user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

//   // filtering data by assignmember and assign group
//   const fetchAssignedUsers = useCallback(async () => {
//     setLoading(true);
//     try {
//       if (!user?.email) {
//         throw new Error("User email not found");
//       }

//       const response = await fetch(`${apiUrl}/api/filterData?email=${user.email}`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const result = await response.json();
//       setFilterData(result);
//     } catch (err) {
//       setError('Error fetching assigned users');
//       console.error('Error details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl, user?.email]);

//   const fetchUserVideoStats = async (email: string): Promise<UserVideoDataProps[]> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/userVideo/${email}`);
//       const data = await response.json();
  
//       // Check if data is already an array or needs to be wrapped in an array
//       if (Array.isArray(data)) {
//         return data.map((video: any) => ({
//           userVideoEmail: video.userVideoEmail,
//           userVideoName: video.userVideoName,
//           userVideoTitle: video.userVideoTitle,
//           userVideoCategory: video.userVideoCategory,
//           userVideoViews: video.userVideoViews,
//           userVideoLastViews: video.userVideoLastViews,
//         }));
//       } else {
//         // If the response is not an array, we wrap it in an array
//         return [{
//           userVideoEmail: data.userVideoEmail,
//           userVideoName: data.userVideoName,
//           userVideoTitle: data.userVideoTitle,
//           userVideoCategory: data.userVideoCategory,
//           userVideoViews: data.userVideoViews,
//           userVideoLastViews: data.userVideoLastViews,
//         }];
//       }
//     } catch (error) {
//       console.error('Error fetching video stats:', error);
//       throw error;
//     }
//   };      

//   const fetchTradingStatus = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/algoMatrix/${email}/tradingStatus`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch trading status');
//       }
//       const data = await response.json();
//       return data.tradingStatus;
//     } catch (error) {
//       console.error('Error fetching trading status:', error);
//       throw error;
//     }
//   };
  
//   useEffect(() => {
//     if (user) {
//       fetchAssignedUsers();
//     }
//   }, [fetchAssignedUsers, user]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//  return { 
//     loading, error, 
//     data, setData, filterData, setFilterData,
//     fetchData, fetchAssignedUsers, fetchUserVideoStats, fetchTradingStatus,
//     addUser, updateUser, deleteUsers, 
//     handleSendTelegramLink,
//   };
// };

// export default useDataTable;


// // udpate code --> 22 oct 2024
// // src/hooks/AdminDashboard/useDataTable.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import { useAuth } from '../../context/AuthContext'; 
// export interface ClientDataProps {
//   id: string;
//   name: string;
//   icNo: string;
//   email: string;
//   gender: string;
//   countryCode?: string;
//   phone: string;
//   dob: string;
//   country: string;
//   state: string;
//   address: string;
//   telegram_id?: string; 
//   discord_id?: string;
//   profileImage?: string;
//   stages?: string;
//   badges?: string;
//   programStats?: string;
//   password?: string;
//   memberType: string;
//   assignMember?: string;
//   assignGroup?: string;
// }
// export interface UserVideoDataProps {
//   userVideoEmail: string;
//   userVideoName: string;
//   userVideoTitle: string;
//   userVideoCategory: string;
//   userVideoViews: string;
//   userVideoLastViews: string;
// }

// const useDataTable = () => {
//   const [data, setData] = useState<ClientDataProps[]>([]);
//   const [filterData, setFilterData] = useState<ClientDataProps[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const result = await response.json();

//       setData(result);
//     } catch (err) {
//       setError('Error fetching data');
//       console.error('Error details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const handleSendTelegramLink = async (user: ClientDataProps) => {

//     try {
//       const response = await fetch(`${apiUrl}/api/sendTelegramLink`, { 
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email: user.email }),  
//       });
  
//       if (response.ok) {
//         await fetchData();
//         alert('Telegram link sent via email.');
//       } else {
//         const errorDetails = await response.json();
//         console.error("Failed to send Telegram link, response details:", errorDetails);
//         alert('Failed to send Telegram link');
//       }
//     } catch (error) {
//       console.error('Error sending Telegram link via email:', error);
//     }
//   };
    
//   const deleteUsers = async (ids: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ ids }),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       await fetchData();
//     } catch (err) {
//       setError('Error deleting users');
//       console.error('Error details:', err);
//     }
//   };

//   const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newUser),
//       });
  
//       if (response.status === 409) {
//         const errorResponse = await response.json();
//         console.error("Duplicate user error from server:", errorResponse); 
//         throw new Error('Duplicate entry: Email already exists'); 
//       }

//       if (!response.ok) {
//         const errorResponse = await response.json();
//         console.error("Failed to add user - Error from server:", errorResponse); 
//         throw new Error('Failed to add new user');
//       }
  
//       const addedUser = await response.json();

//       setData(prevData => [...prevData, addedUser]);
//       return addedUser;
//     } catch (err) {
//       console.error("Error occurred during addUser:", err); 
//       setError('Error adding new user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

//   const updateUser = async (updatedUser: ClientDataProps) => {
//     try {

//       const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedUser),
//       });

//       if (!response.ok) {
//       const errorResponse = await response.json(); 
//       console.error('Failed to update user:', errorResponse);
//       throw new Error('Failed to update user');
//     }

//       const result = await response.json();
//       setData(prevData => prevData.map(user => user.id === updatedUser.id ? result : user));
//       return result;
//     } catch (err) {
//       setError('Error updating user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

//   // filtering data by assignmember and assign group
//   const fetchAssignedUsers = useCallback(async () => {
//     setLoading(true);
//     try {
//       if (!user?.email) {
//         throw new Error("User email not found");
//       }

//       const response = await fetch(`${apiUrl}/api/filterData?email=${user.email}`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const result = await response.json();
//       setFilterData(result);
//     } catch (err) {
//       setError('Error fetching assigned users');
//       console.error('Error details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl, user?.email]);

//   const fetchUserVideoStats = async (email: string): Promise<UserVideoDataProps[]> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/userVideo/${email}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch video stats');
//       }
//       const data = await response.json();
      
//       // Return an array of UserVideoDataProps
//       return data.map((video: any) => ({
//         userVideoEmail: video.userVideoEmail,
//         userVideoName: video.userVideoName,
//         userVideoTitle: video.userVideoTitle,
//         userVideoCategory: video.userVideoCategory,
//         userVideoViews: video.userVideoViews,
//         userVideoLastViews: video.userVideoLastViews,
//       }));
//     } catch (error) {
//       console.error('Error fetching video stats:', error);
//       throw error;
//     }
//   };   

//   const fetchTradingStatus = async (email: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/algoMatrix/${email}/tradingStatus`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch trading status');
//       }
//       const data = await response.json();
//       return data.tradingStatus;
//     } catch (error) {
//       console.error('Error fetching trading status:', error);
//       throw error;
//     }
//   };
  

//   useEffect(() => {
//     if (user) {
//       fetchAssignedUsers();
//     }
//   }, [fetchAssignedUsers, user]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//  return { 
//     loading, error, 
//     data, setData, filterData, setFilterData,
//     fetchData, fetchAssignedUsers, fetchUserVideoStats, fetchTradingStatus,
//     addUser, updateUser, deleteUsers, 
//     handleSendTelegramLink,
//   };
// };

// export default useDataTable;

// // udpate code --> 21 oct 2024
// // src/hooks/AdminDashboard/useDataTable.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import { useAuth } from '../../context/AuthContext'; 
// export interface ClientDataProps {
//   id: string;
//   name: string;
//   icNo: string;
//   email: string;
//   gender: string;
//   countryCode?: string;
//   phone: string;
//   dob: string;
//   country: string;
//   state: string;
//   address: string;
//   telegramId?: string; 
//   discordId?: string;
//   profileImage?: string;
//   stages?: string;
//   badges?: string;
//   programStats?: string;
//   password?: string;
//   memberType: string;
//   assignMember?: string;
//   assignGroup?: string;
// }
// // export interface ProgramDataProps {
// //  id: string;
// //  name: string;
// //  status: string;
// //  memberType: string;
// //  amount: string;
// //  currency: string;
// //  slot: string;
// //  redeemCounts: string;
// //  startDate: string;
// //  time: string;
// //  endDate: string;
// //  location: string;
// // }

// const useDataTable = () => {
//   const [data, setData] = useState<ClientDataProps[]>([]);
//   const [filterData, setFilterData] = useState<ClientDataProps[]>([]);
//   // const [programData, setProgramData] = useState<ProgramDataProps[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();
//   const { user } = useAuth();

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const result = await response.json();

//       setData(result);
//     } catch (err) {
//       setError('Error fetching data');
//       console.error('Error details:', err);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const deleteUsers = async (ids: string[]) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ ids }),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       await fetchData();
//     } catch (err) {
//       setError('Error deleting users');
//       console.error('Error details:', err);
//     }
//   };

//   const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/data/User`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newUser),
//       });
  
//       if (response.status === 409) {
//         const errorResponse = await response.json();
//         console.error("Duplicate user error from server:", errorResponse); 
//         throw new Error('Duplicate entry: Email already exists'); 
//       }

//       if (!response.ok) {
//         const errorResponse = await response.json();
//         console.error("Failed to add user - Error from server:", errorResponse); 
//         throw new Error('Failed to add new user');
//       }
  
//       const addedUser = await response.json();

//       setData(prevData => [...prevData, addedUser]);
//       return addedUser;
//     } catch (err) {
//       console.error("Error occurred during addUser:", err); 
//       setError('Error adding new user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

//   const addFilterUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/filterData/addUser`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newUser),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to add new user');
//       }
  
//       const addedUser = await response.json();
//       setData(prevData => [...prevData, addedUser]);
//       return addedUser;
//     } catch (err) {
//       setError('Error adding new user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

//   const updateUser = async (updatedUser: ClientDataProps) => {
//     try {

//       const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedUser),
//       });

//       if (!response.ok) {
//       const errorResponse = await response.json(); 
//       console.error('Failed to update user:', errorResponse);
//       throw new Error('Failed to update user');
//     }

//       const result = await response.json();
//       setData(prevData => prevData.map(user => user.id === updatedUser.id ? result : user));
//       return result;
//     } catch (err) {
//       setError('Error updating user');
//       console.error('Error details:', err);
//       throw err;
//     }
//   };

// //   // Fetch Program Data
// //  const fetchProgramData = useCallback(async () => {
// //    try {
// //      const response = await fetch(`${apiUrl}/api/data/Program`);
// //      if (!response.ok) {
// //        throw new Error('Network response was not ok');
// //      }
// //      const result = await response.json();
// //      setProgramData(result);
// //    } catch (err) {
// //      setError('Error fetching data');
// //      console.error('Error details:', err);
// //    } finally {
// //      setLoading(false);
// //    }
// //  }, [apiUrl]);

//  // filtering data by assignmember and assign group
//  const fetchAssignedUsers = useCallback(async () => {
//   setLoading(true);
//   try {
//     if (!user?.email) {
//       throw new Error("User email not found");
//     }

//     const response = await fetch(`${apiUrl}/api/filterData?email=${user.email}`);
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     const result = await response.json();
//     setFilterData(result);
//   } catch (err) {
//     setError('Error fetching assigned users');
//     console.error('Error details:', err);
//   } finally {
//     setLoading(false);
//   }
// }, [apiUrl, user?.email]);

//   useEffect(() => {
//     if (user) {
//       fetchAssignedUsers();
//     }
//   }, [fetchAssignedUsers, user]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   // useEffect(() => {
//   //   fetchProgramData();
//   // }, [fetchProgramData]);

//  return { 
//     loading, error, 
//     data, setData, filterData, setFilterData,
//     fetchData, fetchAssignedUsers, 
//     addUser, updateUser, deleteUsers, 
//     addFilterUser,
//     // programData, setProgramData, 
//   };
// };

// export default useDataTable;