// src/components/admin/VideoSetting/VideoPlaylistModal.tsx

import React, { useState } from 'react';
import { FaSearch, FaMinusSquare } from 'react-icons/fa';
import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload';
import { useEnv } from '../../../context/EnvContext';
import VideoGroupModal from './VideoGroupModal';

interface VideoData {
  videoId: string;
  videoTitle: string;
  videoThumbnail: string;
  videoLength: string;
  videoCategory: string;
}

interface PlaylistData {
  videoPlaylistId?: string;
  videoPlaylistTitle: string;
  videoPlaylistDescription: string;
  videoPlaylistArray: number;
  videoPlaylistContent: string[];
  videoPlaylistPermission: string;
  videoPlaylistGroup: string;
  videoPlaylistGroupCustom:string;
  videoPlaylistDate: string;
}

interface VideoPlaylistModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleCreatePlaylist: (playlistData: PlaylistData) => void;
}

const VideoPlaylistModal: React.FC<VideoPlaylistModalProps> = ({ isOpen, onClose, handleCreatePlaylist }) => {
  const { apiUrl } = useEnv();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [playlistArray, setPlaylistArray] = useState('');
  const [selectedVideos, setSelectedVideos] = useState<VideoData[]>([]);
  const [isSelectingVideos, setIsSelectingVideos] = useState(false);

  const [permission, setPermission] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [customGroupEmails, setCustomGroupEmails] = useState<string[]>([]);
  const [groupOptions, setGroupOptions] = useState<string[]>([
    "R One Certified Trader",
    "R One Master Trader",
    "R One Elite Trader",
    "R One Dealer",
    "R One Trader Hub",
    "R One Member",
    "R One Basic",
  ]);  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { videos, fetchPlaylists } = useVideoUpload();

  // Function to handle group selection
  const handleGroupSelection = (group: string) => {
    if (!selectedGroups.includes(group)) {
      setSelectedGroups([...selectedGroups, group]);
    }
  };

  const handleRemoveGroup = (group: string) => {
    setSelectedGroups(selectedGroups.filter(g => g !== group));
  };

  // Handle custom group addition
  const handleAddCustomGroup = (groupName: string, emailList: string[]) => {
    setGroupOptions([...groupOptions, groupName]);
    setSelectedGroups([...selectedGroups, groupName]);
    setCustomGroupEmails([...customGroupEmails, ...emailList]); 
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (title) {
      const playlistContent = selectedVideos.map(video => video.videoId);

      const playlistData: PlaylistData = {
        videoPlaylistTitle: title,
        videoPlaylistDescription: description,
        videoPlaylistArray: parseInt(playlistArray, 10),
        videoPlaylistContent: playlistContent,
        videoPlaylistGroup: selectedGroups.join(','), 
        videoPlaylistGroupCustom: customGroupEmails.join(','), 
        videoPlaylistPermission: permission,
        videoPlaylistDate: new Date().toISOString(),
      };

      // Call the passed-in function to handle the playlist creation
      await handleCreatePlaylist(playlistData);

      // Refetch playlists after adding to playlist
      await fetchPlaylists(); 

      onClose();
    } else {
      alert('Title is required');
    }
  };

  const handleVideoSelect = (video: VideoData) => {
    if (selectedVideos.some((v) => v.videoId === video.videoId)) {
      setSelectedVideos(selectedVideos.filter((v) => v.videoId !== video.videoId));
    } else {
      setSelectedVideos([...selectedVideos, video]);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-[800px] h-auto max-h-screen mx-4 p-6 rounded-lg shadow-lg">
        {!isSelectingVideos ? (
          <>
            <h2 className="text-2xl font-semibold text-cyan-800 mb-2">Create a new playlist</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-5">
                <label className="block text-cyan-800 font-semibold mb-2">Title (required)</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
                  placeholder="Add title"
                  required
                />
              </div>

              <div className="mb-5">
                <label className="block text-cyan-800 font-semibold mb-2">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
                  placeholder="Add description"
                  rows={3}
                  maxLength={5000}
                />
              </div>

              {/* Permission field */}
              <div className="flex flex-row justify-between items-center space-x-4 mb-5">
                <div className="flex flex-col w-full">
                  <label className="block text-cyan-800 font-semibold mb-2">Playlist Permission</label>
                  <select
                    value={permission}
                    onChange={(e) => setPermission(e.target.value)}
                    className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
                  >
                    <option value="">Select a permission</option>
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                  </select>
                </div>

                <div className="flex flex-col w-full">
                  <label className="block text-cyan-800 font-semibold mb-2">Playlist Array</label>
                  <input
                    type="number"
                    value={playlistArray}
                    onChange={(e) => setPlaylistArray(e.target.value)}
                    className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
                    placeholder="Enter a number"
                    required
                  />
                </div>
              </div>

              {/* Group field (only visible if permission is Private) */}
              {permission === 'Private' && (
                <div className="mb-5">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-cyan-800 font-semibold">Private Group Access</label>
                    {/* Dropdown to select a group */}
                    <select
                      className="block max-w-[160px] text-sm text-gray-600 rounded-lg px-4 py-1"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === 'custom') {
                          setIsModalOpen(true);
                        } else {
                          handleGroupSelection(value);
                        }
                      }}
                      value=""
                    >
                      <option value="" disabled>Select a group</option>
                      {groupOptions.map(group => (
                        <option key={group} value={group}>{group}</option>
                      ))}
                      <option value="custom">Add custom group</option>
                    </select>
                  </div>

                  {/* Display selected groups */}
                  {selectedGroups.length === 0 ? (
                    <div className="text-center border-dashed border-4 border-gray-200 rounded-lg p-4">
                      <span className="text-gray-300 font-semibold px-4 py-1 rounded-lg">
                        Add a group
                      </span>
                    </div>
                  ) : (
                    <div className="ml-4">
                      <ul className="mb-2">
                        {selectedGroups.map(group => (
                          <li key={group} className="text-sm text-cyan-800 flex justify-normal items-center">
                            <FaMinusSquare
                              className="mr-2 cursor-pointer"
                              onClick={() => handleRemoveGroup(group)}
                            />
                            {group}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {/* Playlist Content */}
              <div className="mb-5">
                <label className="text-cyan-800 font-semibold w-full">Playlist Content</label>
                {selectedVideos.length > 0 ? (
                  <div className="border-4 border-dashed rounded-lg p-4 text-center mt-2">
                    <div className="flex flex-wrap justify-center">
                      {/* Fix: Display only one randomly selected video */}
                      {(() => {
                        const randomVideo = selectedVideos[Math.floor(Math.random() * selectedVideos.length)];
                        return (
                          <div key={randomVideo.videoId} className="w-24 h-14 mr-4 mb-2 cursor-pointer">
                            <img
                              src={`${apiUrl}${randomVideo.videoThumbnail}`}
                              alt={randomVideo.videoTitle}
                              className="w-full h-full rounded-lg"
                            />
                            <p className="text-xs font-semibold text-gray-700 mt-1">{randomVideo.videoTitle}</p>
                          </div>
                        );
                      })()}
                    </div>
                    <p className="text-sm font-semibold text-cyan-800 mt-2">Total Selected Videos: {selectedVideos.length}</p>
                  </div>
                ) : (
                  <div className="border-4 border-dashed rounded-lg p-4 text-center mt-2">
                    <button
                      type="button"
                      className="text-gray-300 font-bold"
                      onClick={() => setIsSelectingVideos(true)}
                    >
                      Add videos
                    </button>
                  </div>
                )}
              </div>

              <div className="flex justify-between mt-4">
                <button type="button" onClick={onClose} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
                  Cancel
                </button>
                <button type="submit" className="bg-cyan-800 text-white px-4 py-2 rounded-lg">
                  Create
                </button>
              </div>
            </form>

            {/* Include VideoGroupModal */}
            <VideoGroupModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              handleAddGroup={handleAddCustomGroup}
            />
          </>
        ) : (
          // Step 2: Video Selection Modal
          <>
            <h2 className="text-xl font-semibold text-cyan-800 mb-2">Add videos to your playlist</h2>
            <div className="flex justify-between items-start w-full text-sm">
              <div className="w-[400px]">
                <div className="flex justify-between items-center border py-1 px-4">
                  <div className="font-semibold text-sm">Video Content</div>
                  <div className="relative">
                    <input
                      type="input"
                      className="w-[200px] py-1 text-sm border-gray-300"
                      placeholder="Search videos"
                      onChange={() => {}}
                    />
                    <FaSearch className="absolute right-3 top-2 text-gray-500 text-xs" />
                  </div>
                </div>

                <div className="bg-gray-100 overflow-y-auto max-h-[300px] flex flex-col justify-between">
                  {videos
                    .filter((video) => video.videoId && !video.videoPlaylistId)
                    .map((video) => (
                      <div
                        key={video.videoId}
                        className={`flex flex-row justify-between items-center p-2 rounded cursor-pointer border-b ${
                          selectedVideos.some((v) => v.videoId === video.videoId) ? 'bg-cyan-100' : 'hover:bg-gray-200'
                        }`}
                        onClick={() => handleVideoSelect(video)} 
                      >
                        <img
                          src={`${apiUrl}${video.videoThumbnail}`}
                          alt={video.videoTitle}
                          className="w-16 h-9 mr-2"
                        />
                        <div className="flex flex-col text-left">
                          <div className="font-bold">{video.videoTitle}</div>
                          <div className="text-xs text-gray-500">{video.videoLength}</div>
                        </div>
                        <div className="flex justify-end items-center ">
                          <div className="border-2 rounded-full px-4">{video.videoCategoryArray}</div>
                          <div className="mx-4">{video.videoCategory}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="w-1/2">
                <div className="border py-2 px-4 font-semibold text-sm">Videos to add</div>
                <div className="overflow-y-auto max-h-[300px]">
                  {selectedVideos.length > 0 ? (
                    selectedVideos.map((video) => (
                      <div key={video.videoId} className="flex flex-row justify-between items-center p-2 border-b cursor-pointer">
                        <img
                          src={`${apiUrl}${video.videoThumbnail}`}
                          alt={video.videoTitle}
                          className="w-16 h-9 mr-2"
                        />
                        <div className="flex flex-row items-center space-x-4">
                          <div className="flex flex-row justify-end text-right">
                            <div className="text-sm font-bold">{video.videoTitle}</div>
                            <div className="text-sm text-gray-500">{video.videoLength}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center p-5 text-gray-300 text-sm ml-6">No videos selected</div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <button type="button" onClick={() => setIsSelectingVideos(false)} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
                Cancel
              </button>
              <button type="button" className="bg-cyan-800 text-white px-4 py-2 rounded-lg" onClick={() => setIsSelectingVideos(false)}>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoPlaylistModal;


// // udpate code --> 08 oct 2024
// // src/components/admin/VideoSetting/VideoPlaylistModal.tsx

// import React, { useState } from 'react';
// import { FaSearch } from 'react-icons/fa';
// import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload';
// import { useEnv } from '../../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoTitle: string;
//   videoThumbnail: string;
//   videoLength: string;
//   videoCategory: string;
// }

// interface PlaylistData {
//   videoPlaylistId?: string;
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: Record<string, { videoId: string; videoTitle: string; videoCategory: string }>; 
//   videoPlaylistDate: string;
// }

// interface VideoPlaylistModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   handleCreatePlaylist: (playlistData: PlaylistData) => void;
// }

// const VideoPlaylistModal: React.FC<VideoPlaylistModalProps> = ({ isOpen, onClose, handleCreatePlaylist }) => {
//   const { apiUrl } = useEnv();
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [visibility, setVisibility] = useState('Public');
//   const [playlistArray, setPlaylistArray] = useState('');
//   const [selectedVideos, setSelectedVideos] = useState<VideoData[]>([]);
//   const [isSelectingVideos, setIsSelectingVideos] = useState(false);

//   const { videos, fetchPlaylists } = useVideoUpload();

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     if (title) {
//       const playlistContent = selectedVideos?.reduce<Record<string, { videoId: string; videoTitle: string; videoCategory: string }>>(
//         (acc, video) => {
//           acc[video.videoId] = {
//             videoId: video.videoId,      
//             videoTitle: video.videoTitle,  
//             videoCategory: video.videoCategory 
//           };
//           return acc;
//         },
//         {}
//       );

//       const playlistData: PlaylistData = {
//         videoPlaylistTitle: title,
//         videoPlaylistDescription: description,
//         videoPlaylistArray: parseInt(playlistArray, 10),
//         videoPlaylistContent: playlistContent,
//         videoPlaylistDate: new Date().toISOString(),
//       };

//       // Call the passed-in function to handle the playlist creation
//       await handleCreatePlaylist(playlistData);

//       // Refetch playlists after adding to playlist
//       await fetchPlaylists(); 

//       onClose();
//     } else {
//       alert('Title is required');
//     }
//   };

//   const handleVideoSelect = (video: VideoData) => {
//     if (selectedVideos.some((v) => v.videoId === video.videoId)) {
//       setSelectedVideos(selectedVideos.filter((v) => v.videoId !== video.videoId));
//     } else {
//       setSelectedVideos([...selectedVideos, video]);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
//       <div className="bg-white w-full max-w-[800px] h-auto max-h-screen mx-4 p-6 rounded-lg shadow-lg">
//         {!isSelectingVideos ? (
//           <>
//             <h2 className="text-2xl font-semibold text-cyan-800 mb-2">Create a new playlist</h2>
//             <form onSubmit={handleSubmit}>
//               <div className="mb-5">
//                 <label className="block text-cyan-800 font-semibold mb-2">Title (required)</label>
//                 <input
//                   type="text"
//                   value={title}
//                   onChange={(e) => setTitle(e.target.value)}
//                   className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   placeholder="Add title"
//                   required
//                 />
//               </div>

//               <div className="mb-5">
//                 <label className="block text-cyan-800 font-semibold mb-2">Description</label>
//                 <textarea
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                   className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   placeholder="Add description"
//                   rows={3}
//                   maxLength={5000}
//                 />
//               </div>

//               <div className="flex flex-row justify-between items-center space-x-4 mb-5">
//                 <div className="flex flex-col w-full">
//                   <label className="block text-cyan-800 font-semibold mb-2">Visibility</label>
//                   <select
//                     value={visibility}
//                     onChange={(e) => setVisibility(e.target.value)}
//                     className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   >
//                     <option value="Public">Public</option>
//                     <option value="Private">Private</option>
//                   </select>
//                 </div>

//                 <div className="flex flex-col w-full">
//                   <label className="block text-cyan-800 font-semibold mb-2">Playlist Array</label>
//                   <input
//                     type="number"
//                     value={playlistArray}
//                     onChange={(e) => setPlaylistArray(e.target.value)}
//                     className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                     placeholder="Enter a number"
//                     required
//                   />
//                 </div>
//               </div>

//               {/* Playlist Content */}
//               <div className="mb-5">
//                 <label className="text-cyan-800 font-semibold w-full">Playlist Content</label>
//                 {selectedVideos.length > 0 ? (
//                   <div className="border-4 border-dashed rounded-lg p-4 text-center mt-2">
//                     <div className="flex flex-wrap justify-center">
//                       {/* Fix: Display only one randomly selected video */}
//                       {(() => {
//                         const randomVideo = selectedVideos[Math.floor(Math.random() * selectedVideos.length)];
//                         return (
//                           <div key={randomVideo.videoId} className="w-24 h-14 mr-4 mb-2 cursor-pointer">
//                             <img
//                               src={`${apiUrl}${randomVideo.videoThumbnail}`}
//                               alt={randomVideo.videoTitle}
//                               className="w-full h-full rounded-lg"
//                             />
//                             <p className="text-xs font-semibold text-gray-700 mt-1">{randomVideo.videoTitle}</p>
//                           </div>
//                         );
//                       })()}
//                     </div>
//                     <p className="text-sm font-semibold text-cyan-800 mt-2">Total Selected Videos: {selectedVideos.length}</p>
//                   </div>
//                 ) : (
//                   <div className="border-4 border-dashed rounded-lg p-4 text-center mt-2">
//                     <button
//                       type="button"
//                       className="text-gray-300 font-bold"
//                       onClick={() => setIsSelectingVideos(true)}
//                     >
//                       Add videos
//                     </button>
//                   </div>
//                 )}
//               </div>

//               <div className="flex justify-between mt-4">
//                 <button type="button" onClick={onClose} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
//                   Cancel
//                 </button>
//                 <button type="submit" className="bg-cyan-800 text-white px-4 py-2 rounded-lg">
//                   Create
//                 </button>
//               </div>
//             </form>
//           </>
//         ) : (
//           // Step 2: Video Selection Modal
//           <>
//             <h2 className="text-xl font-semibold text-cyan-800 mb-2">Add videos to your playlist</h2>
//             <div className="flex justify-between items-start w-full text-sm">
//               <div className="w-[400px]">
//                 <div className="flex justify-between items-center border py-1 px-4">
//                   <div className="font-semibold text-sm">Video Content</div>
//                   <div className="relative">
//                     <input
//                       type="input"
//                       className="w-[200px] py-1 text-sm border-gray-300"
//                       placeholder="Search videos"
//                       onChange={() => {}}
//                     />
//                     <FaSearch className="absolute right-3 top-2 text-gray-500 text-xs" />
//                   </div>
//                 </div>

//                 <div className="bg-gray-100 overflow-y-auto max-h-[300px] flex flex-col justify-between">
//                   {videos
//                     .filter((video) => video.videoId && !video.videoPlaylistId)
//                     .map((video) => (
//                       <div
//                         key={video.videoId}
//                         className={`flex flex-row justify-between items-center p-2 rounded cursor-pointer border-b ${
//                           selectedVideos.some((v) => v.videoId === video.videoId) ? 'bg-cyan-100' : 'hover:bg-gray-200'
//                         }`}
//                         onClick={() => handleVideoSelect(video)} 
//                       >
//                         <img
//                           src={`${apiUrl}${video.videoThumbnail}`}
//                           alt={video.videoTitle}
//                           className="w-16 h-9 mr-2"
//                         />
//                         <div className="flex flex-col text-left">
//                           <div className="font-bold">{video.videoTitle}</div>
//                           <div className="text-xs text-gray-500">{video.videoLength}</div>
//                         </div>
//                         <div className="flex justify-end items-center ">
//                           <div className="border-2 rounded-full px-4">{video.videoCategoryArray}</div>
//                           <div className="mx-4">{video.videoCategory}</div>
//                         </div>
//                       </div>
//                     ))}
//                 </div>
//               </div>

//               <div className="w-1/2">
//                 <div className="border py-2 px-4 font-semibold text-sm">Videos to add</div>
//                 <div className="overflow-y-auto max-h-[300px]">
//                   {selectedVideos.length > 0 ? (
//                     selectedVideos.map((video) => (
//                       <div key={video.videoId} className="flex flex-row justify-between items-center p-2 border-b cursor-pointer">
//                         <img
//                           src={`${apiUrl}${video.videoThumbnail}`}
//                           alt={video.videoTitle}
//                           className="w-16 h-9 mr-2"
//                         />
//                         <div className="flex flex-row items-center space-x-4">
//                           <div className="flex flex-row justify-end text-right">
//                             <div className="text-sm font-bold">{video.videoTitle}</div>
//                             <div className="text-sm text-gray-500">{video.videoLength}</div>
//                           </div>
//                         </div>
//                       </div>
//                     ))
//                   ) : (
//                     <div className="text-center p-5 text-gray-300 text-sm ml-6">No videos selected</div>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <div className="flex justify-between mt-4">
//               <button type="button" onClick={() => setIsSelectingVideos(false)} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
//                 Cancel
//               </button>
//               <button type="button" className="bg-cyan-800 text-white px-4 py-2 rounded-lg" onClick={() => setIsSelectingVideos(false)}>
//                 Done
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default VideoPlaylistModal;



// // update code --> 06 Oct 2024 v02
// // src/components/admin/VideoSetting/VideoPlaylistModal.tsx

// import React, { useState } from 'react';
// import { FaSearch } from 'react-icons/fa';
// import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload';
// import { useEnv } from '../../../context/EnvContext';

// interface VideoData {
//   videoId: string;
//   videoTitle: string;
//   videoThumbnail: string;
//   videoLength: string;
// }

// interface PlaylistData {
//   videoPlaylistId?: string; 
//   videoPlaylistTitle: string;
//   videoPlaylistDescription: string;
//   videoPlaylistArray: number;
//   videoPlaylistContent: Record<string, string>;
//   videoPlaylistDate: string;
// }

// interface VideoPlaylistModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   handleCreatePlaylist: (playlistData: PlaylistData) => void;
// }

// const VideoPlaylistModal: React.FC<VideoPlaylistModalProps> = ({ isOpen, onClose, handleCreatePlaylist }) => {
//   const { apiUrl } = useEnv();
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [visibility, setVisibility] = useState('Public');
//   const [playlistArray, setPlaylistArray] = useState('');
//   const [selectedVideos, setSelectedVideos] = useState<VideoData[]>([]);
//   const [isSelectingVideos, setIsSelectingVideos] = useState(false);

//   const { videos, fetchVideos, fetchPlaylists } = useVideoUpload(); 

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     if (title) {
//       const playlistContent = selectedVideos.reduce<Record<string, string>>((acc, video) => {
//         acc[video.videoId] = video.videoTitle; // Add each selected video as {videoId: videoTitle}
//         return acc;
//       }, {});

//       const playlistData: PlaylistData = {
//         videoPlaylistTitle: title,
//         videoPlaylistDescription: description,
//         videoPlaylistArray: parseInt(playlistArray, 10),
//         videoPlaylistContent: playlistContent,
//         videoPlaylistDate: new Date().toISOString(),
//       };

//       // Call the passed-in function to handle the playlist creation
//       await handleCreatePlaylist(playlistData);

//       // Refetch videos and playlists after adding to playlist
//       await fetchVideos();
//       await fetchPlaylists(); // Ensure playlists are also refreshed

//       onClose();
//     } else {
//       alert('Title is required');
//     }
//   };   

//   const handleVideoSelect = (video: VideoData) => {
//     if (selectedVideos.some(v => v.videoId === video.videoId)) {
//       setSelectedVideos(selectedVideos.filter(v => v.videoId !== video.videoId));
//     } else {
//       setSelectedVideos([...selectedVideos, video]);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
//       <div className="bg-white w-full max-w-[800px] h-auto max-h-screen mx-4 p-6 rounded-lg shadow-lg">
//         {/* Step 1: Playlist Information */}
//         {!isSelectingVideos ? (
//           <>
//             <h2 className="text-2xl font-semibold text-cyan-800 mb-2">Create a new playlist</h2>
//             <form onSubmit={handleSubmit}>
//               <div className="mb-5">
//                 <label className="block text-cyan-800 font-semibold mb-2">Title (required)</label>
//                 <input
//                   type="text"
//                   value={title}
//                   onChange={(e) => setTitle(e.target.value)}
//                   className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   placeholder="Add title"
//                   required
//                 />
//               </div>

//               <div className="mb-5">
//                 <label className="block text-cyan-800 font-semibold mb-2">Description</label>
//                 <textarea
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                   className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   placeholder="Add description"
//                   rows={3}
//                   maxLength={5000}
//                 />
//               </div>
              
//               <div className="flex flex-row justify-between items-center space-x-4 mb-5">
//                 <div className="flex flex-col w-full">
//                     <label className="block text-cyan-800 font-semibold mb-2">
//                         Visibility
//                     </label>
//                     <div className="flex space-x-4">
//                         <select
//                             value={visibility}
//                             onChange={(e) => setVisibility(e.target.value)}
//                             className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                         >
//                         <option value="Public">Public</option>
//                         <option value="Private">Private</option>
//                     </select>
//                     </div>
//                 </div>

//                 <div className="flex flex-col w-full">
//                     <label className=" text-cyan-800 font-semibold w-full mb-2">
//                         Playlist Array
//                     </label>
//                     <div className="flex space-x-4">
//                         <input
//                             type="number"
//                             value={playlistArray}
//                             onChange={(e) => setPlaylistArray(e.target.value)}
//                             className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                             placeholder="Enter a number"
//                             required
//                         />
//                     </div>
//                 </div>
//               </div>

//               {/* Playlist Content (Add Videos Button) */}
//               <div className="mb-5">
//                 <label className="text-cyan-800 font-semibold w-full">Playlist Content</label>
//                 {selectedVideos.length > 0 ? (
//                     <div className="border-4 border-dashed rounded-lg p-4 text-center mt-2">
//                     <div className="flex flex-wrap justify-center">
//                         {/* Display only one randomly selected video */}
//                         {(() => {
//                         const randomVideo = selectedVideos[Math.floor(Math.random() * selectedVideos.length)];
//                         return (
//                             <div key={randomVideo.videoId} className="w-24 h-14 mr-4 mb-2 cursor-pointer">
//                             <img
//                                 src={`${apiUrl}${randomVideo.videoThumbnail}`}
//                                 alt={randomVideo.videoTitle}
//                                 className="w-full h-full rounded-lg"
//                             />
//                             <p className="text-xs font-semibold text-gray-700 mt-1">{randomVideo.videoTitle}</p>
//                             </div>
//                         );
//                         })()}
//                     </div>
//                     <p className="text-sm font-semibold text-cyan-800 mt-2">
//                         Total Selected Videos: {selectedVideos.length}
//                     </p>
//                     </div>
//                 ) : (
//                     <div className="border-4 border-dashed rounded-lg p-4 text-center mt-2">
//                     <button
//                         type="button"
//                         className="text-gray-300 font-bold"
//                         onClick={() => setIsSelectingVideos(true)} // Open video selection modal
//                     >
//                         Add videos
//                     </button>
//                     </div>
//                 )}
//               </div>

//               <div className="flex justify-between mt-4">
//                 <button type="button" onClick={onClose} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
//                   Cancel
//                 </button>
//                 <button type="submit" className="bg-cyan-800 text-white px-4 py-2 rounded-lg">
//                   Create
//                 </button>
//               </div>
//             </form>
//           </>
//         ) : (
//           // Step 2: Video Selection Modal (This is the one that opens for selecting videos)
//           <>
//             <h2 className="text-xl font-semibold text-cyan-800 mb-2">Add videos to your playlist</h2>
//             <div className="flex justify-between items-start w-full text-sm">
//               <div className="w-[400px]">
//                 <div className="flex justify-between items-center border py-1 px-4">
//                     {/* search bar and content title */}
//                     <div className="font-semibold text-sm">Video Content</div>
//                     <div className="relative">
//                     <input
//                         type="input"
//                         className="w-[200px] py-1 text-sm border-gray-300"
//                         placeholder="Search videos"
//                     />
//                         <FaSearch className="absolute right-3 top-2 text-gray-500 text-xs" />
//                     </div>
//                 </div>

//                  {/* Selected Playlist Section */}
//                 <div className="bg-gray-100 overflow-y-auto max-h-[300px] flex flex-col justify-between">
//                   {videos.map((video) => (
//                     <div
//                       key={video.videoId}
//                       className={`flex flex-row justify-between items-center p-2 rounded cursor-pointer border-b ${selectedVideos.some((v) => v.videoId === video.videoId) ? 'bg-cyan-100' : 'hover:bg-gray-200'}`}
//                       onClick={() => handleVideoSelect(video)} // Handle video selection
//                     >
//                         <img
//                             src={`${apiUrl}${video.videoThumbnail}`} 
//                             alt={video.videoTitle}
//                             className="w-16 h-9 mr-2"
//                         />
//                         <div className="flex flex-col text-left">
//                             <div className="font-bold">{video.videoTitle}</div>
//                             <div className="text-xs text-gray-500">{video.videoLength}</div>
//                         </div>
//                         <div className="flex justify-end items-center ">
//                             <div className="border-2 rounded-full px-4">{video.videoCategoryArray}</div>
//                             <div className="mx-4">{video.videoCategory}</div>
//                         </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>

//               {/* Show selected videos */}
//               <div className="w-1/2">
//                 <div className="border py-2 px-4 font-semibold text-sm">Videos to add</div>
//                 <div className="overflow-y-auto max-h-[300px]">
//                   {selectedVideos.length > 0 ? (
//                     selectedVideos.map((video) => (
//                       <div
//                         key={video.videoId}
//                         className="flex justify-between items-center p-2 border-b cursor-pointer"
//                       >
//                         <img
//                           src={`${apiUrl}${video.videoThumbnail}`}
//                           alt={video.videoTitle}
//                           className="w-16 h-9 mr-2"
//                         />
//                         <div className="flex flex-row items-center space-x-4">
//                           <div className="flex flex-col justify-end text-right">
//                             <div className="text-sm font-bold">{video.videoTitle}</div>
//                             <div className="text-sm text-gray-500">{video.videoLength}</div>
//                           </div>
//                         </div>
//                       </div>
//                     ))
//                   ) : (
//                     <div className="text-center p-5 text-gray-300 text-sm ml-6">No videos selected</div>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <div className="flex justify-between mt-4">
//               <button type="button" onClick={() => setIsSelectingVideos(false)} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
//                 Cancel
//               </button>
//               <button type="button" className="bg-cyan-800 text-white px-4 py-2 rounded-lg" onClick={() => setIsSelectingVideos(false)}>
//                 Done
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default VideoPlaylistModal;


// // update caode --> 06 oct 2024
// // src/components/admin/VideoSetting/VideoPlaylistModal.tsx

// import React, { useState } from 'react';
// import { FaSearch } from 'react-icons/fa';
// import useVideoUpload from '../../../hooks/AdminDashboard/useVideoUpload';

// interface VideoData {
//   videoId: string;
//   videoTitle: string;
//   videoThumbnail: string;
//   videoLength: string;
// }

// interface VideoPlaylistModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   handleCreatePlaylist: (playlistData: { title: string; description: string; visibility: string; sortOrder: string, videos: VideoData[] }) => void;
// }

// const VideoPlaylistModal: React.FC<VideoPlaylistModalProps> = ({ isOpen, onClose, handleCreatePlaylist }) => {
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [visibility, setVisibility] = useState('Public');
//   const [sortOrder, setSortOrder] = useState('Date published (newest)');
//   const [selectedVideos, setSelectedVideos] = useState<VideoData[]>([]);
//   const [isSelectingVideos, setIsSelectingVideos] = useState(false);

//   const { videos } = useVideoUpload(); // Fetch available videos

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     if (title) {
//       handleCreatePlaylist({ title, description, visibility, sortOrder, videos: selectedVideos });
//       onClose(); // Close modal after submission
//     } else {
//       alert('Title is required');
//     }
//   };

//   const handleVideoSelect = (video: VideoData) => {
//     if (selectedVideos.some(v => v.videoId === video.videoId)) {
//       setSelectedVideos(selectedVideos.filter(v => v.videoId !== video.videoId));
//     } else {
//       setSelectedVideos([...selectedVideos, video]);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
//       <div className="bg-white w-full max-w-[800px] h-auto max-h-screen mx-4 p-6 rounded-lg shadow-lg">
//         {/* Step 1: Playlist Information */}
//         {!isSelectingVideos ? (
//           <>
//             <h2 className="text-2xl font-semibold text-cyan-800 mb-2">Create a new playlist</h2>
//             <form onSubmit={handleSubmit}>
//               <div className="mb-5">
//                 <label className="block text-cyan-800 font-semibold mb-2">Title (required)</label>
//                 <input
//                   type="text"
//                   value={title}
//                   onChange={(e) => setTitle(e.target.value)}
//                   className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   placeholder="Add title"
//                   required
//                 />
//               </div>

//               <div className="mb-5">
//                 <label className="block text-cyan-800 font-semibold mb-2">Description</label>
//                 <textarea
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                   className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   placeholder="Add description"
//                   rows={3}
//                   maxLength={5000}
//                 />
//               </div>

//               <div className="mb-5">
//                 <label className="block text-cyan-800 font-semibold mb-2">Visibility and Sort Order</label>
//                 <div className="flex space-x-4">
//                   <select
//                     value={visibility}
//                     onChange={(e) => setVisibility(e.target.value)}
//                     className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   >
//                     <option value="Public">Public</option>
//                     <option value="Private">Private</option>
//                   </select>

//                   <select
//                     value={sortOrder}
//                     onChange={(e) => setSortOrder(e.target.value)}
//                     className="block w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg"
//                   >
//                     <option value="Date published (newest)">Date published (newest)</option>
//                     <option value="Date published (oldest)">Date published (oldest)</option>
//                     <option value="Most popular">Most popular</option>
//                   </select>
//                 </div>
//               </div>

//               {/* Step 1: Add Videos Button */}
//               <div className="mb-5">
//                 <button
//                   type="button"
//                   className="bg-cyan-800 text-white px-4 py-2 rounded-lg flex items-center"
//                   onClick={() => setIsSelectingVideos(true)} // Switch to video selection view
//                 >
//                   Add videos
//                 </button>
//               </div>

//               <div className="flex justify-between mt-4">
//                 <button type="button" onClick={onClose} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
//                   Cancel
//                 </button>
//                 <button type="submit" className="bg-cyan-800 text-white px-4 py-2 rounded-lg">
//                   Create
//                 </button>
//               </div>
//             </form>
//           </>
//         ) : (
//           // Step 2: Video Selection Modal
//           <>
//             <h2 className="text-xl font-semibold text-cyan-800 mb-2">Add videos to your playlist</h2>
//             <div className="flex justify-between items-start w-full text-sm">
//               <div className="w-[400px]">
//                 <div className="flex justify-between items-center border py-1 px-4">
//                     {/* search bar and content title */}
//                     <div className="font-semibold text-sm">Video Content</div>
//                     <div className="relative">
//                     <input
//                         type="input"
//                         className="w-[200px] py-1 text-sm border-gray-300"
//                         placeholder="Search videos"
//                     />
//                         <FaSearch className="absolute right-3 top-2 text-gray-500 text-xs" />
//                     </div>
//                 </div>

//                  {/* Selected Playlist Section */}
//                 <div className="bg-gray-100 overflow-y-auto space-y-2 max-h-[300px]">
//                   {videos.map((video) => (
//                     <div
//                       key={video.videoId}
//                       className="flex justify-between items-center p-2 rounded cursor-pointer"
//                       onClick={() => handleVideoSelect(video)}
//                     >
//                       <input
//                         type="checkbox"
//                         checked={selectedVideos.some((v) => v.videoId === video.videoId)}
//                         onChange={() => handleVideoSelect(video)}
//                         className="mr-2 accent-cyan-800"
//                       />
//                       <img
//                         src={video.videoThumbnail}
//                         alt={video.videoTitle}
//                         className="w-16 h-9 mr-2"
//                       />
//                       <div className="flex flex-row items-center space-x-4">
//                         <div className="flex flex-col justify-start">
//                           <div>{video.videoTitle}</div>
//                           <div className="text-sm text-gray-500">{video.videoLength}</div>
//                         </div>
//                         <div>{video.videoCategory}</div>
//                         <div>{video.videoCategoryArray}</div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>

//               {/* Show selected videos */}
//               <div className="w-1/2">
//                 <div className="border py-2 px-4 font-semibold text-sm">Videos to add</div>
//                 <div className="overflow-y-auto max-h-[300px]">
//                   {selectedVideos.length > 0 ? (
//                     selectedVideos.map((video) => (
//                       <div
//                         key={video.videoId}
//                         className="flex justify-between items-center p-2 border-b"
//                       >
//                         <img
//                           src={video.videoThumbnail}
//                           alt={video.videoTitle}
//                           className="w-16 h-9 mr-2"
//                         />
//                         <div className="flex flex-row items-center space-x-4">
//                           <div className="flex flex-col justify-start">
//                             <div>{video.videoTitle}</div>
//                             <div className="text-sm text-gray-500">{video.videoLength}</div>
//                           </div>
//                         </div>
//                       </div>
//                     ))
//                   ) : (
//                     <div className="text-center p-5 text-gray-300 text-sm ml-6">
//                         No videos selected
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <div className="flex justify-between mt-4">
//               <button type="button" onClick={() => setIsSelectingVideos(false)} className="text-cyan-800 px-6 py-2 rounded-lg border border-cyan-800">
//                 Cancel
//               </button>
//               <button type="button" className="bg-cyan-800 text-white px-4 py-2 rounded-lg" onClick={() => setIsSelectingVideos(false)}>
//                 Done
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default VideoPlaylistModal;
