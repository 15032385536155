// src/components/client/Dashboard/UserCalendar.tsx

import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './UserCalendar.css';

import { FaClock } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { useTheme } from '../../../context/ThemeContext';
import useProgramCalendar, { ProgramEvent } from '../../../hooks/Dashboard/useUserProgramCalender';
import useEventData, { getEventImagePath, formatDate, formatTime } from '../../../hooks/MiniApp/useEventData'; // Add this import
import { useEnv } from '../../../context/EnvContext';
import { motion, AnimatePresence } from 'framer-motion';

const timezones = [
  { label: 'Kuala Lumpur', value: 'Asia/Kuala_Lumpur' },
  { label: 'UTC', value: 'UTC' },
  { label: 'New York', value: 'America/New_York' },
  { label: 'London', value: 'Europe/London' },
  { label: 'Tokyo', value: 'Asia/Tokyo' },
];

const UserCalendar: React.FC<{ email: string }> = ({ email }) => {
  const { theme } = useTheme();
  const { apiUrl } = useEnv(); 
  const { getSlotMessage } = useEventData();
  const { programEvents,getStatusStyle } = useProgramCalendar(email);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<ProgramEvent | null>(null);
  const [currentTime, setCurrentTime] = useState<string>(new Date().toLocaleString());
  const [timezone, setTimezone] = useState<string>('Asia/Kuala_Lumpur');
  const [currentEventIndex, setCurrentEventIndex] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleString('en-US', { timeZone: timezone }));
    }, 1000);

    return () => clearInterval(timer);
  }, [timezone]);

  const handleDateClick = (date: Date) => {
    const event = programEvents.find(
      (event: ProgramEvent) =>
        new Date(event.startDate).toDateString() === date.toDateString() ||
        new Date(event.endDate).toDateString() === date.toDateString() ||
        (date > new Date(event.startDate) && date < new Date(event.endDate))
    );
    if (event) {
      setSelectedEvent(event);
      setModalOpen(true);
    }
    setSelectedDate(date);
  };

  const hasEvent = (date: Date) => {
    return programEvents.some(
      (event: ProgramEvent) =>
        new Date(event.startDate).toDateString() === date.toDateString() ||
        new Date(event.endDate).toDateString() === date.toDateString() ||
        (date > new Date(event.startDate) && date < new Date(event.endDate))
    );
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  const handleTimezoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimezone(event.target.value);
  };

  const handleNextEvent = () => {
    const nextIndex = (currentEventIndex + 1) % programEvents.length;
    setSelectedEvent(programEvents[nextIndex]);
    setCurrentEventIndex(nextIndex);
    setModalOpen(true);
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="bg-white h-full rounded-lg shadow-md p-8 relative">
      <div className="flex flex-col items-center">

        {/* R One Calender Title */}
        <div className="flex justify-center items-center mb-4">
          <h1
            className="text-2xl font-bold md:text-xl xs:text-xl"
            style={styles.title}
          >
            R One Event Calendar
          </h1>
          <div className="relative group ml-2">
            <AiOutlineInfoCircle 
              className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer w-4 h-4"
              aria-hidden="true"
            />
            {/* Tooltip */}
            <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white sm:right-0 xs:right-0"
              style={styles.button}>
              Plan ahead and check upcoming month events with the R One Event Calendar, 
              allowing you to schedule and attend events seamlessly.
            </div>
          </div>
        </div>

        {/* Real Time and Date and Tiem Zone */}
        <div className="text-lg text-gray-600 mb-4">
          <div className="flex items-center py-2 px-4 rounded-lg md:text-base md:text-center sm:text-base sm:text-center xs:text-sm xs:text-center" style={styles.button}>
            <FaClock className="mr-2 md:w-8 h-8 sm:w-10 sm:h-10 xs:w-8 xs:h-8" />
            <span className="font-medium">{currentTime} ({timezone})</span>
          </div>
        </div>
        <div className="mb-4 md:text-sm sm:text-base xs:text-sm">
          <label htmlFor="timezone" 
            className="mr-2 font-medium" style={styles.title}>Select Timezone:</label>
          <div className="relative inline-block">
            <select
              id="timezone"
              value={timezone}
              onChange={handleTimezoneChange}
              className="p-2 border rounded text-center bg-gray-100 hover:bg-gray-200 transition-colors duration-300"
              style={styles.title}
            >
              {timezones.map((tz) => (
                <option key={tz.value} value={tz.value}>
                  {tz.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="border-2 rounded-xl ">
        <Calendar
          onClickDay={handleDateClick}
          tileClassName={({ date }) => (hasEvent(date) ? 'event-day' : '')}
          value={selectedDate}
          className="custom-calendar"
        />
        </div>
      </div>
      {modalOpen && selectedEvent && (
        <AnimatePresence>
          <motion.div 
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
          >
            <motion.div 
              className="bg-white rounded-lg shadow-lg p-6 w-auto h-auto max-h-screen max-w-[600px] md:w-[600px] sm:w-[395px] xs:w-[360px] xs:h-[600px]"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold sm:text-lg xs:text-base"
                  style={styles.title}>Event Details</h2>
                <button onClick={closeModal} className="text-xl">&times;</button>
              </div>
              <div className="w-auto h-auto max-h-[580px] mt-4 space-y-2 overflow-y-auto xs:h-[460px]">
                <img
                  src={getEventImagePath(selectedEvent.image, apiUrl)}
                  alt={selectedEvent.name}
                  className="w-[280px] h-auto object-cover rounded-lg mb-4 md:w-[250px] sm:w-[250px]"
                />
                <div className="flex justify-left items-center">
                  <h2 className="font-semibold text-xl my-2 md:text-lg sm:text-lg xs:text-base" style={styles.title}>{selectedEvent.name}</h2>
                  <div
                    className="py-1 px-3 rounded-lg my-2 mx-4 text-lg md:text-sm sm:text-sm xs:text-sm"
                    style={{ backgroundColor: getStatusStyle(selectedEvent.status).backgroundColor, color: getStatusStyle(selectedEvent.status).color }}
                  >
                    {getStatusStyle(selectedEvent.status).label}
                  </div>
                </div>
                <table className="table-auto w-full text-left md:text-sm sm:text-sm xs:text-xs" style={styles.text}>
                  <tbody>
                    <tr>
                      <td className="font-bold">Date:</td>
                      <td>{formatDate(selectedEvent.startDate, selectedEvent.time)} - {formatDate(selectedEvent.endDate, selectedEvent.time)}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">Time:</td>
                      <td>{formatTime(selectedEvent.time)}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">Location:</td>
                      <td>{selectedEvent.location}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">Amount:</td>
                      <td>
                        {selectedEvent.amount === 0 
                          ? 'Free of Charges' 
                          : `${selectedEvent.currency} ${selectedEvent.amount}`}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">Slot:</td>
                      <td>{getSlotMessage(selectedEvent.slot)}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">Requirement:</td>
                      <ul>
                        <li>▪️ {selectedEvent.lotSize} lot Size</li>
                        <li>▪️ {selectedEvent.deposit} USD </li>
                        <li>▪️ {selectedEvent.broker}</li>
                      </ul>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end py-4">
                <button 
                  onClick={handleNextEvent}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition-colors xs:text-sm"
                  style={styles.button}
                >
                  Next Event
                </button>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default UserCalendar;








// // udpate code --> 09 july 2024
// // src/components/client/Dashboard/UserCalender.tsx

// import React, { useState } from 'react';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import './UserCalendar.css';
// import useUserStage from '../../../hooks/Dashboard/useUserStage';
// import useProgramCalendar, { ProgramEvent } from '../../../hooks/Dashboard/useUserProgramCalender';

// const UserCalendar: React.FC<{ email: string }> = ({ email }) => {
//   const [selectedDate, setSelectedDate] = useState<Date | null>(null);
//   const [modalOpen, setModalOpen] = useState<boolean>(false);
//   const [selectedEvent, setSelectedEvent] = useState<ProgramEvent | null>(null);
//   const { isGuest } = useUserStage();
//   const { programEvents } = useProgramCalendar(email);

//   const handleDateClick = (date: Date) => {
//     const event = programEvents.find(
//       (event: ProgramEvent) =>
//         new Date(event.startDate).toDateString() === date.toDateString() ||
//         new Date(event.endDate).toDateString() === date.toDateString()
//     );
//     if (event) {
//       setSelectedEvent(event);
//       setModalOpen(true);
//     }
//     setSelectedDate(date);
//   };

//   const hasEvent = (date: Date) => {
//     return programEvents.some(
//       (event: ProgramEvent) =>
//         new Date(event.startDate).toDateString() === date.toDateString() ||
//         new Date(event.endDate).toDateString() === date.toDateString()
//     );
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//     setSelectedEvent(null);
//   };

//   return (
//     <div className={`bg-white h-full rounded-lg shadow-md p-4 relative ${isGuest ? 'opacity-50' : ''}`}>
//       <div className="flex justify-center items-center h-full">
//         <Calendar
//           onClickDay={handleDateClick}
//           tileClassName={({ date }) => (hasEvent(date) ? 'event-day' : '')}
//           value={selectedDate}
//           className="custom-calendar"
//         />
//       </div>
//       {isGuest && (
//         <div className="absolute inset-0 bg-gray-300 bg-opacity-75 flex items-center justify-center rounded-lg">
//           <span className="text-red-600 font-bold">Enroll classes to access the calendar.</span>
//         </div>
//       )}
//       {modalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-1/3">
//             <div className="flex justify-between items-center">
//               <h2 className="text-xl font-bold">Event Details</h2>
//               <button onClick={closeModal} className="text-xl">&times;</button>
//             </div>
//             <div className="mt-4">
//               {selectedEvent && (
//                 <div>
//                   <p className="font-semibold">Title: {selectedEvent.name}</p>
//                   <p>Date: {new Date(selectedEvent.startDate).toLocaleDateString()} - {new Date(selectedEvent.endDate).toLocaleDateString()}</p>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserCalendar;