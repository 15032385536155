// src/components/admin/AdminSetting/AdminAddAccountModal.tsx

import React, { useState, useEffect } from 'react';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs'; 
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { PiPasswordFill } from "react-icons/pi";
import { RiMailAddLine } from "react-icons/ri";
import { useAdminUsers } from '../../../hooks/AdminDashboard/useAdminRoles';
import AssignAdminEmail from '../AdminSetting/AdminUserAssignEmail';

interface AdminAddAccountModalProps {
  onClose: () => void;
  initialData?: any;
}

const AdminAddAccountModal: React.FC<AdminAddAccountModalProps> = ({ onClose, initialData }) => {
  const { addAdminUser, updateAdminUser, getDefaultPermissions } = useAdminUsers();
  const [fullAccessOn, setFullAccessOn] = useState<boolean>(true);
  const [overviewOn, setOverviewOn] = useState<boolean>(true);
  const [userDataOn, setUserDataOn] = useState<boolean>(true);
  const [emailScheduleOn, setEmailScheduleOn] = useState<boolean>(true);
  const [eventSettingOn, setEventSettingOn] = useState<boolean>(true);
  const [eventNotificationOn, setEventNotificationOn] = useState<boolean>(true);
  const [fundedProgramOn, setFundedProgramOn] = useState<boolean>(true);
  const [productSubOn, setProductSubOn] = useState<boolean>(true);
  const [videoManageOn, setvideoManageOn] = useState<boolean>(true);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  // Individual collapse states for each menu category
  const [isAccessMenuCollapsed, setIsAccessMenuCollapsed] = useState(false);
  const [isOverviewMenuCollapsed, setIsOverviewMenuCollapsed] = useState(false);
  const [isUserDataMenuCollapsed, setIsUserDataMenuCollapsed] = useState(false);
  const [isEmailScheduleMenuCollapsed, setIsEmailScheduleMenuCollapsed] = useState(false);
  const [isEventSettingMenuCollapsed, setIsEventSettingMenuCollapsed] = useState(false);
  const [isEventNotificationMenuCollapsed, setIsEventNotificationMenuCollapsed] = useState(false);
  const [isFundedProgramMenuCollapsed, setIsFundedProgramMenuCollapsed] = useState(false);
  const [isProductSubMenuCollapsed, setIsProductSubMenuCollapsed] = useState(false);
  const [isVideoManageMenuCollapsed, setIsVideoManageMenuCollapsed] = useState(false);

  const [toggles, setToggles] = useState({
    fullAccess: true,
    overview: false,
    userData: false,
    emailSchedule: false,
    eventSetting: false,
    eventNotification: false,
    productSubscription: false,
    fundedProgram: false,
    videoManage: false,
    adminSetting: false,
  });

  const [overviewToggles, setOverviewToggles] = useState({
    revenuStats: false,
    earningStats: false,
    salesStats: false,
  })

  const [userDataToggles, setUserDataToggles] = useState({
    addData: false,
    editData: false,
    deleteData: false,
  })

  const [emailScheduleToggles, setEmailScheduleToggles] = useState({
    addLogic: false,
    editLogic: false,
    deleteLogic: false,
    addEmail: false,
    editEmail: false,
    deleteEmail: false,
    changeStatus: false,
  })

  const [eventSettingToggles, setEventSettingToggles] = useState({
    addEvent: false,
    editEvent: false,
    deleteEvent: false,
  })

  const [eventNotificationToggles, setEventNotificationToggles] = useState({
    approveEventTicket: false,
    rejectEventTicket: false,
    deleteEventTicket: false,
  })

  const [fundedProgramToggles, setFundedProgramToggles] = useState({
    addFund: false,
    editFund: false,
    deleteFund: false,
    approveFund: false,
    rejectFund: false,
  })

  const [productSubToggles, setProductSubToggles] = useState({
    addSub: false,
    editSub: false,
    deleteSub: false,
    approveSub: false,
    rejectSub: false,
  })

  const [videoManageToggles, setVideoManageToggles] = useState({
    uploadVideo: false,
    removeVideo: false,
    editVideo: false,
    addPlaylist: false,
    editPlaylist: false,
    removePlaylist: false,
  })

  // Define types now, after defining the states
  type GeneralToggles = keyof typeof toggles;
  type OverviewToggles = keyof typeof overviewToggles;
  type UserDataToggles = keyof typeof userDataToggles;
  type EmailScheduleToggles = keyof typeof emailScheduleToggles;
  type EventSettingToggles = keyof typeof eventSettingToggles;
  type EventNotificationToggles = keyof typeof eventNotificationToggles;
  type FundedProgramToggles = keyof typeof fundedProgramToggles;
  type ProductSubToggles = keyof typeof productSubToggles;
  type VideoManageToggles = keyof typeof videoManageToggles;

  const [form, setForm] = useState({
    roleId: '',
    email: '',
    name: '',
    password: '',
    role: '',
    roleAssign: '',
    status: '',
  });

  useEffect(() => {
    if (initialData) {
      setForm({
        roleId: initialData.roleId,
        email: initialData.email,
        name: initialData.name,
        password: initialData.password || '', 
        role: initialData.role,
        roleAssign: initialData.roleAssign,
        status: initialData.status,
      });
  
      // Set initial permissions state for toggles based on initialData.rolePermission
      if (initialData.rolePermission) {
        setToggles(initialData.rolePermission);
        setFullAccessOn(initialData.rolePermission.fullAccess === 'enable');
      }
    }
  }, [initialData]);

   // useEffect to load initial data for form and toggles
   useEffect(() => {
    if (initialData) {
      const savedPermissions = initialData.rolePermission;

      setForm({
        roleId: initialData.roleId,
        email: initialData.email,
        name: initialData.name,
        password: initialData.password || '',
        role: initialData.role,
        roleAssign: initialData.roleAssign,
        status: initialData.status,
      });

      setToggles({
        fullAccess: savedPermissions.fullAccess === 'enable',
        overview: savedPermissions.overview === 'enable',
        userData: savedPermissions.userData?.enable === 'enable',
        emailSchedule: savedPermissions.emailSchedule?.enable === 'enable',
        eventSetting: savedPermissions.eventSetting?.enable === 'enable',
        eventNotification: savedPermissions.eventNotification?.enable === 'enable',
        productSubscription: savedPermissions.productSubscription === 'enable',
        fundedProgram: savedPermissions.fundedProgram?.enable === 'enable',
        videoManage: savedPermissions.videoManage === 'enable',
        adminSetting: savedPermissions.adminSetting === 'enable',
      });
  
      setFullAccessOn(savedPermissions.fullAccess === 'enable');
      setOverviewOn(savedPermissions.overview === 'enable');
      setUserDataOn(savedPermissions.userData?.enable === 'enable');
      setEmailScheduleOn(savedPermissions.emailSchedule?.enable === 'enable');
      setEventSettingOn(savedPermissions.eventSetting?.enable === 'enable');
      setEventNotificationOn(savedPermissions.eventNotification?.enable === 'enable');
      setFundedProgramOn(savedPermissions.fundedProgram?.enable === 'enable');
    }
  }, [initialData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  
    if (name === 'role') {
      // Apply default permissions when role is selected
      const defaultPermissions = getDefaultPermissions(value);
      setToggles(defaultPermissions);
      setFullAccessOn(defaultPermissions.fullAccess);
    }
  };  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Append @t1trader.com to the email if not already present
    const emailWithDomain = form.email.includes('@r1trader.com')
      ? form.email
      : `${form.email}@r1trader.com`;
  
    const rolePermission = {
      fullAccess: fullAccessOn ? 'enable' : 'disable',
      overview: toggles.overview ? 'enable' : 'disable',
  
      // If any userData permission is enabled, store the sub-object, otherwise store 'disable'
      userData: Object.values(userDataToggles).some(toggle => toggle) 
        ? {
            enable: 'enable',
            addData: userDataToggles.addData ? 'enable' : 'disable',
            editData: userDataToggles.editData ? 'enable' : 'disable',
            deleteData: userDataToggles.deleteData ? 'enable' : 'disable',
        } : 'disable',
  
      // For eventSetting, if any toggle is enabled, save sub-object, otherwise store 'disable'
      eventSetting: Object.values(eventSettingToggles).some(toggle => toggle) 
        ? {
            enable: 'enable',
            addEvent: eventSettingToggles.addEvent ? 'enable' : 'disable',
            editEvent: eventSettingToggles.editEvent ? 'enable' : 'disable',
            deleteEvent: eventSettingToggles.deleteEvent ? 'enable' : 'disable',
        } : 'disable',
  
      // Repeat the same pattern for emailSchedule and other groups
      emailSchedule: Object.values(emailScheduleToggles).some(toggle => toggle) 
        ? {
            enable: 'enable',
            addLogic: emailScheduleToggles.addLogic ? 'enable' : 'disable',
            editLogic: emailScheduleToggles.editLogic ? 'enable' : 'disable',
            deleteLogic: emailScheduleToggles.deleteLogic ? 'enable' : 'disable',
        } : 'disable',
  
      // Repeat for eventNotification
      eventNotification: Object.values(eventNotificationToggles).some(toggle => toggle) 
        ? {
            enable: 'enable',
            approveEventTicket: eventNotificationToggles.approveEventTicket ? 'enable' : 'disable',
            rejectEventTicket: eventNotificationToggles.rejectEventTicket ? 'enable' : 'disable',
            deleteEventTicket: eventNotificationToggles.deleteEventTicket ? 'enable' : 'disable',
        } : 'disable',
  
      // Repeat for fundedProgram
      fundedProgram: Object.values(fundedProgramToggles).some(toggle => toggle) 
        ? {
            enable: 'enable',
            addFund: fundedProgramToggles.addFund ? 'enable' : 'disable',
            editFund: fundedProgramToggles.editFund ? 'enable' : 'disable',
            deleteFund: fundedProgramToggles.deleteFund ? 'enable' : 'disable',
            approveFund: fundedProgramToggles.approveFund ? 'enable' : 'disable',
            rejectFund: fundedProgramToggles.rejectFund ? 'enable' : 'disable',
        } : 'disable',
  
      productSubscription: Object.values(productSubToggles).some(toggle => toggle)
        ? {
            enable:'enable',
            addSub: productSubToggles.addSub ? 'enable' : 'disable',
            editFund: productSubToggles.editSub ? 'enable' : 'disable',
            deleteFund: productSubToggles.deleteSub ? 'enable' : 'disable',
            approveFund: productSubToggles.approveSub ? 'enable' : 'disable',
            rejectFund: productSubToggles.rejectSub ? 'enable' : 'disable',
        } : 'disable',

      videoManage: Object.values(videoManageToggles).some(toggle => toggle)
        ? {
            enable:'enable',
            uploadVideo: videoManageToggles.uploadVideo ? 'enable' : 'disable',
            editVideo: videoManageToggles.editVideo ? 'enable' : 'disable',
            removeVideo: videoManageToggles.removeVideo ? 'enable' : 'disable',
            addPlaylist: videoManageToggles.addPlaylist ? 'enable' : 'disable',
            editPlaylist: videoManageToggles.editPlaylist ? 'enable' : 'disable',
            removePlaylist: videoManageToggles.removePlaylist ? 'enable' : 'disable',
        } : 'disable',

      adminSetting: toggles.adminSetting ? 'enable' : 'disable',
    };
  
    const formData = {
      ...form,
      email: emailWithDomain,
      rolePermission, 
    };
  
    try {
      if (initialData) {
        // If editing, update the existing user
        await updateAdminUser(formData); 
      } else {
        // If not editing, create a new user
        await addAdminUser(formData); 
      }
      onClose(); 
    } catch (error) {
      console.error('Error:', error);
    }
  };   

  // Toggle individual buttons for both general toggles and overview toggles
  const handleToggle = (
    feature: 
      GeneralToggles | OverviewToggles | UserDataToggles | 
      EmailScheduleToggles | EventSettingToggles | EventNotificationToggles |
      FundedProgramToggles | ProductSubToggles | VideoManageToggles
    ) => {
    if (!fullAccessOn) {
      if (feature in toggles) {
        // Handle general toggles
        setToggles((prevState) => ({
          ...prevState,
          [feature as GeneralToggles]: !prevState[feature as GeneralToggles], 
        }));

        // If toggling overview, sync overviewOn state
        if (feature === 'overview') {
          setOverviewOn((prev) => !prev);
        }
      } else if (feature in overviewToggles) {
        // Handle overview-specific toggles
        setOverviewToggles((prevState) => ({
          ...prevState,
          [feature as OverviewToggles]: !prevState[feature as OverviewToggles], 
        }));

        // If toggling user data, sync user data state
        if (feature === 'userData') {
          setUserDataOn((prev) => !prev);
        }
      } else if (feature in userDataToggles) {
        // Handle user data toggles
        setUserDataToggles((prevState) => ({
          ...prevState,
          [feature as UserDataToggles]: !prevState[feature as UserDataToggles], 
        }));

        // If toggling email schedule, sync email schedule state
        if (feature === 'emailSchedule') {
          setEmailScheduleOn((prev) => !prev);
        }
      } else if (feature in emailScheduleToggles) {
        // Handle email schedule toggles
        setEmailScheduleToggles((prevState) => ({
          ...prevState,
          [feature as EmailScheduleToggles]: !prevState[feature as EmailScheduleToggles], 
        }));

        // If toggling event setting, sync event setting state
        if (feature === 'eventSetting') {
          setEventSettingOn((prev) => !prev);
        }
      } else if (feature in eventSettingToggles) {
        // Handle event setting toggles
        setEventSettingToggles((prevState) => ({
          ...prevState,
          [feature as EventSettingToggles]: !prevState[feature as EventSettingToggles], 
        }));

        // If toggling event notification, sync event notification state
        if (feature === 'eventNotification') {
          setEventNotificationOn((prev) => !prev);
        }
      } else if (feature in eventNotificationToggles) {
        // Handle event notification toggles
        setEventNotificationToggles((prevState) => ({
          ...prevState,
          [feature as EventNotificationToggles]: !prevState[feature as EventNotificationToggles], 
        }));

        // If toggling funded program, sync funded program state
        if (feature === 'fundedProgram') {
          setFundedProgramOn((prev) => !prev);
        }
      } else if (feature in fundedProgramToggles) {
        // Handle funded program toggles
        setFundedProgramToggles((prevState) => ({
          ...prevState,
          [feature as FundedProgramToggles]: !prevState[feature as FundedProgramToggles], 
        }));

        // If toggling product subscription, sync product subscription state
        if (feature === 'productSubscription') {
          setProductSubOn((prev) => !prev);
        }
      } else if (feature in productSubToggles) {
        // Handle product subscription toggles
        setProductSubToggles((prevState) => ({
          ...prevState,
          [feature as ProductSubToggles]: !prevState[feature as ProductSubToggles], 
        }));

        // If toggling video manage, sync video manage state
        if (feature === 'videoManage') {
          setvideoManageOn((prev) => !prev);
        }
      } else if (feature in videoManageToggles) {
        // Handle video manage toggles
        setVideoManageToggles((prevState) => ({
          ...prevState,
          [feature as VideoManageToggles]: !prevState[feature as VideoManageToggles], 
        }));
      }
    }
  };

  // Handle collapse/expand for each individual category
  const toggleAccessMenu = () => setIsAccessMenuCollapsed(!isAccessMenuCollapsed);
  const toggleOverviewMenu = () => setIsOverviewMenuCollapsed(!isOverviewMenuCollapsed);
  const toggleUserDataMenu = () => setIsUserDataMenuCollapsed(!isUserDataMenuCollapsed);
  const toggleEmailScheduleMenu = () => setIsEmailScheduleMenuCollapsed(!isEmailScheduleMenuCollapsed); 
  const toggleEventSettingMenu = () => setIsEventSettingMenuCollapsed(!isEventSettingMenuCollapsed);
  const toggleEventNotificationMenu = () => setIsEventNotificationMenuCollapsed(!isEventNotificationMenuCollapsed); 
  const toggleFundedProgramMenu = () => setIsFundedProgramMenuCollapsed(!isFundedProgramMenuCollapsed);
  const toggleProductSubscriptionMenu = () => setIsProductSubMenuCollapsed(!isProductSubMenuCollapsed);
  const toggleVideoManageMenu = () => setIsVideoManageMenuCollapsed(!isVideoManageMenuCollapsed);

  const handleFullAccessToggle = () => {
    setFullAccessOn((prev) => !prev); 
    if (fullAccessOn) {
      // If full access is turned off, turn all individual options on
      setToggles({
        fullAccess: false,
        overview: false,
        userData: false,
        emailSchedule: false,
        eventSetting: false,
        eventNotification: false,
        productSubscription: false,
        fundedProgram: false,
        videoManage: false,
        adminSetting: false,
      });
      setIsAccessMenuCollapsed(false);
      setIsOverviewMenuCollapsed(false);
      setIsUserDataMenuCollapsed(false);
      setIsEmailScheduleMenuCollapsed(false);
      setIsEventSettingMenuCollapsed(false);
      setIsEventNotificationMenuCollapsed(false);
      setIsFundedProgramMenuCollapsed(false);
      setIsProductSubMenuCollapsed(false);
      setIsVideoManageMenuCollapsed(false);
    } else {
      // If full access is turned on, disable all individual options
      setToggles({
        fullAccess: true,
        overview: false,
        userData: false,
        emailSchedule: false,
        eventSetting: false,
        eventNotification: false,
        productSubscription: false,
        fundedProgram: false,
        videoManage: false,
        adminSetting: false,
      });
      setIsAccessMenuCollapsed(true);
      setIsOverviewMenuCollapsed(true);
      setIsUserDataMenuCollapsed(true);
      setIsEmailScheduleMenuCollapsed(true);
      setIsEventSettingMenuCollapsed(true);
      setIsEventNotificationMenuCollapsed(true);
      setIsFundedProgramMenuCollapsed(true);
      setIsProductSubMenuCollapsed(true);
      setIsVideoManageMenuCollapsed(true);
    }
  };

  const handleOpenEmailModal = () => {
    setIsEmailModalOpen(true);
  };
  
  const handleCloseEmailModal = () => {
    setIsEmailModalOpen(false);
  };
  
  const handleAddEmail = (emailList: string[]) => {
    setForm({
      ...form,
      roleAssign: emailList.filter(email => email).join(', '),
    });
    setIsEmailModalOpen(false);  
  };
  
  // Function to generate a random password
  const generatePassword = () => {
    const digits = Math.floor(1000 + Math.random() * 9000).toString(); 
    const chars = Math.random().toString(36).substring(2, 6).toUpperCase(); 
    return digits + chars;
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full h-auto max-h-screen">
        <h2 className="text-xl font-bold mb-4 text-cyan-800">
          {initialData ? 'Edit Account' : 'Add New Account'}
        </h2>
        <div className="flex flex-row space-x-2">
          {/* left section */}
          <div className="border p-4 min-w-[350px] rounded-lg">
            <form onSubmit={handleSubmit}>
              {/* role id field */}
              <div className="mb-4">
                <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
                    Role ID
                </label>
                <input
                  type="text"
                  name="roleId"
                  value={form.roleId}
                  onChange={handleChange}
                  className="text-xs w-full px-4 py-2 border rounded-md"
                  disabled
                />
              </div>

              {/* email field */}
              <div className="mb-4">
                <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
                  Account Email
                </label>
                <div className="flex justify-between items-center">
                  <input
                    type="email"
                    name="email"
                    value={form.email.replace('@r1trader.com', '')}
                    onChange={handleChange}
                    className="text-xs w-full px-4 py-2 border rounded-md"
                    required
                  />
                  <span className="text-xs ml-4">
                    @r1trader.com
                  </span>
                </div>
              </div>

              {/* role account password field */}
              <div className="mb-4">
                <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
                  Account Password
                </label>
                <div className="flex">
                  <input
                    type="text"
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                    className="text-xs w-full px-4 py-2 border rounded-md"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setForm({ ...form, password: generatePassword() })} 
                    className="ml-2 text-cyan-800"
                  >
                    <PiPasswordFill className="text-lg" />
                  </button>
                </div>
              </div>

              {/* name field */}
              <div className="mb-4">
                <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
                  Account Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  className="text-xs w-full px-4 py-2 border rounded-md"
                  required
                />
              </div>

              {/* role field */}
              <div className="mb-4">
                <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
                  Assign Role
                </label>
                <select
                  name="role"
                  value={form.role}
                  onChange={handleChange}
                  className="text-xs text-gray-400 w-full px-4 py-2 border rounded-md"
                >
                  <option value="">Select a Role</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                  <option value="marketing">Marketing</option>
                  <option value="sale">Sale</option>
                  <option value="support">Support</option>
                  <option value="assistant">Assistant</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {/* assign role to user email field */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
                    Assign To Users
                  </label>
                  <div 
                    className="flex justify-normal items-center text-sm text-cyan-800 cursor-pointer"
                    onClick={handleOpenEmailModal}
                  >
                    <RiMailAddLine/>
                    <span className="text-xs ml-2">add email</span>
                  </div>
                </div>
                <input
                  type="text"
                  name="roleAssign"
                  value={
                    form.roleAssign
                      ? `${form.roleAssign.split(', ').length} emails assigned`
                      : 'No emails assigned'
                  }
                  onChange={handleChange}
                  onClick={handleOpenEmailModal}
                  className="text-xs w-full px-4 py-2 border rounded-md"
                  readOnly
                  disabled
                />
              </div>

              {/* status field */}
              <div className="mb-4">
                <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
                  Status
                </label>
                <select
                  name="status"
                  value={form.status}
                  onChange={handleChange}
                  className="text-xs w-full px-4 py-2 border rounded-md"
                >
                  <option value="">Select a Status</option>
                  <option value="Active">Active</option>
                  <option value="Deactive">Deactive</option>
                </select>
              </div>
            </form>
          </div>

          {/* right section */}
          <div className="border w-[350px] h-auto max-h-[550px] rounded-lg overflow-y-auto">
            <div className="font-bold text-cyan-800 text-sm px-4 pt-4 mb-1">
              Role Manager
            </div>
            
            {/* catergory for menu accessment */}
            <div className="bg-gray-100 px-4 py-2">
              <div className="flex justify-between items-center">
                <label className="text-cyan-800 text-xs font-semibold">Access Menu</label>
                <button onClick={toggleAccessMenu} className="text-cyan-800">
                  {isAccessMenuCollapsed  ? <FaCaretDown className="text-sm" /> : <FaCaretUp className="text-sm" />}
                </button>
              </div>
            </div>

            {/* Access Menu Toggles */}
            {!isAccessMenuCollapsed && (
              <div className="px-4 py-2">
                {/* Full Access Toggles */}
                <div className="flex justify-between items-center my-2 border-b border-dashed border-gray-400">
                  <span className={`text-xs ${fullAccessOn ? "text-cyan-800" : "text-gray-400" }`}>
                    Full Access
                  </span>
                  <button 
                    onClick={handleFullAccessToggle} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-cyan-800' : 'text-gray-400'}`} 
                  >
                    {fullAccessOn ? (
                      <>
                        <span className="text-xs text-cyan-800">
                          Enable
                        </span>
                        <BsToggleOn className="text-cyan-800 text-xl"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">
                          Disable
                        </span>
                        <BsToggleOff className="text-gray-400 text-xl" />
                      </>
                    )}
                  </button>
                </div>

                {/* Overview menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Overview
                  </span>
                  <button 
                    onClick={() => handleToggle('overview')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? "text-gray-400" : "text-cyan-800" }`}
                    disabled={fullAccessOn}
                  >
                    {toggles.overview ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* User Data menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    User Data
                  </span>
                  <button 
                    onClick={() => handleToggle('userData')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.userData ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* email schedule menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Email Schedule
                  </span>
                  <button 
                    onClick={() => handleToggle('emailSchedule')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.emailSchedule ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* event setting menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Event Setting
                  </span>
                  <button 
                    onClick={() => handleToggle('eventSetting')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.eventSetting ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* event notification menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Event Notification
                  </span>
                  <button 
                    onClick={() => handleToggle('eventNotification')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.eventNotification ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* Funded program menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Funded Program
                  </span>
                  <button 
                    onClick={() => handleToggle('fundedProgram')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.fundedProgram ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* subscription menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Product Subscription
                  </span>
                  <button 
                    onClick={() => handleToggle('productSubscription')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.productSubscription ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* video manager menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Video Manage
                  </span>
                  <button 
                    onClick={() => handleToggle('videoManage')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.videoManage ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>

                {/* admin dashboard setting menu Toggles */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
                    Admin Setting
                  </span>
                  <button 
                    onClick={() => handleToggle('adminSetting')} 
                    className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={fullAccessOn}
                  >
                    {toggles.adminSetting ? (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
                        <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    ) : (
                      <>
                        <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
                        <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* -------------------------------- Overview Menu Section --------------------------------------- */}
            {/* Overview Access Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.overview ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`}>
                  Overview Menu
                </label>
                <button 
                  onClick={toggleOverviewMenu} 
                  className={`text-cyan-800 ${!toggles.overview && 'cursor-not-allowed'}`} 
                  disabled={!toggles.overview}
                >
                  {isOverviewMenuCollapsed  ? 
                    <FaCaretDown className={`text-sm ${!toggles.overview ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.overview ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isOverviewMenuCollapsed && toggles.overview && (
              <div className="px-4 py-1">
                {/* Revenu Stats Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${overviewOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Revenu Stats
                  </span>
                  <button 
                      onClick={() => handleToggle('revenuStats')} 
                      className={`flex items-center space-x-2 ${overviewOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!overviewOn}
                  >
                    {overviewToggles.revenuStats ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Sales Stats Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${overviewOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Sale Stats
                  </span>
                  <button 
                      onClick={() => handleToggle('salesStats')} 
                      className={`flex items-center space-x-2 ${overviewOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!overviewOn}
                  >
                    {overviewToggles.salesStats ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Earn Stats Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${overviewOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Earning Stats
                  </span>
                  <button 
                      onClick={() => handleToggle('earningStats')} 
                      className={`flex items-center space-x-2 ${overviewOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!overviewOn}
                  >
                    {overviewToggles.earningStats ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* -------------------------------- USer Data Menu Section --------------------------------------- */}
            {/* User Data Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.userData ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`}>
                  User Data Menu
                </label>
                <button 
                  onClick={toggleUserDataMenu} 
                  className={`text-cyan-800 ${!toggles.userData && 'cursor-not-allowed'}`} 
                  disabled={!toggles.userData}
                >
                  {isUserDataMenuCollapsed ? 
                    <FaCaretDown className={`text-sm ${!toggles.userData ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.userData ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isUserDataMenuCollapsed && toggles.userData && (
              <div className="px-4 py-2">

                {/* Add Data Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${userDataOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Add Data
                  </span>
                  <button 
                      onClick={() => handleToggle('addData')} 
                      className={`flex items-center space-x-2 ${userDataOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!userDataOn}
                  >
                    {userDataToggles.addData ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* edit Data Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${userDataOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Data
                  </span>
                  <button 
                      onClick={() => handleToggle('editData')} 
                      className={`flex items-center space-x-2 ${userDataOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!userDataOn}
                  >
                    {userDataToggles.editData ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Data Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${userDataOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Delete Data
                  </span>
                  <button 
                      onClick={() => handleToggle('deleteData')} 
                      className={`flex items-center space-x-2 ${userDataOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!userDataOn}
                  >
                    {userDataToggles.deleteData ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

              </div>
            )}

            {/* -------------------------------- Email Schedule Section --------------------------------------- */}
            {/* Email Schedule Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.emailSchedule ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`}>
                  Email Scheduled Menu
                </label>
                <button 
                  onClick={toggleEmailScheduleMenu} 
                  className={`text-cyan-800 ${!toggles.emailSchedule && 'cursor-not-allowed'}`} 
                  disabled={!toggles.emailSchedule}
                >
                  {isEmailScheduleMenuCollapsed ? 
                    <FaCaretDown className={`text-sm ${!toggles.emailSchedule ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.emailSchedule ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isEmailScheduleMenuCollapsed && toggles.emailSchedule && (
              <div className="px-4 py-2">

                {/* Add Email Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Add Email
                  </span>
                  <button 
                      onClick={() => handleToggle('addEmail')} 
                      className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!emailScheduleOn}
                  >
                    {emailScheduleToggles.addEmail ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Edit Email Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Email
                  </span>
                  <button 
                      onClick={() => handleToggle('editEmail')} 
                      className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!emailScheduleOn}
                  >
                    {emailScheduleToggles.editEmail ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Email Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Delete Email
                  </span>
                  <button 
                      onClick={() => handleToggle('deleteEmail')} 
                      className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!emailScheduleOn}
                  >
                    {emailScheduleToggles.deleteEmail ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Add Logic Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Add Logic
                  </span>
                  <button 
                      onClick={() => handleToggle('addLogic')} 
                      className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!emailScheduleOn}
                  >
                    {emailScheduleToggles.addLogic ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Edit Logic Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Logic
                  </span>
                  <button 
                      onClick={() => handleToggle('editLogic')} 
                      className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!emailScheduleOn}
                  >
                    {emailScheduleToggles.editLogic ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Logic Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Delete Logic
                  </span>
                  <button 
                      onClick={() => handleToggle('deleteLogic')} 
                      className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!emailScheduleOn}
                  >
                    {emailScheduleToggles.deleteLogic ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* change logic status Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Change Status
                  </span>
                  <button 
                      onClick={() => handleToggle('deleteLogic')} 
                      className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!emailScheduleOn}
                  >
                    {emailScheduleToggles.deleteLogic ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

              </div>
            )}

            {/* -------------------------------- Event Setting Section --------------------------------------- */}
            {/* Event Setting Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.eventSetting ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`}>
                  Event Setting Menu
                </label>
                <button 
                  onClick={toggleEventSettingMenu} 
                  className={`text-cyan-800 ${!toggles.eventSetting && 'cursor-not-allowed'}`} 
                  disabled={!toggles.eventSetting}
                >
                  {isEventSettingMenuCollapsed ? 
                    <FaCaretDown className={`text-sm ${!toggles.eventSetting ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.eventSetting ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isEventSettingMenuCollapsed && toggles.eventSetting && (
              <div className="px-4 py-2">

                {/* Add Event Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${eventSettingOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Add Event
                  </span>
                  <button 
                      onClick={() => handleToggle('addEvent')} 
                      className={`flex items-center space-x-2 ${eventSettingOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!eventSettingOn}
                  >
                    {eventSettingToggles.addEvent ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Edit Event Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${eventSettingOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Event
                  </span>
                  <button 
                      onClick={() => handleToggle('editEvent')} 
                      className={`flex items-center space-x-2 ${eventSettingOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!eventSettingOn}
                  >
                    {eventSettingToggles.editEvent ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Event Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${eventSettingOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Delete Event
                  </span>
                  <button 
                      onClick={() => handleToggle('deleteEvent')} 
                      className={`flex items-center space-x-2 ${eventSettingOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!eventSettingOn}
                  >
                    {eventSettingToggles.deleteEvent ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

              </div>
            )}

            {/* -------------------------------- Event Notification Section --------------------------------------- */}
            {/* Event Notification Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.eventNotification ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`}>
                  Event Notification Menu
                </label>
                <button 
                  onClick={toggleEventNotificationMenu} 
                  className={`text-cyan-800 ${!toggles.eventNotification && 'cursor-not-allowed'}`} 
                  disabled={!toggles.eventNotification}
                >
                  {isEventNotificationMenuCollapsed ? 
                    <FaCaretDown className={`text-sm ${!toggles.eventNotification ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.eventNotification ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isEventNotificationMenuCollapsed && toggles.eventNotification && (
              <div className="px-4 py-2">

                {/* Approve Ticket Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${eventNotificationOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Approve Event Ticket
                  </span>
                  <button 
                      onClick={() => handleToggle('approveEventTicket')} 
                      className={`flex items-center space-x-2 ${eventNotificationOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!eventNotificationOn}
                  >
                    {eventNotificationToggles.approveEventTicket ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Reject Ticket Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${eventNotificationOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Reject Event Ticket
                  </span>
                  <button 
                      onClick={() => handleToggle('rejectEventTicket')} 
                      className={`flex items-center space-x-2 ${eventNotificationOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!eventNotificationOn}
                  >
                    {eventNotificationToggles.rejectEventTicket ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Ticket Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${eventNotificationOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Delete Event Ticket
                  </span>
                  <button 
                      onClick={() => handleToggle('deleteEventTicket')} 
                      className={`flex items-center space-x-2 ${eventNotificationOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!eventNotificationOn}
                  >
                    {eventNotificationToggles.deleteEventTicket ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* -------------------------------- Funded Program Section --------------------------------------- */}
            {/* Funded Program Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.fundedProgram ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`}>
                  Funded Program Menu
                </label>
                <button 
                  onClick={toggleFundedProgramMenu} 
                  className={`text-cyan-800 ${!toggles.fundedProgram && 'cursor-not-allowed'}`} 
                  disabled={!toggles.fundedProgram}
                >
                  {isFundedProgramMenuCollapsed ? 
                    <FaCaretDown className={`text-sm ${!toggles.fundedProgram ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.fundedProgram ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isFundedProgramMenuCollapsed && toggles.fundedProgram && (
              <div className="px-4 py-2">

                {/* Approve Fund Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Add Fund
                  </span>
                  <button 
                      onClick={() => handleToggle('addFund')} 
                      className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!fundedProgramOn}
                  >
                    {fundedProgramToggles.addFund ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Reject Fund Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Fund
                  </span>
                  <button 
                      onClick={() => handleToggle('editFund')} 
                      className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!fundedProgramOn}
                  >
                    {fundedProgramToggles.editFund ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Fund Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Delete Fund
                  </span>
                  <button 
                      onClick={() => handleToggle('deleteFund')} 
                      className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!fundedProgramOn}
                  >
                    {fundedProgramToggles.deleteFund ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Approve Fund Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Approve Fund
                  </span>
                  <button 
                      onClick={() => handleToggle('approveFund')} 
                      className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
                      disabled={!fundedProgramOn}
                  >
                    {fundedProgramToggles.approveFund ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Reject Fund Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Reject Fund
                  </span>
                  <button 
                    onClick={() => handleToggle('rejectFund')} 
                    className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!fundedProgramOn}
                  >
                    {fundedProgramToggles.rejectFund ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* -------------------------------- Product Subscription Section --------------------------------------- */}
            {/* Product Subscription Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.productSubscription ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`}>
                  Product Subscription Menu
                </label>
                <button 
                  onClick={toggleProductSubscriptionMenu} 
                  className={`text-cyan-800 ${!toggles.productSubscription && 'cursor-not-allowed'}`} 
                  disabled={!toggles.productSubscription}
                >
                  {isProductSubMenuCollapsed ? 
                    <FaCaretDown className={`text-sm ${!toggles.productSubscription ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.productSubscription ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isProductSubMenuCollapsed && toggles.productSubscription && (
              <div className="px-4 py-2">

                {/* Approve Sub Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${productSubOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Add Subscription
                  </span>
                  <button 
                    onClick={() => handleToggle('addSub')} 
                    className={`flex items-center space-x-2 ${productSubOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!productSubOn}
                  >
                    {productSubToggles.addSub ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Reject Sub Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${productSubOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Subscription
                  </span>
                  <button 
                    onClick={() => handleToggle('editSub')} 
                    className={`flex items-center space-x-2 ${productSubOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!productSubOn}
                  >
                    {productSubToggles.editSub ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Sub Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${productSubOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Delete Subscription
                  </span>
                  <button 
                    onClick={() => handleToggle('deleteSub')} 
                    className={`flex items-center space-x-2 ${productSubOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!productSubOn}
                  >
                    {productSubToggles.deleteSub ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Approve Sub Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${productSubOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Approve Subscription
                  </span>
                  <button 
                    onClick={() => handleToggle('approveSub')} 
                    className={`flex items-center space-x-2 ${productSubOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!productSubOn}
                  >
                    {productSubToggles.approveSub ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Reject Sub Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${productSubOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Reject Subscription
                  </span>
                  <button 
                    onClick={() => handleToggle('rejectSub')} 
                    className={`flex items-center space-x-2 ${productSubOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!productSubOn}
                  >
                    {productSubToggles.rejectSub ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* -------------------------------- Video Manage Section --------------------------------------- */}
            {/* Video Manage Menu */}
            <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.videoManage ? '' : 'bg-gray-200'}`}>
              <div className="flex justify-between items-center">
                <label className={`text-xs font-semibold ${toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`}>
                  Video Manage Menu
                </label>
                <button 
                  onClick={toggleVideoManageMenu} 
                  className={`text-cyan-800 ${!toggles.videoManage && 'cursor-not-allowed'}`} 
                  disabled={!toggles.videoManage}
                >
                  {isVideoManageMenuCollapsed ? 
                    <FaCaretDown className={`text-sm ${!toggles.videoManage ? "text-gray-400" : "text-cyan-800"}`} /> : 
                    <FaCaretUp className={`text-sm ${!toggles.videoManage ? "text-gray-400" : "text-cyan-800"}`} />
                  }
                </button>
              </div>
            </div>

            {!isVideoManageMenuCollapsed && toggles.videoManage && (
              <div className="px-4 py-2">

                {/* Upload Video Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${videoManageOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Upload Video
                  </span>
                  <button 
                    onClick={() => handleToggle('uploadVideo')} 
                    className={`flex items-center space-x-2 ${videoManageOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!videoManageOn}
                  >
                    {videoManageToggles.uploadVideo ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* edit Video Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${videoManageOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Video
                  </span>
                  <button 
                    onClick={() => handleToggle('editVideo')} 
                    className={`flex items-center space-x-2 ${videoManageOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!videoManageOn}
                  >
                    {videoManageToggles.editVideo ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Delete Video Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${videoManageOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Remove Video
                  </span>
                  <button 
                    onClick={() => handleToggle('removeVideo')} 
                    className={`flex items-center space-x-2 ${videoManageOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!videoManageOn}
                  >
                    {videoManageToggles.removeVideo ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Add Playlist Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${videoManageOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Add Playlist
                  </span>
                  <button 
                    onClick={() => handleToggle('addPlaylist')} 
                    className={`flex items-center space-x-2 ${videoManageOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!videoManageOn}
                  >
                    {videoManageToggles.addPlaylist ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>

                {/* Edit Playlist Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${videoManageOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Edit Playlist
                  </span>
                  <button 
                    onClick={() => handleToggle('editPlaylist')} 
                    className={`flex items-center space-x-2 ${videoManageOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!videoManageOn}
                  >
                    {videoManageToggles.editPlaylist ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>
              
                {/* Remove Playlist Toggle */}
                <div className="flex justify-between items-center my-2">
                  <span className={`text-xs ${videoManageOn ? 'text-gray-700' : 'text-gray-400'}`}>
                    Remove Playlist
                  </span>
                  <button 
                    onClick={() => handleToggle('removePlaylist')} 
                    className={`flex items-center space-x-2 ${videoManageOn ? 'text-gray-400' : 'text-cyan-800'}`}
                    disabled={!videoManageOn}
                  >
                    {videoManageToggles.removePlaylist ? (
                      <>
                        <span className="text-xs text-cyan-800">Enable</span>
                        <BsToggleOn className="text-xl text-cyan-800"/>
                      </>
                    ) : (
                      <>
                        <span className="text-xs text-gray-400">Disable</span>
                        <BsToggleOff className="text-xl text-gray-400"/>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* button function field */}
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="mr-4 bg-gray-400 text-white rounded-md px-8 py-1"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="text-sm bg-cyan-800 text-white rounded-md px-4 py-1"
            onClick={handleSubmit}
          >
            {initialData ? 'Save Changes' : 'Add Account'}
          </button>
        </div>

        {isEmailModalOpen && (
          <AssignAdminEmail
            isOpen={isEmailModalOpen}
            onClose={handleCloseEmailModal}
            handleAddEmail={handleAddEmail}
            initialEmails={form.roleAssign.split(', ')}
          />
        )}
      </div>
    </div>
  );
};

export default AdminAddAccountModal;

// // udpate code --> 22 oct 2024
// // src/components/admin/AdminSetting/AdminAddAccountModal.tsx

// import React, { useState, useEffect } from 'react';
// import { BsToggleOff, BsToggleOn } from 'react-icons/bs'; 
// import { FaCaretUp, FaCaretDown } from "react-icons/fa";
// import { PiPasswordFill } from "react-icons/pi";
// import { RiMailAddLine } from "react-icons/ri";
// import { useAdminUsers } from '../../../hooks/AdminDashboard/useAdminRoles';
// import AssignAdminEmail from '../AdminSetting/AdminUserAssignEmail';

// interface AdminAddAccountModalProps {
//   onClose: () => void;
//   initialData?: any;
// }

// const AdminAddAccountModal: React.FC<AdminAddAccountModalProps> = ({ onClose, initialData }) => {
//   const { addAdminUser, updateAdminUser, getDefaultPermissions } = useAdminUsers();
//   const [fullAccessOn, setFullAccessOn] = useState<boolean>(true);
//   const [overviewOn, setOverviewOn] = useState<boolean>(true);
//   const [userDataOn, setUserDataOn] = useState<boolean>(true);
//   const [emailScheduleOn, setEmailScheduleOn] = useState<boolean>(true);
//   const [eventSettingOn, setEventSettingOn] = useState<boolean>(true);
//   const [eventNotificationOn, setEventNotificationOn] = useState<boolean>(true);
//   const [fundedProgramOn, setFundedProgramOn] = useState<boolean>(true);
//   const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

//   // Individual collapse states for each menu category
//   const [isAccessMenuCollapsed, setIsAccessMenuCollapsed] = useState(false);
//   const [isOverviewMenuCollapsed, setIsOverviewMenuCollapsed] = useState(false);
//   const [isUserDataMenuCollapsed, setIsUserDataMenuCollapsed] = useState(false);
//   const [isEmailScheduleMenuCollapsed, setIsEmailScheduleMenuCollapsed] = useState(false);
//   const [isEventSettingMenuCollapsed, setIsEventSettingMenuCollapsed] = useState(false);
//   const [isEventNotificationMenuCollapsed, setIsEventNotificationMenuCollapsed] = useState(false);
//   const [isFundedProgramMenuCollapsed, setIsFundedProgramMenuCollapsed] = useState(false);

//   const [toggles, setToggles] = useState({
//     fullAccess: true,
//     overview: false,
//     userData: false,
//     emailSchedule: false,
//     eventSetting: false,
//     eventNotification: false,
//     productSubscription: false,
//     fundedProgram: false,
//     videoManage: false,
//     adminSetting: false,
//   });

//   const [overviewToggles, setOverviewToggles] = useState({
//     revenuStats: false,
//     earningStats: false,
//     salesStats: false,
//   })

//   const [userDataToggles, setUserDataToggles] = useState({
//     addData: false,
//     editData: false,
//     updateData: false,
//     deleteData: false,
//   })

//   const [emailScheduleToggles, setEmailScheduleToggles] = useState({
//     addLogic: false,
//     editLogic: false,
//     updateLogic: false,
//     deleteLogic: false,
//   })

//   const [eventSettingToggles, setEventSettingToggles] = useState({
//     addEvent: false,
//     editEvent: false,
//     updateEvent: false,
//     deleteEvent: false,
//   })

//   const [eventNotificationToggles, setEventNotificationToggles] = useState({
//     approveEventTicket: false,
//     rejectEventTicket: false,
//     deleteEventTicket: false,
//   })

//   const [fundedProgramToggles, setFundedProgramToggles] = useState({
//     addFund: false,
//     editFund: false,
//     updateFund: false,
//     deleteFund: false,
//     approveFund: false,
//     rejectFund: false,
//   })

//   // Define types now, after defining the states
//   type GeneralToggles = keyof typeof toggles;
//   type OverviewToggles = keyof typeof overviewToggles;
//   type UserDataToggles = keyof typeof userDataToggles;
//   type EmailScheduleToggles = keyof typeof emailScheduleToggles;
//   type EventSettingToggles = keyof typeof eventSettingToggles;
//   type EventNotificationToggles = keyof typeof eventNotificationToggles;
//   type FundedProgramToggles = keyof typeof fundedProgramToggles;

//   const [form, setForm] = useState({
//     roleId: '',
//     email: '',
//     name: '',
//     password: '',
//     role: '',
//     roleAssign: '',
//     status: '',
//   });

//   useEffect(() => {
//     if (initialData) {
//       setForm({
//         roleId: initialData.roleId,
//         email: initialData.email,
//         name: initialData.name,
//         password: initialData.password || '', 
//         role: initialData.role,
//         roleAssign: initialData.roleAssign,
//         status: initialData.status,
//       });
  
//       // Set initial permissions state for toggles based on initialData.rolePermission
//       if (initialData.rolePermission) {
//         setToggles(initialData.rolePermission);
//         setFullAccessOn(initialData.rolePermission.fullAccess === 'enable');
//       }
//     }
//   }, [initialData]);

//    // useEffect to load initial data for form and toggles
//    useEffect(() => {
//     if (initialData) {
//       const savedPermissions = initialData.rolePermission;

//       setForm({
//         roleId: initialData.roleId,
//         email: initialData.email,
//         name: initialData.name,
//         password: initialData.password || '',
//         role: initialData.role,
//         roleAssign: initialData.roleAssign,
//         status: initialData.status,
//       });

//       setToggles({
//         fullAccess: savedPermissions.fullAccess === 'enable',
//         overview: savedPermissions.overview === 'enable',
//         userData: savedPermissions.userData?.enable === 'enable',
//         emailSchedule: savedPermissions.emailSchedule?.enable === 'enable',
//         eventSetting: savedPermissions.eventSetting?.enable === 'enable',
//         eventNotification: savedPermissions.eventNotification?.enable === 'enable',
//         productSubscription: savedPermissions.productSubscription === 'enable',
//         fundedProgram: savedPermissions.fundedProgram?.enable === 'enable',
//         videoManage: savedPermissions.videoManage === 'enable',
//         adminSetting: savedPermissions.adminSetting === 'enable',
//       });
  
//       setFullAccessOn(savedPermissions.fullAccess === 'enable');
//       setOverviewOn(savedPermissions.overview === 'enable');
//       setUserDataOn(savedPermissions.userData?.enable === 'enable');
//       setEmailScheduleOn(savedPermissions.emailSchedule?.enable === 'enable');
//       setEventSettingOn(savedPermissions.eventSetting?.enable === 'enable');
//       setEventNotificationOn(savedPermissions.eventNotification?.enable === 'enable');
//       setFundedProgramOn(savedPermissions.fundedProgram?.enable === 'enable');
//     }
//   }, [initialData]);

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setForm({
//       ...form,
//       [name]: value,
//     });
  
//     if (name === 'role') {
//       // Apply default permissions when role is selected
//       const defaultPermissions = getDefaultPermissions(value);
//       setToggles(defaultPermissions);
//       setFullAccessOn(defaultPermissions.fullAccess);
//     }
//   };  

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     // Append @t1trader.com to the email if not already present
//     const emailWithDomain = form.email.includes('@t1trader.com')
//       ? form.email
//       : `${form.email}@t1trader.com`;
  
//     const rolePermission = {
//       fullAccess: fullAccessOn ? 'enable' : 'disable',
//       overview: toggles.overview ? 'enable' : 'disable',
  
//       // If any userData permission is enabled, store the sub-object, otherwise store 'disable'
//       userData: Object.values(userDataToggles).some(toggle => toggle) 
//         ? {
//           enable: 'enable',
//           addData: userDataToggles.addData ? 'enable' : 'disable',
//           editData: userDataToggles.editData ? 'enable' : 'disable',
//           updateData: userDataToggles.updateData ? 'enable' : 'disable',
//           deleteData: userDataToggles.deleteData ? 'enable' : 'disable',
//         } : 'disable',
  
//       // For eventSetting, if any toggle is enabled, save sub-object, otherwise store 'disable'
//       eventSetting: Object.values(eventSettingToggles).some(toggle => toggle) 
//         ? {
//           enable: 'enable',
//           addEvent: eventSettingToggles.addEvent ? 'enable' : 'disable',
//           editEvent: eventSettingToggles.editEvent ? 'enable' : 'disable',
//           updateEvent: eventSettingToggles.updateEvent ? 'enable' : 'disable',
//           deleteEvent: eventSettingToggles.deleteEvent ? 'enable' : 'disable',
//         } : 'disable',
  
//       // Repeat the same pattern for emailSchedule and other groups
//       emailSchedule: Object.values(emailScheduleToggles).some(toggle => toggle) 
//         ? {
//           enable: 'enable',
//           addLogic: emailScheduleToggles.addLogic ? 'enable' : 'disable',
//           editLogic: emailScheduleToggles.editLogic ? 'enable' : 'disable',
//           updateLogic: emailScheduleToggles.updateLogic ? 'enable' : 'disable',
//           deleteLogic: emailScheduleToggles.deleteLogic ? 'enable' : 'disable',
//         } : 'disable',
  
//       // Repeat for eventNotification
//       eventNotification: Object.values(eventNotificationToggles).some(toggle => toggle) 
//         ? {
//           enable: 'enable',
//           approveEventTicket: eventNotificationToggles.approveEventTicket ? 'enable' : 'disable',
//           rejectEventTicket: eventNotificationToggles.rejectEventTicket ? 'enable' : 'disable',
//           deleteEventTicket: eventNotificationToggles.deleteEventTicket ? 'enable' : 'disable',
//         } : 'disable',
  
//       // Repeat for fundedProgram
//       fundedProgram: Object.values(fundedProgramToggles).some(toggle => toggle) 
//         ? {
//           enable: 'enable',
//           addFund: fundedProgramToggles.addFund ? 'enable' : 'disable',
//           editFund: fundedProgramToggles.editFund ? 'enable' : 'disable',
//           updateFund: fundedProgramToggles.updateFund ? 'enable' : 'disable',
//           deleteFund: fundedProgramToggles.deleteFund ? 'enable' : 'disable',
//           approveFund: fundedProgramToggles.approveFund ? 'enable' : 'disable',
//           rejectFund: fundedProgramToggles.rejectFund ? 'enable' : 'disable',
//         } : 'disable',
  
//       productSubscription: toggles.productSubscription ? 'enable' : 'disable',
//       videoManage: toggles.videoManage ? 'enable' : 'disable',
//       adminSetting: toggles.adminSetting ? 'enable' : 'disable',
//     };

//     // Log to verify structure
//     console.log('Submitting rolePermission:', rolePermission);
  
//     const formData = {
//       ...form,
//       email: emailWithDomain,
//       rolePermission, 
//     };
  
//     try {
//       if (initialData) {
//         // If editing, update the existing user
//         await updateAdminUser(formData); 
//       } else {
//         // If not editing, create a new user
//         await addAdminUser(formData); 
//       }
//       onClose(); 
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };   

//   // Toggle individual buttons for both general toggles and overview toggles
//   const handleToggle = (
//     feature: 
//       GeneralToggles | OverviewToggles | UserDataToggles | 
//       EmailScheduleToggles | EventSettingToggles | EventNotificationToggles |
//       FundedProgramToggles
//     ) => {
//     if (!fullAccessOn) {
//       if (feature in toggles) {
//         // Handle general toggles
//         setToggles((prevState) => ({
//           ...prevState,
//           [feature as GeneralToggles]: !prevState[feature as GeneralToggles], 
//         }));

//         // If toggling overview, sync overviewOn state
//         if (feature === 'overview') {
//           setOverviewOn((prev) => !prev);
//         }
//       } else if (feature in overviewToggles) {
//         // Handle overview-specific toggles
//         setOverviewToggles((prevState) => ({
//           ...prevState,
//           [feature as OverviewToggles]: !prevState[feature as OverviewToggles], 
//         }));

//         // If toggling overview, sync user data state
//         if (feature === 'userData') {
//           setUserDataOn((prev) => !prev);
//         }
//       } else if (feature in userDataToggles) {
//         // Handle user data toggles
//         setUserDataToggles((prevState) => ({
//           ...prevState,
//           [feature as UserDataToggles]: !prevState[feature as UserDataToggles], 
//         }));

//         // If toggling overview, sync email schedule state
//         if (feature === 'emailSchedule') {
//           setEmailScheduleOn((prev) => !prev);
//         }
//       } else if (feature in emailScheduleToggles) {
//         // Handle email schedule toggles
//         setEmailScheduleToggles((prevState) => ({
//           ...prevState,
//           [feature as EmailScheduleToggles]: !prevState[feature as EmailScheduleToggles], 
//         }));

//         // If toggling overview, sync event setting state
//         if (feature === 'eventSetting') {
//           setEventSettingOn((prev) => !prev);
//         }
//       } else if (feature in eventSettingToggles) {
//         // Handle email schedule toggles
//         setEventSettingToggles((prevState) => ({
//           ...prevState,
//           [feature as EventSettingToggles]: !prevState[feature as EventSettingToggles], 
//         }));

//         // If toggling overview, sync event notification state
//         if (feature === 'eventNotification') {
//           setEventNotificationOn((prev) => !prev);
//         }
//       } else if (feature in eventNotificationToggles) {
//         // Handle email schedule toggles
//         setEventNotificationToggles((prevState) => ({
//           ...prevState,
//           [feature as EventNotificationToggles]: !prevState[feature as EventNotificationToggles], 
//         }));

//         // If toggling overview, sync funded program state
//         if (feature === 'fundedProgram') {
//           setFundedProgramOn((prev) => !prev);
//         }
//       } else if (feature in fundedProgramToggles) {
//         // Handle email schedule toggles
//         setFundedProgramToggles((prevState) => ({
//           ...prevState,
//           [feature as FundedProgramToggles]: !prevState[feature as FundedProgramToggles], 
//         }));
//       }
//     }
//   };

//   // Handle collapse/expand for each individual category
//   const toggleAccessMenu = () => setIsAccessMenuCollapsed(!isAccessMenuCollapsed);
//   const toggleOverviewMenu = () => setIsOverviewMenuCollapsed(!isOverviewMenuCollapsed);
//   const toggleUserDataMenu = () => setIsUserDataMenuCollapsed(!isUserDataMenuCollapsed);
//   const toggleEmailScheduleMenu = () => setIsEmailScheduleMenuCollapsed(!isEmailScheduleMenuCollapsed); 
//   const toggleEventSettingMenu = () => setIsEventSettingMenuCollapsed(!isEventSettingMenuCollapsed);
//   const toggleEventNotificationMenu = () => setIsEventNotificationMenuCollapsed(!isEventNotificationMenuCollapsed); 
//   const toggleFundedProgramMenu = () => setIsFundedProgramMenuCollapsed(!isFundedProgramMenuCollapsed);

//   const handleFullAccessToggle = () => {
//     setFullAccessOn((prev) => !prev); 
//     if (fullAccessOn) {
//       // If full access is turned off, turn all individual options on
//       setToggles({
//         fullAccess: false,
//         overview: false,
//         userData: false,
//         emailSchedule: false,
//         eventSetting: false,
//         eventNotification: false,
//         productSubscription: false,
//         fundedProgram: false,
//         videoManage: false,
//         adminSetting: false,
//       });
//       setIsAccessMenuCollapsed(false);
//       setIsOverviewMenuCollapsed(false);
//       setIsUserDataMenuCollapsed(false);
//       setIsEmailScheduleMenuCollapsed(false);
//       setIsEventSettingMenuCollapsed(false);
//       setIsEventNotificationMenuCollapsed(false);
//       setIsFundedProgramMenuCollapsed(false);
//     } else {
//       // If full access is turned on, disable all individual options
//       setToggles({
//         fullAccess: true,
//         overview: false,
//         userData: false,
//         emailSchedule: false,
//         eventSetting: false,
//         eventNotification: false,
//         productSubscription: false,
//         fundedProgram: false,
//         videoManage: false,
//         adminSetting: false,
//       });
//       setIsAccessMenuCollapsed(true);
//       setIsOverviewMenuCollapsed(true);
//       setIsUserDataMenuCollapsed(true);
//       setIsEmailScheduleMenuCollapsed(true);
//       setIsEventSettingMenuCollapsed(true);
//       setIsEventNotificationMenuCollapsed(true);
//       setIsFundedProgramMenuCollapsed(true);
//     }
//   };

//   const handleOpenEmailModal = () => {
//     setIsEmailModalOpen(true);
//   };
  
//   const handleCloseEmailModal = () => {
//     setIsEmailModalOpen(false);
//   };
  
//   const handleAddEmail = (emailList: string[]) => {
//     setForm({
//       ...form,
//       roleAssign: emailList.filter(email => email).join(', '),
//     });
//     setIsEmailModalOpen(false);  
//   };
  
//   // Function to generate a random password
//   const generatePassword = () => {
//     const digits = Math.floor(1000 + Math.random() * 9000).toString(); 
//     const chars = Math.random().toString(36).substring(2, 6).toUpperCase(); 
//     return digits + chars;
//   };
  
  
//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full h-auto max-h-screen">
//         <h2 className="text-xl font-bold mb-4 text-cyan-800">
//           {initialData ? 'Edit Account' : 'Add New Account'}
//         </h2>
//         <div className="flex flex-row space-x-2">
//           {/* left section */}
//           <div className="border p-4 min-w-[350px] rounded-lg">
//             <form onSubmit={handleSubmit}>
//               {/* role id field */}
//               <div className="mb-4">
//                 <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//                     Role ID
//                 </label>
//                 <input
//                   type="text"
//                   name="roleId"
//                   value={form.roleId}
//                   onChange={handleChange}
//                   className="text-xs w-full px-4 py-2 border rounded-md"
//                   disabled
//                 />
//               </div>

//               {/* email field */}
//               <div className="mb-4">
//                 <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//                   Account Email
//                 </label>
//                 <div className="flex justify-between items-center">
//                   <input
//                     type="email"
//                     name="email"
//                     value={form.email.replace('@r1trader.com', '')}
//                     onChange={handleChange}
//                     className="text-xs w-full px-4 py-2 border rounded-md"
//                     required
//                   />
//                   <span className="text-xs ml-4">
//                     @r1trader.com
//                   </span>
//                 </div>
//               </div>

//               {/* role account password field */}
//               <div className="mb-4">
//                 <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//                   Account Password
//                 </label>
//                 <div className="flex">
//                   <input
//                     type="text"
//                     name="password"
//                     value={form.password}
//                     onChange={handleChange}
//                     className="text-xs w-full px-4 py-2 border rounded-md"
//                     required
//                   />
//                   <button
//                     type="button"
//                     onClick={() => setForm({ ...form, password: generatePassword() })} 
//                     className="ml-2 text-cyan-800"
//                   >
//                     <PiPasswordFill className="text-lg" />
//                   </button>
//                 </div>
//               </div>

//               {/* name field */}
//               <div className="mb-4">
//                 <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//                   Account Name
//                 </label>
//                 <input
//                   type="text"
//                   name="name"
//                   value={form.name}
//                   onChange={handleChange}
//                   className="text-xs w-full px-4 py-2 border rounded-md"
//                   required
//                 />
//               </div>

//               {/* role field */}
//               <div className="mb-4">
//                 <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//                   Assign Role
//                 </label>
//                 <select
//                   name="role"
//                   value={form.role}
//                   onChange={handleChange}
//                   className="text-xs text-gray-400 w-full px-4 py-2 border rounded-md"
//                 >
//                   <option value="">Select a Role</option>
//                   <option value="admin">Admin</option>
//                   <option value="manager">Manager</option>
//                   <option value="marketing">Marketing</option>
//                   <option value="sale">Sale</option>
//                   <option value="support">Support</option>
//                   <option value="assistant">Assistant</option>
//                   <option value="custom">Custom</option>
//                 </select>
//               </div>

//               {/* assign role to user email field */}
//               <div className="mb-4">
//                 <div className="flex justify-between items-center">
//                   <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//                     Assign To Users
//                   </label>
//                   <div 
//                     className="flex justify-normal items-center text-sm text-cyan-800 cursor-pointer"
//                     onClick={handleOpenEmailModal}
//                   >
//                     <RiMailAddLine/>
//                     <span className="text-xs ml-2">add email</span>
//                   </div>
//                 </div>
//                 <input
//                   type="text"
//                   name="roleAssign"
//                   value={
//                     form.roleAssign
//                       ? `${form.roleAssign.split(', ').length} emails assigned`
//                       : 'No emails assigned'
//                   }
//                   onChange={handleChange}
//                   onClick={handleOpenEmailModal}
//                   className="text-xs w-full px-4 py-2 border rounded-md"
//                   readOnly
//                   disabled
//                 />
//               </div>

//               {/* status field */}
//               <div className="mb-4">
//                 <label className="block text-sm text-cyan-800 font-semibold mb-1 ml-2">
//                   Status
//                 </label>
//                 <select
//                   name="status"
//                   value={form.status}
//                   onChange={handleChange}
//                   className="text-xs w-full px-4 py-2 border rounded-md"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="Active">Active</option>
//                   <option value="Deactive">Deactive</option>
//                 </select>
//               </div>
//             </form>
//           </div>

//           {/* right section */}
//           <div className="border w-[350px] h-auto max-h-[550px] rounded-lg overflow-y-auto">
//             <div className="font-bold text-cyan-800 text-sm px-4 pt-4 mb-1">
//               Role Manager
//             </div>
            
//             {/* catergory for menu accessment */}
//             <div className="bg-gray-100 px-4 py-2">
//               <div className="flex justify-between items-center">
//                 <label className="text-cyan-800 text-xs font-semibold">Access Menu</label>
//                 <button onClick={toggleAccessMenu} className="text-cyan-800">
//                   {isAccessMenuCollapsed  ? <FaCaretDown className="text-sm" /> : <FaCaretUp className="text-sm" />}
//                 </button>
//               </div>
//             </div>

//             {/* Access Menu Toggles */}
//             {!isAccessMenuCollapsed && (
//               <div className="px-4 py-2">
//                 {/* Full Access Toggles */}
//                 <div className="flex justify-between items-center my-2 border-b border-dashed border-gray-400">
//                   <span className={`text-xs ${fullAccessOn ? "text-cyan-800" : "text-gray-400" }`}>
//                     Full Access
//                   </span>
//                   <button 
//                     onClick={handleFullAccessToggle} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-cyan-800' : 'text-gray-400'}`} 
//                   >
//                     {fullAccessOn ? (
//                       <>
//                         <span className="text-xs text-cyan-800">
//                           Enable
//                         </span>
//                         <BsToggleOn className="text-cyan-800 text-xl"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">
//                           Disable
//                         </span>
//                         <BsToggleOff className="text-gray-400 text-xl" />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Overview menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Overview
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('overview')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? "text-gray-400" : "text-cyan-800" }`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.overview ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* User Data menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     User Data
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('userData')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.userData ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* email schedule menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Email Schedule
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('emailSchedule')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.emailSchedule ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* event setting menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Event Setting
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('eventSetting')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.eventSetting ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* event notification menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Event Notification
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('eventNotification')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.eventNotification ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Funded program menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Funded Program
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('fundedProgram')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.fundedProgram ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* subscription menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Product Subscription
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('productSubscription')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.productSubscription ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.productSubscription ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* video manager menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Video Manage
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('videoManage')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.videoManage ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.videoManage ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* admin dashboard setting menu Toggles */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : 'text-gray-700'}`}>
//                     Admin Setting
//                   </span>
//                   <button 
//                     onClick={() => handleToggle('adminSetting')} 
//                     className={`flex items-center space-x-2 ${fullAccessOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                     disabled={fullAccessOn}
//                   >
//                     {toggles.adminSetting ? (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Enable</span>
//                         <BsToggleOn className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     ) : (
//                       <>
//                         <span className={`text-xs ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`}>Disable</span>
//                         <BsToggleOff className={`text-xl ${fullAccessOn ? 'text-gray-400' : toggles.adminSetting ? 'text-cyan-800' : 'text-gray-400'}`} />
//                       </>
//                     )}
//                   </button>
//                 </div>
//               </div>
//             )}

//             {/* -------------------------------- Overview Menu Section --------------------------------------- */}
//             {/* Overview Access Menu */}
//             <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.overview ? '' : 'bg-gray-200'}`}>
//               <div className="flex justify-between items-center">
//                 <label className={`text-xs font-semibold ${toggles.overview ? 'text-cyan-800' : 'text-gray-400'}`}>
//                   Overview Menu
//                 </label>
//                 <button 
//                   onClick={toggleOverviewMenu} 
//                   className={`text-cyan-800 ${!toggles.overview && 'cursor-not-allowed'}`} 
//                   disabled={!toggles.overview}
//                 >
//                   {isOverviewMenuCollapsed  ? 
//                     <FaCaretDown className={`text-sm ${!toggles.overview ? "text-gray-400" : "text-cyan-800"}`} /> : 
//                     <FaCaretUp className={`text-sm ${!toggles.overview ? "text-gray-400" : "text-cyan-800"}`} />
//                   }
//                 </button>
//               </div>
//             </div>

//             {!isOverviewMenuCollapsed && toggles.overview && (
//               <div className="px-4 py-1">
//                 {/* Revenu Stats Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${overviewOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Revenu Stats
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('revenuStats')} 
//                       className={`flex items-center space-x-2 ${overviewOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!overviewOn}
//                   >
//                     {overviewToggles.revenuStats ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Sales Stats Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${overviewOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Sale Stats
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('salesStats')} 
//                       className={`flex items-center space-x-2 ${overviewOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!overviewOn}
//                   >
//                     {overviewToggles.salesStats ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Earn Stats Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${overviewOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Earning Stats
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('earningStats')} 
//                       className={`flex items-center space-x-2 ${overviewOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!overviewOn}
//                   >
//                     {overviewToggles.earningStats ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>
//               </div>
//             )}

//             {/* -------------------------------- USer Data Menu Section --------------------------------------- */}
//             {/* User Data Menu */}
//             <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.userData ? '' : 'bg-gray-200'}`}>
//               <div className="flex justify-between items-center">
//                 <label className={`text-xs font-semibold ${toggles.userData ? 'text-cyan-800' : 'text-gray-400'}`}>
//                   User Data Menu
//                 </label>
//                 <button 
//                   onClick={toggleUserDataMenu} 
//                   className={`text-cyan-800 ${!toggles.userData && 'cursor-not-allowed'}`} 
//                   disabled={!toggles.userData}
//                 >
//                   {isUserDataMenuCollapsed ? 
//                     <FaCaretDown className={`text-sm ${!toggles.userData ? "text-gray-400" : "text-cyan-800"}`} /> : 
//                     <FaCaretUp className={`text-sm ${!toggles.userData ? "text-gray-400" : "text-cyan-800"}`} />
//                   }
//                 </button>
//               </div>
//             </div>

//             {!isUserDataMenuCollapsed && toggles.userData && (
//               <div className="px-4 py-2">

//                 {/* Add Data Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${userDataOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Add Data
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('addData')} 
//                       className={`flex items-center space-x-2 ${userDataOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!userDataOn}
//                   >
//                     {userDataToggles.addData ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* edit Data Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${userDataOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Edit Data
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('editData')} 
//                       className={`flex items-center space-x-2 ${userDataOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!userDataOn}
//                   >
//                     {userDataToggles.editData ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* update Data Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${userDataOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Update Data
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('updateData')} 
//                       className={`flex items-center space-x-2 ${userDataOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!userDataOn}
//                   >
//                     {userDataToggles.updateData ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Delete Data Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${userDataOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Delete Data
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('deleteData')} 
//                       className={`flex items-center space-x-2 ${userDataOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!userDataOn}
//                   >
//                     {userDataToggles.deleteData ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//               </div>
//             )}

//             {/* -------------------------------- Email Schedule Section --------------------------------------- */}
//             {/* User Data Menu */}
//             <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.emailSchedule ? '' : 'bg-gray-200'}`}>
//               <div className="flex justify-between items-center">
//                 <label className={`text-xs font-semibold ${toggles.emailSchedule ? 'text-cyan-800' : 'text-gray-400'}`}>
//                   Email Scheduled Menu
//                 </label>
//                 <button 
//                   onClick={toggleEmailScheduleMenu} 
//                   className={`text-cyan-800 ${!toggles.emailSchedule && 'cursor-not-allowed'}`} 
//                   disabled={!toggles.emailSchedule}
//                 >
//                   {isEmailScheduleMenuCollapsed ? 
//                     <FaCaretDown className={`text-sm ${!toggles.emailSchedule ? "text-gray-400" : "text-cyan-800"}`} /> : 
//                     <FaCaretUp className={`text-sm ${!toggles.emailSchedule ? "text-gray-400" : "text-cyan-800"}`} />
//                   }
//                 </button>
//               </div>
//             </div>

//             {!isEmailScheduleMenuCollapsed && toggles.emailSchedule && (
//               <div className="px-4 py-2">

//                 {/* Add Logic Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Add Logic
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('addLogic')} 
//                       className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!emailScheduleOn}
//                   >
//                     {emailScheduleToggles.addLogic ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Edit Logic Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Edit Logic
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('editLogic')} 
//                       className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!emailScheduleOn}
//                   >
//                     {emailScheduleToggles.editLogic ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Update Logic Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Update Logic
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('updateLogic')} 
//                       className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!emailScheduleOn}
//                   >
//                     {emailScheduleToggles.updateLogic ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Delete Logic Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${emailScheduleOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Delete Logic
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('deleteLogic')} 
//                       className={`flex items-center space-x-2 ${emailScheduleOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!emailScheduleOn}
//                   >
//                     {emailScheduleToggles.deleteLogic ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//               </div>
//             )}

//             {/* -------------------------------- Event Setting Section --------------------------------------- */}
//             {/* Event Setting Menu */}
//             <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.eventSetting ? '' : 'bg-gray-200'}`}>
//               <div className="flex justify-between items-center">
//                 <label className={`text-xs font-semibold ${toggles.eventSetting ? 'text-cyan-800' : 'text-gray-400'}`}>
//                   Event Setting Menu
//                 </label>
//                 <button 
//                   onClick={toggleEventSettingMenu} 
//                   className={`text-cyan-800 ${!toggles.eventSetting && 'cursor-not-allowed'}`} 
//                   disabled={!toggles.eventSetting}
//                 >
//                   {isEventSettingMenuCollapsed ? 
//                     <FaCaretDown className={`text-sm ${!toggles.eventSetting ? "text-gray-400" : "text-cyan-800"}`} /> : 
//                     <FaCaretUp className={`text-sm ${!toggles.eventSetting ? "text-gray-400" : "text-cyan-800"}`} />
//                   }
//                 </button>
//               </div>
//             </div>

//             {!isEventSettingMenuCollapsed && toggles.eventSetting && (
//               <div className="px-4 py-2">

//                 {/* Add Event Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${eventSettingOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Add Event
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('addEvent')} 
//                       className={`flex items-center space-x-2 ${eventSettingOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!eventSettingOn}
//                   >
//                     {eventSettingToggles.addEvent ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Edit Event Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${eventSettingOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Edit Event
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('editEvent')} 
//                       className={`flex items-center space-x-2 ${eventSettingOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!eventSettingOn}
//                   >
//                     {eventSettingToggles.editEvent ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Update Event Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${eventSettingOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Update Event
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('updateEvent')} 
//                       className={`flex items-center space-x-2 ${eventSettingOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!eventSettingOn}
//                   >
//                     {eventSettingToggles.updateEvent ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Delete Event Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${eventSettingOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Delete Event
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('deleteEvent')} 
//                       className={`flex items-center space-x-2 ${eventSettingOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!eventSettingOn}
//                   >
//                     {eventSettingToggles.deleteEvent ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//               </div>
//             )}

//             {/* -------------------------------- Event Notification Section --------------------------------------- */}
//             {/* Event Notification Menu */}
//             <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.eventNotification ? '' : 'bg-gray-200'}`}>
//               <div className="flex justify-between items-center">
//                 <label className={`text-xs font-semibold ${toggles.eventNotification ? 'text-cyan-800' : 'text-gray-400'}`}>
//                   Event Notification Menu
//                 </label>
//                 <button 
//                   onClick={toggleEventNotificationMenu} 
//                   className={`text-cyan-800 ${!toggles.eventNotification && 'cursor-not-allowed'}`} 
//                   disabled={!toggles.eventNotification}
//                 >
//                   {isEventNotificationMenuCollapsed ? 
//                     <FaCaretDown className={`text-sm ${!toggles.eventNotification ? "text-gray-400" : "text-cyan-800"}`} /> : 
//                     <FaCaretUp className={`text-sm ${!toggles.eventNotification ? "text-gray-400" : "text-cyan-800"}`} />
//                   }
//                 </button>
//               </div>
//             </div>

//             {!isEventNotificationMenuCollapsed && toggles.eventNotification && (
//               <div className="px-4 py-2">

//                 {/* Approve Ticket Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${eventNotificationOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Approve Event Ticket
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('approveEventTicket')} 
//                       className={`flex items-center space-x-2 ${eventNotificationOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!eventNotificationOn}
//                   >
//                     {eventNotificationToggles.approveEventTicket ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Reject Ticket Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${eventNotificationOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Reject Event Ticket
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('rejectEventTicket')} 
//                       className={`flex items-center space-x-2 ${eventNotificationOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!eventNotificationOn}
//                   >
//                     {eventNotificationToggles.rejectEventTicket ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Delete Ticket Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${eventNotificationOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Delete Event Ticket
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('deleteEventTicket')} 
//                       className={`flex items-center space-x-2 ${eventNotificationOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!eventNotificationOn}
//                   >
//                     {eventNotificationToggles.deleteEventTicket ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>
//               </div>
//             )}

//             {/* -------------------------------- Funded Program Section --------------------------------------- */}
//             {/* Event Notification Menu */}
//             <div className={`bg-gray-100 px-4 py-2 mt-1 ${toggles.fundedProgram ? '' : 'bg-gray-200'}`}>
//               <div className="flex justify-between items-center">
//                 <label className={`text-xs font-semibold ${toggles.fundedProgram ? 'text-cyan-800' : 'text-gray-400'}`}>
//                   Funded Program Menu
//                 </label>
//                 <button 
//                   onClick={toggleFundedProgramMenu} 
//                   className={`text-cyan-800 ${!toggles.fundedProgram && 'cursor-not-allowed'}`} 
//                   disabled={!toggles.fundedProgram}
//                 >
//                   {isFundedProgramMenuCollapsed ? 
//                     <FaCaretDown className={`text-sm ${!toggles.fundedProgram ? "text-gray-400" : "text-cyan-800"}`} /> : 
//                     <FaCaretUp className={`text-sm ${!toggles.fundedProgram ? "text-gray-400" : "text-cyan-800"}`} />
//                   }
//                 </button>
//               </div>
//             </div>

//             {!isFundedProgramMenuCollapsed && toggles.fundedProgram && (
//               <div className="px-4 py-2">

//                 {/* Approve Fund Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Add Fund
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('addFund')} 
//                       className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!fundedProgramOn}
//                   >
//                     {fundedProgramToggles.addFund ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Reject Fund Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Edit Fund
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('editFund')} 
//                       className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!fundedProgramOn}
//                   >
//                     {fundedProgramToggles.editFund ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Update Fund Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Update Fund
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('updateFund')} 
//                       className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!fundedProgramOn}
//                   >
//                     {fundedProgramToggles.updateFund ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Delete Fund Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Delete Fund
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('deleteFund')} 
//                       className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!fundedProgramOn}
//                   >
//                     {fundedProgramToggles.deleteFund ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Approve Fund Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Approve Fund
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('approveFund')} 
//                       className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!fundedProgramOn}
//                   >
//                     {fundedProgramToggles.approveFund ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {/* Reject Fund Toggle */}
//                 <div className="flex justify-between items-center my-2">
//                   <span className={`text-xs ${fundedProgramOn ? 'text-gray-700' : 'text-gray-400'}`}>
//                     Reject Fund
//                   </span>
//                   <button 
//                       onClick={() => handleToggle('rejectFund')} 
//                       className={`flex items-center space-x-2 ${fundedProgramOn ? 'text-gray-400' : 'text-cyan-800'}`}
//                       disabled={!fundedProgramOn}
//                   >
//                     {fundedProgramToggles.rejectFund ? (
//                       <>
//                         <span className="text-xs text-cyan-800">Enable</span>
//                         <BsToggleOn className="text-xl text-cyan-800"/>
//                       </>
//                     ) : (
//                       <>
//                         <span className="text-xs text-gray-400">Disable</span>
//                         <BsToggleOff className="text-xl text-gray-400"/>
//                       </>
//                     )}
//                   </button>
//                 </div>
//               </div>
//             )}

//           </div>
//         </div>

//         {/* button function field */}
//         <div className="flex justify-end mt-4">
//           <button
//             type="button"
//             className="mr-4 bg-gray-400 text-white rounded-md px-8 py-1"
//             onClick={onClose}
//           >
//             Cancel
//           </button>
//           <button
//             type="submit"
//             className="text-sm bg-cyan-800 text-white rounded-md px-4 py-1"
//             onClick={handleSubmit}
//           >
//             {initialData ? 'Save Changes' : 'Add Account'}
//           </button>
//         </div>

//         {isEmailModalOpen && (
//           <AssignAdminEmail
//             isOpen={isEmailModalOpen}
//             onClose={handleCloseEmailModal}
//             handleAddEmail={handleAddEmail}
//             initialEmails={form.roleAssign.split(', ')}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default AdminAddAccountModal;